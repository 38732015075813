import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import jQuery from "jquery";
import { Collapse, Popover, PopoverBody } from "reactstrap";
import { Link } from "react-router-dom";
import controllers from "../../store/controllers";
import SearchBar from "../SearchBar/SearchBarInput";
import queryString from "query-string";
import { HeaderLinks } from "../../resources/NavigationLinks";

export default function(props) {
  const { t } = useTranslation();
  const {
    onSidebarToggle,
    currentUser,
    isAuthenticated,
    isUserAccountDeckOpen,
    history,
    currencies,
    selectedCurrencyId,
    hideCurrency,
    isCurrencyChanging,
    languages,
    selectedLanguage
  } = props;

  const links = HeaderLinks(currentUser);
  const _hideCurrency = hideCurrency || false;

  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const HandleSearch = address => {
    history.push({
      pathname: "/filter",
      search: queryString.stringify(address)
    });
  };

  const UpdateCurrency = key => {
    controllers.changePreferredCurrency(key);
  };

  const UpdateLanguage = key => {
    jQuery("#multi-language-modal").modal("hide");
    setTimeout(() => {
      controllers.changePreferredLanguage(key);
    }, 100);
  };

  return (
    <React.Fragment>
      <header
        style={{ transition: "background-color 0.3s" }}
        className={`container-fluid ${isUserAccountDeckOpen === true &&
          "dashboard-content"}`}
      >
        <div className={`row d-none d-lg-flex`}>
          <div className="col-md-6 col-lg-5 header-wrapper-left align-items-center py-2 d-flex">
            <div className="logo-wrapper flex-shrink-0 px-2">
              <Link to="/">
                <img
                  src={require("./../../assets/images/logo.png")}
                  alt="Tripbonder Logo"
                />
              </Link>
            </div>
            <div className="w-100 mx-3">
              <SearchBar onSearch={HandleSearch} headerComponent={true} />
            </div>
            {_hideCurrency === false ? (
              <div className="currency-wrapper flex-shrink-0 ml-md-3 ml-lg-0">
                <i className="coin fas fa-coins"></i>
                <select
                  disabled={isCurrencyChanging}
                  value={selectedCurrencyId}
                  onChange={e => UpdateCurrency(e.target.value)}
                  className="custom-select"
                >
                  {currencies &&
                    currencies.map(item => (
                      <option
                        key={item.key}
                        value={item.key}
                        className="select-items"
                      >
                        {item.key}
                      </option>
                    ))}
                </select>
              </div>
            ) : null}
            <button
              data-toggle="modal"
              data-target="#multi-language-modal"
              className="language-flag-wrapper ml-1 ml-sm-2"
            >
              <img
                src={require(`./../../assets/images/${selectedLanguage.image}`)}
                alt="uk-flag"
              />

              <h6 className="mb-0 pl-1 text-uppercase">
                {selectedLanguage.label}
              </h6>
            </button>
            {/* <button
               style={{ backgroundColor: "transparent", fontSize: 12 }}
               id="download-app-popover"
               type="button"
              >
               <i className="fas fa-download"></i>
               Download App
              </button>
              <Popover
               trigger="legacy"
               placement="auto"
               isOpen={popoverOpen}
               target="download-app-popover"
               toggle={toggle}
              >
               <PopoverBody>
                 <img
                   src={require("./../../assets/images/logo.png")}
                   alt="logo"
                   width={120}
                 />
                 <h6 style={{ fontSize: 14 }} className="font-weight-bold">
                   Download Tripbonder Mobile App
                 </h6>
                 <hr />
                 <div className="text-center pb-3">
                   <img
                     src={require("./../../assets/images/qr-code.png")}
                     alt="qr-code"
                     width={200}
                   />
                   <a
                     href="https://apps.apple.com/us/app/tripbonder/id1491889457?ls=1"
                     target="blank"
                   >
                     <img
                       src={require("./../../assets/images/apple-store.png")}
                       alt="qr-code"
                       width={120}
                     />
                   </a>
                   <a
                     target="blank"
                     href="https://play.google.com/store/apps/details?id=com.tripbonder.app"
                   >
                     <img
                       src={require("./../../assets/images/google-play.png")}
                       alt="qr-code"
                       width={120}
                     />
                   </a>
                 </div>
               </PopoverBody>
              </Popover> */}
          </div>
          <div className="col-md-6 col-lg-7 header-wrapper-right d-flex py-2">
            <div className="navbar-wrapper flex-shrink-0">
              <ul className="nav">
                <button
                  style={{
                    backgroundColor: "transparent",
                    fontSize: 14,
                    color: "#656565"
                  }}
                  id="download-app-popover"
                  type="button"
                >
                  <i className="fas fa-download pr-1"></i>
                  {t("Download App")}
                </button>
                <Popover
                  trigger="legacy"
                  placement="auto"
                  isOpen={popoverOpen}
                  target="download-app-popover"
                  toggle={toggle}
                >
                  <PopoverBody>
                    <img
                      src={require("./../../assets/images/logo.png")}
                      alt="logo"
                      width={120}
                    />

                    <h6 style={{ fontSize: 14 }} className="font-weight-bold">
                      {t("Download Tripbonder Mobile App")}
                    </h6>
                    <hr />
                    <div className="text-center pb-3">
                      <img
                        src={require("./../../assets/images/qr-code.png")}
                        alt="qr-code"
                        width={200}
                      />

                      <a
                        href="https://apps.apple.com/us/app/tripbonder/id1491889457?ls=1"
                        target="blank"
                      >
                        <img
                          src={require("./../../assets/images/apple-store.png")}
                          alt="qr-code"
                          width={120}
                        />
                      </a>
                      <a
                        target="blank"
                        href="https://play.google.com/store/apps/details?id=com.tripbonder.app"
                      >
                        <img
                          src={require("./../../assets/images/google-play.png")}
                          alt="qr-code"
                          width={120}
                        />
                      </a>
                    </div>
                  </PopoverBody>
                </Popover>
                {links
                  .filter(l => l.shouldRender())
                  .map(link => (
                    <li
                      key={link.to}
                      className={`nav-item ${
                        history.location.pathname &&
                        history.location.pathname.startsWith(link.to)
                          ? "selected-item"
                          : ""
                      }`}
                    >
                      <Link
                        to={link.to}
                        data-tbpath={link.to}
                        className="nav-link"
                      >
                        {t(link.title)}
                      </Link>
                    </li>
                  ))}

                {isAuthenticated === true ? (
                  <>
                    <div className="pro-dp-sm">
                      <div className="proborder">
                        <div className="inner">
                          <img
                            src={
                              currentUser.profileImageId
                                ? controllers.resolveAssetAddr(
                                    currentUser.profileImageId
                                  )
                                : require("../../assets/images/default-avatar.png")
                            }
                            className="avatar"
                            alt="Cinque Terre"
                            onClick={e => controllers.toggleUserAccountDeck()}
                          />
                        </div>
                      </div>
                    </div>
                    <li className="nav-item">
                      <button
                        className="nav-link pl-1"
                        aria-expanded="false"
                        onClick={e => controllers.toggleUserAccountDeck()}
                      >
                        {t("You")}

                        <i className="fas fa-caret-down ml-2"></i>
                      </button>
                    </li>
                  </>
                ) : (
                  <li className="nav-item">
                    <Link to="/auth/login" className="nav-link">
                      {t("Sign In")}
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="row d-lg-none py-2">
          <div className="col-12 mobile-header-wrapper">
            <button onClick={onSidebarToggle} className="menu-btn p-3">
              <i className="fas fa-bars"></i>
            </button>
            <div className="mobile-logo-wrapper">
              <Link to="/">
                <img src={require("./../../assets/images/logo.png")} alt="" />
              </Link>
            </div>
            {_hideCurrency === false ? (
              <div className="mobile-currency-wrapper ml-3">
                <i className="coin fas fa-coins"></i>
                <select
                  disabled={isCurrencyChanging}
                  value={selectedCurrencyId}
                  onChange={e => UpdateCurrency(e.target.value)}
                  className="custom-select"
                >
                  {currencies &&
                    currencies.map(item => (
                      <option
                        key={item.key}
                        value={item.key}
                        className="select-items"
                      >
                        {item.key}
                      </option>
                    ))}
                </select>
              </div>
            ) : null}
            <button
              data-toggle="modal"
              data-target="#multi-language-modal"
              className="language-flag-wrapper ml-2 ml-sm-2"
            >
              <img
                src={require(`./../../assets/images/${selectedLanguage.image}`)}
                alt="uk-flag"
              />

              <h6 className="mb-0 pl-1 d-none d-sm-block">{t("EN")}</h6>
            </button>
          </div>
        </div>
      </header>
      <Collapse
        isOpen={isUserAccountDeckOpen}
        className="col-12"
        id="dashboardContent"
      >
        <div className="row">
          <div className="col-5 col-xl-8 p-0">
            <div className="bg-img"></div>
          </div>
          <div className="col-7 col-xl-4 p-0">
            <div className="row py-3 pr-4">
              <div
                className="col-6 pl-4 px-4 py-3 text-right"
                style={{ borderRight: "1px solid #00000024" }}
              >
                <h6>
                  <b>{t("YOUR ACTIVITY")}</b>
                </h6>
                <div className="vertical-menu">
                  <Link
                    onClick={() => controllers.closeUserAccountDeck()}
                    to="/me/orders"
                  >
                    {t("My Bookings")}
                  </Link>
                  <Link
                    onClick={() => controllers.closeUserAccountDeck()}
                    to="/me/wishlist"
                  >
                    {t("My Wishlist")}
                  </Link>
                </div>
              </div>
              <div className="col-6 pl-4 px-4 py-3 pr-4 text-right d-flex flex-column">
                <h6>
                  <b>{t("ACCOUNT SETTINGS")}</b>
                </h6>
                <div className="vertical-menu">
                  <Link
                    to="/me/profile"
                    onClick={() => controllers.closeUserAccountDeck()}
                  >
                    {t("Profile")}
                  </Link>
                  <Link
                    to="/me/profile"
                    onClick={() => controllers.closeUserAccountDeck()}
                  >
                    {t("Change Password")}
                  </Link>
                  {/* <a href="#home">My Wishlist</a> */}
                </div>
                <button
                  className=" mt-2 btn btn-danger btn-block btn-sm"
                  onClick={() => {
                    controllers.logoutCurrentUser();
                    controllers.closeUserAccountDeck();
                  }}
                >
                  {t("Logout")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
      <div
        className="modal fade multi-language-modal-wrapper"
        id="multi-language-modal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content pl-3 pb-4">
            <div className="form-row">
              <div className="col-12">
                <div className="close-button-wrapper text-right pr-1 pt-1">
                  <button data-dismiss="modal">
                    <i className="fas fa-times"></i>
                  </button>
                </div>
                <h6 className=" pb-2 mr-3">{t("Select Language")}</h6>
              </div>
              {languages.map(lan => {
                return (
                  <div key={lan.key} className="col-12 col-sm-6">
                    <button
                      onClick={() => UpdateLanguage(lan.key)}
                      className="language-flag-wrapper py-1"
                    >
                      <img
                        height={25}
                        src={require(`./../../assets/images/${lan.image}`)}
                        alt="uk-flag"
                      />

                      <span
                        className={`pl-2 ${
                          selectedLanguage.key === lan.key ? "selected" : ""
                        }`}
                      >
                        {lan.label}
                      </span>
                    </button>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
