import {
  FILTER_TOGGLE_CATEGORY,
  FILTER_UPDATE_KEY,
  FILTER_RESET,
  FILTER_CLOSE_MINI,
  FILTER_OPEN_MINI,
  FILTER_TOGGLE_MINI,
  FILTER_SET_RESULTS,
  FILTER_SET_REFRESHING,
  FILTER_SET_ADDRESS,
  FILTER_SET_PACKAGE,
  FILTER_SET_RESULT_MODE
} from "../types";

const initialState = {
  package: "itinerary",
  miniFilterOpen: false,

  categories: [],
  dayRange: "",
  priceRange: null,
  address: "",
  lat: null,
  lon: null,
  city: null,
  country: null,

  results: [],
  nearByResults: [],
  filterTags: [],
  selectedHashTags: [],
  nearByResultCount: 0,
  selectedTab: "result", // result || nearby
  isRefreshing: false,
  isSilentRefreshing: false,
  hasInitialized: false,
  itemsPerLoad: 50,
  hasLoadedCompleteResults: false,
  hasLoadedCompleteNearbyResults: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FILTER_SET_ADDRESS: {
      const {
        address,
        lat,
        lon,
        city,
        country,
        priceRange,
        theme,
        type
      } = action.payload;
      return Object.assign({}, state, {
        address: address || "",
        lat: lat || null,
        lon: lon || null,
        city: city || null,
        country: country || null,
        results: [],
        hasLoadedCompleteResults: false,
        hasLoadedCompleteNearbyResults: false,
        nearByResults: [],
        nearByResultCount: 0,
        priceRange: state.priceRange === null ? priceRange : state.priceRange,
        categories: theme ? [theme] : [],
        package: type ? type : "itinerary"
      });
    }
    case FILTER_SET_RESULT_MODE: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        selectedTab: value,
        isRefreshing: false
      });
    }
    case FILTER_SET_PACKAGE: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        package: value
      });
    }
    case FILTER_TOGGLE_CATEGORY: {
      const { value, reset } = action.payload;
      if (state.categories.indexOf(value) > -1) {
        return Object.assign({}, state, {
          categories: state.categories.filter(c => c !== value)
        });
      } else {
        return Object.assign({}, state, {
          categories: [...(reset === true ? [] : state.categories), value]
        });
      }
    }
    case FILTER_UPDATE_KEY: {
      const { key, value } = action.payload;
      console.log(value);
      return Object.assign({}, state, {
        [key]: value
      });
    }
    case FILTER_RESET: {
      const { priceRange } = action.payload;
      return Object.assign({}, state, {
        categories: initialState.categories,
        dayRange: initialState.dayRange,
        priceRange: priceRange || initialState.priceRange,
        miniFilterOpen: false
      });
    }
    case FILTER_OPEN_MINI: {
      return Object.assign({}, state, {
        miniFilterOpen: true
      });
    }
    case FILTER_CLOSE_MINI: {
      return Object.assign({}, state, {
        miniFilterOpen: false
      });
    }
    case FILTER_TOGGLE_MINI: {
      return Object.assign({}, state, {
        miniFilterOpen: !state.miniFilterOpen
      });
    }
    case FILTER_SET_RESULTS: {
      const { results, nearByResults, push } = action.payload;
      return Object.assign({}, state, {
        results: [...(push ? state.results : []), ...results],
        nearByResults: [...(push ? state.nearByResults : []), ...nearByResults],
        nearByResultCount: [
          ...(push ? state.nearByResults : []),
          ...nearByResults
        ].length,
        isRefreshing: false,
        isSilentRefreshing: false,
        hasInitialized: true,
        hasLoadedCompleteResults: results.length < state.itemsPerLoad,
        hasLoadedCompleteNearbyResults:
          nearByResults.length < state.itemsPerLoad
      });
    }
    case FILTER_SET_REFRESHING: {
      const { value } = action.payload;
      let isSilent = action.payload.isSilent;
      return Object.assign({}, state, {
        hasLoadedCompleteNearbyResults: true,
        hasLoadedCompleteResults: true,
        [isSilent === true ? "isSilentRefreshing" : "isRefreshing"]: false,
        [isSilent === true ? "isSilentRefreshing" : "isRefreshing"]:
          value || false
      });
    }
    default: {
      return state;
    }
  }
}
