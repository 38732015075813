import { createStore, compose, applyMiddleware } from "redux";

import createTripbonderApp from "./reducers";
import { EDITOR_PREFIX_, SET_EDITOR_MODIFIED } from "./types";

const EditorModificationTracker = store => next => action => {
  let result = next(action);
  if (result.type && result.type.startsWith(EDITOR_PREFIX_)) {
    store.dispatch({
      type: SET_EDITOR_MODIFIED
    });
  }
  return result;
};

let composeScript = null;
let middlewares = [EditorModificationTracker];

if (process.env.NODE_ENV === "development") {
  composeScript = compose(applyMiddleware(...middlewares));
  if (window.__REDUX_DEVTOOLS_EXTENSION__) {
    composeScript = compose(
      applyMiddleware(...middlewares),
      window.__REDUX_DEVTOOLS_EXTENSION__()
    );
  }
} else {
  composeScript = compose(applyMiddleware(...middlewares));
}

export default createStore(createTripbonderApp(), composeScript);
