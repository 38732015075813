import { useTranslation } from "react-i18next";
import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useState } from "react";
import Controller from "../../store/controllers/index";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ListingServices } from "./../../services/Listing.services";
import { NETWORK_ERR_MESSAGE } from "../../resources/String";
import moment from "moment";

const mapStateToProps = state => ({
  context: state.local.editorListingSnapshot
});

export const StatusAlert = connect(mapStateToProps)(function(props) {
  const { t } = useTranslation();
  const statusKey = props.statusKey || null;
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [packageAction, setPackageAction] = useState("");
  const toggle = action => {
    setPackageAction(action || null);
    setModal(!modal);
  };

  function editPackage(_id, mode) {
    toggle(null);
    Controller.setGlobalLoader("Opening Editor...", false);
    setTimeout(() => {
      ListingServices.ClonePackage(_id, mode)
        .then(response => {
          history.push(`/dashboard/composer/${response.type}/${response._id}`);
          setTimeout(() => {
            Controller.clearGlobalMessage();
          }, 1200);
        })
        .catch(err => {
          Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
        });
    }, 800);
  }

  function UpdatePublishStatus(_id, key) {
    toggle(null);
    Controller.setGlobalLoader("Changing Status...", false);
    setTimeout(() => {
      ListingServices.UpdatePublishStatus(_id, key)
        .then(response => {
          Controller.updateEditorListing(
            "effectiveStatus",
            response.effectiveStatus
          );

          Controller.setGlobalSuccess(`Order ${key}`, true);
          setTimeout(() => {
            Controller.clearGlobalMessage();
          }, 1200);
        })
        .catch(err => {
          Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
        });
    }, 800);
  }
  function restoreDraft(type, id) {
    toggle(null);
    Controller.setGlobalLoader("Restoring Draft....", false);
    setTimeout(() => {
      ListingServices.CancelOrMoveToDraft(type, id)
        .then(response => {
          Controller.updateEditorListing("status", response.status);
          Controller[response.type][
            `update${(s => s.charAt(0).toUpperCase() + s.slice(1))(
              response.type
            )}`
          ]("_status", response.status);
          Controller.setGlobalSuccess("Drafted", true);
          setTimeout(() => {
            Controller.clearGlobalMessage();
          }, 1200);
        })
        .catch(err => {
          Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
        });
    }, 800);
  }

  if (statusKey && statusKey !== "draft") {
    let title;
    let message;
    let theme;
    let actionButton;

    switch (statusKey) {
      case "accepted": {
        let listingBeginingDate = props.listingStartDate || null;
        let listingEndDate = props.listingEndDate || null;

        if (listingBeginingDate && listingEndDate) {
          const today = moment();
          listingBeginingDate = moment(listingBeginingDate);
          listingEndDate = moment(listingEndDate);

          if (listingBeginingDate.isAfter(today) === true) {
            message = `${t(
              "Your listing will be visible on search"
            )} ${today.to(listingBeginingDate)}`;
          } else {
            message = `${t(
              "Your listing is currently active until"
            )} ${listingEndDate.format("LL")}`;
          }
        }

        if (props.context.effectiveStatus === "active") {
          title = t("Your Package is Approved");
          theme = "alert-success";
          actionButton = (
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => toggle("customize")}
              >
                {t("CUSTOMIZE")}
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle dropdown-toggle-split pl-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="sr-only">{t("Toggle Dropdown")}</span>
              </button>
              <div className="dropdown-menu">
                <button
                  className="dropdown-item btn btn-link"
                  onClick={() => toggle("edit")}
                >
                  {t("EDIT")}
                </button>
                <button
                  className="dropdown-item btn btn-link"
                  onClick={() => toggle("duplicate")}
                >
                  {t("DUPLICATE")}
                </button>
                <button
                  className="dropdown-item btn btn-link"
                  onClick={() => toggle("unpublish")}
                >
                  {t("UNPUBLISH")}
                </button>
              </div>
            </div>
          );
        } else if (props.context.effectiveStatus === "upcoming") {
          title = t("Your Package is Approved");
          theme = "alert-success";
          actionButton = (
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => toggle("edit")}
              >
                {t("EDIT")}
              </button>
              <button
                type="button"
                className="btn btn-success dropdown-toggle dropdown-toggle-split pl-0"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="sr-only">{t("Toggle Dropdown")}</span>
              </button>
              <div className="dropdown-menu">
                <button
                  className="dropdown-item btn btn-link"
                  onClick={() => toggle("duplicate")}
                >
                  {t("DUPLICATE")}
                </button>
                <button
                  className="dropdown-item btn btn-link"
                  onClick={() => toggle("unpublish")}
                >
                  {t("UNPUBLISH")}
                </button>
              </div>
            </div>
          );
        } else if (props.context.effectiveStatus === "ended") {
          title = t("Your Package has Ended");
          theme = "alert-dark";
          actionButton = (
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => toggle("duplicate")}
              >
                {t("DUPLICATE")}
              </button>
            </div>
          );
        } else {
          title = t("Your Package is yet to be Published");
          message = t("Publish in order to activate the package");
          theme = "alert-danger";
          actionButton = (
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-success"
                onClick={() => toggle("publish")}
              >
                {t("PUBLISH")}
              </button>
            </div>
          );
        }

        break;
      }
      case "in_review": {
        title = t("Waiting for review");
        message = t(
          "This package is in review. You cannot make changes to package until it is completed."
        );

        theme = "alert-primary";
        actionButton = (
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-info"
              onClick={() => toggle("restore-draft")}
            >
              {t("CANCEL REVIEW")}
            </button>
          </div>
        );

        break;
      }
      case "rejected": {
        title = t("Package Rejected");
        message = t(
          "This package has been rejected. Contact Tripbonder support for more information."
        );

        theme = "alert-danger";
        actionButton = (
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-success"
              onClick={() => toggle("restore-draft")}
            >
              <i className="fas fa-arrow-left"></i>
              {t("MOVE TO DRAFT")}
            </button>
          </div>
        );

        break;
      }
      default: {
        title = `${t("Status")}: ${statusKey.toUpperCase()}`;
        message = null;
        theme = "alert-light";
        break;
      }
    }

    return (
      <>
        <div className="row sticky-alert">
          <div className="col-12">
            <div className={`alert ${theme}`} role="alert">
              <div className="row">
                <div className="col-12 col-md-6">
                  <h4 className="alert-heading">
                    <b>{title}</b>
                  </h4>
                  <p className="m-0">{message}</p>
                </div>
                <div className="col-12 col-md-6 pt-3 pt-md-0 justify-content-center justify-content-md-end d-flex align-items-center">
                  {actionButton}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={modal} toggle={toggle}>
          {packageAction === "edit" ? (
            <>
              <ModalHeader toggle={toggle}>
                {t("DUPLICATE PACKAGE")}
              </ModalHeader>
              <ModalBody>
                {t(
                  "Any changes made to the packages will only be reflected after approval"
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={() => editPackage(props.context._id, "merge")}
                >
                  {t("Confirm Edit")}
                </Button>{" "}
                <Button color="secondary" onClick={toggle}>
                  {t("Cancel")}
                </Button>
              </ModalFooter>
            </>
          ) : null}
          {packageAction === "customize" ? (
            <>
              <ModalHeader toggle={toggle}>
                {t("CREATE CUSTOM PACKAGE")}
              </ModalHeader>
              <ModalBody>
                {t(
                  "The custom package created won't be made available in the main search listings. Instead a special link will be provided which you can share with your customer through which they can access this private package."
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={() => editPackage(props.context._id, "private")}
                >
                  {t("Customize")}
                </Button>{" "}
                <Button color="secondary" onClick={toggle}>
                  {t("Cancel")}
                </Button>
              </ModalFooter>
            </>
          ) : null}
          {packageAction === "duplicate" ? (
            <>
              <ModalHeader toggle={toggle}>{t("EDIT PACKAGE")}</ModalHeader>
              <ModalBody>
                {t("You are about to duplicate the current package")}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="primary"
                  onClick={() => editPackage(props.context._id, "general")}
                >
                  {t("Duplicate")}
                </Button>{" "}
                <Button color="secondary" onClick={toggle}>
                  {t("Cancel")}
                </Button>
              </ModalFooter>
            </>
          ) : null}
          {packageAction === "unpublish" ? (
            <>
              <ModalHeader toggle={toggle}>
                {t("UNPUBLISH PACKAGE")}
              </ModalHeader>
              <ModalBody>
                {t("Are you sure you want to unpublish this order ?")}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  onClick={() =>
                    UpdatePublishStatus(props.context._id, "unpublish")
                  }
                >
                  {t("Unpublish")}
                </Button>{" "}
                <Button color="secondary" onClick={toggle}>
                  {t("Cancel")}
                </Button>
              </ModalFooter>
            </>
          ) : null}
          {packageAction === "publish" ? (
            <>
              <ModalHeader toggle={toggle}>
                {t("UNPUBLISH PACKAGE")}
              </ModalHeader>
              <ModalBody>
                {t("Are you sure you want to unpublish this order ?")}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="success"
                  onClick={() =>
                    UpdatePublishStatus(props.context._id, "publish")
                  }
                >
                  {t("Publish")}
                </Button>{" "}
                <Button color="secondary" onClick={toggle}>
                  {t("Cancel")}
                </Button>
              </ModalFooter>
            </>
          ) : null}
          {packageAction === "restore-draft" ? (
            <>
              <ModalHeader toggle={toggle}>{t("RESTORE TO DRAFT")}</ModalHeader>
              <ModalBody>
                {t("Do you want to move the package back to draft")}
              </ModalBody>
              <ModalFooter>
                <Button
                  color="success"
                  onClick={() =>
                    restoreDraft(props.context.type, props.context._id)
                  }
                >
                  {t("Move to Draft")}
                </Button>{" "}
                <Button color="secondary" onClick={toggle}>
                  {t("Cancel")}
                </Button>
              </ModalFooter>
            </>
          ) : null}
        </Modal>
      </>
    );
  } else {
    return null;
  }
});
