import { useTranslation } from "react-i18next";
import React, { useState, useRef } from "react";
import { ListingServices } from "./../../services/Listing.services";
import Controller from "../../store/controllers/index";
import { useHistory } from "react-router-dom";
import { NETWORK_ERR_MESSAGE } from "../../resources/String";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
export default function PrivatePackageAlert(props) {
  const { t } = useTranslation();
  const draftDeleteConfTextBoxRef = useRef();
  const [modal, setModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const history = useHistory();
  const [confirmationText, setConfirmationText] = useState("");
  const [customerName, getCustomerName] = useState("");
  const [customerEmail, getCustomerEmail] = useState("");
  const toggle = () => setModal(!modal);
  const toggleShareModal = () => setShareModal(!shareModal);
  function SharePackage(id, name, email) {
    toggleShareModal();
    Controller.setGlobalLoader("Sending mail...", false);
    setTimeout(() => {
      ListingServices.SharePrivatePackage(id, {
        emailAddress: email,
        name: name || null
      })
        .then(response => {
          Controller.setGlobalSuccess("Package shared successfully", true);
          getCustomerName("");
          getCustomerEmail("");
          setTimeout(() => {
            Controller.clearGlobalMessage();
          }, 1200);
        })
        .catch(err => {
          Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
        });
    }, 800);
  }
  function DeletePrivatePackage(id) {
    toggle();
    Controller.setGlobalLoader("Deleting Package...", false);
    setTimeout(() => {
      ListingServices.Delete(id)
        .then(response => {
          setConfirmationText("");
          history.push("/dashboard/listings/private");
        })
        .catch(err => {
          Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
        });
    }, 800);
  }
  return (
    <>
      <div
        className="alert alert-info d-flex flex-column justify-content-between flex-md-row"
        role="alert"
      >
        <div>
          <h4 className="alert-heading">{t("Private Package")}</h4>
          <p>
            <i className="fas fa-user-lock pr-2 fa-sm"></i>
            {t("This private package can only viewed through this link")}
          </p>
          <a
            target="blank"
            style={{ overflowWrap: "break-word", fontSize: 12 }}
            href={`/custom/packages/view/${props.id}`}
          >
            {" "}
            {t("https://tripbonder.com/custom/packages/view/")}
            {props.id}{" "}
          </a>
        </div>
        <div className="flex-shrink-0 d-flex justify-content-end justify-content-md-center align-items-center mt-3 mt-md-0">
          <button
            type="button mr-2"
            className="btn btn-link"
            onClick={() => toggleShareModal()}
          >
            <i className="fas fa-share-alt"></i>
          </button>
          <div className="dropdown">
            <button
              className="btn btn-link dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-ellipsis-v"></i>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <button
                className="dropdown-item"
                onClick={() => {
                  toggle();
                  setTimeout(() => {
                    draftDeleteConfTextBoxRef &&
                      draftDeleteConfTextBoxRef.current &&
                      draftDeleteConfTextBoxRef.current.focus();
                  }, 200);
                }}
              >
                {t("Delete")}
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <div className="modal-content delete-draft-modal">
          <form
            onSubmit={e => {
              e.preventDefault();
              DeletePrivatePackage(props.id);
            }}
          >
            <div className="modal-header truncate">
              <h5 className="modal-title truncate">{`Delete private listing`}</h5>
              <button type="button" className="close" onClick={toggle}>
                <span aria-hidden="true">{t("\xD7")}</span>
              </button>
            </div>
            <div className="modal-body">
              <b>{t("Note: You won't be able to undo this action.")}</b>
              {t("Please type in '")}
              <b>{t("DELETE")}</b>
              {t("' in the box below to continue.")}
              <div className="form-group mt-3">
                <input
                  ref={draftDeleteConfTextBoxRef}
                  value={confirmationText}
                  type="text"
                  maxLength={10}
                  onChange={e =>
                    setConfirmationText(e.target.value.toUpperCase())
                  }
                  placeholder={t("e.g. 'DELETE'")}
                  className="form-control form-control-sm"
                />
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-default btn-sm"
                data-dismiss="modal"
                onClick={toggle}
              >
                {t("Cancel")}
              </button>
              <button
                disabled={confirmationText !== "DELETE"}
                type="submit"
                className="btn btn-danger btn-sm"
              >
                {t("DELETE")}
              </button>
            </div>
          </form>
        </div>
      </Modal>
      <Modal isOpen={shareModal} toggle={toggleShareModal}>
        <form
          onSubmit={e => {
            e.preventDefault();
            SharePackage(props.id, customerName, customerEmail);
          }}
        >
          <ModalHeader toggle={toggleShareModal}>
            {t("SHARE PRIVATE PACKAGE")}
          </ModalHeader>
          <ModalBody>
            <div className="form-row">
              <div className="col-12">
                <label htmlFor="validationServer01">{t("Name")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="validationServer01"
                  value={customerName}
                  placeholder={t("(Optional)")}
                  onChange={e => getCustomerName(e.target.value)}
                />
              </div>
              <div className="col-12 pt-2">
                <label htmlFor="validationServer02">{t("E-mail ID")}</label>
                <input
                  type="email"
                  className="form-control"
                  id="validationServer02"
                  value={customerEmail}
                  placeholder={t("(Required)")}
                  onChange={e => getCustomerEmail(e.target.value)}
                  required
                />

                <div className="valid-feedback">{t("Looks good!")}</div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="success" type="submit">
              {t("Share")}
            </Button>
            <Button color="secondary" onClick={toggleShareModal}>
              {t("Cancel")}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
}
