import { withTranslation } from "react-i18next";
import React from "react";
import { connect } from "react-redux";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
const BecomeTripbonder = withTranslation()(
  class BecomeTripbonder extends React.Component {
    componentDidMount() {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    render() {
      const t = this.props.t;
      const { currentUser } = this.props.local;
      const hasTripbonderDashboardAccess =
        (currentUser && currentUser.hasTripbonderDashboardAccess) || false;
      const getStartedLink =
        hasTripbonderDashboardAccess === true
          ? "/dashboard"
          : "/about/agreement";
      var iOS =
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      return (
        <Fade duration={300}>
          <Helmet>
            <title>
              {this.props.t(
                "Are you a travel agent offering customised tour packages? Talk to us"
              )}
            </title>
          </Helmet>
          <div className="container-fluid become-a-tripbonder">
            <div
              className={`row header-img ${
                iOS === true ? "no-fixed-attachment" : ""
              } pt-4 px-4`}
            >
              <div className="col-12 header-wrapper">
                <div className="row">
                  <div className="col-6">
                    <h6>{this.props.t("Become a Tripbonder")}</h6>
                  </div>
                  <div className="col-6 text-right ">
                    <Link
                      to={getStartedLink}
                      className="btn btn-warning-gradient btn-sm px-4"
                      onClick={() =>
                        ReactGA.event({
                          category: "tripbonder sign-up",
                          action:
                            "Clicked on get started from Become a tripbonder"
                        })
                      }
                    >
                      {hasTripbonderDashboardAccess === true ? (
                        <>
                          {this.props.t("Go to Dashboard")}
                          <i className="fas fa-arrow-right"></i>
                        </>
                      ) : (
                        t("Get Started")
                      )}
                    </Link>
                  </div>
                </div>
                <hr />
                <div className="text-center pt-4">
                  <h3>{this.props.t("You can do it right.")}</h3>
                </div>
              </div>
            </div>

            <section className="content-wrapper">
              <div className="row p-0">
                <div className="col-12">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 mt-4 col-md-8">
                        <hr />
                        <h3 className="mt-4 mb-5">
                          {this.props.t(
                            "Are you an experienced trip-goer or a trip service provider?"
                          )}
                        </h3>
                        <div className="pl-2">
                          <div>
                            <i className="fas fa-tools mr-2"></i>
                            <span>{this.props.t("ITINERARY MAKERS")}</span>
                            <p className="mt-4">
                              {this.props.t(
                                "Every Tripbonder will have access to an exceptionally user-friendly trip creation tool. By using this tool, you can quickly curate your unique travel experience for the world. You can create full-length trip with full itinerary, or individual services (Tickets, Entrance Fees, Transports or Accommodations) that will help to complete the traveler's entire travel itinerary."
                              )}
                            </p>
                          </div>
                          <div>
                            <i className="fas fa-chart-line mr-2"></i>
                            <span>{this.props.t("INCOME BOOST")}</span>
                            <p className="mt-4">
                              {this.props.t(
                                "Empower your life by earning a living with your passions, knowledge and skills. Earn the income you desired monthly by providing quality travel services. More importantly, you can share ideas and knowledge with others and have the power to inspire and delight everyone who participates. There\u2019s no better feeling in the world than sharing in these moments."
                              )}
                            </p>
                          </div>
                          <div className="mb-5">
                            <i className="fab fa-gratipay mr-2"></i>
                            <span>{this.props.t("FREE LISITNG")}</span>
                            <p className="mt-4">
                              {this.props.t(
                                "It is forever free to be a Tripbonder. There is no hidden costs or fees to join. Only when your service has been purchased and utilized by travelers that you pay a small service fees to us in order for us to maintain the site and to undertake multiple marketing activities."
                              )}
                            </p>
                          </div>
                          <Link
                            to={getStartedLink}
                            className="btn btn-warning-gradient btn-sm px-5 mb-5"
                          >
                            {hasTripbonderDashboardAccess === true ? (
                              <>
                                {this.props.t("Go to Dashboard")}{" "}
                                <i className="fas fa-arrow-right"></i>
                              </>
                            ) : (
                              t("Get Started")
                            )}
                          </Link>
                        </div>
                      </div>
                      <div className="col-12 col-md-4 mt-2 mt-md-4 pl-md-2">
                        <div className="img-wrapper"></div>
                      </div>
                    </div>
                    <div className="row content-wrapper2">
                      <div className="col-12 text-center mt-5 mb-3">
                        <h3>{this.props.t("Watch how you can do it right")}</h3>
                        <hr />
                        <div className="mb-2 mt-3 mb-sm-5 mt-sm-4 ">
                          <video
                            width="100%"
                            controls
                            poster={require("./../../assets/images/video-poster.jpg")}
                          >
                            <source
                              src={require("../../assets/images/marketing/Video.mp4")}
                              alt="video"
                              type="video/mp4"
                            ></source>
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Fade>
      );
    }
  }
);

export default connect(state => ({
  local: state.local
}))(BecomeTripbonder);
