import React from "react";
import ReactDOM from "react-dom";
import { StripeProvider } from "react-stripe-elements";
import Axios from "axios";
import { Provider } from "react-redux";
import { configure } from "react-hotkeys";
import { BrowserRouter as Router } from "react-router-dom";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "jquery/dist/jquery.slim";
import "popper.js/dist/popper";
import "bootstrap/dist/js/bootstrap";
import ReactGA from "react-ga";
import TripbonderStore from "./store";
import Controller from "./store/controllers";
import Routes from "./Routes";

import createi18n from "./i18n";
import { I18nextProvider } from "react-i18next";

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/scss/regular.scss";
import "@fortawesome/fontawesome-free/scss/solid.scss";
import "./assets/styles/index.scss";

import * as serviceWorker from "./serviceWorker";

import GalleryController from "./components/MediaGallery/gallery.controller";
import getConfig from "./config";
import "./assets/styles/smartbanner.min.css";
import "../node_modules/smartbanner.js/dist/smartbanner.min.js";

const i18n = createi18n({
  baseAddress: getConfig().SERVER_BASE_ADDRS
});

ReactGA.initialize(getConfig().GA_ID);

const STRIPE_API_KEY = getConfig().STRIPE_API_KEY;
const baseUrl = getConfig().SERVER_BASE_ADDRS;

Controller.connect(TripbonderStore, i18n);
GalleryController.connect(TripbonderStore);

// Configure Axios
Axios.defaults.baseURL = baseUrl;
Axios.defaults.withCredentials = true;

// Configure HotKeys
configure({
  ignoreTags: []
});

// function loadJS(file) {
//   // DOM: Create the script element
//   var jsElm = document.createElement("script");
//   jsElm.type = "application/javascript";
//   jsElm.src = file;
//   document.body.appendChild(jsElm);
// }

// loadJS(
//   `https://maps.googleapis.com/maps/api/js?key=${
//     getConfig().GMAPS_KEY
//   }&libraries=places&callback=myCallbackFunc`
// );

function LanguageLoadIndicator(props) {
  const [isActive, setActive] = React.useState(false);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setActive(true);
    }, 1500);

    return function cleanup() {
      clearTimeout(timer);
    };
  });

  if (!isActive) {
    return null;
  }

  return (
    <div
      style={{
        textAlign: "center",
        padding: 80,
        color: "#9e9e9e"
      }}
    >
      Poor network. Still loading...
    </div>
  );
}

ReactDOM.render(
  <StripeProvider apiKey={STRIPE_API_KEY}>
    <Provider store={TripbonderStore}>
      <Router>
        <I18nextProvider i18n={i18n}>
          <React.Suspense fallback={<LanguageLoadIndicator />}>
            <Routes />
          </React.Suspense>
        </I18nextProvider>
      </Router>
    </Provider>
  </StripeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
