import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Rating from "react-rating";
import Controller from "./../../store/controllers";
import { Link } from "react-router-dom";
export default function Trip(props) {
  const { t } = useTranslation();
  let shadowClass = "";
  if (props.hasShadow && props.hasShadow === true) {
    shadowClass = "trip-shadow";
  }
  let isChecked = false;
  let displayCheckBox = false;
  let modeClass = "";

  if (props.mode && props.mode === "select") {
    displayCheckBox = true;
    if (props.hasChecked && props.hasChecked === true) {
      isChecked = true;
    } else {
      modeClass = "unselectedTrip";
    }
  }

  const [readMore, setreadMore] = useState(false);

  const toAddress =
    props.to ||
    ((props.data && props.data.class) === "private"
      ? `/custom/packages/view/${props.data._id}`
      : `/package/view/${props.data._id}`);

  const selectHandleInterceptor = e => {
    if (props.mode && props.mode === "select") {
      e.preventDefault();
      props.onSelect && props.onSelect(props.data);
    }
  };

  return (
    <React.Fragment>
      <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
        <div
          className={`mt-3 mt-lg-5 trip-details ${shadowClass} ${modeClass} zoomable`}
        >
          <Link
            onClick={selectHandleInterceptor}
            to={toAddress}
            className="packages"
          >
            {displayCheckBox === true ? (
              isChecked === true ? (
                <div className="compare-package-icon-wrapper">
                  <i className="fas fa-check-circle selected"></i>
                </div>
              ) : (
                <div className="compare-package-icon-wrapper">
                  <i className="fas fa-circle"></i>
                </div>
              )
            ) : null}
            <div
              className="package-img"
              style={{
                backgroundImage: `url('${Controller.resolveAssetAddr(
                  props.data.package.defaultCoverArtId
                )}')`
              }}
            >
              {/* <div className="trending m-3">
                    <i className="fas fa-fire pr-1 pt-1"></i>
                    TRENDING
                    </div> */}
              {props.data.formattedStoredEffectiveView !== 0 ? (
                <div className="views m-3">
                  <i className="eye fas fa-eye pr-1"></i>
                  {props.data.formattedStoredEffectiveView}
                </div>
              ) : null}
              {/* <div className="btn-wrapper">
                     <Link
                       to={toAddress}
                       className="view-activity go btn btn-warning-gradient"
                     >
                       <i className="view-activity fas fa-search"></i>
                       View Activity
                     </Link>
                    </div> */}
            </div>
            <div
              className="pl-2 pt-1 location truncate"
              style={{ maxWidth: 180 }}
            >
              {props.data.averageRating > 0 ? (
                <div className="tr-rating">
                  <Rating
                    emptySymbol="far fa-star empty-star-color"
                    fullSymbol="fas fa-star rated-star-color"
                    initialRating={props.data.averageRating}
                    readonly={true}
                    start={0}
                    stop={5}
                    fractions={2}
                  />
                </div>
              ) : null}

              <i className="fas fa-map-marker-alt pr-2"></i>
              <span className="country">{props.data.package.location}</span>
            </div>
            <div className="package-desc">
              <h4 className="pl-2 truncate">{props.data.package.title}</h4>
              {/* <p className="desc-head pl-2">By {props.data.user.name}</p> */}
              <div
                className={`truncate-${
                  !readMore && props.data.package.description.length > 200
                    ? "trip"
                    : "block"
                } pb-2`}
                style={{ height: 125 }}
              >
                <p className="px-2 mb-0" style={{ overflow: "hidden" }}>
                  {props.data.package.description}
                </p>
                {!readMore && props.data.package.description.length > 200 ? (
                  <button
                    className="btn px-2"
                    style={{ fontSize: 12, color: "#42939D", zIndex: 2 }}
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      setreadMore(true);
                    }}
                  >
                    {t("Read More")}
                  </button>
                ) : null}
              </div>
            </div>
            {props.data.type === "itinerary" ? (
              <div className="duration pl-2 pt-2">
                <i className="far fa-clock pr-2"></i>
                <span>
                  {props.data.package.days.length}
                  {t("days")}
                </span>
                {readMore ? (
                  <span className="pl-3">
                    <small style={{ fontSize: 10, color: "#42939D" }}>
                      {t("open package to read more")}
                    </small>
                  </span>
                ) : null}
              </div>
            ) : (
              <div className="duration">
                {" "}
                {readMore ? (
                  <span className="pl-2">
                    <small style={{ fontSize: 10, color: "#42939D" }}>
                      {t("open package to read more")}
                    </small>
                  </span>
                ) : null}
              </div>
            )}

            {/* <p className="actual-price-tag pl-2 pt-1 m-0">
                   <del>{props.data.package.perAdultPrice} per Adult</del>
                  </p> */}
            <div className="discounted-price-tag pl-2 pt-0">
              {props.data.priceToDisplay.formattedPrice}
              {/* <i className="fas fa-sun px-2"></i>
                    <i className="fas fa-plane-departure px-2"></i>
                    <span className="offer">30% off!</span> */}
            </div>
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
}
