export const ExtrasMaster = [
  {
    label: "Air Condition",
    value: "ac"
  },
  {
    label: "Additional Tour Information",
    value: "addt-info"
  },
  {
    label: "Parking Fee",
    value: "parking-fee"
  },
  {
    label: "Toll",
    value: "toll"
  },
  {
    label: "Insurance",
    value: "insurance"
  }
];

export const AdditionalInfoMaster = [
  {
    label: "Flight Number",
    value: "flightnumber"
  },
  {
    label: "Time of Flight",
    value: "time-of-flight"
  },
  {
    label: "Time to Pick Up",
    value: "pickup-time"
  },
  {
    label: "Name of Contact Person",
    value: "contact-person-name"
  },
  {
    label: "Contact Number",
    value: "contactnumber"
  },
  {
    label: "Contact Email",
    value: "contact-email"
  },
  {
    label: "Pick Up Point",
    value: "pickuppoint"
  },
  {
    label: "Drop off Address",
    value: "dropoffad"
  },
  {
    label: "Passport Number",
    value: "passport-number"
  },
  {
    label: "Passport Issuing Country",
    value: "passport-issue-country"
  }
];

export const CapacityMaster = [
  {
    label: "2 people (Upto 1 child)",
    description: "Capacity of 2 Adults",
    value: "2",
    adult: 2,
    child: 1
  },
  {
    label: "3 people (Upto 2 children)",
    description: "Capacity of 3 Adults",
    value: "3",
    adult: 3,
    child: 2
  },
  {
    label: "4 people (Upto 3 children)",
    description: "Capacity of 4 Adults",
    value: "4",
    adult: 4,
    child: 3
  }
];

export const SpaceMaster = [
  {
    label: "City View",
    value: "city"
  },
  {
    label: "Beach View",
    value: "beach"
  },
  {
    label: "Shower",
    value: "shower"
  },
  {
    label: "No Smoking",
    value: "smoking"
  }
];

export const InternetAccessMaster = [
  {
    label: "Free Wi-Fi in all rooms",
    value: "wifiinroom"
  },
  {
    label: "Wi-Fi in public areas",
    value: "wifipublic"
  }
];

export const AccommodationAmenitiesMaster = [
  {
    label: "ATM",
    value: "atm"
  },
  {
    label: "Concierge",
    value: "concierge"
  },
  {
    label: "Smoking Area",
    value: "smoking-area"
  },
  {
    label: "Daily Housekeeping",
    value: "daily-housekeeping"
  },
  {
    label: "Dry Cleaning",
    value: "dry-cleaning"
  },
  {
    label: "Luggage Storage",
    value: "luggage-storage"
  },
  {
    label: "Safety Deposit Boxes",
    value: "safety-deposit-boxes"
  },
  {
    label: "Currency Exchange",
    value: "currency-exchange"
  },
  {
    label: "Laundry Service",
    value: "laundry-service"
  }
];

export const AccommodationAccessMaster = [
  {
    label: "Check-In [24 hours]",
    value: "checkin"
  },
  {
    label: "Elevator",
    value: "elevator"
  },
  {
    label: "Facilities (Disabled guests)",
    value: "facilities"
  },
  {
    label: "Front Desk [24 hours]",
    value: "front-desk"
  },
  {
    label: "Pets Allowed",
    value: "pets"
  },
  {
    label: "Security [24 hours]",
    value: "security"
  },
  {
    label: "Wheelchair Accessible",
    value: "wheelchair"
  }
];

export const ActivityHourMaster = [
  {
    label: "4 AM",
    value: 4,
    category: "morning"
  },
  {
    label: "5 AM",
    value: 5,
    category: "morning"
  },
  {
    label: "6 AM",
    value: 6,
    category: "morning"
  },
  {
    label: "7 AM",
    value: 7,
    category: "morning"
  },
  {
    label: "8 AM",
    value: 8,
    category: "morning"
  },
  {
    label: "9 AM",
    value: 9,
    category: "morning"
  },
  {
    label: "10 AM",
    value: 10,
    category: "morning"
  },
  {
    label: "11 AM",
    value: 11,
    category: "morning"
  },
  {
    label: "12 PM",
    value: 12,
    category: "afternoon"
  },
  {
    label: "1 PM",
    value: 13,
    category: "afternoon"
  },
  {
    label: "2 PM",
    value: 14,
    category: "afternoon"
  },
  {
    label: "3 PM",
    value: 15,
    category: "afternoon"
  },
  {
    label: "4 PM",
    value: 16,
    category: "evening"
  },
  {
    label: "5 PM",
    value: 17,
    category: "evening"
  },
  {
    label: "6 PM",
    value: 18,
    category: "evening"
  },
  {
    label: "7 PM",
    value: 19,
    category: "evening"
  },
  {
    label: "8 PM",
    value: 20,
    category: "evening"
  },
  {
    label: "9 PM",
    value: 21,
    category: "evening"
  },
  {
    label: "10 PM",
    value: 22,
    category: "evening"
  },
  {
    label: "11 PM",
    value: 23,
    category: "evening"
  },
  {
    label: "12 AM",
    value: 0,
    category: "evening"
  },
  {
    label: "1 AM",
    value: 1,
    category: "evening"
  },
  {
    label: "2 AM",
    value: 2,
    category: "evening"
  },
  {
    label: "3 AM",
    value: 3,
    category: "evening"
  }
];

export const TransportTypeMaster = [
  {
    label: "Standard Sedan (4 Persons)",
    value: "standard-sedan-4-persons"
  },
  {
    label: "Full Size Sedan (5 Persons)",
    value: "full-size-sedan-5-persons"
  },
  {
    label: "Minivan (4 Persons)",
    value: "minivan-4-persons"
  },
  {
    label: "Minivan (8 Persons)",
    value: "minivan-8-persons"
  },
  {
    label: "Minivan (12 Persons)",
    value: "minivan-12-persons"
  },
  {
    label: "Mini Bus (15 Persons)",
    value: "mini-bus-15-persons"
  },
  {
    label: "MPV (6 Persons)",
    value: "mpv-6-persons"
  },
  {
    label: "MPV (10 Persons)",
    value: "mpv-10-persons"
  },
  {
    label: "Minivan (14 Persons)",
    value: "minivan-14-persons"
  },
  {
    label: "Minivan (18 Persons)",
    value: "minivan-18-persons"
  }
];
