import Axios from "axios";
export const OrderServices = {
  AttachCoupon: (orderId, couponCode) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/public/api/orders/${orderId}/coupons`, { couponCode })
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  DetatchCoupons: orderId => {
    return new Promise((resolve, reject) => {
      Axios.delete(`/public/api/orders/${orderId}/coupons`)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  CreateOrder: packageId => {
    return new Promise((resolve, reject) => {
      Axios.post(`/public/api/orders/?packageId=${packageId}`)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  Update: (orderId, payload, isBillingAddress = false) => {
    return new Promise((resolve, reject) => {
      Axios.put(
        `/public/api/orders/${orderId}${
          isBillingAddress === true ? "/?isBillingAddress=true" : ""
        }`,
        payload
      )
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  Place: (orderId, body, providerId = "stripe") => {
    return new Promise((resolve, reject) => {
      Axios.post(`/public/api/orders/${orderId}/place/${providerId}`, body)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  GetOrders: () => {
    return new Promise((resolve, reject) => {
      Axios.get("/api/orders")
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  CancelOrder: (_id, reason) => {
    return new Promise((resolve, reject) => {
      Axios.put(`/api/me/orders/${_id}/cancel`, {
        reason: reason
      })
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  GetMe: () => {
    return new Promise((resolve, reject) => {
      Axios.get("/api/me/orders")
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  PayRemaining: (orderId, body) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/me/orders/${orderId}/pay/balance`, body)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  UpdateStatus: (_id, action, message) => {
    return new Promise((resolve, reject) => {
      Axios.put(`/api/orders/${_id}/status?status=${action}`, {
        message: message
      })
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  UpdateAdditionalInformation: (_id, payload) => {
    return new Promise((resolve, reject) => {
      Axios.put(`/api/me/orders/${_id}/information`, payload)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  }
};
