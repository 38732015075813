import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TripbonderLinks } from "../../resources/NavigationLinks";

export default withTranslation()(function(props) {
  const { history, currentUser, t } = props;

  const links = TripbonderLinks(currentUser);

  return (
    <React.Fragment>
      <nav className="tripbonder-nav-wrapper d-none d-md-block">
        <ul className="nav flex-column pt-2 pl-2">
          {links.map(link => (
            <li
              key={link.to}
              className={`nav-item pt-2 ${
                history.location.pathname.startsWith(link.to)
                  ? "selected-item"
                  : ""
              }`}
            >
              <Link className="nav-link" to={link.to}>
                <i className={`${link.icon} pr-2`}></i>
                {t(link.title)}
                <i className={`${link.icon2} pr-2`}></i>
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </React.Fragment>
  );
});
