import {
  TRANSPORT_UPDATE,
  TRANSPORT_ADD_HIGHLIGHT,
  TRANSPORT_DELETE_HIGHLIGHT,
  TRANSPORT_TOGGLE_FEATURE,
  TRANSPORT_UPDATE_HIGHLIGHT,
  TRANSPORT_SET,
  TRANSPORT_UPDATE_LOC,
  TRANSPORT_SPLICE_BANNER
} from "../types";

const initialState = {
  _status: null,
  _id: null,
  title: "",
  description: "",
  location: "",
  geoLocation: null,
  city: null,
  country: null,
  highlights: [],
  coverImages: ["", "", "", "", ""],
  transportType: "",
  storage: "",
  maxPassenger: 4,
  travelInfo: "",
  extras: [],
  addtinfo: [],
  price: "",
  pricePerWay: "",
  otherInformation: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TRANSPORT_SET: {
      const { value, status } = action.payload;
      return Object.assign({}, value, { _status: status });
    }
    case TRANSPORT_UPDATE: {
      const { key, value } = action.payload;
      return Object.assign({}, state, { [key]: value });
    }
    case TRANSPORT_UPDATE_LOC: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        location: value.address,
        geoLocation: value.geoLocation,
        city: value.city,
        country: value.country
      });
    }
    case TRANSPORT_SPLICE_BANNER: {
      const { index, value } = action.payload;
      return Object.assign({}, state, {
        coverImages: state.coverImages.map((image, mapIndex) => {
          if (mapIndex === index) {
            return value;
          }
          return image;
        })
      });
    }
    case TRANSPORT_DELETE_HIGHLIGHT: {
      const { indexToDelete } = action.payload;
      return Object.assign({}, state, {
        highlights: state.highlights.filter(
          (highlight, index) => index !== indexToDelete
        )
      });
    }
    case TRANSPORT_UPDATE_HIGHLIGHT: {
      const { indexToUpdate, key, value } = action.payload;
      return Object.assign({}, state, {
        highlights: state.highlights.map((highlight, index) => {
          if (index === indexToUpdate) {
            return Object.assign({}, highlight, {
              [key]: value
            });
          } else {
            return highlight;
          }
        })
      });
    }
    case TRANSPORT_ADD_HIGHLIGHT: {
      return Object.assign({}, state, {
        highlights: [
          ...state.highlights,
          {
            imageId: null,
            location: "",
            geoLocation: null,
            description: ""
          }
        ]
      });
    }
    case TRANSPORT_TOGGLE_FEATURE: {
      const { key, value } = action.payload;
      return Object.assign({}, state, {
        [key]:
          state[key].indexOf(value) > -1
            ? state[key].filter(a => a !== value)
            : [...state[key], value]
      });
    }
    default: {
      return state;
    }
  }
}
