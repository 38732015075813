import { useTranslation, withTranslation } from "react-i18next";
import React from "react";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { DashboardServices } from "../../services/Dashboard.services";
import Controller from "../../store/controllers/index";
import { NETWORK_ERR_MESSAGE } from "../../resources/String";
import moment from "moment";
import { Link } from "react-router-dom";
import { GetRawFormattedPrice } from "../../resources/Helper";

function PreOrder(props) {
  const order = props.order;
  return (
    <>
      <div className="col-12 my-2 px-2">
        <div className="container pre-order">
          <div className="row">
            <div className="col-8 text-left px-4 py-4">
              <div className="location pb-1">
                <span style={{ fontSize: 13 }} className="place pr-1">
                  <i className="fa fa-map-marker" aria-hidden="true" />{" "}
                  {order.listingSnapshot.package.location}
                </span>
              </div>
              <h5 style={{ fontWeight: "bold", fontSize: 18 }}>
                {order.listingSnapshot.package.title}
              </h5>
            </div>
            {/* <div className="col-4 right">
                   <div>
                     <button className="p-2">
                       <i className="fas fa-check"></i>
                     </button>
                     <button className="p-2">
                       <i className="fas fa-times"></i>
                     </button>
                   </div>
                  </div> */}
            {/* <div className="text-left p-1 px-3 pb-2">
                   <button
                     type="button"
                     className="d-block d-md-inline px-2 btn btn-link"
                     style={{ color: "#089473" }}
                   >
                     View Trip Details
                   </button>
                   <button
                     type="button"
                     className="d-block d-md-inline px-2 btn btn-link"
                     style={{ color: "#089473" }}
                   >
                     View Order Details
                   </button>
                   <button
                     type="button"
                     className="d-block d-md-inline px-2 btn btn-link"
                     style={{ color: "#0974D2" }}
                   >
                     Contact Traveller
                   </button>
                  </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
function Work(props) {
  const { t } = useTranslation();
  const item = props.item;
  return (
    <>
      <div className="col-12 my-2 px-2">
        <div className="container work">
          <div className="row">
            <div className="col-9 text-left px-4 py-4">
              <div className="location pb-1">
                <span style={{ fontSize: 13 }} className="place pr-1">
                  <i className="fa fa-map-marker pr-1" aria-hidden="true" />
                  {item.package.location}
                </span>
              </div>
              <h6 style={{ fontWeight: "bold", fontSize: 18 }}>
                {item.package.title}
              </h6>
              {/* <div className="text-left">
                     <button
                       type="button"
                       className="d-block d-md-inline pr-4 btn btn-link pl-0"
                       style={{ color: "#089473" }}
                     >
                       View Trip Details
                     </button>
                     <button
                       type="button"
                       className="d-block d-md-inline pr-4 btn btn-link pl-0"
                       style={{ color: "#089473" }}
                     >
                       View Order Details
                     </button>
                     <button
                       type="button"
                       className="d-block d-md-inline pr-4 btn btn-link pl-0"
                       style={{ color: "#F24343" }}
                     >
                       Cancel Work
                     </button>
                    </div> */}
            </div>
            <div className="col-12 col-lg-3 right p-3">
              <Link
                to={`/dashboard/composer/${item.type}/${item._id}`}
                style={{ WebkitAppearance: "none" }}
                type="button"
                className="btn btn-success-gradient btn-block"
              >
                {t("Continue Editing")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// function Request(props) {
//   const item = props.item;
//   return (
//     <>
//       <div className="col-12 my-2">
//         <div className="container request p-0">
//           <div className="card">
//             <div className="card-body">
//               <div className="row">
//                 <div className="col-12 col-md-8">
//                   <p className="text-left username">
//                     <i className="fas fa-user pr-1"></i>
//                     {item.displayName}
//                     <span className="d-block d-md-inline pl-1">
//                       {moment(item.timestamp).format("ll")}
//                     </span>{" "}
//                   </p>
//                 </div>
//                 <div className="col-12 col-md-4">
//                   <p className="card-text text-left text-md-right">
//                     {moment(item.timestamp).format("LT")}
//                   </p>
//                 </div>
//                 <div className="col-12">
//                   <p className="card-text text-left pt-2">{item.content}</p>
//                 </div>
//               </div>
//             </div>
//             <div className="card-footer">
//               <div className="row">
//                 <div className="col-12 col-md-6 text-left px-3">
//                   <div className="location pb-1">
//                     {/* <span className="place pr-1">
//                       <i className="fa fa-map-marker" aria-hidden="true" />{" "}
//                      {item.listingId.location}
//                     </span> */}
//                   </div>
//                   <h6 style={{fontWeight: 'bold', fontSize: 18}}>{item.title}</h6>
//                 </div>
//                 {/* <div className="col-12 col-md-6 text-center text-md-right p-1">
//                   <button
//                     type="button"
//                     className="d-block d-md-inline btn btn-link"
//                     style={{ color: "#089473" }}
//                   >
//                     View Trip Details
//                   </button>
//                   <button
//                     type="button"
//                     className="d-block d-md-inline btn btn-link"
//                     style={{ color: "#089473" }}
//                   >
//                     View Order Details
//                   </button>
//                   <button
//                     type="button"
//                     className="d-block d-md-inline btn btn-link"
//                     style={{ color: "#0974D2" }}
//                   >
//                     Contact Traveller
//                   </button>
//                 </div> */}
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }
function List(props) {
  const { t } = useTranslation();
  const item = props.item;
  return (
    <>
      <div className="col-12 col-lg-6 col-xl-4">
        <div className="card list mx-auto">
          <div
            className="card-image"
            style={{
              backgroundImage: `url('${Controller.resolveAssetAddr(
                item.package.defaultCoverArtId
              )}')`
            }}
          ></div>
          <div className="card-body text-left">
            <div className="card-title">
              <div className="location pb-1">
                <span style={{ fontSize: 13 }} className="place pr-1">
                  <i className="fa fa-map-marker" aria-hidden="true" />{" "}
                  {item.package.location}
                </span>
              </div>
              <h6 style={{ fontWeight: "bold", fontSize: 18 }} className="mb-0">
                {item.package.title}
              </h6>
            </div>
            <div className="">
              <label>{t("PACKAGE TYPE")}</label>
              <div className="text-capitalize">{item.type}</div>
            </div>
          </div>
          <div className="card-footer">
            <div className="row px-3">
              {/* <div className="col-12 col-md-6 p-2 truncate">
                     <button
                       type="button"
                       className="btn btn-warning-gradient px-2 btn-block truncate"
                     >
                       Edit Package
                     </button>
                    </div> */}
              <div className="col-12 p-2 truncate">
                <Link
                  style={{ WebkitAppearance: "none" }}
                  to="/dashboard/messages"
                  type="button"
                  className="btn btn-warning-gradient px-2 btn-block truncate"
                >
                  {t("Contact Admin")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const ComposeDashboard = withTranslation()(
  class ComposeDashboard extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: true,
        newBookingsCount: 0,
        upComingOrders: 0,
        monthlyOrderAmount: 0,
        walletBalance: 0,
        pendingOrders: [],
        pendingOrdersCount: 0,
        drafts: [],
        draftsCount: 0,
        unreadMessages: [],
        unreadMessagesCount: 0,
        packagesInReview: [],
        packagesInReviewCount: 0
      };

      this.fetchSummary = this.fetchSummary.bind(this);
    }

    fetchSummary() {
      DashboardServices.GetSummary()
        .then(summary => {
          this.setState({
            isLoading: false,
            ...summary
          });

          console.log(summary);
        })
        .catch(err => {
          Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
        });
    }

    componentDidMount() {
      this.fetchSummary();
    }

    renderLoader() {
      return (
        <Fade key="package-loader" duration={350}>
          <div className="container-fluid package-loader">
            <div className="row">
              <div className="col-12 px-0">
                <div className="ph-item mh-100">
                  <div className="ph-col-12">
                    <div className="ph-picture banner"></div>
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <div className="ph-effect box"></div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 d-none d-sm-block">
                          <div className="ph-effect box"></div>
                        </div>
                        <div className="col-md-4 col-lg-3 d-none d-md-block">
                          <div className="ph-effect box"></div>
                        </div>
                        <div className="col-lg-3 d-none d-lg-block">
                          <div className="ph-effect box"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      );
    }

    render() {
      if (this.state.isLoading === true) {
        return this.renderLoader();
      }

      return (
        <>
          <Helmet>
            <title>{this.props.t("Dashboard - Tripbonder.com")}</title>
          </Helmet>
          {this.state.isLoading === false ? (
            <Fade>
              <div className="container-fluid dashboard">
                <div className="row position-relative">
                  <div className="col-12">
                    <div className="px-3 pt-3 pb-4 pb-md-3">
                      <p className="m-0" style={{ color: "#42939D" }}>
                        {this.props.t("Hello,")}{" "}
                        {this.props.local.currentUser.name}
                        {this.props.t("!")}
                      </p>
                      <h3 style={{ fontWeight: "bold" }}>
                        {this.props.t("Dashboard")}
                      </h3>
                    </div>
                  </div>
                  <div
                    className="balloon d-none d-md-block"
                    style={{
                      backgroundImage: `url('${require("../../assets/images/camp.png")}')`
                    }}
                  ></div>
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    <h5 className="mb-0">
                      <strong>{this.props.t("Summary")}</strong>
                    </h5>
                    <small>{moment().format("ll")}</small>
                  </div>
                  <div className="col-12 pt-3">
                    <div className="container stat">
                      <div className="row">
                        <div className="col-12 col-md-6 col-lg-4 col-xl-3">
                          <div className="card bg-primary">
                            <div className="card-body truncate text-center">
                              <p className="card-text truncate text-left">
                                {this.props.t("Today's booking")}
                              </p>
                              <div className="card-text text-right number">
                                {this.state.newBookingsCount}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 pt-4 pt-md-0">
                          <div className="card bg-warning">
                            <div className="card-body truncate text-center">
                              <p className="card-text truncate text-left">
                                {this.props.t("ARRIVAL")}
                                <small>
                                  {this.props.t("( Within 30 days )")}
                                </small>
                              </p>
                              <div className="card-text text-right number">
                                {this.state.upComingOrders}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4 col-xl-3 pt-4 pt-lg-0">
                          <div className="card bg-success">
                            <div className="card-body truncate text-center">
                              <p className="card-text truncate text-left">
                                {this.props.t("BOOKING AMOUNT (")}
                                {moment().format("MMM")}
                                {this.props.t(")")}
                              </p>
                              <div className="card-text text-right number">
                                {GetRawFormattedPrice(
                                  this.state.monthlyOrderAmount,
                                  "USD"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-12 col-xl-3 pt-4 pt-xl-0">
                          <div className="card bg-danger">
                            <div className="card-body truncate text-center">
                              <p className="card-text truncate text-left">
                                {this.props.t("WALLET BALANCE")}
                              </p>
                              <div className="card-text text-right number">
                                {GetRawFormattedPrice(
                                  this.state.walletBalance,
                                  "USD"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 pt-5 text-center">
                    <h5>
                      <strong>{this.props.t("Trips")}</strong>
                    </h5>
                    <hr />

                    <div className="row px-4 ">
                      <div className="col text-left">
                        <h5>
                          <strong>
                            {this.props.t("Pending Bookings (")}
                            {this.state.pendingOrdersCount}
                            {this.props.t(")")}
                          </strong>
                        </h5>
                      </div>
                      <div className="col d-none d-md-block text-right">
                        {this.state.pendingOrdersCount === 0 ? null : (
                          <Link
                            to="/dashboard/orders"
                            style={{ WebkitAppearance: "none" }}
                            type="button"
                            className="btn btn-primary-gradient px-5 py-1 btn-sm"
                          >
                            {this.props.t("View All")}
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className="row mt-4">
                      {this.state.pendingOrdersCount === 0 ? (
                        <div
                          className="row jumbotron mx-2"
                          style={{ width: "100%" }}
                        >
                          <div className="col-12 text-left">
                            <h1 className="display-4">
                              {this.props.t("No Pending Bookings")}
                            </h1>
                            {/* <p className="lead">
                             Here, you will find any customisation requests from travellers.
                             Also you can use the chat to stay connected with them.
                             </p> */}
                            <hr className="my-4" />
                            {/* <p>
                             You will get email notification when you receive new messages
                             here
                             </p> */}
                          </div>
                        </div>
                      ) : (
                        this.state.pendingOrders.map((order, index) => (
                          <PreOrder key={index} order={order} />
                        ))
                      )}
                    </div>
                    {this.state.pendingOrdersCount === 0 ? null : (
                      <Link
                        to="/dashboard/orders"
                        style={{ WebkitAppearance: "none" }}
                        type="button"
                        className="btn btn-primary-gradient btn-sm btn-block d-md-none btn-sm"
                      >
                        {this.props.t("View All")}
                      </Link>
                    )}

                    <hr />
                    <div className="row px-4 ">
                      <div className="col text-left">
                        <h5>
                          <strong>
                            {this.props.t("Drafts (")}
                            {this.state.draftsCount}
                            {this.props.t(")")}
                          </strong>
                        </h5>
                      </div>
                      {this.state.draftsCount === 0 ? null : (
                        <div className="col d-none d-md-block text-right">
                          <Link
                            style={{ WebkitAppearance: "none" }}
                            to="/dashboard/composer"
                            type="button"
                            className="btn btn-primary-gradient px-5 py-1 btn-sm"
                          >
                            {this.props.t("View All")}
                          </Link>
                        </div>
                      )}
                    </div>
                    <div className="row mt-4">
                      {this.state.draftsCount === 0 ? (
                        <div
                          className="row jumbotron mx-2"
                          style={{ width: "100%" }}
                        >
                          <div className="col-12 text-left">
                            <h1 className="display-4">
                              {this.props.t("No Drafts")}
                            </h1>
                            {/* <p className="lead">
                             Here, you will find any customisation requests from travellers.
                             Also you can use the chat to stay connected with them.
                             </p> */}
                            <hr className="my-4" />
                            {/* <p>
                             You will get email notification when you receive new messages
                             here
                             </p> */}
                          </div>
                        </div>
                      ) : (
                        this.state.drafts.map((item, index) => (
                          <Work key={index} item={item} />
                        ))
                      )}
                    </div>
                    {this.state.draftsCount === 0 ? null : (
                      <Link
                        style={{ WebkitAppearance: "none" }}
                        to="/dashboard/composer"
                        type="button"
                        className="btn btn-primary-gradient btn-sm btn-block d-md-none btn-sm"
                      >
                        {this.props.t("View All")}
                      </Link>
                    )}

                    <hr />
                    <div className="row px-4 ">
                      <div className="col text-left">
                        <h5>
                          <strong>
                            {this.props.t("Packages In Review (")}
                            {this.state.packagesInReviewCount}
                            {this.props.t(")")}
                          </strong>
                        </h5>
                      </div>
                      <div className="col d-none d-md-block text-right">
                        {this.state.packagesInReviewCount === 0 ? null : (
                          <Link
                            to="/dashboard/listings/in-review"
                            style={{ WebkitAppearance: "none" }}
                            type="button"
                            className="btn btn-primary-gradient px-5 py-1 btn-sm"
                          >
                            {this.props.t("View All")}
                          </Link>
                        )}
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-12">
                        <div className="row">
                          {this.state.packagesInReviewCount === 0 ? (
                            <div
                              className="row jumbotron mx-2"
                              style={{ width: "100%" }}
                            >
                              <div className="col-12 text-left">
                                <h1 className="display-4">
                                  {this.props.t("No Packages In Review")}
                                </h1>
                                {/* <p className="lead">
                                 Here, you will find any customisation requests from travellers.
                                 Also you can use the chat to stay connected with them.
                                 </p> */}
                                <hr className="my-4" />
                                {/* <p>
                                 You will get email notification when you receive new messages
                                 here
                                 </p> */}
                              </div>
                            </div>
                          ) : (
                            this.state.packagesInReview.map((item, index) => (
                              <List key={index} item={item} />
                            ))
                          )}
                        </div>
                        {this.state.packagesInReviewCount === 0 ? null : (
                          <div className="row">
                            <div className="col-12">
                              <Link
                                to="/dashboard/listings/in-review"
                                style={{ WebkitAppearance: "none" }}
                                type="button"
                                className="btn btn-primary-gradient btn-sm btn-block d-md-none btn-sm"
                              >
                                {this.props.t("View All")}
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          ) : (
            this.renderLoader()
          )}
        </>
      );
    }
  }
);

export default connect(state => ({
  local: state.local
}))(ComposeDashboard);
