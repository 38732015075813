import Axios from "axios";
export const ReviewServices = {
  Compose: payload => {
    return new Promise((resolve, reject) => {
      Axios.post("/api/me/reviews", payload)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  Update: (id, payload) => {
    return new Promise((resolve, reject) => {
      Axios.put(`/api/me/reviews/${id}`, payload)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  Delete: id => {
    return new Promise((resolve, reject) => {
      Axios.delete(`/api/me/reviews/${id}`)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  Vote: (id, action) => {
    return new Promise((resolve, reject) => {
      Axios.put(`/api/me/reviews/${id}/vote/${action}`)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  }
};
