import React, { Component } from "react";
import { Fade, Slide } from "react-reveal";

export default class BottomSlider extends Component {
  constructor(props) {
    super(props);
    this.toggleView = this.toggleView.bind(this);
    this.selfRef = React.createRef();
  }

  toggleView(e) {
    const { onToggleView } = this.props;
    if (e && e.keyCode === 27) {
      onToggleView && onToggleView();
    }
  }

  componentDidMount() {
    this.selfRef.current.addEventListener("keydown", this.toggleView);
  }

  componentWillUnmount() {
    this.selfRef.current.removeEventListener("keydown", this.toggleView);
  }

  render() {
    const zIndexBase =
      (this.props.level && parseInt(this.props.level) * 2000) || 1000;
    return (
      <div ref={this.selfRef} tabIndex="7000">
        <Fade when={this.props.isOpen} duration={300} collapse>
          <div
            className={`bottom-slider bs-backdrop${(this.props.darker &&
              this.props.darker === true &&
              " darker") ||
              ""}`}
            style={{ zIndex: zIndexBase + 1 }}
          ></div>
        </Fade>
        <Slide when={this.props.isOpen} bottom duration={450} collapse>
          <div
            className="bottom-slider content-view"
            style={{ zIndex: zIndexBase + 1 }}
          >
            {this.props.children}
          </div>
        </Slide>
      </div>
    );
  }
}
