import { withTranslation } from "react-i18next";
import React from "react";
import { Fade } from "react-reveal";
import controllers from "../../store/controllers";
import queryString from "query-string";
import { AuthServices } from "../../services/Auth";
import { NETWORK_ERR_MESSAGE } from "../../resources/String";
const PasswordReset = withTranslation()(
  class PasswordReset extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        newPassword: "",
        reNewPassword: "",
        isFieldBusy: false
      };

      this.updateState = this.updateState.bind(this);
      this.handleReset = this.handleReset.bind(this);
      this.FieldBusy = this.FieldBusy.bind(this);
    }
    updateState(key, value) {
      this.setState({
        [key]: value
      });
    }
    FieldBusy() {
      this.setState({
        isFieldBusy: true
      });
    }
    handleReset(e) {
      e.preventDefault();

      const token = queryString.parse(window.location.search).token || null;
      if (token === null) {
        controllers.setGlobalError("Invalid request", true);
      } else if (!this.state.newPassword || !this.state.reNewPassword) {
        controllers.setGlobalError("Please fill all the fields", true);
      } else if (this.state.newPassword.length < 5) {
        controllers.setGlobalError(
          "Password must be at least 5 characters long",
          true
        );
      } else if (this.state.newPassword !== this.state.reNewPassword) {
        controllers.setGlobalError("Entered password does not match", true);
      } else {
        this.FieldBusy();
        controllers.setGlobalLoader("Resetting Password");
        setTimeout(() => {
          AuthServices.RecoveryExecute(token, this.state.newPassword)
            .then(() => {
              controllers.setGlobalSuccess("Password reset successfully", true);
            })
            .catch(err => {
              controllers.setGlobalError(
                (err.data && err.data.message) || NETWORK_ERR_MESSAGE,
                true
              );
            });
        }, 800);
      }
    }
    render() {
      return (
        <Fade duration={300}>
          <div className="container reset-password-wrapper">
            <div className="row">
              <div className="col-12 pt-4">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">
                      {this.props.t("Reset Password")}
                    </h5>
                    <form onSubmit={this.handleReset}>
                      <div className="form-group">
                        <label htmlFor="new-password">
                          {this.props.t("New Password")}
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          disabled={this.state.isFieldBusy}
                          id="new-password"
                          minLength="5"
                          placeholder={this.props.t(
                            "Must be atleast 5 characters"
                          )}
                          onChange={e =>
                            this.updateState("newPassword", e.target.value)
                          }
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="confirm-password">
                          {this.props.t("Confirm Password")}
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          disabled={this.state.isFieldBusy}
                          id="confirm-password"
                          minLength="5"
                          placeholder={this.props.t(
                            "Must be atleast 5 characters"
                          )}
                          onChange={e =>
                            this.updateState("reNewPassword", e.target.value)
                          }
                        />
                      </div>
                      <div className="text-right d-none d-sm-block">
                        <button
                          type="submit"
                          className="btn btn-success-gradient"
                        >
                          {this.props.t("Reset Password")}
                        </button>
                      </div>
                      <div className="d-sm-none">
                        <button
                          type="submit"
                          className="btn btn-success-gradient btn-block"
                        >
                          {this.props.t("Reset Password")}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      );
    }
  }
);

export default PasswordReset;
