import Axios from "axios";
export const WishlistServices = {
  GetWishlist: () => {
    return new Promise((resolve, reject) => {
      Axios.get("/public/api/wishlist")
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  AddToWishlist: payload => {
    return new Promise((resolve, reject) => {
      Axios.post("/public/api/wishlist", payload)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  RempoveWishlist: id => {
    return new Promise((resolve, reject) => {
      Axios.delete(`/public/api/wishlist/${id}`)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  }
};
