import Axios from "axios";
export const AccomodationServices = {
  CreateAccomodation: title => {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/listings/accomodation?name=${encodeURI(title)}`.trim())
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  }
};
