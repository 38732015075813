import { withTranslation } from "react-i18next";
import React from "react";
import { Modal } from "reactstrap";
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-reveal";
import controllers from "./../../store/controllers";
import { ItineraryServices } from "../../services/Itinerary.services";
import { TicketServices } from "../../services/Ticket.services";
import { AccomodationServices } from "../../services/Accomodation.services";
import { TransportServices } from "../../services/Transport.services";
import { ListingServices } from "../../services/Listing.services";
import { NETWORK_ERR_MESSAGE } from "../../resources/String";
import { Helmet } from "react-helmet";
import { SanitizePackageTypes } from "../../resources/Helper";
const Composer = withTranslation()(
  class Composer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        name: "",
        numberOfDays: 2
      };

      this.getStarted = this.getStarted.bind(this);
    }

    getStarted(e) {
      e.preventDefault();
      const { mode, history } = this.props;
      const { name, numberOfDays } = this.state;
      switch (mode) {
        case "itinerary": {
          if (name === "" || numberOfDays < 1) {
            controllers.setGlobalError("Name is required", true);

            return;
          }

          controllers.setGlobalLoader("Drafting...", false);
          setTimeout(() => {
            ItineraryServices.CreateItinerary(name, numberOfDays)
              .then(product => {
                history.push("/dashboard/composer/itinerary/" + product._id);
              })
              .catch(err => {
                controllers.setGlobalError(
                  (err.data && err.data.message) || NETWORK_ERR_MESSAGE,
                  true
                );
              });
          }, 300);

          break;
        }
        case "ticket": {
          if (name === "") {
            controllers.setGlobalError("Name is required", true);
            return;
          }
          controllers.setGlobalLoader("Drafting...", false);
          setTimeout(() => {
            TicketServices.CreateTicket(name)
              .then(product => {
                history.push("/dashboard/composer/ticket/" + product._id);
                setTimeout(() => {
                  controllers.clearGlobalMessage();
                }, 800);
              })
              .catch(err => {
                controllers.setGlobalError(
                  (err.data && err.data.message) || NETWORK_ERR_MESSAGE,
                  true
                );
              });
          }, 300);

          break;
        }
        case "accomodation": {
          if (name === "") {
            controllers.setGlobalError("Name is required", true);
            return;
          }
          controllers.setGlobalLoader("Drafting...", false);
          setTimeout(() => {
            AccomodationServices.CreateAccomodation(name)
              .then(product => {
                history.push("/dashboard/composer/accomodation/" + product._id);
                setTimeout(() => {
                  controllers.clearGlobalMessage();
                }, 800);
              })
              .catch(err => {
                controllers.setGlobalError(
                  (err.data && err.data.message) || NETWORK_ERR_MESSAGE,
                  true
                );
              });
          }, 300);

          break;
        }
        case "transport": {
          if (name === "") {
            controllers.setGlobalError("Name is required", true);
            return;
          }
          controllers.setGlobalLoader("Drafting...", false);
          setTimeout(() => {
            TransportServices.CreateTransport(name)
              .then(product => {
                history.push("/dashboard/composer/transport/" + product._id);
                setTimeout(() => {
                  controllers.clearGlobalMessage();
                }, 800);
              })
              .catch(err => {
                controllers.setGlobalError(
                  (err.data && err.data.message) || NETWORK_ERR_MESSAGE,
                  true
                );
              });
          }, 300);

          break;
        }
        default: {
          return;
        }
      }
    }

    render() {
      const { mode, toggle } = this.props;

      let bannerImage = "";
      let title = "";
      let formComponent = null;

      switch (mode) {
        case "itinerary": {
          bannerImage = require("../../assets/images/f1.jpg");
          title = "Create Itinerary";
          formComponent = (
            <React.Fragment>
              <label htmlFor="itinerary-name">
                {this.props.t("ITINERARY NAME")}
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                id="itinerary-name"
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                value={this.state.name}
                onChange={e => this.setState({ name: e.target.value })}
                placeholder={this.props.t("Enter Name")}
                ref={ref => ref && ref.focus()}
              />

              {/* <label htmlFor="no-of-days" className="pt-2">
               NO. OF DAYS
               </label>
               <select
               id="no-of-days"
               value={this.state.numberOfDays}
               onChange={e => this.setState({ numberOfDays: e.target.value })}
               className="form-control form-control-sm"
               >
               {new Array(62).fill(null).map((item, index) => (
                 <option key={index} value={index + 1}>{`${index + 1} Day${
                   index > 0 ? "s" : ""
                 }`}</option>
               ))}
               </select> */}
            </React.Fragment>
          );

          break;
        }
        case "ticket": {
          bannerImage = require("../../assets/images/f2.jpg");
          title = "Create Ticket";
          formComponent = (
            <React.Fragment>
              <label htmlFor="itinerary-name">
                {this.props.t("TICKET NAME")}
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                value={this.state.name}
                onChange={e => this.setState({ name: e.target.value })}
                id="itinerary-name"
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder={this.props.t("Enter Name")}
                ref={ref => ref && ref.focus()}
              />
            </React.Fragment>
          );

          break;
        }
        case "accomodation": {
          bannerImage = require("../../assets/images/f1.jpg");
          title = "Create Accommodation";
          formComponent = (
            <React.Fragment>
              <label htmlFor="itinerary-name">
                {this.props.t("ACCOMMODATION NAME")}
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                value={this.state.name}
                onChange={e => this.setState({ name: e.target.value })}
                id="itinerary-name"
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder={this.props.t("Enter Name")}
                ref={ref => ref && ref.focus()}
              />
            </React.Fragment>
          );

          break;
        }
        case "transport": {
          bannerImage = require("../../assets/images/f4.jpg");
          title = "Create Transport";
          formComponent = (
            <React.Fragment>
              <label htmlFor="itinerary-name">
                {this.props.t("TRANSPORT NAME")}
              </label>
              <input
                type="text"
                className="form-control form-control-sm"
                value={this.state.name}
                onChange={e => this.setState({ name: e.target.value })}
                id="itinerary-name"
                aria-label="Small"
                aria-describedby="inputGroup-sizing-sm"
                placeholder={this.props.t("Enter Name")}
                ref={ref => ref && ref.focus()}
              />
            </React.Fragment>
          );

          break;
        }
        default: {
          return null;
        }
      }

      return (
        <React.Fragment>
          <div
            className="col-12 header text-right"
            style={{
              background: `linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,.6)), url(${bannerImage})`
            }}
          >
            <button className="pt-3" onClick={toggle} aria-label="Close">
              <i className="far fa-times-circle"></i>
            </button>
            <div className="text-left pt-4">
              <h6 className="pt-4">{this.props.t("Getting Started")}</h6>
              <h4 className="pb-2">{this.props.t(title)}</h4>
            </div>
          </div>
          <div className="modal-body col-12">
            <form onSubmit={this.getStarted}>
              {formComponent}
              <div className="text-right pt-4">
                <button type="submit" className="btn btn-success-gradient px-5">
                  {this.props.t("Get Started")}
                </button>
              </div>
            </form>
          </div>
        </React.Fragment>
      );
    }
  }
);

const ComposeDashboard = withTranslation()(
  class ComposeDashboard extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isComposerOpen: false,
        composerMode: null,
        drafts: [],
        isReady: false,
        draftToDelete: -1,
        draftTitleToDelete: "Draft",
        deleteConfirmationText: ""
      };

      this.draftDeleteConfTextBoxRef = React.createRef();

      this.toggleComposer = this.toggleComposer.bind(this);
      this.createItinerary = this.createItinerary.bind(this);
      this.createAccomodation = this.createAccomodation.bind(this);
      this.createTicket = this.createTicket.bind(this);
      this.createTransport = this.createTransport.bind(this);
      this.promptDraftDelete = this.promptDraftDelete.bind(this);
      this.closeDeletePrompt = this.closeDeletePrompt.bind(this);
      this.processDraftRemoval = this.processDraftRemoval.bind(this);
    }

    promptDraftDelete(id, title) {
      this.setState(
        {
          draftToDelete: id,
          deleteConfirmationText: "",
          draftTitleToDelete: title
        },

        () =>
          setTimeout(() => {
            this.draftDeleteConfTextBoxRef &&
              this.draftDeleteConfTextBoxRef.current &&
              this.draftDeleteConfTextBoxRef.current.focus();
          }, 200)
      );
    }

    closeDeletePrompt() {
      this.setState({
        draftToDelete: -1,
        deleteConfirmationText: "",
        draftTitleToDelete: "Draft"
      });
    }

    processDraftRemoval() {
      const { draftToDelete, deleteConfirmationText } = this.state;
      if (draftToDelete && deleteConfirmationText === "DELETE") {
        controllers.setGlobalLoader("Deleting...", false);
        ListingServices.Delete(draftToDelete)
          .then(response => {
            setTimeout(() => {
              this.setState(
                {
                  drafts: this.state.drafts.filter(
                    d => d._id !== draftToDelete
                  ),

                  draftToDelete: -1,
                  deleteConfirmationText: "",
                  draftTitleToDelete: "Draft"
                },

                () => controllers.clearGlobalMessage()
              );
            }, 1200);
          })
          .catch(err => {
            controllers.setGlobalError(err.message, true);
          });
      }
    }

    toggleComposer() {
      this.setState({
        isComposerOpen: !this.state.isComposerOpen
      });
    }

    createItinerary() {
      this.setState({
        isComposerOpen: true,
        composerMode: "itinerary"
      });
    }
    createTicket() {
      this.setState({
        isComposerOpen: true,
        composerMode: "ticket"
      });
    }
    createAccomodation() {
      this.setState({
        isComposerOpen: true,
        composerMode: "accomodation"
      });
    }
    createTransport() {
      this.setState({
        isComposerOpen: true,
        composerMode: "transport"
      });
    }

    loadDrafts() {
      ListingServices.GetListings("draft")
        .then(items => {
          this.setState({
            drafts: items,
            isReady: true
          });
        })
        .catch(err => {
          this.setState({
            isReady: false
          });
        });
    }

    componentDidMount() {
      setTimeout(() => {
        this.loadDrafts();
      }, 300);
    }

    render() {
      const {
        isComposerOpen,
        composerMode,
        isReady,
        drafts,
        draftToDelete,
        deleteConfirmationText,
        draftTitleToDelete
      } = this.state;
      const { history, user } = this.props;
      const blankImage = require("./../../assets/images/blank-camera.png");
      return (
        <React.Fragment>
          <Helmet>
            <title>{this.props.t("Compose Dashboard - Tripbonder.com")}</title>
          </Helmet>
          <Fade duration={300}>
            <div className="container-fluid">
              {drafts.length > 0 ? (
                <Zoom duration={300}>
                  <div className="row compose-dashboard-head m-0 m-md-2">
                    <div className="col-12">
                      <div className="container">
                        <div className="row">
                          <div className="col-12 head text-center pt-4 pb-2">
                            <div className="">
                              <i className="fab fa-envira"></i>
                            </div>
                            <label>
                              {this.props.t("Continue Where You Left Off")}
                            </label>
                          </div>
                          {drafts.map((draft, index) => {
                            return (
                              <div
                                key={index}
                                className="col-12 col-md-6 col-xl-3 pt-4 draft-wrapper"
                              >
                                <div
                                  className="image-wrapper"
                                  style={{
                                    backgroundImage: `url('${
                                      draft.package.defaultCoverArtId
                                        ? controllers.resolveAssetAddr(
                                            draft.package.defaultCoverArtId
                                          )
                                        : blankImage
                                    }')`
                                  }}
                                >
                                  <div className="h-100 button-wrapper">
                                    <button
                                      onClick={() =>
                                        this.promptDraftDelete(
                                          draft._id,
                                          draft.package.title
                                        )
                                      }
                                    >
                                      <i className="fas fa-trash"></i>
                                    </button>
                                    <Link
                                      to={`/dashboard/composer/${draft.type}/${draft._id}`}
                                      className="btn btn-warning-gradient px-5"
                                    >
                                      {this.props.t("Continue")}
                                    </Link>
                                  </div>
                                </div>
                                <div className="text-center mt-2">
                                  <h6 className="">{draft.package.title}</h6>
                                  <span className="text-capitalize">
                                    {this.props.t(
                                      SanitizePackageTypes(draft.type)
                                    )}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </Zoom>
              ) : null}

              <div className="row">
                <div className="col-12 text-center my-3">
                  <p className="lead m-0">
                    {this.props.t("Let's start,")} {user.name}
                    {this.props.t("!")}
                  </p>
                  <h3>{this.props.t("What are we creating?")}</h3>
                </div>
              </div>
              <div className="row new-trip">
                <div className=" col-12 col-md-6 p-2">
                  <div
                    className="p-4 options"
                    style={{
                      background: `linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,.6)), url(${require("../../assets/images/f1.jpg")})`
                    }}
                  >
                    <div>
                      <p className="h3">
                        <b>{this.props.t("ITINERARY")}</b>
                      </p>
                      <p style={{ fontSize: 15 }}>
                        {this.props.t(
                          "Use Itinerary to create trip having multiple points of interests, as well as places to stay and meals. You can create multiple days, single day or even single tour using this tool"
                        )}
                      </p>
                    </div>
                    <div className="text-right">
                      <button
                        disabled={isReady === false}
                        onClick={this.createItinerary}
                        type="button"
                        className="btn btn-warning-gradient btn-sm px-5"
                      >
                        {isReady
                          ? this.props.t("Create")
                          : this.props.t("Please wait...")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" col-12 col-md-6 p-2">
                  <div
                    className="p-4 options"
                    style={{
                      background: `linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,.6)), url(${require("../../assets/images/f2.jpg")})`
                    }}
                  >
                    <div>
                      <p className="h3">
                        <b>{this.props.t("TICKETS & ENTRANCE FEES")}</b>
                      </p>
                      <p style={{ fontSize: 15 }}>
                        {this.props.t(
                          "Use Tickets & Entrance Fees to create tickets for point of interest or entrance fees for adventures. Examples are Ticket for Disneyland or Entrance Fees to National Parks"
                        )}
                      </p>
                    </div>
                    <div className="text-right">
                      <button
                        disabled={isReady === false}
                        type="button"
                        onClick={this.createTicket}
                        className="btn btn-warning-gradient btn-sm px-5"
                      >
                        {isReady
                          ? this.props.t("Create")
                          : this.props.t("Please wait...")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" col-12 col-md-6 p-2">
                  <div
                    className="p-4 options"
                    style={{
                      background: `linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,.6)), url(${require("../../assets/images/f3.png")})`
                    }}
                  >
                    <div>
                      <p className="h3">
                        <b>{this.props.t("ACCOMMODATIONS")}</b>
                      </p>
                      <p style={{ fontSize: 15 }}>
                        {this.props.t(
                          "Use Accommodations when you have places to stay to offer. You can create booking for hotels, motels or even private home using this tool"
                        )}
                      </p>
                    </div>
                    <div className="text-right">
                      <button
                        disabled={isReady === false}
                        type="button"
                        onClick={this.createAccomodation}
                        className="btn btn-warning-gradient btn-sm px-5"
                      >
                        {isReady
                          ? this.props.t("Create")
                          : this.props.t("Please wait...")}
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" col-12 col-md-6 p-2">
                  <div
                    className="p-4 options"
                    style={{
                      background: `linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,.6)), url(${require("../../assets/images/f4.jpg")})`
                    }}
                  >
                    <div>
                      <p className="h3">
                        <b>{this.props.t("TRANSPORT")}</b>
                      </p>
                      <p style={{ fontSize: 15 }}>
                        {this.props.t(
                          "Use Transport when you have transportations to offer. You can offer your Sedan, MPV, or Minibus for booking using this tool"
                        )}
                      </p>
                    </div>
                    <div className="text-right">
                      <button
                        disabled={isReady === false}
                        type="button"
                        onClick={this.createTransport}
                        className="btn btn-warning-gradient btn-sm px-5"
                      >
                        {isReady
                          ? this.props.t("Create")
                          : this.props.t("Please wait...")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>

          <Modal isOpen={draftToDelete !== -1} toggle={this.closeDeletePrompt}>
            <div className="modal-content delete-draft-modal">
              <form
                onSubmit={e => {
                  e.preventDefault();
                  this.processDraftRemoval();
                }}
              >
                <div className="modal-header truncate">
                  <h5 className="modal-title truncate">{`Delete '${draftTitleToDelete}'`}</h5>
                  <button
                    type="button"
                    className="close"
                    onClick={this.closeDeletePrompt}
                  >
                    <span aria-hidden="true">{this.props.t("\xD7")}</span>
                  </button>
                </div>
                <div className="modal-body">
                  <b>
                    {this.props.t(
                      "Note: You won't be able to undo this action."
                    )}
                  </b>
                  {this.props.t("Please type in '")}
                  <b>{this.props.t("DELETE")}</b>
                  {this.props.t("' in the box below to continue.")}
                  <div className="form-group mt-3">
                    <input
                      ref={this.draftDeleteConfTextBoxRef}
                      value={deleteConfirmationText}
                      type="text"
                      maxLength={10}
                      onChange={e =>
                        this.setState({
                          deleteConfirmationText: e.target.value.toUpperCase()
                        })
                      }
                      placeholder={this.props.t("e.g. 'DELETE'")}
                      className="form-control form-control-sm"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default btn-sm"
                    data-dismiss="modal"
                    onClick={this.closeDeletePrompt}
                  >
                    {this.props.t("Cancel")}
                  </button>
                  <button
                    disabled={deleteConfirmationText !== "DELETE"}
                    type="submit"
                    className="btn btn-danger btn-sm"
                  >
                    {this.props.t("DELETE")}
                  </button>
                </div>
              </form>
            </div>
          </Modal>

          <Modal
            isOpen={isComposerOpen}
            centered={true}
            toggle={this.toggleComposer}
            className="itinerary-setup"
          >
            <Composer
              mode={composerMode}
              toggle={this.toggleComposer}
              history={history}
            />
          </Modal>
        </React.Fragment>
      );
    }
  }
);

export default ComposeDashboard;
