import {
  ACCOMODATION_UPDATE,
  ACCOMODATION_ADD_HIGHLIGHT,
  ACCOMODATION_DELETE_HIGHLIGHT,
  ACCOMODATION_UPDATE_HIGHLIGHT,
  ACCOMODATION_TOGGLE_FEATURE,
  ACCOMODATION_TOGGLE_ROOM_FEATURES,
  ACCOMODATION_ROOMS_ADD,
  ACCOMODATION_ROOMS_DELETE,
  ACCOMODATION_ROOMS_UPDATE,
  ACCOMODATION_UPDATE_LOC,
  ACCOMODATION_SPLICE_BANNER,
  ACCOMODATION_SET
} from "../types";

export class AccomodationController {
  store = null;

  constructor(_store) {
    this.store = _store;
  }

  setAccomodationEditorContext(value, status) {
    this.store.dispatch({
      type: ACCOMODATION_SET,
      payload: {
        value,
        status
      }
    });
  }

  updateAccomodation(key, value) {
    this.store.dispatch({
      type: ACCOMODATION_UPDATE,
      payload: {
        value,
        key
      }
    });
  }

  addHighlight() {
    this.store.dispatch({
      type: ACCOMODATION_ADD_HIGHLIGHT
    });
  }

  updateHighlight(indexToUpdate, key, value) {
    this.store.dispatch({
      type: ACCOMODATION_UPDATE_HIGHLIGHT,
      payload: {
        indexToUpdate,
        key,
        value
      }
    });
  }
  updateAccomodationLocation(locationData) {
    this.store.dispatch({
      type: ACCOMODATION_UPDATE_LOC,
      payload: {
        value: locationData
      }
    });
  }

  replaceAccomodationBanner(indexToSplice, imageId) {
    this.store.dispatch({
      type: ACCOMODATION_SPLICE_BANNER,
      payload: {
        index: indexToSplice,
        value: imageId
      }
    });
  }

  deleteHighlight(indexToDelete) {
    this.store.dispatch({
      type: ACCOMODATION_DELETE_HIGHLIGHT,
      payload: {
        indexToDelete
      }
    });
  }

  addDetailsHighlight() {
    this.store.dispatch({
      type: ACCOMODATION_ROOMS_ADD
    });
  }

  updateDetailsHighlight(indexToUpdate, key, value) {
    this.store.dispatch({
      type: ACCOMODATION_ROOMS_UPDATE,
      payload: {
        indexToUpdate,
        key,
        value
      }
    });
  }

  deleteDetailsHighlight(indexToDelete) {
    this.store.dispatch({
      type: ACCOMODATION_ROOMS_DELETE,
      payload: {
        indexToDelete
      }
    });
  }

  toggleFeature(key, value) {
    this.store.dispatch({
      type: ACCOMODATION_TOGGLE_FEATURE,
      payload: {
        value,
        key
      }
    });
  }

  toggleRoomFeature(indexToUpdate, key, value) {
    this.store.dispatch({
      type: ACCOMODATION_TOGGLE_ROOM_FEATURES,
      payload: {
        indexToUpdate,
        value,
        key
      }
    });
  }
}
