import {
  CHAT_MSG_PUSH,
  CHAT_THREAD_CLOSE,
  CHAT_ATTACH_INFO,
  CHAT_CLEAR_CONVO,
  CHAT_ON_SELECT_THREAD_WIN,
  CHAT_V2_START_CONVERSATION,
  CHAT_V2_INIT_CONVERSATION,
  CHAT_V2_UPDATE_MSG_STATUS,
  CHAT_POPULATE_ALL,
  CHAT_V2_UPDATE_LAST_ACTIVE_ON,
  CHAT_V2_RESET_READ_COUNT,
  CHAT_V2_PUSH_CONVERSATION,
  CHAT_V2_UPDATE_CONVERSATION,
  CHAT_V2_OPEN_MINI_CONVERSATION
} from "../types";
import moment from "moment";

const initialState = {
  conversations: [],
  hasInitialized: false,

  selectedPackageTitle: null,
  miniConversations: [],

  threads: [],
  messages: [],
  displayName: null,
  emailAddress: null,
  selectedThreadId: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHAT_V2_PUSH_CONVERSATION:
    case CHAT_V2_START_CONVERSATION: {
      const { item } = action.payload;

      return Object.assign({}, state, {
        conversations: [
          ...[item].map((c, i) => {
            c.key = moment().valueOf() + (i + 1);
            c.isMiniWindowOpened = action.type === CHAT_V2_START_CONVERSATION;
            return c;
          }),
          ...state.conversations
        ]
      });
    }
    case CHAT_V2_OPEN_MINI_CONVERSATION: {
      const { threadId } = action.payload;
      return Object.assign({}, state, {
        conversations: state.conversations.map(c => {
          if (c._id === threadId) {
            return Object.assign({}, c, {
              isMiniWindowOpened: true,
              hasInitialized: false
            });
          }
          return c;
        })
      });
    }
    case CHAT_V2_INIT_CONVERSATION: {
      const { item, id } = action.payload;
      return Object.assign({}, state, {
        conversations: state.conversations.map(c => {
          if (c._id === id) {
            return Object.assign({}, c, item, { hasInitialized: true });
          }
          return c;
        })
      });
    }
    case CHAT_V2_UPDATE_CONVERSATION: {
      const { item, threadId } = action.payload;
      return Object.assign({}, state, {
        conversations: state.conversations.map(c => {
          if (c._id === threadId) {
            return Object.assign({}, c, item);
          }
          return c;
        })
      });
    }
    case CHAT_MSG_PUSH: {
      const { threadId, messages } = action.payload;
      return Object.assign({}, state, {
        conversations: state.conversations.map(c => {
          if (c._id === threadId) {
            return Object.assign({}, c, {
              messages: [...c.messages, ...messages]
            });
          }
          return c;
        })
      });
    }
    case CHAT_V2_UPDATE_MSG_STATUS: {
      const { threadId, sortIdentifier, status } = action.payload;
      return Object.assign({}, state, {
        conversations: state.conversations.map(c => {
          if (c._id === threadId) {
            return Object.assign({}, c, {
              messages: c.messages.map(m => {
                if (m.sortIdentifier === sortIdentifier) {
                  return Object.assign({}, m, {
                    status: status
                  });
                }
                return m;
              })
            });
          }
          return c;
        })
      });
    }
    case CHAT_V2_UPDATE_LAST_ACTIVE_ON: {
      const { threadId, lastActiveOn } = action.payload;
      return Object.assign({}, state, {
        conversations: state.conversations.map(c => {
          if (c._id === threadId) {
            return Object.assign({}, c, {
              lastActiveOn
            });
          }
          return c;
        })
      });
    }
    case CHAT_V2_RESET_READ_COUNT: {
      const { threadId, chatUserId, value } = action.payload;
      return Object.assign({}, state, {
        conversations: state.conversations.map(c => {
          if (c._id === threadId) {
            return Object.assign({}, c, {
              participants: c.participants.map(p => {
                if (p.chatUserId === chatUserId) {
                  return Object.assign({}, p, {
                    unreadMessages: value
                  });
                }
                return p;
              })
            });
          }
          return c;
        })
      });
    }
    case CHAT_THREAD_CLOSE: {
      const { threadId } = action.payload;
      return Object.assign({}, state, {
        conversations: state.conversations.map(c => {
          if (c._id === threadId) {
            return Object.assign({}, c, {
              isMiniWindowOpened: false
            });
          }
          return c;
        })
      });
    }
    case CHAT_POPULATE_ALL: {
      const { threads } = action.payload;
      return Object.assign({}, state, {
        hasInitialized: true,
        conversations: [
          ...state.conversations,
          ...threads.map((t, i) => {
            return Object.assign({}, t, {
              key: moment().valueOf() + (i + 1),
              isMiniWindowOpened: false,
              hasInitialized: false,
              messages: []
            });
          })
        ]
      });
    }

    case CHAT_ON_SELECT_THREAD_WIN: {
      const { selectedThreadId } = action.payload;
      return Object.assign({}, state, {
        selectedThreadId
      });
    }
    case CHAT_ATTACH_INFO: {
      const {
        threadId,
        chatUserId,
        displayName,
        emailAddress
      } = action.payload;
      return Object.assign({}, state, {
        displayName,
        emailAddress,
        conversations: state.conversations.map(c => {
          if (c._id === threadId) {
            return Object.assign({}, c, {
              participants: c.participants.map(p => {
                if (p.chatUserId === chatUserId) {
                  return Object.assign({}, p, {
                    displayName,
                    emailAddress
                  });
                }
                return p;
              })
            });
          }
          return c;
        })
      });
    }
    case CHAT_CLEAR_CONVO: {
      return Object.assign({}, initialState);
    }
    default: {
      return state;
    }
  }
}
