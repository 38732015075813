import { withTranslation } from "react-i18next";
import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { Modal } from "reactstrap";
import Trip from "../../components/listing/Trip";
import { Helmet } from "react-helmet";
import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import controllers from "../../store/controllers";
import SearchBar from "./../../components/SearchBar/SearchBarInput";
import InfiniteScroll from "../../components/InfiniteScroll";
import ReactGA from "react-ga";
import SliderValueDisplay from "../../components/listing/SliderValueDisplay";
import { SanitizePackageTypes } from "../../resources/Helper";

const HashTagFilter = (selectedTags, dataSource) => {
  if (selectedTags.length > 0) {
    return dataSource.filter(listing => {
      const tags = listing.package.tags || [];
      return tags.some(tag => selectedTags.indexOf(tag) > -1);
    });
  }
  return dataSource;
};

const AirbnbSlider = withStyles({
  root: {
    color: "#3a8589",
    height: 3,
    padding: "13px 0"
  },

  thumb: {
    height: 27,
    width: 27,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    marginTop: -12,
    marginLeft: -13,
    boxShadow: "#ebebeb 0px 2px 2px",
    "&:focus,&:hover,&$active": {
      boxShadow: "#ccc 0px 2px 3px 1px"
    },

    "& .bar": {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1
    },

    "& .label-display": {
      bottom: -23,
      fontSize: 12,
      position: "absolute",
      textAlign: "center",
      whiteSpace: "nowrap"
    }
  },

  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },

  track: {
    height: 3
  },

  rail: {
    color: "#d8d8d8",
    opacity: 1,
    height: 3
  }
})(Slider);
function AirbnbThumbComponent(props) {
  // const valueIndex = parseInt(props["data-index"]);
  // const valueMax = parseInt(props["aria-valuemax"]);
  // const valueNow = parseInt(props["aria-valuenow"]);
  // const maxPlusLabel = valueIndex === 1 && valueNow === valueMax ? "+" : null;
  return (
    <span {...props}>
      {/* <span className="label-display">
             {GetFormattedPrice(
               props["aria-valuenow"],
               controllers.getStore().getState().local.selectedCurrency
             )}
             {maxPlusLabel ? " + " : null}
            </span> */}
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  );
}
const Filterpage = withTranslation()(
  class Filterpage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isFilterOpen: false,
        filterResults: []
      };

      this.useUrlState = this.useUrlState.bind(this);

      this.toggleFilter = this.toggleFilter.bind(this);
      this.resetFilters = this.resetFilters.bind(this);
      this.updatePriceRange = this.updatePriceRange.bind(this);
      this.toggleSelectedCategory = this.toggleSelectedCategory.bind(this);
      this.changeDaysRange = this.changeDaysRange.bind(this);
      this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    }

    useUrlState() {
      const defaultState = {
        address: "",
        lat: null,
        lon: null,
        city: null,
        country: null,
        theme: undefined,
        type: undefined,
        _preset: undefined
      };

      const currentQuery = queryString.parseUrl(window.location.href, {
        parseBooleans: true,
        parseNumbers: true
      });

      return Object.assign(defaultState, currentQuery.query);
    }
    toggleFilter() {
      controllers.filter.toggleMiniDialog();
    }
    resetFilters() {
      const { local } = this.props;
      controllers.filter.reset([
        local.selectedCurrency.minPurchasePower,
        local.selectedCurrency.maxPurchasePower
      ]);
    }
    updatePriceRange(e, value) {
      controllers.filter.updateFilter("priceRange", value);
    }
    changeDaysRange(value) {
      controllers.filter.updateFilter("dayRange", value);
    }

    toggleSelectedCategory(value) {
      controllers.filter.togglePackageCategory(value);
    }
    switchPackageTab(value) {
      controllers.filter.setPackage(value);
    }

    loadMore() {
      controllers.filter.refreshFilter(this.props.options.selectedTab);
    }

    handleCurrencyChange() {
      controllers.filter.refreshFilter();
    }

    componentDidMount() {
      const { options, local } = this.props;
      const url = this.useUrlState();
      ReactGA.pageview(window.location.pathname + window.location.search);
      const isUrlSame =
        url.address === options.address &&
        url.lat === options.lat &&
        url.lon === options.lon;

      if (isUrlSame === false) {
        controllers.filter.setAddress(
          url.address,
          url.lat,
          url.lon,
          url.city,
          url.country,
          [
            local.selectedCurrency.minPurchasePower,
            local.selectedCurrency.maxPurchasePower
          ],

          url.theme,
          url.type
        );
      } else if (options.hasInitialized === false) {
        controllers.filter.refreshFilter();
      }

      controllers.subscribeToCurrencyChange(
        this.handleCurrencyChange,
        "filter-page"
      );

      controllers.subscribeToLanguageChange(
        this.handleCurrencyChange,
        "filter-page"
      );
    }

    render() {
      const { options, local } = this.props;

      let filterCount = 0;
      filterCount += options.categories.length;

      if (options.dayRange !== "") {
        filterCount++;
      }

      let activeTab = options.selectedTab;
      let dataSource;

      if (options.address !== "") {
        if (activeTab === "nearby" && options.nearByResultCount > 0) {
          dataSource = options.nearByResults;
        } else {
          activeTab = "result";
          dataSource = options.results;
        }
      } else {
        dataSource = options.results;
        activeTab = "result";
      }

      return (
        <React.Fragment>
          <Helmet>
            <title>{`${options.address} - Search Results - Tripbonder.com`}</title>
          </Helmet>
          <div className="filter-content-wrapper">
            <div className="container-fluid filter-page-wrapper">
              <div className="row">
                <div className="col-auto d-none d-md-block">
                  <div className="filter-navbar px-3">
                    <div className="row">
                      <div className="col filter-nav-bar-header">
                        <i className="fas fa-filter pb-1"></i>
                        <h6 className="ml-3">
                          {this.props.t("Filter Search")}
                        </h6>
                      </div>
                      <div className="col-auto badge-refresh-wrapper">
                        {filterCount > 0 ? (
                          <h6>
                            <span className="badge badge-danger font-13 mr-2">
                              {filterCount}
                            </span>
                          </h6>
                        ) : null}
                        <button
                          className="mb-1 pl-1 ml-0"
                          onClick={this.resetFilters}
                        >
                          <i className="fas fa-redo-alt pt-1"></i>
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-auto">
                        <label className="pt-4">
                          {this.props.t("Category")}
                        </label>
                      </div>
                      <div className="col line-seperator-wrapper mr-3"></div>
                    </div>
                    <div className="row pt-2 category-wrapper">
                      <div className="col-12">
                        {this.props.local.filterSearchCategoryMaster.map(
                          (category, index) => (
                            <div
                              key={index}
                              className="d-inline-block pr-1 p-1"
                            >
                              <button
                                className={`${
                                  options.categories.indexOf(category.value) >
                                  -1
                                    ? "active"
                                    : "inactive"
                                } py-1 px-3 d-block mr-1 truncate`}
                                onClick={() =>
                                  this.toggleSelectedCategory(category.value)
                                }
                              >
                                {this.props.t(category.label)}
                              </button>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-auto">
                        <label className="pt-4">
                          {this.props.t("No. of Days")}
                        </label>
                      </div>
                      <div className="col line-seperator-wrapper mr-3"></div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {this.props.local.filterSearchNumberOfDays.map(
                          (numberOfDayRange, index) => (
                            <div
                              key={index}
                              className="form-check d-flex px-2 py-2 mt-3"
                            >
                              <div className="flex-fill">
                                <label
                                  htmlFor={`days-${numberOfDayRange.value}`}
                                  className="form-check-label d-block"
                                >
                                  {this.props.t(numberOfDayRange.label)}
                                </label>
                              </div>
                              <div className="flex-shrink-0">
                                <input
                                  type="radio"
                                  id={`days-${numberOfDayRange.value}`}
                                  className="d-block"
                                  onChange={() =>
                                    this.changeDaysRange(numberOfDayRange.value)
                                  }
                                  checked={
                                    options.dayRange === numberOfDayRange.value
                                  }
                                />
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-auto">
                        <label className="pt-4">{this.props.t("Price")}</label>
                      </div>
                      <div className="col line-seperator-wrapper mr-3"></div>
                      <div className="col-12 text-center">
                        <p>{this.props.t("Drag the slider to adjust")}</p>
                      </div>
                      <div className="col-12 pt-4 px-4">
                        <AirbnbSlider
                          ThumbComponent={AirbnbThumbComponent}
                          value={
                            options.priceRange || [
                              local.selectedCurrency.minPurchasePower,
                              local.selectedCurrency.maxPurchasePower
                            ]
                          }
                          onChange={this.updatePriceRange}
                          valueLabelDisplay="on"
                          aria-labelledby="range-slider"
                          step={local.selectedCurrency.purchasePowerStep}
                          min={local.selectedCurrency.minPurchasePower}
                          max={local.selectedCurrency.maxPurchasePower}
                        />
                      </div>
                      <div className="col-12 d-flex slider-val-display flex-row justify-content-between">
                        <SliderValueDisplay
                          value={
                            options.priceRange || [
                              local.selectedCurrency.minPurchasePower,
                              local.selectedCurrency.maxPurchasePower
                            ]
                          }
                          max={local.selectedCurrency.maxPurchasePower}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="container">
                    <div className="row">
                      <div className="col d-md-none mt-1 pr-0 mr-3">
                        <SearchBar
                          onSearch={address =>
                            this.props.history.push({
                              pathname: "/filter",
                              search: queryString.stringify(address)
                            })
                          }
                        />
                      </div>
                      <div className="col-auto d-md-none filter-icon-wrapper">
                        <i
                          className="fas fa-filter pr-2 mt-3"
                          onClick={this.toggleFilter}
                        ></i>
                        {filterCount > 0 ? (
                          <span className="badge badge-danger">
                            {filterCount}
                          </span>
                        ) : null}
                      </div>
                      <div className="col-12">
                        <div className="title-wrapper text-center pt-4">
                          <button
                            onClick={() =>
                              controllers.filter.switchResultTab("result")
                            }
                            className="mr-0"
                          >
                            <h4
                              className={`${
                                activeTab === "result" ? "active" : "inactive"
                              } pr-1`}
                            >
                              {this.props.t("Search Results")}
                            </h4>
                          </button>
                          {options.nearByResultCount > 0 ? (
                            <>
                              <button
                                onClick={() =>
                                  controllers.filter.switchResultTab("nearby")
                                }
                                className="search-result pl-0"
                              >
                                <h4
                                  className={`${
                                    activeTab === "nearby"
                                      ? "active"
                                      : "inactive"
                                  } pl-2`}
                                >
                                  {this.props.t("Nearby Places")}
                                </h4>
                              </button>
                              <h6>
                                <span className="badge badge-danger py-1">
                                  {options.nearByResultCount}
                                </span>
                              </h6>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 pt-0 text-center location-desc-wrapper">
                        {options.address ? (
                          <div className="location-wrapper py-2">
                            <i className="fas fa-map-marker-alt pr-2"></i>
                            <span>{options.address}</span>
                          </div>
                        ) : null}
                        {/* <label className="pt-2">
                         A wonderful spot for camping. Tea farming.
                         <br /> Sightseeing and tourist attraction
                         </label> */}
                      </div>
                      {options.filterTags.length > 0 ? (
                        <div className="col-12 my-2 text-center">
                          {options.filterTags.map((tag, index) => {
                            return (
                              <button
                                onClick={() => {
                                  controllers.filter.selectFilterTag(tag);
                                }}
                                className={`mx-2 filter-tag-btn ${
                                  options.selectedHashTags.indexOf(tag) > -1
                                    ? "selected"
                                    : ""
                                }`}
                                key={index}
                              >
                                <em>{this.props.t("#")}</em>
                                <span>{tag}</span>
                              </button>
                            );
                          })}
                        </div>
                      ) : null}
                    </div>
                    <div className="row activity">
                      <div className="col-12 pt-2 text-center d-none d-md-block">
                        <div
                          className="btn-group text-capitalize"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            className={`btn ${
                              options.package === "itinerary"
                                ? "active-button"
                                : "inactive-button"
                            } btn-secondary popover-title-content`}
                            onClick={() => {
                              this.switchPackageTab("itinerary");
                            }}
                          >
                            <i className="flight fas fa-plane-departure pr-2"></i>
                            {this.props.t("Trips")}
                          </button>
                          <button
                            type="button"
                            className={`btn ${
                              options.package === "ticket"
                                ? "active-button"
                                : "inactive-button"
                            } btn-secondary popover-title-content`}
                            onClick={() => {
                              this.switchPackageTab("ticket");
                            }}
                          >
                            <i className="fas fa-ticket-alt pr-2"></i>
                            {this.props.t("Tickets")}
                          </button>
                          <button
                            type="button"
                            className={`btn text-capitalize ${
                              options.package === "accomodation"
                                ? "active-button"
                                : "inactive-button"
                            } btn-secondary popover-title-content`}
                            onClick={() => {
                              this.switchPackageTab("accomodation");
                            }}
                          >
                            <i className="fas fa-mug-hot pr-2"></i>
                            {this.props.t("Accommodation")}
                          </button>
                          <button
                            type="button"
                            className={`btn ${
                              options.package === "transport"
                                ? "active-button"
                                : "inactive-button"
                            } btn-secondary popover-title-content`}
                            onClick={() => {
                              this.switchPackageTab("transport");
                            }}
                          >
                            <i className="fas fa-bus pr-2"></i>
                            {this.props.t("Transport")}
                          </button>
                        </div>
                      </div>
                      <div className="col-12 pt-2 text-center d-md-none mv-activity">
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            className={`btn ${
                              options.package === "itinerary"
                                ? "active-button"
                                : "inactive-button"
                            } btn-secondary popover-title-content`}
                            onClick={() => {
                              this.switchPackageTab("itinerary");
                            }}
                          >
                            <i className="fas fa-plane-departure"></i>
                          </button>
                          <button
                            type="button"
                            className={`btn ${
                              options.package === "ticket"
                                ? "active-button"
                                : "inactive-button"
                            } btn-secondary popover-title-content`}
                            onClick={() => {
                              this.switchPackageTab("ticket");
                            }}
                          >
                            <i className="fas fa-ticket-alt"></i>
                          </button>
                          <button
                            type="button"
                            className={`btn ${
                              options.package === "accomodation"
                                ? "active-button"
                                : "inactive-button"
                            } btn-secondary popover-title-content`}
                            onClick={() => {
                              this.switchPackageTab("accomodation");
                            }}
                          >
                            <i className="fas fa-mug-hot"></i>
                          </button>
                          <button
                            type="button"
                            className={`btn ${
                              options.package === "transport"
                                ? "active-button"
                                : "inactive-button"
                            } btn-secondary popover-title-content`}
                            onClick={() => {
                              this.switchPackageTab("transport");
                            }}
                          >
                            <i className="fas fa-bus"></i>
                          </button>
                        </div>
                      </div>
                      <div className="col-12 d-lg-none text-center pt-3 ticket-label-wrapper">
                        <label className="text-capitalize">
                          {this.props.t(SanitizePackageTypes(options.package))}
                        </label>
                      </div>
                    </div>
                    {options.isRefreshing === false && dataSource.length < 1 ? (
                      <div
                        className="row mt-5"
                        style={{
                          opacity: `${
                            options.isRefreshing === true ? "0.3" : "1"
                          }`
                        }}
                      >
                        <div className="jumbotron-fluid">
                          <h1 className="display-4">
                            {this.props.t("No")}{" "}
                            <span className="text-capitalize">
                              {options.package === "ticket"
                                ? this.props.t("Tickets / Entrance Fees")
                                : `${this.props.t(options.package)}`}
                            </span>
                          </h1>
                          <p className="lead">
                            {this.props.t(
                              "Your search yielded no results, you can try searching for a different place"
                            )}

                            {options.nearByResultCount > 0
                              ? " " +
                                this.props.t("or explore near by packages")
                              : ""}
                          </p>
                          {options.nearByResultCount > 0 ? (
                            <>
                              <hr className="my-4" />
                              <p>
                                {this.props.t(
                                  "Nearby packages are suggestions based on your past searches"
                                )}
                              </p>
                              <p className="lead">
                                <button
                                  onClick={() =>
                                    controllers.filter.switchResultTab("nearby")
                                  }
                                  className="btn btn-info btn-lg"
                                >
                                  {this.props.t("Explore Nearby")}
                                </button>
                              </p>
                            </>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <>
                        <InfiniteScroll
                          refreshLock={
                            options.selectedTab === "result"
                              ? options.hasLoadedCompleteResults
                              : options.hasLoadedCompleteNearbyResults
                          }
                          thresholdRate={70}
                          className="row results"
                          style={{
                            opacity: `${
                              options.isRefreshing === true ? "0.3" : "1"
                            }`
                          }}
                          onRefresh={() => this.loadMore()}
                        >
                          {HashTagFilter(
                            options.selectedHashTags,
                            dataSource
                          ).map((results, index) => (
                            <Trip
                              key={`${options.package}-${index}`}
                              hasShadow={true}
                              data={results}
                            />
                          ))}
                        </InfiniteScroll>
                      </>
                    )}

                    {options.isRefreshing === true ||
                    options.isSilentRefreshing === true ? (
                      <div className="mt-5 text-center">
                        <i
                          className="fas fa-circle-notch fa-spin"
                          style={{ fontSize: 50 }}
                        ></i>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Modal isOpen={options.miniFilterOpen} toggle={this.toggleFilter}>
            <div className="modal-body">
              <div className="mini-filter-navbar d-flex flex-column">
                <div className="flex-fill d-flex flex-column container-fluid overflow-hidden px-2 pt-2">
                  <div className="row py-2 flex-fill overflow-auto">
                    <div className="col-12">
                      <div className="mini-filter-header-wrapper">
                        <div className="flex-shrink-0">
                          <button onClick={this.resetFilters}>
                            <i className="fas fa-redo-alt pl-2 pt-1 d-sm-none"></i>
                          </button>
                        </div>
                        <div className="flex-fill text-center badge-wrapper">
                          <h6>
                            <span className="badge badge-danger mr-2">
                              {filterCount}
                            </span>
                          </h6>
                          <h6>{this.props.t("Filter(s) Applied")}</h6>
                        </div>
                        {/* <div className="flex-shrink-0 icon-wrapper">
                           <button onClick={this.toggleFilter}>
                             <i className="fas fa-times"></i>
                           </button>
                         </div> */}
                      </div>
                      <div className="pt-2 mt-2 filter-page-accordion-wrapper">
                        <div className="accordion" id="filterAccordion">
                          <div className="card filter-item-wrapper mb-2 p-t-2">
                            <button
                              className="card-header d-flex"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                              id="filterHeadingOne"
                            >
                              <div className="flex-shrink-0"></div>
                              <div className="mb-0 text-center flex-fill">
                                <h5 className="mb-0 my-1">
                                  {this.props.t("Theme")}
                                </h5>
                              </div>
                              <div className="flex-shrink-0">
                                <i className="fas fa-chevron-circle-up mt-1"></i>
                              </div>
                            </button>

                            <div
                              id="collapseOne"
                              className="collapse show mb-2"
                              aria-labelledby="filterHeadingOne"
                              data-parent="#filterAccordion"
                            >
                              {this.props.local.filterSearchCategoryMaster.map(
                                (category, index) => (
                                  <div
                                    key={index}
                                    className="form-check d-flex px-3 py-2 m-2"
                                  >
                                    <div className="flex-fill">
                                      <label
                                        htmlFor={`check-box-${category.value}`}
                                        className="form-check-label d-block"
                                      >
                                        {this.props.t(category.label)}
                                      </label>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`check-box-${category.value}`}
                                        onChange={() =>
                                          this.toggleSelectedCategory(
                                            category.value
                                          )
                                        }
                                        checked={
                                          options.categories.indexOf(
                                            category.value
                                          ) > -1
                                        }
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                          <div className="card filter-item-wrapper mb-2 p-t-2">
                            <button
                              className="card-header d-flex collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="true"
                              aria-controls="collapseTwo"
                              id="filterHeadingTwo"
                            >
                              <div className="flex-shrink-0"></div>
                              <div className="mb-0 text-center flex-fill">
                                <h5 className="mb-0 my-1">
                                  {this.props.t("No. of days")}
                                </h5>
                              </div>
                              <div className="flex-shrink-0">
                                <i className="fas fa-chevron-circle-up mt-1"></i>
                              </div>
                            </button>

                            <div
                              id="collapseTwo"
                              className="collapse mb-2"
                              aria-labelledby="filterHeadingTwo"
                              data-parent="#filterAccordion"
                            >
                              {this.props.local.filterSearchNumberOfDays.map(
                                (numberOfDayRange, index) => (
                                  <div
                                    key={index}
                                    className="form-check d-flex px-3 py-2 m-2"
                                  >
                                    <div className="flex-fill">
                                      <label
                                        htmlFor={`check-box-${numberOfDayRange.value}`}
                                        className="form-check-label d-block"
                                      >
                                        {this.props.t(numberOfDayRange.label)}
                                      </label>
                                    </div>
                                    <div className="flex-shrink-0">
                                      <input
                                        type="radio"
                                        className="form-check-input"
                                        id={`check-box-${numberOfDayRange.value}`}
                                        onChange={() =>
                                          this.changeDaysRange(
                                            numberOfDayRange.value
                                          )
                                        }
                                        checked={
                                          options.dayRange.indexOf(
                                            numberOfDayRange.value
                                          ) > -1
                                        }
                                      />
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                          <div className="card filter-item-wrapper mb-2 p-t-2">
                            <button
                              className="card-header d-flex collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapseThree"
                              aria-expanded="true"
                              aria-controls="collapseThree"
                              id="filterHeadingThree"
                            >
                              <div className="flex-shrink-0"></div>
                              <div className="mb-0 text-center flex-fill">
                                <h5 className="mb-0 my-1">
                                  {this.props.t("Price")}
                                </h5>
                              </div>
                              <div className="flex-shrink-0">
                                <i className="fas fa-chevron-circle-up mt-1"></i>
                              </div>
                            </button>

                            <div
                              id="collapseThree"
                              className="collapse mb-2"
                              aria-labelledby="filterHeadingThree"
                              data-parent="#filterAccordion"
                            >
                              <div className="col-12 text-center">
                                <p
                                  className="font-13"
                                  style={{ color: "rgb(169, 169, 169)" }}
                                >
                                  {this.props.t("Drag the slider to adjust")}
                                </p>
                              </div>
                              <div className="col-12 mb-5 pb-2">
                                <div className="px-5">
                                  <AirbnbSlider
                                    ThumbComponent={AirbnbThumbComponent}
                                    value={options.priceRange}
                                    onChange={this.updatePriceRange}
                                    valueLabelDisplay="on"
                                    aria-labelledby="range-slider"
                                    step={
                                      local.selectedCurrency.purchasePowerStep
                                    }
                                    min={
                                      local.selectedCurrency.minPurchasePower
                                    }
                                    max={
                                      local.selectedCurrency.maxPurchasePower
                                    }
                                  />
                                </div>
                                <div className="col-12 d-flex slider-val-display flex-row justify-content-between">
                                  <SliderValueDisplay
                                    value={
                                      options.priceRange || [
                                        local.selectedCurrency.minPurchasePower,
                                        local.selectedCurrency.maxPurchasePower
                                      ]
                                    }
                                    max={
                                      local.selectedCurrency.maxPurchasePower
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row flex-shrink px-0 px-sm-2">
                    <div className="col-12 col-sm-6 px-0 px-sm-2">
                      <button
                        className="btn btn-warning-gradient btn-block btn-sm py-2"
                        onClick={this.toggleFilter}
                      >
                        {this.props.t("Apply")}
                      </button>
                    </div>
                    <div className="col-6 d-none d-sm-block px-0 px-sm-2">
                      <button
                        type="button"
                        className="btn btn-secondary btn-block btn-sm py-2"
                        onClick={this.resetFilters}
                      >
                        {this.props.t("Reset")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </React.Fragment>
      );
    }
  }
);

export default connect(state => ({
  options: state.filter,
  local: state.local
}))(Filterpage);
