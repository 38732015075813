import Axios from "axios";
export const ChatServices = {
  PostMessage: (listingId, payload) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/public/api/chat/${listingId}`, payload)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  GetThreads: () => {
    return new Promise((resolve, reject) => {
      Axios.get(`/public/api/chat`)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },

  GetConversations: () => {
    return new Promise((resolve, reject) => {
      Axios.get(`/public/api/chat/v2/conversations`)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  FetchConversation: participants => {
    return new Promise((resolve, reject) => {
      Axios.post(`/public/api/chat/v2/fetch`, { participants })
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  UpdateConversation: (id, payload) => {
    return new Promise((resolve, reject) => {
      Axios.put(`/public/api/chat/v2/conversations/${id}`, payload)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  }
};
