import {
  CHAT_LAUNCH_THREAD,
  CHAT_THREAD_CLOSE,
  CHAT_ON_SELECT_THREAD_WIN,
  CHAT_ATTACH_INFO,
  CHAT_POPULATE_ALL,
  CHAT_MSG_PUSH,
  CHAT_V2_START_CONVERSATION,
  CHAT_V2_INIT_CONVERSATION,
  CHAT_V2_UPDATE_MSG_STATUS,
  CHAT_V2_UPDATE_LAST_ACTIVE_ON,
  CHAT_V2_RESET_READ_COUNT,
  CHAT_V2_PUSH_CONVERSATION,
  CHAT_V2_UPDATE_CONVERSATION,
  CHAT_V2_OPEN_MINI_CONVERSATION
} from "../types";
import moment from "moment";
import { ChatServices } from "../../services/Chat.services";
import { ResetChatWindow, FocusChatWindow } from "../../resources/Helper";

export class ChatController {
  store = null;
  audio = null;

  constructor(_store) {
    this.store = _store;
    this.messageAlert = new Audio(
      require("./../../assets/audio/message_notification.mp3")
    );

    this.messageSentAlert = new Audio(
      require("./../../assets/audio/uggg_sent_message.mp3")
    );
  }

  getChatState() {
    let state = this.store.getState();
    return state && state.chat;
  }

  pushConversation(thread) {
    if (!thread) {
      throw new Error("Thread is required");
    }

    const state = this.getChatState();
    if (state) {
      const selectedThreadId = state.selectedThreadId;
      if (selectedThreadId !== thread._id) {
        const conversation = state.conversations.find(
          c => c._id === thread._id
        );

        if (!conversation) {
          this.messageAlert.play();
          this.store.dispatch({
            type: CHAT_V2_PUSH_CONVERSATION,
            payload: {
              item: Object.assign({}, thread, {
                hasInitialized: false,
                isMiniWindowOpen: false,
                messages: []
              })
            }
          });
        } else {
          if (conversation.isMiniWindowOpen === true) {
            // Do nothing
          } else {
            this.messageAlert.play();
            this.store.dispatch({
              type: CHAT_V2_UPDATE_CONVERSATION,
              payload: {
                threadId: thread._id,
                item: Object.assign({
                  hasInitialized: false,
                  isMiniWindowOpen: false,
                  messages: [],
                  lastActiveOn: thread.lastActiveOn,
                  participants: thread.participants
                })
              }
            });
          }
        }
      }
    }
  }

  startConversation(participants, id = moment().valueOf()) {
    const state = this.getChatState();
    if (state) {
      const conversation = state.conversations.find(c => {
        return c.participants.every(p => {
          return (
            participants.findIndex(
              orgP => orgP.chatUserId === p.chatUserId && orgP.group === p.group
            ) > -1
          );
        });
      });
      if (!conversation) {
        this.store.dispatch({
          type: CHAT_V2_START_CONVERSATION,
          payload: {
            item: {
              _id: id,
              hasInitialized: false,
              isMiniWindowOpen: true,
              participants
            }
          }
        });
      } else if (conversation.isMiniWindowOpened === false) {
        this.store.dispatch({
          type: CHAT_V2_OPEN_MINI_CONVERSATION,
          payload: {
            threadId: conversation._id
          }
        });
      } else {
        ResetChatWindow(conversation._id);
        FocusChatWindow(conversation._id);
      }
    }
  }

  fetchConversation(id, force) {
    return new Promise((resolve, reject) => {
      const state = this.getChatState();
      if (state) {
        const conversation = state.conversations.find(
          c => c._id === id && (c.hasInitialized === false || force === true)
        );

        if (conversation) {
          ChatServices.FetchConversation(conversation.participants)
            .then(response => {
              this.store.dispatch({
                type: CHAT_V2_INIT_CONVERSATION,
                payload: {
                  item: response,
                  id
                }
              });

              resolve(response);
            })
            .catch(err => {
              reject(err);
            });
        } else {
          resolve(false);
        }
      } else {
        reject(new Error("State not available"));
      }
    });
  }

  setUserInfo(conversationId, payload, chatUserId) {
    return new Promise((resolve, reject) => {
      ChatServices.UpdateConversation(conversationId, payload)
        .then(response => {
          this.store.dispatch({
            type: CHAT_ATTACH_INFO,
            payload: {
              chatUserId,
              threadId: conversationId,
              displayName: payload.displayName,
              emailAddress: payload.emailAddress
            }
          });
        })
        .catch(err => {
          console.log(err);
        });
    });
  }

  pushMessages(threadId, messages) {
    this.store.dispatch({
      type: CHAT_MSG_PUSH,
      payload: {
        threadId,
        messages
      }
    });

    if (messages.some(m => m.status === "sent")) {
      this.messageSentAlert.play();
    }
  }

  updateMessageStatus(threadId, messageSortId, status) {
    this.store.dispatch({
      type: CHAT_V2_UPDATE_MSG_STATUS,
      payload: {
        threadId,
        sortIdentifier: messageSortId,
        status
      }
    });

    this.messageSentAlert.play();
  }

  updateConversationLastActiveOn(threadId, lastActiveOn) {
    this.store.dispatch({
      type: CHAT_V2_UPDATE_LAST_ACTIVE_ON,
      payload: {
        threadId,
        lastActiveOn
      }
    });
  }

  resetReadCount(threadId, chatUserId, count = 0) {
    this.store.dispatch({
      type: CHAT_V2_RESET_READ_COUNT,
      payload: {
        threadId,
        chatUserId,
        value: count
      }
    });
  }

  closeThread(threadId) {
    this.store.dispatch({
      type: CHAT_THREAD_CLOSE,
      payload: {
        threadId
      }
    });
  }

  populateMessages() {
    return new Promise((resolve, reject) => {
      let state = this.store.getState();
      if (state) {
        state = state.chat;
        if (state.hasInitialized === false) {
          ChatServices.GetConversations()
            .then(threads => {
              this.store.dispatch({
                type: CHAT_POPULATE_ALL,
                payload: {
                  threads
                }
              });

              resolve(true);
            })
            .catch(err => {
              console.error(err);
              reject(err);
            });
        } else {
          resolve(true);
        }
      }
    });
  }

  launchThread(listingId, listingType, title, participants, imageId) {
    this.store.dispatch({
      type: CHAT_LAUNCH_THREAD,
      payload: {
        listingId,
        listingType,
        title,
        participants,
        listingImageId: imageId
      }
    });
  }

  attachInfo(displayName, emailAddress) {
    this.store.dispatch({
      type: CHAT_ATTACH_INFO,
      payload: {
        displayName,
        emailAddress
      }
    });
  }

  selectThreadWindow(selectedPackageTitle, selectedThreadId) {
    this.store.dispatch({
      type: CHAT_ON_SELECT_THREAD_WIN,
      payload: {
        selectedPackageTitle,
        selectedThreadId
      }
    });
  }

  // this.messageAlert.play();
}
