import React, { Component, createRef } from "react";

export default class InifiniteScroll extends Component {
  constructor(props) {
    super(props);
    this.wrapElem = createRef();

    this.handleWindowScroll = this.handleWindowScroll.bind(this);
  }

  handleWindowScroll() {
    const bodyHeight = this.wrapElem.current.scrollHeight; // document.documentElement.scrollHeight;
    const scrollTop =
      document.documentElement.scrollTop +
      document.documentElement.clientHeight;
    const thresholdRate = this.props.thresholdRate || 70;
    let scrollRate = 0;
    if (bodyHeight > 0 && scrollTop > 0) {
      scrollRate = (scrollTop / bodyHeight) * 100;
      if (scrollRate > thresholdRate && this.props.refreshLock === false) {
        console.log("123");
        this.props.onRefresh && this.props.onRefresh();
      }
    }
  }

  componentDidMount() {
    document.addEventListener("scroll", this.handleWindowScroll);
  }

  componentWillUnmount() {
    document.removeEventListener("scroll", this.handleWindowScroll);
  }

  // componentWillReceiveProps(newProps) {
  //     console.log(this.refreshLock);
  // }

  render() {
    return (
      <div
        ref={this.wrapElem}
        style={this.props.style}
        className={this.props.className}
      >
        {this.props.children}
      </div>
    );
  }
}
