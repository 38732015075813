import React from "react";
import { connect } from "react-redux";

import Auth from "../views/Auth/Auth.view";
import LostAndCatch from "../views/LostAndCatch";

class AuthLayout extends React.Component {
  componentDidMount() {
    // setTimeout(() => {
    //   this.props.history.push({
    //     pathname: '/auth/anandhu'
    //   })
    // }, 1000);
  }

  render() {
    const action = this.props.match.params.action;
    switch (action) {
      case "login":
      case "register": {
        break;
      }
      default: {
        return <LostAndCatch />;
      }
    }

    return (
      <React.Fragment>
        <main className="auth">
          <div className="content">
            <Auth currentAction={action} />
          </div>
        </main>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  local: state.local
});

export default connect(mapStateToProps)(AuthLayout);
