import React from "react";
import { Zoom } from "react-reveal";
import { ModalBody, ModalFooter } from "reactstrap";
import { DateRangePicker } from "react-dates";
import { ListingServices } from "./../../services/Listing.services";
import controllers from "../../store/controllers";
import { NETWORK_ERR_MESSAGE } from "../../resources/String";

const PublishModal = props => {
  const _package = props.package || null;
  const [warnings, setWarnings] = React.useState([]);
  const [mode, setMode] = React.useState("validating"); // validating, ready-to-publish, validation-failed

  const [focusedDateRangeInput, updateFocusedDateRangeInput] = React.useState(
    null
  );

  const [listingDateRange, setListingDateRange] = React.useState({
    start: null,
    end: null
  });

  const Validate = (packageType, id) => {
    ListingServices.Validate(packageType, id)
      .then(response => {
        if (response.isSuccess === true) {
          setMode("ready-to-publish");
        } else {
          setWarnings(response.warnings);
          // setDeclarations(response.declarations);
          setMode("validation-failed");
        }
      })
      .catch(err => {
        props.toggle && props.toggle();
        console.error(err);
        controllers.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
      });
  };

  const SubmitForReview = () => {
    if (listingDateRange.start !== null && listingDateRange.end !== null) {
      controllers.setGlobalLoader("Processing...", false);
      setTimeout(() => {
        ListingServices.SubmitReview(props.packageType, props.listingId, {
          listingStartDate: listingDateRange.start.valueOf(),
          listingEndDate: listingDateRange.end.valueOf(),
          otherInformation: props.package.otherInformation,
          geoLocation: _package.geoLocation,
          city: _package.city,
          country: _package.country
        })
          .then(response => {
            switch (props.packageType) {
              case "itinerary": {
                controllers.itinerary.updateItinerary(
                  "_status",
                  response.status
                );

                break;
              }
              case "ticket": {
                controllers.ticket.updateTicket("_status", response.status);
                break;
              }
              case "accomodation": {
                controllers.accomodation.updateAccomodation(
                  "_status",
                  response.status
                );

                break;
              }
              case "transport": {
                controllers.transport.updateTransport(
                  "_status",
                  response.status
                );

                break;
              }
              default: {
                break;
              }
            }

            controllers.setEditorSaved();

            controllers.setGlobalSuccess(
              "Your package is now waiting for review",
              true
            );

            setTimeout(() => {
              controllers.clearGlobalMessage();
              props.toggle && props.toggle();
            }, 2000);
          })
          .catch(err => {
            console.error(err);
            controllers.setGlobalError(
              err.message || NETWORK_ERR_MESSAGE,
              true
            );
          });
      }, 300);
    } else {
      controllers.setGlobalError(
        "Listing start and end dates are required",
        true
      );
    }
  };

  React.useEffect(() => {
    setMode("validating");
    setTimeout(() => {
      Validate(props.packageType, props.listingId);
    }, 2200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.listingId, props.packageType]);

  let title;
  let subTitle;
  let content;

  switch (mode) {
    case "ready-to-publish": {
      title = "Before you publish this...";
      subTitle =
        "Provide further settings for each people attending this itinerary and the cost for the same";
      content = (
        <Zoom duration={350}>
          <div className="col-4 d-none d-lg-block">
            <div className="img"></div>
          </div>
          <div className="col-12 col-lg-8">
            <div className="form-row">
              <div className="form-group col-12">
                <DateRangePicker
                  keepOpenOnDateSelect
                  hideKeyboardShortcutsPanel
                  small={window.matchMedia("(max-width: 400px)").matches}
                  block={window.matchMedia("(max-width: 400px)").matches}
                  numberOfMonths={
                    window.matchMedia("(max-width: 400px)").matches === true
                      ? 1
                      : 2
                  }
                  startDate={listingDateRange.start}
                  displayFormat="ll"
                  startDateId="your_unique_start_date_id"
                  endDate={listingDateRange.end}
                  endDateId="your_unique_end_date_id"
                  onDatesChange={({ startDate, endDate }) =>
                    setListingDateRange({ start: startDate, end: endDate })
                  }
                  focusedInput={focusedDateRangeInput}
                  onFocusChange={focusedInput =>
                    updateFocusedDateRangeInput(focusedInput)
                  }
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-12">
                <label htmlFor="tripDesc" className="header">
                  OTHER INFORMATION (USE EDITOR TO UPDATE THIS FIELD)
                </label>
                <hr />
                <textarea
                  className="form-control"
                  id="tripDesc"
                  rows="5"
                  placeholder="Any information regarding people and cost on this package"
                  value={props.package.otherInformation}
                  readOnly={true}
                  // onChange={e => setOtherInfo(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </Zoom>
      );

      break;
    }
    case "validation-failed": {
      title = "Validation Failed";
      subTitle = "Review and rectify all warnings before trying again";
      content = (
        <Zoom duration={350}>
          <div className="alert alert-warning w-100" role="alert">
            <h5 className="alert-heading">Warning!</h5>
            <ul>
              {warnings.map((item, index) => (
                <li key={index} className="font-13">
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </Zoom>
      );

      break;
    }
    case "validating": {
      title = "Please wait";
      subTitle = "Validating your package...";
      content = (
        <div className="col-12 text-center">
          <i className="loader fas fa-spin fa-circle-notch"></i>
        </div>
      );

      break;
    }
    default: {
      title = "";
      subTitle = "";
      content = null;
      break;
    }
  }

  return (
    <>
      <ModalBody>
        <button type="button" className="close" aria-label="Close">
          <span aria-hidden="true" onClick={props.toggle}>
            ×
          </span>
        </button>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 py-3 text-center">
              <h5>
                <b>{title}</b>
              </h5>
              <p className="font-13">{subTitle}</p>
            </div>
            {content}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        {mode === "ready-to-publish" ? (
          <button
            className="btn btn-xl btn-success-gradient px-4"
            onClick={SubmitForReview}
          >
            Submit to Review <i className="fas fa-arrow-right"></i>
          </button>
        ) : null}
      </ModalFooter>
    </>
  );
};

export default PublishModal;
