import Axios from "axios";
export const ListingServices = {
  Save: (packageType, id, _package) => {
    return new Promise((resolve, reject) => {
      Axios.put(`/api/listings/${packageType}/${id}`, _package)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  Delete: id => {
    return new Promise((resolve, reject) => {
      Axios.delete(`/api/listings/drafts/${id}`)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  GetListings: (status = null) => {
    return new Promise((resolve, reject) => {
      Axios.get(`/api/listings${status !== null ? `/${status}` : ""}`)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  GetListingById: (packageType, id) => {
    return new Promise((resolve, reject) => {
      Axios.get(`/api/listings/${packageType}/${id}`)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  Validate: (packageType, id) => {
    return new Promise((resolve, reject) => {
      Axios.get(`/api/listings/${packageType}/${id}/validate?strict=1`)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  SubmitReview: (packageType, id, payload) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/listings/${packageType}/${id}/submit-review`, payload)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  ClonePackage: (id, mode) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/listings/clone/${id}?mode=${mode}`)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  UpdatePublishStatus: (id, key) => {
    return new Promise((resolve, reject) => {
      Axios.put(`/api/listings/lifecycle/${id}/${key}`)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  CancelOrMoveToDraft: (type, id) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/listings/${type}/${id}/restore-draft`)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  SharePrivatePackage: (id, payload) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/api/listings/share/email/${id}`, payload)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  }
};
