import { withTranslation } from "react-i18next";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet";
import controllers from "../../store/controllers";
import ChatRoom from "../../components/Chat/ChatRoom";

function MessageThreadItem(props) {
  const conversations = props.conversations || [];
  const selectedThreadId = props.selectedThreadId || -1;
  const [isCollapsed, setIsCollapsed] = useState(true);
  const totalUnreadMessage = conversations.reduce((accumulator, thread) => {
    const sender = thread.participants.find(
      p => p.chatUserId === props.local.chatUserId
    );

    return accumulator + (sender.unreadMessages || 0);
  }, 0);
  return (
    <React.Fragment>
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        className={`message p-2 d-flex ${
          conversations.find(c => c._id === selectedThreadId)
            ? "active-package"
            : ""
        }`}
      >
        <div
          className="profile-image flex-shrink-0"
          style={{
            backgroundImage: `url('${controllers.resolveAssetAddr(
              props.imageId
            )}')`
          }}
        ></div>
        <div className="flex-fill truncate">
          <h6 className="mt-2 truncate text-left mx-3">{props.title || ""}</h6>
        </div>
        {totalUnreadMessage > 0 ? (
          <div className="flex-shrink-0">
            <h4>
              <span className="badge badge-danger">{totalUnreadMessage}</span>
            </h4>
          </div>
        ) : null}
      </button>
      {isCollapsed === false ||
      conversations.findIndex(c => c.threadId === selectedThreadId) > -1
        ? conversations.map((convo, index) => {
            const recepient = convo.participants.filter(
              p => p.chatUserId !== props.local.chatUserId
            );

            const sender = convo.participants.find(
              p => p.chatUserId === props.local.chatUserId
            );

            return (
              <button
                key={index}
                onClick={() =>
                  props.onSelect && props.onSelect(props.title, convo._id)
                }
                className={`dropdown-message message ${
                  convo._id === selectedThreadId ? "active-thread" : ""
                } pl-5 py-2`}
              >
                <div
                  className="profile-image"
                  style={{
                    backgroundImage: `url('${
                      !convo.imageId
                        ? require("./../../assets/images/default-avatar.png")
                        : controllers.resolveAssetAddr(
                            recepient[0].profileImageId
                          )
                    }')`
                  }}
                ></div>
                <div className="truncate mx-3 text-left flex-fill">
                  <h6
                    className="mt-2 mb-0 truncate"
                    style={{
                      fontWeight: sender.unreadMessages > 0 ? "bold" : "unset"
                    }}
                  >
                    {recepient[0].displayName}
                  </h6>
                  <span className="email truncate pl-0">
                    {recepient[0].emailAddress}
                  </span>
                </div>
                {sender.unreadMessages > 0 ? (
                  <div className="flex-shrink-0">
                    <h4>
                      <span className="badge badge-danger">
                        {sender.unreadMessages}
                      </span>
                    </h4>
                  </div>
                ) : null}
              </button>
            );
          })
        : null}
    </React.Fragment>
  );
}
const Inbox = withTranslation()(
  class Inbox extends React.Component {
    componentDidMount() {
      controllers.setGlobalLoader("Fetching messages...");
      setTimeout(() => {
        controllers.chat.populateMessages().then(() => {
          setTimeout(() => {
            controllers.clearGlobalMessage();
          }, 300);
        });
      }, 700);
    }

    componentWillUnmount() {
      controllers.chat.selectThreadWindow(null, null);
    }

    render() {
      const selectedThreadId = this.props.chat.selectedThreadId;
      const messageGroup = this.props.chat.conversations
        .sort(function(a, b) {
          if (a.lastActiveOn > b.lastActiveOn) {
            return -1;
          }
          return 1;
        })
        .reduce(function(accumulator, thread) {
          accumulator[thread.participants[0].group] = Object.assign(
            accumulator[thread.participants[0].group] || {},
            {
              title: thread.participants[0].group,
              ...thread.participants[0].groupPayload,
              items: [
                ...((accumulator[thread.participants[0].group] &&
                  accumulator[thread.participants[0].group].items) ||
                  []),
                thread
              ]
            }
          );

          return accumulator;
        }, {});

      const selectedThread = this.props.chat.conversations.find(
        c => c._id === selectedThreadId
      );

      let receipient = null;
      if (selectedThreadId) {
        receipient = selectedThread.participants.filter(
          p => p.chatUserId !== this.props.local.chatUserId
        );
      }

      return (
        <Fade duration={350}>
          <Helmet>
            <title>{this.props.t("Inbox - Tripbonder.com")}</title>
          </Helmet>
          <div className="container-fluid inbox-wrapper">
            <div className="row h-100 pt-0 pt-lg-3">
              <div
                className={`col-12 col-lg-4 col-xl-3 ${
                  selectedThreadId !== null ? "d-none" : ""
                } d-lg-block`}
              >
                <div className="messagethreads d-flex">
                  <div className="flex-shrink-0 message-head-wrapper">
                    <label className="mb-0 pl-2">
                      {this.props.t("Your messages,")}{" "}
                      {this.props.currentUser.name}
                      {this.props.t("!")}
                    </label>
                    <h3 className="pl-2">{this.props.t("Inbox")}</h3>
                    <hr className="d-sm-none" />
                  </div>
                  <div className="flex-fill thread-wrapper">
                    {Object.keys(messageGroup).map((title, index) => (
                      <MessageThreadItem
                        key={index}
                        title={title}
                        local={this.props.local}
                        imageId={messageGroup[title].defaultCoverArtId}
                        conversations={messageGroup[title].items}
                        selectedThreadId={selectedThreadId}
                        onSelect={(packageTitle, convoId) =>
                          controllers.chat.selectThreadWindow(
                            packageTitle,
                            convoId
                          )
                        }
                      />
                    ))}
                  </div>
                </div>
              </div>
              {selectedThread ? (
                <div className="col-12 col-lg-8 col-xl-9">
                  <div className="messagecomposer">
                    <div className="container-fluid flex-fill">
                      <div className="row">
                        <div className="col-12 contact-button-wrapper text-right pt-2 d-none d-lg-block">
                          <a
                            className="view-btn"
                            href={`/package/view/${receipient[0].groupPayload.listingId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <i className="fas fa-external-link-alt pr-1"></i>
                            {this.props.t("View Trip Details")}
                          </a>
                          <a
                            href={`/dashboard/composer/${receipient[0].groupPayload.type}/${receipient[0].groupPayload.listingId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-primary customize-btn px-4 mx-3"
                          >
                            <i className="fas fa-user-edit pr-1"></i>
                            {this.props.t("Customize Package")}
                          </a>
                        </div>
                      </div>
                      <div className="convo-header row pt-0 pt-lg-4">
                        <div className="col-12 profile-wrapper pl-0 d-flex">
                          <div className="d-lg-none flex-shrink-0 d-flex justify-content-center">
                            <button
                              onClick={() =>
                                controllers.chat.selectThreadWindow(null, null)
                              }
                            >
                              <i className="fas fa-arrow-left"></i>
                            </button>
                          </div>
                          <div className="flex-fill chat-box-display-picture">
                            <div
                              className="profile-image"
                              style={{
                                backgroundImage: `url('${
                                  selectedThread.imageId === null
                                    ? require("./../../assets/images/default-avatar.png")
                                    : require("./../../assets/images/default-avatar.png")
                                }')`
                              }}
                            ></div>
                            <h6 className="mt-2 truncate mx-2">
                              {receipient[0].displayName}
                              {this.props.t("-")}
                              {receipient[0].group}
                            </h6>
                          </div>
                          <div className="flex-shrink-0 d-flex justify-content-center pb-1">
                            <button
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              className="d-lg-none px-0 call-button"
                            >
                              <i className="fas fa-ellipsis-v"></i>
                            </button>
                            <div
                              className="dropdown-menu py-0"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                href={`/package/view/${receipient[0].groupPayload.listingId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="dropdown-item"
                              >
                                {this.props.t("View Trip Details")}
                              </a>
                              <a
                                href={`/dashboard/composer/${receipient[0].groupPayload.type}/${receipient[0].groupPayload.listingId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="dropdown-item"
                              >
                                {this.props.t("Customize Trip")}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <ChatRoom
                        key={selectedThread._id}
                        context={selectedThread}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            {this.props.chat.conversations.length < 1 ? (
              <div
                style={{ backgroundColor: "#e4f9e4", border: "none" }}
                className="alert alert-success text-center"
                role="alert"
              >
                <h6 style={{ fontWeight: "bold", color: "#42939D" }}>
                  {this.props.t("You have no messages")}
                </h6>
                <label style={{ color: " #42939D", fontSize: 13 }}>
                  {this.props.t(
                    "Here you will find any customization requests from travellers . Also you can use this chatbox to stay connected with them"
                  )}
                </label>
                <div>
                  <label style={{ color: "#363636", fontSize: 14 }}>
                    {this.props.t(
                      "NOTE: You will get email notification from your new messages"
                    )}
                  </label>
                </div>
              </div>
            ) : // <div className="jumbotron">
            //   <h1 className="display-4">No Messages!</h1>
            //   <p className="lead">
            //     Here, you will find any customisation requests from travellers.
            //     Also you can use the chat to stay connected with them.
            //   </p>
            //   <hr className="my-4" />
            //   <p>
            //     You will get email notification when you receive new messages
            //     here
            //   </p>
            // </div>
            null}
          </div>
        </Fade>
      );
    }
  }
);

export default connect(state => ({
  chat: state.chat,
  currentUser: state.local.currentUser,
  local: state.local
}))(Inbox);
