import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Fade } from "react-reveal";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ProfileServices } from "../../services/Profile.services";
import controllers from "../../store/controllers";
import { NETWORK_ERR_MESSAGE } from "../../resources/String";
import ReactGA from "react-ga";

const Agreement = function(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const openTripbonderAccount = () => {
    controllers.setGlobalLoader("Activating account", false);
    ProfileServices.UpdateProfile("activate-tripbonder", {})
      .then(response => {
        setTimeout(() => {
          controllers.setGlobalSuccess("Activated", true);
          controllers.setCurrentUser(response.user);
          setTimeout(() => {
            controllers.clearGlobalMessage();
            history.push("/dashboard");
            window.scrollTo(0, 0);
          }, 1200);
        }, 700);
      })
      .catch(err => {
        console.error(err);
        controllers.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
      });
  };

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <Fade duration={300}>
      <Helmet>
        <title>{t("Agreement - Tripbonder.com")}</title>
      </Helmet>
      <div className="container-fluid agreement-page-wrapper">
        <div className="row">
          <div
            className="col-4 d-none d-md-block img-wrapper"
            style={{
              backgroundImage: `url('${require("./../../assets/images/women.jpg")}')`
            }}
          ></div>
          <div className="col-12 col-md-8 pt-3 pt-md-4 pl-1 pl-md-5 agreement-header-wrapper pb-3">
            <div className="text-center text-md-left">
              <h6>{t("Become a Tripbonder")}</h6>
              <h4>{t("There's always some kind of paperwork!")}</h4>
              <p className="pt-2 pt-md-3">
                {t(
                  "Read & agree to the terms and conditions for a Tripbonder to continue"
                )}
              </p>
              <hr className="mr-4" />
            </div>
            <div className="agreement-content-wrapper pl-3">
              <h6>
                {t(
                  "The Services are owned and/or controlled by Tripbonder PTE LTD ( \u201CTripbonder\u201D), a company incorporated and validly existing in Singapore."
                )}
              </h6>
              <div>
                <p>
                  {t(
                    'TRIPBONDER PROVIDES THIS WEB SITE (THE "SITE") TO YOU SUBJECT TO THESE TERMS AND CONDITIONS OF USE (THESE "TERMS"). \u201CTRAVELLER", \u201CBUYER\u2019 ,\u201CSUBSCRIBER\u201D, \u201CMEMBER\u201D AND \u201CUSER\u201D (ALL OF WHICH HAVE THE SAME MEANING THROUGH THIS SITE) REFER TO GENERAL REGISTERED USERS CALLED "USER"; "PASSIONATE LOCALS" , "LOCAL PRIVATE TOUR GUIDES" , "TOURIST GUIDES" ,"TOUR GUIDES", \u201CTRAVEL SERVICE PROVIDER\u201D and \u201CSELLER\u201D (ALL OF WHICH HAVE THE SAME MEANING THROUGH THIS SITE). PLEASE READ THESE TERMS CAREFULLY BEFORE USING THIS WEB SITE. BY ACCESSING OR USING THIS WEB SITE, YOU EXPLICITLY AGREE TO COMPLY WITH AND BE BOUND BY THESE TERMS AND ALL TERMS, POLICIES AND GUIDELINES INCORPORATED BY REFERENCE HEREIN. IF YOU DO NOT AGREE TO ALL PROVISIONS OF THESE TERMS, DO NOT USE THIS WEB SITE.'
                  )}
                </p>
              </div>
              <p>
                {t(
                  "These Terms do not alter in any way the terms or conditions of any other agreement you may have with Tripbonder or its affiliates for products, services or otherwise. Tripbonder reserves the right to change or modify any of the terms and conditions contained in these Terms, or any policy or guideline of the Site, at any time and in its sole discretion. Unless otherwise specified, any changes or modifications will be effective immediately upon posting of the revisions on the Site, and your continued use of the Site will constitute your acceptance of such changes or modifications. You should review the Terms and its incorporated policies and documents from time to time to understand the terms and conditions that apply to your use of the Site. If you do not agree to any amended terms, you must stop using the Site."
                )}
              </p>
              <h6>{t("Eligibility")}</h6>
              <p>
                {t(
                  "You must be eighteen (18) or over to register as a User and/or use the Website and/or the Service. Subscription in the Service is void where prohibited. By using the Website and/or the Service, you represent and warrant that you have the right, authority, and capacity to enter into this Agreement and to abide by all of the terms and conditions herein. You further represent and warrant that all registration information you submit is truthful and accurate and that you agree to maintain the accuracy of such information. Your subscription may be terminated without warning and you may be barred from any future use of the Website and/or the Service if you provide any information that is untrue, inaccurate, not current, or incomplete, including but not limited to misrepresenting your age, or if Tripbonder has reasonable grounds to suspect that such information is untrue, inaccurate, not current, or incomplete. Your Subscription is solely for your personal use, and you agree not to authorise others to use your Subscription account, including but not limited to your profile. You are solely responsible for all content published or displayed through your Subscription account, including but not limited to e-mail messages, and for your interactions with other Users. Tripbonder reserves the right to refuse or revoke Subscription to anyone at any time for any reason resulting in the loss of ownership for said user."
                )}
              </p>
              <h6>{t("Privacy Policy")}</h6>
              <p>
                {t(
                  "Please refer to Tripbonder's Privacy Policy for information on how Tripbonder collects, uses and discloses personally identifiable information from its users."
                )}
              </p>
              <h6>{t("Ownership and Copyright")}</h6>
              <p>
                {t(
                  'Except as otherwise indicated, the Site, and all text, images, marks, logos and other content contained herein, including, without limitation, the Tripbonder logo and all designs, text, graphics, pictures, information, data, software, sound files, other files, and the selection and arrangement thereof (collectively, the "Site Content") are the proprietary property of Tripbonder Sdn. Bhd. Hence, the use of any robot, spider, site search/retrieval application, or other automated device, process or means to access, retrieve, scrape, or index the site or any site Content is prohibited.'
                )}
              </p>
              <h6>{t("Trademarks")}</h6>
              <p>
                {t(
                  "Tripbonder logo and all other product or service names or slogans displayed on the Site are registered and/or common law trademarks of Tripbonder, and/or its suppliers or licensors, and may not be copied, imitated or used, in whole or in part, without the prior written permission of Tripbonder or the applicable trademark holder. In addition, the look and feel of the Site, including all page headers, custom graphics, button icons and scripts, is the service mark, trademark and/or trade dress of Tripbonder and may not be copied, imitated or used, in whole or in part, without the prior written permission of Tripbonder. Reference to any products, services, processes or other information, by trade name, trademark, manufacturer, and supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof by Tripbonder."
                )}
              </p>
              <h6>
                {t(
                  "Travel Service Provider (\u201CService Provider\u201D) usage"
                )}
              </h6>
              <p>
                {t(
                  "Tripbonder assumes no responsibility for incorrect information collected from any of the Travel Service Providers registered on Tripbonder.com, any delay, changes in his/her schedule, strikes, injury, weather, fire, theft, quarantine, medical or customs regulations and similar act or incident beyond its ability to control. Tripbonder is not responsible for any arrangements made between you and the Travel Service Provider in the country you visit."
                )}
              </p>
              <h6>{t("User (\u201CUser\u201D) usage")}</h6>
              <p>
                {t(
                  "Users must be registered on the Sites to access or use some Services (a registered User is also referred to as a \u201CUser\u201D below). Tripbonder may reject User\u2019s application for registration for any reason. Upon registration on the Services, Tripbonder shall assign an account and issue a member ID and password (the latter shall be chosen by a registered User during registration) to each registered User. A set of User ID and password is unique to a single account. Each User shall be solely responsible for maintaining the confidentiality and security of your User ID and password and for all activities that occur under your account. No User may share, assign, or permit the use of your User account, ID or password by another person outside of the User\u2019s own business entity. User agrees to notify Tripbonder immediately if you become aware of any unauthorised use of your password or your account or any other breach of security of your account. User agrees that in case of the multiple use of your account or User\u2019s failure to maintain the security of your account, Tripbonder shall not be liable for any loss or damages arising from such a breach and shall have the right to suspend or terminate User\u2019s account without liability to User."
                )}
              </p>
              <h6>
                {t(
                  "Travel Service Provider (\u201CTravel Service Provider\u201D) Responsibility"
                )}
              </h6>
              <p>
                {t(
                  "Each Travel Service Provider represents, warrants and agrees to Tripbonder that (a) you shall be solely responsible for obtaining all necessary third party licenses and permissions regarding any User Content that you submit, post or display; (b) any User Content that you submit, post or display does not infringe or violate any of the copyright, patent, trademark, trade name, trade secrets or any other personal or proprietary rights of any third party (\u201CThird Party Rights\u201D); and (c) you have the right and authority to sell, trade, distribute or export or offer to sell, trade, distribute or export the products or services described in the User Content and such sale, trade, distribution or export or offer does not violate any Third Party Rights."
                )}
              </p>
              <p>
                {t(
                  "Each Travel Service Provider further represents, warrants and agrees that the User Content that you submit, post or display shall:"
                )}
              </p>
              <ol type="a">
                <li>{t("be true, accurate, complete and lawful;")}</li>
                <li>{t("not be false, misleading or deceptive;")}</li>
                <li>
                  {t(
                    "not contain information that is defamatory, libelous, threatening or harassing, obscene, objectionable, offensive, sexually explicit or harmful to minors;"
                  )}
                </li>
                <li>
                  {t(
                    "not contain information that is discriminatory or promotes discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;"
                  )}
                </li>
                <li>
                  {t(
                    "not violate the Product Listing Policy, other Terms or any applicable Additional Agreements"
                  )}
                </li>
                <li>
                  {t(
                    "not violate any applicable laws and regulations (including without limitation those governing export control, consumer protection, unfair competition, or false advertising) or promote any activities which may violate any applicable laws and regulations;"
                  )}
                </li>
                <li>
                  {t(
                    "not contain any link directly or indirectly to any other web Sites which includes any content that may violate the Terms."
                  )}
                </li>
              </ol>
              <p>
                {t(
                  "Each Travel Service Provider further represents, warrants and agrees that you shall:"
                )}
              </p>
              <ol type="a">
                <li>
                  {t(
                    "carry on your activities in connection with the Services in compliance with any applicable laws and regulations;"
                  )}
                </li>
                <li>
                  {t(
                    "conduct your business transactions with other users of the Services in good faith;"
                  )}
                </li>
                <li>
                  {t(
                    "carry on your activities in accordance with the Terms and any applicable Additional Agreements;"
                  )}
                </li>
                <li>
                  {t(
                    "not use the Services to defraud any person or entity (including without limitation sale of stolen items, use of stolen credit/debit cards);"
                  )}
                </li>
                <li>
                  {t(
                    "not impersonate any person or entity, misrepresent yourself or your affiliation with any person or entity;"
                  )}
                </li>
                <li>{t("not engage in spamming or phishing;")}</li>
                <li>
                  {t(
                    "not engage in any other unlawful activities (including without limitation those which would constitute a criminal offence, give rise to civil liability, etc) or encourage or abet any unlawful activities;"
                  )}
                </li>
                <li>
                  {t(
                    "not involve attempts to copy, reproduce, exploit or expropriate Tripbonder\u2019s various proprietary directories, databases and listings;"
                  )}
                </li>
                <li>
                  {t(
                    "not involve any computer viruses or other destructive devices and codes that have the effect of damaging, interfering with, intercepting or expropriating any software or hardware system, data or personal information;"
                  )}
                </li>
                <li>
                  {t(
                    "not involve any scheme to undermine the integrity of the data, systems or networks used by Tripbonder and/or any user of the Sites or gain unauthorized access to such data, systems or networks;"
                  )}
                </li>
                <li>
                  {t(
                    "not engage in any activities that would otherwise create any liability for Tripbonder or our affiliates."
                  )}
                </li>
              </ol>
              <p>
                {t(
                  "Travel Service Provider may not use the Services and member account to engage in activities which are identical or similar to Tripbonder\u2019s e-commerce marketplace business."
                )}
              </p>
              <h6>{t("Pricing Terms and Conditions")}</h6>
              <p>
                {t(
                  "This section relates only to Pricing of Travel Services by Travel Service Providers on www.tripbonder.com"
                )}
              </p>
              <p>
                {t(
                  "Pricing is set by the travel service providers directly and not by Tripbonder. The travel service provider will price your services in US Dollars. However, Travel Service Provider can make arrangement with Tripbonder to have the payment paid to you in your native currency separately."
                )}{" "}
              </p>
              <h6>{t("Deposit, Refund and Payment")}</h6>
              <p>
                {t(
                  "Rules for Deposit:If the departure date is more than 90 days away, the deposit of 50% of the total cost of the tour, is payable to Tripbonder on booking. If the departure date is less than 90 days away, 100% of the total cost of the tour is payable to Tripbonder on booking."
                )}
              </p>
              <p>
                {t(
                  "If the departure date is less than 90 days away, the User is responsible for paying the balance of the tour cost through the payment gateway and payment method captured during the deposit payment process."
                )}
              </p>
              <h6>{t("Rules for Refund to the User:")}</h6>
              <p>
                {t(
                  "This deposit is fully refundable (less 5% of processing fees) to the traveller under the following circumstances:"
                )}
              </p>
              <ol>
                <li>
                  {t(
                    "At the end of the trip, Trip Service Provider is presence and no complaint is received from the Traveller"
                  )}
                </li>
                <li>
                  {t(
                    "Full payment (less 18% of service charge*) is made via online payment in US Dollar, or a separate arrangement between Trip Service Provider and Tripbonder"
                  )}
                </li>
              </ol>
              <p>
                {t(
                  "*With the exception of Service Provider in Bhutan, a flat fee of USD 7 per head per day is rendered for services by Tripbonder.com"
                )}
              </p>
              <h6>{t("Hyperlinks")}</h6>
              <p>
                {t(
                  "You are granted a limited, non-exclusive right to create a text hyperlink to the Site for noncommercial purposes, provided such link does not portray Tripbonder or any of its products or services in a false, misleading, derogatory or otherwise defamatory manner and provided further that the linking site does not contain any adult or illegal material or any material that is offensive, harassing or otherwise objectionable. This limited right may be revoked at any time. You may not use a Tripbonder logo or other proprietary graphic of Tripbonder to link to the Site without the express written permission of Tripbonder. Further, you may not use, frame or utilize framing techniques to enclose any Tripbonder trademark, logo or other proprietary information, including the images found at the Site, the content of any text or the layout/design of any page or form contained on a page on the Site without Tripbonder's express written consent. Except as noted above, you are not conveyed any right or license by implication, estoppel or otherwise in or under any trademark, copyright or proprietary right of Tripbonder or any third party. Nothing contained on the Site may be construed as granting, by implication, estoppel or otherwise, any license or right to use any trademark, copyright or other proprietary right of Tripbonder or any third party without the prior written permission of Tripbonder or such other party that may own such trademark, copyright or other proprietary right(s)."
                )}
              </p>
              <p>{t("Content posted on Tripbonder.com :")}</p>
              <p>
                {t(
                  'You understand and agree that Tripbonder may review, modify, or delete, without notice any data, text, software, music, sound, message, photograph, graphic, image, video, profile, blog, review, information, comment, work of authorship, or other material or expression (collectively, "Content"), whether posted publicly or posted privately to other Users, that in the sole judgment of Tripbonder violates these Terms might be offensive, indecent, or illegal, or might harm, violate the rights, or threaten the safety of any person. Tripbonder is the final arbiter of what is and is not allowed on the Website.'
                )}
              </p>
              <p>
                {t(
                  "You are solely responsible for the Content that you publish, transmit, upload, communicate, or display on the Website and/or through the Service, whether posted publicly or posted privately to other Users. You understand that Tripbonder has no obligation to monitor the Website, or monitor, control or edit the Content thereon, or monitor any use of the Service by Users, or remove inappropriate Content. Tripbonder is not responsible in any way for the Content posted by Users on any area of the Website, including but not limited to Profiles, Passion Groups, Reviews, Blogs, Albums, and messages, or otherwise posted through the Service. By posting Content on the Website and/or through the Service, you agree to bear all risks associated with the accuracy and completeness of such Content."
                )}
              </p>
              <p>
                {t(
                  'If you post Content to any public area of Tripbonder, you automatically grant to Tripbonder an irrevocable, perpetual, non-exclusive, royalty-free worldwide license to use, reproduce, publicly perform, display, modify, adapt, distribute, access, view, store, prepare derivative works of, incorporate into other works, and/or otherwise exploit such content, or any part thereof, including but not limited to any copyrighted material, and your name, image, and likeness, for any purpose and in any form, media, or technology now known or later developed, including but not limited to featuring such Content, name, image, or likeness on Tripbonder\'s homepage (www.tripbonder.com) or any other page of the Website, or in any marketing, promotional materials or advertisements for Tripbonder, and to grant and authorize sublicenses of the foregoing (the "License"). You further agree to permit anyone to access, display, view, store and reproduce such Content for personal use. By posting any information about or photographs or images of yourself on the Website and/or through the Service, you thereby waive any rights of privacy or publicity to such information, photographs, or images.'
                )}
              </p>
              <p>
                {t(
                  "You further represent and warrant that: You exclusively and irrevocably own the Content posted by you on the website and/or through the Service or otherwise have the right to grant the License set forth in this section; and any Content posted by you on the website and/or through the Service does not violate or infringe the privacy rights, copyright, trademarks, moral rights or other intellectual property rights, or proprietary rights of any person or entity. You agree to pay all royalties, fees, and other monies owing to any person or entity by reason of any Content that you post on the website and/or through the Service."
                )}
              </p>
              <p>
                {t(
                  "The following is a partial list of the kind of Content that you are prohibited from posting on the website and/or through the Service. Tripbonder reserves the right to investigate and take appropriate legal action against anyone who, in Tripbonder's sole discretion, violates this provision, including but not limited to removing the offending Content from the Website and terminating the Subscription of such violators. Prohibited Content includes but is not limited to Content that, in Tripbonder's sole discretion:"
                )}
              </p>
              <ul>
                <li>{t("is vulgar, profane, abusive or indecent;")}</li>
                <li>
                  {t("contains nudity, violence, or offensive subject matter;")}
                </li>
                <li>
                  {t(
                    "is patently offensive to the online community, including but not limited to Content that promotes racism, bigotry, hatred or physical harm of any kind against any group or individual, or cruelty to animals;"
                  )}
                </li>
                <li>{t("exploits people in a sexual or violent manner;")}</li>
                <li>
                  {t(
                    "threatens, harasses, or advocates harassment of another person;"
                  )}
                </li>
                <li>{t("invades another person's privacy;")}</li>
                <li>
                  {t(
                    'involves the transmission of "junk mail," "chain letters," unsolicited mass mailing or "spamming;"'
                  )}
                </li>
                <li>
                  {t(
                    "promotes illegal or unauthorized copying of another person's or entity's copyrighted work, including but not limited to providing pirated computer programs or links thereto, providing information to circumvent manufacture-installed copy-protect devices or digital rights management software, or providing pirated music or videos or links thereto;"
                  )}
                </li>
                <li>
                  {t(
                    "contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page of the Website);"
                  )}
                </li>
                <li>
                  {t(
                    "exploits people under the age of eighteen (18) in a sexual, violent, or otherwise objectionable manner, or solicits personal information from anyone under the age of eighteen (18);"
                  )}
                </li>
                <li>
                  {t(
                    "involves pornographic or sexually explicit material of any kind, including but not limited to sexually suggestive imagery or language; or"
                  )}
                </li>
                <li>
                  {t(
                    "promotes, furthers, or provides instructional information about illegal activities, including but not limited to making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses."
                  )}
                </li>
              </ul>
              <p>
                {t(
                  "Notwithstanding the prohibition on the content listed above, you might become exposed to such information or material, in which event, you agree to hold harmless and covenant not to sue Tripbonder for any such exposure and any injuries based thereon."
                )}
              </p>
              <p>
                {t(
                  "Tripbonder reserves the right to disclose any Content posted on the Website and/or through the Service as necessary to satisfy any applicable law, regulation, legal process or governmental request. Advertisements and Promotions"
                )}
              </p>
              <p>
                {t(
                  "Tripbonder may run advertisements and promotions from third parties on the Site. Your business dealings or correspondence with, or participation in promotions of, advertisers other than Tripbonder, and any terms, conditions, warranties or representations associated with such dealings, are solely between you and such third party. Tripbonder is not responsible or liable for any loss or damage of any sort incurred as the result of any such dealings or as the result of the presence of third-party advertisers on the Site. Copyright Complaints"
                )}
              </p>
              <p>
                {t(
                  "If you believe that material posted on the Site infringes upon any copyright that you own or control, or that any link on the Site directs users to another Web site that contains materials that infringes upon any copyright that you hold or control, you may file a notification of such infringement. We may give notice of a claim of copyright infringement to our users by means of a general notice on the Site, electronic mail to a user's email address in our records. User Disputes"
                )}
              </p>
              <h6>{t("Dispute")}</h6>
              <p>
                {t(
                  "In the event that any User has a dispute with any party to a transaction, such User agrees to release and indemnify Tripbonder (and our agents, affiliates, directors, officers and employees) from all claims, demands, actions, proceedings, costs, expenses and damages (including without limitation any actual, special, incidental or consequential damages) arising out of or in connection with such transaction."
                )}
              </p>
              <h6>{t("Disclaimer and Limitation of Liability")}</h6>
              <p>
                {t(
                  "You agree that your use of the Tripbonder website shall be at your sole risk. To the fullest extent permitted by law, Tripbonder, its officers, directors, employees, and agents disclaim all warranties, express or implied, in connection with the website and your use thereof. Tripbonder makes no warranties or representations about the accuracy or completeness of this site's content or the content of any sites linked to this site and assumes no liability or responsibility for:"
                )}
              </p>
              <ul>
                <li>{t("errors, mistakes, or inaccuracies in Content;")}</li>
                <li>
                  {t(
                    "personal injury or property damage, of any nature whatsoever, resulting from your access to and use of our website and local personal travel service providers;"
                  )}
                </li>
                <li>
                  {t(
                    "any unauthorised access to or use of our secure servers and/or any and all personal information and/or financial information stored therein;"
                  )}
                </li>
                <li>
                  {t(
                    "any interruption or cessation of transmission to or from our website;"
                  )}
                </li>
                <li>
                  {t(
                    "any bugs, viruses, trojan horses, or the like which may be transmitted to or through our website by any third party;"
                  )}
                </li>
                <li>
                  {t(
                    "any errors or omissions in any Content or for any loss or damage of any kind incurred as a result of the use of any Content posted, emailed, transmitted, or otherwise made available via the Tripbonder website; or"
                  )}
                </li>
                <li>
                  {t(
                    "any product or service advertised or offered by a third party through the Tripbonder website or any hyperlinked website or featured in any banner or other advertising, and Tripbonder will not be a party to or in any way be responsible for monitoring any transaction between you and third-party providers of products or services. As with the purchase of a product or service through any medium or in any environment, you should use your best judgment and exercise caution where appropriate."
                  )}
                </li>
              </ul>
              <p>
                {t(
                  "The foregoing limitation of liability shall apply to the fullest extent permitted by law in each applicable jurisdiction. You specifically acknowledge that Tripbonder shall not be liable for user submissions or the defamatory, offensive, or illegal conduct of any third party and that the risk of harm or damage from the foregoing rests entirely with you. Tripbonder makes no representations that the Tripbonder Website is appropriate or available for use in any particular location. Those who access or use the Tripbonder Website are responsible for compliance with local law."
                )}
              </p>
              <p>
                {t(
                  "Under no circumstances, including but not limited to any act or omission on the part of Tripbonder, will Tripbonder be liable for any indirect, incidental, special and/or consequential damages whatsoever which result from any use or access of, or any inability to use or access, this website or its content, including but not limited to any dealings with the travel service providers."
                )}
              </p>
              <h6>{t("Indemnity")}</h6>
              <p>
                {t(
                  "You agree to defend, indemnify and hold harmless Tripbonder, its parent or related corporations, officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from:"
                )}
              </p>
              <ul>
                <li>
                  {t("Your use of and access to the Tripbonder Website;")}
                </li>
                <li>{t("Your use of the Tripbonder Service;")}</li>
                <li>{t("Your violation of Terms;")}</li>
                <li>
                  {t(
                    "Your violation of any third party right, including without limitation any copyright, property, or privacy right;"
                  )}
                </li>
                <li>
                  {t(
                    "Any claim that one of your User Submissions caused damage to a third party; or"
                  )}
                </li>
                <li>
                  {t(
                    "Personal injury or property damage, of any nature whatsoever, resulting from your access to and use of our website or local personal travel service providers."
                  )}
                </li>
              </ul>
              <h6>{t("Severability")}</h6>
              <p>
                {t(
                  "If any provision of these Terms is deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severed from these Terms and shall not affect the validity and enforceability of any remaining provisions."
                )}
              </p>
              <h6>{t("Questions and Comments")}</h6>
              <p>
                {t(
                  "If you have any questions regarding these Terms or your use of the website, please submit them to us via contact Tripbonder."
                )}
              </p>
              <div className="border-wrapper mb-3 mr-4">
                <h6>{t("USE RESTRICTIONS")}</h6>
              </div>
              <p className="mb-0">
                {t(
                  "Your permission to use the Site is conditioned upon the following use, posting and conduct restrictions:"
                )}
              </p>
              <p>{t("You agree that you will not under any circumstances:")}</p>
              <p>
                {t(
                  "\xB7 access the Service for any reason other than your personal, non-commercial use solely as permitted by the normal functionality of the Service,"
                )}
              </p>
              <p>
                {t(
                  "\xB7 collect or harvest any personal data of any user of the Site or the Service"
                )}{" "}
              </p>
              <p>
                {t(
                  "\xB7 use the Site or the Service for the solicitation of business in the course of trade or in connection with a commercial enterprise;"
                )}
              </p>
              <p>
                {t(
                  "\xB7 distribute any part or parts of the Site or the Service without our explicit written permission (we grant the operators of public search engines permission to use spiders to copy materials from the site for the sole purpose of creating publicly-available searchable indices but retain the right to revoke this permission at any time on a general or specific basis);"
                )}
              </p>
              <p>
                {t(
                  "\xB7 use the Service for any unlawful purpose or for the promotion of illegal activities;"
                )}
              </p>
              <p>
                {t(
                  "\xB7 attempt to, or harass, abuse or harm another person or group;"
                )}
              </p>
              <p>
                {t("\xB7 use another user\u2019s account without permission;")}
              </p>
              <p>
                {t(
                  "\xB7 intentionally allow another user to access your account;"
                )}
              </p>
              <p>
                {t(
                  "\xB7 provide false or inaccurate information when registering an account;"
                )}
              </p>
              <p>
                {t(
                  "\xB7 interfere or attempt to interfere with the proper functioning of the Service;"
                )}
              </p>
              <p>
                {t(
                  "\xB7 make any automated use of the Site, the Service or the related systems, or take any action that we deem to impose or to potentially impose an unreasonable or disproportionately large load on our servers or network infrastructure;"
                )}
              </p>
              <p>
                {t(
                  "\xB7 bypass any robot exclusion headers or other measures we take to restrict access to the Service, or use any software, technology, or device to scrape, spider, or crawl the Service or harvest or manipulate data;"
                )}{" "}
              </p>
              <h6 className="pt-3">{t("Contact Information")}</h6>
              <h6 className="mb-0">{t("Tripbonder Admin")}</h6>
              <p className="mb-0">
                {t("Email:")}{" "}
                <a href="mailto:tbadmin@tripbonder.com">
                  {t("tbadmin@tripbonder.com>")}
                </a>
              </p>
              <p className="mb-0">{t("Address: TRIPBONDER PTE LTD")}</p>
              <p>{t("28C STANLEY STREET SINGAPORE (068737)")}</p>
              <p>
                {t(
                  "The Tripbonder website is operated under jurisdiction of Singapore Law."
                )}
              </p>
            </div>
            {props.local.isAuthenticated === true ? (
              <>
                <hr className="bottom-border ml-2" />
                <div className="d-sm-none pl-2 controls-wrapper">
                  {props.local.currentUser.hasTripbonderDashboardAccess ===
                  true ? (
                    <>
                      <Link
                        to="/dashboard"
                        onClick={() => scrollToTop()}
                        className="btn btn-light px-4 mr-3 btn-block"
                      >
                        {t("Go to Dashboard")}
                      </Link>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => openTripbonderAccount()}
                        type="button"
                        className="btn btn-success-gradient px-4 btn-block"
                      >
                        {t("Agree & Continue")}
                      </button>
                      <Link
                        to="/tripbonder/get-started"
                        className="btn btn-light px-4 mr-3 btn-block"
                      >
                        {t("Don't Agree")}
                      </Link>
                    </>
                  )}
                </div>
                <div className="text-right d-none d-sm-block controls-wrapper">
                  {props.local.currentUser.hasTripbonderDashboardAccess ===
                  true ? (
                    <>
                      <Link
                        to="/dashboard"
                        onClick={() => scrollToTop()}
                        className="btn btn-light px-4 mr-3"
                      >
                        {t("Go to Dashboard")}
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        to="/tripbonder/get-started"
                        className="btn btn-light px-4 mr-3"
                      >
                        {t("Don't Agree")}
                      </Link>
                      <button
                        onClick={() => openTripbonderAccount()}
                        type="button"
                        className="btn btn-success-gradient px-4"
                      >
                        {t("Agree & Continue")}
                      </button>
                    </>
                  )}
                </div>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default connect(state => ({
  local: state.local
}))(Agreement);
