import { withTranslation } from "react-i18next";
import React from "react";
import { connect } from "react-redux";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactTable from "react-table";
import { Modal } from "reactstrap";
import matchSorter from "match-sorter";
import { WalletServices } from "../../services/Wallet.service";
import Controller from "../../store/controllers/index";
import { NETWORK_ERR_MESSAGE } from "../../resources/String";
import moment from "moment";
import getConfig from "../../config";
const Wallets = withTranslation()(
  class Wallets extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        data: [],
        debit: 0,
        credit: 0,
        balance: 0,
        withdrawal: 0,
        isCashWithdrawalModalOpen: false,
        beneficiaryChannel: "telegraphic-transfer"
      };

      this.toggleCashWithdrawalModal = this.toggleCashWithdrawalModal.bind(
        this
      );

      this.fetchWallet = this.fetchWallet.bind(this);
      this.switchingToBankAccount = this.switchingToBankAccount.bind(this);
      this.switchingToPaypal = this.switchingToPaypal.bind(this);
      this.withdrawalamount = this.withdrawalamount.bind(this);
      this.withdrawalfunction = this.withdrawalfunction.bind(this);
      this.renderStatus = this.renderStatus.bind(this);
    }

    withdrawalamount(e) {
      this.setState({
        withdrawal: e.target.value
      });
    }

    switchingToPaypal() {
      this.setState({
        beneficiaryChannel: "paypal"
      });
    }

    switchingToBankAccount() {
      this.setState({
        beneficiaryChannel: "telegraphic-transfer"
      });
    }

    toggleCashWithdrawalModal() {
      if (this.props.local.currentUser.availableBeneficiaryChannel === null) {
        Controller.setGlobalError(
          "Please add a bank account or paypal account in your profile" ||
            NETWORK_ERR_MESSAGE,
          true
        );
      } else {
        this.setState({
          isCashWithdrawalModalOpen: !this.state.isCashWithdrawalModalOpen,
          withdrawal: ""
        });
      }
    }

    fetchWallet() {
      Controller.setGlobalLoader("Fetching Summary...", false);
      setTimeout(() => {
        WalletServices.GetWalletDetails()
          .then(summary => {
            Controller.clearGlobalMessage();
            this.setState({
              debit: summary.totalDebit,
              credit: summary.totalCredit,
              balance: summary.balance,
              withdrawal: summary.balance,
              data: summary.transactions.map(transaction => {
                transaction.createdAtFormatted = moment(
                  transaction.createdAt
                ).format("ll");
                return transaction;
              })
            });
          })
          .catch(err => {
            Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
          });
      }, 800);
    }

    withdrawalfunction(e) {
      e && e.preventDefault();
      const payload = {
        amountInUSD: this.state.withdrawal,
        beneficiaryChannel: this.state.beneficiaryChannel,
        bankName: this.props.local.currentUser.bankName,
        bankAccountNumber: this.props.local.currentUser.bankAccountNumber,
        bankBranchCode: this.props.local.currentUser.bankBranchCode,
        bankBranchLocation: this.props.local.currentUser.bankBranchLocation,
        paypalEmailAddress: this.props.local.currentUser.paypalEmailAddress
      };

      Controller.setGlobalLoader("Processing...", false);
      setTimeout(() => {
        WalletServices.Withdrawal(payload)
          .then(withdrawalDetails => {
            Controller.setGlobalSuccess("Withdawal requested", true);
            setTimeout(() => {
              this.setState({
                withdrawal: null,
                isCashWithdrawalModalOpen: false
              });

              this.fetchWallet();
            }, 1200);
          })
          .catch(err => {
            Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
          });
      }, 800);
    }

    renderDescription(row) {
      const data = row.original;
      let cellComponent;
      switch (data.trType) {
        case "order-credit": {
          cellComponent = (
            <>
              <div>
                {this.props.t("Order Id:")}
                {data.order}
              </div>
              <div>{data.narration}</div>
            </>
          );

          break;
        }
        case "withdrawal-debit": {
          switch (data.beneficiaryChannel) {
            case "paypal": {
              cellComponent = (
                <>
                  <div>
                    {this.props.t("Paypal:")}
                    {data.paypalEmailAddress}
                  </div>
                  <div>{data.narration}</div>
                </>
              );

              break;
            }
            case "telegraphic-transfer": {
              cellComponent = (
                <>
                  <div>
                    {this.props.t("Bank Name:")}
                    {data.bankName}
                  </div>
                  <div>
                    {this.props.t("Bank Account No:")}
                    {data.bankAccountNumber}
                  </div>
                  <div>
                    {this.props.t("Bank Branch Code:")}
                    {data.bankBranchCode}
                  </div>
                  <div>
                    {this.props.t("Bank Branch Location:")}
                    {data.bankBranchLocation}
                  </div>
                  <div>{data.narration}</div>
                </>
              );

              break;
            }
            default: {
              cellComponent = null;
            }
          }

          break;
        }
        default: {
          cellComponent = null;
          break;
        }
      }

      return cellComponent;
    }

    renderStatus(row) {
      const status = row.original.trStatus;
      let currentStatusColor;
      switch (status) {
        case "requested": {
          currentStatusColor = "requested-yellow";
          break;
        }
        case "processing": {
          currentStatusColor = "processing-blue";
          break;
        }
        case "processed": {
          currentStatusColor = "processed-green";
          break;
        }
        case "void": {
          currentStatusColor = "void-red";
          break;
        }
        default: {
          currentStatusColor = "requested-yellow";
          break;
        }
      }

      return (
        <div className={`${currentStatusColor} text-capitalize`}>{status}</div>
      );
    }

    componentDidMount() {
      this.fetchWallet();
    }

    render() {
      const {
        match: {
          params: { status }
        }
      } = this.props;

      let currentTab;
      let dataToDisplay = [];
      switch (status) {
        case "credit": {
          currentTab = "credit";
          dataToDisplay = this.state.data.filter(
            transaction => transaction.trType === "order-credit"
          );

          break;
        }
        case "debit": {
          currentTab = "debit";
          dataToDisplay = this.state.data.filter(
            transaction => transaction.trType === "withdrawal-debit"
          );

          break;
        }
        default: {
          currentTab = "all";
          dataToDisplay = this.state.data;
        }
      }

      return (
        <React.Fragment>
          <Fade duration={300}>
            <Helmet>
              <title>{this.props.t("Wallet - Tripbonder.com")}</title>
            </Helmet>
            <div className="container-fluid wallet-wrapper">
              <div className="row px-4 py-2 py-xl-4">
                <div className="col-12 background-wrapper">
                  <div className="row pt-2">
                    <div className="col-12 Wallet-header-wrapper">
                      <div className="row">
                        <div className="col-12 text-center pt-3 title">
                          <label>
                            {this.props.t("See what's in for you,")}{" "}
                            {this.props.local.currentUser.name}
                            {this.props.t("!")}
                          </label>
                          <h3>{this.props.t("My Wallet")}</h3>
                        </div>
                      </div>
                      <div className="row pt-2 pb-4 pt-lg-4 pl-0">
                        <div className="col-12 col-lg-4  pl-lg-5">
                          <div className="money-wallet balance text-right pt-2 pr-4">
                            <label>{this.props.t("All debits")}</label>
                            <h3>
                              {this.props.t("$")}
                              {this.state.debit}
                            </h3>
                          </div>
                        </div>
                        <div className="col-12 col-lg-4 pt-3 pt-lg-0">
                          <div className="money-wallet debit text-right pt-2 pr-4">
                            <label>{this.props.t("All credits")}</label>
                            <h3>
                              {this.props.t("$")}
                              {this.state.credit}
                            </h3>
                          </div>
                        </div>
                        <div className="col-12 col-lg-4 pr-lg-5 pt-3 pt-lg-0">
                          <div className="money-wallet credit text-right pt-2 pr-4">
                            <label>{this.props.t("Your wallet balance")}</label>
                            <h3>
                              {this.props.t("$")}
                              {this.state.balance}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row pt-2 activity pl-0 pl-xl-2">
                <div className="col-12 col-xl-3 pt-2 text-center text-xl-left">
                  <h5>{this.props.t("Transaction History")}</h5>
                </div>
                <div className="col-12 col-xl-6 text-center pt-3 pt-xl-0">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <Link
                      to="/dashboard/account/wallet"
                      className={`${
                        currentTab === "all"
                          ? "active-button"
                          : "inactive-button"
                      } btn btn-secondary px-4`}
                    >
                      {this.props.t("All")}
                    </Link>
                    <Link
                      to="/dashboard/account/wallet/credit"
                      className={`${
                        currentTab === "credit"
                          ? "active-button"
                          : "inactive-button"
                      } btn btn-secondary px-4`}
                    >
                      {this.props.t("Credit")}
                    </Link>
                    <Link
                      to="/dashboard/account/wallet/debit"
                      className={`${
                        currentTab === "debit"
                          ? "active-button"
                          : "inactive-button"
                      } btn btn-secondary px-4`}
                    >
                      {this.props.t("Debit")}
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-xl-6 text-center text-xl-left pt-3 mt-0 mt-xl-2">
                  <div className=" refresh ">
                    <a
                      className="export-to-csv"
                      href={`${
                        getConfig().SERVER_BASE_ADDRS
                      }api/wallet?csv=true`}
                      target="blank"
                    >
                      <i className="fas fa-cloud-download-alt pr-1"></i>
                      {this.props.t("Export to CSV")}
                    </a>
                  </div>
                </div>
                <div className="col-12 col-xl-6 text-center text-xl-right pt-2 pt-xl-3 ">
                  <div className="d-xl-inline">
                    <button
                      onClick={() => this.fetchWallet()}
                      className="coupon-delete-edit-refresh-button-wrapper mr-lg-2"
                    >
                      <i
                        className="fas fa-redo-alt pr-2"
                        style={{ fontSize: 12 }}
                      ></i>
                      {this.props.t("Refresh")}
                    </button>
                  </div>
                  <button
                    onClick={() => this.toggleCashWithdrawalModal()}
                    className="btn btn-warning-gradient mt-2 mt-xl-0 btn-sm"
                  >
                    {this.props.t("Withdraw to bank Account")}
                  </button>
                </div>
                <div className="col-3"></div>
              </div>
              <div className="row">
                <div className="col-12 my-4 px-0">
                  <div className="table-responsive">
                    <ReactTable
                      showPaginationTop={true}
                      showPaginationBottom={true}
                      defaultPageSize={5}
                      data={dataToDisplay}
                      filterable
                      columns={[
                        {
                          Header: this.props.t("DATE"),
                          accessor: "createdAtFormatted",
                          filterable: false,
                          Filter: ({ filter, onChange }) => (
                            <input
                              onChange={event => onChange(event.target.value)}
                              style={{ width: "100%" }}
                              value={filter ? filter.value : ""}
                              type="date"
                            ></input>
                          )
                        },

                        {
                          Header: this.props.t("DESCRIPTION"),
                          accessor: "description",
                          filterable: true,
                          Cell: this.renderDescription.bind(this),
                          width: 500,
                          filterMethod: (filter, rows) => {
                            return matchSorter(rows, filter.value, {
                              keys: [
                                "_original.paypalEmailAddress",
                                "_original.id",
                                "_original.bankAccountNumber",
                                "_original.bankName",
                                "_original.bankBranchCode",
                                "_original.bankBranchLocation"
                              ]
                            });
                          },
                          filterAll: true
                        },

                        {
                          Header: this.props.t("($)AMOUNT"),
                          accessor: "amountInUSD",
                          filterable: false
                        },

                        {
                          Header: this.props.t("(-) COMMISSION"),
                          accessor: "serviceCharge",
                          filterable: false
                        },

                        {
                          Header: this.props.t("STATUS"),
                          accessor: "trStatus",
                          filterable: true,
                          Cell: this.renderStatus,
                          filterMethod: (filter, rows) => {
                            return matchSorter(rows, filter.value, {
                              keys: ["trStatus"]
                            });
                          },
                          filterAll: true
                        },

                        {
                          Header: this.props.t("($)DEBIT"),
                          accessor: "debitAmount",
                          filterable: false,
                          className: "text-right",
                          headerClassName: "text-right",
                          style: { fontWeight: "bold" }
                        },

                        {
                          Header: this.props.t("($)CREDIT"),
                          accessor: "creditAmount",
                          filterable: false,
                          className: "text-right",
                          headerClassName: "text-right",
                          style: { fontWeight: "bold" }
                        }
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Fade>
          <Modal
            isOpen={this.state.isCashWithdrawalModalOpen}
            toggle={e => this.toggleCashWithdrawalModal()}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {this.props.t("Trasnfer To Bank Account")}
                </h5>
                <button
                  onClick={e => this.toggleCashWithdrawalModal()}
                  type="button"
                  className="close"
                  aria-label="Close"
                >
                  <span aria-hidden="true">{this.props.t("\xD7")}</span>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={e => this.withdrawalfunction(e)}>
                  <div className="form-group">
                    <div className="text-center">
                      <h6>{this.props.t("Available Amount")}</h6>
                      <h3 style={{ fontWeight: "bold" }}>
                        {this.props.t("$")}
                        {this.state.balance}
                      </h3>
                      <hr />
                    </div>
                    <label htmlFor="withdrawal-amount">
                      {this.props.t("Amount In USD")}
                    </label>
                    <input
                      value={this.state.withdrawal}
                      onChange={e => this.withdrawalamount(e)}
                      id="withdrawal-amount"
                      type="text"
                      className="form-control form-control-sm"
                      placeholder={this.props.t("Enter Amount")}
                    />

                    <div className="pt-2">
                      <input
                        checked={
                          this.state.beneficiaryChannel ===
                          "telegraphic-transfer"
                        }
                        type="radio"
                        id="bank-account"
                        onChange={() => this.switchingToBankAccount()}
                        value="other"
                        className="mr-1"
                      />

                      <label htmlFor="bank-account" className="mr-2">
                        {this.props.t("Bank Account")}
                      </label>
                      <input
                        checked={this.state.beneficiaryChannel === "paypal"}
                        type="radio"
                        id="paypal"
                        className="mr-1"
                        onChange={() => this.switchingToPaypal()}
                      />

                      <label htmlFor="paypal" className="mr-2">
                        {this.props.t("Paypal")}
                      </label>
                    </div>
                    {this.state.beneficiaryChannel === "paypal" ? (
                      <div className="form-group">
                        <label style={{ fontSize: 13 }} htmlFor="email-address">
                          {this.props.t("Email address")}
                        </label>
                        <input
                          key="paypal-email"
                          disabled={true}
                          value={
                            this.props.local.currentUser.paypalEmailAddress ||
                            ""
                          }
                          type="email"
                          className="form-control form-control-sm"
                          id="email-address"
                          aria-describedby="emailHelp"
                          placeholder={this.props.t("Enter email")}
                        />
                      </div>
                    ) : (
                      <div className="form-group">
                        <label style={{ fontSize: 13 }} htmlFor="bank-name">
                          {this.props.t("Bank Name")}
                        </label>
                        <input
                          key="bank-name"
                          disabled={true}
                          value={this.props.local.currentUser.bankName || ""}
                          type="text"
                          id="bank-name"
                          className="form-control form-control-sm"
                          placeholder={this.props.t("Bank Name")}
                        />

                        <label
                          style={{ fontSize: 13 }}
                          htmlFor="account-number"
                        >
                          {this.props.t("Account Number")}
                        </label>
                        <input
                          disabled={true}
                          value={
                            this.props.local.currentUser.bankAccountNumber || ""
                          }
                          type="text"
                          id="account-number"
                          className="form-control form-control-sm"
                          placeholder={this.props.t("Account Number")}
                        />

                        <label style={{ fontSize: 13 }} htmlFor="bank-code">
                          {this.props.t("Bank Code")}
                        </label>
                        <input
                          disabled={true}
                          value={
                            this.props.local.currentUser.bankBranchCode || ""
                          }
                          type="text"
                          id="bank-code"
                          className="form-control form-control-sm"
                          placeholder={this.props.t("Bank Code")}
                        />

                        <label style={{ fontSize: 13 }} htmlFor="branch">
                          {this.props.t("Branch")}
                        </label>
                        <input
                          disabled={true}
                          value={
                            this.props.local.currentUser.bankBranchLocation ||
                            ""
                          }
                          type="text"
                          id="branch"
                          className="form-control form-control-sm"
                          placeholder={this.props.t("Branch")}
                        />
                      </div>
                    )}
                  </div>
                  <div className="text-right">
                    <button
                      onClick={e => this.toggleCashWithdrawalModal()}
                      type="button"
                      className="btn btn-secondary mr-2"
                    >
                      {this.props.t("cancel")}
                    </button>
                    <button
                      disabled={
                        this.state.withdrawal === 0 ||
                        this.state.withdrawal === null ||
                        this.state.withdrawal === ""
                      }
                      type="submit"
                      className="btn btn-success"
                    >
                      {this.props.t("Request Withdrawal")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Modal>
        </React.Fragment>
      );
    }
  }
);

export default connect(state => ({
  local: state.local
}))(Wallets);
