import { withTranslation } from "react-i18next";
import React from "react";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet";
const ComposeDashboard = withTranslation()(
  class ComposeDashboard extends React.Component {
    render() {
      return (
        <Fade duration={300}>
          <Helmet>
            <title>
              {this.props.t(
                "Welcome to the Tripbonder - Asia's leading trip and travel planning portal"
              )}
            </title>
          </Helmet>
          <div className="container-fluid who-is-tripbonder px-0">
            <div className="row">
              <div className="col-12 header-wrapper">
                <div className="p-4">
                  <h6>{this.props.t("Who is Tripbonder")}</h6>
                  <hr />
                </div>
                <div className="container">
                  <div className="row pr-0 ">
                    <div className="col-4 d-none d-md-block"></div>
                    <div
                      className="col-12 col-md-8 cover-pic text-left text-sm-right  pt-5"
                      // style={{
                      //   backgroundImage: `url('${require("./../../assets/images/women-bag.jpg")}')`
                      // }}
                    >
                      <div className="pt-md-3 pt-ls-5 pl-2">
                        <h1 className="">
                          {this.props.t(
                            "A platform that aims to make your travel experience memorable, fun and worry-free."
                          )}
                        </h1>
                        <p className="mt-3 pt-1 pt-md-2">
                          {this.props.t(
                            "Tripbonder creates an extremely user-friendly platform for trip-goer and trip service providers to offer their service to traveller like you. In here, you will find the travel experience that you have ever wanted."
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section className="content-wrapper1 mt-2">
              <div className="row background-img1">
                <div className="col-12">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 text-center section-heading">
                        <h1 className="mt-5 mb-4">
                          {this.props.t("WHAT'S THE STORY")}
                        </h1>
                        <p>
                          {this.props.t(
                            "Everyone likes to travel. But not everyone likes the travelling. Not everyone wants to go through the pain of putting a perfect trip together!"
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="row img-wrapper py-5">
                      <div className="col-6 text-center">
                        <img
                          src={require("../../assets/images/marketing/too-much-work.jpg")}
                          alt=""
                        />

                        <div className="pt-3 mb-3">
                          <i className="fas fa-times-circle"></i>
                        </div>
                        <span className="">
                          {this.props.t("Too much work in travelling")}
                        </span>
                      </div>
                      <div className="col-6 text-center ">
                        <img
                          src={require("../../assets/images/marketing/maps.jpg")}
                          alt=""
                        />

                        <div className="pt-3 mb-3">
                          <i className="fas fa-times-circle"></i>
                        </div>
                        <span>
                          {this.props.t(
                            "The researches, the decisions, the reservations, the confirmations, the delays & cancellations"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="content-wrapper2 mt-2">
              <div className="row background-img2">
                <div className="col-12">
                  <div className="container">
                    <div className="row">
                      <div className="col-12 mt-5 mb-5 ">
                        <div className="solution-wrapper mb-3">
                          <h4 className="mr-2">{this.props.t("Solution?")}</h4>
                          <span>{this.props.t("Tripbonder.")}</span>
                        </div>
                        <h2>
                          {this.props.t(
                            "A go to platform for anything in-location, in-country, in-the-moment travel experiences"
                          )}
                        </h2>
                        <div className="website-link-wrapper mt-5">
                          <a className="mr-1" href="/">
                            {this.props.t("Tripbonder.com")}
                          </a>
                          <p>
                            {this.props.t(
                              "is the place to discover unique, customizable experience through connecting with local travel guides or local trip-goer. The justification is simple - the locals know the local scenes the best. And they are empowered with this platform to give you the most memorable trip experiences"
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row background-color">
                <div className="col-12">
                  <div className="container">
                    <div className="row pt-4 pb-4">
                      <div className="col-12 text-center col-md-6 contant-wrapper">
                        <div className="">
                          <i className="fas fa-check-circle"></i>
                          <span className="ml-1">
                            {this.props.t("VERIFIED TRIPS")}
                          </span>
                        </div>
                        <p className="pt-3">
                          {this.props.t(
                            "We trust our customers\u2019 judgments, and therefore we trust their reviews. For the latest updates, nothing beats a review posted by someone who was just there. We do not verify the trip ourselves. Traveller like you does. And that is the most sincere form of verification."
                          )}
                        </p>
                      </div>
                      <div className="col-12 text-center col-md-6 contant-wrapper">
                        <div className="">
                          <i className="fas fa-comments"></i>
                          <span className="ml-1">
                            {this.props.t("24/7 TRIPBONDER SUPPORT")}
                          </span>
                        </div>
                        <p className="pt-3">
                          {this.props.t(
                            "We are here to help. Before, during and even after your trip. Email our support center anytime."
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="content-wrapper3 mt-2">
              <div className="row yellow-background pt-4 pb-4">
                <div className="col-12 text-center">
                  <h4>
                    <del>{this.props.t("Why Tripbonder")}</del>
                  </h4>
                  <h1>{this.props.t("Why Not Tripbonder?")}</h1>
                </div>
              </div>
              <div className="row background-img3">
                <div className="col-12"></div>
              </div>
              <div className="row yellow-background">
                <div className="col-12">
                  <div className="container yellow-background">
                    <div className="row pt-3 pb-3">
                      <div className="col-12 col-md-6 text-center pl-3 py-4">
                        <div className="mb-2">
                          <i className="fas fa-globe"></i>
                        </div>
                        <span className="mb-4">
                          {this.props.t(
                            "BOOK TRIP DIRECTLY WITH LOCAL TOUR OPERATORS"
                          )}
                        </span>
                        <p>
                          {this.props.t(
                            "In Tripbonder.com, all trips are operated by local tour operators and guides. You get the first hand experience from the best operators, i.e. the locals!"
                          )}
                        </p>
                      </div>
                      <div className="col-12 col-md-6 text-center py-4">
                        <div className="mb-2">
                          <i className="fas fa-hand-holding-usd"></i>
                        </div>
                        <span className="mb-4">
                          {this.props.t("CUSTOMIZE YOUR TRIP")}
                        </span>
                        <p>
                          {this.props.t(
                            "Every trip in Tripbonder.com is customizable. There is no one single way to enjoy a place. Let the operator know what you want by using the CHAT with X button. Tour operators are happy to custom built a trip just for you!"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="team mt-2">
              <div className="container pt-5">
                <div className="row align-items-center seperator">
                  <div className="line col"></div>
                  <div
                    className="seperator-image p-3 mx-3 col-auto"
                    style={{
                      backgroundImage: `url('${require("./../../assets/images/in-love.png")}')`
                    }}
                  ></div>
                  <div className="line col"></div>
                </div>
                <div className="row">
                  <div className="col-12 pt-5 title-wrapper">
                    <div className="text-center">
                      <h2>{this.props.t("The Team Behind TripBonder")}</h2>
                      <span className="brief">
                        {this.props.t(
                          "Our mission is always to bring travellers closer to in-location tour operators via the the use of technologies. Naturally, the people behind Tripbonder come from these two massive universes- Travel & Technology. A mash up of these two will definitely ensure an unforgettable travel experience."
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row pt-5 pb-5">
                  <div className="col-12 p-3 p-md-3 col-md-6 text-center d-flex justify-content-center justify-content-md-end">
                    <div className="directors mb-3 pt-4 pb-3">
                      <div className="profile-card">
                        <img
                          src={require("./../../assets/images/marketing/chinfun-4.jpg")}
                          alt=""
                        />
                      </div>
                      <div className="pt-2 px-3" style={{ height: "88px" }}>
                        <h5>{this.props.t("NICHOLAS KHOR")}</h5>
                        <h6>
                          {this.props.t("Co-Founder & Chief Executive Officer")}
                        </h6>
                      </div>
                      <div className="intro px-4">
                        <ul>
                          <li>
                            {this.props.t(
                              "20 years of multinational IT corporate from business analysts, to solution architect, to practice principal. Specializing in the area of big data, advanced analytics and machine learning"
                            )}
                          </li>
                          <li>
                            {this.props.t(
                              "In Tripbonder, Nicholas is responsible for strategic direction, finance and product development"
                            )}
                          </li>
                        </ul>
                      </div>
                      <div className="social mx-auto">
                        <a
                          target="blank"
                          href="https://www.facebook.com/cfkhor"
                        >
                          <img
                            src={require("./../../assets/images/fb.png")}
                            alt="fb"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-3 p-md-3 col-md-6 text-center d-flex justify-content-center justify-content-md-start">
                    <div className="directors mb-3 pt-4 pb-3">
                      <div className="profile-card">
                        <img
                          src={require("./../../assets/images/marketing/veron-4.jpg")}
                          alt=""
                        />
                      </div>
                      <div className="pt-2 px-3">
                        <h5>{this.props.t("VERONICA CHEE")}</h5>
                        <h6>
                          {this.props.t(
                            "Co-Founder & Business Development Director"
                          )}
                        </h6>
                      </div>
                      <div className="intro px-4">
                        <ul>
                          <li>
                            {this.props.t(
                              "30 years of tour expert in East Java with specialization in Bali"
                            )}
                          </li>
                          <li>
                            {this.props.t(
                              "In the tourism industry with over 35 years of experience"
                            )}
                          </li>
                          <li>
                            {this.props.t(
                              "In Tripbonder, Veronica is responsible for market expansion and Sales"
                            )}
                          </li>
                        </ul>
                      </div>
                      <div className="social mx-auto">
                        <a
                          target="blank"
                          href="https://www.facebook.com/veronica.chee.37"
                        >
                          <img
                            src={require("./../../assets/images/fb.png")}
                            alt="fb"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row">
                 <div className="col-12 pt-5 pb-5 d-none d-sm-block">
                   <div className="text-center">
                     <button
                       type="button"
                       className="btn btn-success-gradient px-5"
                     >
                       Learn More
                     </button>
                   </div>
                 </div>
                 <div className="col-12 pt-5 pb-5 d-sm-none">
                   <div>
                     <button
                       type="button"
                       className="btn btn-success-gradient btn-sm btn-block"
                     >
                       Learn More
                     </button>
                   </div>
                 </div>
                 </div> */}
              </div>
            </section>

            <div className="line-wrapper p-0"></div>
          </div>
        </Fade>
      );
    }
  }
);

export default ComposeDashboard;
