import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";

export default options => {
  // Normalize options
  options = Object.assign(
    {
      baseAddress: "/"
    },

    options || {}
  );

  i18n
    .use(LanguageDetector)
    .use(Backend)
    .init({
      backend: {
        loadPath: `${options.baseAddress}public/resources/locale/{{lng}}.json`
      },

      fallbackLng: "en",
      debug: false,

      keySeparator: false, // we use content as keys

      interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
      },

      nsSeparator: "->@<-",

      react: {
        wait: true
      }
    });

  if (window) {
    if (window.__hydrateLocaleEn) {
      i18n.addResourceBundle("en", "", window.__hydrateLocaleEn);
    }
  }

  window.i18n = i18n;

  return i18n;
};
