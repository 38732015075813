import { withTranslation } from "react-i18next";
import React from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import controllers from "../../store/controllers";
import { ExtractAddressInformation } from "../../resources/Helper";
const SearchBarInput = React.forwardRef((props, ref) =>
  withTranslation()(
    class SearchBarInput extends React.Component {
      constructor(props) {
        super(props);
        this.state = {
          searchText: "",
          isGeocoding: false
        };

        ref = this;

        this.searchBarRef = React.createRef();
        this.updateSearchText = this.updateSearchText.bind(this);
        this.updateIsGeocoding = this.updateIsGeocoding.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.doSearch = this.doSearch.bind(this);
      }

      updateSearchText = searchText => this.setState({ searchText });

      updateIsGeocoding = isGeocoding => this.setState({ isGeocoding });

      handleSelect = address => {
        this.updateSearchText(address);
        this.updateIsGeocoding(true);
        geocodeByAddress(address)
          .then(results => {
            return getLatLng(results[0])
              .then(latLong => {
                return {
                  coordinates: latLong,
                  result: results[0]
                };
              })
              .catch(error => {
                this.updateIsGeocoding(false);
                console.error("Error", error);
              });
          })
          .then(payload => {
            this.updateIsGeocoding(false);
            this.props.onSearch &&
              this.props.onSearch({
                address,
                lat: payload.coordinates.lat,
                lon: payload.coordinates.lng,
                city: ExtractAddressInformation(payload.result, "city"),
                country: ExtractAddressInformation(payload.result, "country")
              });
          })
          .catch(error => {
            this.updateIsGeocoding(false);
            console.error("Error", error);
          });
      };

      doSearch = () => {
        if (this.searchBarRef) {
          if (this.state.searchText) {
            this.searchBarRef.current.handleEnterKey();
          } else {
            controllers.setGlobalError(
              "Enter your destination and try again",
              true
            );

            setTimeout(() => {
              controllers.clearGlobalMessage();
            }, 1200);
          }
        }
      };

      render() {
        const { enableBorder } = this.props;
        const headerComponent = this.props.headerComponent || false;
        return (
          <PlacesAutocomplete
            ref={this.searchBarRef}
            debounce={this.props.debounce || 300}
            value={this.state.searchText}
            onChange={this.updateSearchText}
            onSelect={this.handleSelect}
            highlightFirstSuggestion={true}
            googleCallbackName="myCallbackFunc"
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading
            }) => {
              return (
                <div className="search-box input-wrapper flex-fill">
                  <input
                    spellCheck={false}
                    id={this.props.id}
                    {...getInputProps({
                      placeholder: this.props.t("Where do you want to go?"),
                      className: "location-search-input form-control"
                    })}
                    disabled={this.state.isGeocoding}
                    style={{
                      border: enableBorder ? "solid 1px #41939d" : "none"
                    }}
                  />

                  {this.state.isGeocoding === true ? (
                    <i className="fas fa-spin fa-circle-notch"></i>
                  ) : (
                    <i className="fas fa-search"></i>
                  )}

                  <div
                    className={`autocomplete-dropdown-container `}
                    style={!headerComponent ? { position: "static" } : null}
                  >
                    {loading && (
                      <em>
                        <i className="fas fa-spin fa-circle-notch"></i>
                        {this.props.t("Checking suggestions...")}
                      </em>
                    )}

                    {suggestions.map(suggestion => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      return (
                        <div
                          title={suggestion.description}
                          {...getSuggestionItemProps(suggestion, {
                            className: `truncate ${className}`
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            }}
          </PlacesAutocomplete>
        );
      }
    }
  )(props)
);

export default SearchBarInput;
