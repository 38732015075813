import io from "socket.io-client";
import {
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  TOGGLE_SIDEBAR,
  SET_CURRENT_USER,
  INITIALIZE_APP,
  SET_GLOBAL_LOADER,
  SET_GLOBAL_SUCCESS,
  SET_GLOBAL_ERROR,
  CLEAR_GLOBAL_MSG,
  ITINERARY_SET,
  TRANSPORT_SET,
  ACCOMODATION_SET,
  TICKET_SET,
  SET_EDITOR_SAVED,
  RESET_EDITOR,
  OPEN_USER_ACCOUNT_DECK,
  CLOSE_USER_ACCOUNT_DECK,
  TOGGLE_USER_ACCOUNT_DECK,
  UPDATE_PREF_CURRENCY,
  FILTER_UPDATE_KEY,
  SET_EDITOR_LISTING,
  UPDATE_EDITOR_LISTING,
  CHAT_CLEAR_CONVO,
  SET_IS_CURRENCY_CHANGING,
  SET_IS_LANGUAGE_CHANGING,
  UPDATE_PREF_LANGUAGE
} from "./../../store/types";
import { ItineraryController } from "./itinerary.controller";
import { AuthServices } from "../../services/Auth";
import { TicketController } from "./ticket.controller";
import { AccomodationController } from "./accomodation.controller";
import { TransportController } from "./transport.controller";
import { DashboardController } from "./dashboard.controller";
import { MarketingController } from "./marketing.controller";
import { FilterController } from "./filter.controller";
import { ChatController } from "./chat.controller";
import ReactGA from "react-ga";
import { ResetChatWindow } from "../../resources/Helper";
// import { SkyslitServices } from "../../services/_Activation.services";

export class Controller {
  static instance;
  static getInstance() {
    if (!Controller.instance) {
      Controller.instance = new Controller();
    }
    return Controller.instance;
  }

  i18n = null;
  store = null;

  ticket = null;
  itinerary = null;
  accomodation = null;
  transport = null;
  dashboard = null;
  marketing = null;
  filter = null;
  chat = null;

  currencyChangeListeners;

  socket;

  connect(_store, _i18n = null) {
    this._baseUrl = null;
    this.i18n = _i18n;
    this.store = _store;
    this.itinerary = new ItineraryController(_store);
    this.ticket = new TicketController(_store);
    this.accomodation = new AccomodationController(_store);
    this.transport = new TransportController(_store);
    this.dashboard = new DashboardController(_store);
    this.marketing = new MarketingController(_store);
    this.filter = new FilterController(_store);
    this.chat = new ChatController(_store);

    this.currencyChangeListeners = {};
    this.languageChangeListeners = {};
    // SkyslitServices.Activate(this);
  }

  getStore() {
    return this.store;
  }

  connectToServer(baseUrl) {
    this._baseUrl = baseUrl;
    this.socket = io(baseUrl);
    this.socket.on("new-chat-message", response => {
      this.chat.pushConversation(response.thread);
    });

    this.socket.on("rt-push-message", response => {
      this.socket.emit("rt-read-message", response.thread._id);
      this.chat.pushMessages(response.thread._id, [response.message]);
      this.chat.updateConversationLastActiveOn(
        response.thread._id,
        response.threadLastActiveOn
      );

      ResetChatWindow(response.thread._id);
    });
  }

  reconnectSocket() {
    this.socket && this.socket.disconnect();
    setTimeout(() => {
      this.socket && this.socket.connect();
    }, 2000);
  }

  getSocket() {
    return this.socket || null;
  }

  // Currency Methods
  subscribeToCurrencyChange(handler, id) {
    this.currencyChangeListeners[id] = handler;
  }

  unsubscribeFromCurrencyChange(handler, id) {
    if (this.currencyChangeListeners[id]) {
      delete this.currencyChangeListeners[id];
    }
  }

  changePreferredCurrency(key) {
    const currency = this.store
      .getState()
      .local.supportedCurrencies.find(c => c.key === key);

    if (currency) {
      this.store.dispatch({
        type: SET_IS_CURRENCY_CHANGING,
        payload: {
          value: true
        }
      });

      this.store.dispatch({
        type: UPDATE_PREF_CURRENCY,
        payload: {
          value: currency
        }
      });

      this.store.dispatch({
        type: FILTER_UPDATE_KEY,
        payload: {
          key: "priceRange",
          value: [currency.minPurchasePower, currency.maxPurchasePower]
        }
      });

      AuthServices.SetCurrency(key)
        .then(response => {
          Object.keys(this.currencyChangeListeners).forEach(
            handlerId =>
              this.currencyChangeListeners[handlerId] &&
              this.currencyChangeListeners[handlerId](currency)
          );

          ReactGA.event({
            category: "User",
            action: `changed currency to ${key}`
          });

          this.store.dispatch({
            type: SET_IS_CURRENCY_CHANGING,
            payload: {
              value: false
            }
          });
        })
        .catch(err => {
          console.error(err);
        });
    }
  }

  // Language methods
  subscribeToLanguageChange(handler, id) {
    this.languageChangeListeners[id] = handler;
  }

  unsubscribeFromLanguageChange(handler, id) {
    if (this.languageChangeListeners[id]) {
      delete this.languageChangeListeners[id];
    }
  }

  changePreferredLanguage(key) {
    const self = this;
    const language = this.store
      .getState()
      .local.languages.find(c => c.key === key);

    if (language) {
      this.store.dispatch({
        type: SET_IS_LANGUAGE_CHANGING,
        payload: {
          value: true
        }
      });

      this.store.dispatch({
        type: UPDATE_PREF_LANGUAGE,
        payload: {
          value: language
        }
      });

      AuthServices.SetLanguage(key)
        .then(response => {
          Object.keys(this.languageChangeListeners).forEach(
            handlerId =>
              this.languageChangeListeners[handlerId] &&
              this.languageChangeListeners[handlerId](language)
          );

          ReactGA.event({
            category: "User",
            action: `changed language to ${key}`
          });

          this.i18n.changeLanguage(key, function() {
            self.store.dispatch({
              type: SET_IS_LANGUAGE_CHANGING,
              payload: {
                value: false
              }
            });
          });
        })
        .catch(err => {
          console.error(err);
        });
    }
  }

  // Local Methods - BEGIN

  openUserAccountDeck() {
    this.store.dispatch({
      type: OPEN_USER_ACCOUNT_DECK
    });
  }

  closeUserAccountDeck() {
    this.store.dispatch({
      type: CLOSE_USER_ACCOUNT_DECK
    });
  }

  toggleUserAccountDeck() {
    this.store.dispatch({
      type: TOGGLE_USER_ACCOUNT_DECK
    });
  }

  openSidebar() {
    this.store.dispatch({
      type: OPEN_SIDEBAR
    });
  }

  closeSidebar() {
    this.store.dispatch({
      type: CLOSE_SIDEBAR
    });
  }

  toggleSidebar() {
    this.store.dispatch({
      type: TOGGLE_SIDEBAR
    });
  }

  initializePWA(context) {
    this.store.dispatch({
      type: INITIALIZE_APP,
      payload: {
        context
      }
    });
  }

  setGlobalLoader(message = null, enableManualClosing = false) {
    this.store.dispatch({
      type: SET_GLOBAL_LOADER,
      payload: {
        value: message,
        enableManualClosing
      }
    });
  }

  setGlobalSuccess(message = null, enableManualClosing = true) {
    this.store.dispatch({
      type: SET_GLOBAL_SUCCESS,
      payload: {
        value: message,
        enableManualClosing
      }
    });
  }

  setGlobalError(message = null, enableManualClosing = false) {
    this.store.dispatch({
      type: SET_GLOBAL_ERROR,
      payload: {
        value: message,
        enableManualClosing
      }
    });
  }

  clearGlobalMessage() {
    this.store.dispatch({
      type: CLEAR_GLOBAL_MSG
    });
  }

  setCurrentUser(userInfo = null) {
    this.store.dispatch({
      type: SET_CURRENT_USER,
      payload: {
        value: userInfo
      }
    });

    this.store.dispatch({
      type: CHAT_CLEAR_CONVO
    });

    this.reconnectSocket();
  }

  logoutCurrentUser() {
    this.setGlobalLoader("Securely logging out...");
    setTimeout(() => {
      AuthServices.LogoutUser()
        .then(response => {
          if (response && response.success === true) {
            this.setGlobalSuccess("You've been successfully logged out", true);
            this.setCurrentUser(null);
            setTimeout(() => {
              this.clearGlobalMessage();
            }, 1800);
          } else {
            this.setGlobalError(
              "Something went wrong. Please try again.",
              true
            );
          }
        })
        .catch(err => {
          console.error(err);
          this.setGlobalError(
            (err.data && err.data.message) ||
              "Check your connection and try again",
            true
          );
        });
    }, 800);
  }

  resolveAssetAddr(imageId) {
    const extension =
      imageId && imageId.indexOf(".") > -1
        ? imageId.split(".").pop() || null
        : null;
    return (
      (imageId &&
        `${this.store.getState().local.assetBaseAddr}${imageId}${
          extension ? "" : ".jpg"
        }`) ||
      null
    );
  }

  setEditorListing(value) {
    this.store.dispatch({
      type: SET_EDITOR_LISTING,
      payload: {
        value
      }
    });
  }

  setItinerary(value) {
    this.store.dispatch({
      type: ITINERARY_SET,
      payload: {
        value
      }
    });
  }

  setTransport(value) {
    this.store.dispatch({
      type: TRANSPORT_SET,
      payload: {
        value
      }
    });
  }

  setAccomodation(value) {
    this.store.dispatch({
      type: ACCOMODATION_SET,
      payload: {
        value
      }
    });
  }

  setTicket(value) {
    this.store.dispatch({
      type: TICKET_SET,
      payload: {
        value
      }
    });
  }

  setEditorSaved() {
    this.store.dispatch({
      type: SET_EDITOR_SAVED
    });
  }

  resetEditorSaveState() {
    this.store.dispatch({
      type: RESET_EDITOR
    });
  }

  updateEditorListing(key, value) {
    this.store.dispatch({
      type: UPDATE_EDITOR_LISTING,
      payload: {
        key,
        value
      }
    });
  }

  // Local Methods - END
}

export default Controller.getInstance();
