import React, { Component } from "react";
import Script from "react-load-script";

export class PaypalButton extends Component {
  constructor(props) {
    super(props);
    this.buttonContainer = React.createRef();

    this.state = {
      isReady: false
    };

    this._renderButton = this._renderButton.bind(this);
    this._handleLoad = this._handleLoad.bind(this);
  }

  _renderButton(props) {
    const self = this;
    let {
      onPay,
      onApprove,
      onCancel,
      onError,
      onComplete,
      amount,
      currencyCode
    } = props;

    const FUNDING_SOURCES = [window.paypal.FUNDING.PAYPAL];

    amount = parseFloat(amount);
    currencyCode = currencyCode || "USD";

    if (isNaN(amount) || amount < 0.01) {
      throw new Error("Amount must be specified and above 0.01");
    }

    FUNDING_SOURCES.forEach(function(fundingSource) {
      // Initialize the buttons
      var button = window.paypal.Buttons({
        fundingSource: fundingSource,
        createOrder: function(data, actions) {
          onPay && onPay();
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: String(amount),
                  currency_code: currencyCode
                }
              }
            ]
          });
        },
        onApprove: function(data, actions) {
          onApprove && onApprove(data, actions);
          onComplete && onComplete();
        },
        onCancel: function(data) {
          onCancel && onCancel(data);
          onComplete && onComplete();
        },
        onError: function(err) {
          onError && onError(err);
          onComplete && onComplete();
        }
      });

      if (button.isEligible()) {
        button.render(self.buttonContainer.current);
      }
    });
  }

  _handleLoad() {
    this.setState(
      {
        isReady: true
      },

      () => this._renderButton(this.props)
    );
  }

  componentDidMount() {
    const { isReady } = this.state;
    if (isReady === true) {
      this._renderButton(this.props);
    }
  }

  componentWillUnmount() {
    if (document.getElementById("paypal-sdk-script-911")) {
      document.getElementById("paypal-sdk-script-911").remove();
    }
  }

  render() {
    const { currencyCode } = this.props;
    return (
      <>
        <Script
          attributes={{ id: "paypal-sdk-script-911" }}
          url={`https://www.paypal.com/sdk/js?client-id=AezZCZAzPwwv6VqCoEejKS3RaDkl4EQWZ4ENCt4zTdbICmBdXhbpZaB5oRrj-nOu6L2CIWEpx9ZMmtO-&components=buttons,funding-eligibility&currency=${currencyCode}`}
          onLoad={() => this._handleLoad()}
        />

        <div ref={this.buttonContainer}></div>
      </>
    );
  }
}
