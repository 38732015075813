import { withTranslation } from "react-i18next";
import React from "react";
import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import Trip from "../../components/listing/Trip";
import { WishlistServices } from "./../../services/Wishlist.services";
import Controller from "../../store/controllers/index";
import { NETWORK_ERR_MESSAGE } from "../../resources/String";
import { Helmet } from "react-helmet";
const Whishlist = withTranslation()(
  class Whishlist extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        allInfo: [],
        isCompareModeOn: false,
        selectedTrip: false,
        selectedPackagesForCompare: []
      };

      this.fetchWishlist = this.fetchWishlist.bind(this);
      this.toggleIsCompareModeOn = this.toggleIsCompareModeOn.bind(this);
      this.toggleSelectedTrip = this.toggleSelectedTrip.bind(this);
      this.addToCompare = this.addToCompare.bind(this);
    }

    toggleIsCompareModeOn() {
      this.setState({
        isCompareModeOn: !this.state.isCompareModeOn,
        selectedPackagesForCompare: []
      });
    }

    toggleSelectedTrip() {
      this.setState({
        selectedTrip: true
      });
    }

    fetchWishlist() {
      Controller.setGlobalLoader("Fetching Wishlist ...", false);
      setTimeout(() => {
        WishlistServices.GetWishlist()
          .then(wishlist => {
            Controller.clearGlobalMessage();
            this.setState({
              allInfo: wishlist
            });
          })
          .catch(err => {
            Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
          });
      }, 800);
    }

    componentDidMount() {
      this.fetchWishlist();
    }

    addToCompare(listingId) {
      if (this.state.selectedPackagesForCompare.indexOf(listingId) < 0) {
        this.setState({
          selectedPackagesForCompare: [
            ...this.state.selectedPackagesForCompare,
            listingId
          ]
        });
      } else {
        this.setState({
          selectedPackagesForCompare: this.state.selectedPackagesForCompare.filter(
            id => id !== listingId
          )
        });
      }
    }

    render() {
      const {
        match: {
          params: { status }
        }
      } = this.props;
      const { isCompareModeOn, selectedPackagesForCompare } = this.state;
      const t = this.props.t;
      let currentTab;
      let privateList = this.state.allInfo.filter(w => w.class === "private");
      let wishlist = this.state.allInfo.filter(w => w.class === "general");
      let result = [];
      switch (status) {
        case "private": {
          currentTab = "private";
          result = privateList;
          break;
        }
        default: {
          currentTab = "wishlist";
          result = wishlist;
        }
      }

      let currentType;
      if (isCompareModeOn === true) {
        let primarySelectionId =
          selectedPackagesForCompare.length > 0
            ? selectedPackagesForCompare[0]
            : null;
        if (primarySelectionId) {
          let primaryListing = this.state.allInfo.find(
            w => w.listing._id === primarySelectionId
          );

          if (primaryListing) {
            currentType = primaryListing.listing.type;
            result = result.filter(w => w.listing.type === currentType);
          }
        }
      }

      return (
        <Fade duration={300}>
          <Helmet>
            <title>
              {this.props.t(
                "Tripbonder Wishlist - start from where you left about your travel plans"
              )}
            </title>
          </Helmet>
          <div className="container-fluid whishlistWrapper pb-5">
            <div className="row pt-5">
              <div className="col-12 px-0">
                <div className="container">
                  <div className="row whishlist-title-wrapper">
                    <div className="col-12 col-sm-6">
                      <h5>{this.props.t("Your Wishlist")}</h5>
                    </div>
                    <div className="col-6 text-right d-none d-sm-block">
                      {this.state.isCompareModeOn === true ? (
                        <button
                          onClick={() => this.toggleIsCompareModeOn()}
                          className="times-button"
                        >
                          <i className="fas fa-times"></i>
                        </button>
                      ) : (
                        <button
                          onClick={() => this.toggleIsCompareModeOn()}
                          className="btn btn-success-gradient btn-sm"
                        >
                          {this.props.t("Compare Packages")}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                {this.state.isCompareModeOn === true ? (
                  <div className="container-fluid compare-package-draft mt-4 d-none d-sm-block">
                    <div className="row">
                      <div className="col-12">
                        <div className="container">
                          <div className="row py-3">
                            <div className="col-6">
                              <i className="fas fa-check-circle pr-2"></i>
                              <label className="mb-0">
                                {this.state.selectedPackagesForCompare.length}
                                {this.props.t("of 2 package(s) selected")}
                              </label>
                            </div>
                            <div className="col-6 text-right">
                              <label className="mb-0 pr-md-3">
                                {this.props.t(
                                  "Select any two packages to compare"
                                )}
                              </label>
                              {this.state.selectedPackagesForCompare.length <
                              2 ? (
                                <button
                                  disabled={true}
                                  className="btn btn-success-gradient"
                                >
                                  {this.props.t("Compare Now")}
                                </button>
                              ) : (
                                <Link
                                  to={`/me/wishlist/compare/${
                                    this.state.selectedPackagesForCompare[0]
                                  }/${
                                    this.state.selectedPackagesForCompare[1]
                                  }`}
                                  disabled={
                                    this.state.selectedPackagesForCompare
                                      .length < 2
                                  }
                                  className="btn btn-success-gradient"
                                >
                                  {this.props.t("Compare Now")}
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <hr></hr>
                    </div>
                  </div>
                </div>
                <div className="container">
                  <div className="row activity">
                    <div className="col-12 pt-2 text-center">
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic example"
                      >
                        <Link
                          to="/me/wishlist"
                          className={`${
                            currentTab === "wishlist"
                              ? "active-button"
                              : "inactive-button"
                          } btn btn-secondary px-4`}
                        >
                          {this.props.t("My Wishlist")}{" "}
                          {wishlist.length > 0 ? `(${wishlist.length})` : ""}
                        </Link>
                        <Link
                          to="/me/wishlist/private"
                          className={`${
                            currentTab === "private"
                              ? "active-button"
                              : "inactive-button"
                          } btn btn-secondary px-4`}
                        >
                          {this.props.t("My Customised List")}{" "}
                          {privateList.length > 0
                            ? `(${privateList.length})`
                            : ""}
                        </Link>
                      </div>
                    </div>
                    <div className="col-12 text-center pt-3">
                      <label className="wishlist-count">
                        {`${t("Showing")} ${result.length} ${t("item(s)")}`}
                      </label>
                    </div>
                  </div>
                  {result.length === 0 ? (
                    <div
                      className="row jumbotron mx-2"
                      style={{ width: "100%" }}
                    >
                      <div className="col-12 text-left">
                        <h1 className="display-4">
                          {this.props.t("No Items")}
                        </h1>
                        <Link to="/" className="btn btn-primary btn-lg mt-2">
                          {this.props.t("Explore Packages")}
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      {result.map((item, index) => (
                        <Trip
                          key={index}
                          data={item.listing}
                          hasShadow={true}
                          mode={
                            this.state.isCompareModeOn === true
                              ? "select"
                              : null
                          }
                          onSelect={() => this.addToCompare(item.listing._id)}
                          hasChecked={
                            this.state.selectedPackagesForCompare.indexOf(
                              item.listing._id
                            ) > -1
                          }
                        />
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Fade>
      );
    }
  }
);

export default Whishlist;
