import {
  FILTER_TOGGLE_CATEGORY,
  FILTER_UPDATE_KEY,
  FILTER_RESET,
  FILTER_OPEN_MINI,
  FILTER_CLOSE_MINI,
  FILTER_TOGGLE_MINI,
  FILTER_SET_REFRESHING,
  FILTER_SET_RESULTS,
  FILTER_SET_ADDRESS,
  FILTER_SET_PACKAGE,
  FILTER_SET_RESULT_MODE
} from "../types";

import { PackageServices } from "./../../services/Package.services";
import ReactGA from "react-ga";
import { fetchTags } from "../../resources/Helper";
export class FilterController {
  store = null;

  constructor(_store) {
    this.store = _store;
  }

  debounceActivated = false;
  debounceMax = 1;
  debounceVal = 0;
  debounceManager = null;

  activateDebounce() {
    this.debounceVal = this.debounceMax;
    if (this.debounceActivated === false) {
      this.store.dispatch({
        type: FILTER_SET_REFRESHING,
        payload: { value: true }
      });

      this.debounceActivated = true;
      this.debounceManager = setInterval(() => {
        if (this.debounceVal > 0) {
          this.debounceVal--;
        } else {
          if (this.debounceActivated === true) {
            this.debounceActivated = false;
            this.refreshFilter();
            clearInterval(this.debounceManager);
          }
        }
      }, 200);
    }
  }

  setAddress(
    address,
    lat,
    lon,
    city,
    country,
    priceRange,
    theme = null,
    type = null
  ) {
    this.store.dispatch({
      type: FILTER_SET_ADDRESS,
      payload: {
        address,
        lat,
        lon,
        city,
        country,
        priceRange,
        theme,
        type
      }
    });

    this.refreshFilter();
  }

  setPackage(value) {
    this.store.dispatch({
      type: FILTER_SET_PACKAGE,
      payload: {
        value
      }
    });

    this.refreshFilter();
  }

  updateFilter(key, value, disableRefresh = false) {
    this.store.dispatch({
      type: FILTER_UPDATE_KEY,
      payload: {
        key,
        value
      }
    });

    if (!disableRefresh) this.activateDebounce();
  }

  togglePackageCategory(category, reset) {
    this.store.dispatch({
      type: FILTER_TOGGLE_CATEGORY,
      payload: {
        value: category,
        reset: reset || false
      }
    });

    this.activateDebounce();
  }

  reset(priceRange) {
    this.store.dispatch({
      type: FILTER_RESET,
      payload: {
        priceRange
      }
    });

    this.refreshFilter();
  }

  openMiniDialog() {
    this.store.dispatch({
      type: FILTER_OPEN_MINI
    });
  }

  closeMiniDialog() {
    this.store.dispatch({
      type: FILTER_CLOSE_MINI
    });
  }

  toggleMiniDialog() {
    this.store.dispatch({
      type: FILTER_TOGGLE_MINI
    });
  }

  switchResultTab(mode) {
    this.store.dispatch({
      type: FILTER_SET_REFRESHING,
      payload: { value: true }
    });

    setTimeout(() => {
      this.store.dispatch({
        type: FILTER_SET_RESULT_MODE,
        payload: {
          value: mode
        }
      });
    }, 800);
  }

  refreshFilter(loadMoreToken) {
    const options = this.store.getState().filter;

    const payload = {
      isLoadMore: loadMoreToken || false,
      limit: loadMoreToken
        ? options.selectedTab === "result"
          ? options.results.length + options.itemsPerLoad
          : options.nearByResults.length + options.itemsPerLoad
        : options.itemsPerLoad,
      offset: loadMoreToken
        ? options.selectedTab === "result"
          ? options.results.length
          : options.nearByResults.length
        : 0
    };

    const local = this.store.getState().local;
    this.store.dispatch({
      type: FILTER_SET_REFRESHING,
      payload: {
        value: true,
        isSilent: loadMoreToken !== undefined && loadMoreToken !== null
      }
    });

    const filter = {
      categories: options.categories,
      dayRange: options.dayRange,
      priceRange: options.priceRange || [
        local.selectedCurrency.minPurchasePower,
        local.selectedCurrency.maxPurchasePower
      ],

      address: options.address,
      lat: options.lat,
      lon: options.lon,
      city: options.city,
      country: options.country,
      ...payload
    };

    PackageServices.Filter(options.package, filter)
      .then(results => {
        let msg = `Category: ${options.package}, Day Range: ${
          filter.dayRange
        }, Price: ${filter.priceRange.join(" to ")}, Address: ${
          filter.address
        } - [${filter.lat}, ${filter.lon}]`;
        ReactGA.event({
          category: "User",
          action: `Filter / Search ${msg}`
        });

        setTimeout(() => {
          const nearByItems = results.filter(r => r.proximity === "nearby");
          const preciseItems = results.filter(r => r.proximity === "precise");
          this.store.dispatch({
            type: FILTER_SET_RESULTS,
            payload: {
              results: preciseItems,
              nearByResults: nearByItems,
              nearByResultCount: nearByItems.length,
              push: loadMoreToken ? true : false
            }
          });

          if (!payload.isLoadMore) {
            this.refreshTags(preciseItems);
          }
        }, 100);
      })
      .catch(err => {
        console.error(err);
      });
  }

  refreshTags(items) {
    /**
     * @description Extract, reduce and count tags from listing items
     */
    const tags = fetchTags(items);
    this.updateFilter("filterTags", tags, true);
  }

  selectFilterTag(tag, reset = false) {
    const options = this.store.getState().filter;
    if (reset) {
      this.updateFilter("selectedHashTags", [tag], true);
    } else {
      if (options.selectedHashTags.indexOf(tag) > -1) {
        this.updateFilter(
          "selectedHashTags",
          options.selectedHashTags.filter(t => t !== tag),
          true
        );
      } else {
        this.updateFilter(
          "selectedHashTags",
          [...options.selectedHashTags, tag],
          true
        );
      }
    }
  }
}
