import {
  ACCOMODATION_UPDATE,
  ACCOMODATION_ADD_HIGHLIGHT,
  ACCOMODATION_DELETE_HIGHLIGHT,
  ACCOMODATION_UPDATE_HIGHLIGHT,
  ACCOMODATION_ROOMS_ADD,
  ACCOMODATION_ROOMS_DELETE,
  ACCOMODATION_ROOMS_UPDATE,
  ACCOMODATION_TOGGLE_FEATURE,
  ACCOMODATION_TOGGLE_ROOM_FEATURES,
  ACCOMODATION_SET,
  ACCOMODATION_UPDATE_LOC,
  ACCOMODATION_SPLICE_BANNER
} from "../types";

const initialState = {
  _status: null,
  _id: null,
  title: "",
  location: "",
  geoLocation: null,
  city: null,
  country: null,
  description: "",
  highlights: [],
  coverImages: ["", "", "", "", ""],
  internetAccess: ["wifipublic"],
  amenities: ["atm", "dry-cleaning"],
  access: [],
  rooms: [],
  availabliltyStartDate: null,
  availabliltyEndDate: null,
  otherInformation: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ACCOMODATION_SET: {
      const { value, status } = action.payload;
      return Object.assign({}, value, { _status: status });
    }
    case ACCOMODATION_UPDATE: {
      const { key, value } = action.payload;
      return Object.assign({}, state, { [key]: value });
    }
    case ACCOMODATION_UPDATE_LOC: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        location: value.address,
        geoLocation: value.geoLocation,
        city: value.city,
        country: value.country
      });
    }
    case ACCOMODATION_SPLICE_BANNER: {
      const { index, value } = action.payload;
      if (state.coverImages.length > index) {
        return Object.assign({}, state, {
          coverImages: state.coverImages.map((image, imageIndex) => {
            if (imageIndex === index) {
              return value;
            }
            return image;
          })
        });
      } else {
        return Object.assign({}, state, {
          coverImages: new Array(Math.min(index + 1, 5))
            .fill(null)
            .map(function(item, i) {
              if (index === i) {
                return value;
              } else {
                if (state.coverImages[i]) {
                  return state.coverImages[i];
                } else {
                  return "";
                }
              }
            })
        });
      }
    }
    case ACCOMODATION_DELETE_HIGHLIGHT: {
      const { indexToDelete } = action.payload;
      return Object.assign({}, state, {
        highlights: state.highlights.filter(
          (highlight, index) => index !== indexToDelete
        )
      });
    }
    case ACCOMODATION_UPDATE_HIGHLIGHT: {
      const { indexToUpdate, key, value } = action.payload;
      return Object.assign({}, state, {
        highlights: state.highlights.map((highlight, index) => {
          if (index === indexToUpdate) {
            return Object.assign({}, highlight, {
              [key]: value
            });
          } else {
            return highlight;
          }
        })
      });
    }
    case ACCOMODATION_ADD_HIGHLIGHT: {
      return Object.assign({}, state, {
        highlights: [
          ...state.highlights,
          {
            imageId: null,
            location: "",
            geoLocation: null,
            description: ""
          }
        ]
      });
    }
    case ACCOMODATION_ROOMS_ADD: {
      return Object.assign({}, state, {
        rooms: [
          ...state.rooms,
          {
            roomType: "",
            price: "",
            description: "",
            imageId: null,
            capacityInfo: [],
            spaceInfo: []
          }
        ]
      });
    }
    case ACCOMODATION_ROOMS_UPDATE: {
      const { indexToUpdate, key, value } = action.payload;
      return Object.assign({}, state, {
        rooms: state.rooms.map((highlightDetail, index) => {
          if (index === indexToUpdate) {
            return Object.assign({}, highlightDetail, {
              [key]: value
            });
          } else {
            return highlightDetail;
          }
        })
      });
    }

    case ACCOMODATION_ROOMS_DELETE: {
      const { indexToDelete } = action.payload;
      return Object.assign({}, state, {
        rooms: state.rooms.filter(
          (highlightDetail, index) => index !== indexToDelete
        )
      });
    }

    case ACCOMODATION_TOGGLE_ROOM_FEATURES: {
      const { indexToUpdate, key, value } = action.payload;
      return Object.assign({}, state, {
        rooms: state.rooms.map((room, index) => {
          if (index === indexToUpdate) {
            if (room[key].indexOf(value) > -1) {
              return Object.assign({}, room, {
                [key]: room[key].filter(v => v !== value)
              });
            } else {
              return Object.assign({}, room, {
                [key]: [...room[key], value]
              });
            }
          } else {
            return room;
          }
        })
      });
    }

    case ACCOMODATION_TOGGLE_FEATURE: {
      const { key, value } = action.payload;
      return Object.assign({}, state, {
        [key]:
          state[key].indexOf(value) > -1
            ? state[key].filter(a => a !== value)
            : [...state[key], value]
      });
    }
    default: {
      return state;
    }
  }
}
