import { useTranslation } from "react-i18next";
import React from "react";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
export default function(props) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <footer className="footer mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12 pt-3 pl-2">
              <div className="logo-3 float-left">
                <img
                  height="80"
                  src={require("../../assets/images/trip-logo.png")}
                  alt=""
                />
              </div>
              <div className="icons float-right pt-2">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/TripBonder/"
                  onClick={() => {
                    ReactGA.event({
                      category: "User",
                      action: "Clicked on Facebook link"
                    });
                  }}
                >
                  <i className="social fab fa-facebook pr-2"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://twitter.com/nickkhor888"
                  onClick={() => {
                    ReactGA.event({
                      category: "User",
                      action: "Clicked on Twitter link"
                    });
                  }}
                >
                  <i className="social fab fa-twitter-square pr-2"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/tripbonder"
                  onClick={() => {
                    ReactGA.event({
                      category: "User",
                      action: "Clicked on Instagram link"
                    });
                  }}
                >
                  <i className="social fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="terms pt-5 pl-3">
              <Link to="/about/legal/terms">{t("Terms & Condition")}</Link>
              <Link to="/about/tripbonder">{t("About Us")}</Link>
              <Link to="/support/help/faq">{t("FAQ")}</Link>
              <Link to="/about/contact">{t("Contact Us")}</Link>
            </div>
          </div>
          <div className="copyright text-center pt-5 pb-3">
            <span>
              {t("\xA9 TripBonder")}
              {new Date().getFullYear()}
              {t(". All Rights Reserved")}
            </span>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}
