import {
  ITINERARY_UPDATE,
  ITINERARY_ADD_HIGHLIGHT,
  ITINERARY_DELETE_HIGHLIGHT,
  ITINERARY_UPDATE_HIGHLIGHT,
  ITINERARY_ADD_DAY,
  ITINERARY_UPDATE_DAY,
  ITINERARY_DELETE_DAY,
  ITINERARY_ADD_ACTIVITY,
  ITINERARY_UPDATE_ACTIVITY,
  ITINERARY_REMOVE_ACTIVITY,
  ITINERARY_ADD_FOOD_ACTIVITY,
  ITINERARY_UPDATE_FOOD_ACTIVITY,
  ITINERARY_REMOVE_FOOD_ACTIVITY,
  ITINERARY_ADD_STAY_IMAGE,
  ITINERARY_UPDATE_STAY_IMAGE,
  ITINERARY_REMOVE_STAY_IMAGE,
  ITINERARY_SET,
  ITINERARY_UPDATE_LOC,
  ITINERARY_SPLICE_BANNER,
  ITINERARY_HIGHLIGHT_LOC,
  ITINERARY_TOGGLE_FEATURES,
  ITINERARY_UPDATE_ACTIVITY_LOC,
  ITINERARY_UPDATE_FOOD_ACTIVITY_LOC,
  ITINERARY_UPDATE_DAY_STAY_LOC,
  ITINERARY_TOGGLE_INFO
} from "../types";

export class ItineraryController {
  store = null;

  constructor(_store) {
    this.store = _store;
  }

  setItineraryEditorContext(value, status) {
    this.store.dispatch({
      type: ITINERARY_SET,
      payload: {
        value,
        status
      }
    });
  }

  updateItinerary(key, value) {
    this.store.dispatch({
      type: ITINERARY_UPDATE,
      payload: {
        value,
        key
      }
    });
  }

  updateItineraryLocation(locationData) {
    this.store.dispatch({
      type: ITINERARY_UPDATE_LOC,
      payload: {
        value: locationData
      }
    });
  }

  replaceItineraryBanner(indexToSplice, imageId) {
    this.store.dispatch({
      type: ITINERARY_SPLICE_BANNER,
      payload: {
        index: indexToSplice,
        value: imageId
      }
    });
  }

  updateHighlightLocation(indexToUpdate, data) {
    this.store.dispatch({
      type: ITINERARY_HIGHLIGHT_LOC,
      payload: {
        index: indexToUpdate,
        value: data
      }
    });
  }

  addHighlight() {
    this.store.dispatch({
      type: ITINERARY_ADD_HIGHLIGHT
    });
  }

  updateHighlight(indexToUpdate, key, value) {
    this.store.dispatch({
      type: ITINERARY_UPDATE_HIGHLIGHT,
      payload: {
        indexToUpdate,
        key,
        value
      }
    });
  }

  deleteHighlight(indexToDelete) {
    this.store.dispatch({
      type: ITINERARY_DELETE_HIGHLIGHT,
      payload: {
        indexToDelete
      }
    });
  }

  addDay() {
    this.store.dispatch({
      type: ITINERARY_ADD_DAY
    });
  }

  updateDay(indexToUpdate, key, value) {
    this.store.dispatch({
      type: ITINERARY_UPDATE_DAY,
      payload: {
        indexToUpdate,
        key,
        value
      }
    });
  }

  updateStayLocation(indexToUpdate, value) {
    this.store.dispatch({
      type: ITINERARY_UPDATE_DAY_STAY_LOC,
      payload: {
        indexToUpdate,
        value
      }
    });
  }

  toggleDayStayFeature(indexToUpdate, key, value) {
    this.store.dispatch({
      type: ITINERARY_TOGGLE_FEATURES,
      payload: {
        indexToUpdate,
        key,
        value
      }
    });
  }

  deleteDay(indexToDelete) {
    this.store.dispatch({
      type: ITINERARY_DELETE_DAY,
      payload: {
        indexToDelete
      }
    });
  }

  addActivity(dayIndex, category) {
    this.store.dispatch({
      type: ITINERARY_ADD_ACTIVITY,
      payload: {
        dayIndex,
        category
      }
    });
  }

  updateActivity(dayIndex, indexToUpdate, key, value, category) {
    this.store.dispatch({
      type: ITINERARY_UPDATE_ACTIVITY,
      payload: {
        dayIndex,
        indexToUpdate,
        key,
        value,
        category
      }
    });
  }

  updateActivityLoc(dayIndex, indexToUpdate, value, category) {
    this.store.dispatch({
      type: ITINERARY_UPDATE_ACTIVITY_LOC,
      payload: {
        dayIndex,
        indexToUpdate,
        value,
        category
      }
    });
  }

  removeActivity(dayIndex, indexToDelete, category) {
    this.store.dispatch({
      type: ITINERARY_REMOVE_ACTIVITY,
      payload: {
        indexToDelete,
        dayIndex,
        category
      }
    });
  }

  addFoodActivity(dayIndex, category) {
    this.store.dispatch({
      type: ITINERARY_ADD_FOOD_ACTIVITY,
      payload: {
        dayIndex,
        category
      }
    });
  }

  updateFoodActivity(dayIndex, indexToUpdate, key, value, category) {
    this.store.dispatch({
      type: ITINERARY_UPDATE_FOOD_ACTIVITY,
      payload: {
        dayIndex,
        indexToUpdate,
        key,
        value,
        category
      }
    });
  }

  updateFoodActivityLoc(dayIndex, indexToUpdate, value, category) {
    this.store.dispatch({
      type: ITINERARY_UPDATE_FOOD_ACTIVITY_LOC,
      payload: {
        dayIndex,
        indexToUpdate,
        value,
        category
      }
    });
  }

  removeFoodActivity(dayIndex, indexToDelete, category) {
    this.store.dispatch({
      type: ITINERARY_REMOVE_FOOD_ACTIVITY,
      payload: {
        indexToDelete,
        dayIndex,
        category
      }
    });
  }

  addStayImage(dayIndex) {
    this.store.dispatch({
      type: ITINERARY_ADD_STAY_IMAGE,
      payload: {
        dayIndex
      }
    });
  }

  updateStayImage(dayIndex, indexToUpdate, key, value) {
    this.store.dispatch({
      type: ITINERARY_UPDATE_STAY_IMAGE,
      payload: {
        dayIndex,
        indexToUpdate,
        key,
        value
      }
    });
  }

  removeStayImage(dayIndex, indexToDelete) {
    this.store.dispatch({
      type: ITINERARY_REMOVE_STAY_IMAGE,
      payload: {
        indexToDelete,
        dayIndex
      }
    });
  }

  toggleInfo(key, value) {
    this.store.dispatch({
      type: ITINERARY_TOGGLE_INFO,
      payload: {
        value,
        key
      }
    });
  }
}
