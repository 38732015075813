import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
// import Preloader from "./components/Loaders/Preloader";

// Layouts
import TripbonderLayout from "./layouts/TripbonderLayout";
import MarketingLayout from "./layouts/MarketingLayout";
import AuthLayout from "./layouts/AuthLayout";

import { AuthServices } from "./services/Auth";
import controllers from "./store/controllers";
import GlobalMessage from "./components/Loaders/GlobalMessage";

import getConfig from "./config";

export const TripbonderRoutes = userInfo => {
  return [
    {
      layout: "/dashboard",
      path: "/post/new",
      component: MarketingLayout
    },

    {
      layout: "/dashboard",
      path: "/post/new",
      component: MarketingLayout
    }
  ];
};

export const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      children={props => {
        const search = queryString.parse(window.location.search);
        const returnUrl =
          search.returnUrl && search.returnUrl !== "" ? search.returnUrl : "/";
        return !rest.isAuthenticated ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: returnUrl,
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

export const ProtectedRoute = ({
  hasTripbonderDashboardAccess,
  shouldBeTripbonder,
  component: Component,
  ...rest
}) => {
  shouldBeTripbonder = shouldBeTripbonder || false;
  hasTripbonderDashboardAccess = hasTripbonderDashboardAccess || false;
  return (
    <Route
      {...rest}
      children={props => {
        const callbackUrl = `/?returnUrl=${encodeURI(props.location.pathname)}`;

        if (rest.isAuthenticated === true) {
          if (shouldBeTripbonder === true) {
            if (hasTripbonderDashboardAccess === true) {
              return <Component {...props} {...rest} />;
            } else {
              return (
                <Redirect
                  to={{
                    pathname: `/about/agreement`,
                    state: { from: props.location }
                  }}
                />
              );
            }
          } else {
            return <Component {...props} {...rest} />;
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: `/auth/login${callbackUrl}`,
                state: { from: props.location }
              }}
            />
          );
        }

        // return rest.isAuthenticated ? (
        //   <Component {...props} {...rest} />
        // ) : (
        //   <Redirect
        //     to={{
        //       pathname: `/auth/login${callbackUrl}`,
        //       state: { from: props.location }
        //     }}
        //   />
        // );
      }}
    />
  );
};

class Routes extends Component {
  constructor(props) {
    super(props);
    this.initializeApp = this.initializeApp.bind(this);
  }

  initializeApp() {
    AuthServices.PWAInit()
      .then(context => {
        controllers.initializePWA(context);
        controllers.connectToServer(getConfig().SERVER_BASE_ADDRS);

        // eslint-disable-next-line no-undef
        // googleyolo.hint({
        //   supportedAuthMethods: [
        //    "https://accounts.google.com"
        //   ],
        //   supportedIdTokenProviders: [{
        //     uri: "https://accounts.google.com",
        //     clientId: "684657866348-s5l7b4um2na3g7fplm3dl0ist4553om5.apps.googleusercontent.com"
        //   }],
        //   context: "signUp"
        // }).then((credential) => {
        //   /* hit backend api and API TOKEN here */
        //   /* Also save basic details that we get here */
        // }, (error)=> {
        //   console.log("Error occurred: ",error.type);
        // });
      })
      .catch(err => {
        setTimeout(() => {
          this.initializeApp();
        }, 1000);
      });
  }

  componentDidMount() {
    const { local } = this.props;
    if (local.isAppInitialized === false) {
      setTimeout(() => {
        this.initializeApp();
      }, 900);
    } else {
      window._hasAlreadyConnected = window._hasAlreadyConnected || false;
      if (window._hasAlreadyConnected === false) {
        window._hasAlreadyConnected = true;
        controllers.connectToServer(getConfig().SERVER_BASE_ADDRS);
      }
    }
  }

  render() {
    const { local } = this.props;
    return (
      <React.Fragment>
        {/* <Preloader isInitialized={local.isAppInitialized}></Preloader> */}
        {local.isAppInitialized ? (
          <Switch>
            <ProtectedRoute
              isAuthenticated={local.isAuthenticated}
              path="/dashboard"
              shouldBeTripbonder={true}
              hasTripbonderDashboardAccess={
                local.currentUser &&
                local.currentUser.hasTripbonderDashboardAccess
              }
              component={TripbonderLayout}
            />

            <ProtectedRoute
              isAuthenticated={local.isAuthenticated}
              path="/me"
              component={MarketingLayout}
            />

            <ProtectedRoute
              isAuthenticated={local.isAuthenticated}
              path="/about/agreement"
              component={MarketingLayout}
            />

            <AuthRoute
              isAuthenticated={local.isAuthenticated}
              path="/auth/:action"
              component={AuthLayout}
            />

            <Route path="/" component={MarketingLayout} />
          </Switch>
        ) : (
          "Loading"
        )}

        <GlobalMessage />
      </React.Fragment>
    );

    // if (local.isAppInitialized === true) {
    //   return (
    //     <React.Fragment>
    //       <Switch>
    //         <ProtectedRoute
    //           isAuthenticated={local.isAuthenticated}
    //           path="/dashboard"
    //           component={TripbonderLayout}
    //         />
    //         <AuthRoute
    //           isAuthenticated={local.isAuthenticated}
    //           path="/auth"
    //           component={AuthLayout}
    //         />
    //         <Route path="/" component={MarketingLayout} />
    //       </Switch>
    //       <GlobalMessage />
    //     </React.Fragment>
    //   );
    // } else {
    //   return (
    //     <Preloader />
    //   );
    // }
  }
}

export default connect(state => ({ local: state.local }))(Routes);
