export const HeaderLinks = (currentUser, isSidebar = false) => {
  const hasTripbonderAccess =
    (currentUser && currentUser.hasTripbonderAccess) || false;

  const links = [
    {
      title: "My Dashboard",
      to: "/dashboard",
      icon: "",
      shouldRender: () => {
        return hasTripbonderAccess === true;
      }
    },

    {
      title: "Who is Tripbonder",
      to: "/about/tripbonder",
      icon: "",
      shouldRender: () => {
        return hasTripbonderAccess === false;
      }
    },

    {
      title: "Become a Tripbonder",
      to: "/tripbonder/get-started",
      icon: "",
      shouldRender: () => {
        return hasTripbonderAccess === false;
      }
    },

    {
      title: "My Booking",
      to: "/me/orders",
      icon: "",
      shouldRender: () => {
        return isSidebar === true && currentUser;
      }
    },

    {
      title: "My Wishlist",
      to: "/me/wishlist",
      icon: "",
      shouldRender: () => {
        return isSidebar === true && currentUser;
      }
    },

    {
      title: "Profile",
      to: "/me/profile",
      icon: "",
      shouldRender: () => {
        return isSidebar === true && currentUser;
      }
    },

    {
      title: "Change Password",
      to: "/me/profile?password=change",
      icon: "",
      shouldRender: () => {
        return isSidebar === true && currentUser;
      }
    },

    {
      title: "Help",
      to: "/support/help/faq",
      icon: "",
      shouldRender: () => {
        return true;
      }
    }
  ];

  if (isSidebar) {
    return [
      ...links.slice(0, links.length - 2),
      ...TripbonderLinks(currentUser).slice(1),
      ...links.slice(links.length - 2, links.length)
    ];
  }

  return links;
};

export const TripbonderLinks = currentUser => {
  const hasTripbonderAccess =
    (currentUser && currentUser.hasTripbonderAccess) || false;

  const links = [
    {
      title: "Summary",
      to: "/dashboard/summary",
      icon: "fas fa-tachometer-alt",
      shouldRender: () => {
        return hasTripbonderAccess === true;
      }
    },

    {
      title: "My Listings",
      to: "/dashboard/listings",
      icon: "fas fa-list-ul",
      shouldRender: () => {
        return hasTripbonderAccess === true;
      }
    },

    {
      title: "My Bookings",
      to: "/dashboard/orders",
      icon: "fas fa-sort-amount-up-alt",
      shouldRender: () => {
        return hasTripbonderAccess === true;
      }
    },

    {
      title: "Inbox",
      to: "/dashboard/messages",
      icon: "fas fa-comments-dollar",
      shouldRender: () => {
        return hasTripbonderAccess === true;
      }
    },

    {
      title: "My Wallet",
      to: "/dashboard/account/wallet",
      icon: "fas fa-dollar-sign",
      shouldRender: () => {
        return hasTripbonderAccess === true;
      }
    },

    {
      title: "Discount Coupons",
      to: "/dashboard/coupons",
      icon: "fas fa-percentage",
      shouldRender: () => {
        return hasTripbonderAccess === true;
      }
    },

    {
      title: "Profile",
      to: "/dashboard/account/profile",
      icon: "fas fa-user-alt",
      shouldRender: () => {
        return hasTripbonderAccess === true;
      }
    },

    {
      title: "Create Package",
      to: "/dashboard/composer",
      icon: "fas fa-route",
      icon2: "fas fa-plus-circle",
      shouldRender: () => {
        return hasTripbonderAccess === true;
      }
    }
  ];

  return links;
};
