import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import ImagePlaceholder from "../../components/Draft/ImagePlaceholder";
import { ACTIVITIES, FOOD, STAY } from "../../views/Tripbonder/ItinerarySetup";
import GalleryController from "../MediaGallery/gallery.controller";
import controller from "../../store/controllers";
import LocationSearchInput from "./LocationSearchInput";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";

function ActivityItems(props) {
  const { t } = useTranslation();
  const [hintContext, setHintContext] = useState({});

  const { itinerary } = props;
  function setActivityImage() {
    GalleryController.insertMedia("", "", {}, mediaObj => {
      controller.itinerary.updateActivity(
        props.dayIndex,
        props.index,
        "imageId",
        mediaObj._id,
        props.category
      );
    });
  }

  const _getHintStateId = key => {
    return `_${key}_HINT`;
  };

  const getIsHintOpen = key => {
    if (hintContext[_getHintStateId(key)]) {
      return hintContext[_getHintStateId(key)];
    } else {
      return false;
    }
  };

  const toggleIsHintOpen = key => {
    setHintContext(
      Object.assign({}, hintContext, {
        [_getHintStateId(key)]: !getIsHintOpen(key)
      })
    );
  };

  return (
    <React.Fragment>
      <div className="row px-3 px-sm-5">
        <div className=" col-12 col-sm-auto  pt-2 pt-md-4 pb-3  activities px-0">
          <button
            id="day-activities"
            className="hint-btn mb-2 mx-0"
            type="button"
          >
            {"?"}
          </button>
          <Popover
            trigger="legacy"
            placement="right"
            isOpen={getIsHintOpen("day-activities")}
            target="day-activities"
            toggle={() => toggleIsHintOpen("day-activities")}
          >
            <PopoverHeader>{t("Points to consider")}</PopoverHeader>
            <PopoverBody>
              <ol>
                <li>{t("Try to keep the title short and precise")}</li>
              </ol>
            </PopoverBody>
            <PopoverHeader>{t("Examples")}</PopoverHeader>
            <PopoverBody>
              <ol>
                <li>{t("Bungee Jumping")}</li>
                <li>{t("Trekking")}</li>
                <li>{t("River Rafting")}</li>
              </ol>
            </PopoverBody>
          </Popover>
          <input
            readOnly={itinerary._status !== "draft"}
            value={props.item.title}
            onChange={e =>
              controller.itinerary.updateActivity(
                props.dayIndex,
                props.index,
                "title",
                e.target.value,
                props.category
              )
            }
            className="form-control"
            placeholder={t("Activities")}
          ></input>
        </div>
      </div>
      <div className="row px-3 px-sm-5">
        <div className="col-12 col-lg-4 trip-image px-0">
          <button
            id="activity-cover-art"
            className="hint-btn mx-0 mb-2"
            type="button"
          >
            {"?"}
          </button>
          <Popover
            trigger="legacy"
            placement="right"
            isOpen={getIsHintOpen("activity-cover-art")}
            target="activity-cover-art"
            toggle={() => toggleIsHintOpen("activity-cover-art")}
          >
            <PopoverHeader>{t("Points to consider")}</PopoverHeader>
            <PopoverBody>
              <ol>
                <li>{t("Should Upload images less than 2mb")}</li>
                <li>{t("Should not upload blur images")}</li>
                <li>{t("Image should give an idea about the activity")}</li>
              </ol>
            </PopoverBody>
            <PopoverHeader>{t("Examples")}</PopoverHeader>
            <PopoverBody>
              <div className="text-center">
                <img
                  height={150}
                  src={require("../../assets/images/bungee-jump-example.jpg")}
                  alt="history"
                />
              </div>
            </PopoverBody>
          </Popover>
          <ImagePlaceholder
            disabled={itinerary._status !== "draft"}
            onRemove={e =>
              controller.itinerary.updateActivity(
                props.dayIndex,
                props.index,
                "imageId",
                null,
                props.category
              )
            }
            value={controller.resolveAssetAddr(props.item.imageId)}
            onClick={() => setActivityImage()}
            message={t("Click to add image. Image cannot be larger than 2MB")}
            height="270px"
          />
        </div>
        <div className="col-12 col-lg-4 input-wrapper pl-lg-4 pt-4 pt-lg-0 px-0">
          <label className="pt-4 pt-lg-0" htmlFor="location">
            {t("LOCATION")}
          </label>
          <LocationSearchInput
            id="location"
            readOnly={itinerary._status !== "draft"}
            value={props.item.location}
            geoLocation={props.item.geoLocation}
            onChange={location =>
              controller.itinerary.updateActivityLoc(
                props.dayIndex,
                props.index,
                location,
                props.category
              )
            }
          />

          <label className="pt-4" htmlFor="about-the-activity">
            {t("ABOUT THE ACTIVITY")}
            <button id="about-the-activity" className="hint-btn" type="button">
              {"?"}
            </button>
            <Popover
              trigger="legacy"
              placement="right"
              isOpen={getIsHintOpen("about-the-activity")}
              target="about-the-activity"
              toggle={() => toggleIsHintOpen("about-the-activity")}
            >
              <PopoverHeader>{t("Points to consider")}</PopoverHeader>
              <PopoverBody>
                <ol>
                  <li>
                    {t(
                      "Should provide a brief description about the activity(maximum 6 to 8 sentences)"
                    )}
                  </li>
                </ol>
              </PopoverBody>
              <PopoverHeader>{t("Examples")}</PopoverHeader>
              <PopoverBody>
                <ol>
                  <li>
                    {t(
                      "Explore natural waterfall with crystal clear water pool to swim, a perfect place to explore the beauty of nature and taking a lot of cool photos."
                    )}
                  </li>
                  <li>
                    {t(
                      "Tsavo East Pick up from your Nairobi hotel or Airport in the morning and drive to Tsavo East National Park. The drive will be 6 hours to the main gate. Tsavo park is renowned for its large numbers of elephants and the famous man eating lions."
                    )}
                  </li>
                </ol>
              </PopoverBody>
            </Popover>
          </label>
          <textarea
            readOnly={itinerary._status !== "draft"}
            value={props.item.description}
            onChange={e =>
              controller.itinerary.updateActivity(
                props.dayIndex,
                props.index,
                "description",
                e.target.value,
                props.category
              )
            }
            className="form-control"
            id="about-the-activity"
            rows="5"
            placeholder={t("Description")}
          ></textarea>
        </div>
        <div className="col-12 col-lg-4 input-wrapper pl-lg-4 pt-4 pt-lg-0 px-0">
          <div className="row">
            <div className="col-12 col-lg-6">
              <label htmlFor="approx-time">{t("APPROX. TIME")}</label>
              <select
                disabled={itinerary._status !== "draft"}
                value={props.item.hours}
                onChange={e =>
                  controller.itinerary.updateActivity(
                    props.dayIndex,
                    props.index,
                    "hours",
                    parseInt(e.target.value),
                    props.category
                  )
                }
                id="approx-time"
                className="form-control"
              >
                <option>{t("Select Time")}</option>
                {props.local &&
                  props.local.hours
                    .filter(h => h.category === props.category)
                    .map(hour => (
                      <option key={hour.value} value={hour.value}>
                        {hour.label}
                      </option>
                    ))}
              </select>
            </div>
            <div className="col-12 col-lg-6 pt-4 pt-lg-0">
              <label htmlFor="approx-hours">{t("APPROX. HOURS")}</label>
              <input
                readOnly={itinerary._status !== "draft"}
                value={props.item.approxDuration}
                onChange={e =>
                  controller.itinerary.updateActivity(
                    props.dayIndex,
                    props.index,
                    "approxDuration",
                    parseFloat(e.target.value),
                    props.category
                  )
                }
                type="number"
                className="form-control"
                id="approx-hours"
                placeholder=""
              />
            </div>
            <div className="col-12">
              <label className="pt-4" htmlFor="tips-on-the-activity">
                {t("TIPS ON THE ACTIVITY")}
                <button
                  id="tips-on-the-activity"
                  className="hint-btn"
                  type="button"
                >
                  {"?"}
                </button>
                <Popover
                  trigger="legacy"
                  placement="right"
                  isOpen={getIsHintOpen("tips-on-the-activity")}
                  target="tips-on-the-activity"
                  toggle={() => toggleIsHintOpen("tips-on-the-activity")}
                >
                  <PopoverHeader>{t("Points to consider")}</PopoverHeader>
                  <PopoverBody>
                    <ol>
                      <li>
                        {t(
                          "You can include things like additional services and tips for the passengers"
                        )}
                      </li>
                      <li>{t("Try to write in points")}</li>
                    </ol>
                  </PopoverBody>
                  <PopoverHeader>{t("Examples")}</PopoverHeader>
                  <PopoverBody>
                    <ol>
                      <li>{t("Please carry your own  water bottle")}</li>
                      <li>
                        {t("Pregnant women are prohibited from this activity")}
                      </li>
                      <li>
                        {t(
                          "This activity is not accessible by wheelchairs or strollers"
                        )}
                      </li>
                    </ol>
                  </PopoverBody>
                </Popover>
              </label>
              <textarea
                readOnly={itinerary._status !== "draft"}
                value={props.item.tips}
                onChange={e =>
                  controller.itinerary.updateActivity(
                    props.dayIndex,
                    props.index,
                    "tips",
                    e.target.value,
                    props.category
                  )
                }
                className="form-control"
                id="tips-on-the-activity"
                rows="5"
                placeholder={t("Tips")}
              ></textarea>
            </div>
          </div>
        </div>
        {itinerary._status === "draft" ? (
          <div className="delete-button-wrapper text-center py-3 mt-lg-4">
            <div className="dropdown">
              <button
                className="btn btn-link dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t("Remove")}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <button
                  onClick={e =>
                    controller.itinerary.removeActivity(
                      props.dayIndex,
                      props.index,
                      props.category
                    )
                  }
                  className="dropdown-item"
                >
                  {t("Confirm Remove")}
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
}

function FoodItems(props) {
  const { t } = useTranslation();
  const { itinerary } = props;
  const [hintContext, setHintContext] = useState({});
  function setFoodItemImage() {
    GalleryController.insertMedia("", "", {}, mediaObj => {
      controller.itinerary.updateFoodActivity(
        props.dayIndex,
        props.index,
        "imageId",
        mediaObj._id,
        props.category
      );
    });
  }

  const _getHintStateId = key => {
    return `_${key}_HINT`;
  };

  const getIsHintOpen = key => {
    if (hintContext[_getHintStateId(key)]) {
      return hintContext[_getHintStateId(key)];
    } else {
      return false;
    }
  };

  const toggleIsHintOpen = key => {
    setHintContext(
      Object.assign({}, hintContext, {
        [_getHintStateId(key)]: !getIsHintOpen(key)
      })
    );
  };

  function transformFoodCategory(inputCategory) {
    switch (inputCategory) {
      case "breakfast": {
        return "morning";
      }
      case "lunch": {
        return "afternoon";
      }
      case "dinner": {
        return "evening";
      }
      default: {
        return inputCategory;
      }
    }
  }

  return (
    <React.Fragment>
      <div className="row px-3 px-sm-5">
        <div className=" col-12 col-sm-auto pt-2 pt-md-4 pb-3  activities px-0">
          <button
            id="about-the-food-activity"
            className="hint-btn mb-2 mx-0"
            type="button"
          >
            {"?"}
          </button>
          <Popover
            trigger="legacy"
            placement="right"
            isOpen={getIsHintOpen("about-the-food-activity")}
            target="about-the-food-activity"
            toggle={() => toggleIsHintOpen("about-the-food-activity")}
          >
            <PopoverHeader>{t("Points to consider")}</PopoverHeader>
            <PopoverBody>
              <ol>
                <li>{t("Try to keep the title short and precise")}</li>
              </ol>
            </PopoverBody>
            <PopoverHeader>{t("Examples")}</PopoverHeader>
            <PopoverBody>
              <ol>
                <li>{t("Continental Food")}</li>
                <li>{t("South Indian Food")}</li>
                <li>{t("Chinese")}</li>
              </ol>
            </PopoverBody>
          </Popover>
          <input
            readOnly={itinerary._status !== "draft"}
            value={props.item.title}
            onChange={e =>
              controller.itinerary.updateFoodActivity(
                props.dayIndex,
                props.index,
                "title",
                e.target.value,
                props.category
              )
            }
            className="form-control"
            placeholder={t("Activities")}
          ></input>
        </div>
      </div>
      <div className="row px-3 px-sm-5">
        <div className="col-12 col-lg-4 trip-image px-0">
          <button
            id="food-cover-art"
            className="hint-btn mx-0 mb-2"
            type="button"
          >
            {"?"}
          </button>
          <Popover
            trigger="legacy"
            placement="right"
            isOpen={getIsHintOpen("food-cover-art")}
            target="food-cover-art"
            toggle={() => toggleIsHintOpen("food-cover-art")}
          >
            <PopoverHeader>{t("Points to consider")}</PopoverHeader>
            <PopoverBody>
              <ol>
                <li>{t("Should Upload images less than 2mb")}</li>
                <li>{t("Should not upload blur images")}</li>
                <li>{t("Image should give an idea about the food")}</li>
              </ol>
            </PopoverBody>
            <PopoverHeader>{t("Examples")}</PopoverHeader>
            <PopoverBody>
              <div className="text-center">
                <img
                  height={150}
                  src={require("../../assets/images/sadhya-example.jpg")}
                  alt="history"
                />
              </div>
            </PopoverBody>
          </Popover>
          <ImagePlaceholder
            disabled={itinerary._status !== "draft"}
            onRemove={e =>
              controller.itinerary.updateFoodActivity(
                props.dayIndex,
                props.index,
                "imageId",
                null,
                props.category
              )
            }
            value={controller.resolveAssetAddr(props.item.imageId)}
            onClick={() => setFoodItemImage()}
            message={t("Click to add image. Image cannot be larger than 2MB")}
            height="270px"
          />
        </div>
        <div className="col-12 col-lg-4 input-wrapper pl-lg-4 pt-4 pt-lg-0 px-0">
          <label className="pt-4 pt-lg-0" htmlFor="location">
            {t("LOCATION")}
          </label>
          <LocationSearchInput
            id="location"
            readOnly={itinerary._status !== "draft"}
            value={props.item.location}
            geoLocation={props.item.geoLocation}
            onChange={location =>
              controller.itinerary.updateActivityLoc(
                props.dayIndex,
                props.index,
                location,
                props.category
              )
            }
          />

          <label className="pt-4" htmlFor="about-the-activity">
            {t("ABOUT THE ACTIVITY")}
            <button id="food-activity" className="hint-btn" type="button">
              {"?"}
            </button>
            <Popover
              trigger="legacy"
              placement="right"
              isOpen={getIsHintOpen("food-activity")}
              target="food-activity"
              toggle={() => toggleIsHintOpen("food-activity")}
            >
              <PopoverHeader>{t("Points to consider")}</PopoverHeader>
              <PopoverBody>
                <ol>
                  <li>
                    {t(
                      "Should provide a brief description about the food(maximum 6 to 8 sentences)"
                    )}
                  </li>
                  <li>{t("Mention the type of food , eg: veg or non veg")}</li>
                </ol>
              </PopoverBody>
              <PopoverHeader>{t("Examples")}</PopoverHeader>
              <PopoverBody>
                <ol>
                  <li>
                    {t(
                      "Traditional non veg Indian thali will be served on banana leaf followed by traditional dessert(payasam)"
                    )}
                  </li>
                  <li>
                    {t(
                      "Lunch will be served in Local Restaurant to taste traditional Balinese dishes (optional)"
                    )}
                  </li>
                </ol>
              </PopoverBody>
            </Popover>
          </label>

          <textarea
            readOnly={itinerary._status !== "draft"}
            value={props.item.description}
            onChange={e =>
              controller.itinerary.updateFoodActivity(
                props.dayIndex,
                props.index,
                "description",
                e.target.value,
                props.category
              )
            }
            className="form-control"
            id="about-the-activity"
            rows="5"
            placeholder={t("Description")}
          ></textarea>
        </div>
        <div className="col-12 col-lg-4 input-wrapper pl-lg-4 pt-4 pt-lg-0 px-0">
          <div className="row">
            <div className="col-12 col-lg-6">
              <label htmlFor="approx-time">{t("APPROX. TIME")}</label>

              <select
                disabled={itinerary._status !== "draft"}
                value={props.item.hours}
                onChange={e =>
                  controller.itinerary.updateFoodActivity(
                    props.dayIndex,
                    props.index,
                    "hours",
                    parseInt(e.target.value),
                    props.category
                  )
                }
                id="approx-time"
                className="form-control"
              >
                <option>{t("Select Time")}</option>
                {props.local &&
                  props.local.hours
                    .filter(
                      h => h.category === transformFoodCategory(props.category)
                    )
                    .map(hour => (
                      <option key={hour.value} value={hour.value}>
                        {hour.label}
                      </option>
                    ))}
              </select>
            </div>
            <div className="col-12 col-lg-6 pt-4 pt-lg-0">
              <label htmlFor="approx-hours">{t("APPROX. HOURS")}</label>
              <input
                readOnly={itinerary._status !== "draft"}
                value={props.item.approxDuration}
                onChange={e =>
                  controller.itinerary.updateFoodActivity(
                    props.dayIndex,
                    props.index,
                    "approxDuration",
                    parseFloat(e.target.value),
                    props.category
                  )
                }
                type="number"
                className="form-control"
                id="approx-hours"
                placeholder={t("2")}
              />
            </div>
            <div className="col-12"></div>
          </div>
        </div>
        {itinerary._status === "draft" ? (
          <div className="delete-button-wrapper text-center py-3 mt-lg-4">
            <div className="dropdown">
              <button
                className="btn btn-link dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {t("Remove")}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <button
                  onClick={e =>
                    controller.itinerary.removeFoodActivity(
                      props.dayIndex,
                      props.index,
                      props.category
                    )
                  }
                  className="dropdown-item"
                >
                  {t("Confirm Remove")}
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
}

function StayImages(props) {
  const { t } = useTranslation();
  const { itinerary } = props;
  function setStayImage() {
    GalleryController.insertMedia("", "", {}, mediaObj => {
      controller.itinerary.updateStayImage(
        props.dayIndex,
        props.index,
        "imageId",
        mediaObj._id
      );
    });
  }

  return (
    <div className="photo-wrapper w-100">
      <div className="stay-image-wrapper">
        <ImagePlaceholder
          disabled={itinerary._status !== "draft"}
          onRemove={e =>
            controller.itinerary.updateStayImage(
              props.dayIndex,
              props.index,
              "imageId",
              null
            )
          }
          value={controller.resolveAssetAddr(props.item.imageId)}
          onClick={() => setStayImage()}
          message={t("Click to add image. Image cannot be larger than 2MB")}
          height="260px"
        />
      </div>
      <textarea
        readOnly={itinerary._status !== "draft"}
        value={props.item.description}
        onChange={e =>
          controller.itinerary.updateStayImage(
            props.dayIndex,
            props.index,
            "description",
            e.target.value
          )
        }
        className="form-control mt-4"
        id="about-the-place"
        rows="5"
        placeholder={t("Description")}
      ></textarea>
      {itinerary._status === "draft" ? (
        <div className="delete-button-wrapper text-center py-3">
          <div className="dropdown">
            <button
              className="btn btn-link dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {t("Remove")}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <button
                onClick={e =>
                  controller.itinerary.removeStayImage(
                    props.dayIndex,
                    props.index
                  )
                }
                className="dropdown-item"
              >
                {t("Confirm Remove")}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

function Activities(props) {
  const { t } = useTranslation();
  const { day, selectedDayIndex, itinerary } = props;

  return (
    <div className="container day-editor-content-wrapper">
      <div className="row">
        <div className="col-12 px-0">
          <div className="pt-3 mt-2 day-editor-accordion-wrapper">
            <div className="accordion" id="dayEditorAccordion">
              <div className="card dayEditor-item-wrapper mb-2">
                <button
                  className="card-header d-flex"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  id="dayEditorHeadingOne"
                >
                  <div className="flex-shrink-0"></div>
                  <div className="mb-0 text-center flex-fill truncate">
                    <img src={require("../../assets/images/dawn.png")} alt="" />
                    <h5 className="mb-0 my-1 pl-2 truncate">
                      {t("Morning Activities")}
                    </h5>
                  </div>
                  <div className="flex-shrink-0">
                    <i className="fas fa-chevron-circle-up mt-1"></i>
                  </div>
                </button>
                <div
                  id="collapseOne"
                  className="collapse show mb-2"
                  aria-labelledby="dayEditorHeadingOne"
                  data-parent="#dayEditorAccordion"
                >
                  <div className="container" style={{ marginBottom: 40 }}>
                    {itinerary._status === "draft" ? (
                      <div className="row">
                        <div className="col-12 text-center text-md-left pt-0 pt-md-2">
                          <button
                            onClick={() =>
                              controller.itinerary.addActivity(
                                selectedDayIndex,
                                "morning"
                              )
                            }
                            className="circle btn btn-link btn-sm"
                          >
                            <i className="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {day.morningActivities.length < 1 ? (
                      <div className="row mt-4">
                        <div className="col-12">
                          <div className="jumbotron">
                            <h1 className="display-4">{t("No Activities")}</h1>
                            <p className="lead">
                              {t(
                                "You are yet to add any morning activities to this day of the trip."
                              )}
                            </p>
                            <hr className="my-2" />
                            <p>
                              {t(
                                "Use the Add Activity button below to add activities to the corresponding day of the trip itinerary."
                              )}
                            </p>
                            {itinerary._status === "draft" ? (
                              <p className="lead">
                                <button
                                  onClick={() =>
                                    controller.itinerary.addActivity(
                                      selectedDayIndex,
                                      "morning"
                                    )
                                  }
                                  className="btn btn-primary btn-lg"
                                >
                                  <i className="fas fa-plus-circle"></i>
                                  {t("Add Activity")}
                                </button>
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : (
                      day.morningActivities.map((activity, index) => (
                        <ActivityItems
                          itinerary={itinerary}
                          key={index}
                          index={index}
                          dayIndex={selectedDayIndex}
                          item={activity}
                          category="morning"
                          local={props.local}
                        />
                      ))
                    )}
                  </div>
                </div>
              </div>
              <div className="card dayEditor-item-wrapper mb-2 p-t-2">
                <button
                  className="card-header d-flex collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                  id="dayEditorHeadingTwo"
                >
                  <div className="flex-shrink-0"></div>
                  <div className="mb-0 text-center flex-fill truncate">
                    <img
                      src={require("../../assets/images/sunny.png")}
                      alt=""
                    />

                    <h5 className="mb-0 my-1 pl-2 truncate">
                      {t("Afternoon Activities")}
                    </h5>
                  </div>
                  <div className="flex-shrink-0">
                    <i className="fas fa-chevron-circle-up mt-1"></i>
                  </div>
                </button>

                <div
                  id="collapseTwo"
                  className="collapse mb-2"
                  aria-labelledby="dayEditorHeadingTwo"
                  data-parent="#dayEditorAccordion"
                >
                  <div className="container" style={{ marginBottom: 40 }}>
                    {itinerary._status === "draft" ? (
                      <div className="row">
                        <div className="col-12 text-center text-md-left pt-0 pt-md-2">
                          <button
                            onClick={() =>
                              controller.itinerary.addActivity(
                                selectedDayIndex,
                                "afternoon"
                              )
                            }
                            className="circle btn btn-link btn-sm"
                          >
                            <i className="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {day.afternoonActivities.length < 1 ? (
                      <div className="row mt-4">
                        <div className="col-12">
                          <div className="jumbotron">
                            <h1 className="display-4">{t("No Activities")}</h1>
                            <p className="lead">
                              {t(
                                "You are yet to add any afternoon activities to this day of the trip."
                              )}
                            </p>
                            <hr className="my-2" />
                            <p>
                              {t(
                                "Use the Add Activity button below to add activities to the corresponding day of the trip itinerary."
                              )}
                            </p>
                            {itinerary._status === "draft" ? (
                              <p className="lead">
                                <button
                                  onClick={() =>
                                    controller.itinerary.addActivity(
                                      selectedDayIndex,
                                      "afternoon"
                                    )
                                  }
                                  className="btn btn-primary btn-lg"
                                >
                                  <i className="fas fa-plus-circle"></i>
                                  {t("Add Activity")}
                                </button>
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : (
                      day.afternoonActivities.map((activity, index) => (
                        <ActivityItems
                          itinerary={itinerary}
                          key={index}
                          index={index}
                          dayIndex={selectedDayIndex}
                          item={activity}
                          category="afternoon"
                          local={props.local}
                        />
                      ))
                    )}
                  </div>
                </div>
              </div>
              <div className="card dayEditor-item-wrapper mb-2 p-t-2">
                <button
                  className="card-header d-flex collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                  id="dayEditorHeadingThree"
                >
                  <div className="flex-shrink-0"></div>
                  <div className="mb-0 text-center flex-fill truncate">
                    <img
                      src={require("../../assets/images/night.png")}
                      alt=""
                    />

                    <h5 className="mb-0 my-1 pl-2 truncate">
                      {t("Evening Activities")}
                    </h5>
                  </div>
                  <div className="flex-shrink-0">
                    <i className="fas fa-chevron-circle-up mt-1"></i>
                  </div>
                </button>

                <div
                  id="collapseThree"
                  className="collapse mb-2"
                  aria-labelledby="dayEditorHeadingThree"
                  data-parent="#dayEditorAccordion"
                >
                  <div className="container" style={{ marginBottom: 40 }}>
                    {itinerary._status === "draft" ? (
                      <div className="row">
                        <div className="col-12 text-center text-md-left pt-0 pt-md-2">
                          <button
                            onClick={() =>
                              controller.itinerary.addActivity(
                                selectedDayIndex,
                                "evening"
                              )
                            }
                            className="circle btn btn-link btn-sm"
                          >
                            <i className="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {day.eveningActivities.length < 1 ? (
                      <div className="row mt-4">
                        <div className="col-12">
                          <div className="jumbotron">
                            <h1 className="display-4">{t("No Activities")}</h1>
                            <p className="lead">
                              {t(
                                "You are yet to add any evening activities to this day of the trip."
                              )}
                            </p>
                            <hr className="my-2" />
                            <p>
                              {t(
                                "Use the Add Activity button below to add activities to the corresponding day of the trip itinerary."
                              )}
                            </p>

                            {itinerary._status === "draft" ? (
                              <p className="lead">
                                <button
                                  onClick={() =>
                                    controller.itinerary.addActivity(
                                      selectedDayIndex,
                                      "evening"
                                    )
                                  }
                                  className="btn btn-primary btn-lg"
                                >
                                  <i className="fas fa-plus-circle"></i>
                                  {t("Add Activity")}
                                </button>
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : (
                      day.eveningActivities.map((activity, index) => (
                        <ActivityItems
                          itinerary={itinerary}
                          key={index}
                          index={index}
                          dayIndex={selectedDayIndex}
                          item={activity}
                          category="evening"
                          local={props.local}
                        />
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Food(props) {
  const { t } = useTranslation();
  const { day, selectedDayIndex, itinerary } = props;
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center text-md-left px-0">
          <div className="pt-3 mt-2 day-editor-accordion-wrapper">
            <div className="accordion" id="dayEditorAccordion">
              <div className="card dayEditor-item-wrapper mb-2 pt-2">
                <button
                  className="card-header d-flex"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  id="dayEditorHeadingOne"
                >
                  <div className="flex-shrink-0"></div>
                  <div className="mb-0 text-center flex-fill truncate">
                    <h5 className="mb-0 my-1 pl-2 truncate">
                      {t("Breakfast")}
                    </h5>
                  </div>
                  <div className="flex-shrink-0">
                    <i className="fas fa-chevron-circle-up mt-1"></i>
                  </div>
                </button>
                <div
                  id="collapseOne"
                  className="collapse show mb-2"
                  aria-labelledby="dayEditorHeadingOne"
                  data-parent="#dayEditorAccordion"
                >
                  <div className="container" style={{ marginBottom: 40 }}>
                    {itinerary._status === "draft" ? (
                      <div className="row">
                        <div className="col-12 text-center text-md-left pt-0 pt-md-2">
                          <button
                            onClick={() =>
                              controller.itinerary.addFoodActivity(
                                selectedDayIndex,
                                "breakfast"
                              )
                            }
                            className="circle btn btn-link btn-sm"
                          >
                            <i className="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {day.breakfastActivities.length < 1 ? (
                      <div className="row mt-4">
                        <div className="col-12">
                          <div className="jumbotron">
                            <h1 className="display-4">{t("No Foods?")}</h1>
                            <p clas="lead">
                              {t(
                                "You are yet to include any breakfast details for the day"
                              )}
                            </p>
                            <hr className="my-2" />
                            <p>
                              {t(
                                "Use the Add Food button below to include the breakfast arrangements for the day"
                              )}
                            </p>
                            {itinerary._status === "draft" ? (
                              <p className="lead">
                                <button
                                  onClick={() =>
                                    controller.itinerary.addFoodActivity(
                                      selectedDayIndex,
                                      "breakfast"
                                    )
                                  }
                                  className="btn btn-primary btn-lg"
                                >
                                  <i className="fas fa-plus-circle"></i>
                                  {t("Add Food")}
                                </button>
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : (
                      day.breakfastActivities.map((foodActivity, index) => (
                        <FoodItems
                          itinerary={itinerary}
                          key={index}
                          index={index}
                          dayIndex={selectedDayIndex}
                          item={foodActivity}
                          category="breakfast"
                          local={props.local}
                        />
                      ))
                    )}
                  </div>
                </div>
              </div>
              <div className="card dayEditor-item-wrapper mb-2 p-t-2">
                <button
                  className="card-header d-flex collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                  id="dayEditorHeadingTwo"
                >
                  <div className="flex-shrink-0"></div>
                  <div className="mb-0 text-center flex-fill truncate">
                    <h5 className="mb-0 my-1 pl-2 truncate">{t("Lunch")}</h5>
                  </div>
                  <div className="flex-shrink-0">
                    <i className="fas fa-chevron-circle-up mt-1"></i>
                  </div>
                </button>

                <div
                  id="collapseTwo"
                  className="collapse mb-2"
                  aria-labelledby="dayEditorHeadingTwo"
                  data-parent="#dayEditorAccordion"
                >
                  <div className="container" style={{ marginBottom: 40 }}>
                    {itinerary._status === "draft" ? (
                      <div className="row">
                        <div className="col-12 text-center text-md-left pt-0 pt-md-2">
                          <button
                            onClick={() =>
                              controller.itinerary.addFoodActivity(
                                selectedDayIndex,
                                "lunch"
                              )
                            }
                            className="circle btn btn-link btn-sm"
                          >
                            <i className="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {day.lunchActivities.length < 1 ? (
                      <div className="row mt-4">
                        <div className="col-12">
                          <div className="jumbotron">
                            <h1 className="display-4">{t("No Foods?")}</h1>
                            <p clas="lead">
                              {t(
                                "You are yet to include any lunch details for the day"
                              )}
                            </p>
                            <hr className="my-2" />
                            <p>
                              {t(
                                "Use the Add Food button below to include the lunch arrangements for the day"
                              )}
                            </p>
                            {itinerary._status === "draft" ? (
                              <p className="lead">
                                <button
                                  onClick={() =>
                                    controller.itinerary.addFoodActivity(
                                      selectedDayIndex,
                                      "lunch"
                                    )
                                  }
                                  className="btn btn-primary btn-lg"
                                >
                                  <i className="fas fa-plus-circle"></i>
                                  {t("Add Food")}
                                </button>
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : (
                      day.lunchActivities.map((foodActivity, index) => (
                        <FoodItems
                          itinerary={itinerary}
                          key={index}
                          index={index}
                          dayIndex={selectedDayIndex}
                          item={foodActivity}
                          category="lunch"
                          local={props.local}
                        />
                      ))
                    )}
                  </div>
                </div>
              </div>
              <div className="card dayEditor-item-wrapper mb-2 p-t-2">
                <button
                  className="card-header d-flex collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                  id="dayEditorHeadingThree"
                >
                  <div className="flex-shrink-0"></div>
                  <div className="mb-0 text-center flex-fill truncate">
                    <h5 className="mb-0 my-1 pl-2 truncate">{t("Dinner")}</h5>
                  </div>
                  <div className="flex-shrink-0">
                    <i className="fas fa-chevron-circle-up mt-1"></i>
                  </div>
                </button>

                <div
                  id="collapseThree"
                  className="collapse mb-2"
                  aria-labelledby="dayEditorHeadingThree"
                  data-parent="#dayEditorAccordion"
                >
                  <div className="container" style={{ marginBottom: 40 }}>
                    {itinerary._status === "draft" ? (
                      <div className="row">
                        <div className="col-12 text-center text-md-left pt-0 pt-md-2">
                          <button
                            onClick={() =>
                              controller.itinerary.addFoodActivity(
                                selectedDayIndex,
                                "dinner"
                              )
                            }
                            className="circle btn btn-link btn-sm"
                          >
                            <i className="fas fa-plus"></i>
                          </button>
                        </div>
                      </div>
                    ) : null}
                    {day.dinnerActivities.length < 1 ? (
                      <div className="row mt-4">
                        <div className="col-12">
                          <div className="jumbotron">
                            <h1 className="display-4">{t("No Foods?")}</h1>
                            <p clas="lead">
                              {t(
                                "You are yet to include any dinner details for the day"
                              )}
                            </p>
                            <hr className="my-2" />
                            <p>
                              {t(
                                "Use the Add Food button below to include the dinner arrangements for the day"
                              )}
                            </p>
                            {itinerary._status === "draft" ? (
                              <p className="lead">
                                <button
                                  onClick={() =>
                                    controller.itinerary.addFoodActivity(
                                      selectedDayIndex,
                                      "dinner"
                                    )
                                  }
                                  className="btn btn-primary btn-lg"
                                >
                                  <i className="fas fa-plus-circle"></i>
                                  {t("Add Food")}
                                </button>
                              </p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : (
                      day.dinnerActivities.map((foodActivity, index) => (
                        <FoodItems
                          itinerary={itinerary}
                          key={index}
                          index={index}
                          dayIndex={selectedDayIndex}
                          item={foodActivity}
                          category="dinner"
                          local={props.local}
                        />
                      ))
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function Stay(props) {
  const { t } = useTranslation();
  const { day, selectedDayIndex, itinerary } = props;
  const [hintContext, setHintContext] = useState({});

  const _getHintStateId = key => {
    return `_${key}_HINT`;
  };

  const getIsHintOpen = key => {
    if (hintContext[_getHintStateId(key)]) {
      return hintContext[_getHintStateId(key)];
    } else {
      return false;
    }
  };

  const toggleIsHintOpen = key => {
    setHintContext(
      Object.assign({}, hintContext, {
        [_getHintStateId(key)]: !getIsHintOpen(key)
      })
    );
  };

  return (
    <div className="container stay-editor" style={{ marginBottom: 80 }}>
      <div className="row pb-5">
        <div className="col-12 px-0">
          <div className="pt-3 mt-2 day-editor-accordion-wrapper">
            <div className="accordion" id="dayEditorAccordion">
              <div className="card dayEditor-item-wrapper mb-2 p-t-2">
                <button
                  className="card-header d-flex"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  id="dayEditorHeadingOne"
                >
                  <div className="flex-shrink-0"></div>
                  <div className="mb-0 text-center flex-fill truncate">
                    <h5 className="mb-0 my-1 pl-2 truncate">
                      {t("General Information")}
                    </h5>
                  </div>
                  <div className="flex-shrink-0">
                    <i className="fas fa-chevron-circle-up mt-1"></i>
                  </div>
                </button>

                <div
                  id="collapseOne"
                  className="collapse show mb-2"
                  aria-labelledby="dayEditorHeadingOne"
                  data-parent="#dayEditorAccordion"
                >
                  <div className="row pb-3 mx-2 mx-lg-0">
                    <div className="col-12 input-wrapper pt-4">
                      <label className="" htmlFor="night-stay-location">
                        {t("NIGHT STAY LOCATION")}
                      </label>
                      <LocationSearchInput
                        readOnly={itinerary._status !== "draft"}
                        id="night-stay-location"
                        value={day.stayLocation}
                        geoLocation={day.stayGeoLocation}
                        onChange={location =>
                          controller.itinerary.updateStayLocation(
                            selectedDayIndex,
                            location
                          )
                        }
                      />

                      <div className="input-wrapper">
                        <label className="pt-4" htmlFor="about-the-place">
                          {t("ABOUT THE PLACE")}
                          <button
                            id="about-the-night-stay-place"
                            className="hint-btn"
                            type="button"
                          >
                            {"?"}
                          </button>
                          <Popover
                            trigger="legacy"
                            placement="right"
                            isOpen={getIsHintOpen("about-the-night-stay-place")}
                            target="about-the-night-stay-place"
                            toggle={() =>
                              toggleIsHintOpen("about-the-night-stay-place")
                            }
                          >
                            <PopoverHeader>
                              {t("Points to consider")}
                            </PopoverHeader>
                            <PopoverBody>
                              <ol>
                                <li>
                                  {t(
                                    "Should provide a brief description about the stay(maximum 6 to 8 sentences)"
                                  )}
                                </li>
                              </ol>
                            </PopoverBody>
                            <PopoverHeader>{t("Examples")}</PopoverHeader>
                            <PopoverBody>
                              <ol>
                                <li>
                                  {t(
                                    "This is a leisure hotel located in Ximen District, Taipei. The hotel features a business center and fitness center. Enjoy offers such as 24-hour light snacks, luggage storage, and free wifi services. It's a 1-minute walk away from Ximen station, and the closest convenience store is 1-minute away on foot. Visit nearby attractions like the Ximending Shopping Street and The Red House."
                                  )}
                                </li>
                                <li>
                                  {t(
                                    "This is a business hotel located in Daan District, Taipei City. The hotel features a business center and a shared lounge. Enjoy offers such as 24 hours access to the snack bar and modern self-laundry machines It's a 1-minute walk away from Zhongxiao Dunhua MRT Station, and the closest convenience store is 3 minutes away on foot."
                                  )}
                                </li>
                              </ol>
                            </PopoverBody>
                          </Popover>
                        </label>
                        <textarea
                          readOnly={itinerary._status !== "draft"}
                          value={day.stayDescription}
                          onChange={e =>
                            controller.itinerary.updateDay(
                              selectedDayIndex,
                              "stayDescription",
                              e.target.value
                            )
                          }
                          className="form-control"
                          id="about-the-place"
                          rows="3"
                          placeholder={t("Description")}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card dayEditor-item-wrapper mb-2 p-t-2">
                <button
                  className="card-header d-flex collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                  id="dayEditorHeadingTwo"
                >
                  <div className="flex-shrink-0"></div>
                  <div className="mb-0 text-center flex-fill truncate">
                    <h5 className="mb-0 my-1 pl-2 truncate">
                      {t("Hotel Photos")}
                    </h5>
                  </div>
                  <div className="flex-shrink-0">
                    <i className="fas fa-chevron-circle-up mt-1"></i>
                  </div>
                </button>
                <div
                  id="collapseTwo"
                  className="collapse mb-2"
                  aria-labelledby="dayEditorHeadingTwo"
                  data-parent="#dayEditorAccordion"
                >
                  <div className="row">
                    <div className="col-12 input-wrapper button-wrapper d-flex pt-4">
                      <div className="flex-shrink-0">
                        <label className="pl-3">
                          {t("PHOTOS")}
                          <button
                            id="stay-cover-art"
                            className="hint-btn"
                            type="button"
                            style={{ color: "white" }}
                          >
                            {"?"}
                          </button>
                          <Popover
                            trigger="legacy"
                            placement="right"
                            isOpen={getIsHintOpen("stay-cover-art")}
                            target="stay-cover-art"
                            toggle={() => toggleIsHintOpen("stay-cover-art")}
                          >
                            <PopoverHeader>
                              {t("Points to consider")}
                            </PopoverHeader>
                            <PopoverBody>
                              <ol>
                                <li>
                                  {t("Should Upload images less than 2mb")}
                                </li>
                                <li>{t("Should not upload blur images")}</li>
                                <li>
                                  {t(
                                    "Image should give an idea about the stay"
                                  )}
                                </li>
                              </ol>
                            </PopoverBody>
                            <PopoverHeader>{t("Examples")}</PopoverHeader>
                            <PopoverBody>
                              <div className="text-center">
                                <img
                                  height={150}
                                  width={220}
                                  src={require("../../assets/images/hotel-example.jpg")}
                                  alt="history"
                                />
                              </div>
                            </PopoverBody>
                          </Popover>
                        </label>
                      </div>
                      <div className="flex-fill"></div>
                      {itinerary._status === "draft" ? (
                        <div className="flex-shrink-0">
                          <button
                            className="mr-3"
                            onClick={() =>
                              controller.itinerary.addStayImage(
                                selectedDayIndex
                              )
                            }
                          >
                            <i className="fas fa-plus-circle pr-1"></i>
                            {t("Add photos")}
                          </button>
                        </div>
                      ) : null}
                    </div>
                    {day.stayImages.length < 1 ? (
                      <div className="col-12 mt-4">
                        <div className="jumbotron">
                          <h1 className="display-4">{t("Have Pics?")}</h1>
                          <p clas="lead">
                            {t(
                              "By Including pictures you have a higher chance of attracting potential customers"
                            )}
                          </p>
                          <hr className="my-2" />
                          <p>
                            {t(
                              "Use the Add Photos button below to upload photos of the stay location"
                            )}
                          </p>
                          {itinerary._status === "draft" ? (
                            <p className="lead">
                              <button
                                onClick={() =>
                                  controller.itinerary.addStayImage(
                                    selectedDayIndex
                                  )
                                }
                                className="btn btn-primary btn-lg"
                              >
                                <i className="fas fa-plus-circle"></i>
                                {t("Add Photos")}
                              </button>
                            </p>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <div
                        className="col-12 mx-3 "
                        style={{ marginBottom: 40 }}
                      >
                        {day.stayImages.map((stayImage, index) => (
                          <StayImages
                            itinerary={itinerary}
                            key={index}
                            index={index}
                            dayIndex={selectedDayIndex}
                            item={stayImage}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="card dayEditor-item-wrapper mb-2 p-t-2">
                <button
                  className="card-header d-flex collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                  id="dayEditorHeadingThree"
                >
                  <div className="flex-shrink-0"></div>
                  <div className="mb-0 text-center flex-fill truncate">
                    <h5 className="mb-0 my-1 pl-2 truncate">
                      {t("Features Available")}
                    </h5>
                  </div>
                  <div className="flex-shrink-0">
                    <i className="fas fa-chevron-circle-up mt-1"></i>
                  </div>
                </button>

                <div
                  id="collapseThree"
                  className="collapse mb-2"
                  aria-labelledby="dayEditorHeadingThree"
                  data-parent="#dayEditorAccordion"
                >
                  <div className="container">
                    <div className="row py-4">
                      <div className="col-12 col-lg-4 activities">
                        <h5 className="pt-2">{t("_ Internet Access")}</h5>
                        {props.local.internetaccessMaster.map((item, index) => (
                          <div key={index} className="form-check pt-3">
                            <input
                              disabled={itinerary._status !== "draft"}
                              onChange={e =>
                                controller.itinerary.toggleDayStayFeature(
                                  selectedDayIndex,
                                  "internetFeatures",
                                  item.value
                                )
                              }
                              type="checkbox"
                              checked={
                                day.internetFeatures.indexOf(item.value) > -1
                              }
                              className="form-check-input"
                              id={`internet-${item.value}`}
                            />

                            <label
                              className="form-check-label pr-5"
                              htmlFor={`internet-${item.value}`}
                            >
                              {t(item.label)}
                            </label>
                          </div>
                        ))}
                      </div>
                      <div className="col-12 col-lg-4 activities">
                        <h5 className="pt-2">
                          {t("_ Services & Conveniences")}
                        </h5>
                        {props.local.accomodationAmenitiesMaster.map(
                          (item, index) => (
                            <div key={index} className="form-check pt-3">
                              <input
                                disabled={itinerary._status !== "draft"}
                                onChange={e =>
                                  controller.itinerary.toggleDayStayFeature(
                                    selectedDayIndex,
                                    "amenities",
                                    item.value
                                  )
                                }
                                type="checkbox"
                                checked={day.amenities.indexOf(item.value) > -1}
                                className="form-check-input"
                                id={`services-${item.value}`}
                              />

                              <label
                                className="form-check-label pr-5"
                                htmlFor={`services-${item.value}`}
                              >
                                {t(item.label)}
                              </label>
                            </div>
                          )
                        )}
                      </div>
                      <div className="col-12 col-lg-4 activities">
                        <h5 className="pt-2">{t("_ Access")}</h5>
                        {props.local.accomodationAccessMaster.map(
                          (item, index) => (
                            <div key={index} className="form-check pt-3">
                              <input
                                disabled={itinerary._status !== "draft"}
                                onChange={e =>
                                  controller.itinerary.toggleDayStayFeature(
                                    selectedDayIndex,
                                    "access",
                                    item.value
                                  )
                                }
                                type="checkbox"
                                checked={day.access.indexOf(item.value) > -1}
                                className="form-check-input"
                                id={`access-${item.value}`}
                              />

                              <label
                                className="form-check-label pr-5"
                                htmlFor={`access-${item.value}`}
                              >
                                {t(item.label)}
                              </label>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function(props) {
  const { t } = useTranslation();
  const currentDayEditorMode = props.currentTab || ACTIVITIES;
  const itinerary = props.itinerary || null;
  const selectedDayIndex = props.selectedDayIndex;

  if (!itinerary) return null;
  const currentDay = itinerary.days[selectedDayIndex];
  if (!currentDay) return null;

  let currentView = null;
  switch (currentDayEditorMode) {
    case ACTIVITIES: {
      currentView = (
        <Activities
          day={currentDay}
          selectedDayIndex={selectedDayIndex}
          local={props.local}
          itinerary={itinerary}
        />
      );

      break;
    }
    case FOOD: {
      currentView = (
        <Food
          day={currentDay}
          selectedDayIndex={selectedDayIndex}
          local={props.local}
          itinerary={itinerary}
        />
      );

      break;
    }
    case STAY: {
      currentView = (
        <Stay
          day={currentDay}
          selectedDayIndex={selectedDayIndex}
          local={props.local}
          itinerary={itinerary}
        />
      );

      break;
    }
    default: {
      break;
    }
  }

  return (
    <React.Fragment>
      <div className="container-fluid day-editor px-0 px-md-5">
        <div className="row pt-4 mx-2 m-md-0">
          <div className="col-10 col-lg-2">
            <input
              value={currentDay.title}
              readOnly={itinerary._status !== "draft"}
              onChange={e =>
                controller.itinerary.updateDay(
                  selectedDayIndex,
                  "title",
                  e.target.value
                )
              }
              className="form-control pr-2 w-100"
              placeholder={t("Day title")}
            />
          </div>
          <div className="col-2 order-lg-3">
            <div>
              <button onClick={props.onClose} className="close">
                <i className="fas fa-times"></i>
              </button>
            </div>
          </div>
          <div className="col-12 col-lg day-two-wrapper text-center text-sm-left py-3 py-lg-0">
            <button onClick={props.onNextDay} className="go-to-next-day">
              {t("Go to next day")}

              <i className="fas fa-arrow-right pl-1 arrow"></i>
            </button>
          </div>
        </div>
        <div className="row d-head">
          <div className="col-12 text-center pb-1 pb-md-3">
            <div
              className="btn-group lift-50 pt-md-0"
              role="group"
              aria-label="Basic example"
            >
              <button
                type="button"
                onClick={() =>
                  props.onTabChange && props.onTabChange(ACTIVITIES)
                }
                className={`${
                  currentDayEditorMode === ACTIVITIES
                    ? "active-button"
                    : "inactive-button"
                } btn btn-secondary`}
              >
                <i className="fas fa-bicycle pr-0 pr-md-2"></i>
                <span className="d-none d-md-inline">{t("Activities")}</span>
              </button>
              <button
                type="button"
                onClick={() => props.onTabChange && props.onTabChange(FOOD)}
                className={`${
                  currentDayEditorMode === FOOD
                    ? "active-button"
                    : "inactive-button"
                } btn btn-secondary`}
              >
                <i className="fas fa-mug-hot pr-0 pr-md-2"></i>
                <span className="d-none d-md-inline">{t("Food")}</span>
              </button>
              <button
                onClick={() => props.onTabChange && props.onTabChange(STAY)}
                type="button"
                className={`${
                  currentDayEditorMode === STAY
                    ? "active-button"
                    : "inactive-button"
                } btn btn-secondary`}
              >
                <i className="fas fa-moon pr-0 pr-md-2"></i>
                <span className="d-none d-md-inline">{t("Stay")}</span>
              </button>
            </div>
          </div>
        </div>

        {currentView}
      </div>
    </React.Fragment>
  );
}
