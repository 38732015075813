import React from "react";
import { GetFormattedPrice } from "../../resources/Helper";
import controllers from "../../store/controllers";
export default function(props) {
  return (
    <>
      <div className="slider-val-display">
        {GetFormattedPrice(
          props.value[0],
          controllers.getStore().getState().local.selectedCurrency
        )}
      </div>
      <div className="slider-val-display">
        {GetFormattedPrice(
          props.value[1],
          controllers.getStore().getState().local.selectedCurrency
        )}

        {props.max === props.value[1] ? " +" : ""}
      </div>
    </>
  );
}
