import {
  TRANSPORT_UPDATE,
  TRANSPORT_ADD_HIGHLIGHT,
  TRANSPORT_DELETE_HIGHLIGHT,
  TRANSPORT_UPDATE_HIGHLIGHT,
  TRANSPORT_TOGGLE_FEATURE,
  TRANSPORT_UPDATE_LOC,
  TRANSPORT_SPLICE_BANNER,
  TRANSPORT_SET
} from "../types";

export class TransportController {
  store = null;

  constructor(_store) {
    this.store = _store;
  }

  setTransportEditorContext(value, status) {
    this.store.dispatch({
      type: TRANSPORT_SET,
      payload: {
        value,
        status
      }
    });
  }

  updateTransport(key, value) {
    this.store.dispatch({
      type: TRANSPORT_UPDATE,
      payload: {
        value,
        key
      }
    });
  }

  addHighlight() {
    this.store.dispatch({
      type: TRANSPORT_ADD_HIGHLIGHT
    });
  }

  updateHighlight(indexToUpdate, key, value) {
    this.store.dispatch({
      type: TRANSPORT_UPDATE_HIGHLIGHT,
      payload: {
        indexToUpdate,
        key,
        value
      }
    });
  }
  updateTransportLocation(locationData) {
    this.store.dispatch({
      type: TRANSPORT_UPDATE_LOC,
      payload: {
        value: locationData
      }
    });
  }

  replaceTransportBanner(indexToSplice, imageId) {
    this.store.dispatch({
      type: TRANSPORT_SPLICE_BANNER,
      payload: {
        index: indexToSplice,
        value: imageId
      }
    });
  }

  deleteHighlight(indexToDelete) {
    this.store.dispatch({
      type: TRANSPORT_DELETE_HIGHLIGHT,
      payload: {
        indexToDelete
      }
    });
  }
  toggleFeature(key, value) {
    this.store.dispatch({
      type: TRANSPORT_TOGGLE_FEATURE,
      payload: {
        value,
        key
      }
    });
  }
}
