import {
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  TOGGLE_SIDEBAR,
  SET_CURRENT_USER,
  INITIALIZE_APP,
  SET_GLOBAL_LOADER,
  SET_GLOBAL_SUCCESS,
  SET_GLOBAL_ERROR,
  CLEAR_GLOBAL_MSG,
  SET_EDITOR_MODIFIED,
  SET_EDITOR_SAVED,
  RESET_EDITOR,
  OPEN_USER_ACCOUNT_DECK,
  CLOSE_USER_ACCOUNT_DECK,
  TOGGLE_USER_ACCOUNT_DECK,
  UPDATE_PREF_CURRENCY,
  SET_EDITOR_LISTING,
  UPDATE_EDITOR_LISTING,
  SET_IS_CURRENCY_CHANGING,
  UPDATE_PREF_LANGUAGE,
  SET_IS_LANGUAGE_CHANGING
} from "./../types";

import {
  OrderCancellationReasonMaster,
  SearchCategoryMaster,
  SearchDayRangeMaster,
  ExtrasMaster,
  AdditionalInfoMaster,
  CapacityMaster,
  SpaceMaster,
  InternetAccessMaster,
  AccommodationAccessMaster,
  AccommodationAmenitiesMaster,
  ActivityHourMaster,
  TransportTypeMaster
} from "shared-tripbonder-lib";

import getConfig from "./../../config";

let initialState = {
  editorLastSavedOn: 0,
  editorLastModifiedOn: 0,
  editorListingSnapshot: null,

  isSideBarOpened: false,
  isUserAccountDeckOpen: false,

  isAppInitialized: false,
  isAuthenticated: true,
  currentUser: null,
  chatUserId: null,
  sessionId: null,
  anonymChatId: null,

  supportedCurrencies: [],
  selectedCurrencyId: null,
  selectedCurrency: null,
  isCurrencyChanging: false,

  languages: [],
  selectedLanguage: null,
  isLanguageChanging: false,

  globalLoaderMessage: null,
  globalSuccessMessage: null,
  globalErrorMessage: null,
  manuallyCloseGlobalMessage: false,
  assetBaseAddr: getConfig().ASSET_BASE_ADDRS,
  cancellingReasonMaster: OrderCancellationReasonMaster,
  extrasMaster: ExtrasMaster,
  addtinfosMaster: AdditionalInfoMaster,
  capacityMaster: CapacityMaster,
  spaceMaster: SpaceMaster,
  internetaccessMaster: InternetAccessMaster,
  accomodationAmenitiesMaster: AccommodationAmenitiesMaster,
  accomodationAccessMaster: AccommodationAccessMaster,
  hours: ActivityHourMaster,
  filterSearchCategoryMaster: SearchCategoryMaster,
  filterSearchNumberOfDays: SearchDayRangeMaster,
  transportTypes: TransportTypeMaster
};

if (window.__hydratePayload) {
  initialState = Object.assign(initialState, window.__hydratePayload, {
    isAppInitialized: true
  });
}

export default function(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_APP: {
      const { context } = action.payload;
      return Object.assign({}, state, {
        isAppInitialized: true,
        isAuthenticated: context.isAuthenticated || false,
        currentUser: context.currentUser || null,
        chatUserId: context.chatUserId || null,
        sessionId: context.sessionId,
        anonymChatId: context.sessionChatUserId,

        supportedCurrencies: context.supportedCurrencies,
        selectedCurrencyId: context.selectedCurrencyId,
        selectedCurrency: context.selectedCurrency || {
          key: "USD",
          title: "US Dollar",
          sign: "$",
          exchangeRateToUSD: 1,
          exchangeRateUpdatedOn: 0,
          purchasePowerStep: 10,
          minPurchasePower: 20,
          maxPurchasePower: 2000
        },

        languages: context.languages,
        selectedLanguage: context.selectedLanguage
      });
    }
    case SET_IS_CURRENCY_CHANGING: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        isCurrencyChanging: value
      });
    }
    case UPDATE_PREF_CURRENCY: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        selectedCurrencyId: value.key,
        selectedCurrency: value
      });
    }
    case SET_IS_LANGUAGE_CHANGING: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        isLanguageChanging: value,
        isCurrencyChanging: value
      });
    }
    case UPDATE_PREF_LANGUAGE: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        selectedLanguage: value
      });
    }
    case OPEN_SIDEBAR: {
      return Object.assign({}, state, {
        isSideBarOpened: true
      });
    }
    case CLOSE_SIDEBAR: {
      return Object.assign({}, state, {
        isSideBarOpened: false
      });
    }
    case OPEN_USER_ACCOUNT_DECK: {
      return Object.assign({}, state, {
        isUserAccountDeckOpen: true
      });
    }
    case CLOSE_USER_ACCOUNT_DECK: {
      return Object.assign({}, state, {
        isUserAccountDeckOpen: false
      });
    }
    case TOGGLE_USER_ACCOUNT_DECK: {
      return Object.assign({}, state, {
        isUserAccountDeckOpen: !state.isUserAccountDeckOpen
      });
    }
    case TOGGLE_SIDEBAR: {
      return Object.assign({}, state, {
        isSideBarOpened: !state.isSideBarOpened
      });
    }
    case SET_CURRENT_USER: {
      const { value } = action.payload;

      return Object.assign({}, state, {
        isAuthenticated: value ? true : false,
        currentUser: value,
        chatUserId: value ? value._id : state.anonymChatId
      });
    }
    case SET_GLOBAL_LOADER: {
      const { value, enableManualClosing } = action.payload;
      return Object.assign({}, state, {
        globalLoaderMessage: value,
        globalSuccessMessage: null,
        globalErrorMessage: null,
        manuallyCloseGlobalMessage: enableManualClosing
      });
    }
    case SET_GLOBAL_SUCCESS: {
      const { value, enableManualClosing } = action.payload;
      return Object.assign({}, state, {
        globalLoaderMessage: null,
        globalSuccessMessage: value,
        globalErrorMessage: null,
        manuallyCloseGlobalMessage: enableManualClosing
      });
    }
    case SET_GLOBAL_ERROR: {
      const { value, enableManualClosing } = action.payload;
      return Object.assign({}, state, {
        globalLoaderMessage: null,
        globalSuccessMessage: null,
        globalErrorMessage: value,
        manuallyCloseGlobalMessage: enableManualClosing
      });
    }
    case CLEAR_GLOBAL_MSG: {
      return Object.assign({}, state, {
        globalLoaderMessage: null,
        globalSuccessMessage: null,
        globalErrorMessage: null,
        manuallyCloseGlobalMessage: null
      });
    }
    case SET_EDITOR_MODIFIED: {
      return Object.assign({}, state, {
        editorLastModifiedOn: new Date().getTime()
      });
    }
    case SET_EDITOR_SAVED: {
      return Object.assign({}, state, {
        editorLastSavedOn: new Date().getTime()
      });
    }
    case RESET_EDITOR: {
      const currentTime = 0; // (new Date()).getTime();
      return Object.assign({}, state, {
        editorLastModifiedOn: currentTime,
        editorLastSavedOn: currentTime
      });
    }
    case SET_EDITOR_LISTING: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        editorListingSnapshot: value
      });
    }
    case UPDATE_EDITOR_LISTING: {
      const { key, value } = action.payload;
      return Object.assign({}, state, {
        editorListingSnapshot: Object.assign({}, state.editorListingSnapshot, {
          [key]: value
        })
      });
    }
    default: {
      return state;
    }
  }
}
