import { withTranslation } from "react-i18next";
import React from "react";
import { Zoom } from "react-reveal";
import { Helmet } from "react-helmet";
import _ from "underscore";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

const FAQS = [
  {
    category: "General",
    question: "Who is Tripbonder?",
    answer:
      "Tripbonder is created as a platform that aims to make your travel experience memorable, fun and worry-free. Tripbonder creates a extremely user-friendly platform for trip-goer and trip service providers to offer their service to traveller like you. In here, you will find the travel experience that you have ever wanted. If you don’t, do not worry, as you can connect DIRECTLY with one of many of the travel service providers, which we fondly identify them as our Tripbonder guides. Problems such as security, languages, and local knowledge are no longer issues for you. You can search, contact, customize, and book your dream trip in one single platform."
  },

  {
    category: "General",
    question: "Who are the Tripbonder Team?",
    answer:
      "Tripbonder is founded by veteran travel service providers, and experienced information technology practitioners. We are constantly using technology to solve the issues faced by traveller in the travel industry."
  },

  {
    category: "General",
    question: "Who are the users on Tripbonder?",
    answer:
      "Tripbonder is designed with two types of users in mind. Firstly, Traveller. Traveller is user whom will be browsing the trips and travel services available in Tripbonder.com, and able to contact the Tripbonder for customized trip, and book directly with the Tripbonder guides. User type number 2: Tripbonder Guide, or Tripbonder in short. Tripbonder are travel service provider, or frequent trip-goer, that is creating, curating, offering and taking full responsibility for the travel experiences."
  },

  {
    category: "General",
    question: "I Cannot update my service properly, What can i do?",
    answer:
      "If you are having difficulties with the listing of your services, contact us immediately at tbadmin@tripbonder.com. We will help you to resolve the issue as soon as possible."
  },

  {
    category: "Create Trip",
    question: "How do i use Tribonder.com?",
    answer:
      "Sign up for an account on Tripbonder.com. You can then search, filter, add to favorite and compare your dream trips. Once you have made up your mind, simply order the trip by paying a deposit amount, or pay in full. You will need a valid credit card to make payment. After the reservation is completed, you will get a confirmation email, with a valid travel vouchers."
  },

  {
    category: "Create Trip",
    question:
      "When Will i pay the full amount if i pay deposit in the first place?",
    answer:
      "If your trip departure date is more than 90 days away, you are only required to pay a deposit amount. Once the departure date reaches less than 90 days, your card will be automatically charged for the remaining balance and a valid trip voucher will be issued to you."
  },

  {
    category: "Create Trip",
    question: "Can i cancel my reservation and recieve refund?",
    answer:
      "Yes of course you may. The booked reservation can be canceled 24 hours prior to the trip day in order to receive full refund (less minimum processing fees). You cannot cancel the booking after or during the booked trip day, and if you do, you will not receive any refund. You may contact tbadmin@tripbonder.com for more information."
  },

  {
    category: "Create Trip",
    question:
      "If the trip experience does not appear to be as what the Tripbonder has advertised, What can i do?",
    answer:
      "On the first day of your trip, you will receive an email asking for your feedback on the experience matching, with a Yes or No button. If you feel that it did not match the expectation, simply click on No and the system will automatically create a dispute case for you and we will investigate. The Tripbonder, however, will not be informed of your feedback. Once the investigation is completed, and the fault is with the Tripbonder, you will receive your refunds. The entire process will take 5-10 working days."
  },

  {
    category: "Create Trip",
    question:
      "If the Tripbonder did not show up on the day of the day of the trip, What can i do?",
    answer:
      "If the booked tripbonder failed to make it on tour day, contact us immediately so that we can assist you to find another replacement locally. Also, we will verify and proceed with full refund if the replacement was not arranged. In the mean time, we impose penalties against the respective tour guide and lower his/her service quality and ranking on this platform. Rest assured, we take our services seriously, all our Tripbonders on this platform are verified and reliable."
  }
];

function FaqGroup(props) {
  const { t } = props;
  return (
    <React.Fragment>
      <hr />
      <div className="row">
        <div className="col-12 text-center">
          <h6 className="pb-3">{t(props.title)}</h6>
          <div className="row">
            {props.item.map((faq, index) => (
              <Zoom duration={300} key={index}>
                <div className="col-12 col-md-6">
                  <div
                    className="accordion indicator-plus-before round-indicator"
                    id={`faq-${props.categoryIndex}-${index}`}
                    aria-multiselectable="true"
                  >
                    <div className="card mb-3">
                      <div
                        className="card-header collapsed  text-left"
                        role="tab"
                        data-target={`#faq-taget-${props.categoryIndex}-${index}`}
                        data-toggle="collapse"
                        data-parent={`#faq-${props.categoryIndex}-${index}`}
                        aria-expanded="false"
                        aria-controls={`faq-taget-${props.categoryIndex}-${index}`}
                      >
                        <span style={{ fontSize: 18 }} className="card-title">
                          {t(faq.question)}
                        </span>
                      </div>
                      <div
                        className="collapse"
                        id={`faq-taget-${props.categoryIndex}-${index}`}
                        role="tabpanel"
                      >
                        <div className="card-body">{t(faq.answer)}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Zoom>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
const Faq = withTranslation()(
  class Faq extends React.Component {
    state = {
      searchTerm: ""
    };

    componentDidMount() {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    render() {
      const groupedFaq = _.groupBy(
        (this.state.searchTerm &&
          FAQS.filter(
            faq =>
              faq.question
                .toLowerCase()
                .indexOf(this.state.searchTerm.toLowerCase()) > -1
          )) ||
          FAQS,
        "category"
      );

      return (
        <div className="container-fluid faq">
          <Helmet>
            <title>
              {this.props.t(
                "Questions about Tripbonder? Find all your questions answered here by Tripbonder"
              )}
            </title>
          </Helmet>
          <div className="row who">
            <div className="col-12 px-md-5 pt-3 text-center text-md-left">
              <h5 style={{ fontSize: 16, fontWeight: "bold" }}>
                {this.props.t("Frequently Asked Questions")}
              </h5>
              <hr />
            </div>
            <div className="col-12">
              <div className="container pb-5">
                <div className="row">
                  <div className="col-12 text-center">
                    <div
                      className="man"
                      style={{
                        backgroundImage: `url('${require("../../assets/images/man.png")}')`
                      }}
                    ></div>
                    <h2 className="pt-3">
                      <b>
                        {this.props.t("You got questions?")}{" "}
                        <span className="d-block d-md-inline">
                          {this.props.t("We got answers!")}
                        </span>
                      </b>
                    </h2>
                  </div>
                  <div className="col-12 search-box-wrapper py-2">
                    <div
                      className="search-bar-wrapper mx-auto"
                      style={{ maxWidth: 400 }}
                    >
                      <i
                        className="fas fa-search"
                        style={{ position: "absolute", right: 13, top: 10 }}
                      ></i>
                      <input
                        value={this.state.searchTerm}
                        onChange={e =>
                          this.setState({ searchTerm: e.target.value })
                        }
                        type="text"
                        className="form-control pr-5"
                        placeholder={this.props.t("Search for questions")}
                      />
                    </div>
                  </div>
                </div>
                {(Object.keys(groupedFaq).length > 0 &&
                  Object.keys(groupedFaq).map((category, index) => (
                    <FaqGroup
                      key={index}
                      title={category}
                      categoryIndex={index}
                      item={groupedFaq[category]}
                      t={this.props.t}
                    />
                  ))) || (
                  <div className="pt-5">
                    <div className="jumbotron">
                      <h1 className="display-4">
                        {this.props.t("Don't worry, We are here to help.")}
                      </h1>
                      <p className="lead">
                        {this.props.t(
                          "Please reach out to us if you have any other queries"
                        )}
                      </p>
                      <p className="lead">
                        <Link
                          to="/about/contact"
                          className="btn btn-primary btn-lg"
                          href="#"
                          role="button"
                        >
                          {this.props.t("Contact us")}
                        </Link>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);

export default Faq;
