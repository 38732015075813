import {
  LAUNCH_GALLERY,
  CLOSE_GALLERY,
  SET_RECENT_FILES,
  SET_GALLERY_STATUS,
  PUSH_RECENT_FILES,
  SELECT_MEDIA,
  MARK_FOR_DELETION,
  UNMARK_FOR_DELETION
} from "./gallery.types";
import Axios from "axios";

export class GalleryController {
  static instance;
  static getInstance() {
    if (!GalleryController.instance) {
      GalleryController.instance = new GalleryController();
    }
    return GalleryController.instance;
  }

  store = null;
  currentCallback = null;

  connect(_store) {
    this.store = _store;
  }

  getStore() {
    return this.store;
  }

  launchGallery(title, desc, options) {
    this.store.dispatch({
      type: LAUNCH_GALLERY,
      payload: {
        title,
        desc,
        options
      }
    });

    this.refreshRecentFiles();
  }

  closeGallery() {
    delete this.currentCallback;
    this.store.dispatch({
      type: CLOSE_GALLERY
    });
  }

  addToRecentFiles(media) {
    this.store.dispatch({
      type: PUSH_RECENT_FILES,
      payload: {
        value: media
      }
    });
  }

  refreshRecentFiles() {
    const self = this;
    const galleryState = this.store.getState()._gallery_;
    let isWaiting = false;
    if (galleryState.recentFiles && galleryState.recentFiles.length < 1) {
      isWaiting = true;
      this.store.dispatch({
        type: SET_GALLERY_STATUS,
        payload: { value: true }
      });
    }

    function setResponse(data) {
      let files = (data && data) || [];
      self.store.dispatch({
        type: SET_RECENT_FILES,
        payload: {
          value: files
        }
      });

      if (isWaiting === true) {
        isWaiting = false;
        self.store.dispatch({
          type: SET_GALLERY_STATUS,
          payload: { value: false }
        });
      }
    }

    Axios.get("/api/media/recent")
      .then(response => {
        setTimeout(() => {
          setResponse(response && response.data);
        }, 900);
      })
      .catch(err => {
        console.error(err);
        setResponse(null);
      });
  }

  insertMedia(title, desc, options, cb) {
    this.launchGallery(title, desc, options);
    this.currentCallback = cb;
  }

  sendImageResult(mediaObj) {
    this.currentCallback && this.currentCallback(mediaObj);
  }

  selectRecentMedia(id) {
    this.store.dispatch({
      type: SELECT_MEDIA,
      payload: {
        value: id
      }
    });
  }

  markForDeletion(id) {
    this.store.dispatch({
      type: MARK_FOR_DELETION,
      payload: {
        value: id
      }
    });

    Axios.delete(`/api/media/${id}`)
      .then(response => {
        this.store.dispatch({
          type: UNMARK_FOR_DELETION,
          payload: {
            value: id
          }
        });
      })
      .catch(err => {
        console.error(err);
        this.store.dispatch({
          type: UNMARK_FOR_DELETION,
          payload: {
            value: id
          }
        });
      });
  }
}

export default GalleryController.getInstance();
