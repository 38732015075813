import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import controllers from "../../store/controllers";
export default function GridImagePlaceholder(props) {
  const { t } = useTranslation();
  const { itinerary, index } = props;

  const blankImg = require("../../assets/images/group.png");
  const picGallery = {
    pic1: blankImg,
    pic2: blankImg,
    pic3: blankImg,
    pic4: blankImg
  };

  const [gallery, setGallery] = useState(picGallery);

  useEffect(() => {
    const FetchImages = () => {
      return new Promise((resolve, rejected) => {
        const day = itinerary.days[index];
        let images = [];

        function getTheBest() {
          function searchFirstImage(key) {
            if (day[key].length > 0) {
              if (day[key][0].imageId) {
                images.push(controllers.resolveAssetAddr(day[key][0].imageId));
                return true;
              } else {
                return false;
              }
            }
          }

          if (!searchFirstImage("morningActivities")) {
            if (!searchFirstImage("afternoonActivities")) {
              if (!searchFirstImage("eveningActivities")) {
                // Do nothing
              }
            }
          }

          if (!searchFirstImage("lunchActivities")) {
            if (!searchFirstImage("dinnerActivities")) {
              if (!searchFirstImage("breakfastActivities")) {
                // Do nothing
              }
            }
          }

          if (!searchFirstImage("eveningActivities")) {
            if (!searchFirstImage("afternoonActivities")) {
              if (!searchFirstImage("morningActivities")) {
                // Do nothing
              }
            }
          }

          searchFirstImage("stayImages");
        }

        function getTheMost() {
          function searchCollection(key) {
            for (var i = 0; i < day[key].length; i++) {
              if (day[key][i].imageId) {
                images.push(controllers.resolveAssetAddr(day[key][i].imageId));
              }
            }
          }

          searchCollection("morningActivities");
          searchCollection("afternoonActivities");
          searchCollection("eveningActivities");

          searchCollection("breakfastActivities");
          searchCollection("lunchActivities");
          searchCollection("dinnerActivities");
        }

        getTheBest();
        if (images.length < 4) {
          images = [];
          getTheMost();
        }

        setGallery({
          pic1: images[0] || blankImg,
          pic2: images[1] || blankImg,
          pic3: images[2] || blankImg,
          pic4: images[3] || blankImg
        });

        resolve(true);
      });
    };

    FetchImages();
  }, [index, blankImg, itinerary.days]);

  return (
    <React.Fragment>
      <div className="preview-thumbnail text-center">
        <div className="thumbnail-template">
          <div className="thumbrow">
            <div
              className="thumbcol"
              style={{
                backgroundImage: `url('${gallery.pic1}')`,
                borderRadius: 6
              }}
            ></div>
            <div
              className="thumbcol"
              style={{
                backgroundImage: `url('${gallery.pic3}')`,
                borderRadius: 6
              }}
            ></div>
          </div>
          <div className="thumbrow">
            <div
              className="thumbcol"
              style={{
                backgroundImage: `url('${gallery.pic2}')`,
                borderRadius: 6
              }}
            ></div>
            <div
              className="thumbcol"
              style={{
                backgroundImage: `url('${gallery.pic4}')`,
                borderRadius: 6
              }}
            ></div>
          </div>
          <div className="overlay">
            <div className="middle">
              <button
                onClick={() => props.onClick && props.onClick(props.index)}
                type="button"
                className="btn btn-warning-gradient px-5"
              >
                {t("View")}
              </button>
            </div>
            {itinerary._status === "draft" ? (
              <button
                data-toggle="modal"
                data-target="#deletePrompt"
                className="remove"
                onClick={e => props.onDelete && props.onDelete(props.index)}
              >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            ) : null}
          </div>
        </div>
        <label>{props.message}</label>
      </div>
    </React.Fragment>
  );
}
