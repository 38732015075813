import React, { Component } from "react";
import { connect } from "react-redux";
import { Zoom, Fade } from "react-reveal";
import controllers from "../../store/controllers";
import { withTranslation } from "react-i18next";

export default connect(state => ({ local: state.local }))(
  withTranslation()(
    class GlobalMessage extends Component {
      constructor(props) {
        super(props);
        this.clearMessage = this.clearMessage.bind(this);
      }

      clearMessage = e => {
        e = e || null;
        if (e && e.keyCode && e.keyCode !== 27) return;

        const { local } = this.props;
        if (
          local.manuallyCloseGlobalMessage &&
          local.manuallyCloseGlobalMessage === true
        ) {
          controllers.clearGlobalMessage();
        }
      };

      componentDidMount() {
        window.addEventListener("keydown", this.clearMessage);
      }

      componentWillUnmount() {
        window.removeEventListener("keydown", this.clearMessage);
      }

      render() {
        const { local } = this.props;
        let attachment;
        let message;
        if (local && local.globalLoaderMessage) {
          attachment = <i className="fas fa-spin fa-circle-notch"></i>;
          message = local.globalLoaderMessage;
        } else if (local && local.globalSuccessMessage) {
          attachment = (
            <small className="check">
              <svg
                className="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className="checkmark__circle"
                  cx="26"
                  cy="26"
                  r="25"
                  fill="none"
                />

                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </small>
          );

          message = local.globalSuccessMessage;
        } else if (local && local.globalErrorMessage) {
          attachment = <i className="fas fa-exclamation-circle error"></i>;
          message = local.globalErrorMessage;
        } else {
          attachment = null;
          message = null;
        }

        if (message) {
          if (message !== "") {
            console.log(message);
          }
        }

        return (
          (local &&
            (local.globalLoaderMessage ||
            local.globalSuccessMessage ||
            local.globalErrorMessage ? (
              <Fade duration={300}>
                <div onClick={this.clearMessage} className="global-loader">
                  {/* {local.manuallyCloseGlobalMessage &&
                   local.manuallyCloseGlobalMessage === true ? (
                    <button onClick={this.clearMessage} className="close-btn">
                      <i className="fas fa-times"></i>
                    </button>
                   ) : null} */}
                  <Zoom duration={500}>
                    <div className="l-wrapper">
                      {attachment}
                      <span>{this.props.t(message)}</span>
                    </div>
                  </Zoom>
                </div>
              </Fade>
            ) : null)) ||
          null
        );
      }
    }
  )
);
