export const SearchCategoryMaster = [
  {
    label: "Nature",
    value: "nature"
  },
  {
    label: "Tourist Attraction",
    value: "tourist-attraction"
  },
  {
    label: "Fun",
    value: "fun"
  },
  {
    label: "Adventure",
    value: "adventure"
  },
  {
    label: "Historical Places",
    value: "historical-places"
  },
  {
    label: "Shopping",
    value: "shopping"
  }
];

export const SearchDayRangeMaster = [
  {
    label: "Single Day",
    value: "single-day"
  },
  {
    label: "2 to 7 Days",
    value: "2-7-days"
  },
  {
    label: "1 to 2 Weeks",
    value: "1-2-weeks"
  },
  {
    label: "More than 2 Weeks",
    value: "2-weeks-and-more"
  }
];
