import { useTranslation } from "react-i18next";
import React from "react";

export default function ImagePlaceholder(props) {
  const { t } = useTranslation();
  const isDisabled = props.disabled || false;
  return (
    <React.Fragment>
      <div
        onClick={() => {
          if (isDisabled === false) {
            props.onClick && props.onClick();
          }
        }}
        className={`image-placeholder ${isDisabled ? "disabled" : ""}`}
        style={{
          border: "2px solid rgba(128, 128, 128, 0.299)",
          height: props.height || "100%",
          width: "100%",
          maxWidth: props.width || "100%",
          backgroundImage: `${(props.value && `url('${props.value}')`) ||
            "unset"}`
        }}
      >
        {props.value ? (
          <div className="close-btn dropdown">
            <button
              onClick={e => e.stopPropagation()}
              className="dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i className="fas fa-times"></i>
            </button>
            <div
              key="dropdown"
              className="dropdown-menu dropdown-menu-right"
              aria-labelledby="dropdownMenuButton"
            >
              <button
                onClick={e => {
                  e.stopPropagation();
                  props.onRemove && props.onRemove();
                }}
                className="dropdown-item"
              >
                {t("Remove Image")}
              </button>
            </div>
          </div>
        ) : props.large ? (
          <React.Fragment>
            <div className="d-flex flex-fill justify-content-center align-items-center flex-column w-100">
              <i className="fas fa-camera-retro fa-3x"></i>
              <div className="truncate w-100 px-3 text-center">
                {props.message}
              </div>
            </div>
            <div className="uploadbtn">
              <span>
                <i className="far fa-plus-square fa-lg mr-2"></i>
              </span>{" "}
              {t("Add Image")}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="d-flex flex-fill justify-content-center align-items-center flex-column w-100">
              <i className="fas fa-camera-retro fa-lg"></i>
              <div
                className="truncate w-100 px-3 text-center"
                style={{ fontSize: 12 }}
              >
                {props.message}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
}
