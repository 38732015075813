import {
  MARKETING_SET_CURRENT_PACKAGE,
  MARKETING_SET_SELECTED_ROOM,
  MARKETING_SET_HOME_PAGE_CONTEXT,
  MARKETING_SET_HOME_LOADING,
  MARKETING_SET_WISHLIST_OPTION,
  MARKETING_SET_READ_MORE_CONTEXT,
  MARKETING_SET_LIGHTBOX_IMAGE,
  MARKETING_PUSH_REVIEW,
  MARKETING_PUSH_REVIEW_VOTES,
  MARKETING_SPLICE_REVIEW,
  MARKETING_POP_REVIEW,
  MARKETING_SET_HOME_FEATURE_LOADING,
  MARKETING_UPDATE_KEY
} from "../types";
import { HomeServices } from "../../services/Home.services";
import { fetchTags } from "../../resources/Helper";

export class MarketingController {
  store = null;

  constructor(_store) {
    this.store = _store;
  }

  setCurrentPackage(_package) {
    this.store.dispatch({
      type: MARKETING_SET_CURRENT_PACKAGE,
      payload: {
        listingPackage: _package
      }
    });
  }

  setReadMoreContext(readMore_Body, readMore_Subject, readMore_Image) {
    this.store.dispatch({
      type: MARKETING_SET_READ_MORE_CONTEXT,
      payload: {
        readMore_Body,
        readMore_Subject,
        readMore_Image
      }
    });
  }
  setLightBoxImage(lightBoxImageId) {
    this.store.dispatch({
      type: MARKETING_SET_LIGHTBOX_IMAGE,
      payload: {
        lightBoxImageId
      }
    });
  }
  removeLightBoxImage() {
    this.store.dispatch({
      type: MARKETING_SET_LIGHTBOX_IMAGE,
      payload: {
        lightBoxImageId: null
      }
    });
  }
  deleteReadMoreContext() {
    this.store.dispatch({
      type: MARKETING_SET_READ_MORE_CONTEXT,
      payload: {
        readMore_Body: null,
        readMore_Subject: null,
        readMore_Image: null
      }
    });
  }
  updateHashTags(key, value) {
    this.store.dispatch({
      type: MARKETING_UPDATE_KEY,
      payload: {
        key,
        value
      }
    });
  }

  updatePackageWishlistOption(value) {
    this.store.dispatch({
      type: MARKETING_SET_WISHLIST_OPTION,
      payload: {
        value
      }
    });
  }

  setHomePageContext(force = false, silent = false) {
    const state = this.store.getState();
    if (state.marketing.hasInitialized === false || force === true) {
      if (force === true) {
        if (silent === true) {
          this.store.dispatch({
            type: MARKETING_SET_HOME_FEATURE_LOADING,
            payload: {
              value: true
            }
          });
        } else {
          this.store.dispatch({
            type: MARKETING_SET_HOME_LOADING,
            payload: {
              value: true
            }
          });
        }
      }
      setTimeout(() => {
        HomeServices.GetContext().then(context => {
          console.log(context);
          this.store.dispatch({
            type: MARKETING_SET_HOME_PAGE_CONTEXT,
            payload: {
              context
            }
          });

          const tags = fetchTags(context.populatActivities.trips);
          this.store.dispatch({
            type: MARKETING_UPDATE_KEY,
            payload: {
              key: "hashTags",
              value: tags
            }
          });
        });
      }, 0);
    }
  }

  setSelectedRoomIndex(index) {
    this.store.dispatch({
      type: MARKETING_SET_SELECTED_ROOM,
      payload: {
        index
      }
    });
  }

  pushReview(payload, key = "reviews") {
    this.store.dispatch({
      type: MARKETING_PUSH_REVIEW,
      payload: {
        value: payload,
        key
      }
    });
  }

  popReview(id, key = "reviews") {
    this.store.dispatch({
      type: MARKETING_POP_REVIEW,
      payload: {
        id,
        key
      }
    });
  }

  spliceReview(id, payload, key = "reviews") {
    this.store.dispatch({
      type: MARKETING_SPLICE_REVIEW,
      payload: {
        id,
        value: payload,
        key
      }
    });
  }

  pushReviewVotes(id, payload, key = "reviews") {
    this.store.dispatch({
      type: MARKETING_PUSH_REVIEW_VOTES,
      payload: {
        id,
        value: payload,
        key
      }
    });
  }
}
