import { withTranslation } from "react-i18next";
import React from "react";
import { Fade } from "react-reveal";
const ComposeDashboard = withTranslation()(
  class ComposeDashboard extends React.Component {
    render() {
      return (
        <Fade duration={300}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="jumbotron">
                  <h1 className="display-4">{this.props.t("Hello, world!")}</h1>
                  <p className="lead">
                    {this.props.t(
                      "This is a simple hero unit, a simple jumbotron-style component for calling extra attention to featured content or information."
                    )}
                  </p>
                  <hr className="my-4" />
                  <p>
                    {this.props.t(
                      "It uses utility classes for typography and spacing to space content out within the larger container."
                    )}
                  </p>
                  <p className="lead">
                    <a
                      className="btn btn-primary btn-lg"
                      href="/"
                      role="button"
                    >
                      {this.props.t("Learn more")}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      );
    }
  }
);

export default ComposeDashboard;
