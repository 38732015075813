import { withTranslation } from "react-i18next";
import React from "react";
import jQuery from "jquery";
import { connect } from "react-redux";
import { Fade } from "react-reveal";
import { Route, Switch } from "react-router-dom";
import Controller from "./../store/controllers";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import DefaultHeader from "../components/Navbars/DefaultHeader";
import DefaultSidebar from "../components/Navbars/DefaultSidebar";
import DefaultFooter from "../components/Navbars/DefaultFooter";

import HomePage from "./../views/Marketing/HomePage";
import FilterPage from "./../views/Marketing/FilterPage";
import OrdersPage from "./../views/Marketing/OrdersPage";
import TripDetailsPage from "./../views/Marketing/TripDetailsPage";
import StarterPage from "./../views/Starter.tmpl";
// import AboutUs from "../views/Marketing/AboutUs";
import { Modal } from "reactstrap";
import FAQ from "../views/Marketing/FAQ";
import ContactUs from "../views/Marketing/ContactUs";
import PrivacyPolicy from "../views/Marketing/PrivacyPolicy";
import TermsConditions from "../views/Marketing/TermsConditions";
import WhoIsTripbonder from "../views/Marketing/WhoIsTripbonder";
import BecomeATripbonder from "../views/Marketing/BecomeATripbonder";
import Agreement from "../views/Marketing/Agreement";
import LostAndCatch from "../views/LostAndCatch";
import PasswordReset from "../views/Marketing/PasswordReset";
import Profile from "../views/Shared/Profile";
import ChatRoom from "../components/Chat/ChatRoom";
import Whishlist from "./../views/Marketing/Wishlist";
import ComparePackages from "./../views/Marketing/ComparePackages";

const scrollToTopExceptionList = ["/package/view"];
const MarketingLayout = withTranslation()(
  class MarketingLayout extends React.Component {
    componentDidUpdate(prevProps) {
      if (this.props.location.pathname !== prevProps.location.pathname) {
        const searchResult = scrollToTopExceptionList.find(e =>
          this.props.location.pathname.startsWith(e)
        );

        if (!searchResult) {
          window.scrollTo(0, 0);
        }
        Controller.closeUserAccountDeck();
      }

      if (
        this.props.context.lightBoxImageId !== prevProps.context.lightBoxImageId
      ) {
        if (this.props.context.lightBoxImageId) {
          jQuery("html").addClass("overflow-hidden");
        } else {
          jQuery("html").removeClass("overflow-hidden");
        }
      }
    }

    render() {
      const { local, history } = this.props;
      return (
        <React.Fragment>
          <div className="content">
            <DefaultHeader
              onSidebarToggle={() => Controller.toggleSidebar()}
              isAuthenticated={local.isAuthenticated}
              currentUser={local.currentUser}
              isUserAccountDeckOpen={local.isUserAccountDeckOpen}
              history={history}
              currencies={local.supportedCurrencies}
              selectedCurrencyId={local.selectedCurrencyId}
              isCurrencyChanging={local.isCurrencyChanging}
              languages={local.languages}
              selectedLanguage={local.selectedLanguage}
            />

            <DefaultSidebar
              isOpen={local.isSideBarOpened}
              isAuthenticated={local.isAuthenticated}
              currentUser={local.currentUser}
            />

            <main className="marketing">
              <div className="content">
                <Switch>
                  <Route
                    exact
                    path="/package/view/:packageId"
                    component={TripDetailsPage}
                  />

                  <Route
                    exact
                    path="/custom/packages/view/:packageId"
                    component={TripDetailsPage}
                  />

                  <Route path="/me/orders/:status?" component={OrdersPage} />
                  <Route
                    exact
                    path="/me/wishlist/compare/:package1/:package2"
                    component={ComparePackages}
                  />

                  <Route path="/me/wishlist/:status?" component={Whishlist} />
                  <Route
                    path="/account/password/reset"
                    component={PasswordReset}
                  />

                  <Route path="/me/profile" component={Profile} />
                  <Route path="/support/help/faq" component={FAQ} />
                  <Route path="/about/contact" component={ContactUs} />

                  <Route path="/about/tripbonder" component={WhoIsTripbonder} />
                  <Route
                    path="/about/legal/terms"
                    component={TermsConditions}
                  />

                  <Route
                    path="/about/legal/privacy"
                    component={PrivacyPolicy}
                  />

                  <Route path="/about/contact" component={StarterPage} />
                  <Route path="/about/agreement" component={Agreement} />

                  <Route
                    path="/tripbonder/get-started"
                    component={BecomeATripbonder}
                  />

                  <Route
                    path="/filter"
                    exact
                    component={innerProps => (
                      <FilterPage {...innerProps} local={this.props.local} />
                    )}
                  />

                  <Route path="/" exact component={HomePage} />
                  <Route exact component={LostAndCatch} />
                </Switch>
                <DefaultFooter />
              </div>
            </main>
          </div>
          <div className="mini-chat-container d-flex flex-row text-right">
            {this.props.chat.conversations
              .filter(t => t.isMiniWindowOpened === true)
              .map(thread => (
                <Fade key={thread.key} duration={350} bottom>
                  <ChatRoom
                    context={thread}
                    requireEmail={true}
                    isResponsive={true}
                  />
                </Fade>
              ))}
          </div>
          <Modal
            isOpen={
              this.props.context.readMoreBody ||
              this.props.context.readMoreSubject ||
              this.props.context.readMoreBannerImageId
            }
            centered={true}
            modalClassName="see-more-modal-wrapper"
            toggle={() => {
              Controller.marketing.deleteReadMoreContext();
            }}
          >
            <div className="see-more-modal-content py-3">
              <div className="see-more-modal-header">
                <div className="form-row">
                  <div className="col-12 text-center title-wrapper">
                    <h4
                      className="truncate mx-auto pt-3"
                      style={{ width: "350px" }}
                    >
                      {this.props.context.readMoreSubject}
                    </h4>
                    <button
                      className="mr-3"
                      onClick={() => {
                        Controller.marketing.deleteReadMoreContext();
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                    <div
                      className="item-background-img mt-3"
                      style={{
                        backgroundImage: `url('${Controller.resolveAssetAddr(
                          this.props.context.readMoreBannerImageId
                        )}')`
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="see-more-modal-body">
                <div className="form-row px-4 pt-3">
                  <div
                    className="col-12 px-4 pt-3"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    <p>{this.props.context.readMoreBody}</p>
                    <div className="text-center pt-3">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                          Controller.marketing.deleteReadMoreContext();
                        }}
                      >
                        {this.props.t("Close")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          {this.props.context.lightBoxImageId ? (
            <Lightbox
              mainSrc={Controller.resolveAssetAddr(
                this.props.context.lightBoxImageId
              )}
              onCloseRequest={() => Controller.marketing.removeLightBoxImage()}
            />
          ) : null}
        </React.Fragment>
      );
    }
  }
);

const mapStateToProps = state => ({
  local: state.local,
  chat: state.chat,
  context: state.marketing
});

export default connect(mapStateToProps)(MarketingLayout);
