import { withTranslation } from "react-i18next";
import React from "react";
import { Fade } from "react-reveal";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Trip from "../../components/listing/Trip";
import SearchBar from "../../components/SearchBar/SearchBarInput";
import { ContactDetails } from "./../../services/Enquiries.services";
import Controller from "../../store/controllers/index";
import queryString from "query-string";
import { withStyles } from "@material-ui/core/styles";
import GetYourGuide from "../../components/Widgets/GetYourGuide";
import Slider from "@material-ui/core/Slider";
import ReactGA from "react-ga";
import SliderValueDisplay from "../../components/listing/SliderValueDisplay";
const AirbnbSlider = withStyles({
  root: {
    color: "#3a8589",
    height: 3,
    padding: "13px 0"
  },

  thumb: {
    height: 27,
    width: 27,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    marginTop: -12,
    marginLeft: -13,
    boxShadow: "#ebebeb 0px 2px 2px",
    "&:focus,&:hover,&$active": {
      boxShadow: "#ccc 0px 2px 3px 1px"
    },

    "& .bar": {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: "currentColor",
      marginLeft: 1,
      marginRight: 1
    },

    "& .label-display": {
      top: -23,
      fontSize: 12,
      position: "absolute",
      textAlign: "center",
      whiteSpace: "nowrap"
    }
  },

  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },

  track: {
    height: 3
  },

  rail: {
    color: "#d8d8d8",
    opacity: 1,
    height: 3
  }
})(Slider);
function AirbnbThumbComponent(props) {
  // const valueIndex = parseInt(props["data-index"]);
  // const valueMax = parseInt(props["aria-valuemax"]);
  // const valueNow = parseInt(props["aria-valuenow"]);
  // const maxPlusLabel = valueIndex === 1 && valueNow === valueMax ? "+" : null;
  return (
    <span {...props}>
      {/* <span className="label-display">
             {GetFormattedPrice(
               props["aria-valuenow"],
               Controller.getStore().getState().local.selectedCurrency
             )}
             {maxPlusLabel ? " + " : null}
            </span> */}
      <span className="bar" />
      <span className="bar" />
      <span className="bar" />
    </span>
  );
}
const HomePage = withTranslation()(
  class HomePage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        modal: false,
        email: "",
        location: "",
        latitude: "",
        longitude: "",
        selectedActivityTab: "itinerary",
        hideFilterOptions: false
      };

      this.toggle = this.toggle.bind(this);
      this.emailChange = this.emailChange.bind(this);
      this.locationChange = this.locationChange.bind(this);
      this.postEnquiry = this.postEnquiry.bind(this);
      this.handleCurrencyChange = this.handleCurrencyChange.bind(this);

      this.searchBox1 = React.createRef();
      this.searchBox2 = React.createRef();
    }

    handleCurrencyChange() {
      Controller.marketing.setHomePageContext(true, true);
    }
    toggleHideFilter() {
      this.setState({
        hideFilterOptions: !this.state.hideFilterOptions
      });
    }
    componentDidMount() {
      Controller.marketing.setHomePageContext();
      Controller.subscribeToCurrencyChange(
        this.handleCurrencyChange,
        "home-page"
      );

      Controller.subscribeToLanguageChange(
        this.handleCurrencyChange,
        "home-page"
      );

      ReactGA.pageview(window.location.pathname + window.location.search);
    }

    toggle() {
      this.setState({
        modal: !this.state.modal
      });
    }

    emailChange(e) {
      this.setState({
        email: e.target.value
      });
    }

    locationChange(e) {
      this.setState({
        location: e.target.value
      });
    }

    postEnquiry(e) {
      e.preventDefault();
      const payload = {
        emailAddress: this.state.email,
        location: this.state.location
      };

      if (this.state.email === "") {
        Controller.setGlobalError("Please enter your email", true);
        return;
      }
      if (this.state.location === "") {
        Controller.setGlobalError("Please enter your location", true);
        return;
      }

      Controller.setGlobalLoader("Sending...", false);
      ContactDetails.PostEnquiry(payload)
        .then(response => {
          this.setState(
            {
              email: "",
              location: "",
              latitude: null,
              longitude: null
            },

            () => {
              Controller.setGlobalSuccess(
                "Thank you, we will contact you soon at your email",
                true
              );

              setTimeout(() => {
                Controller.clearGlobalMessage();
              }, 800);
            }
          );
        })
        .catch(err => {
          Controller.setGlobalError(err.message, true);
        });
    }

    HandleSearch = address => {
      this.props.history.push({
        pathname: "/filter",
        search: queryString.stringify(address)
      });
    };
    renderTopDestination() {
      let dataSource = this.props.context.topDestinations;
      return (
        <>
          {dataSource.map((data, index) => (
            <div key={index} className="col-lg-3 col-md-4 col-sm-6 pt-5">
              <div className="destinations">
                <Link
                  to={`/filter?address=${encodeURI(data.name)}${
                    data._cityRaw ? `&city=${data._cityRaw}` : ""
                  }${data._countryRaw ? `&country=${data._countryRaw}` : ""}`}
                >
                  <div className="backdrop"></div>
                  <img
                    width="100%"
                    height="100%"
                    src={Controller.resolveAssetAddr(data.image)}
                    alt=""
                  />

                  <div className="centered">
                    <span style={{ textTransform: "uppercase" }}>
                      {data.name}
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </>
      );
    }
    renderActivity() {
      let dataSource = this.props.context;
      const isSilentLoading = this.props.context.isSilentLoading;

      if (isSilentLoading === true) {
        return (
          <div className="text-center py-5">
            <i
              style={{ color: "#ccc" }}
              className="fas fa-circle-notch fa-spin mr-2"
            ></i>{" "}
            <span>{this.props.t("Fetching results...")}</span>
          </div>
        );
      }

      if (this.state.selectedActivityTab === "itinerary") {
        return (
          <>
            <div className="row">
              <div className="col-12 pt-3">
                <div className="text-center">
                  {dataSource.popularTrips.length > 1 ? (
                    <span className="trip-no">
                      {this.props.t("Showing")} {dataSource.popularTrips.length}{" "}
                      {this.props.t("Trips")}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row">
              {dataSource.popularTrips.map((data, index) => (
                <Trip key={index} hasShadow={true} data={data} />
              ))}
            </div>
            <div className="row">
              <div className="col-12 pt-5 pb-5 d-none d-sm-block">
                <div className="text-center">
                  <Link
                    onClick={() => Controller.filter.setPackage("itinerary")}
                    to="/filter"
                    className=" see-all btn btn-success-gradient px-5"
                  >
                    {this.props.t("See all")}
                  </Link>
                </div>
              </div>
              <div className="col-12 pt-5 pb-5 d-sm-none">
                <div>
                  <Link
                    onClick={() => Controller.filter.setPackage("itinerary")}
                    to="/filter"
                    className=" see-all btn btn-success-gradient btn-sm btn-block"
                  >
                    {this.props.t("See all")}
                  </Link>
                </div>
              </div>
            </div>
          </>
        );
      } else if (this.state.selectedActivityTab === "accomodation") {
        return (
          <>
            <div className="row">
              <div className="col-12 pt-3">
                <div className="text-center">
                  {dataSource.popularAccomodation.length > 1 ? (
                    <span className="trip-no">
                      {this.props.t("Showing")}
                      {dataSource.popularAccomodation.length}{" "}
                      {this.props.t("Accommodations")}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row">
              {dataSource.popularAccomodation.map((data, index) => (
                <Trip key={index} hasShadow={true} data={data} />
              ))}
            </div>
            <div className="row">
              <div className="col-12 pt-5 pb-5 d-none d-sm-block">
                <div className="text-center">
                  <Link
                    onClick={() => Controller.filter.setPackage("accomodation")}
                    to="/filter"
                    className=" see-all btn btn-success-gradient px-5"
                  >
                    {this.props.t("See all")}
                  </Link>
                </div>
              </div>
              <div className="col-12 pt-5 pb-5 d-sm-none">
                <div>
                  <Link
                    onClick={() => Controller.filter.setPackage("accomodation")}
                    to="/filter"
                    className=" see-all btn btn-success-gradient btn-sm btn-block"
                  >
                    {this.props.t("See all")}
                  </Link>
                </div>
              </div>
            </div>
          </>
        );
      } else if (this.state.selectedActivityTab === "transport") {
        return (
          <>
            <div className="row">
              <div className="col-12 pt-3">
                <div className="text-center">
                  {dataSource.popularTransport.length > 1 ? (
                    <span className="trip-no">
                      {this.props.t("Showing")}
                      {dataSource.popularTransport.length}
                      {this.props.t("Transports")}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row">
              {dataSource.popularTransport.map((data, index) => (
                <Trip key={index} hasShadow={true} data={data} />
              ))}
            </div>
            <div className="row">
              <div className="col-12 pt-5 pb-5 d-none d-sm-block">
                <div className="text-center">
                  <Link
                    onClick={() => Controller.filter.setPackage("transport")}
                    to="/filter"
                    className=" see-all btn btn-success-gradient px-5"
                  >
                    {this.props.t("See all")}
                  </Link>
                </div>
              </div>
              <div className="col-12 pt-5 pb-5 d-sm-none">
                <div>
                  <Link
                    onClick={() => Controller.filter.setPackage("transport")}
                    to="/filter"
                    className=" see-all btn btn-success-gradient btn-sm btn-block"
                  >
                    {this.props.t("See all")}
                  </Link>
                </div>
              </div>
            </div>
          </>
        );
      } else if (this.state.selectedActivityTab === "ticket") {
        return (
          <>
            <div className="row">
              <div className="col-12 pt-3">
                <div className="text-center">
                  {dataSource.popularTickets.length > 1 ? (
                    <span className="trip-no">
                      {this.props.t("Showing")}
                      {dataSource.popularTickets.length}
                      {this.props.t("Tickets")}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row">
              {dataSource.popularTickets.map((data, index) => (
                <Trip key={index} hasShadow={true} data={data} />
              ))}
            </div>
            <div className="row">
              <div className="col-12 pt-5 pb-5 d-none d-sm-block">
                <div className="text-center">
                  <Link
                    onClick={() => Controller.filter.setPackage("ticket")}
                    to="/filter"
                    className=" see-all btn btn-success-gradient px-5"
                  >
                    {this.props.t("See all")}
                  </Link>
                </div>
              </div>
              <div className="col-12 pt-5 pb-5 d-sm-none">
                <div>
                  <Link
                    onClick={() => Controller.filter.setPackage("ticket")}
                    to="/filter"
                    className=" see-all btn btn-success-gradient btn-sm btn-block"
                  >
                    {this.props.t("See all")}
                  </Link>
                </div>
              </div>
            </div>
          </>
        );
      } else if (this.state.selectedActivityTab === "partner") {
        return (
          <div className="row">
            <div className="col-12 pt-3 pb-2">
              <GetYourGuide />
            </div>
          </div>
        );
      }
    }

    renderLoader() {
      return (
        <Fade key="package-loader" duration={350}>
          <div className="container-fluid package-loader">
            <div className="row">
              <div className="col-12 px-0">
                <div className="ph-item mh-100">
                  <div className="ph-col-12">
                    <div className="ph-picture banner"></div>
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <div className="ph-effect box"></div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 d-none d-sm-block">
                          <div className="ph-effect box"></div>
                        </div>
                        <div className="col-md-4 col-lg-3 d-none d-md-block">
                          <div className="ph-effect box"></div>
                        </div>
                        <div className="col-lg-3 d-none d-lg-block">
                          <div className="ph-effect box"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      );
    }

    handleSearchClick(ref) {
      if (ref.current) {
        ref.current.doSearch();
      }
    }

    renderHomePage() {
      const { local, context } = this.props;
      return (
        <Fade duration={300}>
          <div className="home-page">
            <section className="container-fluid welcome px-0">
              <div
                id="carouselExampleControls"
                className="carousel slide"
                data-ride="carousel"
                data-interval="2500"
              >
                <div className="carousel-inner">
                  {/* <div className="carousel-item active">
                   <video
                     width="100%"
                     height="100%"
                     className="banner-item"
                     src={require("../../assets/videos/banner-video.mp4")}
                     autoPlay
                     muted
                     loop
                   ></video>
                   </div> */}
                  <div className="carousel-item active">
                    <div
                      className="banner-item"
                      // style={{
                      //   backgroundImage: `url('${require("./../../assets/images/marketing/coverPic-man cropped.jpg")}')`
                      // }}
                    >
                      <div className="row welcome-img-wrapper pb-5">
                        <div className="col p-0">
                          <div className="container-fluid bg">
                            <div className="row px-4">
                              <div className="col-12">
                                <div className=" text-center pt-3 ">
                                  <h1>
                                    {this.props.t(
                                      "Find Paradise Wherever You Go With TripBonder"
                                    )}
                                  </h1>
                                  <span className="desp">
                                    {this.props.t(
                                      "Choose from a wide-range of travel experiences, only on Tripbonder"
                                    )}
                                  </span>
                                </div>
                                <div className="col-12 my-2 text-center">
                                  {context.hashTags.map((tag, index) => {
                                    return (
                                      <button
                                        key={index}
                                        style={{ backgroundColor: "white" }}
                                      >
                                        <Link
                                          to="/filter"
                                          onClick={() =>
                                            Controller.filter.selectFilterTag(
                                              tag,
                                              true
                                            )
                                          }
                                          className="filter-tag-btn mx-2 mt-2"
                                        >
                                          {this.props.t("#")}

                                          {tag}
                                        </Link>
                                      </button>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                            <div className="row pt-2">
                              <div className="col-12 pt-2 pb-5">
                                <div className="gg-box">
                                  <Link
                                    // onClick={() =>
                                    //   Controller.filter.togglePackageCategory(
                                    //     "tourist-attraction",
                                    //     true
                                    //   )
                                    // }
                                    // to="/filter"
                                    // className="grid"
                                    onClick={() =>
                                      Controller.filter.togglePackageCategory(
                                        "historical-places",
                                        true
                                      )
                                    }
                                    to="/filter"
                                    className="grid history"
                                  >
                                    {/* <img
                                     src={require("../../assets/images/theme-grid/1.png")}
                                     alt="tourist attraction"
                                     /> */}
                                    <img
                                      src={require("../../assets/images/theme-grid/3.png")}
                                      alt="history"
                                    />

                                    <div className="overlay"></div>
                                    <div className="title">
                                      {/* <h5 className="pb-2">TOURIST ATTRACTION</h5>
                                       <small className="d-none d-sm-block pr-3">
                                       Checked off your bucket list with tours
                                       that offer sight-seeing to major tourist
                                       attractions.
                                       </small> */}
                                      <h5 className="pb-2">
                                        {this.props.t("HISTORICAL PLACES")}
                                      </h5>
                                      <small className="d-none d-sm-block pr-3">
                                        {this.props.t(
                                          "Be a cultural history buff and explores world\u2019s famous historical places!"
                                        )}
                                      </small>
                                    </div>
                                  </Link>
                                  <Link
                                    onClick={() =>
                                      Controller.filter.togglePackageCategory(
                                        "fun",
                                        true
                                      )
                                    }
                                    to="/filter"
                                    className="grid fun"
                                  >
                                    <img
                                      src={require("../../assets/images/theme-grid/5.png")}
                                      alt="fun"
                                    />

                                    <div className="overlay"></div>
                                    <div className="title">
                                      <h5 className="pb-2">
                                        {this.props.t("FUN")}
                                      </h5>
                                      <small className="d-none d-sm-block pr-3">
                                        {this.props.t(
                                          "Enjoy any activities that is fun and existing with this tour activities. Be it fun at the seas, fun at theme parks or fun at the cities. There is always a fun activities for you and your family to enjoy!"
                                        )}
                                      </small>
                                    </div>
                                  </Link>
                                  <Link
                                    // onClick={() =>
                                    //   Controller.filter.togglePackageCategory(
                                    //     "historical-places",
                                    //     true
                                    //   )
                                    // }
                                    // to="/filter"
                                    // className="grid"
                                    onClick={() =>
                                      Controller.filter.togglePackageCategory(
                                        "tourist-attraction",
                                        true
                                      )
                                    }
                                    to="/filter"
                                    className="grid tourist"
                                  >
                                    {/* <img
                                     src={require("../../assets/images/theme-grid/3.png")}
                                     alt="history"
                                     /> */}
                                    <img
                                      src={require("../../assets/images/theme-grid/1.png")}
                                      alt="tourist attraction"
                                    />

                                    <div className="overlay"></div>
                                    <div className="title">
                                      {/* <h5 className="pb-2">HISTORICAL PLACES</h5>
                                       <small className="d-none d-sm-block pr-3">
                                       Be a cultural history buff and explores
                                       world’s famous historical places!
                                       </small> */}
                                      <h5 className="pb-2">
                                        {this.props.t("TOURIST ATTRACTION")}
                                      </h5>
                                      <small className="d-none d-sm-block pr-3">
                                        {this.props.t(
                                          "Checked off your bucket list with tours that offer sight-seeing to major tourist attractions."
                                        )}
                                      </small>
                                    </div>
                                  </Link>
                                  <Link
                                    onClick={() =>
                                      Controller.filter.togglePackageCategory(
                                        "shopping",
                                        true
                                      )
                                    }
                                    to="/filter"
                                    className="grid shopping"
                                  >
                                    <img
                                      src={require("../../assets/images/theme-grid/6.png")}
                                      alt="shopping"
                                    />

                                    <div className="overlay"></div>
                                    <div className="title">
                                      <h5 className="pb-2">
                                        {this.props.t("SHOPPING")}
                                      </h5>
                                      <small className="d-none d-sm-block pr-3">
                                        {this.props.t(
                                          "Get a Shop-Till-You-Drop experience offered by our local tour guides. Avoid tourist traps by following our locals into back alley to get the best deals!"
                                        )}
                                      </small>
                                    </div>
                                  </Link>
                                  <Link
                                    onClick={() =>
                                      Controller.filter.togglePackageCategory(
                                        "adventure",
                                        true
                                      )
                                    }
                                    to="/filter"
                                    className="grid adventure"
                                  >
                                    <img
                                      src={require("../../assets/images/theme-grid/2.png")}
                                      alt="adventure"
                                    />

                                    <div className="overlay"></div>
                                    <div className="title">
                                      <h5 className="pb-2">
                                        {this.props.t("ADVENTURE")}
                                      </h5>
                                      <small className="d-none d-sm-block pr-3">
                                        {this.props.t(
                                          "Select from a wide varieties of outdoor activities that takes place in unusual, exotic, remote or wilderness destinations."
                                        )}
                                      </small>
                                    </div>
                                  </Link>
                                  <Link
                                    onClick={() =>
                                      Controller.filter.togglePackageCategory(
                                        "nature",
                                        true
                                      )
                                    }
                                    to="/filter"
                                    className="grid nature"
                                  >
                                    <img
                                      src={require("../../assets/images/theme-grid/4.png")}
                                      alt="nature"
                                    />

                                    <div className="overlay"></div>
                                    <div className="title">
                                      <h5 className="pb-2">
                                        {this.props.t("NATURE")}
                                      </h5>
                                      <small className="d-none d-sm-block pr-3">
                                        {this.props.t(
                                          "Be a responsible traveller and conserves the environment and improves the welfare of local people."
                                        )}
                                      </small>
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-4 px-4 d-none d-md-flex pb-4">
                              <div className="col-12">
                                <div className="container">
                                  <div className="row">
                                    <div className="col-9 text-right">
                                      <SearchBar
                                        enableBorder={true}
                                        ref={this.searchBox1}
                                        onSearch={this.HandleSearch.bind(this)}
                                      />
                                    </div>
                                    <div className="col-3">
                                      <button
                                        onClick={() =>
                                          this.handleSearchClick(
                                            this.searchBox1
                                          )
                                        }
                                        type="button"
                                        className="btn btn-warning-gradient btn-block"
                                      >
                                        {this.props.t("Go")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row search-boxf-wrapper sm-view d-md-none px-4">
                              <div className="col-12">
                                <SearchBar
                                  enableBorder={true}
                                  ref={this.searchBox2}
                                  onSearch={this.HandleSearch.bind(this)}
                                />
                              </div>
                              <div className="col-12 pt-3">
                                <button
                                  onClick={() =>
                                    this.handleSearchClick(this.searchBox2)
                                  }
                                  type="button"
                                  className=" btn btn-warning-gradient btn-block"
                                >
                                  {this.props.t("Go")}
                                </button>
                              </div>
                            </div>

                            {/* <div className="row px-4">
                             <div className="col-12">
                             <div className="text-left pt-3 ">
                             <h1>Find Paradise Wherever You Go With TripBonder</h1>
                             <span className="desp">
                             Let our in-location tour operators & guides design
                             your desired travel experiences
                             </span>
                             </div>
                             </div>
                             </div> */}
                            {/* <div className="row mt-4 px-4 d-none d-md-flex pb-4 mb-5">
                             <div className="col-10 text-right">
                             <SearchBar
                             ref={this.searchBox1}
                             onSearch={this.HandleSearch.bind(this)}
                             />
                             </div>
                             <div className="col-2">
                             <button
                             onClick={() => this.handleSearchClick(this.searchBox1)}
                             type="button"
                             className="btn btn-warning-gradient btn-block"
                             >
                             Go
                             </button>
                             </div>
                             </div> */}

                            {/* <div className="row search-boxf-wrapper sm-view pt-5 d-md-none">
                             <div className="col-12">
                             <SearchBar
                             ref={this.searchBox2}
                             onSearch={this.HandleSearch.bind(this)}
                             />
                             </div>
                             <div className="col-12 pt-3">
                             <button
                             onClick={() => this.handleSearchClick(this.searchBox2)}
                             type="button"
                             className=" btn btn-warning-gradient btn-block"
                             >
                             Go
                             </button>
                             </div>
                             </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="carousel-item">
                     <div
                       className="banner-item"
                       style={{
                         backgroundImage: `url('${require("./../../assets/images/marketing/banner-image.png")}')`
                       }}
                     >
                      </div>
                   </div> */}
                </div>
                {/* <span
                   className="carousel-control-prev"
                   data-target="#carouselExampleControls"
                   role="button"
                   data-slide="prev"
                 >
                   <span
                     className="carousel-control-prev-icon"
                     aria-hidden="true"
                   ></span>
                   <span className="sr-only">Previous</span>
                 </span>
                 <span
                   className="carousel-control-next"
                   data-target="#carouselExampleControls"
                   role="button"
                   data-slide="next"
                 >
                   <span
                     className="carousel-control-next-icon"
                     aria-hidden="true"
                   ></span>
                   <span className="sr-only">Next</span>
                 </span> */}
              </div>
            </section>
            <section className="custom mt-2 pb-2">
              <div className="container">
                <div className="row pt-4">
                  <div className="col-12 pb-2 pb-md-4  col-md-auto text-md-left text-center">
                    <img
                      width="45px"
                      height="45px"
                      src={require("./../../assets/images/green-tea.png")}
                      alt=""
                    />
                  </div>
                  <div className="col-12 col-md text-center text-md-left">
                    <div className="intro mt-2">
                      <h2 className="mb-0">
                        {this.props.t(
                          "Let us customize a wonderful experience for you!"
                        )}
                      </h2>
                      <span className="pt-2">
                        {this.props.t("Tailor-made trips coming your way!")}
                      </span>
                    </div>
                  </div>
                  <div className="col-12 pt-3 col-md-5 pt-md-0 d-none d-md-block text-right">
                    <button
                      className={`${
                        this.state.hideFilterOptions === true
                          ? "btn btn-success-gradient"
                          : "btn btn-link"
                      }`}
                      onClick={() => this.toggleHideFilter()}
                    >
                      {this.state.hideFilterOptions
                        ? this.props.t("Show ")
                        : this.props.t("Hide ")}
                      {this.props.t("filter options")}{" "}
                      <i
                        className={`fas fa-chevron-${
                          this.state.hideFilterOptions ? "down" : "up"
                        }`}
                      ></i>
                    </button>
                  </div>
                  {this.state.hideFilterOptions !== true ? (
                    <div className="col-12 pt-3">
                      <div className="row d-none d-md-flex">
                        <div className="col-6">
                          <div className="row px-2">
                            <div className="col-12 filter-options left  pb-3">
                              <div className="d-flex pt-2">
                                <div className="p-2 flex-shrink bd-highlight">
                                  <small>{this.props.t("Choose trip")}</small>
                                  <h4 className="pb-2">
                                    <b>{this.props.t("By Days")}</b>
                                  </h4>
                                </div>
                                <div className="p-2 flex-fill bd-highlight"></div>
                                <div className="p-2 flex-shrink bd-highlight">
                                  <button
                                    onClick={() =>
                                      Controller.filter.updateFilter(
                                        "dayRange",
                                        null
                                      )
                                    }
                                    className="btn btn-link"
                                  >
                                    <i className="fas fa-undo-alt pr-1"></i>
                                    {this.props.t("Reset")}
                                  </button>
                                </div>
                              </div>
                              <div className="form-row custom-checkbox">
                                {this.props.local.filterSearchNumberOfDays.map(
                                  (numberOfDayRange, index) => (
                                    <div key={index} className="col-6">
                                      <div className="form-check">
                                        <label>
                                          <div className="text">
                                            {this.props.t(
                                              numberOfDayRange.label
                                            )}
                                          </div>{" "}
                                          <input
                                            checked={
                                              this.props.filterOptions
                                                .dayRange ===
                                              numberOfDayRange.value
                                            }
                                            type="checkbox"
                                            onChange={() =>
                                              Controller.filter.updateFilter(
                                                "dayRange",
                                                numberOfDayRange.value
                                              )
                                            }
                                            id={`check-box-${numberOfDayRange.value}`}
                                            name="check"
                                          />{" "}
                                          <div className="label-text"></div>
                                        </label>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                              <div
                                className="art d-none d-md-block"
                                style={{
                                  backgroundImage: `url('${require("../../assets/images/calendar.png")}')`
                                }}
                              ></div>
                            </div>
                          </div>
                          {/* <div className="form-row custom-checkbox">
                           <div className="col-6">
                           <div className="form-check">
                           <label>
                           <div className="text">Custom</div><input type="checkbox" name="check" /> <div className="label-text"></div>
                            </label>
                           </div>
                           </div>
                           </div>
                           <div className="form-row custom-checkbox">
                           <div className="col-6">
                           <small for="no-of-days">Specify no. of days</small>
                           <input type="number" className="form-control form-control-sm" id="no-of-days" placeholder="" />
                           </div>
                           </div> */}
                        </div>
                        <div className="col-6">
                          <div className="row px-2">
                            <div className="col-12 filter-options right">
                              <div className="row">
                                <div className="col-4">
                                  <div className="p-2">
                                    <small>{this.props.t("Choose trip")}</small>
                                    <h4 className="pb-5">
                                      <b>{this.props.t("By Price")}</b>
                                    </h4>
                                  </div>
                                </div>
                                <div className="col-8 px-4">
                                  <div className="row">
                                    <div className="col-12 pt-2 pb-3 text-right">
                                      <button
                                        onClick={() =>
                                          Controller.filter.updateFilter(
                                            "priceRange",
                                            [
                                              local.selectedCurrency
                                                .minPurchasePower,
                                              local.selectedCurrency
                                                .maxPurchasePower
                                            ]
                                          )
                                        }
                                        className="btn btn-link"
                                      >
                                        <i className="fas fa-undo-alt pr-1"></i>
                                        {this.props.t("Reset")}
                                      </button>
                                    </div>
                                    <div className="col-12 d-flex slider-val-display flex-row justify-content-between pr-5 pb-2">
                                      <SliderValueDisplay
                                        value={
                                          this.props.filterOptions
                                            .priceRange || [
                                            local.selectedCurrency
                                              .minPurchasePower,
                                            local.selectedCurrency
                                              .maxPurchasePower
                                          ]
                                        }
                                        max={
                                          local.selectedCurrency
                                            .maxPurchasePower
                                        }
                                      />
                                    </div>
                                    <div className="col-12 pr-5">
                                      <AirbnbSlider
                                        ThumbComponent={AirbnbThumbComponent}
                                        value={
                                          this.props.filterOptions
                                            .priceRange || [
                                            local.selectedCurrency
                                              .minPurchasePower,
                                            local.selectedCurrency
                                              .maxPurchasePower
                                          ]
                                        }
                                        onChange={(e, value) =>
                                          Controller.filter.updateFilter(
                                            "priceRange",
                                            value
                                          )
                                        }
                                        valueLabelDisplay="on"
                                        aria-labelledby="range-slider"
                                        step={
                                          local.selectedCurrency
                                            .purchasePowerStep
                                        }
                                        min={
                                          local.selectedCurrency
                                            .minPurchasePower
                                        }
                                        max={
                                          local.selectedCurrency
                                            .maxPurchasePower
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="art d-none d-md-block"
                                style={{
                                  backgroundImage: `url('${require("../../assets/images/money.png")}')`
                                }}
                              ></div>
                            </div>
                            <div className="col-12 text-right pt-3">
                              <Link
                                onClick={() =>
                                  Controller.filter.refreshFilter()
                                }
                                to="/filter"
                                className="btn btn-warning-gradient btn-sm"
                              >
                                {this.props.t("Apply")}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row d-md-none">
                        <div className="col-12 mv-filter">
                          <div className="pt-2 mt-2 accordion-wrapper">
                            <div className="accordion" id="filterAccordion">
                              <div className="card room-item-wrapper mb-2 p-t-2">
                                <button
                                  className="card-header d-flex align-items-center"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target={`#collapse1`}
                                  aria-expanded="true"
                                  aria-controls="collapseOne"
                                  id="filterHeadingOne"
                                >
                                  <div className="flex-shrink-0"></div>
                                  <div className="mb-0 text-center flex-fill">
                                    <img
                                      height="30px"
                                      src={require("../../assets/images/calendar.png")}
                                      alt=""
                                    />

                                    <p>{this.props.t("Choose trip")}</p>
                                    <h5 className="mb-0">
                                      {this.props.t("By Days")}
                                    </h5>
                                  </div>
                                  <div className="flex-shrink-0">
                                    <i className="fas fa-chevron-circle-up mt-1"></i>
                                  </div>
                                </button>

                                <div
                                  id={`collapse1`}
                                  className={`collapse show`}
                                  aria-labelledby="filterHeadingOne"
                                  data-parent="#filterAccordion"
                                >
                                  <div className="form-row custom-checkbox">
                                    {this.props.local.filterSearchNumberOfDays.map(
                                      (numberOfDayRange, index) => (
                                        <div key={index} className="col-12">
                                          <div className="form-check">
                                            <label>
                                              <div className="text">
                                                {this.props.t(
                                                  numberOfDayRange.label
                                                )}
                                              </div>{" "}
                                              <input
                                                checked={
                                                  this.props.filterOptions
                                                    .dayRange ===
                                                  numberOfDayRange.value
                                                }
                                                type="checkbox"
                                                onChange={() =>
                                                  Controller.filter.updateFilter(
                                                    "dayRange",
                                                    numberOfDayRange.value
                                                  )
                                                }
                                                id={`check-box-${numberOfDayRange.value}`}
                                                name="check"
                                              />{" "}
                                              <div className="label-text"></div>
                                            </label>
                                          </div>
                                        </div>
                                      )
                                    )}

                                    <div className="col-12 text-center">
                                      <button
                                        className="btn btn-link"
                                        onClick={() =>
                                          Controller.filter.updateFilter(
                                            "dayRange",
                                            null
                                          )
                                        }
                                      >
                                        <i className="fas fa-undo-alt pr-1"></i>
                                        {this.props.t("Reset")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="card room-item-wrapper mb-2 p-t-2">
                                <button
                                  className="card-header collapsed d-flex align-items-center"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target={`#collapse2`}
                                  aria-expanded="true"
                                  aria-controls="collapseOne"
                                  id="filterHeadingOne"
                                >
                                  <div className="flex-shrink-0"></div>
                                  <div className="mb-0 text-center flex-fill">
                                    <img
                                      height="30px"
                                      src={require("../../assets/images/money.png")}
                                      alt=""
                                    />

                                    <p>{this.props.t("Choose trip")}</p>
                                    <h5 className="mb-0">
                                      {this.props.t("By Price")}
                                    </h5>
                                  </div>
                                  <div className="flex-shrink-0">
                                    <i className="fas fa-chevron-circle-up mt-1"></i>
                                  </div>
                                </button>

                                <div
                                  id={`collapse2`}
                                  className={`collapse`}
                                  aria-labelledby="filterHeadingOne"
                                  data-parent="#filterAccordion"
                                >
                                  <div className="row px-5">
                                    <div className="col-12 d-flex slider-val-display flex-row justify-content-between pb-2">
                                      <SliderValueDisplay
                                        value={
                                          this.props.filterOptions
                                            .priceRange || [
                                            local.selectedCurrency
                                              .minPurchasePower,
                                            local.selectedCurrency
                                              .maxPurchasePower
                                          ]
                                        }
                                        max={
                                          local.selectedCurrency
                                            .maxPurchasePower
                                        }
                                      />
                                    </div>
                                    <div className="col-12">
                                      <AirbnbSlider
                                        ThumbComponent={AirbnbThumbComponent}
                                        value={
                                          this.props.filterOptions
                                            .priceRange || [
                                            local.selectedCurrency
                                              .minPurchasePower,
                                            local.selectedCurrency
                                              .maxPurchasePower
                                          ]
                                        }
                                        onChange={(e, value) =>
                                          Controller.filter.updateFilter(
                                            "priceRange",
                                            value
                                          )
                                        }
                                        valueLabelDisplay="on"
                                        aria-labelledby="range-slider"
                                        step={
                                          local.selectedCurrency
                                            .purchasePowerStep
                                        }
                                        min={
                                          local.selectedCurrency
                                            .minPurchasePower
                                        }
                                        max={
                                          local.selectedCurrency
                                            .maxPurchasePower
                                        }
                                      />
                                    </div>
                                    <div className="col-12 text-center">
                                      <button
                                        className="btn btn-link"
                                        onClick={() =>
                                          Controller.filter.updateFilter(
                                            "priceRange",
                                            [
                                              local.selectedCurrency
                                                .minPurchasePower,
                                              local.selectedCurrency
                                                .maxPurchasePower
                                            ]
                                          )
                                        }
                                      >
                                        <i className="fas fa-undo-alt pr-1"></i>
                                        {this.props.t("Reset")}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <Link
                            onClick={() => Controller.filter.refreshFilter()}
                            to="/filter"
                            className="btn btn-warning-gradient btn-block btn-sm"
                          >
                            {this.props.t("Apply")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="col-12 pt-3 col-md-3 pt-md-0 d-md-none text-center pb-3">
                    <button
                      className={`${
                        this.state.hideFilterOptions === true
                          ? "btn btn-success-gradient btn-sm"
                          : "btn btn-link"
                      }`}
                      onClick={() => this.toggleHideFilter()}
                    >
                      {this.state.hideFilterOptions ? "Show" : "Hide"}
                      {this.props.t("filter options")}{" "}
                      <i
                        className={`fas fa-chevron-${
                          this.state.hideFilterOptions ? "down" : "up"
                        }`}
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </section>
            <section className="top-destinations mt-2">
              <div className="container pt-5">
                <div className="row align-items-center seperator">
                  <div className="line col ml-3 ml-md-0"></div>
                  <div
                    className="seperator-image p-3 mx-3 col-auto"
                    style={{
                      backgroundImage: `url('${require("./../../assets/images/camera.png")}')`
                    }}
                  ></div>
                  <div className="line col mr-3 mr-md-0"></div>
                </div>
                <div className="row">
                  <div className="col-12 title-wrapper pt-5">
                    <div className="text-center">
                      <h2>{this.props.t("Top Destinations")}</h2>
                      <span>
                        {this.props.t(
                          "Discover and be inspired by top destinations curated by Tripbonder\u2019s verified and recommended local tour operators. All trips are designed with your maximum unforgettable experience in mind!"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="row pb-5"
                  style={{
                    opacity:
                      this.props.context.isSilentLoading === true ? 0.4 : 1,
                    transition: "0.3s opacity"
                  }}
                >
                  {this.renderTopDestination()}
                </div>
                {/* <div className="row">
                 <div className="col-12 pt-5 pb-5 d-none d-sm-block">
                   <div className="text-center">
                     <button type="button" className="btn btn-success-gradient">
                       See all
                     </button>
                   </div>
                 </div>
                 <div className="col-12 pt-5 pb-5 d-sm-none">
                   <div>
                     <button
                       type="button"
                       className="btn btn-success-gradient btn-sm btn-block"
                     >
                       See all
                     </button>
                   </div>
                 </div>
                 </div> */}
              </div>
            </section>
            <section className="popular-activities mt-2">
              <div className="container pt-5">
                <div className="row align-items-center seperator">
                  <div className="line col ml-3 ml-md-0"></div>
                  <div
                    className="seperator-image p-3 mx-3 col-auto"
                    style={{
                      backgroundImage: `url('${require("./../../assets/images/backpack.png")}')`
                    }}
                  ></div>
                  <div className="line col mr-3 mr-md-0"></div>
                </div>
                <div className="row">
                  <div className="col-12 title-wrapper pt-4">
                    <div className="text-center">
                      <h2>{this.props.t("Popular Activities")}</h2>
                      <span>
                        {this.props.t(
                          "Find, compare, and book multi-day tours from around the world! Save money and book directly from our local tour operators and guides. Let Tripbonder--Asia sightseeing & experience provider, helps you curate and design your desired travel experience. If you can\u2019t find the trip you want, don\u2019t worry! Just click on the CHAT WITH ME button to discuss directly your dream vacation with the trip service provider"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row activity">
                  <div className="col-12 pt-5 text-center d-none d-md-block">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        type="button"
                        className={`${
                          this.state.selectedActivityTab === "itinerary"
                            ? "active-button"
                            : "inactive-button"
                        } btn btn-secondary`}
                        onClick={() =>
                          this.setState({ selectedActivityTab: "itinerary" })
                        }
                      >
                        <i className="flight fas fa-plane-departure pr-2"></i>
                        {this.props.t("Trips")}
                      </button>
                      <button
                        type="button"
                        className={`${
                          this.state.selectedActivityTab === "ticket"
                            ? "active-button"
                            : "inactive-button"
                        } btn btn-secondary`}
                        onClick={() =>
                          this.setState({ selectedActivityTab: "ticket" })
                        }
                      >
                        <i className="fas fa-ticket-alt pr-2"></i>
                        {this.props.t("Tickets/Entrance Fees")}
                      </button>
                      <button
                        type="button"
                        className={`${
                          this.state.selectedActivityTab === "accomodation"
                            ? "active-button"
                            : "inactive-button"
                        } btn btn-secondary`}
                        onClick={() =>
                          this.setState({ selectedActivityTab: "accomodation" })
                        }
                      >
                        <i className="fas fa-mug-hot pr-2"></i>
                        {this.props.t("Travel & Accommodation")}
                      </button>
                      <button
                        type="button"
                        className={`${
                          this.state.selectedActivityTab === "transport"
                            ? "active-button"
                            : "inactive-button"
                        } btn btn-secondary`}
                        onClick={() =>
                          this.setState({ selectedActivityTab: "transport" })
                        }
                      >
                        <i className="fas fa-car pr-2"></i>
                        {this.props.t("Transport")}
                      </button>
                      {/* <button
                        type="button"
                        className={`${
                          this.state.selectedActivityTab === "partner"
                            ? "active-button"
                            : "inactive-button"
                        } btn btn-secondary`}
                        onClick={() =>
                          this.setState({ selectedActivityTab: "partner" })
                        }
                       >
                        <i className="fas fa-users pr-2"></i>
                        {this.props.t("Use Partners")}
                       </button> */}
                    </div>
                  </div>
                  <div className="col-12 pt-4 text-center d-md-none">
                    <div
                      className="btn-group mobile-view-tabs"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        type="button"
                        className={`${
                          this.state.selectedActivityTab === "itinerary"
                            ? "active-button"
                            : "inactive-button"
                        } btn btn-secondary`}
                        onClick={() =>
                          this.setState({ selectedActivityTab: "itinerary" })
                        }
                      >
                        <i className="fas fa-plane-departure"></i>
                        <br />
                        <small>{this.props.t("Trips")}</small>
                      </button>
                      <button
                        type="button"
                        className={`${
                          this.state.selectedActivityTab === "ticket"
                            ? "active-button"
                            : "inactive-button"
                        } btn btn-secondary`}
                        onClick={() =>
                          this.setState({ selectedActivityTab: "ticket" })
                        }
                      >
                        <i className="fas fa-ticket-alt"></i>
                        <br />
                        <small>{this.props.t("Tickets")}</small>
                      </button>
                      <button
                        type="button"
                        className={`${
                          this.state.selectedActivityTab === "accomodation"
                            ? "active-button"
                            : "inactive-button"
                        } btn btn-secondary`}
                        onClick={() =>
                          this.setState({ selectedActivityTab: "accomodation" })
                        }
                      >
                        <i className="fas fa-bed"></i>
                        <br />
                        <small>{this.props.t("Stay")}</small>
                      </button>
                      <button
                        type="button"
                        className={`${
                          this.state.selectedActivityTab === "transport"
                            ? "active-button"
                            : "inactive-button"
                        } btn btn-secondary`}
                        onClick={() =>
                          this.setState({ selectedActivityTab: "transport" })
                        }
                      >
                        <i className="fas fa-car"></i>
                        <br />
                        <small>{this.props.t("Transit")}</small>
                      </button>
                      {/* <button
                        type="button"
                        className={`${
                          this.state.selectedActivityTab === "partner"
                            ? "active-button"
                            : "inactive-button"
                        } btn btn-secondary`}
                        onClick={() =>
                          this.setState({ selectedActivityTab: "partner" })
                        }
                       >
                        <i className="fas fa-users"></i>
                        <br />
                        <small>{this.props.t("Partners")}</small>
                       </button> */}
                    </div>
                  </div>
                </div>
                {this.renderActivity()}
              </div>
            </section>
            {/* <section className="theme mt-2">
             <div className="container pt-5">
               <div className="row align-items-center seperator">
                 <div className="line col"></div>
                 <div
                   className="seperator-image p-3 mx-3 col-auto"
                   style={{
                     backgroundImage: `url('${require("./../../assets/images/rickshaw.png")}')`
                   }}
                 ></div>
                 <div className="line col"></div>
               </div>
               <div className="row">
                 <div className="col-12 pt-4 title-wrapper">
                   <div className="text-center">
                     <h2>Select Trip By Theme</h2>
                     <span className="brief pr-3">
                       Search your desired travel experience by the different
                       type of travel themes available in tripbonder.com
                     </span>
                   </div>
                 </div>
               </div>
               <div className="row pt-2">
                 <div className="col-12 pt-2 pb-5">
                   <div className="gg-box">
                     <Link
                       onClick={() =>
                         Controller.filter.togglePackageCategory(
                           "tourist-attraction",
                           true
                         )
                       }
                       to="/filter"
                       className="grid"
                     >
                       <img
                         src={require("../../assets/images/theme-grid/1.png")}
                         alt="tourist attraction"
                       />
                       <div className="overlay"></div>
                       <div className="title">
                         <h5 className="pb-2">TOURIST ATTRACTION</h5>
                         <small className="d-none d-sm-block pr-3">
                           Checked off your bucket list with tours that offer
                           sight-seeing to major tourist attractions.
                         </small>
                       </div>
                     </Link>
                     <Link
                       onClick={() =>
                         Controller.filter.togglePackageCategory(
                           "historical-places",
                           true
                         )
                       }
                       to="/filter"
                       className="grid"
                     >
                       <img
                         src={require("../../assets/images/theme-grid/3.png")}
                         alt="history"
                       />
                       <div className="overlay"></div>
                       <div className="title">
                         <h5 className="pb-2">HISTORICAL PLACES</h5>
                         <small className="d-none d-sm-block pr-3">
                           Be a cultural history buff and explores world’s famous
                           historical places!
                         </small>
                       </div>
                     </Link>
                     <Link
                       onClick={() =>
                         Controller.filter.togglePackageCategory(
                           "adventure",
                           true
                         )
                       }
                       to="/filter"
                       className="grid"
                     >
                       <img
                         src={require("../../assets/images/theme-grid/2.png")}
                         alt="adventure"
                       />
                       <div className="overlay"></div>
                       <div className="title">
                         <h5 className="pb-2">ADVENTURE</h5>
                         <small className="d-none d-sm-block pr-3">
                           Select from a wide varieties of outdoor activities
                           that takes place in unusual, exotic, remote or
                           wilderness destinations.
                         </small>
                       </div>
                     </Link>
                     <Link
                       onClick={() =>
                         Controller.filter.togglePackageCategory("nature", true)
                       }
                       to="/filter"
                       className="grid"
                     >
                       <img
                         src={require("../../assets/images/theme-grid/4.png")}
                         alt="nature"
                       />
                       <div className="overlay"></div>
                       <div className="title">
                         <h5 className="pb-2">NATURE</h5>
                         <small className="d-none d-sm-block pr-3">
                           Be a responsible traveller and conserves the
                           environment and improves the welfare of local people.
                         </small>
                       </div>
                     </Link>
                     <Link
                       onClick={() =>
                         Controller.filter.togglePackageCategory("fun", true)
                       }
                       to="/filter"
                       className="grid"
                     >
                       <img
                         src={require("../../assets/images/theme-grid/5.png")}
                         alt="fun"
                       />
                       <div className="overlay"></div>
                       <div className="title">
                         <h5 className="pb-2">FUN</h5>
                         <small className="d-none d-sm-block pr-3">
                           Enjoy any activities that is fun and existing with
                           this tour activities. Be it fun at the seas, fun at
                           theme parks or fun at the cities. There is always a
                           fun activities for you and your family to enjoy!
                         </small>
                       </div>
                     </Link>
                     <Link
                       onClick={() =>
                         Controller.filter.togglePackageCategory(
                           "shopping",
                           true
                         )
                       }
                       to="/filter"
                       className="grid"
                     >
                       <img
                         src={require("../../assets/images/theme-grid/6.png")}
                         alt="shopping"
                       />
                       <div className="overlay"></div>
                       <div className="title">
                         <h5 className="pb-2">SHOPPING</h5>
                         <small className="d-none d-sm-block pr-3">
                           Get a Shop-Till-You-Drop experience offered by our
                           local tour guides. Avoid tourist traps by following
                           our locals into back alley to get the best deals!
                         </small>
                       </div>
                     </Link>
                   </div>
                 </div>
               </div>
             </div>
             </section> */}
            <section className="container-fluid become-a-tripbonder">
              <div className="row swimming-img">
                <div className="col-12 col-lg-6 text-center text-lg-left book-your-trip pl-lg-5 pb-5">
                  <div className="pl-lg-5">
                    <h1 className="pt-5">
                      {this.props.t("BOOK YOUR")}
                      <span className="bonder">{this.props.t("TRIP")}</span>
                    </h1>
                    {this.props.local.isAuthenticated === true ? (
                      <p>
                        {this.props.t(
                          "SEARCH YOUR DESIRED TRAVEL EXPERIENCE BY THE DIFFERENT TYPE OF TRAVEL THEMES"
                        )}
                      </p>
                    ) : (
                      <p>
                        {this.props.t(
                          "SIGN IN TO TRIPBONDER FOR A PERSONALISED EXPERIENCE"
                        )}
                      </p>
                    )}

                    <div className="pt-5">
                      <Link
                        to={`${
                          this.props.local.isAuthenticated === true
                            ? "/filter"
                            : "/auth/register"
                        }`}
                        type="button"
                        className="btn btn-success-gradient take-me-there-button-wrapper px-5 mt-3"
                      >
                        {this.props.local.isAuthenticated === true ? (
                          <span className="mb-0 pt-0">
                            {this.props.t("Explore Packages")}
                          </span>
                        ) : (
                          <span className="mb-0 pt-0">
                            {this.props.t("Sign Up for Free")}
                          </span>
                        )}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 text-center text-lg-right pr-lg-5 pb-5">
                  <div className="pr-lg-5">
                    <h1 className="pt-5">
                      {this.props.t("BECOME A TRIP")}
                      <span className="bonder">{this.props.t("BONDER")}</span>
                    </h1>
                    <p className="pt-2">
                      {this.props.t(
                        "FREE LISTING | ITINERARY MAKERS | INCOME BOOST"
                      )}
                    </p>
                    <div className="pt-5">
                      <Link
                        to="/tripbonder/get-started"
                        type="button"
                        className="btn btn-success-gradient take-me-there-button-wrapper px-5 mt-3"
                      >
                        {this.props.t("Take me there")}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* <section className="container-fluid become-a-tripbonder">
             <div className="row">
               <div className="col-12">
                 <div className="swimming-img">
                   <div className="container">
                     <div className="row">
                       <div className="col-12 text-center">
                         <p className="d-sm-none pt-2">
                           FREE LISTING | ITINERARY MAKERS | INCOME BOOST
                         </p>
                       </div>
                       <div className="col-12 text-right">
                         <div>
                           <h1 className="pt-5">
                             BECOME A TRIP<span className="bonder">BONDER</span>
                           </h1>
                           <p className="d-none d-sm-block pt-2">
                             FREE LISTING &nbsp; | &nbsp; ITINERARY MAKERS &nbsp;
                             | &nbsp; INCOME BOOST
                           </p>
                         </div>
                         <div className="pt-5 mt-5">
                           <Link
                             to="/tripbonder/get-started"
                             type="button"
                             className="btn btn-success-gradient take-me-there-button-wrapper px-5"
                           >
                             Take me there
                           </Link>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
             </section> */}
            <section className="container-fluid content-wrapper3 mt-2">
              <div className="row yellow-background pt-4 pb-4">
                <div className="col-12 text-center">
                  <h4>
                    <del>{this.props.t("Why Tripbonder")}</del>
                  </h4>
                  <h1>{this.props.t("Why Not Tripbonder?")}</h1>
                </div>
              </div>
              <div className="row background-img3">
                <div className="col-12"></div>
              </div>
              <div className="row yellow-background">
                <div className="col-12">
                  <div className="container yellow-background">
                    <div className="row pt-3 pb-3">
                      <div className="col-12 col-md-6 text-center pl-3 py-4">
                        <div className="mb-2">
                          <i className="fas fa-globe"></i>
                        </div>
                        <span className="mb-4">
                          {this.props.t(
                            "BOOK TRIP DIRECTLY WITH LOCAL TOUR OPERATORS"
                          )}
                        </span>
                        <p>
                          {this.props.t(
                            "In Tripbonder.com, all trips are operated by local tour operators and guides. You get the first hand experience from the best operators, i.e. the locals!"
                          )}
                        </p>
                      </div>
                      <div className="col-12 col-md-6 text-center py-4">
                        <div className="mb-2">
                          <i className="fas fa-hand-holding-usd"></i>
                        </div>
                        <span className="mb-4">
                          {this.props.t("CUSTOMIZE YOUR TRIP")}
                        </span>
                        <p>
                          {this.props.t(
                            "Every trip in Tripbonder.com is customizable. There is no one single way to enjoy a place. Let the operator know what you want by using the CHAT with X button. Tour operators are happy to custom built a trip just for you!"
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="help-desk mt-2">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="help-desk-img">
                      <div className="container">
                        <div className="row ">
                          <div className="col-12 col-md-6 pt-5 pb-2 pb-md-5">
                            <h2>
                              {this.props.t(
                                "Couldn't find what you were looking for?"
                              )}
                            </h2>
                            <span className="pb-5">
                              {this.props.t("Please let us know")}
                            </span>
                            <form onSubmit={e => this.postEnquiry(e)}>
                              <div className="pt-5">
                                <input
                                  type="email"
                                  className="form-control"
                                  id="exampleInputEmail1"
                                  aria-describedby="emailHelp"
                                  placeholder={this.props.t(
                                    "Your email address"
                                  )}
                                  value={this.state.email}
                                  onChange={this.emailChange}
                                />
                              </div>
                              <div className="pt-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="location"
                                  aria-describedby="location"
                                  placeholder={this.props.t(
                                    "Type your location"
                                  )}
                                  value={this.state.location}
                                  onChange={this.locationChange}
                                />
                              </div>
                              <div className="pt-3 text-right d-none d-sm-block">
                                <button
                                  type="submit"
                                  className="btn btn-warning-gradient"
                                >
                                  {this.props.t("Send Request")}
                                </button>
                              </div>
                              <div className="pt-3 d-sm-none">
                                <button
                                  type="submit"
                                  className="btn btn-warning-gradient btn-sm btn-block"
                                >
                                  {this.props.t("Send Request")}
                                </button>
                              </div>
                            </form>
                          </div>
                          <div className="col-12 col-md-6 d-flex justify-content-center align-items-center p-2 pb-4 pt-md-5">
                            <div
                              className="help-desk-sprite"
                              style={{
                                backgroundImage: `url('${require("./../../assets/images/tourists.png")}')`
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Fade>
      );
    }

    render() {
      const { context } = this.props;

      return (
        <React.Fragment>
          <Helmet>
            <title>
              {this.props.t(
                "Welcome to the Tripbonder - Asia's leading trip and travel planning portal"
              )}
            </title>
          </Helmet>
          {context.isLoading === true
            ? this.renderLoader()
            : this.renderHomePage()}
        </React.Fragment>
      );
    }
  }
);

export default connect(state => ({
  context: state.marketing,
  local: state.local,
  filterOptions: state.filter
}))(HomePage);
