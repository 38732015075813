import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Fade, Zoom } from "react-reveal";
import { Nav, NavItem, NavLink } from "reactstrap";
import ReactGA from "react-ga";
import {
  DateRangePicker,
  DayPickerRangeController,
  DayPickerSingleDateController
} from "react-dates";
import controllers from "../../store/controllers";
import { NETWORK_ERR_MESSAGE } from "../../resources/String";
import moment from "moment";

import { Elements, CardElement, injectStripe } from "react-stripe-elements";
import {
  GetFormattedPrice,
  CalculateGrossAmount,
  CalculateDeposit,
  CalculateDiscountAmount,
  stripAddress,
  CalculateOrderServiceCharge,
  GetItineraryAdultPrice
} from "../../resources/Helper";
import { OrderServices } from "../../services/Order.services";
import { PaypalButton } from "../Braintree/PaypalButton";

const CheckoutForm = injectStripe(props => {
  const { t } = useTranslation();
  const { orderId, onMount, stripe } = props;
  useEffect(() => {
    onMount && onMount(stripe);
  }, [orderId, onMount, stripe]);
  return (
    <div className="checkout">
      <p style={{ fontSize: 12 }}>
        {t("Provide your credit / debit card information here")}
      </p>
      <CardElement />
    </div>
  );
});

const GetTitle = mode => {
  switch (mode) {
    case "receipt": {
      return "Thank you for your order";
    }
    case "payment-info": {
      return "Step 3 of 3";
    }
    case "billing-info": {
      return "Step 2 of 3";
    }
    default: {
      return "Setup your order...";
    }
  }
};

const GetDescription = (mode, type) => {
  switch (mode) {
    case "receipt": {
      return "View all your booked trips on My Bookings section. Have a wonderful vacation!";
    }
    case "payment-info": {
      return "Please complete your payment";
    }
    case "billing-info": {
      return "Please provide your billing / contact information";
    }
    default: {
      return "Provide your order details";
    }
  }
};

const GetCheckoutButtonTitle = (mode, summaryMetrics, currency, t) => {
  switch (mode) {
    case "receipt": {
      return t("See All Bookings");
    }
    case "payment-info": {
      return (
        <span>
          <i className="fas fa-lock pr-2"></i>
          {t("Complete Purchase")}
        </span>
      );
    }
    case "billing-info": {
      return `${t("PAY")} ${GetFormattedPrice(
        summaryMetrics.amountPayable,
        currency
      )}`;
    }
    default: {
      return t("Proceed to Checkout");
    }
  }
};

export default connect(state => ({
  selectedCurrency: state.local.selectedCurrency,
  selectedRoomIndex: state.marketing.selectedRoomIndex,
  local: state.local
}))(function(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const [mode, setMode] = React.useState("order-setup"); // order-setup, billing-info, payment-info, receipt
  const [isLoading, setLoading] = React.useState(true);
  let [isOrderUpdating, setOrderUpdating] = React.useState(false);
  let [couponCode, setCouponCode] = React.useState("");

  // Step 1 State
  const [min2Pax, setMin2Pax] = React.useState(false);
  const [paymentTerm, setPaymentTerm] = React.useState("full");
  const [roomCapacity, setRoomCapacity] = React.useState("");
  const [transportTariffType, setTransportTariffType] = React.useState("price");
  const [adultPersonCount, setAdultPersonCount] = React.useState(1);
  const [childCount, setChildCount] = React.useState(0);
  const [childCountWithBed, setChildCountWithBed] = React.useState(0);
  const [extraBedCount, setExtraBedCount] = React.useState(0);
  const [focusedDateRangeInput, updateFocusedDateRangeInput] = React.useState(
    null
  );

  const [orderDateRange, setOrderDateRange] = React.useState({
    beginingDate: null,
    endDate: null
  });

  // Step 2 State
  const [billingName, setBillingName] = React.useState("");
  const [addressLine1, setAddressLine1] = React.useState("");
  const [addressLine2, setAddressLine2] = React.useState("");
  const [city, setCity] = React.useState("");
  const [state, updateState] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [altPhoneNumber, setAltPhoneNumber] = React.useState("");

  // Step 3 State
  const [paymentProvider, switchPaymentProvider] = React.useState("stripe"); // stripe, paypal
  const [stripeProps, setStripeProps] = React.useState(null);

  const [orderContext, setOrderContext] = React.useState(null);
  const context = orderContext ? orderContext.listingSnapshot : null;

  const packageId = props.context._id;
  const selectedRoomIndex = props.selectedRoomIndex;
  const toggle = props.toggle;

  let isEligibleForPartialPayment =
    orderDateRange.beginingDate &&
    orderDateRange.beginingDate.diff(moment(), "days") > 90;

  const ApplyCoupon = e => {
    e.preventDefault();
    setOrderUpdating(true);
    setTimeout(() => {
      OrderServices.AttachCoupon(orderContext._id, couponCode)
        .then(attachedOrder => {
          ReactGA.event({
            category: "User",
            action: `${orderContext._id} Coupon code ${couponCode} added`,
            value: 3
          });

          setOrderUpdating(false);
          setOrderContext(attachedOrder);
          setCouponCode("");
        })
        .catch(err => {
          console.error(err);
          setOrderUpdating(false);
          setCouponCode("");
          controllers.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
        });
    }, 700);
  };

  const DetatchCoupon = () => {
    setOrderUpdating(true);
    setTimeout(() => {
      OrderServices.DetatchCoupons(orderContext._id)
        .then(attachedOrder => {
          setOrderUpdating(false);
          setOrderContext(attachedOrder);
          setCouponCode("");
        })
        .catch(err => {
          console.error(err);
          setOrderUpdating(false);
          setCouponCode("");
          controllers.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
        });
    }, 700);
  };

  const ProceedCheckout = options => {
    switch (mode) {
      case "order-setup": {
        if (orderContext) {
          let payload;
          switch (orderContext.type) {
            case "itinerary": {
              payload = {
                beginingDate: orderDateRange.beginingDate,
                endDate: orderDateRange.endDate,
                adultPersonCount: adultPersonCount || 0,
                childCount: childCount || 0,
                childCountWithBed: childCountWithBed || 0
              };

              if (!payload.beginingDate) {
                controllers.setGlobalError("Please choose a start date", true);
                return;
              }

              if (!payload.endDate) {
                controllers.setGlobalError("Please choose an end date", true);
                return;
              }

              if (payload.adultPersonCount < 1) {
                controllers.setGlobalError(
                  "At least one adult is required to proceed",
                  true
                );

                return;
              }

              break;
            }
            case "ticket": {
              payload = {
                beginingDate: orderDateRange.beginingDate,
                adultPersonCount: adultPersonCount
              };

              if (!payload.beginingDate) {
                controllers.setGlobalError("Please choose a date", true);
                return;
              }

              if (payload.adultPersonCount < 1) {
                controllers.setGlobalError(
                  "Please select at least one person",
                  true
                );

                return;
              }

              break;
            }
            case "transport": {
              payload = {
                beginingDate: orderDateRange.beginingDate,
                endDate: orderDateRange.endDate,
                tariff:
                  transportTariffType === "pricePerWay" ? "per-way" : "normal"
              };

              if (!payload.beginingDate) {
                controllers.setGlobalError("Please choose a start date", true);
                return;
              }

              if (!payload.endDate) {
                controllers.setGlobalError("Please choose an end date", true);
                return;
              }

              if (payload.tariff !== "normal" && payload.tariff !== "per-way") {
                controllers.setGlobalError(
                  "Either the tariff should be normal or per way",
                  true
                );

                return;
              }

              break;
            }
            case "accomodation": {
              payload = {
                beginingDate: orderDateRange.beginingDate,
                endDate: orderDateRange.endDate,
                selectedRoomIndex: selectedRoomIndex,
                selectedCapacityOptions: roomCapacity,
                extraBedCount: extraBedCount,
                adultPersonCount: adultPersonCount,
                childCount: childCount
              };

              if (!payload.beginingDate) {
                controllers.setGlobalError(
                  "Please choose the check-in date",
                  true
                );

                return;
              }

              if (!payload.endDate) {
                controllers.setGlobalError(
                  "Please choose the check-out date",
                  true
                );

                return;
              }

              if (payload.selectedRoomIndex < 0) {
                controllers.setGlobalError(
                  "Please choose your preferred room",
                  true
                );

                return;
              }

              if (!payload.selectedCapacityOptions) {
                controllers.setGlobalError("Please pick room capacity", true);
                return;
              }

              break;
            }
            default: {
              controllers.setGlobalError(
                "Sorry, your order cannot be processed",
                true
              );

              toggle && toggle();
              return;
            }
          }

          if (payload.beginingDate) {
            payload.beginingDate = payload.beginingDate.valueOf();
          }

          payload.paymentTerm = "full";
          if (isEligibleForPartialPayment) {
            payload.paymentTerm = paymentTerm;
          }

          if (payload.endDate) {
            payload.endDate = payload.endDate.valueOf();
          }

          setLoading(true);
          setTimeout(() => {
            OrderServices.Update(orderContext._id, payload, false)
              .then(updatedOrder => {
                ReactGA.event({
                  category: "User",
                  action: `${orderContext._id} Reached till billing info`
                });

                setMode("billing-info");
                setLoading(false);
              })
              .catch(err => {
                console.error(err);
                controllers.setGlobalError(
                  err.message || NETWORK_ERR_MESSAGE,
                  true
                );
              });
          }, 400);
        }
        break;
      }
      case "billing-info": {
        const payload = {
          billingName: billingName,
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          city: city,
          state: state,
          postalCode: postalCode,
          country: country,
          phoneNumber: phoneNumber,
          altPhoneNumber: altPhoneNumber,
          paymentProvider: paymentProvider
        };

        if (payload.billingName === "") {
          controllers.setGlobalError("Billing name is required", true);
          return;
        }

        if (payload.addressLine1 === "") {
          controllers.setGlobalError("Address line 1 is required", true);
          return;
        }

        if (payload.city === "") {
          controllers.setGlobalError("City is required", true);
          return;
        }

        if (payload.state === "") {
          controllers.setGlobalError("State is required", true);
          return;
        }

        if (payload.postalCode === "") {
          controllers.setGlobalError("Postal code is required", true);
          return;
        }

        if (payload.country === "") {
          controllers.setGlobalError("Country is required", true);
          return;
        }

        if (payload.phoneNumber === "") {
          controllers.setGlobalError("Phone number is required", true);
          return;
        }

        // if (payload.altPhoneNumber === "") {
        //   controllers.setGlobalError("Alt. phone number is required", true);
        //   return;
        // }

        setLoading(true);
        setTimeout(() => {
          OrderServices.Update(orderContext._id, payload, true)
            .then(updatedOrder => {
              ReactGA.event({
                category: "User",
                action: `${orderContext._id} Reached till payment info`
              });

              setMode("payment-info");
              setLoading(false);
            })
            .catch(err => {
              console.error(err);
              setLoading(false);
              controllers.setGlobalError(
                err.message || NETWORK_ERR_MESSAGE,
                true
              );
            });
        }, 400);

        break;
      }
      case "payment-info": {
        if (paymentProvider === "stripe") {
          if (stripeProps) {
            setOrderUpdating(true);
            setTimeout(() => {
              stripeProps
                .createToken()
                .then(({ token }) => {
                  setOrderUpdating(false);
                  if (token) {
                    setLoading(true);
                    setTimeout(() => {
                      OrderServices.Place(orderContext._id, {
                        tokenId: token.id
                      })
                        .then(response => {
                          ReactGA.event({
                            category: "User",
                            action: `Order ${orderContext._id} placed successfully`
                          });

                          setOrderContext(response);
                          setLoading(false);
                          setMode("receipt");
                        })
                        .catch(err => {
                          setLoading(false);
                          controllers.setGlobalError(
                            err.message || NETWORK_ERR_MESSAGE,
                            true
                          );
                        });
                    }, 700);
                  } else {
                    controllers.setGlobalError(
                      "Please enter a valid card info",
                      true
                    );
                  }
                })
                .catch(err => {
                  setOrderUpdating(false);
                  console.error(err);
                  controllers.setGlobalError(
                    "Failed to process your payment",
                    true
                  );
                });
            }, 600);
          }
        } else if (paymentProvider === "paypal") {
          setLoading(true);
          setTimeout(() => {
            OrderServices.Place(
              orderContext._id,
              { paypalOrderId: options.data.orderID },
              "paypal"
            )
              .then(response => {
                ReactGA.event({
                  category: "User",
                  action: `Order ${orderContext._id} placed successfully`
                });

                setOrderContext(response);
                setLoading(false);
                setMode("receipt");
              })
              .catch(err => {
                setLoading(false);
                controllers.setGlobalError(
                  err.message || NETWORK_ERR_MESSAGE,
                  true
                );
              });
          }, 700);
        }
        break;
      }
      case "receipt": {
        toggle && toggle();
        history.push({ pathname: "/me/orders" });
        break;
      }
      default: {
        break;
      }
    }
  };

  const UpdatePaymentTerm = term => {
    setOrderUpdating(true);
    setTimeout(() => {
      OrderServices.Update(orderContext._id, { paymentTerm: term }, false)
        .then(updatedOrder => {
          setPaymentTerm(term);
          setOrderUpdating(false);
        })
        .catch(err => {
          controllers.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
          setOrderUpdating(false);
        });
    }, 700);
  };

  const GoBack = () => {
    switch (mode) {
      case "billing-info": {
        setLoading(true);
        setTimeout(() => {
          setMode("order-setup");
          setLoading(false);
        }, 700);
        break;
      }
      case "payment-info": {
        setLoading(true);
        setTimeout(() => {
          setMode("billing-info");
          setLoading(false);
        }, 700);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleContact = () => {
    const local = props.local;
    props.toggle();
    controllers.chat.startConversation([
      // Sender
      {
        chatUserId: local.chatUserId,
        profileImageId:
          local.isAuthenticated === true
            ? local.currentUser.profileImageId
            : null,
        displayName:
          local.isAuthenticated === true ? local.currentUser.name : "",
        emailAddress:
          local.isAuthenticated === true ? local.currentUser.emailAddress : "",
        group: context.package.title,
        groupPayload: {
          listingId: context._id,
          type: context.type,
          defaultCoverArtId: context.package.defaultCoverArtId
        },

        tag: "traveller"
      },

      // Receipient
      {
        chatUserId: context.user._id,
        profileImageId: context.user.profileImageId,
        displayName: context.user.name,
        emailAddress: context.user.emailAddress,
        group: context.package.title,
        groupPayload: {
          listingId: context._id,
          type: context.type,
          defaultCoverArtId: context.package.defaultCoverArtId
        },

        tag: "tripbonder"
      }
    ]);
  };

  const GetOrderSummary = () => {
    const result = {
      grossAmount: 0,
      serviceCharges: 0,
      discount: 0,
      netAmount: 0,
      amountPayable: 0
    };

    if (orderContext) {
      const currentQuote = Object.assign({}, orderContext, {
        beginingDate:
          orderDateRange.beginingDate && orderDateRange.beginingDate.valueOf(),
        endDate: orderDateRange.endDate && orderDateRange.endDate.valueOf(),
        tariff: transportTariffType === "pricePerWay" ? "per-way" : "normal",
        adultPersonCount: adultPersonCount,
        childCount: childCount,
        childCountWithBed: childCountWithBed,
        extraBedCount: extraBedCount,
        selectedRoomIndex: selectedRoomIndex,
        selectedCapacityOptions: roomCapacity,
        paymentProvider: paymentProvider
      });

      result.grossAmount = CalculateGrossAmount(currentQuote);
      result.discount = CalculateDiscountAmount(currentQuote);

      result.serviceCharges = CalculateOrderServiceCharge(
        result.grossAmount,
        orderContext.currencyPlaceValue,
        orderContext.forexRateToUSD,
        paymentProvider
      );
    }

    result.netAmount =
      result.grossAmount + result.serviceCharges - result.discount;

    result.amountPayable = result.netAmount;
    if (isEligibleForPartialPayment) {
      if (paymentTerm === "deposit") {
        const deposit = CalculateDeposit(result.netAmount);
        result.amountPayable = deposit;
      }
    }

    return result;
  };

  const handleMin2Pax = val => {
    const isMin2Pax = val;
    setAdultPersonCount(isMin2Pax ? 2 : 1);
    setChildCount(0);
    setChildCountWithBed(0);
    setMin2Pax(val);
  };

  useEffect(() => {
    setLoading(true);
    var timer = setTimeout(() => {
      OrderServices.CreateOrder(packageId)
        .then(order => {
          setOrderContext(order);
          if (order.type === "transport") {
            setTransportTariffType(
              order.tariff === "per-way" ? "pricePerWay" : "price"
            );
          }
          if (order.type === "itinerary") {
            handleMin2Pax(order.listingSnapshot.package.min2Pax);
          }
          setMode("order-setup"); // payment-info, order-setup, billing-info, receipt
          setLoading(false);
        })
        .catch(err => {
          console.error(err);
          controllers.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
        });
    }, 700);
    return function() {
      clearTimeout(timer);
    };
  }, [packageId]);

  let formContent;
  let summaryComponent;
  const summaryMetrics = GetOrderSummary();

  let noOfDays = 1;
  if (orderDateRange.beginingDate && orderDateRange.endDate) {
    noOfDays = orderDateRange.endDate.diff(orderDateRange.beginingDate, "days");
  }

  if (context) {
    const listingType = context.type || null;
    switch (listingType) {
      case "itinerary": {
        const isOneDay =
          orderDateRange.beginingDate &&
          orderDateRange.endDate &&
          orderDateRange.beginingDate.valueOf() ===
            orderDateRange.endDate.valueOf();
        summaryComponent = (
          <React.Fragment>
            <div className="d-flex justify-content-center align-items-center py-1">
              <div className="flex-shrink-0">
                <label className="mb-0">{`${GetFormattedPrice(
                  GetItineraryAdultPrice(
                    { listingSnapshot: context },
                    adultPersonCount
                  ),

                  props.selectedCurrency
                )} X ${adultPersonCount} ${t("Adult")}${
                  adultPersonCount > 1 ? "s" : ""
                }`}</label>
              </div>
              <div className="flex-fill"></div>
              <div className="flex-shrink-0 text-right">
                <h6 className="mb-0 text-right">
                  {GetFormattedPrice(
                    GetItineraryAdultPrice(
                      { listingSnapshot: context },
                      adultPersonCount
                    ) * adultPersonCount,
                    props.selectedCurrency
                  )}
                </h6>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center py-1">
              <div className="flex-shrink-0">
                <label className="mb-0">{`${GetFormattedPrice(
                  context.package.effectivePerChildPrice,
                  props.selectedCurrency
                )} X ${childCount} ${t(
                  `Child${childCount > 1 ? "ren" : ""}`
                )}`}</label>
              </div>
              <div className="flex-fill"></div>
              <div className="flex-shrink-0 text-right">
                <h6 className="mb-0 text-right">
                  {GetFormattedPrice(
                    context.package.effectivePerChildPrice * childCount,
                    props.selectedCurrency
                  )}
                </h6>
              </div>
            </div>
            {parseFloat(context.package.effectivePerChildPriceWithBed) > 0 ? (
              <div className="d-flex justify-content-center align-items-center py-1">
                <div className="flex-shrink-0">
                  <label className="mb-0" style={{ fontSize: 12 }}>
                    {`${GetFormattedPrice(
                      context.package.effectivePerChildPriceWithBed,
                      props.selectedCurrency
                    )} X ${childCountWithBed} ${t(
                      `Child${childCountWithBed > 1 ? "ren" : ""} with bed`
                    )}`}
                  </label>
                </div>
                <div className="flex-fill"></div>
                <div className="flex-shrink-0 text-right">
                  <h6 className="mb-0 text-right">
                    {GetFormattedPrice(
                      context.package.effectivePerChildPriceWithBed *
                        childCountWithBed,
                      props.selectedCurrency
                    )}
                  </h6>
                </div>
              </div>
            ) : null}
            {orderDateRange.beginingDate ? (
              <div className="d-flex justify-content-center align-items-center py-1">
                <div className="flex-shrink-0">
                  <label className="mb-0">
                    <label className="mb-0">
                      {orderDateRange.beginingDate &&
                        orderDateRange.beginingDate.format("ll")}
                    </label>
                    {isOneDay === false ? (
                      <React.Fragment>
                        <label className="px-1 mb-0">{t("-")}</label>
                        <label className="mb-0">
                          {orderDateRange.endDate &&
                            orderDateRange.endDate.format("ll")}
                        </label>
                      </React.Fragment>
                    ) : null}
                  </label>
                </div>
                <div className="flex-fill"></div>
                <div className="flex-shrink-0 text-right">
                  <h6 className="mb-0">{`${context.package.days.length} Day${
                    context.package.days.length > 1 ? "s" : ""
                  }`}</h6>
                </div>
              </div>
            ) : null}
            {isEligibleForPartialPayment === true ? (
              <React.Fragment>
                <label className="checkbox-title mb-0 mt-2">
                  {t("Select Payment Type")}
                </label>
                <div className="form-check">
                  <input
                    checked={paymentTerm === "deposit"}
                    type="checkbox"
                    className="form-check-input mt-2"
                    id="pay-advance"
                    disabled={isOrderUpdating || mode === "payment-info"}
                    onChange={e =>
                      e.target.checked === true
                        ? UpdatePaymentTerm("deposit")
                        : UpdatePaymentTerm("full")
                    }
                  />

                  <label
                    className="form-check-label"
                    htmlFor="pay-advance"
                    style={{ userSelect: "none" }}
                  >
                    {t("Pay 50% advance now")}
                  </label>
                </div>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        );

        formContent = (
          <div className="pt-2 pt-lg-4 ml-0 ml-lg-0 mr-0 mr-lg-3 px-3">
            <h4 className="mb-1">{t("TRAVELLERS")}</h4>
            <hr className="my-0" />
            <div className="form-row py-3">
              <div
                className={`form-group ${
                  parseFloat(context.package.effectivePerChildPriceWithBed) > 0
                    ? "col-12"
                    : "col-6"
                }`}
              >
                <label htmlFor="adult-count">{t("How many people?")}</label>
                <select
                  id="adult-count"
                  value={adultPersonCount}
                  onChange={e => {
                    setChildCount(0);
                    setChildCountWithBed(0);
                    setAdultPersonCount(parseInt(e.target.value));
                  }}
                  className="form-control"
                >
                  {new Array(
                    Math.floor(
                      min2Pax === true
                        ? context.package.maxTravellers / 2
                        : context.package.maxTravellers
                    )
                  )
                    .fill(null)
                    .map((item, index) => {
                      const val = (index + 1) * (min2Pax === true ? 2 : 1);
                      return (
                        <option value={val} key={index}>{`${val} ${t("Adult")}${
                          index > 0 ? "s" : ""
                        }`}</option>
                      );
                    })}
                </select>
              </div>
              <div className="form-group col-6">
                <label htmlFor="child-count">{t("How many children?")}</label>
                {(() => {
                  const maxNoOfChildSupported =
                    Math.floor(adultPersonCount / 2) - childCountWithBed;
                  return (
                    <select
                      id="adult-count"
                      value={childCount}
                      onChange={e => setChildCount(parseInt(e.target.value))}
                      className="form-control"
                      disabled={maxNoOfChildSupported < 1}
                    >
                      <option value={0}>{t("No Child")}</option>
                      {new Array(maxNoOfChildSupported)
                        .fill(null)
                        .map((item, index) => (
                          <option value={index + 1} key={index}>{`${index +
                            1} ${
                            index > 0 ? t("Children") : t("Child")
                          }`}</option>
                        ))}
                    </select>
                  );
                })()}
                <small className="form-text text-muted">
                  {t("Child is from the age of 2 to 10 only")}
                </small>
              </div>
              {parseFloat(context.package.effectivePerChildPriceWithBed) > 0 ? (
                <div className="form-group col-6">
                  <label htmlFor="child-count">
                    {t("Children (with bed)?")}
                  </label>
                  {(() => {
                    const maxNoOfChildSupported =
                      Math.floor(adultPersonCount / 2) - childCount;
                    return (
                      <select
                        id="adult-count"
                        value={childCountWithBed}
                        onChange={e =>
                          setChildCountWithBed(parseInt(e.target.value))
                        }
                        className="form-control"
                        disabled={maxNoOfChildSupported < 1}
                      >
                        <option value={0}>{t("No Child")}</option>
                        {new Array(maxNoOfChildSupported)
                          .fill(null)
                          .map((item, index) => (
                            <option value={index + 1} key={index}>{`${index +
                              1} ${
                              index > 0 ? t("Children") : t("Child")
                            }`}</option>
                          ))}
                      </select>
                    );
                  })()}
                </div>
              ) : null}
              <div className="form-row">
                <div className="form-group col-12 px-3 m-0">
                  <small className="form-text text-muted">
                    {t(
                      `If you are looking to book for large group of traveler (e.g. more than 15 people), please`
                    )}{" "}
                    <span
                      onClick={() => handleContact()}
                      className="contact-me-link"
                    >
                      {t("CONTACT ME")}
                    </span>{" "}
                    {t("for the best price")}
                  </small>
                </div>
              </div>
            </div>
            <h4 className="pt-2 mb-1">{t("CHOOSE YOUR DATES")}</h4>
            <hr className="my-0" />
            <div className="form-row py-3 date-wrapper">
              <div className="col-auto mx-auto mx-lg-0">
                <DayPickerRangeController
                  hideKeyboardShortcutsPanel
                  numberOfMonths={1}
                  startDate={orderDateRange.beginingDate}
                  endDate={orderDateRange.endDate}
                  onDatesChange={({ startDate, endDate }) => {
                    setOrderDateRange({
                      beginingDate: startDate,
                      endDate: startDate
                        .clone()
                        .add(context.package.days.length - 1, "d")
                    });
                  }}
                  enableOutsideDays
                  focusedInput={"startDate"}
                  endDateOffset={day =>
                    day.add(context.package.days.length - 1, "days")
                  }
                  isOutsideRange={day => {
                    return day.isBefore(moment());
                  }}
                />
              </div>
            </div>
          </div>
        );

        break;
      }
      case "ticket": {
        summaryComponent = (
          <React.Fragment>
            <div className="d-flex justify-content-center align-items-center py-1">
              <div className="flex-shrink-0">
                <label className="mb-0">{t("Seat(s)")}</label>
              </div>
              <div className="flex-fill"></div>
              <div className="flex-shrink-0">
                <h6 className="mb-0 text-right">
                  {adultPersonCount}
                  {`${adultPersonCount === 1 ? " Person" : " People"}`}
                </h6>
              </div>
            </div>
            {orderDateRange.beginingDate ? (
              <div className="d-flex justify-content-center align-items-center py-1">
                <div className="flex-shrink-0">
                  <label className="mb-0">{t("Date")}</label>
                </div>
                <div className="flex-fill"></div>
                <div className="flex-shrink-0">
                  <h6 className="mb-0 text-right">
                    {orderDateRange.beginingDate.format("ll")}
                  </h6>
                </div>
              </div>
            ) : null}
            {isEligibleForPartialPayment === true ? (
              <React.Fragment>
                <label className="checkbox-title mb-0 mt-2">
                  {t("Select Payment Type")}
                </label>
                <div className="form-check">
                  <input
                    checked={paymentTerm === "deposit"}
                    type="checkbox"
                    className="form-check-input mt-2"
                    id="pay-advance"
                    disabled={isOrderUpdating || mode === "payment-info"}
                    onChange={e =>
                      e.target.checked === true
                        ? UpdatePaymentTerm("deposit")
                        : UpdatePaymentTerm("full")
                    }
                  />

                  <label
                    className="form-check-label"
                    htmlFor="pay-advance"
                    style={{ userSelect: "none" }}
                  >
                    {t("Pay 50% advance now")}
                  </label>
                </div>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        );

        formContent = (
          <div className="pt-2 pt-lg-4 ml-0 ml-lg-0 mr-0 mr-lg-3 px-3">
            <h4 className="mb-1">{t("NO. OF PEOPLE")}</h4>
            <hr className="my-0" />
            <div className="form-row py-3 date-wrapper">
              <div className="col-12 col-md-6 px-3 p-2 px-md-2 py-md-1">
                <div className="form-group">
                  <label htmlFor="ticket-adultPersonCount">
                    {t("Pick number of people")}
                  </label>
                  <select
                    className="form-control"
                    value={adultPersonCount}
                    onChange={e =>
                      setAdultPersonCount(parseInt(e.target.value))
                    }
                    id="ticket-adultPersonCount"
                  >
                    {new Array(15).fill(null).map((item, index) => (
                      <option value={index + 1}>
                        {index + 1}
                        {`${index + 1 === 1 ? " Person" : " People"}`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <h4 className="pt-2 mb-1">{t("PICK YOUR DATE")}</h4>
            <hr className="my-0" />
            <div className="form-row py-3 date-wrapper">
              <div className="col-auto mx-auto mx-lg-0">
                <DayPickerSingleDateController
                  hideKeyboardShortcutsPanel
                  numberOfMonths={1}
                  date={orderDateRange.beginingDate}
                  onDateChange={date => {
                    setOrderDateRange({
                      beginingDate: date
                    });
                  }}
                  enableOutsideDays={true}
                  isOutsideRange={day => {
                    return day.isBefore(moment());
                  }}
                />
              </div>
            </div>
          </div>
        );

        break;
      }
      case "accomodation": {
        const roomCap = props.local.capacityMaster.find(
          f => f.value === roomCapacity
        );

        summaryComponent = (
          <React.Fragment>
            <div className="d-flex justify-content-center align-items-center py-1">
              <div className="flex-shrink-0">
                <label
                  className="mb-0 truncate"
                  style={{ maxWidth: 125, marginTop: 5 }}
                >
                  {context.package.rooms[selectedRoomIndex].roomType}
                  {t("Room")}
                </label>
              </div>
              <div className="flex-fill"></div>
              <div className="flex-shrink-0 text-right">
                <h6 className="mb-0 text-right pl-2">
                  {GetFormattedPrice(
                    context.package.rooms[selectedRoomIndex].effectivePrice,
                    props.selectedCurrency
                  )}{" "}
                  <small>{t("/ Night")}</small>
                </h6>
              </div>
            </div>
            {roomCap ? (
              <>
                <div className="d-flex justify-content-center align-items-center py-1">
                  <div className="flex-shrink-0">
                    <label className="mb-0">{t("Adult")}</label>
                  </div>
                  <div className="flex-fill"></div>
                  <div className="flex-shrink-0 text-right">
                    <h6 className="mb-0 text-right">
                      {GetFormattedPrice(
                        context.package.rooms[selectedRoomIndex].effectivePrice,
                        props.selectedCurrency
                      )}{" "}
                      {t("X")}
                      {adultPersonCount} <small>{t("Person(s)")}</small>
                    </h6>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center py-1">
                  <div className="flex-shrink-0">
                    <label className="mb-0">{t("Children")}</label>
                  </div>
                  <div className="flex-fill"></div>
                  <div className="flex-shrink-0 text-right">
                    <h6 className="mb-0 text-right">
                      {t("Upto")}
                      {childCount}
                    </h6>
                  </div>
                </div>
              </>
            ) : null}
            {extraBedCount > 0 ? (
              <div className="d-flex justify-content-center align-items-center py-1">
                <div className="flex-shrink-0">
                  <label className="mb-0">{t("Extra Bed")}</label>
                </div>
                <div className="flex-fill"></div>
                <div className="flex-shrink-0 text-right">
                  <h6 className="mb-0 text-right">
                    {GetFormattedPrice(
                      context.package.rooms[selectedRoomIndex]
                        .effectivePricePerExtraBed,
                      props.selectedCurrency
                    )}{" "}
                    {t("X")}
                    {extraBedCount}
                    {t("Nos.")}
                  </h6>
                </div>
              </div>
            ) : null}
            {orderDateRange.beginingDate ? (
              <div className="d-flex justify-content-center align-items-center py-1">
                <div className="flex-shrink-0">
                  <label className="mb-0">
                    <label className="mb-0">
                      {orderDateRange.beginingDate &&
                        orderDateRange.beginingDate.format("ll")}
                    </label>
                    <label className="px-1 mb-0">{t("-")}</label>
                    <label className="mb-0">
                      {orderDateRange.endDate &&
                        orderDateRange.endDate.format("ll")}
                    </label>
                  </label>
                </div>
                <div className="flex-fill"></div>
                <div className="flex-shrink-0 text-right">
                  <h6 className="mb-0">{`${noOfDays} Night${
                    noOfDays > 1 ? "s" : ""
                  }`}</h6>
                </div>
              </div>
            ) : null}
            {isEligibleForPartialPayment === true ? (
              <React.Fragment>
                <label className="checkbox-title mb-0 mt-2">
                  {t("Select Payment Type")}
                </label>
                <div className="form-check">
                  <input
                    checked={paymentTerm === "deposit"}
                    type="checkbox"
                    className="form-check-input mt-2"
                    id="pay-advance"
                    disabled={isOrderUpdating || mode === "payment-info"}
                    onChange={e =>
                      e.target.checked === true
                        ? UpdatePaymentTerm("deposit")
                        : UpdatePaymentTerm("full")
                    }
                  />

                  <label
                    className="form-check-label"
                    htmlFor="pay-advance"
                    style={{ userSelect: "none" }}
                  >
                    {t("Pay 50% advance now")}
                  </label>
                </div>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        );

        formContent = (
          <div className="pt-2 pt-lg-4 ml-0 ml-lg-0 mr-0 mr-lg-3 px-3">
            <h4 className="pt-2 mb-1">{t("PICK YOUR DATE")}</h4>
            <hr className="my-0" />
            <div className="form-row py-3 date-wrapper">
              <div className="col-12">
                <DateRangePicker
                  keepOpenOnDateSelect
                  hideKeyboardShortcutsPanel
                  small={window.matchMedia("(max-width: 400px)").matches}
                  block={window.matchMedia("(max-width: 400px)").matches}
                  numberOfMonths={
                    window.matchMedia("(max-width: 400px)").matches === true
                      ? 1
                      : 2
                  }
                  startDate={orderDateRange.beginingDate}
                  displayFormat="ll"
                  startDateId="your_unique_start_date_id"
                  endDate={orderDateRange.endDate}
                  startDatePlaceholderText="Check-In"
                  endDatePlaceholderText="Check-Out"
                  endDateId="your_unique_end_date_id"
                  onDatesChange={({ startDate, endDate }) =>
                    setOrderDateRange({
                      beginingDate: startDate,
                      endDate: endDate
                    })
                  }
                  focusedInput={focusedDateRangeInput}
                  onFocusChange={focusedInput =>
                    updateFocusedDateRangeInput(focusedInput)
                  }
                />
              </div>
            </div>
            <h4 className="mb-1">{t("ROOM CHOICE")}</h4>
            <hr className="my-0" />
            <div className="form-row py-3 date-wrapper">
              {context.package.rooms.map((room, index) => (
                <div
                  key={index}
                  onClick={() => {
                    controllers.marketing.setSelectedRoomIndex(index);
                    setRoomCapacity("");
                  }}
                  className="col-12 col-md-6 px-3 p-2 px-md-2 py-md-1"
                >
                  <div
                    className={` form-row pt-2 ${
                      selectedRoomIndex === index
                        ? "selected-details-tile"
                        : "details-tile"
                    }`}
                  >
                    <div className="col-8 pl-3">
                      <div>
                        <label className="mb-0">{room.roomType}</label>
                      </div>
                      <label className="amount">
                        {context.package.currency} {room.effectivePrice}
                        {t("/ Person")}
                      </label>
                    </div>
                    <div className="col-4">
                      {/* <input type="number" className="form-control" /> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <h4 className="pt-2 mb-1">{t("ROOM CAPACITY")}</h4>
            <hr className="my-0" />
            <div className="form-row py-3">
              <div className="col-12">
                {context.package.rooms[selectedRoomIndex].capacityInfo
                  .map((capacity, index) => {
                    const roomInfo = props.local.capacityMaster.find(
                      f => f.value === capacity
                    );

                    if (!roomInfo) {
                      return null;
                    }
                    return (
                      <div key={index} className="custom-control custom-radio">
                        <input
                          type="radio"
                          id={`Radio${index}`}
                          name="customRadio"
                          checked={roomCapacity === roomInfo.value}
                          onChange={() => {
                            setRoomCapacity(roomInfo.value);
                            setAdultPersonCount(roomInfo.adult);
                            setChildCount(roomInfo.child);
                          }}
                          className="custom-control-input"
                        />

                        <label
                          className="custom-control-label"
                          htmlFor={`Radio${index}`}
                          style={{ fontSize: 12, paddingTop: 1 }}
                        >
                          {roomInfo && roomInfo.label}
                        </label>
                      </div>
                    );
                  })
                  .filter(r => r !== null)}
              </div>
            </div>
            {selectedRoomIndex > -1 &&
            roomCapacity &&
            context.package.rooms[selectedRoomIndex].extraBedAvailable ===
              true ? (
              <>
                <h4 className="pt-2 mb-1">
                  {t("EXTRA BED OPTIONS FOR CHILDREN")}
                </h4>
                <hr className="my-0" />
                <div className="form-row py-3">
                  <div className="col-12">
                    {new Array(childCount + 1).fill(null).map((item, index) => {
                      let label = "No Extra Bed";
                      if (index > 0) {
                        label = `${index} Bed${index > 1 ? "s" : ""}`;
                      }
                      return (
                        <button
                          onClick={() => setExtraBedCount(index)}
                          key={index}
                          className={`extra-bed-option ${
                            extraBedCount === index ? "selected" : ""
                          }`}
                        >
                          {label}
                        </button>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        );

        break;
      }
      case "transport": {
        noOfDays = noOfDays + 1;
        summaryComponent = (
          <React.Fragment>
            {orderDateRange.beginingDate ? (
              <div className="d-flex justify-content-center align-items-center py-1">
                <div className="flex-shrink-0">
                  <label className="mb-0">
                    <label className="mb-0">
                      {orderDateRange.beginingDate &&
                        orderDateRange.beginingDate.format("ll")}
                    </label>
                    {noOfDays > 1 ? (
                      <>
                        <label className="px-1 mb-0">{t("-")}</label>
                        <label className="mb-0">
                          {orderDateRange.endDate &&
                            orderDateRange.endDate.format("ll")}
                        </label>
                      </>
                    ) : null}
                  </label>
                </div>
                {noOfDays > 0 ? (
                  <>
                    <div className="flex-fill"></div>
                    <div className="flex-shrink-0 text-right">
                      <h6 className="mb-0">{`${noOfDays} Day${
                        noOfDays > 1 ? "s" : ""
                      }`}</h6>
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}
            {transportTariffType ? (
              <>
                <div className="d-flex justify-content-center align-items-center py-1">
                  <div className="flex-shrink-0">
                    <label className="mb-0">{t("Price")}</label>
                  </div>
                  <div className="flex-fill"></div>
                  <div className="flex-shrink-0">
                    <h6 className="mb-0">
                      {context.package.currency}{" "}
                      {context.package[transportTariffType]}{" "}
                      {transportTariffType === "price" ? " / Day" : null}
                      {transportTariffType === "pricePerWay" ? " / Way" : null}
                    </h6>
                  </div>
                </div>
              </>
            ) : null}
            {isEligibleForPartialPayment === true ? (
              <React.Fragment>
                <label className="checkbox-title mb-0 mt-2">
                  {t("Select Payment Type")}
                </label>
                <div className="form-check">
                  <input
                    checked={paymentTerm === "deposit"}
                    type="checkbox"
                    className="form-check-input mt-2"
                    id="pay-advance"
                    disabled={isOrderUpdating || mode === "payment-info"}
                    onChange={e =>
                      e.target.checked === true
                        ? UpdatePaymentTerm("deposit")
                        : UpdatePaymentTerm("full")
                    }
                  />

                  <label
                    className="form-check-label"
                    htmlFor="pay-advance"
                    style={{ userSelect: "none" }}
                  >
                    {t("Pay 50% advance now")}
                  </label>
                </div>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        );

        formContent = (
          <div className="pt-2 pt-lg-4 ml-0 ml-lg-0 mr-0 mr-lg-3 px-3">
            <h4 className="pt-2 mb-1">{t("CHOOSE YOUR TARIFF")}</h4>
            <hr className="my-0" />
            <div className="form-row py-3">
              {context.package.price ? (
                <div className="col-12 col-md-6 px-3 p-2 px-md-2 py-md-1">
                  <div
                    className={`form-row pt-2 ${
                      transportTariffType === "price"
                        ? "selected-details-tile"
                        : "details-tile"
                    }`}
                    onClick={() => setTransportTariffType("price")}
                  >
                    <div className="col-8 pl-3">
                      <div>
                        <label className="mb-0">{t("Per Day")}</label>
                      </div>
                      <label className="amount">
                        {context.package.currency} {context.package.price}
                        {t("/ 8 hrs")}
                      </label>
                    </div>
                    <div className="col-4">
                      {/* <input type="number" className="form-control" /> */}
                    </div>
                  </div>
                </div>
              ) : null}
              {context.package.pricePerWay ? (
                <div className="col-12 col-md-6 px-3 p-2 px-md-2 py-md-1">
                  <div
                    className={`form-row pt-2 ${
                      transportTariffType === "pricePerWay"
                        ? "selected-details-tile"
                        : "details-tile"
                    }`}
                    onClick={() => {
                      setTransportTariffType("pricePerWay");
                      setOrderDateRange({
                        beginingDate: orderDateRange.beginingDate,
                        endDate: orderDateRange.beginingDate
                      });
                    }}
                  >
                    <div className="col-8 pl-3">
                      <div>
                        <label className="mb-0">{t("Per Way")}</label>
                      </div>
                      <label className="amount">
                        {context.package.currency} {context.package.pricePerWay}{" "}
                        {t("/ way")}
                      </label>
                    </div>
                    <div className="col-4">
                      {/* <input type="number" className="form-control" /> */}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <h4 className="pt-2 mb-1">{t("PICK YOUR DATE")}</h4>
            <hr className="my-0" />
            <div className="form-row py-3 date-wrapper">
              <div className="col-12">
                {transportTariffType === "price" ? (
                  <DateRangePicker
                    minimumNights={0}
                    keepOpenOnDateSelect
                    hideKeyboardShortcutsPanel
                    small={window.matchMedia("(max-width: 400px)").matches}
                    block={window.matchMedia("(max-width: 400px)").matches}
                    numberOfMonths={
                      window.matchMedia("(max-width: 400px)").matches === true
                        ? 1
                        : 2
                    }
                    startDate={orderDateRange.beginingDate}
                    displayFormat="ll"
                    startDateId="your_unique_start_date_id"
                    endDate={orderDateRange.endDate}
                    endDateId="your_unique_end_date_id"
                    onDatesChange={({ startDate, endDate }) =>
                      setOrderDateRange({
                        beginingDate: startDate,
                        endDate: endDate
                      })
                    }
                    focusedInput={focusedDateRangeInput}
                    onFocusChange={focusedInput =>
                      updateFocusedDateRangeInput(focusedInput)
                    }
                  />
                ) : (
                  <Zoom duration={350}>
                    <div style={{ height: 300 }}>
                      <DayPickerSingleDateController
                        hideKeyboardShortcutsPanel
                        numberOfMonths={1}
                        date={orderDateRange.beginingDate}
                        onDateChange={date => {
                          setOrderDateRange({
                            beginingDate: date,
                            endDate: date
                          });
                        }}
                        enableOutsideDays={true}
                        isOutsideRange={day => {
                          return day.isBefore(moment());
                        }}
                      />
                    </div>
                  </Zoom>
                )}
              </div>
            </div>
          </div>
        );

        break;
      }
      default: {
        formContent = null;
        summaryComponent = null;
        break;
      }
    }
  }

  let wizardPage;
  switch (mode) {
    case "receipt": {
      wizardPage = (
        <Fade duration={700}>
          <div className="pt-2 pt-lg-4 ml-0 ml-lg-0 mr-0 mr-lg-3 px-3 pb-4 w-100 h-100 d-flex justify-content-center align-items-center">
            <small className="check">
              <img
                height="185"
                src={require("../../assets/images/happiness.png")}
                alt=""
              />
            </small>
          </div>
        </Fade>
      );

      break;
    }
    case "payment-info": {
      let paymentForm;
      switch (paymentProvider) {
        case "stripe": {
          paymentForm = (
            <Elements>
              <CheckoutForm
                orderId={orderContext && orderContext._id}
                onMount={setStripeProps}
              />
            </Elements>
          );

          break;
        }
        case "paypal": {
          paymentForm = (
            <div className="checkout">
              <p style={{ fontSize: 12 }}>
                {t("Click the below button to complete checkout on PayPal")}
              </p>
              <PaypalButton
                amount={summaryMetrics.amountPayable}
                currencyCode={orderContext.currencyId}
                onPay={() =>
                  controllers.setGlobalLoader("Processing...", false)
                }
                onApprove={(data, actions) =>
                  ProceedCheckout({ data, actions })
                }
                onComplete={() => controllers.clearGlobalMessage()}
              />
            </div>
          );

          break;
        }
        default: {
          paymentForm = null;
          break;
        }
      }

      wizardPage = (
        <Fade key={789} duration={700}>
          <div className="payment-info-wrapper pt-1 ml-0 ml-lg-0 mr-0 mr-lg-3 px-3">
            <Nav tabs>
              {(() => {
                if (paymentProvider === "paypal") {
                  return (
                    <NavItem>
                      <NavLink
                        href="#"
                        onClick={() => switchPaymentProvider("paypal")}
                        active={paymentProvider === "paypal"}
                      >
                        <i className="fab fa-paypal pr-2"></i>
                        {t("PayPal")}
                      </NavLink>
                    </NavItem>
                  );
                }

                return (
                  <NavItem>
                    <NavLink
                      href="#"
                      onClick={() => switchPaymentProvider("stripe")}
                      active={paymentProvider === "stripe"}
                    >
                      <i className="fas fa-credit-card pr-2"></i>
                      {t("Credit Card")}
                    </NavLink>
                  </NavItem>
                );
              })()}
            </Nav>
            <div className="payment-info-card p-3">{paymentForm}</div>
          </div>
        </Fade>
      );

      break;
    }
    case "billing-info": {
      wizardPage = (
        <Fade duration={700}>
          <div className="pt-2 pt-lg-4 ml-0 ml-lg-0 mr-0 mr-lg-3 px-3">
            <h4 className="mb-1">{t("Billing Address")}</h4>
            <hr className="my-0" />
            <div className="form-row pt-2">
              <div className="form-group col-12">
                <label htmlFor="adult-count">{t("Billing Name")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("(Required)")}
                  autoFocus={true}
                  value={billingName}
                  onChange={e => setBillingName(e.target.value)}
                />
              </div>
            </div>
            <div className="form-row pt-2">
              <div className="form-group col-12">
                <label htmlFor="adult-count">{t("Address Line 1")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("(Required)")}
                  value={addressLine1}
                  onChange={e => setAddressLine1(e.target.value)}
                />
              </div>
              <div className="form-group col-12">
                <label htmlFor="adult-count">{t("Address Line 2")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("(Optional)")}
                  value={addressLine2}
                  onChange={e => setAddressLine2(e.target.value)}
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="adult-count">{t("Town / City")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("(Required)")}
                  value={city}
                  onChange={e => setCity(e.target.value)}
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="child-count">{t("State")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("(Required)")}
                  value={state}
                  onChange={e => updateState(e.target.value)}
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="child-count">{t("Postal Code")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("(Required)")}
                  value={postalCode}
                  onChange={e => setPostalCode(e.target.value)}
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="child-count">{t("Country")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("(Required)")}
                  value={country}
                  onChange={e => setCountry(e.target.value)}
                />
              </div>
            </div>
            <div className="form-row pt-2">
              <div className="form-group col-6">
                <label htmlFor="adult-count">{t("Mobile Number")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("(Required)")}
                  value={phoneNumber}
                  onChange={e => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="form-group col-6">
                <label htmlFor="child-count">{t("Alt. Mobile Number")}</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={t("(Optional)")}
                  value={altPhoneNumber}
                  onChange={e => setAltPhoneNumber(e.target.value)}
                />
              </div>
            </div>
            <h4 className="mb-1 mt-2">{t("Select Payment Method")}</h4>
            <hr className="my-0" />
            <div className="form-row">
              <div className="form-group payment-provider-opts col-12">
                <div
                  onClick={() => switchPaymentProvider("stripe")}
                  className={`provider-item ${
                    paymentProvider === "stripe" ? "selected" : ""
                  }`}
                >
                  <input
                    type="radio"
                    checked={paymentProvider === "stripe"}
                    readOnly={true}
                  />

                  <i className="fas fa-credit-card"></i>
                  <span>{t("Credit Card")}</span>
                </div>
                {// Paypal support only for USD
                ["USD"].indexOf(orderContext.currencyId) > -1 ? (
                  <div
                    onClick={() => switchPaymentProvider("paypal")}
                    className={`provider-item ${
                      paymentProvider === "paypal" ? "selected" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      checked={paymentProvider === "paypal"}
                      readOnly={true}
                    />

                    <i className="fab fa-paypal"></i>
                    <span>{t("PayPal")}</span>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Fade>
      );

      break;
    }
    default: {
      wizardPage = formContent;
      break;
    }
  }

  // Disable inputs until stripe is loaded
  if (mode === "payment-info") {
    if (paymentProvider === "stripe" && isOrderUpdating === false) {
      if (!stripeProps) {
        isOrderUpdating = true;
      }
    }
  }

  const OrderDetailBlock = orderContext ? (
    <div className="thankyou-page-order-detail-block">
      <div>
        <div
          className="order-detail-block-background-image"
          style={{
            backgroundImage: `url('${controllers.resolveAssetAddr(
              orderContext.listingSnapshot.package.defaultCoverArtId
            )}')`
          }}
        >
          <div className="order-status-img-wrapper">
            <img
              height="130"
              src={require("../../assets/images/placed.png")}
              alt=""
            />
          </div>
        </div>
        <div className="order-block-content pt-3 px-2">
          <div className="text-center location-wrapper">
            <i className="fas fa-map-marker-alt pr-1"></i>
            <span>
              {stripAddress(orderContext.listingSnapshot.package.location)}
            </span>
          </div>
          <h4 className="text-center pt-1">
            {orderContext.listingSnapshot.package.title}
          </h4>
          <div className="pt-5 ml-0 ml-lg-0 mr-0 mr-lg-3 px-3 w-100 d-flex justify-content-center align-items-center">
            <small className="check" style={{ transform: "scale(0.7)" }}>
              <svg
                className="checkmark"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 52 52"
              >
                <circle
                  className="checkmark__circle"
                  cx="29"
                  cy="26"
                  r="25"
                  fill="none"
                />

                <path
                  className="checkmark__check"
                  fill="none"
                  d="M14.1 27.2l7.1 7.2 16.7-16.8"
                />
              </svg>
            </small>
          </div>
          <div className="text-center">
            <label>{t("Your payment is succesful")}</label>
          </div>
        </div>
      </div>
    </div>
  ) : null;

  let receiptUrl = "";
  if (mode === "receipt" && orderContext) {
    if (orderContext.transactions && orderContext.transactions.length > 0) {
      receiptUrl =
        orderContext.transactions[orderContext.transactions.length - 1]
          .receiptUrl;
    }
  }

  let activeCoupon = null;
  if (orderContext) {
    if (orderContext.coupons && orderContext.coupons.length > 0) {
      activeCoupon = orderContext.coupons[0];
    }
  }

  return (
    <div className="modal-body pt-0 pt-lg-3 px-0">
      <div className="form-row">
        <div className="col-12 col-lg-4 order-last order-lg-first order-summary-wrapper px-3">
          <div className="img-wrapper d-none d-lg-block">
            {mode !== "receipt" ? (
              <img
                width="100%"
                src={require("./../../assets/images/Hans.png")}
                alt=""
              />
            ) : (
              OrderDetailBlock
            )}
          </div>
          {mode !== "receipt" ? (
            <>
              <div className="order-summary-card mt-2 pt-3 px-3">
                <Fade duration={700}>
                  <>
                    <div className="text-center">
                      {activeCoupon ? (
                        <h5>{t("Coupon Applied")}</h5>
                      ) : (
                        <h5>{t("Have a Promo Code?")}</h5>
                      )}
                    </div>
                    <hr />
                    {activeCoupon ? (
                      <div className="d-flex justify-content-center align-items-center py-0">
                        <div className="flex-fill form-group d-flex flex-column justify-content-center align-items-center">
                          <small className="check">
                            <svg
                              className="checkmark"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 52 52"
                            >
                              <circle
                                className="checkmark__circle"
                                cx="29"
                                cy="26"
                                r="25"
                                fill="none"
                              />

                              <path
                                className="checkmark__check"
                                fill="none"
                                d="M14.1 27.2l7.1 7.2 16.7-16.8"
                              />
                            </svg>
                          </small>
                          <b className="pt-2">{activeCoupon.couponCode}</b>
                          {mode !== "receipt" ? (
                            <button
                              disabled={isOrderUpdating}
                              onClick={DetatchCoupon}
                              style={{ fontSize: 12 }}
                              className="btn btn-link text-danger"
                            >
                              {t("Remove")}
                            </button>
                          ) : null}
                        </div>
                      </div>
                    ) : (
                      <form onSubmit={ApplyCoupon}>
                        <div className="d-flex justify-content-center align-items-center py-0">
                          <div className="flex-fill form-group">
                            <input
                              readOnly={mode === "receipt" || isOrderUpdating}
                              type="text"
                              value={couponCode}
                              onChange={e =>
                                setCouponCode(e.target.value.toUpperCase())
                              }
                              className="form-control form-control-sm rounded-lg"
                              maxLength={9}
                              placeholder={t("e.g. TB-####")}
                            />
                          </div>
                        </div>
                        <div className="d-flex justify-content-center align-items-center pb-3">
                          <div className="flex-fill">
                            <button
                              disabled={couponCode === "" || isOrderUpdating}
                              type="submit"
                              style={{ fontSize: 12 }}
                              className="btn btn-warning-gradient btn-block btn-sm"
                            >
                              <i className="fas fa-tags pr-1"></i>
                              {t("Apply Coupon")}
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </>
                </Fade>
              </div>
              <div className="order-summary-card mt-2 pt-3 px-3">
                {isLoading === false ? (
                  <Fade duration={700}>
                    <>
                      <div className="text-center">
                        <h5>{t("Booking Summary")}</h5>
                      </div>
                      {summaryComponent}
                      <hr />
                      <div className="d-flex justify-content-center align-items-center py-0">
                        <div className="flex-shrink-0">
                          <h6 className="mb-0 font-weight-normal">
                            {t("Gross Amount:")}
                          </h6>
                        </div>
                        <div className="flex-fill"></div>
                        <div className="flex-shrink-0">
                          <label className="amount mb-0 font-weight-normal">
                            {GetFormattedPrice(
                              summaryMetrics.grossAmount,
                              props.selectedCurrency
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center py-0">
                        <div className="flex-shrink-0">
                          <h6 className="mb-0 font-weight-normal">
                            {t("Service Charges:")}
                          </h6>
                        </div>
                        <div className="flex-fill"></div>
                        <div className="flex-shrink-0">
                          <label className="amount mb-0 font-weight-normal">
                            {GetFormattedPrice(
                              summaryMetrics.serviceCharges,
                              props.selectedCurrency
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center align-items-center py-0">
                        <div className="flex-shrink-0">
                          <h6 className="mb-0 font-weight-normal">
                            {t("(-) Discounts:")}
                          </h6>
                        </div>
                        <div className="flex-fill"></div>
                        <div className="flex-shrink-0">
                          <label className="amount mb-0 font-weight-normal">
                            {GetFormattedPrice(
                              summaryMetrics.discount,
                              props.selectedCurrency
                            )}
                          </label>
                        </div>
                      </div>
                      <div
                        className={`d-flex justify-content-center align-items-center pt-3 ${
                          summaryMetrics &&
                          summaryMetrics.amountPayable ===
                            summaryMetrics.netAmount
                            ? "pb-3"
                            : ""
                        }`}
                      >
                        <div className="flex-shrink-0">
                          <h6 className="mb-0">{t("NET Amount:")}</h6>
                        </div>
                        <div className="flex-fill"></div>
                        <div className="flex-shrink-0">
                          <label className="amount mb-0">
                            {GetFormattedPrice(
                              summaryMetrics.netAmount,
                              props.selectedCurrency
                            )}
                          </label>
                        </div>
                      </div>
                      {summaryMetrics &&
                      summaryMetrics.amountPayable !==
                        summaryMetrics.netAmount ? (
                        <div className="d-flex justify-content-center align-items-center pb-3">
                          <div className="flex-shrink-0">
                            <h6 className="mb-0">{t("Payable Now:")}</h6>
                          </div>
                          <div className="flex-fill"></div>
                          <div className="flex-shrink-0">
                            <label
                              className="amount mb-0"
                              style={{ fontSize: 16 }}
                            >
                              {GetFormattedPrice(
                                summaryMetrics.amountPayable,
                                props.selectedCurrency
                              )}
                            </label>
                          </div>
                        </div>
                      ) : null}
                    </>
                  </Fade>
                ) : (
                  <div className="placeloader">
                    <div className="ph-item mh-100">
                      <div className="ph-col-12">
                        <div className="ph-row">
                          <div className="ph-col-12 big"></div>
                        </div>
                        <div className="ph-row">
                          <div className="ph-col-4"></div>
                          <div className="ph-col-6 empty"></div>
                        </div>
                        <div className="ph-row">
                          <div className="ph-col-8"></div>
                          <div className="ph-col-4 empty"></div>
                        </div>
                        <div className="ph-row">
                          <div className="ph-col-4"></div>
                          <div className="ph-col-8 empty"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : null}
          <div className="d-lg-none pt-3">
            <div className="container-fluid">
              <div className="row">
                {/** @description Receipt only for tr made with stripe */}
                {mode === "receipt" &&
                ["stripe"].indexOf(paymentProvider) > -1 ? (
                  <div className="col-12 pb-3">
                    <a
                      href={receiptUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      disabled={isOrderUpdating}
                      className={`btn btn-light btn-block btn-md`}
                    >
                      {t("View Invoice")}

                      <i className="fas ml-2 fa-external-link-alt"></i>
                    </a>
                  </div>
                ) : null}
                {mode === "payment-info" &&
                paymentProvider === "paypal" ? null : (
                  <div className="col-12">
                    <button
                      disabled={isOrderUpdating}
                      onClick={() => ProceedCheckout()}
                      type="button"
                      className={`btn btn-success-gradient btn-block ${
                        mode === "payment-info" || mode === "receipt"
                          ? "btn-md"
                          : "btn-sm"
                      }`}
                    >
                      {isOrderUpdating === false ? (
                        GetCheckoutButtonTitle(
                          mode,
                          summaryMetrics,
                          props.selectedCurrency,
                          t
                        )
                      ) : (
                        <span>
                          <i className="fas fa-spin fa-circle-notch"></i>
                          {t("Please wait...")}
                        </span>
                      )}
                    </button>
                  </div>
                )}

                {mode !== "order-setup" && mode !== "receipt" ? (
                  <div className="col-12">
                    <button
                      disabled={isOrderUpdating}
                      onClick={() => GoBack()}
                      type="button"
                      className="btn btn-default btn-sm btn-block"
                    >
                      {t("Go Back")}
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 order-first order-lg-last order-details-wrapper pt-0 pb-2 pt-lg-2">
          {mode !== "receipt" ? (
            <div
              className="mini-img-wrapper p-0 d-lg-none"
              style={{
                backgroundImage: `url('${require("./../../assets/images/Totravel.png")}')`
              }}
            >
              <div className="text-right close-button-wrapper px-2 pt-2 pr-3">
                <button onClick={props.toggle}>
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
          ) : (
            <div
              className="mini-img-wrapper thankyou-page-order-detail-block p-0 d-lg-none"
              style={{
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundImage: `url('${controllers.resolveAssetAddr(
                  orderContext.listingSnapshot.package.defaultCoverArtId
                )}')`
              }}
            >
              <div className="order-status-img-wrapper no-radius">
                <img
                  height="130"
                  src={require("../../assets/images/placed.png")}
                  alt=""
                />
              </div>
              <div className="text-right close-button-wrapper px-2 pt-2">
                <button onClick={props.toggle}>
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
          )}

          {isLoading === false ? (
            <Fade duration={700}>
              <div>
                <div className="text-right close-button-wrapper d-none d-lg-block px-3">
                  <button onClick={props.toggle}>
                    <i className="fas fa-times"></i>
                  </button>
                </div>
                <div className="text-center px-3 pt-3 pt-lg-0">
                  {// Show check bar (only in receipt)
                  mode === "receipt" ? (
                    <div className="mb-3 d-flex d-lg-none justify-content-center align-items-center">
                      <small className="check">
                        <svg
                          className="checkmark"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 52 52"
                        >
                          <circle
                            className="checkmark__circle"
                            cx="29"
                            cy="26"
                            r="25"
                            fill="none"
                          />

                          <path
                            className="checkmark__check"
                            fill="none"
                            d="M14.1 27.2l7.1 7.2 16.7-16.8"
                          />
                        </svg>
                      </small>
                    </div>
                  ) : null}
                  <h3>{t(GetTitle(mode))}</h3>
                  <p>{t(GetDescription(mode, orderContext.type))}</p>
                </div>
                {wizardPage}
                <div className="px-2 button-wrapper d-none d-lg-block">
                  <div className="container-fluid">
                    <div className="row">
                      {/** @description Receipt only for tr made with stripe */}
                      {mode === "receipt" &&
                      ["stripe"].indexOf(paymentProvider) > -1 ? (
                        <div className="col">
                          <a
                            href={receiptUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={`btn btn-light btn-block btn-sm`}
                          >
                            {t("View Invoice")}

                            <i className="fas ml-2 fa-external-link-alt"></i>
                          </a>
                        </div>
                      ) : null}
                      {mode === "payment-info" &&
                      paymentProvider === "paypal" ? null : (
                        <div
                          className={`col${mode !== "receipt" ? "-12" : ""}`}
                        >
                          <button
                            disabled={isOrderUpdating}
                            onClick={() => ProceedCheckout()}
                            type="button"
                            className={`btn btn-success-gradient btn-block ${
                              mode === "payment-info" ? "btn-md" : "btn-sm"
                            }`}
                          >
                            {isOrderUpdating === false ? (
                              GetCheckoutButtonTitle(
                                mode,
                                summaryMetrics,
                                props.selectedCurrency,
                                t
                              )
                            ) : (
                              <span>
                                <i className="fas fa-spin fa-circle-notch"></i>{" "}
                                {t("Please wait...")}
                              </span>
                            )}
                          </button>
                        </div>
                      )}

                      {mode !== "order-setup" && mode !== "receipt" ? (
                        <div className="col-12">
                          <button
                            disabled={isOrderUpdating}
                            onClick={() => GoBack()}
                            type="button"
                            className="btn btn-default btn-sm btn-block"
                          >
                            {t("Go Back")}
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </Fade>
          ) : (
            <div className="placeloader p-4">
              <div className="ph-item mh-100 mr-3">
                <div className="ph-col-12">
                  <div className="ph-row">
                    <div className="ph-col-2"></div>
                    <div className="ph-col-10 empty"></div>
                    <div className="ph-col-8 big"></div>
                    <div className="ph-col-4 empty big"></div>
                  </div>
                  <div className="ph-row">
                    <div className="ph-col-2"></div>
                    <div className="ph-col-10 empty"></div>
                  </div>
                  <div className="ph-picture"></div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
