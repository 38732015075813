import { useTranslation, withTranslation } from "react-i18next";
import React, { useState } from "react";
import { Fade } from "react-reveal";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Swiper from "react-id-swiper";
import Controller from "../../store/controllers";
import "swiper/css/swiper.min.css";
import jQuery from "jquery";
import {
  isReadMoreRequired,
  stripAddress,
  GetFormattedPrice,
  sliceText
} from "../../resources/Helper";
import { Modal } from "reactstrap";
import { AmenitiesIcon } from "../../resources/AmenititesIcon";
import OrderModal from "../Modals/OrderModal";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import ReactGA from "react-ga";
import { WishlistServices } from "./../../services/Wishlist.services";
import { NETWORK_ERR_MESSAGE } from "../../resources/String";
import { Map, Marker } from "google-maps-react";
import Review from "./../Review";

const SwiperCarousal = props => {
  const params = {
    spaceBetween: 30,
    effect: "fade",
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true
    },

    loop: true,
    autoplay: {
      delay: 2000
    },

    speed: 600,
    grabCursor: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    }
  };

  return (
    <Swiper {...params}>
      {props.data.map((image, index) => (
        <div
          key={index}
          className="carousal-image"
          style={{
            backgroundImage: `url('${Controller.resolveAssetAddr(image)}')`
          }}
        ></div>
      ))}
    </Swiper>
  );
};
const TimeLine = props => {
  const [swiperDate, updateSwiperDate] = useState(null);
  const [swiperContent, updateSwiperContent] = useState(null);
  const { t } = useTranslation();

  const goNext = () => {
    if (swiperDate !== null) {
      swiperDate.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperDate !== null) {
      swiperDate.slidePrev();
    }
  };

  if (swiperContent && swiperDate) {
    swiperContent.controller.control = swiperDate;
    swiperDate.controller.control = swiperContent;
  }

  let timelineDates = {
    centeredSlides: true,
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 30
      },

      768: {
        slidesPerView: 3,
        spaceBetween: 30
      },

      640: {
        slidesPerView: 1,
        spaceBetween: 20
      },

      320: {
        slidesPerView: 1,
        spaceBetween: 0
      }
    },

    touchRatio: 0.2,
    slideToClickedSlide: true,
    controller: {
      control: null
    }
  };

  let timelineContents = {
    grabCursor: true,
    spaceBetween: 10,
    centeredSlides: true,
    breakpoints: {
      1024: {
        slidesPerView: 4,
        spaceBetween: 30
      },

      768: {
        slidesPerView: 3,
        spaceBetween: 30
      },

      640: {
        slidesPerView: 1,
        spaceBetween: 20
      },

      320: {
        slidesPerView: 1,
        spaceBetween: 15
      }
    },

    controller: {
      control: null
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 py-3 title-tile">
          <div className="row">
            <div className="col-4"></div>
            <div className="col-12 col-md-4 d-flex align-items-center justify-content-center">
              <div>
                <h4>{t("Daily Activity")}</h4>
              </div>
            </div>
            <div className="col-12 col-md-4 text-center text-md-right">
              <button
                className="pill-button warning"
                onClick={props.showFullDetails}
              >
                {t("Full Details")}
              </button>
            </div>
          </div>
        </div>
        <>
          <div className="col-12 p-0 timeline-events">
            <div className="timeline">
              <div className="swiper-container timeline-dates">
                <Swiper
                  getSwiper={updateSwiperDate}
                  {...timelineDates}
                  className="swiper-wrapper"
                >
                  {props.data.map((timeline, index) => {
                    let icon = "";
                    if (timeline.type === "food") {
                      if (
                        timeline.category === "dinner" ||
                        timeline.category === "lunch"
                      ) {
                        icon = "fas fa-utensils pr-2";
                      } else {
                        icon = "fas fa-coffee pr-2";
                      }
                    }
                    return (
                      <div key={index} className="text-center">
                        <div style={{ height: 25 }}>
                          <i className={icon} />
                          {timeline.hourFormatted}
                        </div>
                        <small
                          className="text-muted"
                          style={{ textTransform: "uppercase" }}
                        >
                          {timeline.type === "food"
                            ? t(timeline.category)
                            : t("activity")}
                        </small>
                      </div>
                    );
                  })}
                </Swiper>
              </div>

              <div className="swiper-container timeline-contents">
                <Swiper
                  getSwiper={updateSwiperContent}
                  {...timelineContents}
                  className="swiper-wrapper"
                >
                  {props.data.map((timeline, index) => (
                    <div key={index} className="text-center">
                      <div className="py-3 loco">
                        <i className="fas fa-map-marker-alt mr-2"></i>
                        {stripAddress(timeline.location)}
                      </div>
                      <img
                        style={{
                          height: 150,
                          width: "100%",
                          objectFit: "cover",
                          borderRadius: 8,
                          cursor: "pointer"
                        }}
                        src={Controller.resolveAssetAddr(timeline.imageId)}
                        alt="..."
                        onClick={() =>
                          Controller.marketing.setLightBoxImage(
                            timeline.imageId
                          )
                        }
                      />

                      <p className="pt-3 mb-0">
                        {sliceText(timeline.description, 300)}
                      </p>
                      <div className="py-2 text-left">
                        {isReadMoreRequired(timeline.description, 300) ? (
                          <button
                            className="btn btn-link btn-sm"
                            style={{ color: "#01959F" }}
                            onClick={() => {
                              Controller.marketing.setReadMoreContext(
                                timeline.description,
                                timeline.title,
                                timeline.imageId
                              );
                            }}
                          >
                            <i className="fas fa-plus-circle"></i> Read More
                          </button>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </Swiper>
              </div>
              <div className="timeline-buttons-container">
                <div className="timeline-button-next" onClick={goNext}></div>
                <div className="timeline-button-prev" onClick={goPrev}></div>
              </div>
            </div>
          </div>
        </>
      </div>
      {props.stayData.stayLocation ? (
        <div className="amenities">
          <Activity data={props.stayData} local={props.local} />
        </div>
      ) : null}
    </>
  );
};

const MutipleSlidesPerView = props => {
  const params = {
    spaceBetween: 30,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },

    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true
    },

    autoplay: {
      delay: 2000
    },

    grabCursor: true,
    speed: 600,
    breakpoints: {
      1024: {
        slidesPerView: Math.min(4, props.data.length),
        spaceBetween: 30
      },

      768: {
        slidesPerView: Math.min(3, props.data.length),
        spaceBetween: 30
      },

      640: {
        slidesPerView: Math.min(3, props.data.length),
        spaceBetween: 20
      },

      320: {
        slidesPerView: 1,
        spaceBetween: 15
      }
    }
  };

  return (
    <div className="swiper-wrapper">
      <Swiper {...params}>
        {props.data.map((highlight, index) => (
          <div key={index}>
            <div className="product text-center text-md-left">
              <div
                title={highlight.location}
                className="product-label truncate"
              >
                <b>{stripAddress(highlight.location)}</b>
              </div>
              <img
                className="photograph"
                src={Controller.resolveAssetAddr(highlight.imageId)}
                alt="slide 1"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  Controller.marketing.setLightBoxImage(highlight.imageId)
                }
              />

              <div className="product-desc">
                {sliceText(highlight.description, 300)}
              </div>
              <div className="py-2 text-left">
                {isReadMoreRequired(highlight.description, 300) ? (
                  <button
                    className="btn btn-link btn-sm"
                    style={{ color: "#01959F" }}
                    onClick={() => {
                      Controller.marketing.setReadMoreContext(
                        highlight.description,
                        highlight.location,
                        highlight.imageId
                      );
                    }}
                  >
                    <i className="fas fa-plus-circle"></i> Read More
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        ))}
      </Swiper>
    </div>
  );
};

function Activity(props) {
  const { t } = useTranslation();
  const [currentView, updateCurrentView] = useState("slide");
  const { local } = props;
  const params = {
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true
    },

    autoplay: {
      delay: 2000
    },

    grabCursor: true,
    speed: 600
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="row py-2 py-md-0">
          <div className="col-2 text-center d-flex justify-content-center align-items-center">
            <button
              disabled={currentView === "slide"}
              onClick={e => updateCurrentView("slide")}
              className="btn btn-link d-lg-none"
            >
              <i className="fas fa-chevron-left"></i>
            </button>
          </div>
          <div className="col-8 p-0 text-center d-flex justify-content-center align-items-center">
            <div className="d-none d-lg-block py-3">
              <h5 className="ns-title">
                <b>{t("Night Stay")}</b>
              </h5>
            </div>
            <div
              className={`${currentView === "ameneties" && "d-none"} d-lg-none`}
            >
              <h6 className="font-13">{t("Night Stay")}</h6>
              <b>
                {" "}
                <i className="fas fa-map-marker-alt pl-0 pl-lg-2 mr-1 pr-2"></i>
                <span style={{ textTransform: "uppercase" }}>
                  {stripAddress(props.data.stayLocation)}
                </span>
              </b>
            </div>
            <div className={`${currentView === "slide" && "d-none"} d-lg-none`}>
              <h6 className="font-13">{t("Features")}</h6>
              <b>{t("Services & Convenience")}</b>
            </div>
          </div>
          <div className="col-2 text-center d-flex justify-content-center align-items-center">
            <button
              disabled={currentView === "ameneties"}
              onClick={e => updateCurrentView("ameneties")}
              className="btn btn-link d-lg-none"
            >
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="col-12">
        <div className="row" style={{ minHeight: 310 }}>
          <div
            className={`${currentView === "ameneties" &&
              "d-none"} d-lg-block col-12 col-lg-4 p-0`}
          >
            <Swiper {...params}>
              {props.data.stayImages.map((image, index) => (
                <div key={index}>
                  <img
                    className="img-fluid mx-auto d-block"
                    src={Controller.resolveAssetAddr(image.imageId)}
                    alt="slide 1"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      Controller.marketing.setLightBoxImage(image.imageId)
                    }
                  />
                </div>
              ))}
            </Swiper>
          </div>
          <div
            className={`${currentView === "slide" &&
              "d-none"} d-lg-block col-lg-8 location-details`}
          >
            <div className="row mt-4 px-md-5 pb-4">
              <div className="col-12 p-0">
                <div className="truncate pr-5">
                  <i className="fas fa-map-marker-alt pl-3 pl-md-2 pr-2"></i>
                  <b style={{ textTransform: "uppercase" }}>
                    {" "}
                    {stripAddress(props.data.stayLocation)}
                  </b>{" "}
                </div>
                <div
                  className="balloon"
                  style={{
                    backgroundImage: `url('${require("../../assets/images/marketing/moon.svg")}')`
                  }}
                ></div>
              </div>
              {props.data.stayDescription ? (
                <div className="col-12 px-md-2 mt-2">
                  <div className="truncate">
                    <label className="loc-head truncate d-block m-0">
                      {t("About Stay")}
                    </label>
                  </div>
                  <hr style={{ borderColor: "#0916ff2b" }} />
                  <p
                    style={{
                      textAlign: "justify",
                      letterSpacing: "0.03rem",
                      lineHeight: "1.4rem",
                      fontSize: 12
                    }}
                  >
                    {sliceText(props.data.stayDescription, 400)}
                  </p>
                  <div className="pb-1 text-left">
                    {isReadMoreRequired(props.data.stayDescription, 400) ? (
                      <button
                        className="btn btn-link btn-sm"
                        style={{ color: "#2196f3" }}
                        onClick={() => {
                          Controller.marketing.setReadMoreContext(
                            props.data.stayDescription,
                            stripAddress(props.data.stayLocation),
                            props.data.stayImages[0].imageId
                          );
                        }}
                      >
                        <i className="fas fa-plus-circle"></i>
                        {t("Read More")}
                      </button>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {props.data.internetFeatures.length !== 0 ? (
                <div className="col-12 col-md px-md-2 mt-2 ">
                  <div className="truncate">
                    <label className="loc-head truncate d-block m-0">
                      {t("Internet Access")}
                    </label>
                  </div>
                  <hr style={{ borderColor: "#0916ff2b" }} />
                  <ul>
                    {props.data.internetFeatures.map((feature, index) => {
                      const featureItem = local.internetaccessMaster.find(
                        f => f.value === feature
                      );

                      return (
                        <li className="loc-detail-info" key={index}>
                          {featureItem && t(featureItem.label)}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
              {props.data.amenities.length !== 0 ? (
                <div className="col-12 col-md px-md-2 mt-2">
                  <div className="truncate">
                    <label className="loc-head truncate d-block m-0">
                      {t("Services & Conveniences")}
                    </label>
                  </div>
                  <hr style={{ borderColor: "#0916ff2b" }} />
                  <ul>
                    {props.data.amenities.map((feature, index) => {
                      const featureItem = local.accomodationAmenitiesMaster.find(
                        f => f.value === feature
                      );

                      return (
                        <li className="loc-detail-info" key={index}>
                          {featureItem && t(featureItem.label)}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
              {props.data.access.length !== 0 ? (
                <div className="col-12 col-md px-md-2 mt-2">
                  <div className="truncate">
                    <label className="loc-head truncate d-block m-0">
                      {t("Access")}
                    </label>
                  </div>
                  <hr style={{ borderColor: "#0916ff2b" }} />
                  <ul>
                    {props.data.access.map((feature, index) => {
                      const featureItem = local.accomodationAccessMaster.find(
                        f => f.value === feature
                      );

                      return (
                        <li className="loc-detail-info" key={index}>
                          {featureItem && t(featureItem.label)}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const TripDetailsPage = withTranslation()(
  class TripDetailsPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedDayTitle: "",
        selectedDayActivities: [],
        isFullDetailShown: false,
        roomDetails: "general",
        vehicleDetails: "general",
        isOrderModalOpen: false,
        isWishlistBusy: false,
        selectedActivityTab: "listing"
      };

      this.modalToggle = this.modalToggle.bind(this);
      this.showFullDetails = this.showFullDetails.bind(this);
      this.switchRoomType = this.switchRoomType.bind(this);
      this.switchVehicleType = this.switchVehicleType.bind(this);
      this.handleContactTripbonder = this.handleContactTripbonder.bind(this);
      this.processBooking = this.processBooking.bind(this);
      this.addToWishlist = this.addToWishlist.bind(this);
    }
    addToWishlist() {
      const { local, context, history } = this.props;
      console.log(local);
      if (!local.isAuthenticated) {
        history.push(`/auth/login/?returnUrl=${window.location.pathname}`);
        return;
      }

      this.setState(
        {
          isWishlistBusy: true
        },

        () =>
          setTimeout(() => {
            if (context.hasAddedToWishlist === true) {
              // Call remove service
              WishlistServices.RempoveWishlist(context._id)
                .then(response => {
                  Controller.marketing.updatePackageWishlistOption(false);
                  this.setState({
                    isWishlistBusy: false
                  });
                })
                .catch(err => {
                  Controller.setGlobalError(
                    err.message || NETWORK_ERR_MESSAGE,
                    true
                  );

                  this.setState({
                    isWishlistBusy: false
                  });
                });
            } else {
              // Call add service
              const payload = {
                listingId: context._id
              };

              WishlistServices.AddToWishlist(payload)
                .then(response => {
                  Controller.marketing.updatePackageWishlistOption(true);
                  this.setState({
                    isWishlistBusy: false
                  });
                })
                .catch(err => {
                  Controller.setGlobalError(
                    err.message || NETWORK_ERR_MESSAGE,
                    true
                  );

                  this.setState({
                    isWishlistBusy: false
                  });
                });
            }
          }, 800)
      );
    }
    switchRoomType(type) {
      this.setState({
        roomDetails: type
      });
    }
    switchVehicleType(type) {
      this.setState({
        vehicleDetails: type
      });
    }
    modalToggle() {
      this.setState({
        selectedDayTitle: "",
        selectedDayActivities: [],
        isFullDetailShown: !this.state.isFullDetailShown
      });
    }

    componentDidMount() {
      let pageSearchQuery = queryString.parse(window.location.search);
      let isCheckout = pageSearchQuery.checkout ? true : false;

      if (isCheckout === true) {
        setTimeout(() => {
          this.processBooking();
        }, 1000);
      }

      const scrollableId = this.props.scrollableId || "body";
      const spyOffset = this.props.spyOffset || 150;
      jQuery(scrollableId).scrollspy({
        target: "#day-navbar",
        offset: spyOffset
      });
    }

    showFullDetails(timeline, title) {
      this.setState({
        selectedDayTitle: title,
        selectedDayActivities: timeline,
        isFullDetailShown: true
      });
    }

    handleContactTripbonder() {
      ReactGA.event({
        category: "User",
        action: "Clicked on Contact Tripbonder"
      });

      const { context, local } = this.props;
      Controller.chat.startConversation([
        // Sender
        {
          chatUserId: local.chatUserId,
          profileImageId:
            local.isAuthenticated === true
              ? local.currentUser.profileImageId
              : null,
          displayName:
            local.isAuthenticated === true ? local.currentUser.name : "",
          emailAddress:
            local.isAuthenticated === true
              ? local.currentUser.emailAddress
              : "",
          group: context.package.title,
          groupPayload: {
            listingId: context._id,
            type: context.type,
            defaultCoverArtId: context.package.defaultCoverArtId
          },

          tag: "traveller"
        },

        // Receipient
        {
          chatUserId: context.user._id,
          profileImageId: context.user.profileImageId,
          displayName: context.user.name,
          emailAddress: context.user.emailAddress,
          group: context.package.title,
          groupPayload: {
            listingId: context._id,
            type: context.type,
            defaultCoverArtId: context.package.defaultCoverArtId
          },

          tag: "tripbonder"
        }
      ]);
    }

    processBooking() {
      ReactGA.event({
        category: "User",
        action: "Clicked on Book Button"
      });

      const { local, history } = this.props;
      if (local.isAuthenticated === true) {
        this.setState({ isOrderModalOpen: true });
      } else {
        history.push({
          pathname: `/auth/login/?returnUrl=${encodeURI(
            `${history.location.pathname}?checkout=true`
          )}`
        });
      }
    }

    renderLoader() {
      return (
        <Fade key="package-loader" duration={350}>
          <div className="container-fluid package-loader">
            <div className="row">
              <div className="col-12 px-0">
                <div className="ph-item mh-100">
                  <div className="ph-col-12">
                    <div className="ph-picture banner"></div>
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                          <div className="ph-effect box"></div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-lg-3 d-none d-sm-block">
                          <div className="ph-effect box"></div>
                        </div>
                        <div className="col-md-4 col-lg-3 d-none d-md-block">
                          <div className="ph-effect box"></div>
                        </div>
                        <div className="col-lg-3 d-none d-lg-block">
                          <div className="ph-effect box"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      );
    }

    renderDays() {
      const { context, local } = this.props;
      const headerSize = this.props.headerSize || 0; // 73
      const isCompareView = this.props.isCompareView || false;
      if (!context) {
        return null;
      }
      return (
        <>
          {!isCompareView ? (
            <section className="container-fluid day-browser">
              <div className="row">
                <div
                  className="col-12 px-0 d-md-none day-spy-nav-sm"
                  style={{ top: headerSize - 3 }}
                >
                  <div className="day-navbar"></div>
                </div>
                <div className="col-md-2 col-xl-1 d-none d-md-block day-spy-nav pr-0">
                  <nav id="day-navbar" className="day-navbar navbar pr-0">
                    <ul className="nav nav-pills flex-fill">
                      {context.package.days.map((days, index) => (
                        <li key={index} className="nav-item">
                          <a className="nav-link" href={`#day-${index}`}>
                            <div className="day-tile">{days.title}</div>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
                <div className="col-12 col-md-10 col-xl-11 px-0">
                  <div
                    className="container-fluid day-contents"
                    data-spy="scroll"
                    data-target="#day-navbar"
                  >
                    {context.package.days.map((day, index) => {
                      return (
                        <div
                          key={index}
                          id={`day-${index}`}
                          className="row day-item"
                        >
                          <div
                            className="col-12 my-4 d-md-none sticky-head"
                            style={{
                              zIndex: 9 + (context.package.days.length - index),
                              top: headerSize
                            }}
                          >
                            <div className="btn-group">
                              <button
                                type="button"
                                className="btn btn-link days dropdown-toggle"
                                data-toggle="dropdown"
                                data-boundary="window"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {day.title}
                              </button>
                              <div className="dropdown-menu scrollable-menu">
                                {context.package.days.map((days, index) => (
                                  <a
                                    key={index}
                                    className="dropdown-item"
                                    href={`#day-${index}`}
                                  >
                                    {days.title}
                                  </a>
                                ))}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 px-0 px-md-4 mb-3 mb-md-0 pt-5 pt-md-0 stickbar">
                            <section className="container-fluid body-content">
                              {day.timeline && day.timeline.length !== 0 ? (
                                <TimeLine
                                  data={day.timeline}
                                  showFullDetails={e =>
                                    this.showFullDetails(
                                      day.timeline,
                                      day.title
                                    )
                                  }
                                  nightStay={!day.stayLocation ? false : true}
                                  stayData={day}
                                  local={local}
                                />
                              ) : null}
                            </section>
                            {/* <section className="container-fluid timeline-events">
                                                     {day.timeline && day.timeline.length !== 0 ? (
                             <TimeLine
                              data={day.timeline}
                              showFullDetails={e =>
                                this.showFullDetails(day.timeline, day.title)
                              }
                             />
                             ) : null}
                             </section>
                             <section className="container-fluid amenities mt-3">
                             {!day.stayLocation ? null : (
                             <Activity data={day} local={local} />
                             )}
                             </section> */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </section>
          ) : null}
        </>
      );
    }

    _renderGeneralRoomInfo(room) {
      const { mode } = this.props;
      return (
        <div className="row">
          <div className="col-12 col-sm-6">
            <div>
              <p>
                {this.props.t("Beach View:")}{" "}
                <b>
                  {room.spaceInfo.indexOf("beach") > -1
                    ? this.props.t("Yes")
                    : this.props.t("No")}
                </b>
              </p>
            </div>
            <div>
              <p>
                {this.props.t("Smoking:")}{" "}
                <b>
                  {room.spaceInfo.indexOf("smoking") > -1
                    ? this.props.t("Allowed")
                    : this.props.t("Not Allowed")}
                </b>
              </p>
            </div>
            <div>
              <p>
                {this.props.t("City View:")}{" "}
                <b>
                  {room.spaceInfo.indexOf("city") > -1
                    ? this.props.t("Yes")
                    : this.props.t("No")}
                </b>
              </p>
            </div>
            <div>
              <p>
                {this.props.t("Shower:")}{" "}
                <b>
                  {room.spaceInfo.indexOf("shower") > -1
                    ? this.props.t("Yes")
                    : this.props.t("Not Available")}
                </b>
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div>
              <p>
                {this.props.t("Capacity of 2 People:")}{" "}
                <b>
                  {room.capacityInfo.indexOf("2") > -1
                    ? this.props.t("Yes")
                    : this.props.t("No")}
                </b>
              </p>
            </div>
            <div>
              <p>
                {this.props.t("Capacity of 3 People:")}{" "}
                <b>
                  {room.capacityInfo.indexOf("3") > -1
                    ? this.props.t("Yes")
                    : this.props.t("No")}
                </b>
              </p>
            </div>
            <div>
              <p>
                {this.props.t("Capacity of 4 People:")}{" "}
                <b>
                  {room.capacityInfo.indexOf("4") > -1
                    ? this.props.t("Yes")
                    : this.props.t("No")}
                </b>
              </p>
            </div>
            {room.extraBedAvailable === true && mode !== "preview" ? (
              <>
                <div>
                  <p>
                    {this.props.t("Extra Bed For Children:")}
                    <b>{this.props.t("Available")}</b>
                  </p>
                </div>
                <div>
                  <p>
                    {this.props.t("Cost of Extra Bed:")}{" "}
                    <b>
                      {GetFormattedPrice(
                        room.effectivePricePerExtraBed,
                        this.props.local.selectedCurrency
                      )}
                    </b>
                  </p>
                </div>
              </>
            ) : null}
          </div>
        </div>
      );
    }

    renderRooms() {
      const { context, local, mode } = this.props;
      const isCompareView = this.props.isCompareView || false;

      let selectedRoomIndex = this.props.selectedRoomIndex;

      if (isCompareView) {
        selectedRoomIndex = this.state.selectedRoomIndex;
      }

      const handleRoomChange = index => {
        if (isCompareView === true) {
          this.setState({
            selectedRoomIndex: index
          });
        } else {
          Controller.marketing.setSelectedRoomIndex(index);
        }
      };

      if (!context) {
        return null;
      }

      let mapPoint = null;

      if (context.geoLocation) {
        if (
          context.geoLocation.coordinates &&
          Array.isArray(context.geoLocation.coordinates)
        ) {
          if (context.geoLocation.coordinates.length === 2) {
            mapPoint = {
              lat: context.geoLocation.coordinates[1],
              lng: context.geoLocation.coordinates[0]
            };
          }
        }
      }

      return (
        <>
          <section className="container-fluid">
            <div className="row px-md-5 mx-md-5 ">
              <div className="col-auto">
                <b>{this.props.t("Accommodation Details")}</b>
              </div>
              <div className="col highlight-title d-flex flex-column justify-content-center">
                <hr />
              </div>
            </div>
          </section>
          <section className="container-fluid accomodation-details mt-4">
            <div className="row">
              <div className="col-12">
                <div className="container">
                  <div className="row pb-4">
                    {context.package.rooms.length > 1 ||
                    isCompareView === true ? (
                      <div className="col-12 d-none d-md-flex room-type pb-4 pl-0">
                        {context.package.rooms.map((room, index) => (
                          <div
                            key={index}
                            onClick={() => handleRoomChange(index)}
                            className={`room-name pl-4 ${
                              selectedRoomIndex === index ? "selected" : ""
                            }`}
                          >
                            <div>
                              <h5 className="mb-1">{room.roomType}</h5>
                            </div>
                            {mode === "preview" ? null : (
                              <div>
                                {GetFormattedPrice(
                                  room.effectivePrice,
                                  local.selectedCurrency
                                )}{" "}
                                {this.props.t("/ Person")}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ) : null}
                    <div className="col-12 d-md-none p-0 room-mobile-view">
                      <div className="pt-2 mt-2 room-page-accordion-wrapper">
                        <div className="accordion" id="filterAccordion">
                          {context.package.rooms.map((room, index) => (
                            <div
                              key={index}
                              className="card room-item-wrapper mb-2 p-t-2"
                            >
                              <button
                                className={`card-header ${
                                  index > 0 ? "collapsed" : ""
                                } d-flex align-items-center`}
                                type="button"
                                data-toggle="collapse"
                                onClick={() => handleRoomChange(index)}
                                data-target={`#collapse${index}`}
                                aria-expanded="true"
                                aria-controls="collapseOne"
                                id="filterHeadingOne"
                              >
                                <div className="flex-shrink-0"></div>
                                <div className="mb-0 text-left flex-fill">
                                  <h5 className="mb-0">{room.roomType}</h5>
                                  <p>
                                    {context.package.currency}{" "}
                                    {room.effectivePrice}
                                    {this.props.t("/ Day")}
                                  </p>
                                </div>
                                <div className="flex-shrink-0">
                                  <i className="fas fa-chevron-circle-up mt-1"></i>
                                </div>
                              </button>

                              <div
                                id={`collapse${index}`}
                                className={`collapse ${
                                  index === 0 ? "show" : null
                                }`}
                                aria-labelledby="filterHeadingOne"
                                data-parent="#filterAccordion"
                              >
                                <div className="row">
                                  <div className="d-md-none p-0 col-12">
                                    <div
                                      className="room-img"
                                      style={{
                                        backgroundImage: `url('${Controller.resolveAssetAddr(
                                          room.imageId
                                        )}')`,
                                        cursor: "pointer"
                                      }}
                                      onClick={() =>
                                        Controller.marketing.setLightBoxImage(
                                          room.imageId
                                        )
                                      }
                                    ></div>
                                  </div>
                                  <div className="d-md-none d-flex flex-column col-12 room-detail text-center">
                                    <div className="pt-3">
                                      <div
                                        className="btn-group"
                                        role="group"
                                        aria-label="Basic example"
                                      >
                                        <button
                                          type="button"
                                          className={`btn ${
                                            this.state.roomDetails === "general"
                                              ? "active-button"
                                              : "inactive-button"
                                          } btn-secondary popover-title-content`}
                                          onClick={() =>
                                            this.switchRoomType("general")
                                          }
                                        >
                                          <i className="flight fas fa-info-circle pr-2"></i>
                                          {this.props.t("General")}
                                        </button>
                                        <button
                                          type="button"
                                          className={`btn ${
                                            this.state.roomDetails ===
                                            "included"
                                              ? "active-button"
                                              : "inactive-button"
                                          } btn-secondary popover-title-content`}
                                          onClick={() =>
                                            this.switchRoomType("included")
                                          }
                                        >
                                          <i className="fas fa-question-circle pr-2"></i>
                                          {this.props.t("What's Included")}
                                        </button>
                                      </div>
                                    </div>
                                    <div className="pt-3 flex-fill text-left px-4">
                                      {this.state.roomDetails === "general"
                                        ? this._renderGeneralRoomInfo(room)
                                        : null}
                                      {this.state.roomDetails === "included" ? (
                                        <>
                                          <p>
                                            {sliceText(room.description, 300)}
                                          </p>
                                          <div className="pb-1 text-left">
                                            {isReadMoreRequired(
                                              room.description,
                                              300
                                            ) ? (
                                              <button
                                                className="btn btn-link btn-sm"
                                                style={{ color: "#01959F" }}
                                                onClick={() => {
                                                  Controller.marketing.setReadMoreContext(
                                                    room.description,
                                                    room.roomType,
                                                    room.imageId
                                                  );
                                                }}
                                              >
                                                <i className="fas fa-plus-circle"></i>{" "}
                                                {this.props.t("Read More")}
                                              </button>
                                            ) : null}
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                    <div className="px-4 pb-3">
                                      {this.props.canInteract ? (
                                        <button
                                          onClick={() => this.processBooking()}
                                          className="btn btn-warning-gradient btn-block"
                                        >
                                          {this.props.t("Book Now")}
                                        </button>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="d-none d-md-block p-0 px-md-2 pb-3 pb-md-0 col-md-4">
                      <div
                        className="room-img"
                        style={{
                          backgroundImage: `url('${Controller.resolveAssetAddr(
                            context.package.rooms[selectedRoomIndex] &&
                              context.package.rooms[selectedRoomIndex].imageId
                          )}')`,
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          Controller.marketing.setLightBoxImage(
                            context.package.rooms[selectedRoomIndex] &&
                              context.package.rooms[selectedRoomIndex].imageId
                          )
                        }
                      ></div>
                    </div>
                    <div className="d-none d-md-flex flex-column col-md-8 room-detail text-center">
                      <div className="pt-3">
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            className={`btn ${
                              this.state.roomDetails === "general"
                                ? "active-button"
                                : "inactive-button"
                            } btn-secondary popover-title-content`}
                            onClick={() => this.switchRoomType("general")}
                          >
                            <i className="flight fas fa-info-circle pr-2"></i>
                            {this.props.t("General")}
                          </button>
                          <button
                            type="button"
                            className={`btn ${
                              this.state.roomDetails === "included"
                                ? "active-button"
                                : "inactive-button"
                            } btn-secondary popover-title-content`}
                            onClick={() => this.switchRoomType("included")}
                          >
                            <i className="fas fa-question-circle pr-2"></i>
                            {this.props.t("What's Included")}
                          </button>
                        </div>
                      </div>
                      <div className="pt-3 flex-fill text-left px-5">
                        {this.state.roomDetails === "general"
                          ? this._renderGeneralRoomInfo(
                              context.package.rooms[selectedRoomIndex]
                            )
                          : null}
                        {this.state.roomDetails === "included" ? (
                          <>
                            <p>
                              {sliceText(
                                context.package.rooms[selectedRoomIndex] &&
                                  context.package.rooms[selectedRoomIndex]
                                    .description,
                                300
                              )}
                            </p>
                            <div className="pb-1 text-left">
                              {isReadMoreRequired(
                                context.package.rooms[selectedRoomIndex] &&
                                  context.package.rooms[selectedRoomIndex]
                                    .description,
                                300
                              ) ? (
                                <button
                                  className="btn btn-link btn-sm"
                                  style={{ color: "#01959F" }}
                                  onClick={() => {
                                    Controller.marketing.setReadMoreContext(
                                      context.package.rooms[
                                        selectedRoomIndex
                                      ] &&
                                        context.package.rooms[selectedRoomIndex]
                                          .description,
                                      context.package.rooms[
                                        selectedRoomIndex
                                      ] &&
                                        context.package.rooms[selectedRoomIndex]
                                          .roomType,
                                      context.package.rooms[
                                        selectedRoomIndex
                                      ] &&
                                        context.package.rooms[selectedRoomIndex]
                                          .imageId
                                    );
                                  }}
                                >
                                  <i className="fas fa-plus-circle"></i>
                                  {this.props.t("Read More")}
                                </button>
                              ) : null}
                            </div>
                          </>
                        ) : null}
                      </div>
                      <div className="px-4 pb-3">
                        {this.props.canInteract ? (
                          <button
                            className="btn btn-warning-gradient btn-block"
                            onClick={() => this.processBooking()}
                          >
                            {this.props.t("Book a")}{" "}
                            {context.package.rooms[selectedRoomIndex] &&
                              context.package.rooms[selectedRoomIndex]
                                .roomType}{" "}
                            {this.props.t("Room")}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <section className="col-12">
                <div className="row px-md-5 mx-md-5 ">
                  <div className="col-auto">
                    <b>{this.props.t("On Earth")}</b>
                  </div>
                  <div className="col highlight-title d-flex flex-column justify-content-center">
                    <hr />
                  </div>
                </div>
              </section>

              {mapPoint ? (
                <div
                  className="col-12 mb-4 py-3 pl-0 pr-0 position-relative"
                  style={{ height: 400 }}
                >
                  <Map
                    google={window.google}
                    zoom={10}
                    initialCenter={mapPoint}
                  >
                    <Marker position={mapPoint} />
                  </Map>
                </div>
              ) : null}

              <div className="col-12 mt-4">
                <div className="row pb-4 mx-md-5 px-md-5 px-2">
                  <div className="col-12 pl-0 ml-2 amenities text-center">
                    <div className="text-left pl-4 py-4">
                      <h5 className="mb-0">
                        <b>{this.props.t("Features")}</b>
                      </h5>
                      <small>
                        {this.props.t(
                          "This accommodation provider grants you some amenities for enhancing your stay experience."
                        )}{" "}
                      </small>
                    </div>
                    <div className="row d-flex justify-content-center icons pb-5 pt-2">
                      {[
                        ...context.package.internetAccess,
                        ...context.package.amenities,
                        ...context.package.access
                      ].map((item, index) => {
                        const masterItem = [
                          ...local.internetaccessMaster,
                          ...local.accomodationAmenitiesMaster,
                          ...local.accomodationAccessMaster
                        ].find(i => i.value === item);
                        return (
                          <div
                            key={index}
                            className="p-2 col-6 col-sm-4 col-md-3 col-lg-2"
                          >
                            <div className="py-2">
                              <div>
                                <AmenitiesIcon id={item} />
                              </div>
                              <small>
                                {masterItem
                                  ? this.props.t(masterItem.label)
                                  : ""}
                              </small>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      );
    }
    renderVehicle() {
      const { context, local, mode } = this.props;
      if (!context) {
        return null;
      }
      return (
        <>
          <section className="container-fluid">
            <div className="row px-md-5 mx-md-5 ">
              <div className="col-auto">
                <b>{this.props.t("Transport Details")}</b>
              </div>
              <div className="col highlight-title d-flex flex-column justify-content-center">
                <hr />
              </div>
            </div>
          </section>
          <section className="container-fluid transport-details mt-4">
            <div className="row">
              <div className="col-12">
                <div className="container">
                  <div className="row pb-5">
                    <div className="col-12 d-block p-0 px-md-2 pb-3 pb-md-0 col-md-4">
                      <div
                        className="room-img"
                        style={{
                          backgroundImage: `url('${Controller.resolveAssetAddr(
                            context.package.defaultCoverArtId
                          )}')`,
                          cursor: "pointer"
                        }}
                        onClick={() =>
                          Controller.marketing.setLightBoxImage(
                            context.package.defaultCoverArtId
                          )
                        }
                      ></div>
                    </div>
                    <div className="col-12 d-flex flex-column col-md-8 transport-detail text-center">
                      <div className="pt-3">
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            className={`btn ${
                              this.state.vehicleDetails === "general"
                                ? "active-button"
                                : "inactive-button"
                            } btn-secondary popover-title-content`}
                            onClick={() => this.switchVehicleType("general")}
                          >
                            <i className="flight fas fa-info-circle pr-2"></i>
                            {this.props.t("Vehicle Details")}
                          </button>
                          {context.package.travelInfo ? (
                            <button
                              type="button"
                              className={`btn ${
                                this.state.vehicleDetails === "info"
                                  ? "active-button"
                                  : "inactive-button"
                              } btn-secondary popover-title-content`}
                              onClick={() => this.switchVehicleType("info")}
                            >
                              <i className="fas fa-question-circle pr-2"></i>
                              {this.props.t("Tips")}
                            </button>
                          ) : null}
                        </div>
                      </div>
                      <div className="pt-3 flex-fill text-left px-2 px-md-5">
                        {this.state.vehicleDetails === "general" ? (
                          <table className="table table-borderless table-sm">
                            <tbody>
                              {context.package.maxPassenger ? (
                                <tr>
                                  <th scope="row">
                                    {this.props.t("Passenger:")}
                                  </th>
                                  <td>
                                    {context.package.maxPassenger}
                                    {this.props.t("Seater")}
                                  </td>
                                </tr>
                              ) : null}
                              {context.package.storage ? (
                                <tr>
                                  <th scope="row">
                                    {this.props.t("Capacity:")}
                                  </th>
                                  <td>{context.package.storage}</td>
                                </tr>
                              ) : null}
                              {context.package.transportType ? (
                                <tr>
                                  <th scope="row">{this.props.t("Type:")}</th>
                                  <td>
                                    {(() => {
                                      const type = local.transportTypes.find(
                                        f =>
                                          f.value ===
                                          context.package.transportType
                                      );

                                      return type && type.label;
                                    })()}
                                  </td>
                                </tr>
                              ) : null}
                              <tr>
                                <th scope="row">{this.props.t("Fuel:")}</th>
                                <td>{this.props.t("Fuel Price Included")}</td>
                              </tr>
                              {mode === "preview" ? null : (
                                <tr>
                                  <th scope="row">{this.props.t("Price:")}</th>
                                  <td>
                                    {context.priceToDisplay.formattedPrice}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        ) : null}
                        {this.state.vehicleDetails === "info" ? (
                          <p className="truncate-block normal">
                            {context.package.travelInfo}
                          </p>
                        ) : null}
                      </div>
                      <div className="px-4 pb-3">
                        {this.props.canInteract ? (
                          <button
                            className="btn btn-warning-gradient btn-block"
                            onClick={() => this.processBooking()}
                          >
                            {this.props.t("Book Now")}
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {context.package.extras && context.package.extras.length > 0 ? (
                <div className="col-12">
                  <div className="row pb-4 mx-md-5 px-2">
                    <div className="col-12 amenities text-center">
                      <div className="text-left pl-3 py-4">
                        <h5 className="mb-0">
                          <b>{this.props.t("Extras")}</b>
                        </h5>
                        <small>
                          {this.props.t(
                            "This transportation provider grants you some extra-goodies for enhancing your transport experience."
                          )}{" "}
                        </small>
                      </div>
                      <div className="row d-flex justify-content-center icons pb-5 pt-2">
                        {context.package.extras.map((item, index) => {
                          const masterItem = [...local.extrasMaster].find(
                            i => i.value === item
                          );

                          return (
                            <div
                              key={index}
                              className="p-2 col-6 col-sm-4 col-md-3 col-lg-2"
                            >
                              <div className="py-2">
                                <div>
                                  <AmenitiesIcon id={item} />
                                </div>
                                <small>
                                  {masterItem ? masterItem.label : ""}
                                </small>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </section>
        </>
      );
    }
    render() {
      const { context, history, canInteract } = this.props;
      const { isWishlistBusy } = this.state;
      const isCompareView = this.props.isCompareView || false;

      if (!context) {
        return this.renderLoader();
      }
      const availableBannerImages = context.package.coverImages.filter(
        image => image !== ""
      );

      let packageInformation;

      switch (context.type) {
        case "itinerary": {
          packageInformation = this.renderDays();
          break;
        }
        case "accomodation": {
          packageInformation = this.renderRooms();
          break;
        }
        case "transport": {
          packageInformation = this.renderVehicle();
          break;
        }
        default: {
          packageInformation = null;
          break;
        }
      }

      return (
        <Fade key="package-view" duration={350}>
          <Helmet>
            <title>
              {context.package.title}
              {this.props.t("- Tripbonder.com")}
            </title>
          </Helmet>
          <div>
            <div className="trip-details">
              {context.coupon ? (
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 coupon-display px-0 py-2">
                      <span className="px-4 py-1 d-none d-sm-block">
                        <i className="fas fa-tags"></i>{" "}
                        {GetFormattedPrice(
                          context.coupon.effectiveAmount,
                          this.props.local.selectedCurrency
                        )}{" "}
                        {this.props.t("Off")}
                      </span>
                      <label className="px-2 mb-0">
                        {this.props.t("Use Code:")}
                        <span>{context.coupon.couponCode}</span>
                      </label>
                      <div>
                        <span className="px-4 py-1 mt-2 mb-1 d-sm-none d-block">
                          <i className="fas fa-tags"></i>{" "}
                          {GetFormattedPrice(
                            context.coupon.effectiveAmount,
                            this.props.local.selectedCurrency
                          )}{" "}
                          {this.props.t("OFF")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <section className="container-fluid">
                <div className="row">
                  <div className="col-12 p-0 swiper-carousal">
                    <SwiperCarousal data={availableBannerImages} />
                  </div>
                </div>
              </section>
              {isCompareView ? (
                <div className="container-fluid booking-details">
                  <div
                    className="row"
                    style={{ height: 100, backgroundColor: "#e3f9f3" }}
                  >
                    <div className="col-12 d-flex px-5 justify-content-between">
                      <div className="d-flex flex-column justify-content-center">
                        <div className="location-Wrapper">
                          <span className="place pr-1">
                            <i
                              className="fa fa-map-marker-alt pr-2"
                              aria-hidden="true"
                            ></i>{" "}
                            {stripAddress(context.package.location)}
                          </span>
                        </div>
                        <div style={{ width: 410 }}>
                          <h4
                            className="truncate"
                            style={{ fontWeight: "bold" }}
                          >
                            {context.package.title}
                          </h4>
                        </div>
                      </div>
                      <a
                        className="d-flex justify-content-center align-items-center"
                        href={`${
                          context.class === "private" ? "/custom" : ""
                        }/package/view/${context.id}`}
                        target="blank"
                        style={{ textDecoration: "none" }}
                      >
                        <button
                          type="button"
                          className="btn btn-outline-info px-4"
                          style={{ fontWeight: 600, fontSize: 14 }}
                        >
                          {this.props.t("BOOK NOW")}
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              ) : null}
              <section className="container booking-details pt-4 pb-4">
                <div className="row">
                  <div className="col-12 text-center">
                    {!isCompareView ? (
                      <>
                        <div className="location-Wrapper">
                          <h6>
                            <span className="badge badge-secondary badge text-capitalize px-3 pt-1 pb-2">
                              {this.props.t(context.type)}
                            </span>
                          </h6>
                          {/* <span className="place pr-1"></span> */}
                        </div>
                        <h3 style={{ fontWeight: "bold" }}>
                          {context.package.title}
                        </h3>
                        <div className="location-Wrapper">
                          <span className="place pr-1">
                            <i
                              className="fa fa-map-marker-alt pr-2"
                              aria-hidden="true"
                            ></i>{" "}
                            {stripAddress(context.package.location)}
                          </span>
                          {context.hasAddedToWishlist !== undefined ? (
                            <div className="whishlist-button-wrapper mt-4">
                              <button
                                disabled={isWishlistBusy}
                                onClick={e => this.addToWishlist()}
                                className="pl-0"
                              >
                                <div className="love-icon">
                                  {isWishlistBusy === true ? (
                                    <i className="fas fa-spin fa-circle-notch"></i>
                                  ) : (
                                    <i
                                      className={`${
                                        context.hasAddedToWishlist === true
                                          ? "added"
                                          : null
                                      } fas fa-heart`}
                                    ></i>
                                  )}
                                </div>
                                <label className="px-2 py-2 mb-0">
                                  {isWishlistBusy === true
                                    ? this.props.t("Processing...")
                                    : context.hasAddedToWishlist === true
                                    ? this.props.t("Remove from Wishlist")
                                    : this.props.t("Add to Wishlist")}
                                </label>
                              </button>
                            </div>
                          ) : null}
                          <span className="by pl-1">
                            <span style={{ textTransform: "capitalize" }}>
                              {/* {context.type} */}
                            </span>{" "}
                            {/* By {context.user.name} */}
                          </span>
                        </div>{" "}
                      </>
                    ) : null}
                    <div className="location-desc py-2">
                      {sliceText(context.package.description, 300)}
                    </div>
                    <div className="py-2 text-left">
                      {isReadMoreRequired(context.package.description, 300) ? (
                        <button
                          className="btn btn-link btn-sm"
                          style={{ color: "#01959F" }}
                          onClick={() => {
                            Controller.marketing.setReadMoreContext(
                              context.package.description,
                              context.package.title,
                              context.package.defaultCoverArtId
                            );
                          }}
                        >
                          <i className="fas fa-plus-circle"></i>
                          {this.props.t("Read More")}
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
                {canInteract ? (
                  <div className="row d-none d-md-flex">
                    <div className="col text-right pr-2">
                      <button
                        type="button"
                        onClick={() => this.processBooking()}
                        className="btn btn-light"
                        style={{ width: 250, fontSize: 14 }}
                      >
                        {this.props.t("Book Now @")}{" "}
                        {context.priceToDisplay.formattedPrice}
                      </button>
                    </div>
                    <div className="col text-left pl-2">
                      <button
                        onClick={() => this.handleContactTripbonder()}
                        type="button"
                        className="btn btn-primary-gradient"
                        style={{ width: 250, fontSize: 14 }}
                      >
                        {this.props.t("Chat With Me")}
                      </button>
                    </div>
                  </div>
                ) : null}
              </section>
              {context.package.highlights.length > 0 ? (
                <>
                  <section className="container-fluid">
                    <div className="row px-md-5 mx-md-5 ">
                      <div className="col-auto">
                        <b>{this.props.t("Major Highlights")}</b>
                      </div>
                      <div className="col highlight-title d-flex flex-column justify-content-center">
                        <hr />
                      </div>
                    </div>
                  </section>
                  <section className="container-fluid">
                    <div className="row slider1">
                      <MutipleSlidesPerView data={context.package.highlights} />
                    </div>
                  </section>
                </>
              ) : null}
              {packageInformation}
              {context.otherInformation === "" ||
              context.otherInformation === null ? null : (
                <section className="container-fluid">
                  <div className="row px-2 pb-4 pt-3">
                    <div className="col-12 px-2 px-md-5 otheriinformation-wrapper">
                      <h5 className="text-center text-md-left">
                        {this.props.t("OTHER INFORMATION")}
                      </h5>
                      <p>{context.otherInformation}</p>
                    </div>
                  </div>
                </section>
              )}

              {isCompareView === false ? (
                <>
                  <section className="container-fluid pb-4 reviewSystemWrapper pt-4 review-container">
                    <div className="row px-md-5 mx-md-5">
                      <div className="col-auto">
                        <b>{this.props.t("Reviews")}</b>
                      </div>
                      <div className="col highlight-title d-flex flex-column justify-content-center">
                        <hr />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 d-flex flex-row justify-content-center align-items-center pt-3">
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                        >
                          <button
                            type="button"
                            className={`${
                              this.state.selectedActivityTab === "listing"
                                ? "active-button"
                                : "inactive-button"
                            } btn btn-secondary`}
                            style={{ paddingLeft: 40, paddingRight: 40 }}
                            onClick={() =>
                              this.setState({
                                selectedActivityTab: "listing"
                              })
                            }
                          >
                            {this.props.t("Package Reviews")}
                          </button>
                          <button
                            type="button"
                            className={`${
                              this.state.selectedActivityTab === "tripbonder"
                                ? "active-button"
                                : "inactive-button"
                            } btn btn-secondary`}
                            style={{ paddingLeft: 40, paddingRight: 40 }}
                            onClick={() =>
                              this.setState({
                                selectedActivityTab: "tripbonder"
                              })
                            }
                          >
                            {this.props.t("Tripbonder Reviews")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </section>

                  {this.state.selectedActivityTab === "listing" ? (
                    <Review
                      reviews={context.reviews}
                      resourceType="listing"
                      resourceId={context._id}
                      canInteract={canInteract}
                      modalInterceptor={({ setModal }) => {
                        if (context.canPostReview === false) {
                          return (
                            <div className="review-modal-body-locked pb-4">
                              <div className="form-row">
                                <div className="col-12 text-center pt-4 px-5">
                                  <h6>{this.props.t("Oops! Door locked")}</h6>
                                  <p>
                                    {this.props.t(
                                      "Looks like you haven't tried this service yet. You need to buy and start this package to be eligible for writing this review"
                                    )}
                                  </p>
                                </div>
                                <div className="col-12 text-center pt-3">
                                  <img
                                    height={150}
                                    style={{ borderRadius: 5 }}
                                    src={require("../../assets/images/door.png")}
                                    alt=""
                                  />

                                  <div className="pt-3">
                                    <button
                                      onClick={() => setModal(false)}
                                      type="button"
                                      className="btn btn-light cancel-button mr-3 px-5 py-2"
                                    >
                                      {this.props.t("Close")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }

                        return false;
                      }}
                      Header={
                        <>
                          <div className="col-12 col-sm-5 order-1 text-center text-sm-left pl-4 pt-1 pt-sm-3">
                            <h5 className="review-title">
                              {this.props.t("Review this package")}
                            </h5>
                          </div>
                          <div className="col-12 col-sm-4 text-center order-3 order-sm-2 text-sm-right pt-2 pt-sm-0">
                            <div className="location-wrapper">
                              <i className="fas fa-map-marker-alt pr-1"></i>
                              <span>
                                {stripAddress(context.package.location)}
                              </span>
                            </div>
                            <h6 className="mb-0">{context.package.title}</h6>
                          </div>
                          <div className="col-12 col-sm-3 pl-3 text-center order-2 order-sm-3 text-sm-left pt-2 pt-sm-0">
                            <img
                              height={55}
                              width={110}
                              style={{ borderRadius: 5 }}
                              src={Controller.resolveAssetAddr(
                                context.package.defaultCoverArtId
                              )}
                              alt=""
                            />
                          </div>
                        </>
                      }
                    />
                  ) : (
                    <Review
                      reviews={context.tripbonderReviews}
                      resourceType="user_profile"
                      resourceId={context.user._id}
                      canInteract={canInteract}
                      modalInterceptor={({ setModal }) => {
                        if (context.canPostReview === false) {
                          return (
                            <div className="review-modal-body-locked pb-4">
                              <div className="form-row">
                                <div className="col-12 text-center pt-4 px-5">
                                  <h6>{this.props.t("Oops! Door locked")}</h6>
                                  <p>
                                    {this.props.t(
                                      "Looks like you haven't tried this service yet. You need to buy and start this package to be eligible for writing this review"
                                    )}
                                  </p>
                                </div>
                                <div className="col-12 text-center pt-3">
                                  <img
                                    height={150}
                                    style={{ borderRadius: 5 }}
                                    src={require("../../assets/images/door.png")}
                                    alt=""
                                  />

                                  <div className="pt-3">
                                    <button
                                      onClick={() => setModal(false)}
                                      type="button"
                                      className="btn btn-light cancel-button mr-3 px-5 py-2"
                                    >
                                      {this.props.t("Close")}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }

                        return false;
                      }}
                      Header={
                        <>
                          <div className="col-12 col-sm-5 order-1 text-center text-sm-left pl-4 pt-1 pt-sm-3">
                            <h5 className="review-title">
                              {this.props.t("Review this package")}
                            </h5>
                          </div>
                          <div className="col-12 col-sm-4 text-center order-3 order-sm-2 text-sm-right pt-2 pt-sm-0">
                            <div className="location-wrapper">
                              <i className="fas fa-map-marker-alt pr-1"></i>
                              <span>
                                {stripAddress(context.package.location)}
                              </span>
                            </div>
                            <h6 className="mb-0">{context.package.title}</h6>
                          </div>
                          <div className="col-12 col-sm-3 pl-3 text-center order-2 order-sm-3 text-sm-left pt-2 pt-sm-0">
                            <img
                              height={55}
                              width={110}
                              style={{ borderRadius: 5 }}
                              src={Controller.resolveAssetAddr(
                                context.package.defaultCoverArtId
                              )}
                              alt=""
                            />
                          </div>
                        </>
                      }
                    />
                  )}
                </>
              ) : null}
              {canInteract ? (
                <section className="container-fluid">
                  <div className="row">
                    <div className="col-12 px-4">
                      <div className="row book px-4">
                        <div className="col-12 col-md-6 text-left">
                          {this.props.t("Ready for this trip?")}

                          <h3>
                            <b>{this.props.t("Book Right Away")}</b>
                          </h3>
                        </div>
                        <div className="col-6 text-right d-none d-md-flex align-items-center justify-content-start justify-content-md-end">
                          <button
                            type="button"
                            className="btn btn-light btn-sm"
                            onClick={() => this.processBooking()}
                            style={{ width: 200 }}
                          >
                            {this.props.t("Book Now")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              ) : null}
              {canInteract ? (
                <section className="container-fluid mobilebook my-2">
                  <div className="row d-md-none">
                    <div className="col-6 p-0">
                      <button
                        type="button"
                        onClick={() => this.processBooking()}
                        className=" btn-light btn-block py-2"
                      >
                        {this.props.t("Book Now @")}

                        <br />
                        {context.priceToDisplay.formattedPrice}
                      </button>
                    </div>
                    <div className="col-6 p-0">
                      <button
                        onClick={() => this.handleContactTripbonder()}
                        type="button"
                        className="btn btn-primary-gradient btn-block py-2"
                      >
                        {this.props.t("Chat With Me")}
                      </button>
                    </div>
                  </div>
                </section>
              ) : null}
            </div>
            <Modal
              isOpen={this.state.isFullDetailShown}
              toggle={this.modalToggle}
              size="xl"
              centered
            >
              <div className="modal-body">
                <button
                  type="button"
                  className="close"
                  onClick={this.modalToggle}
                >
                  <span aria-hidden="true">{this.props.t("\xD7")}</span>
                </button>
                <section className="container px-md-5 full-details">
                  <div className="row text-center mt-4">
                    <div className="col-12">
                      <b>{this.state.selectedDayTitle}</b>
                    </div>
                  </div>
                  <hr />
                  <div className="row mt-2 custom-card">
                    {this.state.selectedDayActivities.map((events, index) => (
                      <React.Fragment key={index}>
                        <div className="col-12 col-lg-6">
                          <div className="card mx-2 mx-md-5">
                            <div className="card-header">
                              <small className="text-muted">
                                <b>
                                  {this.props.t("-")}
                                  {events.title}
                                </b>
                              </small>
                            </div>
                            <img
                              src={Controller.resolveAssetAddr(events.imageId)}
                              className="card-img-top"
                              style={{
                                objectFit: "cover",
                                height: 300,
                                width: "100%"
                              }}
                              alt="..."
                            />

                            <div className="card-body">
                              <div className="location">
                                <span className="place pr-1">
                                  <i
                                    className="fas fa-map-marker-alt"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {stripAddress(events.location)}
                                </span>
                                <span className="time pl-1">
                                  <i
                                    className="far fa-clock"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  {events.hourFormatted}
                                </span>
                              </div>
                              {events.description ? (
                                <>
                                  <p className="card-text mb-1">
                                    {sliceText(events.description, 400)}
                                  </p>
                                  <div className="pb-1 text-left">
                                    {isReadMoreRequired(
                                      events.description,
                                      400
                                    ) ? (
                                      <button
                                        className="btn btn-link btn-sm"
                                        style={{ color: "#01959F" }}
                                        onClick={() => {
                                          Controller.marketing.setReadMoreContext(
                                            events.description,
                                            events.title,
                                            events.imageId
                                          );
                                        }}
                                      >
                                        <i className="fas fa-plus-circle"></i>{" "}
                                        {this.props.t("Read More")}
                                      </button>
                                    ) : null}
                                  </div>
                                </>
                              ) : null}
                              {events.approxDuration ? (
                                <>
                                  <h6 className="card-title">
                                    {this.props.t("APPROX. HOURS")}
                                  </h6>
                                  <p className="card-text">
                                    {events.approxDuration}
                                  </p>
                                </>
                              ) : null}
                              {events.tips ? (
                                <>
                                  <h6 className="card-title">
                                    {this.props.t("Tips")}
                                  </h6>
                                  <p className="card-text">{events.tips}</p>
                                </>
                              ) : null}
                            </div>
                          </div>
                          <hr />
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </section>
              </div>
            </Modal>
            <Modal
              isOpen={this.state.isOrderModalOpen}
              toggle={() =>
                this.setState({
                  isOrderModalOpen: !this.state.isOrderModalOpen
                })
              }
              centered={true}
              modalClassName="order-setup-modal-wrapper"
            >
              <OrderModal
                history={history}
                isOrderModalOpen={this.state.isOrderModalOpen}
                context={context}
                toggle={() =>
                  this.setState({
                    isOrderModalOpen: !this.state.isOrderModalOpen
                  })
                }
              />
            </Modal>
          </div>
        </Fade>
      );
    }
  }
);

export default connect(state => ({
  selectedRoomIndex: state.marketing.selectedRoomIndex,
  readMore: state.marketing,
  local: state.local
}))(withRouter(TripDetailsPage));
