import moment from "moment";
import TripbonderLib from "shared-tripbonder-lib";
export const stripAddress = input => {
  if (input && input.indexOf(",") > -1) {
    return input.split(",")[0].trim();
  }
  return input;
};

export const isReadMoreRequired = (input, characterLimit) => {
  if (!input) return false;
  characterLimit = characterLimit || 250;
  if (input.length > characterLimit) {
    return true;
  }
  return false;
};

export const sliceText = (input, characterLimit) => {
  if (!input) return input;
  characterLimit = characterLimit || 250;
  if (input.length < characterLimit) {
    return input;
  }
  return input.slice(0, characterLimit - 3).concat("...");
};

export const ExtractAddressInformation = (placeInfo, infoKey) => {
  if (
    !placeInfo ||
    !placeInfo.address_components ||
    placeInfo.address_components.length < 1
  ) {
    return null;
  }

  let googleTems = [];
  switch (infoKey) {
    case "country": {
      googleTems = ["country", "political"];
      break;
    }
    case "state": {
      googleTems = ["administrative_area_level_1"];
      break;
    }
    case "city": {
      googleTems = [
        "administrative_area_level_3",
        "administrative_area_level_2",
        "locality",
        "administrative_area_level_1"
      ];

      break;
    }
    default: {
      return null;
    }
  }

  for (var i = 0; i < googleTems.length; i++) {
    for (var j = 0; j < placeInfo.address_components.length; j++) {
      if (placeInfo.address_components[j].types.indexOf(googleTems[i]) > -1) {
        return placeInfo.address_components[j].long_name;
      }
    }
  }

  return null;
};

export const GetFormattedPrice = (value, currency) => {
  return `${currency.sign} ${parseFloat(value).toLocaleString(
    currency.localeString,
    {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    }
  )}`;
};

export const GetRawFormattedPrice = (value, currencyId) => {
  return `${currencyId} ${parseFloat(value).toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
  })}`;
};

export const getPriceToDisplay = (
  type,
  _package,
  _basePrice = null,
  _unit = null,
  _currency = null,
  _localeString = null
) => {
  let localeString = _localeString || "en-US";
  let basePrice = _basePrice || 0.0;
  let baseUnit = _unit || null;
  let currency = _currency || "USD";
  let unit = "";
  if (_package && type) {
    switch (type) {
      case "itinerary": {
        basePrice = _basePrice || parseFloat(_package.perAdultPrice);
        currency = _currency || _package.currency;
        unit = baseUnit || " / Adult";
        break;
      }
      case "transport": {
        basePrice = _basePrice || parseFloat(_package.price);
        unit = baseUnit || " / 8 Hrs.";
        const basePricePerWay = _basePrice || parseFloat(_package.pricePerWay);
        if (basePrice < 1 && basePricePerWay > 0) {
          basePrice = _basePrice || parseFloat(_package.pricePerWay);
          unit = baseUnit || " Per Way";
        }

        currency = _currency || _package.currency;
        break;
      }
      case "ticket": {
        basePrice = _basePrice || parseFloat(_package.price);
        currency = _currency || _package.currency;
        unit = baseUnit || " / Person";
        break;
      }
      case "accomodation": {
        if (_package.rooms) {
          const roomsWithPrices = _package.rooms
            .map(function(room) {
              room.capacityPrices = room.capacityInfo
                .filter(function(capacity) {
                  return capacity !== "extra-bed";
                })
                .map(function(capacity) {
                  const master = TripbonderLib.CapacityMaster.find(function(c) {
                    return c.value === capacity;
                  });
                  if (master) {
                    const price = room.price;
                    const adultPersonCount = master.adult;

                    return {
                      id: capacity,
                      ...master,
                      price: price,
                      priceEffective: adultPersonCount * price
                    };
                  }
                  return null;
                });
              return room;
            })
            .filter(function(o) {
              return o !== null;
            });

          basePrice =
            _basePrice ||
            parseFloat(
              Math.min.apply(
                Math,
                roomsWithPrices.reduce(function(accumulator, room) {
                  return [
                    ...accumulator,
                    ...room.capacityPrices.map(function(c) {
                      return c.priceEffective;
                    })
                  ];
                }, [])
              )
            );

          currency = _currency || _package.currency;
          unit = baseUnit || " / Night";
        } else {
          basePrice = 0;
          currency = "";
          unit = baseUnit || "";
        }
        break;
      }
      default: {
        basePrice = 0.0;
        currency = "USD";
        unit = baseUnit || "";
        break;
      }
    }
  }

  return {
    value: basePrice,
    formattedPrice: `${currency} ${basePrice.toLocaleString(localeString, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    })}${unit}`,
    unit: unit
  };
};

// Currency Helper

export const ConvertAmountToUSD = (forexRateToUSD, value, placeValue) => {
  return parseFloat(
    Math.round((value / forexRateToUSD) * placeValue) / placeValue
  );
};

// Price Calculator
export const GetItineraryAdultPrice = (order, adultCount) => {
  let price = 0;
  if (adultCount > 5 || price === 0) {
    // greater than 5
    price = parseFloat(order.listingSnapshot.package.effectivePerAdultPriceMax);
  }

  if ((adultCount >= 2 && adultCount <= 5) || price === 0) {
    // 2 to 5
    price = parseFloat(
      order.listingSnapshot.package.effectivePerAdultUpto5Price
    );
  }

  if (adultCount === 1 || price === 0) {
    // 1 Adult
    price = parseFloat(order.listingSnapshot.package.effectivePerAdultPrice);
  }

  return price;
};

export const CalculateGrossAmount = _order => {
  let grossAmount = 0;
  switch (_order.type) {
    case "itinerary": {
      const adultCount = parseFloat(_order.adultPersonCount) || 1;
      const childCount = parseFloat(_order.childCount) || 0;
      const childCountWithBed = parseFloat(_order.childCountWithBed) || 0;

      const childPrice =
        parseFloat(_order.listingSnapshot.package.effectivePerChildPrice) || 0;

      const childPriceWithBed =
        parseFloat(
          _order.listingSnapshot.package.effectivePerChildPriceWithBed
        ) || 0;

      grossAmount += adultCount * GetItineraryAdultPrice(_order, adultCount);
      grossAmount += childCount * childPrice;
      grossAmount += childCountWithBed * childPriceWithBed;
      break;
    }
    case "ticket": {
      const adultCount = parseFloat(_order.adultPersonCount) || 1;
      const ticketPrice = parseFloat(
        _order.listingSnapshot.package.effectivePrice
      );

      grossAmount += adultCount * ticketPrice;
      break;
    }
    case "accomodation": {
      const selectedRoom =
        _order.listingSnapshot.package.rooms[_order.selectedRoomIndex];
      const startDateMoment = moment(_order.beginingDate);
      const endDateMoment = moment(_order.endDate);
      if (startDateMoment.isValid() && endDateMoment.isValid()) {
        let price = parseFloat(selectedRoom.effectivePrice);
        let pricePerBed = parseFloat(selectedRoom.effectivePricePerExtraBed);
        const adultCount = parseFloat(_order.adultPersonCount) || 1;
        const extraBedCount = parseFloat(_order.extraBedCount) || 0;

        price = price * adultCount;
        price += pricePerBed * extraBedCount;

        const numberOfDays = endDateMoment.diff(startDateMoment, "days");
        grossAmount += price * parseFloat(numberOfDays);
      }
      break;
    }
    case "transport": {
      const tariff = _order.tariff || "normal";
      let price = 0;
      if (tariff === "normal") {
        price = parseFloat(_order.listingSnapshot.package.effectivePrice || 0);
        const startDateMoment = moment(_order.beginingDate);
        const endDateMoment = moment(_order.endDate);
        if (startDateMoment.isValid() && endDateMoment.isValid()) {
          const numberOfDays = endDateMoment.diff(startDateMoment, "days");
          grossAmount += price * parseFloat(numberOfDays);
        }
      } else if (tariff === "per-way") {
        price = parseFloat(
          _order.listingSnapshot.package.effectivePriceWay || 0
        );
      }
      grossAmount += price;
      break;
    }
    default: {
      break;
    }
  }

  return grossAmount;
};

export const CalculateDiscountAmount = _order => {
  let discountAmount = 0;
  const coupons = _order.coupons || [];
  const totalDiscount = coupons.reduce(function(accumulator, coupon) {
    return accumulator + coupon.effectiveAmount;
  }, 0);

  if (totalDiscount > 0) {
    switch (_order.type) {
      case "itinerary": {
        discountAmount = totalDiscount * _order.adultPersonCount;
        break;
      }
      case "ticket": {
        discountAmount = totalDiscount * _order.adultPersonCount;
        break;
      }
      case "accomodation": {
        const beginingDateMoment = moment(_order.beginingDate);
        const endDateMoment = moment(_order.endDateMoment);
        const noOfDays = endDateMoment.diff(beginingDateMoment, "days");
        discountAmount = totalDiscount * parseInt(noOfDays);
        break;
      }
      case "transport": {
        if (_order.tariff === "per-way") {
          discountAmount = totalDiscount;
        } else {
          const beginingDateMoment = moment(_order.beginingDate);
          const endDateMoment = moment(_order.endDateMoment);
          const noOfDays = endDateMoment.diff(beginingDateMoment, "days");
          discountAmount = totalDiscount * parseInt(noOfDays);
        }

        break;
      }
      default: {
        break;
      }
    }
  }

  return discountAmount;
};

export const CalculateOrderServiceCharge = (
  grossAmount,
  placeValue,
  forexRateToUSD,
  paymentProvider
) => {
  switch (paymentProvider) {
    case "stripe": {
      const stripeChargeInUSD = 0.38; // 0.5 SGD;
      const stripeChargeRate = 3.4; // 3.4 %;
      let serviceCharge = ConvertAmountToUSD(
        forexRateToUSD,
        stripeChargeInUSD,
        placeValue
      );

      const priceInclusiveOfCharge =
        (grossAmount + serviceCharge) / (1 - stripeChargeRate / 100);
      return (
        Math.round((priceInclusiveOfCharge - grossAmount) * placeValue) /
        placeValue
      );
    }
    case "paypal": {
      const paypalChargeInUSD = 0.38; // 0.5 SGD;
      const paypalChargeRate = 4.4; // 4.4 %;
      let serviceCharge = ConvertAmountToUSD(
        forexRateToUSD,
        paypalChargeInUSD,
        placeValue
      );

      const priceInclusiveOfCharge =
        (grossAmount + serviceCharge) / (1 - paypalChargeRate / 100);
      return (
        Math.round((priceInclusiveOfCharge - grossAmount) * placeValue) /
        placeValue
      );
    }
    default: {
      return 0;
    }
  }
};

export const CalculateDeposit = amount => {
  return amount - (amount * 50) / 100;
};

// Chat Helper

export const GetCurrentChatUser = (userId, participants) => {
  return participants.find(p => p.chatUserId === userId);
};

export const ResetChatWindow = threadId => {
  const messageAreaElem = document.getElementById(`message-area-${threadId}`);
  messageAreaElem &&
    (messageAreaElem.scrollTop = messageAreaElem.scrollHeight + 30);
};

export const FocusChatWindow = threadId => {
  const elem = document.getElementById(`chat-room-msg-${threadId}`);
  elem && document.getElementById(`chat-room-msg-${threadId}`).focus();
};

export const SanitizePackageTypes = type => {
  switch (type.toLowerCase()) {
    case "accomodation":
      return "accommodation";
    default:
      return type;
  }
};

export const fetchTags = items => {
  let tags = items
    .reduce((accummulator, listing) => {
      if (listing.package.tags) {
        if (Array.isArray(listing.package.tags)) {
          return [
            ...accummulator,
            ...listing.package.tags.map(t => ({ tag: t }))
          ];
        }
      }
      return accummulator;
    }, [])
    .reduce((accummulator, tagObj) => {
      if (!accummulator.hasOwnProperty(tagObj.tag)) {
        accummulator[tagObj.tag] = 0;
      }
      accummulator[tagObj.tag]++;
      return accummulator;
    }, {});

  /**
   * @description Convert to Array and sort
   */
  tags = Object.keys(tags)
    .map(k => ({
      tag: k,
      count: tags[k]
    }))
    .sort((a, b) => {
      if (a.count > b.count) {
        return -1;
      }
      return 1;
    })
    .slice(0, 10)
    .map(tObj => tObj.tag);
  return tags;
};
