import {
  TICKET_UPDATE,
  TICKET_ADD_HIGHLIGHT,
  TICKET_DELETE_HIGHLIGHT,
  TICKET_UPDATE_HIGHLIGHT,
  TICKET_SET,
  TICKET_UPDATE_LOC,
  TICKET_SPLICE_BANNER,
  TICKET_HIGHLIGHT_LOC
} from "../types";

const initialState = {
  _status: null,
  _id: null,
  title: "",
  location: "",
  geoLocation: null,
  city: null,
  country: null,
  description: "",
  highlights: [],
  coverImages: ["", "", "", "", ""],
  category: "",
  availableTicketsPerDay: 10,
  price: 0,
  otherInformation: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
    case TICKET_SET: {
      const { value, status } = action.payload;
      return Object.assign({}, value, { _status: status });
    }
    case TICKET_UPDATE: {
      const { key, value } = action.payload;
      return Object.assign({}, state, { [key]: value });
    }
    case TICKET_UPDATE_LOC: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        location: value.address,
        geoLocation: value.geoLocation,
        city: value.city,
        country: value.country
      });
    }
    case TICKET_SPLICE_BANNER: {
      const { index, value } = action.payload;
      return Object.assign({}, state, {
        coverImages: state.coverImages.map((image, mapIndex) => {
          if (mapIndex === index) {
            return value;
          }
          return image;
        })
      });
    }
    case TICKET_HIGHLIGHT_LOC: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        highlights: state.highlights.map((item, mapIndex) => {
          if (action.payload.index === mapIndex) {
            return Object.assign({}, item, {
              location: value.address,
              geoLocation: value.geoLocation
            });
          } else {
            return item;
          }
        })
      });
    }
    case TICKET_DELETE_HIGHLIGHT: {
      const { indexToDelete } = action.payload;
      return Object.assign({}, state, {
        highlights: state.highlights.filter(
          (highlight, index) => index !== indexToDelete
        )
      });
    }
    case TICKET_UPDATE_HIGHLIGHT: {
      const { indexToUpdate, key, value } = action.payload;
      return Object.assign({}, state, {
        highlights: state.highlights.map((highlight, index) => {
          if (index === indexToUpdate) {
            return Object.assign({}, highlight, {
              [key]: value
            });
          } else {
            return highlight;
          }
        })
      });
    }
    case TICKET_ADD_HIGHLIGHT: {
      return Object.assign({}, state, {
        highlights: [
          ...state.highlights,
          {
            imageId: null,
            location: "",
            geoLocation: null,
            description: ""
          }
        ]
      });
    }
    default: {
      return state;
    }
  }
}
