import { combineReducers } from "redux";
import localReducer from "./local";
import itineraryReducer from "./itinerary.reducer";
import ticketReducer from "./ticket.reducer";
import accomodationReducer from "./accomodation.reducer";
import transportReducer from "./transport.reducer";
import galleryReducer from "../../components/MediaGallery/gallery.reducer";
import dashboardReducer from "./dashboard.reducer";
import marketingReducer from "./marketing.reducer";
import filterReducer from "./filter.reducer";
import chatReducer from "./chat.reducer";

export default () =>
  combineReducers({
    local: localReducer,
    itinerary: itineraryReducer,
    ticket: ticketReducer,
    accomodation: accomodationReducer,
    transport: transportReducer,
    dashboard: dashboardReducer,
    marketing: marketingReducer,
    filter: filterReducer,
    chat: chatReducer,
    _gallery_: galleryReducer
  });
