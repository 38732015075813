import { withTranslation } from "react-i18next";
import React from "react";
import { Link } from "react-router-dom";
import { Zoom, Fade } from "react-reveal";
import { Helmet } from "react-helmet";
import controllers from "../../store/controllers";
import { AuthServices } from "../../services/Auth";
import { NETWORK_ERR_MESSAGE } from "../../resources/String";
import queryString from "query-string";
import { Modal } from "reactstrap";
import ReactGA from "react-ga";
import HideTawkTo from "./../../components/HideTawkTo";
const AuthView = withTranslation()(
  class AuthView extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        emailAddress: "",
        password: "",
        fullName: "",
        registerEmailId: "",
        registerPassword: "",
        recoveryEmail: "",
        forgotPasswordIsOpen: false,
        recoveryConfirmation: false,
        isFieldBusy: false
      };

      this._handleLogin = this._handleLogin.bind(this);
      this._handleregister = this._handleregister.bind(this);
      this._handleForgetPassword = this._handleForgetPassword.bind(this);
      this.toggleForgotPassword = this.toggleForgotPassword.bind(this);
      this.showForgotPasswordDialog = this.showForgotPasswordDialog.bind(this);
      this.FieldBusy = this.FieldBusy.bind(this);
    }
    FieldBusy() {
      this.setState({
        isFieldBusy: !this.state.isFieldBusy
      });
    }
    toggleForgotPassword() {
      this.setState({
        forgotPasswordIsOpen: !this.state.forgotPasswordIsOpen
      });
    }
    showForgotPasswordDialog() {
      this.setState({
        forgotPasswordIsOpen: true,
        recoveryEmail: "",
        isFieldBusy: false,
        recoveryConfirmation: false
      });
    }
    toggleRecoveryConfirmation() {
      this.setState({
        recoveryConfirmation: true
      });
    }

    _handleForgetPassword(e) {
      e.preventDefault();

      const { recoveryEmail } = this.state;
      if (recoveryEmail === "") {
        controllers.setGlobalError("Email is required", true);
      } else {
        this.FieldBusy();
        controllers.setGlobalLoader(
          "Sending a recovery mail to your registered ID"
        );

        setTimeout(() => {
          AuthServices.RecoveryInitiate(recoveryEmail)
            .then(() => {
              this.toggleRecoveryConfirmation();
              controllers.clearGlobalMessage();
            })
            .catch(err => {
              this.FieldBusy();
              controllers.setGlobalError(
                (err.data && err.data.message) || NETWORK_ERR_MESSAGE,
                true
              );
            });
        }, 800);
      }
    }
    _handleregister(e) {
      e.preventDefault();
      const { registerEmailId, registerPassword, fullName } = this.state;
      if (fullName === "") {
        controllers.setGlobalError("Name is Required", true);
      } else if (registerEmailId === "") {
        controllers.setGlobalError("Enter a Valid Email-ID", true);
      } else if (registerEmailId && registerPassword === "") {
        controllers.setGlobalError("Please provide a Password", true);
      } else if (registerPassword.length < 5) {
        controllers.setGlobalError("Enter a Stronger Password", true);
      } else {
        controllers.setGlobalLoader("Signing you up...");
        setTimeout(() => {
          AuthServices.RegisterUser(fullName, registerEmailId, registerPassword)
            .then(payload => {
              ReactGA.event({
                category: "User",
                action: "Created an Account"
              });

              controllers.clearGlobalMessage();
              controllers.setCurrentUser(payload.registeredUser);
            })
            .catch(err => {
              controllers.setGlobalError(
                (err && err.message) || NETWORK_ERR_MESSAGE,
                true
              );
            });
        }, 800);
      }
    }

    _handleLogin(e) {
      e.preventDefault();
      const { emailAddress, password } = this.state;
      if (emailAddress && password) {
        controllers.setGlobalLoader("Signing you in...");
        setTimeout(() => {
          AuthServices.Authenticate(emailAddress, password)
            .then(userInfo => {
              controllers.clearGlobalMessage();
              controllers.setCurrentUser(userInfo);
            })
            .catch(err => {
              controllers.setGlobalError(
                (err && err.message) || NETWORK_ERR_MESSAGE,
                true
              );
            });
        }, 800);
      } else {
        controllers.setGlobalError(
          "Email address and password is required",
          true
        );
      }
    }

    render() {
      const searchQuery = queryString.parse(window.location.search || "");
      const returnUrl = searchQuery.returnUrl || null;

      return (
        <React.Fragment>
          <HideTawkTo />
          <Helmet>
            <title>{this.props.t("Sign In to Tripbonder")}</title>
          </Helmet>
          <Fade duration={300}>
            <div className="container-fluid auth">
              <div className="row h-100">
                <div className="col-12 col-md-8 col-lg-8 col-xl-9 text-center text-md-left login-intro d-none d-md-block">
                  <h1 className="pt-5 pt-md-0 px-md-5 pt-md-5 pb-md-3">
                    {this.props.t("Ready to make")}
                    <br></br>
                    {this.props.t("a real move ?")}
                  </h1>
                  <h4 className="px-5">
                    {this.props.t("Sign into Tripbonder for a")}
                    <br />
                    {this.props.t("personalised experience")}
                  </h4>
                  <div className="px-5 pt-3">
                    <Link to="/about/tripbonder">
                      <button className="pill-outline introbtn d-none d-md-block px-4 py-2">
                        {this.props.t("Learn More")}
                      </button>
                    </Link>
                  </div>
                  <div className="trip-logo d-none d-md-block"></div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 col-xl-3 text-center d-flex h-100 flex-column">
                  <div className="login">
                    <div className="py-3">
                      <Link to="/">
                        <img
                          src={require("./../../assets/images/logo.png")}
                          alt="Tripbonder Logo"
                          style={{ maxHeight: 50 }}
                        />
                      </Link>
                    </div>
                    {this.props.currentAction === "login" ? (
                      <Zoom duration={350}>
                        <hr
                          key="login-title"
                          className="hr-text"
                          data-content={this.props.t("Access your account")}
                        />

                        <div className="py-3">
                          <a
                            className="g-signin-btn mx-auto mb-4"
                            href={`/auth/google${
                              returnUrl ? "/?redirect=/dashboard" : ""
                            }`}
                          >
                            <img
                              src={require("./../../assets/images/g-logo.png")}
                              alt="Google Logo"
                              height="24px"
                            />

                            {this.props.t("Continue with Google")}
                          </a>
                          <a
                            className="fb-signin-btn mx-auto mt-4"
                            href={`/auth/facebook${
                              returnUrl ? "/?redirect=/dashboard" : ""
                            }`}
                          >
                            <img
                              src={require("./../../assets/images/fb-logo.png")}
                              alt="Google Logo"
                              height="24px"
                            />

                            {this.props.t("Continue with Facebook")}
                          </a>
                        </div>
                        <small
                          key="email-login"
                          id="emailHelp"
                          className="form-text text-muted pt-2"
                        >
                          {this.props.t("or use your email")}
                        </small>
                        <form onSubmit={this._handleLogin}>
                          <div
                            key="login-mailId"
                            className="form-group pt-2 px-4"
                          >
                            <input
                              type="email"
                              className="form-control"
                              id="exampleInputEmail1"
                              aria-describedby="emailHelp"
                              placeholder={this.props.t("someone@example.com")}
                              value={this.state.emailAddress}
                              onChange={e =>
                                this.setState({ emailAddress: e.target.value })
                              }
                              autoFocus={true}
                            />
                          </div>
                          <div key="login-password" className="form-group px-4">
                            <input
                              type="password"
                              className="form-control"
                              id="exampleInputPassword1"
                              placeholder={this.props.t("Password")}
                              value={this.state.password}
                              onChange={e =>
                                this.setState({ password: e.target.value })
                              }
                            />

                            <small
                              id="emailHelp"
                              className="form-text text-muted text-right"
                            >
                              <button
                                type="button"
                                className="btn btn-sm btn-link"
                                onClick={this.showForgotPasswordDialog}
                              >
                                {this.props.t("Forgot Password ?")}
                              </button>
                            </small>
                          </div>
                          <button type="submit" className="pill-button green">
                            <i className="fas fa-lock fa-sm pr-2"></i>
                            {this.props.t("Login using Email")}
                          </button>
                        </form>
                        <small className="form-text text-muted text-center">
                          {this.props.t("Don't have an account?")}{" "}
                          <Link
                            to="/auth/register"
                            style={{ color: "#42939D" }}
                          >
                            {this.props.t("Create new account")}
                          </Link>
                        </small>
                      </Zoom>
                    ) : (
                      <Zoom duration={350}>
                        <hr
                          key="register-title"
                          className="hr-text"
                          data-content={this.props.t("Create Account")}
                        />

                        <form onSubmit={this._handleregister}>
                          <div className="form-group pt-2 px-4">
                            <input
                              key="sign-up-name"
                              type="text"
                              className="form-control"
                              id="exampleInputName"
                              aria-describedby="Enter Name"
                              placeholder={this.props.t("Your full name")}
                              value={this.state.fullName}
                              onChange={e =>
                                this.setState({ fullName: e.target.value })
                              }
                              autoFocus={true}
                              required
                            />
                          </div>
                          <div key="sign-up-email" className="form-group px-4">
                            <input
                              type="email"
                              className="form-control"
                              id="exampleInputEmail2"
                              aria-describedby="email register"
                              placeholder={this.props.t("Your email address")}
                              value={this.state.registerEmailId}
                              onChange={e =>
                                this.setState({
                                  registerEmailId: e.target.value
                                })
                              }
                            />
                          </div>
                          <div
                            key="register-password"
                            className="form-group px-4"
                          >
                            <input
                              key="sign-up-password"
                              type="password"
                              className="form-control"
                              id="exampleInputPassword2"
                              placeholder={this.props.t("New password")}
                              value={this.state.registerPassword}
                              onChange={e =>
                                this.setState({
                                  registerPassword: e.target.value
                                })
                              }
                            />
                          </div>
                          <button
                            key="sign-up-btn"
                            type="submit"
                            className="pill-button green"
                          >
                            <i className="fas fa-lock fa-sm pr-2"></i>
                            {this.props.t("Sign Up")}
                          </button>
                        </form>
                        <small className="form-text text-muted text-center">
                          {this.props.t("Already have an account?")}{" "}
                          <Link to="/auth/login" style={{ color: "#42939D" }}>
                            {this.props.t("Log in")}
                          </Link>
                        </small>
                      </Zoom>
                    )}
                  </div>
                  <div
                    className="login-footer py-3 text-center"
                    style={{ fontSize: 12 }}
                  >
                    {this.props.t("By signing into TripBonder, you accept our")}{" "}
                    <Link to="/about/legal/terms" style={{ color: "#42939D" }}>
                      {this.props.t("Terms & Conditions")}
                    </Link>
                    {/* {" "}
                     and{" "}
                     <Link to="/about/legal/privacy" style={{ color: "#089473" }}>
                     Privacy Policies
                     </Link> */}
                  </div>
                </div>
              </div>
              <Modal
                isOpen={this.state.forgotPasswordIsOpen}
                centered={true}
                toggle={this.toggleForgotPassword}
                size={`${this.state.recoveryConfirmation ? "md" : "lg"}`}
              >
                {this.state.recoveryConfirmation ? (
                  <div className="reset-password-confirmation-modal-wrapper">
                    <div className="form-row">
                      <div className="col-12 text-right p-2">
                        <button
                          className="close-button"
                          onClick={() => {
                            this.toggleForgotPassword();
                          }}
                        >
                          <i className="fas fa-times"></i>
                        </button>
                      </div>
                      <div className="col-12 reset-password-content-wrapper pb-4">
                        <img
                          src={require("./../../assets/images/text-message.png")}
                          alt=""
                        />

                        <label className="mb-0">
                          {this.props.t("Reset mail has been sent to")}
                        </label>
                        <h4 className="truncate">{this.state.recoveryEmail}</h4>
                        <button
                          type="button"
                          className="btn btn-success-gradient px-5 mt-3 mt-lg-4"
                          onClick={() => {
                            this.toggleForgotPassword();
                          }}
                        >
                          {this.props.t("Close")}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="modal-body forgot-password-modal-wrapper ">
                    <form onSubmit={this._handleForgetPassword}>
                      <div className="form-row">
                        <div className="col-12 text-right">
                          <button
                            type="button"
                            className="close-button"
                            onClick={this.toggleForgotPassword}
                          >
                            <i className="fas fa-times"></i>
                          </button>
                        </div>
                        <div className="col-12 col-lg-6 pb-2 pb-lg-3">
                          <div className="circle-wrapper">
                            <img
                              src={require("./../../assets/images/message.png")}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-12 col-lg-6 forgot-password-content-wrapper px-2 px-lg-4">
                          <label>
                            {this.props.t("Enter your e-mail address")}
                            <br />
                            {this.props.t("below to reset your password")}
                          </label>
                          <input
                            type="email"
                            autoFocus={true}
                            disabled={this.state.isFieldBusy}
                            className="form-control mt-1 mt-lg-3"
                            value={this.state.recoveryEmail}
                            onChange={e =>
                              this.setState({
                                recoveryEmail: e.target.value
                              })
                            }
                            placeholder={this.props.t(
                              "Email your exsisting mail ID"
                            )}
                          />

                          <button
                            type="submit"
                            className="btn btn-success-gradient px-5 mt-3 mt-lg-4"
                          >
                            {this.props.t("Submit")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </Modal>
            </div>
          </Fade>
        </React.Fragment>
      );
    }
  }
);

export default AuthView;
