import { useTranslation, withTranslation } from "react-i18next";
import React, { useState } from "react";
import { connect } from "react-redux";
import ImagePlaceholder from "../../components/Draft/ImagePlaceholder";
// import MapContainer from "../../components/Draft/Gmaps";
import controller from "../../store/controllers";
import { DraftControl } from "../../components/Draft/DraftControl";
import LocationSearchInput from "../../components/Draft/LocationSearchInput";
import GalleryController from "./../../components/MediaGallery/gallery.controller";
import { Helmet } from "react-helmet";
import controllers from "../../store/controllers";
import moment from "moment";
import { ListingServices } from "../../services/Listing.services";
import { HotKeys } from "react-hotkeys";
import { Modal, ModalBody } from "reactstrap";
import PublishModal from "../../components/Modals/PublishModal";
import { StatusAlert } from "../../components/Draft/StatusAlert";
import TripViewer from "./../../components/TripViewer";
import PrivatePackageAlert from "../../components/Draft/PrivatePackageAlert";
import HideTawkTo from "./../../components/HideTawkTo";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import ChipInput from "material-ui-chip-input";

function Highlight(props) {
  const { t } = useTranslation();
  const { transport } = props;
  const [hintContext, setHintContext] = useState({});
  function setHighlightImage() {
    GalleryController.insertMedia("", "", {}, mediaObj => {
      controller.transport.updateHighlight(
        props.index,
        "imageId",
        mediaObj._id
      );
    });
  }
  const _getHintStateId = key => {
    return `_${key}_HINT`;
  };

  const getIsHintOpen = key => {
    if (hintContext[_getHintStateId(key)]) {
      return hintContext[_getHintStateId(key)];
    } else {
      return false;
    }
  };

  const toggleIsHintOpen = key => {
    setHintContext(
      Object.assign({}, hintContext, {
        [_getHintStateId(key)]: !getIsHintOpen(key)
      })
    );
  };
  return (
    <React.Fragment>
      <div className="highlights row mt-3">
        <div className="col-12">
          <div className="card-title">
            {t("#")}
            {props.index + 1} {t("Highlight")}
            {transport._status === "draft" ? (
              <button
                className="close"
                type="button"
                data-toggle="modal"
                data-target="#deletePrompt"
                onClick={e => props.onDelete && props.onDelete(props.index)}
              >
                <i className="fas fa-times"></i>
              </button>
            ) : null}
          </div>
          <div className="row highlight-content-wrapper mx-0 pb-3">
            <div className="col-12 col-lg-2 px-0">
              <ImagePlaceholder
                disabled={transport._status !== "draft"}
                message={t(
                  "Click to add image. Image cannot be larger than 2MB"
                )}
                height="150px"
                border="none"
                value={controller.resolveAssetAddr(props.item.imageId)}
                onClick={() => setHighlightImage()}
                onRemove={() =>
                  controller.transport.updateHighlight(
                    props.index,
                    "imageId",
                    null
                  )
                }
              />
            </div>
            <div className="col-12 pt-4  col-xl-8">
              <div className="form-group px-3 px-lg-0">
                <label className="header">
                  {t("WHY IS IT A MUST DO OR SEE?")}
                  <button
                    id="transport-highlight-description"
                    className="hint-btn"
                    type="button"
                  >
                    {"?"}
                  </button>
                  <Popover
                    trigger="legacy"
                    placement="right"
                    isOpen={getIsHintOpen("transport-highlight-description")}
                    target="transport-highlight-description"
                    toggle={() =>
                      toggleIsHintOpen("transport-highlight-description")
                    }
                  >
                    <PopoverHeader>{t("Points to consider")}</PopoverHeader>
                    <PopoverBody>
                      <ol>
                        <li>{t("Enter highlights of this transport")}</li>
                        <li>{t("Try to keep the title short and precise")}</li>
                      </ol>
                    </PopoverBody>
                    <PopoverHeader>{t("Examples")}</PopoverHeader>
                    <PopoverBody>
                      <ol>
                        <li>
                          {t(
                            "The Famous Cruise of Dubai. Dhow cruise gives you the perfect setting for a variety of things, be it getting tanned under the sun or spending a peaceful evening on the sea, watching the starry sky above."
                          )}
                        </li>
                      </ol>
                    </PopoverBody>
                  </Popover>
                </label>
                <textarea
                  readOnly={transport._status !== "draft"}
                  value={props.item.description}
                  onChange={e =>
                    controller.transport.updateHighlight(
                      props.index,
                      "description",
                      e.target.value
                    )
                  }
                  className="form-control"
                  id="tripDesc"
                  rows="1"
                  placeholder={t("Description")}
                  style={{ height: 75 }}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
const TransportSetup = withTranslation()(
  class TransportSetup extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        keyToDelete: "",
        indexToDelete: -1,
        isPublishDialogOpen: false,
        autoSaving: false,
        saving: false,
        isPreviewOpen: false
      };

      this.setDeleteContext = this.setDeleteContext.bind(this);
      this.proceedDelete = this.proceedDelete.bind(this);
      this.onPublish = this.onPublish.bind(this);
      this.onTogglePublish = this.onTogglePublish.bind(this);
    }
    // Hint Functions BEGIN

    _getHintStateId(key) {
      return `_${key}_HINT`;
    }

    getIsHintOpen(key) {
      if (this.state[this._getHintStateId(key)]) {
        return this.state[this._getHintStateId(key)];
      } else {
        return false;
      }
    }

    toggleIsHintOpen(key) {
      this.setState({
        [this._getHintStateId(key)]: !this.getIsHintOpen(key)
      });
    }

    // Hint Functions END
    setDeleteContext(keyToDelete, indexToDelete) {
      this.setState({ keyToDelete, indexToDelete });
    }

    proceedDelete() {
      const { keyToDelete, indexToDelete } = this.state;
      switch (keyToDelete) {
        case "highlight": {
          controller.transport.deleteHighlight(indexToDelete);
          this.setDeleteContext("", -1);
          break;
        }
        default: {
          break;
        }
      }
    }
    addCoverImage(index) {
      GalleryController.insertMedia(
        "Upload Transport Cover",
        "Any attractive picture must get you the thing you wanted",
        {},
        mediaObj => {
          controller.transport.replaceTransportBanner(index, mediaObj._id);
        }
      );
    }

    loadTransport(props) {
      const {
        match: {
          params: { id }
        }
      } = props || this.props;
      controllers.setGlobalLoader("Preparing Editor...", false);
      ListingServices.GetListingById("transport", id)
        .then(transport => {
          controllers.resetEditorSaveState();
          setTimeout(() => {
            controllers.setEditorListing(transport);
            controllers.marketing.setCurrentPackage(transport);
            controllers.transport.setTransportEditorContext(
              transport.package,
              transport.status
            );

            controllers.clearGlobalMessage();
            this.titleInputRef && this.titleInputRef.focus();
            this.runAutoSaver();
          }, 1000);
        })
        .catch(err => {
          controllers.setGlobalError("Package not found", false);
        });
    }

    runAutoSaver() {
      const self = this;
      const lastSavePrefix = "last saved ";
      self.setState(
        {
          autoSaving: false,
          lastSavedOn: null
        },

        () => {
          self.autoSaver =
            (self.autoSaver && clearInterval(self.autoSaver)) || null;
          let isModified = false;
          self.autoSaver = setInterval(() => {
            if (self.state.saving === false) {
              isModified =
                self.props.local.editorLastModifiedOn >
                self.props.local.editorLastSavedOn;
              if (isModified === true) {
                let timeDifference =
                  new Date().getTime() - self.props.local.editorLastModifiedOn;
                if (timeDifference > 2500) {
                  self.setState(
                    {
                      saving: true,
                      autoSaving: true
                    },

                    () =>
                      self
                        .saveTransport(true)
                        .then(() =>
                          self.setState({
                            saving: false,
                            autoSaving: false,
                            lastSavedOn: `${lastSavePrefix}${moment(
                              self.props.local.editorLastSavedOn
                            ).fromNow()}`
                          })
                        )
                        .catch(() =>
                          self.setState({ saving: false, autoSaving: false })
                        )
                  );
                }
              }
            }

            if (self.props.local.editorLastSavedOn > 0) {
              self.setState({
                lastSavedOn: `${lastSavePrefix}${moment(
                  self.props.local.editorLastSavedOn
                ).fromNow()}`
              });
            }
          }, 1800);
        }
      );
    }

    stopAutoSaver() {
      this.autoSaver && clearInterval(this.autoSaver);
    }

    saveTransport(silent = false) {
      return new Promise((resolve, reject) => {
        const {
          transport,
          match: {
            params: { id }
          }
        } = this.props;
        this.setState(
          {
            saving: true
          },

          () => {
            !silent && controllers.setGlobalLoader("Saving...", false);
            ListingServices.Save("transport", id, transport)
              .then(response => {
                this.setState(
                  {
                    saving: silent
                  },

                  () => {
                    controllers.marketing.setCurrentPackage(
                      Object.assign(this.props.local.editorListingSnapshot, {
                        package: response
                      })
                    );

                    controllers.setEditorSaved();
                    !silent &&
                      setTimeout(() => {
                        !silent && controllers.setGlobalSuccess("Saved", true);
                        setTimeout(() => {
                          !silent && controllers.clearGlobalMessage();
                          resolve(true);
                        }, 1700);
                      }, 700);
                    silent && setTimeout(() => resolve(true), 300);
                  }
                );
              })
              .catch(err => {
                err = (err.data && err.data.message) || err.message;
                !silent && controllers.setGlobalError(err, true);
                reject(err);
              });
          }
        );
      });
    }

    onPublish() {
      this.setState({
        isPublishDialogOpen: true
      });
    }

    onTogglePublish() {
      this.setState({
        isPublishDialogOpen: false
      });
    }

    componentDidMount() {
      this.loadTransport();
    }

    componentWillReceiveProps(newProps) {
      const newListingId = newProps.match.params.id;
      const oldListingId = this.props.match.params.id;
      if (newListingId !== oldListingId) {
        // Detected Change
        this.loadTransport(newProps);
      }
    }

    componentWillUnmount() {
      this.stopAutoSaver();
      controllers.clearGlobalMessage();
    }

    handleSaveHotKey(e) {
      e.preventDefault();
      this.saveTransport(false);
    }

    handlers = {
      SAVE: this.handleSaveHotKey.bind(this)
    };

    keyMap = {
      SAVE: "command+s"
    };

    render() {
      const { transport, local, match } = this.props;
      const { autoSaving, lastSavedOn, isPublishDialogOpen } = this.state;

      // Calculate save status
      let saveStatus = "";

      if (autoSaving === true) {
        saveStatus = "Auto saving...";
      } else {
        if (lastSavedOn) {
          saveStatus = lastSavedOn;
        }
      }

      return (
        <React.Fragment>
          <HideTawkTo />
          <HotKeys keyMap={this.keyMap} handlers={this.handlers}>
            <Helmet>
              <title>{this.props.t("Create Transport - Tripbonder.com")}</title>
            </Helmet>
            <div className="container-fluid trip-setup">
              <div className="row position-relative">
                <div className="col-12 pr-md-5">
                  <p
                    className="lead"
                    style={{
                      color: "#42939D",
                      fontSize: 15,
                      fontWeight: "normal"
                    }}
                  >
                    {this.props.t("TRANSPORT SETUP")}
                  </p>
                  <h3>
                    <b>{this.props.t("Create transportation service")}</b>
                  </h3>
                  <p>
                    {this.props.t(
                      "This will appear as your main product and will appear in search results and in trip detail top banner"
                    )}
                  </p>
                </div>
                <div
                  className="balloon d-none d-md-block"
                  style={{
                    backgroundImage: `url('${require("../../assets/images/balloon.png")}')`
                  }}
                ></div>
              </div>
              <StatusAlert
                statusKey={transport._status}
                listingStartDate={
                  local.editorListingSnapshot &&
                  local.editorListingSnapshot.listingStartDate
                }
                listingEndDate={
                  local.editorListingSnapshot &&
                  local.editorListingSnapshot.listingEndDate
                }
              />

              {local.editorListingSnapshot &&
              local.editorListingSnapshot.class === "private" ? (
                <PrivatePackageAlert id={local.editorListingSnapshot._id} />
              ) : null}

              <div
                className="accordion indicator-plus-before round-indicator"
                id="accordionH"
                aria-multiselectable="true"
              >
                <div className="card m-b-0">
                  <div
                    className="card-header text-center mb-2"
                    role="tab"
                    id="headingOneH"
                    data-target="#collapseOneH"
                    data-toggle="collapse"
                    data-parent="#accordionH"
                    aria-expanded="false"
                    aria-controls="collapseOneH"
                  >
                    <span className="card-title">
                      {this.props.t("Basic Information")}
                    </span>
                  </div>
                  <div
                    className="collapse show"
                    id="collapseOneH"
                    role="tabpanel"
                    aria-labelledby="headingOneH"
                  >
                    <div className="card-body p-0 p-sm-2">
                      <div className="form-row">
                        <div className="form-group col-12 col-lg-6">
                          <label
                            htmlFor="name-of-transport"
                            className="pt-2 header"
                          >
                            {this.props.t("NAME OF TRANSPORT")}
                            <button
                              id="transport-name-popover"
                              className="hint-btn"
                              type="button"
                            >
                              {"?"}
                            </button>
                            <Popover
                              trigger="legacy"
                              placement="right"
                              isOpen={this.getIsHintOpen("transport-name")}
                              target="transport-name-popover"
                              toggle={() =>
                                this.toggleIsHintOpen("transport-name")
                              }
                            >
                              <PopoverHeader>
                                {this.props.t("Points to consider")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>
                                    {this.props.t(
                                      "Catchy and attractive title"
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t(
                                      "Must include the name of transport service"
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t(
                                      "Try to keep the title short and precise"
                                    )}
                                  </li>
                                </ol>
                              </PopoverBody>
                              <PopoverHeader>
                                {this.props.t("Example")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>
                                    {this.props.t(
                                      "Tub Kaak Beach to Lanta island (Per Way)"
                                    )}
                                  </li>
                                  <li>{this.props.t("Vietnam Private Car")}</li>
                                </ol>
                              </PopoverBody>
                            </Popover>
                          </label>
                          <input
                            readOnly={transport._status !== "draft"}
                            value={transport.title}
                            onChange={e =>
                              controller.transport.updateTransport(
                                "title",
                                e.target.value
                              )
                            }
                            type="text"
                            className="form-control"
                            id="name-of-transport"
                            placeholder={this.props.t("Type Transport")}
                          />

                          <label className="pt-2 header" htmlFor="service-area">
                            {this.props.t("SERVICE AREA")}
                            {/* <button
                                id="service-area-popover"
                                className="hint-btn"
                                type="button"
                              >
                                ?
                              </button>
                              <Popover
                                trigger="legacy"
                                placement="right"
                                isOpen={this.getIsHintOpen("service-area")}
                                target="service-area-popover"
                                toggle={() => this.toggleIsHintOpen("service-area")}
                              >
                                <PopoverHeader>Service area</PopoverHeader>
                                <PopoverBody>
                                  <img
                                    width="100%"
                                    src={require("./../../assets/images/marketing/coverPic-man.jpg")}
                                    alt="Man standing above rocks"
                                  />
                                  <p>
                                    Sed posuere consectetur est at lobortis.
                                    Aenean eu leo quam. Pellentesque ornare sem
                                    lacinia quam venenatis vestibulum.
                                  </p>
                                  <ol>
                                    <li>List item 1</li>
                                    <li>List item 1</li>
                                    <li>List item 1</li>
                                  </ol>
                                </PopoverBody>
                              </Popover> */}
                          </label>
                          <LocationSearchInput
                            readOnly={transport._status !== "draft"}
                            id="service-area"
                            value={transport.location}
                            geoLocation={transport.geoLocation}
                            onChange={location =>
                              controller.transport.updateTransportLocation(
                                location
                              )
                            }
                          />

                          <label className="pt-2 header" htmlFor="describtion">
                            {this.props.t("DESCRIPTION")}
                            <button
                              id="transport-description-popover"
                              className="hint-btn"
                              type="button"
                            >
                              {"?"}
                            </button>
                            <Popover
                              trigger="legacy"
                              placement="right"
                              isOpen={this.getIsHintOpen(
                                "transport-description"
                              )}
                              target="transport-description-popover"
                              toggle={() =>
                                this.toggleIsHintOpen("transport-description")
                              }
                            >
                              <PopoverHeader>
                                {this.props.t("Points to consider")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>
                                    {this.props.t(
                                      "Should provide a brief description about the vehicle(maximum 6 to 8 sentences)"
                                    )}
                                  </li>
                                </ol>
                              </PopoverBody>
                              <PopoverHeader>
                                {this.props.t("Example")}
                              </PopoverHeader>
                              <PopoverBody>
                                <p>
                                  {this.props.t(
                                    "We are based of Krabi. You miss us Traveling with a couple, family and traveling in groups. We have a cars service. Submitted by courteous driver. Dressed in accordance with the Department of Transportation. There are both van and limousine with comfort and privacy. All cars have basic medical equipment. Safety equipment such as a tank of fire, hammer, glass, insurance,"
                                  )}
                                </p>
                              </PopoverBody>
                            </Popover>
                          </label>
                          <textarea
                            readOnly={transport._status !== "draft"}
                            value={transport.description}
                            onChange={e =>
                              controller.transport.updateTransport(
                                "description",
                                e.target.value
                              )
                            }
                            className="form-control"
                            id="describtion"
                            rows="5"
                            placeholder={this.props.t(
                              "A brief about this package"
                            )}
                          ></textarea>

                          <label className="pt-2 header" htmlFor="otherInfo">
                            {this.props.t("OTHER INFORMATION")}
                            <button
                              id="transport-other-information-popover"
                              className="hint-btn"
                              type="button"
                            >
                              {"?"}
                            </button>
                            <Popover
                              trigger="legacy"
                              placement="right"
                              isOpen={this.getIsHintOpen(
                                "transport-other-information"
                              )}
                              target="transport-other-information-popover"
                              toggle={() =>
                                this.toggleIsHintOpen(
                                  "transport-other-information"
                                )
                              }
                            >
                              <PopoverHeader>
                                {this.props.t("Points to consider")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>
                                    {this.props.t(
                                      "You can include things like additional services and tips for the passengers"
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t("Try to write in points")}
                                  </li>
                                </ol>
                              </PopoverBody>
                              <PopoverHeader>
                                {this.props.t("Examples")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>
                                    {this.props.t(
                                      "This Tour will be private tour and not sharing with other travellers or group"
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t(
                                      "All the Activites and Entry charges applicable during the travel will be on direct payment basis"
                                    )}
                                  </li>
                                </ol>
                              </PopoverBody>
                            </Popover>
                          </label>
                          <textarea
                            readOnly={transport._status !== "draft"}
                            value={transport.otherInformation}
                            onChange={e =>
                              controller.transport.updateTransport(
                                "otherInformation",
                                e.target.value
                              )
                            }
                            className="form-control"
                            id="otherInfo"
                            rows="5"
                            placeholder={this.props.t(
                              "Other booking related information"
                            )}
                          ></textarea>

                          <div className="form-row mt-3">
                            <div className="col-12 form-group">
                              <label className="header d-block mb-3">
                                {this.props.t("TAGS")}
                                <button
                                  id="transport-tags"
                                  className="hint-btn"
                                  type="button"
                                >
                                  {"?"}
                                </button>
                                <Popover
                                  trigger="legacy"
                                  placement="right"
                                  isOpen={this.getIsHintOpen("transport-tags")}
                                  target="transport-tags"
                                  toggle={() =>
                                    this.toggleIsHintOpen("transport-tags")
                                  }
                                >
                                  <PopoverHeader>
                                    {this.props.t("Points to consider")}
                                  </PopoverHeader>
                                  <PopoverBody>
                                    <ol>
                                      <li>
                                        {this.props.t(
                                          "Tag should not be too long"
                                        )}
                                      </li>
                                      <li>
                                        {this.props.t("Do not begin with #")}
                                      </li>
                                    </ol>
                                  </PopoverBody>
                                  <PopoverHeader>
                                    {this.props.t("Examples")}
                                  </PopoverHeader>
                                  <PopoverBody>
                                    <ol>
                                      <li>{this.props.t("nature")}</li>
                                      <li>{this.props.t("trekking")}</li>
                                      <li>{this.props.t("cruise")}</li>
                                    </ol>
                                  </PopoverBody>
                                </Popover>
                              </label>
                              <ChipInput
                                disabled={transport._status !== "draft"}
                                alwaysShowPlaceholder={true}
                                value={transport.tags}
                                onAdd={chip =>
                                  controller.transport.updateTransport("tags", [
                                    ...transport.tags,
                                    chip.toLowerCase().replace("#", "")
                                  ])
                                }
                                onDelete={(chip, index) =>
                                  controller.transport.updateTransport(
                                    "tags",
                                    transport.tags.filter(
                                      (it, _index) => _index !== index
                                    )
                                  )
                                }
                                placeholder={this.props.t("Add tags...")}
                                newChipKeys={["Tab", "Enter"]}
                                newChipKeyCodes={[32]}
                              />
                            </div>
                          </div>

                          <label className="pt-2 header">
                            {this.props.t("Price per 8 hrs")}
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text mb-0 py-0"
                                id="basic-addon1"
                              >
                                {this.props.t("USD")}
                              </span>
                            </div>
                            <input
                              readOnly={transport._status !== "draft"}
                              value={transport.price}
                              onChange={e =>
                                controller.transport.updateTransport(
                                  "price",
                                  e.target.value
                                )
                              }
                              type="number"
                              step="0.01"
                              className="form-control"
                            />
                          </div>
                          <label className="pt-2 header">
                            {this.props.t("Price per way")}
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text mb-0 py-0"
                                id="basic-addon1"
                              >
                                {this.props.t("USD")}
                              </span>
                            </div>
                            <input
                              readOnly={transport._status !== "draft"}
                              value={transport.pricePerWay}
                              onChange={e =>
                                controller.transport.updateTransport(
                                  "pricePerWay",
                                  e.target.value
                                )
                              }
                              type="number"
                              step="0.01"
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/* <div
                         className="form-group col-12 col-lg-6"
                         style={{ height: 330 }}
                         >
                         <MapContainer />
                         </div> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-header collapsed text-center mb-2"
                    role="tab"
                    id="headingTwoH"
                    data-target="#collapseTwoH"
                    data-toggle="collapse"
                    data-parent="#accordionH"
                    aria-expanded="false"
                    aria-controls="collapseTwoH"
                  >
                    <span className="card-title">
                      {this.props.t("Cover Art & Highlights")}
                    </span>
                  </div>
                  <div
                    className="collapse"
                    id="collapseTwoH"
                    role="tabpanel"
                    aria-labelledby="headingTwoH"
                  >
                    <div className="card-body p-0">
                      <div className="row cover-art-wrapper p-sm-2 p-0">
                        <div className="col-12 col-xl-4 title-wrapper">
                          <span className="card-title head mb-3">
                            {this.props.t("Cover Art")}
                            <button
                              id="transport-cover-art-popover"
                              className="hint-btn"
                              type="button"
                            >
                              {"?"}
                            </button>
                            <Popover
                              trigger="legacy"
                              placement="right"
                              isOpen={this.getIsHintOpen("transport-cover-art")}
                              target="transport-cover-art-popover"
                              toggle={() =>
                                this.toggleIsHintOpen("transport-cover-art")
                              }
                            >
                              <PopoverHeader>
                                {this.props.t("Points to consider")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>
                                    {this.props.t(
                                      "Should Upload images less than 2mb"
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t(
                                      "Should not upload blur images"
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t(
                                      "Cover art should give an idea about the vecicle"
                                    )}
                                  </li>
                                </ol>
                              </PopoverBody>
                              <PopoverHeader>
                                {this.props.t("Example")}
                              </PopoverHeader>
                              <PopoverBody>
                                <div className="text-center">
                                  <img
                                    height={150}
                                    src={require("../../assets/images/tourist-bus.jpg")}
                                    alt="history"
                                  />
                                </div>
                              </PopoverBody>
                            </Popover>
                          </span>
                          <p>
                            {this.props.t(
                              "A beautiful and high resolution cover art provides the initial temptation for a traveller. So why not make it tempting enough?"
                            )}
                          </p>
                        </div>
                        <div className="col-12 col-xl-4">
                          <ImagePlaceholder
                            disabled={transport._status !== "draft"}
                            message={this.props.t(
                              "Cover image cannot be larger than 2MB"
                            )}
                            height="260px"
                            value={controller.resolveAssetAddr(
                              transport.coverImages[0]
                            )}
                            onClick={() => this.addCoverImage(0)}
                            onRemove={() =>
                              controller.transport.replaceTransportBanner(0, "")
                            }
                          />
                        </div>
                        <div className="col-12 col-xl-4 pt-3 pt-xl-0">
                          <div className="row">
                            <div className="col-6">
                              <div className="pb-4">
                                <ImagePlaceholder
                                  disabled={transport._status !== "draft"}
                                  message={this.props.t(
                                    "Cover image cannot be larger than 2MB"
                                  )}
                                  height="120px"
                                  value={controller.resolveAssetAddr(
                                    transport.coverImages[1]
                                  )}
                                  onClick={() => this.addCoverImage(1)}
                                  onRemove={() =>
                                    controller.transport.replaceTransportBanner(
                                      1,
                                      ""
                                    )
                                  }
                                />
                              </div>
                              <div>
                                <ImagePlaceholder
                                  disabled={transport._status !== "draft"}
                                  message={this.props.t(
                                    "Cover image cannot be larger than 2MB"
                                  )}
                                  height="120px"
                                  value={controller.resolveAssetAddr(
                                    transport.coverImages[2]
                                  )}
                                  onClick={() => this.addCoverImage(2)}
                                  onRemove={() =>
                                    controller.transport.replaceTransportBanner(
                                      2,
                                      ""
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="pb-4">
                                <ImagePlaceholder
                                  disabled={transport._status !== "draft"}
                                  message={this.props.t(
                                    "Cover image cannot be larger than 2MB"
                                  )}
                                  height="120px"
                                  value={controller.resolveAssetAddr(
                                    transport.coverImages[3]
                                  )}
                                  onClick={() => this.addCoverImage(3)}
                                  onRemove={() =>
                                    controller.transport.replaceTransportBanner(
                                      3,
                                      ""
                                    )
                                  }
                                />
                              </div>
                              <div>
                                <ImagePlaceholder
                                  disabled={transport._status !== "draft"}
                                  message={this.props.t(
                                    "Cover image cannot be larger than 2MB"
                                  )}
                                  height="120px"
                                  value={controller.resolveAssetAddr(
                                    transport.coverImages[4]
                                  )}
                                  onClick={() => this.addCoverImage(4)}
                                  onRemove={() =>
                                    controller.transport.replaceTransportBanner(
                                      4,
                                      ""
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row pt-2 p-sm-2 p-0">
                        <div className="col-12 title-wrapper">
                          <span className="mb-3">
                            {this.props.t("Highlights")}
                          </span>
                          <p>
                            {this.props.t(
                              "Add some highlights to showcase what your trip offers"
                            )}
                          </p>
                        </div>
                        <div className="col-12">
                          {transport.highlights.map((highlight, index) => (
                            <Highlight
                              transport={transport}
                              key={index}
                              index={index}
                              item={highlight}
                              onDelete={index =>
                                this.setDeleteContext("highlight", index)
                              }
                            />
                          ))}
                        </div>
                      </div>
                      {transport._status === "draft" ? (
                        <div className="row my-2">
                          <div className="col-12 text-center create-package-add-button pb-2">
                            <button
                              className="circle btn btn-link btn-sm"
                              onClick={() =>
                                controller.transport.addHighlight()
                              }
                            >
                              <i className="fas fa-plus"></i>
                            </button>
                            <small>
                              {this.props.t("Add another highlight")}
                            </small>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className="card-header collapsed text-center mb-2"
                    role="tab"
                    id="headingThreeH"
                    data-target="#collapseThreeH"
                    data-toggle="collapse"
                    data-parent="#accordionH"
                    aria-expanded="false"
                    aria-controls="collapseThreeH"
                  >
                    <span className="card-title">
                      {this.props.t("Transport Details")}
                    </span>
                  </div>
                  <div
                    className="collapse"
                    id="collapseThreeH"
                    role="tabpanel"
                    aria-labelledby="headingThreeH"
                  >
                    <div className="card-body p-0">
                      <div className="form-row">
                        <div className="form-group col-12 col-lg-6">
                          <label htmlFor="inputTheme" className="header">
                            {this.props.t("TRANSPORT TYPE")}
                          </label>
                          <select
                            readOnly={transport._status !== "draft"}
                            id="inputTheme"
                            className="form-control"
                            value={transport.transportType}
                            onChange={e =>
                              controller.transport.updateTransport(
                                "transportType",
                                e.target.value
                              )
                            }
                          >
                            <option>{this.props.t("Select type")}</option>
                            {local.transportTypes.map((item, index) => (
                              <option key={index} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="form-group col-12 col-lg-6">
                          <label htmlFor="storage" className="header">
                            {this.props.t("CAPACITY")}
                          </label>
                          <input
                            readOnly={transport._status !== "draft"}
                            value={transport.storage}
                            onChange={e =>
                              controller.transport.updateTransport(
                                "storage",
                                e.target.value
                              )
                            }
                            type="text"
                            className="form-control"
                            id="storage"
                            placeholder={this.props.t("Storage")}
                          />
                        </div>
                        <div className="form-group col-12 col-lg-6">
                          <label htmlFor="maximum-passenger" className="header">
                            {this.props.t("MAXIMUM PASSENGER")}
                          </label>
                          <input
                            readOnly={transport._status !== "draft"}
                            value={transport.maxPassenger}
                            onChange={e =>
                              controller.transport.updateTransport(
                                "maxPassenger",
                                e.target.value
                              )
                            }
                            type="number"
                            className="form-control"
                            id="maximum-passenger"
                            placeholder={this.props.t("Passenger")}
                          />
                        </div>
                        <div className="form-group col-12 col-lg-6">
                          <label
                            htmlFor="travel-information"
                            className="header"
                          >
                            {this.props.t("TRAVEL INFORMATION")}
                            <button
                              id="travel-information-popover"
                              className="hint-btn"
                              type="button"
                            >
                              {"?"}
                            </button>
                            <Popover
                              trigger="legacy"
                              placement="right"
                              isOpen={this.getIsHintOpen("travel-information")}
                              target="travel-information-popover"
                              toggle={() =>
                                this.toggleIsHintOpen("travel-information")
                              }
                            >
                              <PopoverHeader>
                                {this.props.t("Points to consider")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>
                                    {this.props.t(
                                      "Should provide the details of the travel."
                                    )}
                                  </li>
                                </ol>
                              </PopoverBody>
                              <PopoverHeader>
                                {this.props.t("Example")}
                              </PopoverHeader>
                              <PopoverBody>
                                <p>
                                  {this.props.t(
                                    "Free Waiting Time: Driver will wait up to 2.5 hours after landing.Additional seats are available upon request and subject to availability"
                                  )}
                                </p>
                              </PopoverBody>
                            </Popover>
                          </label>
                          <input
                            readOnly={transport._status !== "draft"}
                            value={transport.travelInfo}
                            onChange={e =>
                              controller.transport.updateTransport(
                                "travelInfo",
                                e.target.value
                              )
                            }
                            type="text"
                            className="form-control"
                            id="travel-information"
                            placeholder={this.props.t("Information")}
                          />
                        </div>
                        <div className="form-group col-12">
                          <label className="header">
                            {this.props.t("EXTRAS")}
                          </label>
                          <div className="form-check">
                            {local.extrasMaster.map((extras, index) => (
                              <React.Fragment key={index}>
                                <label
                                  className="form-check-label pr-5"
                                  htmlFor={`check-box-${extras.value}`}
                                >
                                  <input
                                    disabled={transport._status !== "draft"}
                                    key={extras.value}
                                    type="checkbox"
                                    className="form-check-input"
                                    id={`check-box-${extras.value}`}
                                    checked={
                                      transport.extras &&
                                      transport.extras.indexOf(extras.value) >
                                        -1
                                    }
                                    onChange={e =>
                                      controller.transport.toggleFeature(
                                        "extras",
                                        extras.value
                                      )
                                    }
                                  />

                                  {this.props.t(extras.label)}
                                </label>
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                        <div className="form-group col-12">
                          <label className="header">
                            {this.props.t("ADDITIONAL INFORMATION REQUIRED")}
                          </label>
                          <div className="form-check">
                            {local.addtinfosMaster
                              .filter(
                                i =>
                                  [
                                    "flightnumber",
                                    "pickup-time",
                                    "contact-person-name",
                                    "contactnumber",
                                    "pickuppoint",
                                    "dropoffad"
                                  ].indexOf(i.value) > -1
                              )
                              .map((addtinfo, index) => (
                                <React.Fragment key={index}>
                                  <label
                                    className="form-check-label pr-5"
                                    htmlFor={`check-box-${addtinfo.value}`}
                                  >
                                    <input
                                      disabled={transport._status !== "draft"}
                                      key={addtinfo.value}
                                      type="checkbox"
                                      className="form-check-input"
                                      id={`check-box-${addtinfo.value}`}
                                      checked={
                                        transport.addtinfo &&
                                        transport.addtinfo.indexOf(
                                          addtinfo.value
                                        ) > -1
                                      }
                                      onChange={e =>
                                        controller.transport.toggleFeature(
                                          "addtinfo",
                                          addtinfo.value
                                        )
                                      }
                                    />

                                    {this.props.t(addtinfo.label)}
                                  </label>
                                </React.Fragment>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="deletePrompt"
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="title">
                      {this.props.t("Remove")}{" "}
                      <span className="text-capitalize">
                        {this.state.keyToDelete}
                      </span>
                    </h5>
                    <button
                      onClick={e => this.setDeleteContext("", -1)}
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">{this.props.t("\xD7")}</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {this.props.t("Are you sure want to delete this?")}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={e => this.setDeleteContext("", -1)}
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      {this.props.t("Cancel")}
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      onClick={this.proceedDelete}
                      className="btn btn-danger"
                    >
                      {this.props.t("Confirm")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <DraftControl
              isModified={local.editorLastSavedOn < local.editorLastModifiedOn}
              statusMessage={saveStatus}
              onSaveDraft={() => this.saveTransport()}
              onPublish={() => this.onPublish()}
              onPreview={() => this.setState({ isPreviewOpen: true })}
              statusKey={transport._status}
              private={
                local.editorListingSnapshot &&
                local.editorListingSnapshot.class === "private"
              }
            />
          </HotKeys>
          <Modal
            isOpen={isPublishDialogOpen}
            toggle={this.onTogglePublish}
            size="xl"
            centered
            className="profile-prompt"
            keyboard={false}
            backdrop="static"
          >
            <PublishModal
              toggle={this.onTogglePublish}
              listingId={match.params.id}
              packageType="transport"
              package={transport}
            />
          </Modal>
          <Modal
            style={{ maxWidth: "100%" }}
            isOpen={this.state.isPreviewOpen}
            toggle={() =>
              this.setState({ isPreviewOpen: !this.state.isPreviewOpen })
            }
            scrollable={true}
            size={"lg"}
            className="ordered-preview"
          >
            <button
              className="closePreview"
              onClick={() => this.setState({ isPreviewOpen: false })}
            >
              <i className="fas fa-times fa-2x"></i>
            </button>
            <ModalBody id="sample-id" className="p-0">
              <TripViewer
                scrollableId="#sample-id"
                spyOffset={-1100}
                context={this.props.context}
                mode="preview"
              />
            </ModalBody>
          </Modal>
        </React.Fragment>
      );
    }
  }
);

const mapStateToProps = state => ({
  context: state.marketing.currentPackage,
  transport: state.transport,
  local: state.local
});

export default connect(mapStateToProps)(TransportSetup);
