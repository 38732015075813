import { withTranslation } from "react-i18next";
import React from "react";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet";
import { ContactDetails } from "./../../services/Enquiries.services";
import Controller from "../../store/controllers/index";
import ReactGA from "react-ga";
const ContactUs = withTranslation()(
  class ContactUs extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: "",
        location: "",
        latitude: null,
        longitude: null
      };

      this.emailChange = this.emailChange.bind(this);
      this.locationChange = this.locationChange.bind(this);
      this.postEnquiry = this.postEnquiry.bind(this);
    }

    postEnquiry(e) {
      e.preventDefault();
      const payload = {
        emailAddress: this.state.email,
        location: this.state.location
      };

      if (this.state.email === "") {
        Controller.setGlobalError("Please enter your email", true);
        return;
      }
      if (this.state.location === "") {
        Controller.setGlobalError("Please enter your location", true);
        return;
      }

      Controller.setGlobalLoader("Sending...", false);
      ContactDetails.PostEnquiry(payload)
        .then(response => {
          ReactGA.event({
            category: "user",
            action: "Got in touch with email"
          });

          this.setState(
            {
              email: "",
              location: "",
              latitude: null,
              longitude: null
            },

            () => {
              Controller.setGlobalSuccess(
                "Thank you, we will contact you soon at your email",
                true
              );

              setTimeout(() => {
                Controller.clearGlobalMessage();
              }, 1200);
            }
          );
        })
        .catch(err => {
          Controller.setGlobalError(err.message, true);
        });
    }

    emailChange(e) {
      this.setState({
        email: e.target.value
      });
    }

    locationChange(e) {
      this.setState({
        location: e.target.value
      });
    }
    componentDidMount() {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
    render() {
      return (
        <Fade duration={300}>
          <Helmet>
            <title>
              {this.props.t("Contact Tripbonder? we are just a call away")}
            </title>
          </Helmet>
          <div className="container-fluid contact-us-wrapper">
            <div className="row">
              <div className="col-12 contact-us-header px-md-5 pt-3 text-center text-md-left">
                <h5 style={{ fontSize: 16, fontWeight: "bold" }}>
                  {this.props.t("Contact Us")}
                </h5>
                <hr />
              </div>
              <div className="col-12 text-center content-title-wrapper pt-4">
                <h3 className="mb-0">{this.props.t("Always on service!")}</h3>
                <label>
                  {this.props.t(
                    "Email us or send a snail mail directly to our office"
                  )}
                </label>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="container">
                  <div className="row">
                    <div className="col-12 text-center globe-image  d-md-none py-4">
                      <img
                        src={require("../../assets/images/OBJECTS.png")}
                        alt=""
                        style={{ height: 200 }}
                      />
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-8 col-sm-6 col-md-4 col-lg-4 content-wrapper-sender">
                      <p className="px-4 py-2 mb-0">
                        {this.props.t("Let's get in touch, shall we?")}
                      </p>
                      <div className="text-center">
                        <span>{this.props.t("12:15 PM")}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row reciever-wrapper pt-3">
                    <div className="col-9 offset-3 col-sm-5 offset-sm-7 col-md-5 offset-md-2  content-wrapper-reciever pr-0">
                      <p className="px-4 py-2 mb-0">
                        {this.props.t("Sure, email us at:")}{" "}
                        <a href="mailto:tbadmin@tripbonder.com">
                          {this.props.t("tbadmin@tripbonder.com")}
                        </a>
                      </p>
                      <div className="text-center">
                        <span>{this.props.t("12:15 PM")}</span>
                      </div>
                    </div>
                    <div className="col-auto globe-image d-none d-md-block">
                      <img
                        src={require("../../assets/images/OBJECTS.png")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-9 col-sm-7 col-md-6 col-lg-5 content-wrapper-sender">
                      <p className="px-4 py-2 mb-0">
                        {this.props.t(
                          "So what if I want to send a snail-mail?"
                        )}
                      </p>
                      <div className="text-center">
                        <span>{this.props.t("12:15 PM")}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row reciever-wrapper pt-3">
                    <div className="col-9 offset-3 col-sm-7 offset-sm-5 col-lg-6 offset-lg-6 content-wrapper-reciever pr-0">
                      <p className="px-5 py-3 mb-0">
                        {this.props.t("Expecting your mail at:")}
                        <br />
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.google.com/maps/place/1%C2%B016'47.9%22N+103%C2%B050'52.4%22E/@1.2799807,103.8472034,18z/data=!3m1!4b1!4m9!1m2!2m1!1sTripbonder+PTE+LTD+Tripbonder+PTE+LTD+28C+Stanley+Street+Singapore!3m5!1s0x0:0x0!7e2!8m2!3d1.279979!4d103.8478772"
                        >
                          {" "}
                          {this.props.t("Tripbonder PTE LTD")}
                          <br />
                          {this.props.t(
                            "Tripbonder PTE LTD 28C Stanley Street Singapore (068737)"
                          )}
                          <img
                            className="mb-2"
                            src={require("../../assets/images/smile.png")}
                            alt=""
                          />
                        </a>
                      </p>
                      <div className="text-center">
                        <span className="time">{this.props.t("12:15 PM")}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-7 col-sm-5 col-md-4 content-wrapper-sender">
                      <p className="px-4 py-1 mb-0">
                        {this.props.t("Thanks, Tripbonder")}

                        <img
                          className="mb-2 ml-1"
                          src={require("../../assets/images/love.png")}
                          alt=""
                        />
                      </p>
                      <div className="text-center">
                        <span>{this.props.t("12:15 PM")}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row reciever-wrapper py-3">
                    <div className="col-8 offset-4 col-sm-6 offset-sm-6 col-md-4 offset-md-8 col-lg-3 offset-lg-9 content-wrapper-reciever pr-0">
                      <p className="px-4 py-1 mb-0">
                        {this.props.t("Pleased to serve you well")}

                        <img
                          className="mb-2 mb-md-2"
                          src={require("../../assets/images/like.png")}
                          alt=""
                        />
                      </p>
                      <div className="text-center">
                        <span className="time">{this.props.t("12:15 PM")}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row seperator-border pb-5">
              <div className="col-12">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-md-6 pt-5 contact-us-header-footer">
                      <h2>
                        {this.props.t(
                          "Couldn't find what you were looking for?"
                        )}
                      </h2>
                      <span className="pb-5">
                        {this.props.t("Please let us know")}
                      </span>
                      <form onSubmit={e => this.postEnquiry(e)}>
                        <div className="pt-5">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder={this.props.t("Your email address")}
                            value={this.state.email}
                            onChange={this.emailChange}
                          />
                        </div>
                        <div className="pt-3">
                          <input
                            type="text"
                            className="form-control"
                            id="location"
                            aria-describedby="location"
                            placeholder={this.props.t("Type your location")}
                            value={this.state.location}
                            onChange={this.locationChange}
                          />
                        </div>
                        <div className="pt-3 text-right d-none d-sm-block">
                          <button
                            type="submit"
                            className="btn btn-warning-gradient"
                          >
                            {this.props.t("Send Request")}
                          </button>
                        </div>
                        <div className="pt-3 d-sm-none">
                          <button
                            type="submit"
                            className="btn btn-warning-gradient btn-sm btn-block"
                          >
                            {this.props.t("Send Request")}
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="col-12 col-md-6 text-center text-md-left d-flex justify-content-center align-items-center pt-4">
                      <div
                        className="help-desk-image-wrapper"
                        style={{
                          backgroundImage: `url('${require("./../../assets/images/tourists.png")}')`
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      );
    }
  }
);

export default ContactUs;
