import { withTranslation } from "react-i18next";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import ReactTable from "react-table";
import { Helmet } from "react-helmet";
import Controller from "../../store/controllers/index";
import { ListingServices } from "./../../services/Listing.services";
import moment from "moment";
import matchSorter from "match-sorter";
import Trip from "../../components/listing/Trip";
import { NETWORK_ERR_MESSAGE } from "../../resources/String";
const Listings = withTranslation()(
  class Listings extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        allInfo: [],
        currentView: window.matchMedia("(max-width: 400px)").matches
          ? "grid"
          : "list",
        editPackageModal: false,
        editId: "",
        editMode: ""
      };

      this.fetchList = this.fetchList.bind(this);
      this.setCurrentViewGrid = this.setCurrentViewGrid.bind(this);
      this.setCurrentViewList = this.setCurrentViewList.bind(this);
      this.renderAction = this.renderAction.bind(this);
    }

    setCurrentViewGrid() {
      this.setState({
        currentView: "grid"
      });
    }

    setCurrentViewList() {
      this.setState({
        currentView: "list"
      });
    }

    fetchList() {
      Controller.setGlobalLoader("Fetching information ...", false);
      setTimeout(() => {
        ListingServices.GetListings()
          .then(listings => {
            Controller.clearGlobalMessage();
            this.setState({
              allInfo: listings.map(a => Object.assign({}, a))
            });
          })
          .catch(err => {
            Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
          });
      }, 800);
    }

    componentDidMount() {
      // const {
      //   match: {
      //     params: { status }
      //   }
      // } = this.props;
      this.fetchList();
    }

    renderAction(row) {
      const data = row.original;
      return (
        <>
          <Link
            to={`/dashboard/composer/${data.type}/${data._id}`}
            className="btn btn-info btn-sm"
          >
            {this.props.t("View Package")}
          </Link>
        </>
      );
    }

    renderImage(row) {
      return (
        <div
          style={{
            height: 110,
            width: "100%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundImage: `url('${Controller.resolveAssetAddr(row.value)}')`
          }}
        ></div>
      );
    }

    render() {
      const { local } = this.props;
      const {
        match: {
          params: { status }
        }
      } = this.props;

      const activeOrders = this.state.allInfo
        .filter(f => f.effectiveStatus === "active")
        .map(listing => {
          return {
            _id: listing._id,
            coverImage: listing.package.defaultCoverArtId,
            tripTitle: listing.package.title,
            type: listing.type,
            startDate: moment(listing.listingStartDate).format("ll"),
            endDate: moment(listing.listingEndDate).format("ll"),
            location: listing.package.location,
            price: listing.priceToDisplay.formattedPrice,
            data: listing
          };
        });
      const privateOrders = this.state.allInfo
        .filter(f => f.class === "private")
        .map(listing => {
          return {
            _id: listing._id,
            coverImage: listing.package.defaultCoverArtId,
            tripTitle: listing.package.title,
            type: listing.type,
            startDate: moment(listing.listingStartDate).format("ll"),
            endDate: moment(listing.listingEndDate).format("ll"),
            location: listing.package.location,
            price: listing.priceToDisplay.formattedPrice,
            data: listing
          };
        });
      const upcomingOrders = this.state.allInfo
        .filter(f => f.effectiveStatus === "upcoming")
        .map(listing => {
          return {
            _id: listing._id,
            coverImage: listing.package.defaultCoverArtId,
            tripTitle: listing.package.title,
            type: listing.type,
            startDate: moment(listing.listingStartDate).format("ll"),
            endDate: moment(listing.listingEndDate).format("ll"),
            location: listing.package.location,
            price: listing.priceToDisplay.formattedPrice,
            data: listing
          };
        });
      const inreviewOrders = this.state.allInfo
        .filter(f => f.effectiveStatus === "in_review")
        .map(listing => {
          return {
            _id: listing._id,
            coverImage: listing.package.defaultCoverArtId,
            tripTitle: listing.package.title,
            type: listing.type,
            startDate: moment(listing.listingStartDate).format("ll"),
            endDate: moment(listing.listingEndDate).format("ll"),
            location: listing.package.location,
            price: listing.priceToDisplay.formattedPrice,
            data: listing
          };
        });
      const rejectedOrders = this.state.allInfo
        .filter(f => f.effectiveStatus === "rejected")
        .map(listing => {
          return {
            _id: listing._id,
            coverImage: listing.package.defaultCoverArtId,
            tripTitle: listing.package.title,
            type: listing.type,
            startDate: moment(listing.listingStartDate).format("ll"),
            endDate: moment(listing.listingEndDate).format("ll"),
            location: listing.package.location,
            price: listing.priceToDisplay.formattedPrice,
            data: listing
          };
        });
      const endedOrders = this.state.allInfo
        .filter(f => f.effectiveStatus === "ended")
        .map(listing => {
          return {
            _id: listing._id,
            coverImage: listing.package.defaultCoverArtId,
            tripTitle: listing.package.title,
            type: listing.type,
            startDate: moment(listing.listingStartDate).format("ll"),
            endDate: moment(listing.listingEndDate).format("ll"),
            location: listing.package.location,
            price: listing.priceToDisplay.formattedPrice,
            data: listing
          };
        });
      const unpublishedOrders = this.state.allInfo
        .filter(f => f.effectiveStatus === "unpublished")
        .map(listing => {
          return {
            _id: listing._id,
            coverImage: listing.package.defaultCoverArtId,
            tripTitle: listing.package.title,
            type: listing.type,
            startDate: moment(listing.listingStartDate).format("ll"),
            endDate: moment(listing.listingEndDate).format("ll"),
            location: listing.package.location,
            price: listing.priceToDisplay.formattedPrice,
            data: listing
          };
        });

      let currentTab;
      let dataToShow = [];
      switch (status) {
        case "up-coming": {
          currentTab = "upcoming";
          dataToShow = upcomingOrders;
          break;
        }
        case "private": {
          currentTab = "private";
          dataToShow = privateOrders;
          break;
        }
        case "in-review": {
          currentTab = "in_review";
          dataToShow = inreviewOrders;
          break;
        }
        case "rejected": {
          currentTab = "rejected";
          dataToShow = rejectedOrders;
          break;
        }
        case "ended": {
          currentTab = "ended";
          dataToShow = endedOrders;
          break;
        }
        case "unpublished": {
          currentTab = "unpublished";
          dataToShow = unpublishedOrders;
          break;
        }
        default: {
          currentTab = "active";
          dataToShow = activeOrders;
        }
      }

      return (
        <Fade duration={300}>
          <Helmet>
            <title>{this.props.t("Listings - Tripbonder.com")}</title>
          </Helmet>
          <div className="container-fluid listings-wrapper">
            <div className="row">
              <div className="col-12 text-center pt-3 listing-header-wrapper">
                <label>
                  {this.props.t("Manage your listings here,")}{" "}
                  {local.currentUser.name}
                  {this.props.t("!")}
                </label>
                <h3>{this.props.t("My Listing")}</h3>
              </div>
            </div>
            <div className="row activity">
              <div className="col-12 pt-2 text-center">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <Link
                    to="/dashboard/listings/active"
                    className={`${
                      currentTab === "active"
                        ? "active-button"
                        : "inactive-button"
                    } btn btn-secondary px-4`}
                  >
                    {this.props.t("Active")}

                    {activeOrders.length > 0 ? ` (${activeOrders.length})` : ""}
                  </Link>
                  <Link
                    to="/dashboard/listings/up-coming"
                    className={`${
                      currentTab === "upcoming"
                        ? "active-button"
                        : "inactive-button"
                    } btn btn-secondary`}
                  >
                    {this.props.t("Up Coming")}

                    {upcomingOrders.length > 0
                      ? ` (${upcomingOrders.length})`
                      : ""}
                  </Link>
                  <Link
                    to="/dashboard/listings/private"
                    className={`${
                      currentTab === "private"
                        ? "active-button"
                        : "inactive-button"
                    } btn btn-secondary d-none d-md-block`}
                  >
                    {this.props.t("Private")}

                    {privateOrders.length > 0
                      ? ` (${privateOrders.length})`
                      : ""}
                  </Link>
                  <Link
                    to="/dashboard/listings/in-review"
                    className={`${
                      currentTab === "in_review"
                        ? "active-button"
                        : "inactive-button"
                    } btn btn-secondary d-none d-md-block`}
                  >
                    {this.props.t("In Review")}

                    {inreviewOrders.length > 0
                      ? ` (${inreviewOrders.length})`
                      : ""}
                  </Link>
                  <Link
                    to="/dashboard/listings/rejected"
                    className={`${
                      currentTab === "rejected"
                        ? "active-button"
                        : "inactive-button"
                    } btn btn-secondary d-none d-md-block`}
                  >
                    {this.props.t("Rejected")}

                    {rejectedOrders.length > 0
                      ? ` (${rejectedOrders.length})`
                      : ""}
                  </Link>
                  <Link
                    to="/dashboard/listings/ended"
                    className={`${
                      currentTab === "ended"
                        ? "active-button"
                        : "inactive-button"
                    } btn btn-secondary d-none d-md-block`}
                  >
                    {this.props.t("Ended")}

                    {endedOrders.length > 0 ? ` (${endedOrders.length})` : ""}
                  </Link>
                  <Link
                    to="/dashboard/listings/unpublished"
                    className={`${
                      currentTab === "unpublished"
                        ? "active-button"
                        : "inactive-button"
                    } btn btn-secondary d-none d-md-block`}
                  >
                    {this.props.t("Un-Published")}

                    {unpublishedOrders.length > 0
                      ? ` (${unpublishedOrders.length})`
                      : ""}
                  </Link>
                  <button
                    id="btnGroupDrop1"
                    type="button"
                    className={`${
                      currentTab === "in_review" ||
                      currentTab === "rejected" ||
                      currentTab === "private" ||
                      currentTab === "unpublished" ||
                      currentTab === "ended"
                        ? "active-button"
                        : "inactive-button"
                    } btn btn-secondary dropdown-toggle d-md-none`}
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {this.props.t("More")}
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="btnGroupDrop1"
                  >
                    <Link
                      className={`${
                        currentTab === "private" ? "active" : ""
                      } dropdown-item`}
                      to="/dashboard/listings/private"
                    >
                      {this.props.t("Private")}
                    </Link>
                    <Link
                      className={`${
                        currentTab === "in_review" ? "active" : ""
                      } dropdown-item`}
                      to="/dashboard/listings/in-review"
                    >
                      {this.props.t("In Review")}
                    </Link>
                    <Link
                      className={`${
                        currentTab === "rejected" ? "active" : ""
                      } dropdown-item`}
                      to="/dashboard/listings/rejected"
                    >
                      {this.props.t("Rejected")}
                    </Link>
                    <Link
                      className={`${
                        currentTab === "ended" ? "active" : ""
                      } dropdown-item`}
                      to="/dashboard/listings/ended"
                    >
                      {this.props.t("Ended")}
                    </Link>
                    <Link
                      className={`${
                        currentTab === "unpublished" ? "active" : ""
                      } dropdown-item`}
                      to="/dashboard/listings/unpublished"
                    >
                      {this.props.t("Un-Published")}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-6">
                {/* <div className="text-left refresh pt-3 "><a className="export-to-csv" href={`${SERVER_BASE_ADDRS}api/listings/active?csv=true`} target="blank">
                 <i className="fas fa-cloud-download-alt pr-1"></i>Export to CSV</a>
                 </div> */}
              </div>
              <div className="col-6 text-right">
                <div className="grid-table-toggle-button-wrapper text-center text-md-right pt-3">
                  <button onClick={() => this.fetchList(status)}>
                    <i className="fas fa-redo-alt refresh"></i>
                  </button>
                  <button
                    className={`${
                      this.state.currentView === "list" ? "disabled" : ""
                    }`}
                    onClick={() => this.setCurrentViewList()}
                  >
                    <i className="fas fa-th-list table-view"></i>
                  </button>
                  <button
                    className={`${
                      this.state.currentView === "grid" ? "disabled" : ""
                    }`}
                    onClick={() => this.setCurrentViewGrid()}
                  >
                    <i className="fas fa-th-large grid-view"></i>
                  </button>
                </div>
              </div>
            </div>
            {this.state.currentView === "list" ? (
              <div className="row">
                <div className="col-12 my-4 px-0">
                  <ReactTable
                    defaultPageSize={5}
                    showPaginationTop={true}
                    showPaginationBottom={true}
                    filterable
                    data={dataToShow}
                    columns={[
                      {
                        Header: "",
                        accessor: "coverImage",
                        Cell: this.renderImage,
                        filterable: false
                      },

                      {
                        Header: this.props.t("TITLE"),
                        accessor: "tripTitle",
                        className: "lh-110",
                        filterMethod: (filter, rows) => {
                          return matchSorter(rows, filter.value, {
                            keys: ["tripTitle"]
                          });
                        },
                        filterAll: true
                      },

                      {
                        Header: this.props.t("TYPE"),
                        accessor: "type",
                        className: "lh-110 text-capitalize",
                        filterMethod: (filter, rows) => {
                          return matchSorter(rows, filter.value, {
                            keys: ["type"]
                          });
                        },
                        filterAll: true
                      },

                      {
                        Header: this.props.t("START DATE"),
                        accessor: "startDate",
                        className: "lh-110",

                        filterable: true
                      },

                      {
                        Header: this.props.t("END DATE"),
                        accessor: "endDate",
                        className: "lh-110",
                        filterable: true
                      },

                      {
                        Header: this.props.t("LOCATION"),
                        accessor: "location",
                        className: "lh-110",
                        filterMethod: (filter, rows) => {
                          return matchSorter(rows, filter.value, {
                            keys: ["location"]
                          });
                        },
                        filterAll: true
                      },

                      {
                        Header: this.props.t("PRICE"),
                        accessor: "price",
                        className: "lh-110",
                        filterable: false
                      },

                      {
                        Header: this.props.t("ACTIONS"),
                        accessor: "actions",
                        className: "lh-110",
                        sortable: false,
                        filterable: false,
                        Cell: this.renderAction
                      }
                    ]}
                  />
                </div>
              </div>
            ) : (
              <div className="container">
                <div className="row">
                  {dataToShow.length > 0 ? (
                    <>
                      {" "}
                      {dataToShow.map((results, index) => (
                        <Trip
                          key={`${this.state.allInfo.package}-${index}`}
                          hasShadow={true}
                          data={results.data}
                          to={`/dashboard/composer/${results.data.type}/${results.data._id}`}
                        />
                      ))}
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className="jumbotron" style={{ width: "100%" }}>
                        <h1 className="display-4">
                          {this.props.t("No package available")}
                        </h1>
                        <p className="lead">
                          {this.props.t(
                            "There is no listing available under current tab"
                          )}
                        </p>
                        <hr className="my-4" />
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
        </Fade>
      );
    }
  }
);

export default connect(state => ({
  local: state.local
}))(Listings);
