import {
  LAUNCH_GALLERY,
  CLOSE_GALLERY,
  SET_RECENT_FILES,
  SET_GALLERY_STATUS,
  PUSH_RECENT_FILES,
  SELECT_MEDIA,
  MARK_FOR_DELETION,
  UNMARK_FOR_DELETION
} from "./gallery.types";

const initialState = {
  isOpen: false,
  isLoading: false,
  title: "",
  desc: "",
  recentFiles: [],
  selectedMediaId: null,
  markedForDeletion: [],
  uploadConfig: {
    maxFileSizeInBytes: 10000000,
    aspectRatio: 16 / 9
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case LAUNCH_GALLERY: {
      const { title, desc, options } = action.payload;
      return Object.assign({}, state, {
        isOpen: true,
        title,
        desc,
        uploadConfig: Object.assign({}, state.uploadConfig, options)
      });
    }
    case CLOSE_GALLERY: {
      return Object.assign({}, state, {
        isOpen: false,
        selectedMediaId: null
      });
    }
    case SET_RECENT_FILES: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        recentFiles: value
      });
    }
    case SET_GALLERY_STATUS: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        isLoading: value
      });
    }
    case PUSH_RECENT_FILES: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        recentFiles: [value, ...state.recentFiles]
      });
    }
    case SELECT_MEDIA: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        selectedMediaId: value
      });
    }
    case MARK_FOR_DELETION: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        markedForDeletion: [...state.markedForDeletion, value],
        selectedMediaId: null
      });
    }
    case UNMARK_FOR_DELETION: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        markedForDeletion: state.markedForDeletion.filter(id => id !== value),
        recentFiles: state.recentFiles.filter(item => item._id !== value)
      });
    }
    default: {
      return state;
    }
  }
}
