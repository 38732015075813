import {
  TICKET_UPDATE,
  TICKET_ADD_HIGHLIGHT,
  TICKET_DELETE_HIGHLIGHT,
  TICKET_UPDATE_HIGHLIGHT,
  TICKET_UPDATE_LOC,
  TICKET_SPLICE_BANNER,
  TICKET_HIGHLIGHT_LOC,
  TICKET_SET
} from "../types";

export class TicketController {
  store = null;

  constructor(_store) {
    this.store = _store;
  }

  setTicketEditorContext(value, status) {
    this.store.dispatch({
      type: TICKET_SET,
      payload: {
        value,
        status
      }
    });
  }

  updateTicket(key, value) {
    this.store.dispatch({
      type: TICKET_UPDATE,
      payload: {
        value,
        key
      }
    });
  }

  addHighlight() {
    this.store.dispatch({
      type: TICKET_ADD_HIGHLIGHT
    });
  }

  updateHighlight(indexToUpdate, key, value) {
    this.store.dispatch({
      type: TICKET_UPDATE_HIGHLIGHT,
      payload: {
        indexToUpdate,
        key,
        value
      }
    });
  }
  updateHighlightLocation(indexToUpdate, data) {
    this.store.dispatch({
      type: TICKET_HIGHLIGHT_LOC,
      payload: {
        index: indexToUpdate,
        value: data
      }
    });
  }
  updateTicketLocation(locationData) {
    this.store.dispatch({
      type: TICKET_UPDATE_LOC,
      payload: {
        value: locationData
      }
    });
  }

  replaceTicketBanner(indexToSplice, imageId) {
    this.store.dispatch({
      type: TICKET_SPLICE_BANNER,
      payload: {
        index: indexToSplice,
        value: imageId
      }
    });
  }

  deleteHighlight(indexToDelete) {
    this.store.dispatch({
      type: TICKET_DELETE_HIGHLIGHT,
      payload: {
        indexToDelete
      }
    });
  }
}
