import { useTranslation } from "react-i18next";
import React from "react";
import { Link } from "react-router-dom";

export default function(props) {
  const { t } = useTranslation();
  return (
    <div className="jumbotron">
      <h1 className="display-4">{t("404 - We've got you!")}</h1>
      <p className="lead">
        {t("Looks like you're lost from your favorite location")}
      </p>
      <hr className="my-4" />
      <p>
        {t(
          "Go back to Tripbonder home page for finding your dream desitination"
        )}
      </p>
      <p className="lead">
        <Link className="btn btn-primary btn-lg" to="/" role="button">
          <i className="far fa-arrow-alt-circle-left"></i>
          {t("Take me back")}
        </Link>
      </p>
    </div>
  );
}
