export { OrderCancellationReasonMaster } from "./masters/order.master";
export {
  SearchCategoryMaster,
  SearchDayRangeMaster
} from "./masters/filter.master";
export {
  ExtrasMaster,
  AdditionalInfoMaster,
  CapacityMaster,
  SpaceMaster,
  InternetAccessMaster,
  AccommodationAccessMaster,
  AccommodationAmenitiesMaster,
  ActivityHourMaster,
  TransportTypeMaster
} from "./masters/package.master";

export function ResolveAssetAddrs(assetBaseAddress, imageId) {
  const extension =
    imageId && imageId.indexOf(".") > -1
      ? imageId.split(".").pop() || null
      : null;
  return (
    (imageId && `${assetBaseAddress}${imageId}${extension ? "" : ".jpg"}`) ||
    null
  );
}
