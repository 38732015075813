import { useTranslation } from "react-i18next";
import React from "react";

export function DraftControl(props) {
  const { t } = useTranslation();
  const statusKey = props.statusKey || null;
  if (statusKey && statusKey === "draft") {
    let isModified = (props.isModified && props.isModified) || false;
    return (
      <div className="savepanel container-fluid mt-3 p-3">
        <div className="row">
          <div className="col-12 order-2 order-sm-0 col-sm-4">
            <div className="d-none d-sm-block">
              <button
                disabled={isModified === true}
                type="button"
                className="btn btn-primary-gradient px-5 mr-2"
                onClick={props.onPreview}
              >
                <i className="fas fa-eye pr-1"></i>
                {t("Preview")}
              </button>
            </div>
            <div className="d-sm-none">
              <button
                disabled={isModified === true}
                type="button"
                className="btn btn-block btn-primary-gradient px-5 mr-2"
                onClick={props.onPreview}
              >
                <i className="fas fa-eye pr-1"></i>
                {t("Preview")}
              </button>
            </div>
          </div>
          <div className="col-12 col-sm-8">
            <div className="text-right d-none d-sm-block">
              <span className="status-msg mr-3">{props.statusMessage}</span>
              <button
                disabled={isModified === false}
                type="button"
                className="btn btn-secondary save-buton px-5 mr-3"
                onClick={props.onSaveDraft}
              >
                {t("Save")}

                {!props.private ? t(" Draft") : null}
              </button>
              {!props.private ? (
                <button
                  disabled={isModified === true}
                  type="button"
                  className="btn btn-success-gradient active-button px-5 mr-2"
                  onClick={props.onPublish}
                >
                  {t("Publish")}
                </button>
              ) : null}
            </div>
            <div className="d-sm-none">
              <button
                disabled={isModified === false}
                type="button"
                className="btn btn-secondary btn-block save-buton px-5 mr-3"
                onClick={props.onSaveDraft}
              >
                {t("Save")}

                {!props.private ? " Draft" : null}
              </button>
              {!props.private ? (
                <button
                  disabled={isModified === true}
                  type="button"
                  className="btn btn-success-gradient btn-block active-button px-5 mr-2 mt-2"
                  onClick={props.onPublish}
                >
                  {t("Publish")}
                </button>
              ) : null}
              <span className="status-msg d-block text-center pt-2">
                {props.statusMessage}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
