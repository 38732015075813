import { useTranslation } from "react-i18next";
import React, { useEffect, createRef } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import { ExtractAddressInformation } from "../../resources/Helper";

function LocationSearchInput(props) {
  const { t } = useTranslation();
  const [searchText, updateSearchText] = React.useState(props.value);
  const [isGeocoding, updateIsGeocoding] = React.useState(false);
  const [isBlured, updateIsBlured] = React.useState(true);
  const geoLocation = props.geoLocation || null;
  const searchInputRef = createRef();

  useEffect(() => {
    updateSearchText(props.value);
  }, [props.value]);

  useEffect(() => {
    const currentSearchInputRef = searchInputRef.current;
    function handleInputBlur() {
      updateIsBlured(true);
    }

    currentSearchInputRef &&
      currentSearchInputRef.addEventListener("blur", handleInputBlur);
    return function cleanup() {
      currentSearchInputRef &&
        currentSearchInputRef.removeEventListener("blur", handleInputBlur);
    };
  });

  const handleOnChange = e => {
    updateSearchText(e);
    props.onChange &&
      props.onChange({
        address: e,
        geoLocation: null,
        placeInfo: null,
        city: null,
        country: null
      });
  };

  const handleSelect = address => {
    updateIsGeocoding(true);
    geocodeByAddress(address)
      .then(results => {
        return getLatLng(results[0])
          .then(latLong => {
            return {
              coordinates: latLong,
              result: results[0]
            };
          })
          .catch(error => {
            updateIsGeocoding(false);
            console.error("Error", error);
          });
      })
      .then(payload => {
        updateIsGeocoding(false);
        props.onChange &&
          props.onChange({
            address,
            geoLocation: {
              type: "Point",
              coordinates: [payload.coordinates.lng, payload.coordinates.lat]
            },

            placeInfo: payload.result,
            city: ExtractAddressInformation(payload.result, "city"),
            country: ExtractAddressInformation(payload.result, "country")
          });
      })
      .catch(error => {
        updateIsGeocoding(false);
        console.error("Error", error);
        props.onChange &&
          props.onChange({
            address,
            geoLocation: null
          });
      });
  };

  return (
    <PlacesAutocomplete
      debounce={props.debounce || 700}
      value={searchText}
      onChange={handleOnChange}
      onSelect={handleSelect}
      highlightFirstSuggestion={true}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
        return (
          <div className="draft-location-search-input">
            <input
              ref={searchInputRef}
              onFocus={e => updateIsBlured(false)}
              spellCheck={false}
              id={props.id}
              {...getInputProps({
                placeholder: t("Search Places..."),
                className: "location-search-input form-control"
              })}
              disabled={isGeocoding}
              readOnly={props.readOnly || false}
            />

            {geoLocation === null &&
            searchText !== "" &&
            isBlured === true &&
            isGeocoding === false ? (
              <small className="warn">
                {t("Location must be selected from the suggestion")}
              </small>
            ) : null}
            <div
              className={`autocomplete-dropdown-container ${
                loading === true || suggestions.length > 0 ? "show-grip" : ""
              }`}
            >
              {loading && (
                <em>
                  <i className="fas fa-spin fa-circle-notch"></i>
                  {t("Checking suggestions...")}
                </em>
              )}

              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                return (
                  <div
                    title={suggestion.description}
                    {...getSuggestionItemProps(suggestion, {
                      className: `truncate ${className}`
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }}
    </PlacesAutocomplete>
  );
}

export default LocationSearchInput;
