import Axios from "axios";
export const DashboardServices = {
  GetSummary: () => {
    return new Promise((resolve, reject) => {
      Axios.get("/api/summary")
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  }
};
