import { withTranslation } from "react-i18next";
import React from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import Controller from "./../store/controllers";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Modal } from "reactstrap";

import DefaultHeader from "../components/Navbars/DefaultHeader";
import DefaultSidebar from "../components/Navbars/DefaultSidebar";
import TripbonderSidebar from "../components/Navbars/TripbonderSidebar";
import DefaultFooter from "../components/Navbars/DefaultFooter";

import Starter from "./../views/Starter.tmpl";
import Profile from "../views/Shared/Profile";
import ComposeDashboard from "./../views/Tripbonder/ComposeDashboard";
import ItinerarySetup from "./../views/Tripbonder/ItinerarySetup";
import TicketSetup from "./../views/Tripbonder/TicketSetup";
import AccomodationSetup from "./../views/Tripbonder/AccomodationSetup";
import TransportSetup from "./../views/Tripbonder/TransportSetup";
import Dashboard from "./../views/Tripbonder/Dashboard";
import Inbox from "./../views/Tripbonder/Inbox";

import BottomSlider from "../components/Modals/BottomSlider";
import MediaGallery from "../components/MediaGallery";
import GalleryController from "../components/MediaGallery/gallery.controller";
import Listings from "./../views/Tripbonder/Listings";
import Coupons from "./../views/Tripbonder/Coupons";
import Wallets from "./../views/Tripbonder/Wallets";
import MyOrders from "./../views/Tripbonder/MyOrders";
import AutoScrollBlackList from "../resources/AutoScrollBlackList";
const TripbonderLayout = withTranslation()(
  class TripbonderLayout extends React.Component {
    componentDidUpdate(prevProps) {
      if (AutoScrollBlackList.indexOf(this.props.location.pathname) < 0) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
          window.scrollTo(0, 0);
          Controller.closeUserAccountDeck();
        }
      }
    }

    renderAccountStatusAlert({ tripbonderStatus }) {
      switch (tripbonderStatus) {
        case "pending_verification": {
          return (
            <div
              className="alert alert-warning m-3 sticky-alert"
              style={{ fontSize: 14 }}
              role="alert"
            >
              {this.props.t(
                "Your Tripbonder account is awaiting approval. Feel free to roam around the dashboard. Only approved accounts can publish package."
              )}
            </div>
          );
        }
        case "active": {
          return null;
        }
        default: {
          return (
            <div
              className="alert alert-danger m-3 sticky-alert"
              style={{ fontSize: 14 }}
              role="alert"
            >
              {this.props.t(
                "Your account is blocked / disabled. Please contact Tripbonder support."
              )}
            </div>
          );
        }
      }
    }

    render() {
      const { gallery, local, history } = this.props;
      return (
        <React.Fragment>
          <div className="content tripbonder-dashboard-wrapper">
            <DefaultHeader
              onSidebarToggle={() => Controller.toggleSidebar()}
              isAuthenticated={local.isAuthenticated}
              currentUser={local.currentUser}
              isUserAccountDeckOpen={local.isUserAccountDeckOpen}
              history={history}
              hideCurrency={true}
              currencies={local.supportedCurrencies}
              selectedCurrencyId={local.selectedCurrencyId}
              languages={local.languages}
              selectedLanguage={local.selectedLanguage}
            />

            <DefaultSidebar
              isOpen={local.isSideBarOpened}
              isAuthenticated={local.isAuthenticated}
              currentUser={local.currentUser}
            />

            <main className="tripbonder">
              <TripbonderSidebar
                history={history}
                currentUser={local.currentUser}
              />

              <div className="content">
                {local.currentUser &&
                local.currentUser.tripbonderStatus !== "active"
                  ? this.renderAccountStatusAlert(local.currentUser)
                  : null}
                <Switch>
                  <Route path="/dashboard/start" component={Starter} />
                  <Route
                    path="/dashboard/listings/:status?"
                    component={Listings}
                  />

                  <Route
                    path="/dashboard/orders/:status?"
                    component={MyOrders}
                  />

                  <Route
                    path="/dashboard/coupons/:status?"
                    component={Coupons}
                  />

                  <Route
                    path="/dashboard/composer/itinerary/:id"
                    component={ItinerarySetup}
                  />

                  <Route
                    path="/dashboard/composer/ticket/:id"
                    component={TicketSetup}
                  />

                  <Route
                    path="/dashboard/composer/accomodation/:id"
                    component={AccomodationSetup}
                  />

                  <Route
                    path="/dashboard/composer/transport/:id"
                    component={TransportSetup}
                  />

                  <Route
                    path="/dashboard/composer"
                    render={props => (
                      <ComposeDashboard {...props} user={local.currentUser} />
                    )}
                  />

                  <Route path="/dashboard/messages" component={Inbox} />
                  <Route
                    path="/dashboard/account/profile"
                    component={Profile}
                  />

                  <Route
                    path="/dashboard/account/wallet/:status?"
                    component={Wallets}
                  />

                  <Route path="/dashboard/summary" component={Dashboard} />
                  <Redirect from="/dashboard" to="/dashboard/summary" />
                </Switch>
              </div>
            </main>
            <DefaultFooter />
          </div>
          <BottomSlider
            key="gallery-slider"
            level={2}
            darker={this.state && this.state.isMediaCropEnabled}
            isOpen={gallery.isOpen}
            onToggleView={e => GalleryController.closeGallery()}
          >
            <MediaGallery
              title="Higlight Pictures"
              description="Upload attractive photos of this place"
              onClose={() => GalleryController.closeGallery()}
              onToggleCrop={isCrop =>
                this.setState({ isMediaCropEnabled: isCrop })
              }
              onError={message => Controller.setGlobalError(message, true)}
            />
          </BottomSlider>
          <Modal
            isOpen={
              this.props.context.readMoreBody ||
              this.props.context.readMoreSubject ||
              this.props.context.readMoreBannerImageId
            }
            centered={true}
            modalClassName="see-more-modal-wrapper"
            toggle={() => {
              Controller.marketing.deleteReadMoreContext();
            }}
          >
            <div className="see-more-modal-content py-3">
              <div className="see-more-modal-header">
                <div className="form-row">
                  <div className="col-12 text-center title-wrapper">
                    <h4
                      className="truncate mx-auto pt-3"
                      style={{ width: "350px" }}
                    >
                      {this.props.context.readMoreSubject}
                    </h4>
                    <button
                      className="mr-3"
                      onClick={() => {
                        Controller.marketing.deleteReadMoreContext();
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                    <div
                      className="item-background-img mt-3"
                      style={{
                        backgroundImage: `url('${Controller.resolveAssetAddr(
                          this.props.context.readMoreBannerImageId
                        )}')`
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              <div className="see-more-modal-body">
                <div className="form-row px-4 pt-3">
                  <div
                    className="col-12 px-4 pt-3"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    <p>{this.props.context.readMoreBody}</p>
                    <div className="text-center pt-3">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => {
                          Controller.marketing.deleteReadMoreContext();
                        }}
                      >
                        {this.props.t("Close")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          {this.props.context.lightBoxImageId ? (
            <Lightbox
              mainSrc={Controller.resolveAssetAddr(
                this.props.context.lightBoxImageId
              )}
              onCloseRequest={() => Controller.marketing.removeLightBoxImage()}
            />
          ) : null}
        </React.Fragment>
      );
    }
  }
);

const mapStateToProps = state => ({
  local: state.local,
  gallery: state._gallery_,
  context: state.marketing
});

export default connect(mapStateToProps)(TripbonderLayout);
