export const EDITOR_PREFIX_ = "EDITOR_";

// Local
export const INITIALIZE_APP = "INITIALIZE_APP";
export const OPEN_SIDEBAR = "OPEN_SIDEBAR";
export const OPEN_USER_ACCOUNT_DECK = "OPEN_USER_ACCOUNT_DECK";
export const CLOSE_USER_ACCOUNT_DECK = "CLOSE_USER_ACCOUNT_DECK";
export const TOGGLE_USER_ACCOUNT_DECK = "TOGGLE_USER_ACCOUNT_DECK";
export const UPDATE_PREF_CURRENCY = "UPDATE_PREF_CURRENCY";
export const SET_IS_CURRENCY_CHANGING = "SET_IS_CURRENCY_CHANGING";
export const UPDATE_PREF_LANGUAGE = "UPDATE_PREF_LANGUAGE";
export const SET_IS_LANGUAGE_CHANGING = "SET_IS_LANGUAGE_CHANGING";
export const CLOSE_SIDEBAR = "CLOSE_SIDEBAR";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const SET_GLOBAL_LOADER = "SET_GLOBAL_LOADER";
export const SET_GLOBAL_SUCCESS = "SET_GLOBAL_SUCCESS";
export const SET_GLOBAL_ERROR = "SET_GLOBAL_ERROR";
export const CLEAR_GLOBAL_MSG = "CLEAR_GLOBAL_MSG";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_EDITOR_MODIFIED = "SET_EDITOR_MODIFIED";
export const SET_EDITOR_SAVED = "SET_EDITOR_SAVED";
export const RESET_EDITOR = "RESET_EDITOR";
export const SET_EDITOR_LISTING = "SET_EDITOR_LISTING";
export const UPDATE_EDITOR_LISTING = "UPDATE_EDITOR_LISTING";

// Itinerary
export const ITINERARY_SET = "ITINERARY_SET";
export const ITINERARY_UPDATE_STATUS = "ITINERARY_UPDATE_STATUS";
export const ITINERARY_UPDATE = EDITOR_PREFIX_ + "ITINERARY_UPDATE";
export const ITINERARY_UPDATE_LOC = EDITOR_PREFIX_ + "ITINERARY_UPDATE_LOC";
export const ITINERARY_TOGGLE_FEATURES =
  EDITOR_PREFIX_ + "ITINERARY_TOGGLE_FEATURES";
export const ITINERARY_HIGHLIGHT_LOC =
  EDITOR_PREFIX_ + "ITINERARY_HIGHLIGHT_LOC";
export const ITINERARY_SPLICE_BANNER =
  EDITOR_PREFIX_ + "ITINERARY_SPLICE_BANNER";
export const ITINERARY_ADD_HIGHLIGHT =
  EDITOR_PREFIX_ + "ITINERARY_ADD_HIGHLIGHT";
export const ITINERARY_UPDATE_HIGHLIGHT =
  EDITOR_PREFIX_ + "ITINERARY_UPDATE_HIGHLIGHT";
export const ITINERARY_DELETE_HIGHLIGHT =
  EDITOR_PREFIX_ + "ITINERARY_DELETE_HIGHLIGHT";
export const ITINERARY_ADD_ACTIVITY = EDITOR_PREFIX_ + "ITINERARY_ADD_ACTIVITY";
export const ITINERARY_UPDATE_ACTIVITY =
  EDITOR_PREFIX_ + "ITINERARY_UPDATE_ACTIVITY";
export const ITINERARY_UPDATE_ACTIVITY_LOC =
  EDITOR_PREFIX_ + "ITINERARY_UPDATE_ACTIVITY_LOC";
export const ITINERARY_REMOVE_ACTIVITY =
  EDITOR_PREFIX_ + "ITINERARY_REMOVE_ACTIVITY";
export const ITINERARY_ADD_FOOD_ACTIVITY =
  EDITOR_PREFIX_ + "ITINERARY_ADD_FOOD_ACTIVITY";
export const ITINERARY_UPDATE_FOOD_ACTIVITY =
  EDITOR_PREFIX_ + "ITINERARY_UPDATE_FOOD_ACTIVITY";
export const ITINERARY_UPDATE_FOOD_ACTIVITY_LOC =
  EDITOR_PREFIX_ + "ITINERARY_UPDATE_FOOD_ACTIVITY_LOC";
export const ITINERARY_REMOVE_FOOD_ACTIVITY =
  EDITOR_PREFIX_ + "ITINERARY_REMOVE_FOOD_ACTIVITY";
export const ITINERARY_UPDATE_STAY_ACTIVITY =
  EDITOR_PREFIX_ + "ITINERARY_UPDATE_STAY_ACTIVITY";
export const ITINERARY_ADD_STAY_IMAGE =
  EDITOR_PREFIX_ + "ITINERARY_ADD_STAY_IMAGE";
export const ITINERARY_UPDATE_STAY_IMAGE =
  EDITOR_PREFIX_ + "ITINERARY_UPDATE_STAY_IMAGE";
export const ITINERARY_REMOVE_STAY_IMAGE =
  EDITOR_PREFIX_ + "ITINERARY_REMOVE_STAY_IMAGE";
export const ITINERARY_ADD_DAY = EDITOR_PREFIX_ + "ITINERARY_ADD_DAY";
export const ITINERARY_UPDATE_DAY = EDITOR_PREFIX_ + "ITINERARY_UPDATE_DAY";
export const ITINERARY_UPDATE_DAY_STAY_LOC =
  EDITOR_PREFIX_ + "ITINERARY_UPDATE_DAY_STAY_LOC";
export const ITINERARY_DELETE_DAY = EDITOR_PREFIX_ + "ITINERARY_DELETE_DAY";

export const ITINERARY_TOGGLE_INFO = EDITOR_PREFIX_ + "ITINERARY_TOGGLE_INFO";

// Ticket
export const TICKET_SET = "TICKET_SET";
export const TICKET_UPDATE_STATUS = "TICKET_UPDATE_STATUS";
export const TICKET_UPDATE = EDITOR_PREFIX_ + "TICKET_UPDATE";
export const TICKET_UPDATE_LOC = EDITOR_PREFIX_ + "TICKET_UPDATE_LOC";
export const TICKET_SPLICE_BANNER = EDITOR_PREFIX_ + "TICKET_SPLICE_BANNER";
export const TICKET_ADD_HIGHLIGHT = EDITOR_PREFIX_ + "TICKET_ADD_HIGHLIGHT";
export const TICKET_HIGHLIGHT_LOC = EDITOR_PREFIX_ + "TICKET_HIGHLIGHT_LOC";
export const TICKET_UPDATE_HIGHLIGHT =
  EDITOR_PREFIX_ + "TICKET_UPDATE_HIGHLIGHT";
export const TICKET_DELETE_HIGHLIGHT =
  EDITOR_PREFIX_ + "TICKET_DELETE_HIGHLIGHT";

// Accomodation
export const ACCOMODATION_SET = "ACCOMODATION_SET";
export const ACCOMODATION_UPDATE_STATUS = "ACCOMODATION_UPDATE_STATUS";
export const ACCOMODATION_UPDATE_LOC =
  EDITOR_PREFIX_ + "ACCOMODATION_UPDATE_LOC";
export const ACCOMODATION_SPLICE_BANNER =
  EDITOR_PREFIX_ + "ACCOMODATION_SPLICE_BANNER";
export const ACCOMODATION_UPDATE = EDITOR_PREFIX_ + "ACCOMODATION_UPDATE";
export const ACCOMODATION_ADD_HIGHLIGHT =
  EDITOR_PREFIX_ + "ACCOMODATION_ADD_HIGHLIGHT";
export const ACCOMODATION_UPDATE_HIGHLIGHT =
  EDITOR_PREFIX_ + "ACCOMODATION_UPDATE_HIGHLIGHT";
export const ACCOMODATION_DELETE_HIGHLIGHT =
  EDITOR_PREFIX_ + "ACCOMODATION_DELETE_HIGHLIGHT";
export const ACCOMODATION_ROOMS_ADD = EDITOR_PREFIX_ + "ACCOMODATION_ROOMS_ADD";
export const ACCOMODATION_ROOMS_UPDATE =
  EDITOR_PREFIX_ + "ACCOMODATION_ROOMS_UPDATE";
export const ACCOMODATION_ROOMS_DELETE =
  EDITOR_PREFIX_ + "ACCOMODATION_ROOMS_DELETE";
export const ACCOMODATION_TOGGLE_FEATURE =
  EDITOR_PREFIX_ + "ACCOMODATION_TOGGLE_FEATURE";
export const ACCOMODATION_TOGGLE_ROOM_FEATURES =
  EDITOR_PREFIX_ + "ACCOMODATION_TOGGLE_ROOM_FEATURES";

// Transport
export const TRANSPORT_SET = "TRANSPORT_SET";
export const TRANSPORT_UPDATE_STATUS = "TRANSPORT_UPDATE_STATUS";
export const TRANSPORT_UPDATE = EDITOR_PREFIX_ + "TRANSPORT_UPDATE";
export const TRANSPORT_UPDATE_LOC = EDITOR_PREFIX_ + "TRANSPORT_UPDATE_LOC";
export const TRANSPORT_SPLICE_BANNER =
  EDITOR_PREFIX_ + "TRANSPORT_SPLICE_BANNER";
export const TRANSPORT_ADD_HIGHLIGHT =
  EDITOR_PREFIX_ + "TRANSPORT_ADD_HIGHLIGHT";
export const TRANSPORT_UPDATE_HIGHLIGHT =
  EDITOR_PREFIX_ + "TRANSPORT_UPDATE_HIGHLIGHT";
export const TRANSPORT_DELETE_HIGHLIGHT =
  EDITOR_PREFIX_ + "TRANSPORT_DELETE_HIGHLIGHT";
export const TRANSPORT_TOGGLE_FEATURE =
  EDITOR_PREFIX_ + "TRANSPORT_TOGGLE_FEATURE";

// Dashboard

// Marketing
export const MARKETING_SET_CURRENT_PACKAGE = "MARKETING_SET_CURRENT_PACKAGE";
export const MARKETING_SET_WISHLIST_OPTION = "MARKETING_SET_WISHLIST_OPTION";
export const MARKETING_SET_SELECTED_ROOM = "MARKETING_SET_SELECTED_ROOM";
export const MARKETING_SET_HOME_LOADING = "MARKETING_SET_HOME_LOADING";
export const MARKETING_SET_HOME_FEATURE_LOADING =
  "MARKETING_SET_HOME_FEATURE_LOADING";
export const MARKETING_SET_HOME_PAGE_CONTEXT =
  "MARKETING_SET_HOME_PAGE_CONTEXT";
export const MARKETING_SET_READ_MORE_CONTEXT =
  "MARKETING_SET_READ_MORE_CONTEXT";
export const MARKETING_SET_LIGHTBOX_IMAGE = "MARKETING_SET_LIGHTBOX_IMAGE";
export const MARKETING_PUSH_REVIEW = "MARKETING_PUSH_REVIEW";
export const MARKETING_SPLICE_REVIEW = "MARKETING_SPLICE_REVIEW";
export const MARKETING_POP_REVIEW = "MARKETING_POP_REVIEW";
export const MARKETING_PUSH_REVIEW_VOTES = "MARKETING_PUSH_REVIEW_VOTES";
export const MARKETING_UPDATE_KEY = "MARKETING_UPDATE_KEY";

// Filter
export const FILTER_TOGGLE_CATEGORY = "FILTER_TOGGLE_CATEGORY";
export const FILTER_SET_PACKAGE = "FILTER_SET_PACKAGE";
export const FILTER_UPDATE_KEY = "FILTER_UPDATE_KEY";
export const FILTER_RESET = "FILTER_RESET";
export const FILTER_CLOSE_MINI = "FILTER_CLOSE_MINI";
export const FILTER_OPEN_MINI = "FILTER_OPEN_MINI";
export const FILTER_TOGGLE_MINI = "FILTER_TOGGLE_MINI";
export const FILTER_SET_RESULTS = "FILTER_SET_RESULTS";
export const FILTER_SET_REFRESHING = "FILTER_SET_REFRESHING";
export const FILTER_SET_ADDRESS = "FILTER_SET_ADDRESS";
export const FILTER_SET_RESULT_MODE = "FILTER_SET_RESULT_MODE";

// Chat
export const CHAT_ADD_INCOMING_THREAD = "CHAT_ADD_INCOMING_THREAD";

export const CHAT_LAUNCH_THREAD = "CHAT_LAUNCH_THREAD";
export const CHAT_POPULATE_ALL = "CHAT_POPULATE_ALL";
export const CHAT_MSG_PUSH = "CHAT_MSG_PUSH";
export const CHAT_THREAD_CLOSE = "CHAT_THREAD_CLOSE";
export const CHAT_ATTACH_INFO = "CHAT_ATTACH_INFO";
export const CHAT_CLEAR_CONVO = "CHAT_CLEAR_CONVO";
export const CHAT_ON_SELECT_THREAD_WIN = "CHAT_ON_SELECT_THREAD_WIN";

export const CHAT_V2_START_CONVERSATION = "CHAT_V2_START_CONVERSATION";
export const CHAT_V2_OPEN_MINI_CONVERSATION = "CHAT_V2_OPEN_MINI_CONVERSATION";
export const CHAT_V2_PUSH_CONVERSATION = "CHAT_V2_PUSH_CONVERSATION";
export const CHAT_V2_UPDATE_CONVERSATION = "CHAT_V2_UPDATE_CONVERSATION";
export const CHAT_V2_INIT_CONVERSATION = "CHAT_V2_INIT_CONVERSATION";
export const CHAT_V2_UPDATE_MSG_STATUS = "CHAT_V2_UPDATE_MSG_STATUS";
export const CHAT_V2_UPDATE_LAST_ACTIVE_ON = "CHAT_V2_UPDATE_LAST_ACTIVE_ON";
export const CHAT_V2_RESET_READ_COUNT = "CHAT_V2_RESET_READ_COUNT";
