import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "reactstrap";
import moment from "moment";
import Rating from "react-rating";
import { ReviewServices } from "../../services/Review.services";
import controllers from "../../store/controllers";
import { NETWORK_ERR_MESSAGE } from "../../resources/String";

export default connect(state => ({
  local: state.local
}))(function(props) {
  const { t } = useTranslation();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [composerOpen, setComposerOpen] = useState(false);
  const [busyReviews, setBusyReviews] = useState([]);
  const history = useHistory();
  const [idToUpdate, setIdToUpdate] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [rating, setRating] = useState(0);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const resourceType = props.resourceType;

  const reviews = props.reviews || [];
  const canInteract = props.canInteract;

  const deleteReview = () => {
    setDeleteModalOpen(false);
    setIsDeleting(true);
    ReviewServices.Delete(idToUpdate)
      .then(response => {
        controllers.marketing.popReview(
          idToUpdate,
          resourceType === "listing" ? "reviews" : "tripbonderReviews"
        );

        setModal(false);
      })
      .catch(err => {
        console.error(err);
        controllers.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
      });
  };

  const setModal = (value, payload) => {
    payload = Object.assign(
      {
        id: null,
        rating: 0,
        subject: "",
        message: ""
      },

      payload || {}
    );

    setDeleteModalOpen(false);
    setIsDeleting(false);
    setIdToUpdate(payload.id);
    setRating(payload.rating);
    setSubject(payload.subject);
    setMessage(payload.message);
    setDisplayName(payload.displayName || "");
    setComposerOpen(value);
  };

  const CastVote = (id, action) => {
    if (props.local.isAuthenticated === true) {
      let setBusyTimer = setTimeout(() => {
        setBusyReviews([...busyReviews, id]);
      }, 300);

      ReviewServices.Vote(id, action)
        .then(response => {
          // do nothing
          controllers.marketing.pushReviewVotes(
            id,
            response.review,
            resourceType === "listing" ? "reviews" : "tripbonderReviews"
          );

          setBusyReviews(busyReviews.filter(r => r !== id));
          clearTimeout(setBusyTimer);
        })
        .catch(err => {
          console.error(err);
          controllers.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
        });
    } else {
      history.push(`/auth/login/?returnUrl=${window.location.pathname}`);
    }
  };

  const PostReview = e => {
    e.preventDefault();
    if (idToUpdate) {
      controllers.setGlobalLoader("Updating review...", false);
      setTimeout(() => {
        ReviewServices.Update(idToUpdate, {
          resourceId: props.resourceId,
          resourceType: props.resourceType,
          rating,
          subject,
          message,
          displayName
        })
          .then(response => {
            controllers.marketing.spliceReview(
              idToUpdate,
              response,
              resourceType === "listing" ? "reviews" : "tripbonderReviews"
            );

            controllers.setGlobalSuccess("Review Updated", true);
            setTimeout(() => {
              controllers.clearGlobalMessage();
              setModal(false);
            }, 1200);
          })
          .catch(err => {
            controllers.setGlobalError(
              err.message || NETWORK_ERR_MESSAGE,
              true
            );
          });
      }, 700);
    } else {
      controllers.setGlobalLoader("Posting your feedback...", false);
      setTimeout(() => {
        ReviewServices.Compose({
          resourceId: props.resourceId,
          resourceType: props.resourceType,
          rating,
          subject,
          message,
          displayName
        })
          .then(response => {
            controllers.marketing.pushReview(
              response,
              resourceType === "listing" ? "reviews" : "tripbonderReviews"
            );

            controllers.setGlobalSuccess("Review Posted", true);
            setTimeout(() => {
              controllers.clearGlobalMessage();
              setModal(false);
            }, 1200);
          })
          .catch(err => {
            controllers.setGlobalError(
              err.message || NETWORK_ERR_MESSAGE,
              true
            );
          });
      }, 700);
    }
  };

  function renderModalContent() {
    const local = props.local;
    if (local.isAuthenticated === true) {
      let interceptedComponent =
        props.modalInterceptor && props.modalInterceptor({ setModal });
      if (interceptedComponent && idToUpdate === null) {
        return interceptedComponent;
      } else {
        return (
          <div className="review-modal-body-composer pb-4">
            <form onSubmit={e => PostReview(e)}>
              {props.local.isAuthenticated === true &&
              props.local.currentUser.roles.indexOf("admin") > -1 ? (
                <div id="accordion">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h5 className="mb-0">
                        <button
                          className="btn btn-link p-0"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          {t("Administrator Options")}
                        </button>
                      </h5>
                    </div>
                    <div
                      id="collapseOne"
                      className="collapse"
                      aria-labelledby="headingOne"
                      data-parent="#accordion"
                    >
                      <div className="card-body">
                        <div className="form-group">
                          <label className="font-13" htmlFor="displayNameInput">
                            {t("Display Name")}
                          </label>
                          <input
                            disabled={isDeleting}
                            value={displayName}
                            onChange={e => setDisplayName(e.target.value)}
                            type="text"
                            className="form-control form-control-sm"
                            id="displayNameInput"
                            aria-describedby="adminHelp"
                            placeholder={t("e.g. John Doe")}
                          />

                          <small
                            id="adminHelp"
                            className="form-text text-muted"
                          >
                            {t(
                              "This options is only available for administrators"
                            )}
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="form-row">
                <div className="col-12 text-center pt-3">
                  <h6>{t("Give your ratings")}</h6>
                  <Rating
                    readonly={isDeleting}
                    initialRating={rating}
                    onChange={v => setRating(v)}
                    emptySymbol="far fa-star fa-2x empty-star-color"
                    fullSymbol="fas fa-star fa-2x rated-star-color"
                    start={0}
                    stop={5}
                    fractions={2}
                  />

                  <hr className="mx-3" />
                </div>
                <div className="col-12 text-center">
                  <h6>{t("Give an appropriate title")}</h6>
                  <div className="mx-3 mx-sm-5 pt-2">
                    <input
                      disabled={isDeleting}
                      value={subject}
                      onChange={e => setSubject(e.target.value)}
                      style={{ fontSize: 13 }}
                      className="form-control"
                      placeholder={t("Subject...")}
                    ></input>
                  </div>
                  <hr className="mx-3" />
                </div>
                <div className="col-12 text-center">
                  <h6>{t("Write your review")}</h6>
                  <div className="mx-3 mx-sm-5 pt-2">
                    <textarea
                      disabled={isDeleting}
                      value={message}
                      onChange={e => setMessage(e.target.value)}
                      style={{ fontSize: 12 }}
                      rows="4"
                      className="form-control"
                      placeholder={t("Your message...")}
                    ></textarea>
                  </div>
                </div>
                <div className="col-12 text-center pt-4">
                  {idToUpdate ? (
                    <button
                      disabled={isDeleting}
                      onClick={() => setDeleteModalOpen(true)}
                      type="button"
                      className="btn btn-danger mr-3 px-5 py-2"
                    >
                      {isDeleting === true ? (
                        <span>
                          <i className="fas circle-notch fa-spin"></i>{" "}
                          {t("Deleting...")}
                        </span>
                      ) : (
                        "Delete"
                      )}
                    </button>
                  ) : (
                    <button
                      disabled={isDeleting}
                      onClick={() => setModal(false)}
                      type="button"
                      className="btn btn-light cancel-button mr-3 px-5 py-2"
                    >
                      {t("Cancel")}
                    </button>
                  )}

                  <button
                    disabled={
                      rating < 0.5 ||
                      subject === "" ||
                      message === "" ||
                      isDeleting
                    }
                    type="submit"
                    className="btn btn-info submit-button px-4 py-2"
                  >
                    {idToUpdate ? t("Update Review") : t("Submit Review")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        );
      }
    } else {
      return (
        <div className="review-modal-body-sign-in pb-4">
          <div className="form-row">
            <div className="col-12 text-center pt-4">
              <h6>{t("Sign in to continue")}</h6>
              <p>{t("You need to sign into Tripbonder to write a review")}</p>
            </div>
            <div className="col-12 text-center pt-4">
              <button
                onClick={() => setModal(false)}
                type="button"
                className="btn btn-light cancel-button mr-3 px-5 py-2"
              >
                {t("Cancel")}
              </button>
              <Link
                to={`/auth/login/?returnUrl=${window.location.pathname}`}
                type="button"
                className="btn btn-info btn-sm submit-button px-5 py-2"
              >
                {t("Sign in")}
              </Link>
            </div>
            <div className="col-12 text-center pt-5 px-5">
              <img
                height={100}
                style={{ borderRadius: 5 }}
                src={require("../../assets/images/travel-tips.png")}
                alt=""
              />

              <h5 className="pt-2">{t("#Travel Facts")}</h5>
              <p className="tips">
                {t(
                  "The world\u2019s most expensive coffee in the world comes from Bali. It is called Kopi Luwak and is not your typical coffee bean\u2026"
                )}
              </p>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <section className="container-fluid pb-5 reviewSystemWrapper review-container">
        {/* <div className="row px-md-5 mx-md-5">
           <div className="col-auto">
             <b>{t("Reviews")}</b>
           </div>
           <div className="col highlight-title d-flex flex-column justify-content-center">
             <hr />
           </div>
          </div> */}

        <div className="container">
          {reviews.length < 1 ? (
            <div
              className="alert alert-success text-center mt-3 font-13"
              role="alert"
            >
              {t("No Reviews Yet")}
            </div>
          ) : null}
          {reviews.map((r, i) => {
            let isLiked = false;
            let isUnliked = false;

            if (props.local.isAuthenticated === true) {
              isLiked = r.upVotes.indexOf(props.local.currentUser._id) > -1;
              isUnliked = r.downVotes.indexOf(props.local.currentUser._id) > -1;
            }
            const isVoteBusy = busyReviews.findIndex(i => i === r._id) > -1;
            return (
              <div key={i} className="row reviwSystemContent">
                <div className="col-12 px-0 pt-4">
                  <div className="d-flex justify-content-between">
                    <label>{r.effectiveDisplayName}</label>
                    {props.local.isAuthenticated === true &&
                    r.user === props.local.currentUser._id &&
                    canInteract ? (
                      <button
                        onClick={() => setModal(true, r)}
                        className="edit-btn"
                      >
                        <i className="far fa-edit"></i>
                      </button>
                    ) : null}
                  </div>
                  <div className="ratingStarWrapper">
                    <Rating
                      emptySymbol="far fa-star fa-2x empty-star-color"
                      fullSymbol="fas fa-star fa-2x rated-star-color"
                      initialRating={r.rating}
                      readonly={true}
                      start={0}
                      stop={5}
                      fractions={2}
                    />
                  </div>
                  <div>
                    <span>{moment(r.postedOn).format("ll")}</span>
                  </div>
                  <h6>{r.subject}</h6>
                  <p>{r.message}</p>
                  <div className="like-unlike-button-wrapper pb-3">
                    <button
                      disabled={isVoteBusy || !canInteract}
                      onClick={() => CastVote(r._id, "up")}
                    >
                      <i
                        className={`${
                          isLiked === true ? "fas" : "far"
                        } fa-thumbs-up like-button`}
                      ></i>
                    </button>
                    {r.effectiveupVoteCount > 0 ? (
                      <label className="mr-2">{r.effectiveupVoteCount}</label>
                    ) : null}
                    <button
                      disabled={isVoteBusy || !canInteract}
                      onClick={() => CastVote(r._id, "down")}
                    >
                      <i
                        className={`${
                          isUnliked === true ? "fas" : "far"
                        } fa-thumbs-down dislike-button`}
                      ></i>
                    </button>
                    {r.effectivedownVoteCount > 0 ? (
                      <label className="mr-2">{r.effectivedownVoteCount}</label>
                    ) : null}
                    {isVoteBusy === true ? (
                      <div style={{ color: "#ccc" }}>
                        <i className="fas fa-circle-notch fa-spin"></i>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
          {canInteract ? (
            <div className="row">
              <div className="col-12 text-center pt-4">
                <button
                  onClick={() => setModal(true)}
                  type="button"
                  className="btn btn-info btn-sm"
                >
                  {t("+ Write a review")}
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </section>
      <Modal
        isOpen={composerOpen}
        centered={true}
        toggle={e => setModal(false)}
        modalClassName="review-modal-Wrapper review-container"
      >
        <div className="modal-content">
          <div className="review-modal-header">
            <div className="form-row pb-3">
              <div className="col-12 text-right">
                <button onClick={() => setModal(false)}>
                  <i className="fas fa-times"></i>
                </button>
              </div>
              {props.Header}
            </div>
          </div>
          {renderModalContent()}
        </div>
      </Modal>
      <Modal
        isOpen={deleteModalOpen}
        toggle={() => setDeleteModalOpen(!deleteModalOpen)}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{t("Delete Confirmation")}</h5>
            <button
              onClick={() => setDeleteModalOpen(!deleteModalOpen)}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">{t("\xD7")}</span>
            </button>
          </div>
          <div className="modal-body">
            <p>{t("Do you want to delete this review?")}</p>
          </div>
          <div className="modal-footer">
            <button
              onClick={() => deleteReview()}
              type="button"
              className="btn btn-danger btn-danger-gradient"
            >
              {t("Yes, Delete")}
            </button>
            <button
              onClick={() => setDeleteModalOpen(!deleteModalOpen)}
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              {t("Cancel")}
            </button>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
});
