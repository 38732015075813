import Axios from "axios";

export const ContactDetails = {
  PostEnquiry: payload => {
    return new Promise((resolve, reject) => {
      Axios.post("/api/enquiries", payload)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  }
};
