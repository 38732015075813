import { Component } from "react";
export default class TawkController extends Component {
  constructor(props) {
    super(props);
    this.apiEnabled = window.Tawk_API ? true : false;
    this.api = this.apiEnabled ? window.Tawk_API : null;
  }

  componentDidMount() {
    if (this.apiEnabled) {
      if (!this.api.isChatHidden()) {
        this.api.hideWidget();
      }
    }
  }

  componentWillUnmount() {
    if (this.apiEnabled) {
      if (this.api.isChatHidden() === true) {
        this.api.showWidget();
      }
    }
  }

  render() {
    return null;
  }
}
