import Axios from "axios";
export const PackageServices = {
  GetComparePackages: listingIds => {
    return new Promise((resolve, reject) => {
      Axios.post(`/public/api/listings/compare`, {
        listingIds
      })
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  GetPackage: (id, isPrivate = false) => {
    return new Promise((resolve, reject) => {
      Axios.get(
        `/public/api/listings/${id}${isPrivate === true ? "?private=true" : ""}`
      )
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  Filter: (packageType, query) => {
    return new Promise((resolve, reject) => {
      Axios.post(`/public/api/listings/filter/${packageType}`, query)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  ViewCounter: id => {
    return new Promise((resolve, reject) => {
      Axios.put(`public/api/listings/track/${id}`)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  }
};
