import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import { Zoom } from "react-reveal";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import controllers from "../../store/controllers";
import {
  GetCurrentChatUser,
  FocusChatWindow,
  ResetChatWindow
} from "../../resources/Helper";

const Message = props => {
  const self = props.self;
  const content = props.content;
  const displayName = props.displayName;
  const status = props.status;
  const timestamp = moment(props.timestamp).calendar();
  let statusText = "";
  if (status === "sending") {
    statusText = "sending...";
  } else {
    statusText = timestamp;
  }
  return (
    <div className={`message-line ${self === true ? "self" : ""} ${status}`}>
      <label className="truncate">{displayName}</label>
      <div className="message-content pl-3 pr-4 py-2">
        <i className="fas fa-circle-notch fa-spin mr-1 p-0"></i>
        {content}
      </div>
      <small>{statusText}</small>
    </div>
  );
};
const ChatRoom = withTranslation()(
  class ChatRoom extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isBusy: false,
        message: "",
        emailAddress: "",
        displayName: "",
        isConnected: false,
        isChatRoomReady: props.preInitialize || false
      };

      this.roomId = null;
      this.io = controllers.getSocket();
      this.hasJoinedChatRoom = false;

      this.populateChatBox = this.populateChatBox.bind(this);
      this.handleReconnection = this.handleReconnection.bind(this);
      this.handleDisconnection = this.handleDisconnection.bind(this);
      this.renderEmailForm = this.renderEmailForm.bind(this);
      this.renderConversationThread = this.renderConversationThread.bind(this);
      this.joinRoom = this.joinRoom.bind(this);
      this.leaveRoom = this.leaveRoom.bind(this);
    }

    handleReconnection() {
      if (this.props.context._id !== null && this.hasJoinedChatRoom === true) {
        console.log(`Reconnecting... ${this.props.context._id}`);
        this.populateChatBox(true);
      }
    }

    handleDisconnection() {
      this.setState({
        isConnected: false
      });
    }

    getDisplayName() {
      return (
        this.props.chat.displayName ||
        (this.props.local.currentUser && this.props.local.currentUser.name)
      );
    }

    joinRoom(id) {
      const self = this;
      const { local } = self.props;
      self.hasJoinedChatRoom = true;
      self.io.emit("join-chat-room", id, (err, response) => {
        controllers.chat.resetReadCount(id, local.chatUserId, 0);
      });
      self.setState({
        isConnected: true,
        isChatRoomReady: true
      });
    }

    leaveRoom() {
      if (this.hasJoinedChatRoom === true) {
        const { context } = this.props;
        this.io.emit("leave-chat-room", context._id);
        this.roomId = null;
        this.hasJoinedChatRoom = false;
      }
    }

    populateChatBox(force = false) {
      const self = this;
      const { context } = self.props;
      controllers.chat
        .fetchConversation(context._id, force)
        .then(response => {
          if (response) {
            self.threadId = response._id;
            self.joinRoom(response._id);
            setTimeout(() => {
              ResetChatWindow(self.props.context._id);
              FocusChatWindow(self.props.context._id);
            }, 100);
          } else {
            self.joinRoom(self.props.context._id);
            setTimeout(() => {
              ResetChatWindow(self.props.context._id);
              FocusChatWindow(self.props.context._id);
            }, 100);
          }
        })
        .catch(err => {});
    }

    componentDidMount() {
      const { isChatRoomReady } = this.state;
      this.io.on("connect", this.handleReconnection);
      this.io.on("disconnect", this.handleDisconnection);
      if (isChatRoomReady) {
        this.populateChatBox();
      } else {
        setTimeout(() => {
          this.populateChatBox();
        }, 1200);
      }
    }

    componentWillUnmount() {
      this.io.off("connect", this.handleReconnection);
      this.io.off("disconnect", this.handleDisconnection);
      this.leaveRoom();
    }

    handleClose = e => {
      const context = this.props.context;
      controllers.chat.closeThread(context._id);
    };

    sendMessage = e => {
      e.preventDefault();
      const { context, local } = this.props;
      const { message } = this.state;
      if (message) {
        const participant = context.participants.find(
          p => p.chatUserId === local.chatUserId
        );

        const time = moment().valueOf();
        const draft = {
          conversation: context._id,
          chatUserId: participant.chatUserId,
          displayName: participant.displayName,
          content: message,
          messagedOn: time,
          sortIdentifier: time,
          status: "sending"
        };

        controllers.chat.pushMessages(context._id, [draft]);
        this.io.emit("post-message", context._id, message, time, function(
          err,
          response
        ) {
          if (!err && response) {
            // Update status
            controllers.chat.updateConversationLastActiveOn(
              context._id,
              response.threadLastActiveOn
            );

            controllers.chat.updateMessageStatus(
              context._id,
              time,
              response.message.status
            );
          } else {
            console.error(err);
            controllers.setGlobalError(
              "Failed to send message. Try again.",
              true
            );
          }
        });
        this.setState(
          {
            message: ""
          },

          () => {
            ResetChatWindow(this.props.context._id);
            FocusChatWindow(this.props.context._id);
          }
        );
      }
    };

    attachEmailAddress = e => {
      e.preventDefault();
      const { displayName, emailAddress } = this.state;
      if (displayName && emailAddress) {
        controllers.chat.setUserInfo(
          this.props.context._id,
          {
            displayName: displayName,
            emailAddress: emailAddress
          },

          this.props.local.chatUserId
        );

        setTimeout(() => {
          ResetChatWindow(this.props.context._id);
          FocusChatWindow(this.props.context._id);
        }, 300);
      } else {
        controllers.setGlobalError(
          "Please provide your name and email address",
          true
        );

        setTimeout(() => {
          controllers.clearGlobalMessage();
        }, 2300);
      }
    };

    renderEmailForm = () => {
      return (
        <Zoom duration={350}>
          <div className="message-area px-3 pb-3">
            <p className="desc">
              {this.props.t(
                "Please provide a name and email, or sign in to Tripbonder"
              )}
            </p>
            <form onSubmit={this.attachEmailAddress}>
              <div className="form-group mb-3">
                <input
                  autoComplete="off"
                  type="text"
                  value={this.state.displayName}
                  onChange={e => this.setState({ displayName: e.target.value })}
                  className="form-control p-3 form-control-sm"
                  id="chatNameInput"
                  placeholder={this.props.t("Your Name*")}
                />
              </div>
              <div className="form-group mb-3">
                <input
                  autoComplete="off"
                  type="email"
                  value={this.state.emailAddress}
                  onChange={e =>
                    this.setState({ emailAddress: e.target.value })
                  }
                  className="form-control p-3 form-control-sm"
                  id="chatEmail"
                  placeholder={this.props.t("Email Address*")}
                />
              </div>
              <div className="form-group mb-3">
                <button
                  type="submit"
                  className="btn btn-success-gradient btn-block"
                >
                  {this.props.t("Chat Now")}
                </button>
                <Link
                  className="btn btn-sm btn-link btn-block"
                  to={`/auth/login/?returnUrl=${window.location.pathname}`}
                >
                  {this.props.t("Sign In / Register Now")}
                </Link>
              </div>
            </form>
          </div>
        </Zoom>
      );
    };

    renderConversationThread = options => {
      const { local, context } = this.props;
      return (
        <>
          <div id={`message-area-${context._id}`} className="message-area">
            {(context.messages || [])
              .sort((a, b) => {
                if (a.sortIdentifier < b.sortIdentifier) {
                  return -1;
                }
                return 1;
              })
              .map(message => (
                <Message
                  key={message._id || message.sortIdentifier}
                  self={message.chatUserId === local.chatUserId}
                  displayName={message.displayName}
                  content={message.content}
                  timestamp={message.messagedOn}
                  status={message.status}
                />
              ))}
          </div>
          <div className="composer">
            <form onSubmit={this.sendMessage}>
              <input
                value={this.state.message}
                onChange={e => this.setState({ message: e.target.value })}
                placeholder={this.props.t("Ask your questions...")}
                type="text"
                id={`chat-room-msg-${context._id}`}
                autoComplete="off"
              />

              <div className="send-btn-wrapper">
                <button
                  disabled={this.state.isConnected === false}
                  type="submit"
                >
                  <div className="icon">
                    <i className="fas fa-location-arrow"></i>
                  </div>
                </button>
              </div>
            </form>
          </div>
        </>
      );
    };

    render() {
      const { isChatRoomReady, isConnected } = this.state;
      const context = this.props.context || {
        title: "",
        participants: []
      };

      const currentUser = GetCurrentChatUser(
        this.props.local.chatUserId,
        context.participants
      );

      if (currentUser) {
        const requireEmail = this.props.requireEmail || false;
        const currentEmailAddress = currentUser.emailAddress;
        const isEmailForm = requireEmail === true && !currentEmailAddress;
        const isResponsive = this.props.isResponsive || false;

        if (isChatRoomReady === true) {
          const receipients = context.participants.filter(
            p => p.chatUserId !== currentUser.chatUserId
          );

          let title;
          if (isChatRoomReady === false) {
            title = "Preparing...";
          } else if (isConnected) {
            title = receipients
              .map(r => {
                return r.group
                  ? `${r.group} - ${r.displayName}`
                  : r.displayName;
              })
              .join(", ");
          } else {
            title = "Connecting...";
          }

          return (
            <div
              className={`mini-chat-window ${
                isResponsive === true ? "responsive" : ""
              } ${isEmailForm === true ? "email-form" : ""}`}
            >
              <div className="title-bar">
                {isConnected === false ? (
                  <i
                    className="fas fa-circle-notch fa-spin"
                    style={{ color: "#8a8a8a" }}
                  ></i>
                ) : null}
                <label className="truncate">
                  {isEmailForm ? "Chat with Tripbonder" : title}
                </label>
                <button onClick={this.handleClose}>
                  <i className="fas fa-times"></i>
                </button>
              </div>
              {isEmailForm
                ? this.renderEmailForm()
                : this.renderConversationThread({ isEmailForm })}
            </div>
          );
        } else {
          return (
            <div
              className={`mini-chat-window ${
                isResponsive === true ? "responsive" : ""
              } ${isEmailForm === true ? "email-form" : ""}`}
            >
              <div className="title-bar">
                <label className="truncate">
                  {this.props.t("Connecting...")}
                </label>
                <button onClick={this.handleClose}>
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className="flex-fill first-loader">
                <i className="fas fa-circle-notch fa-spin"></i>
              </div>
            </div>
          );
        }
      } else {
        return null;
      }
    }
  }
);

export default connect(state => ({
  local: state.local,
  chat: state.chat
}))(ChatRoom);
