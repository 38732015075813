import Axios from "axios";
export const ProfileServices = {
  UpdateProfile: (key, body) => {
    return new Promise((resolve, reject) => {
      Axios.put(`/api/me/update/${key}`, body)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  UploadDisplayPicture: picture => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", picture);
      Axios.put("/api/me/update/picture", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  }
};
