import Axios from "axios";
export const CouponServices = {
  GetCoupons: () => {
    return new Promise((resolve, reject) => {
      Axios.get("/api/coupons")
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  CreateCoupon: payload => {
    return new Promise((resolve, reject) => {
      Axios.post("/api/coupons", payload)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  UpdateCoupon: (id, payload) => {
    return new Promise((resolve, reject) => {
      Axios.put(`/api/coupons/${id}`, payload)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  deleteCoupon: id => {
    return new Promise((resolve, reject) => {
      Axios.delete(`/api/coupons/${id}`)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  }
};
