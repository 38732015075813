import {
  MARKETING_SET_CURRENT_PACKAGE,
  MARKETING_SET_SELECTED_ROOM,
  MARKETING_SET_HOME_PAGE_CONTEXT,
  MARKETING_SET_HOME_LOADING,
  MARKETING_SET_WISHLIST_OPTION,
  MARKETING_SET_READ_MORE_CONTEXT,
  MARKETING_SET_LIGHTBOX_IMAGE,
  MARKETING_PUSH_REVIEW,
  MARKETING_PUSH_REVIEW_VOTES,
  MARKETING_SPLICE_REVIEW,
  MARKETING_POP_REVIEW,
  MARKETING_SET_HOME_FEATURE_LOADING,
  MARKETING_UPDATE_KEY
} from "../types";

const initialState = {
  currentPackage: null,
  selectedRoomIndex: 0,
  topDestinations: [],
  popularTrips: [],
  popularTickets: [],
  popularAccomodation: [],
  popularTransport: [],
  isLoading: true,
  isSilentLoading: false,
  hasInitialized: false,
  readMoreBody: "",
  readMoreSubject: "",
  readMoreBannerImageId: null,
  lightBoxImageId: null,
  hashTags: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case MARKETING_POP_REVIEW: {
      const { id, key } = action.payload;
      return Object.assign({}, state, {
        currentPackage: Object.assign({}, state.currentPackage || {}, {
          [key || "reviews"]: state.currentPackage[key || "reviews"].filter(
            (r, i) => r._id !== id
          )
        })
      });
    }
    case MARKETING_SPLICE_REVIEW: {
      const { id, value, key } = action.payload;
      return Object.assign({}, state, {
        currentPackage: Object.assign({}, state.currentPackage || {}, {
          [key || "reviews"]: state.currentPackage[key || "reviews"].map(
            (r, i) => {
              if (r._id === id) {
                return value;
              }
              return r;
            }
          )
        })
      });
    }
    case MARKETING_UPDATE_KEY: {
      const { key, value } = action.payload;
      return Object.assign({}, state, {
        [key]: value
      });
    }
    case MARKETING_PUSH_REVIEW_VOTES: {
      const { id, value, key } = action.payload;
      return Object.assign({}, state, {
        currentPackage: Object.assign({}, state.currentPackage || {}, {
          [key || "reviews"]: state.currentPackage[key || "reviews"].map(
            (r, i) => {
              if (r._id === id) {
                return Object.assign({}, r, {
                  upVotes: value.upVotes,
                  downVotes: value.downVotes,
                  upVoteCount: value.upVoteCount,
                  downVoteCount: value.downVoteCount,
                  effectiveupVoteCount: value.effectiveupVoteCount,
                  effectivedownVoteCount: value.effectivedownVoteCount
                });
              }
              return r;
            }
          )
        })
      });
    }
    case MARKETING_PUSH_REVIEW: {
      const { value, key } = action.payload;
      return Object.assign({}, state, {
        currentPackage: Object.assign({}, state.currentPackage || {}, {
          [key || "reviews"]: [
            ...(state.currentPackage[key || "reviews"] || []),
            value
          ]
        })
      });
    }
    case MARKETING_SET_WISHLIST_OPTION: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        currentPackage: Object.assign({}, state.currentPackage, {
          hasAddedToWishlist: value
        })
      });
    }
    case MARKETING_SET_HOME_LOADING: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        isLoading: value
      });
    }
    case MARKETING_SET_HOME_FEATURE_LOADING: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        isSilentLoading: value
      });
    }
    case MARKETING_SET_READ_MORE_CONTEXT: {
      const {
        readMore_Body,
        readMore_Subject,
        readMore_Image
      } = action.payload;
      return Object.assign({}, state, {
        readMoreBody: readMore_Body,
        readMoreSubject: readMore_Subject,
        readMoreBannerImageId: readMore_Image
      });
    }
    case MARKETING_SET_LIGHTBOX_IMAGE: {
      const { lightBoxImageId } = action.payload;
      return Object.assign({}, state, {
        lightBoxImageId: lightBoxImageId
      });
    }
    case MARKETING_SET_HOME_PAGE_CONTEXT: {
      const { context } = action.payload;
      return Object.assign({}, state, {
        topDestinations: context.topDestinations || [],
        popularTrips:
          (context.populatActivities && context.populatActivities.trips) || [],
        popularTickets:
          (context.populatActivities && context.populatActivities.tickets) ||
          [],
        popularAccomodation:
          (context.populatActivities &&
            context.populatActivities.accomodations) ||
          [],
        popularTransport:
          (context.populatActivities && context.populatActivities.transport) ||
          [],
        isLoading: false,
        isSilentLoading: false,
        hasInitialized: true
      });
    }
    case MARKETING_SET_CURRENT_PACKAGE: {
      const { listingPackage } = action.payload;
      return Object.assign({}, state, {
        currentPackage: listingPackage,
        selectedRoomIndex: 0
      });
    }
    case MARKETING_SET_SELECTED_ROOM: {
      const { index } = action.payload;
      return Object.assign({}, state, {
        selectedRoomIndex: index
      });
    }
    default: {
      return state;
    }
  }
}
