import { useTranslation, withTranslation } from "react-i18next";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import ImagePlaceholder from "../../components/Draft/ImagePlaceholder";
// import MapContainer from "../../components/Draft/Gmaps";
import controller from "../../store/controllers";
import { DraftControl } from "../../components/Draft/DraftControl";
import LocationSearchInput from "../../components/Draft/LocationSearchInput";
import { Helmet } from "react-helmet";
import GalleryController from "./../../components/MediaGallery/gallery.controller";
import TripViewer from "./../../components/TripViewer";
import controllers from "../../store/controllers";
import moment from "moment";
import { ListingServices } from "../../services/Listing.services";
import { HotKeys } from "react-hotkeys";
import { StatusAlert } from "../../components/Draft/StatusAlert";
import PublishModal from "../../components/Modals/PublishModal";
import PrivatePackageAlert from "../../components/Draft/PrivatePackageAlert";
import HideTawkTo from "./../../components/HideTawkTo";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import ChipInput from "material-ui-chip-input";

function Highlight(props) {
  const { t } = useTranslation();
  const { accomodation } = props;
  const [hintContext, setHintContext] = useState({});
  function setHighlightImage() {
    GalleryController.insertMedia("", "", {}, mediaObj => {
      controller.accomodation.updateHighlight(
        props.index,
        "imageId",
        mediaObj._id
      );
    });
  }
  const _getHintStateId = key => {
    return `_${key}_HINT`;
  };

  const getIsHintOpen = key => {
    if (hintContext[_getHintStateId(key)]) {
      return hintContext[_getHintStateId(key)];
    } else {
      return false;
    }
  };

  const toggleIsHintOpen = key => {
    setHintContext(
      Object.assign({}, hintContext, {
        [_getHintStateId(key)]: !getIsHintOpen(key)
      })
    );
  };
  return (
    <React.Fragment>
      <div className="highlights row mt-3">
        <div className="col-12">
          <div className="card-title">
            {t("#")}
            {props.index + 1} {t("Highlight")}
            {accomodation._status === "draft" ? (
              <button
                className="close"
                type="button"
                data-toggle="modal"
                data-target="#deletePrompt"
                onClick={e => props.onDelete && props.onDelete(props.index)}
              >
                <i className="fas fa-times"></i>
              </button>
            ) : null}
          </div>
          <div className="row highlight-content-wrapper mx-0">
            <div className="col-12 col-lg-2 px-0">
              <ImagePlaceholder
                disabled={accomodation._status !== "draft"}
                message={t(
                  "Click to add image. Image cannot be larger than 2MB"
                )}
                height="150px"
                border="none"
                value={controller.resolveAssetAddr(props.item.imageId)}
                onClick={() => setHighlightImage()}
                onRemove={() =>
                  controller.accomodation.updateHighlight(
                    props.index,
                    "imageId",
                    null
                  )
                }
              />
            </div>
            <div className="col-12 pt-4  col-lg-8">
              <div className="form-group px-3 px-lg-0">
                <label className="header">
                  {t("WHY IS IT A MUST DO OR SEE?")}
                  <button
                    id="event-highlight-description"
                    className="hint-btn"
                    type="button"
                  >
                    {"?"}
                  </button>
                  <Popover
                    trigger="legacy"
                    placement="right"
                    isOpen={getIsHintOpen("event-highlight-description")}
                    target="event-highlight-description"
                    toggle={() =>
                      toggleIsHintOpen("event-highlight-description")
                    }
                  >
                    <PopoverHeader>{t("Points to consider")}</PopoverHeader>
                    <PopoverBody>
                      <ol>
                        <li>{t("Enter highlights of this accomodation")}</li>
                        <li>{t("Try to keep the title short and precise")}</li>
                      </ol>
                    </PopoverBody>
                    <PopoverHeader>{t("Examples")}</PopoverHeader>
                    <PopoverBody>
                      <ol>
                        <li>
                          {t(
                            "Because of its rugged terrain, and abundant rainfall, the rivers in Coorg have challenging grades, ranging from I to V"
                          )}
                        </li>
                        <li>
                          {t(
                            "Dubai Skydive, a leader in extreme skydiving, has broken yet another Guinness World Record in less than a month for BASE jumping off the very tip of The Burj Khalifa"
                          )}
                        </li>
                      </ol>
                    </PopoverBody>
                  </Popover>
                </label>
                <textarea
                  readOnly={accomodation._status !== "draft"}
                  value={props.item.description}
                  onChange={e =>
                    controller.accomodation.updateHighlight(
                      props.index,
                      "description",
                      e.target.value
                    )
                  }
                  className="form-control"
                  id="tripDesc"
                  rows="1"
                  placeholder={t("Description")}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
function Room(props) {
  const { t } = useTranslation();
  const { accomodation } = props;
  const [hintContext, setHintContext] = useState({});
  function setDetailsHighlightImage() {
    GalleryController.insertMedia("", "", {}, mediaObj => {
      controller.accomodation.updateDetailsHighlight(
        props.index,
        "imageId",
        mediaObj._id
      );
    });
  }
  const _getHintStateId = key => {
    return `_${key}_HINT`;
  };

  const getIsHintOpen = key => {
    if (hintContext[_getHintStateId(key)]) {
      return hintContext[_getHintStateId(key)];
    } else {
      return false;
    }
  };

  const toggleIsHintOpen = key => {
    setHintContext(
      Object.assign({}, hintContext, {
        [_getHintStateId(key)]: !getIsHintOpen(key)
      })
    );
  };
  return (
    <>
      <div className="card-title mt-4">
        {" "}
        {t("#")}
        {props.index + 1}
        {t("Stay")}
        {accomodation._status === "draft" ? (
          <button
            className="close"
            type="button"
            data-toggle="modal"
            data-target="#deletePrompt"
            onClick={e => props.onDelete && props.onDelete(props.index)}
          >
            <i className="fas fa-times"></i>
          </button>
        ) : null}
      </div>
      <div className="row">
        <div className="col-12 col-lg-4">
          <ImagePlaceholder
            disabled={accomodation._status !== "draft"}
            message={t("Click to add image. Image cannot be larger than 2MB")}
            height="300px"
            value={controller.resolveAssetAddr(props.item.imageId)}
            onClick={() => setDetailsHighlightImage()}
            onRemove={() =>
              controller.accomodation.updateDetailsHighlight(
                props.index,
                "imageId",
                null
              )
            }
          />
        </div>
        <div className="col-12 col-lg-8">
          <div className="row">
            <div className="col-12 col-lg-6">
              <label
                className="pt-3 pt-lg-0 header"
                htmlFor="accomodation-room-type"
              >
                {t("ACCOMMODATION ROOM TYPE")}
              </label>
              <input
                readOnly={accomodation._status !== "draft"}
                value={props.item.roomType}
                onChange={e =>
                  controller.accomodation.updateDetailsHighlight(
                    props.index,
                    "roomType",
                    e.target.value
                  )
                }
                type="text"
                className="form-control"
                id="accomodation-room-type"
                placeholder={t("Type")}
              />

              <label className="pt-3 header" htmlFor="whats-included">
                {t("WHAT'S INCLUDED")}
                <button id="whats-included" className="hint-btn" type="button">
                  {"?"}
                </button>
                <Popover
                  trigger="legacy"
                  placement="right"
                  isOpen={getIsHintOpen("whats-included")}
                  target="whats-included"
                  toggle={() => toggleIsHintOpen("whats-included")}
                >
                  <PopoverHeader>{t("Points to consider")}</PopoverHeader>
                  <PopoverBody>
                    <ol>
                      <li>{t("Short description about the room type")}</li>
                      <li>{t("Try to mark in points")}</li>
                    </ol>
                  </PopoverBody>
                  <PopoverHeader>{t("Examples")}</PopoverHeader>
                  <PopoverBody>
                    <ol>
                      <li>{t("Stay is pet friendly")}</li>
                      <li>
                        {t(
                          "Room can accomodate 2 adults and 2 children, extra bed can be provided"
                        )}
                      </li>
                    </ol>
                  </PopoverBody>
                </Popover>
              </label>
              <textarea
                readOnly={accomodation._status !== "draft"}
                value={props.item.description}
                onChange={e =>
                  controller.accomodation.updateDetailsHighlight(
                    props.index,
                    "description",
                    e.target.value
                  )
                }
                className="form-control"
                id="whats-included"
                rows="5"
                placeholder={t("Description")}
              ></textarea>
              <label className="pt-3 header">{t("PRICE (PER PERSON)")}</label>
              <div className="input-group flex-nowrap">
                <div className="input-group-prepend">
                  <span
                    className="input-group-text mb-0 py-0"
                    id="addon-wrapping"
                  >
                    {t("USD")}
                  </span>
                </div>
                <input
                  readOnly={accomodation._status !== "draft"}
                  value={props.item.price || ""}
                  onChange={e =>
                    controller.accomodation.updateDetailsHighlight(
                      props.index,
                      "price",
                      e.target.value
                    )
                  }
                  type="number"
                  step="0.01"
                  className="form-control"
                  placeholder={t("$ Per Person")}
                  aria-label="Username"
                  aria-describedby="addon-wrapping"
                />
              </div>
              {props.item.extraBedAvailable === true ? (
                <>
                  <label className="pt-3 header">
                    {t("PRICE (PER EXTRA BED)")}
                    <small>{t("For Children")}</small>
                  </label>
                  <div className="input-group flex-nowrap">
                    <div className="input-group-prepend">
                      <span
                        className="input-group-text mb-0 py-0"
                        id="addon-wrapping"
                      >
                        {t("USD")}
                      </span>
                    </div>
                    <input
                      readOnly={accomodation._status !== "draft"}
                      value={props.item.priceExtraBed || ""}
                      onChange={e =>
                        controller.accomodation.updateDetailsHighlight(
                          props.index,
                          "priceExtraBed",
                          e.target.value
                        )
                      }
                      type="number"
                      step="0.01"
                      className="form-control"
                      placeholder={t("$ Per Extra-Bed")}
                      aria-label="Username"
                      aria-describedby="addon-wrapping"
                    />
                  </div>
                </>
              ) : null}
            </div>
            <div className="col-12 col-lg-6 pl-lg-5">
              <label className="pt-3 pt-lg-0 header">{t("CAPACITY")}</label>
              {props.local.capacityMaster.map(capacity => (
                <div key={capacity.value} className="form-check">
                  <input
                    disabled={accomodation._status !== "draft"}
                    type="checkbox"
                    className="form-check-input"
                    onChange={e =>
                      controller.accomodation.toggleRoomFeature(
                        props.index,
                        "capacityInfo",
                        capacity.value
                      )
                    }
                    checked={
                      props.item.capacityInfo.indexOf(capacity.value) > -1
                    }
                    id={`check-box-${capacity.value}-${props.index}`}
                  />

                  <label
                    className="form-check-label"
                    htmlFor={`check-box-${capacity.value}-${props.index}`}
                  >
                    {t(capacity.label)}
                  </label>
                </div>
              ))}

              <div className="form-check">
                <input
                  disabled={accomodation._status !== "draft"}
                  type="checkbox"
                  className="form-check-input"
                  onChange={e =>
                    controller.accomodation.updateDetailsHighlight(
                      props.index,
                      "extraBedAvailable",
                      e.target.checked
                    )
                  }
                  checked={props.item.extraBedAvailable}
                  id={`check-box-extra-bed-${props.index}`}
                />

                <label
                  className="form-check-label"
                  htmlFor={`check-box-extra-bed-${props.index}`}
                >
                  {t("Extra Bed Available for Children")}
                </label>
              </div>
              <label className="pt-3 header">{t("THE SPACE HAS")}</label>
              {props.spaceMaster.map(space => (
                <div key={space.value} className="form-check">
                  <input
                    disabled={accomodation._status !== "draft"}
                    type="checkbox"
                    className="form-check-input"
                    onChange={e =>
                      controller.accomodation.toggleRoomFeature(
                        props.index,
                        "spaceInfo",
                        space.value
                      )
                    }
                    checked={props.item.spaceInfo.indexOf(space.value) > -1}
                    id={`check-box-${space.value}-${props.index}`}
                  />

                  <label
                    className="form-check-label"
                    htmlFor={`check-box-${space.value}-${props.index}`}
                  >
                    {t(space.label)}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const AccomodationSetup = withTranslation()(
  class AccomodationSetup extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        autoSaving: false,
        saving: false,
        keyToDelete: "",
        indexToDelete: -1,
        isPublishDialogOpen: false,
        isPreviewOpen: false
      };

      this.setDeleteContext = this.setDeleteContext.bind(this);
      this.proceedDelete = this.proceedDelete.bind(this);

      this.loadAccomodation = this.loadAccomodation.bind(this);
      this.saveAccomodation = this.saveAccomodation.bind(this);
      this.onPublish = this.onPublish.bind(this);
      this.onTogglePublish = this.onTogglePublish.bind(this);
      this.runAutoSaver = this.runAutoSaver.bind(this);
      this.stopAutoSaver = this.stopAutoSaver.bind(this);
      this.handleSaveHotKey = this.handleSaveHotKey.bind(this);
    }
    // Hint Functions BEGIN

    _getHintStateId(key) {
      return `_${key}_HINT`;
    }

    getIsHintOpen(key) {
      if (this.state[this._getHintStateId(key)]) {
        return this.state[this._getHintStateId(key)];
      } else {
        return false;
      }
    }

    toggleIsHintOpen(key) {
      this.setState({
        [this._getHintStateId(key)]: !this.getIsHintOpen(key)
      });
    }

    // Hint Functions END
    setDeleteContext(keyToDelete, indexToDelete) {
      this.setState({ keyToDelete, indexToDelete });
    }

    proceedDelete() {
      const { keyToDelete, indexToDelete } = this.state;
      switch (keyToDelete) {
        case "highlight": {
          controller.accomodation.deleteHighlight(indexToDelete);
          this.setDeleteContext("", -1);
          break;
        }
        case "highlightdetail": {
          controller.accomodation.deleteDetailsHighlight(indexToDelete);
          this.setDeleteContext("", -1);
          break;
        }
        default: {
          break;
        }
      }
    }
    addCoverImage(index) {
      GalleryController.insertMedia(
        "Upload Accomodation Cover",
        "Any attractive picture must get you the thing you wanted",
        {},
        mediaObj => {
          controller.accomodation.replaceAccomodationBanner(
            index,
            mediaObj._id
          );
        }
      );
    }

    loadAccomodation(props) {
      const {
        match: {
          params: { id }
        }
      } = props || this.props;
      controllers.setGlobalLoader("Preparing Editor...", false);
      ListingServices.GetListingById("accomodation", id)
        .then(accomodation => {
          controllers.resetEditorSaveState();
          setTimeout(() => {
            controllers.setEditorListing(accomodation);
            controllers.marketing.setCurrentPackage(accomodation);
            controllers.accomodation.setAccomodationEditorContext(
              accomodation.package,
              accomodation.status
            );

            controllers.clearGlobalMessage();
            this.titleInputRef && this.titleInputRef.focus();
            this.runAutoSaver();
          }, 1000);
        })
        .catch(err => {
          controllers.setGlobalError("Package not found", false);
        });
    }

    runAutoSaver() {
      const self = this;
      const lastSavePrefix = "last saved ";
      self.setState(
        {
          autoSaving: false,
          lastSavedOn: null
        },

        () => {
          self.autoSaver =
            (self.autoSaver && clearInterval(self.autoSaver)) || null;
          let isModified = false;
          self.autoSaver = setInterval(() => {
            if (self.state.saving === false) {
              isModified =
                self.props.local.editorLastModifiedOn >
                self.props.local.editorLastSavedOn;
              if (isModified === true) {
                let timeDifference =
                  new Date().getTime() - self.props.local.editorLastModifiedOn;
                if (timeDifference > 2500) {
                  self.setState(
                    {
                      saving: true,
                      autoSaving: true
                    },

                    () =>
                      self
                        .saveAccomodation(true)
                        .then(() =>
                          self.setState({
                            saving: false,
                            autoSaving: false,
                            lastSavedOn: `${lastSavePrefix}${moment(
                              self.props.local.editorLastSavedOn
                            ).fromNow()}`
                          })
                        )
                        .catch(() =>
                          self.setState({ saving: false, autoSaving: false })
                        )
                  );
                }
              }
            }

            if (self.props.local.editorLastSavedOn > 0) {
              self.setState({
                lastSavedOn: `${lastSavePrefix}${moment(
                  self.props.local.editorLastSavedOn
                ).fromNow()}`
              });
            }
          }, 1800);
        }
      );
    }

    stopAutoSaver() {
      this.autoSaver && clearInterval(this.autoSaver);
    }

    saveAccomodation(silent = false) {
      return new Promise((resolve, reject) => {
        const {
          accomodation,
          match: {
            params: { id }
          }
        } = this.props;
        this.setState(
          {
            saving: true
          },

          () => {
            !silent && controllers.setGlobalLoader("Saving...", false);
            ListingServices.Save("accomodation", id, accomodation)
              .then(response => {
                this.setState(
                  {
                    saving: silent
                  },

                  () => {
                    controllers.marketing.setCurrentPackage(
                      Object.assign(this.props.local.editorListingSnapshot, {
                        package: response
                      })
                    );

                    controllers.setEditorSaved();
                    !silent &&
                      setTimeout(() => {
                        !silent && controllers.setGlobalSuccess("Saved", true);
                        setTimeout(() => {
                          !silent && controllers.clearGlobalMessage();
                          resolve(true);
                        }, 1700);
                      }, 700);
                    silent && setTimeout(() => resolve(true), 300);
                  }
                );
              })
              .catch(err => {
                err = (err.data && err.data.message) || err.message;
                !silent && controllers.setGlobalError(err, true);
                reject(err);
              });
          }
        );
      });
    }

    onPublish() {
      this.setState({
        isPublishDialogOpen: true
      });
    }

    onTogglePublish() {
      this.setState({
        isPublishDialogOpen: false
      });
    }

    handleSaveHotKey(e) {
      e.preventDefault();
      this.saveAccomodation(false);
    }

    handlers = {
      SAVE: this.handleSaveHotKey.bind(this)
    };

    keyMap = {
      SAVE: "command+s"
    };

    componentDidMount() {
      this.loadAccomodation();
    }

    componentWillReceiveProps(newProps) {
      const newListingId = newProps.match.params.id;
      const oldListingId = this.props.match.params.id;
      if (newListingId !== oldListingId) {
        // Detected Change
        this.loadAccomodation(newProps);
      }
    }

    componentWillUnmount() {
      this.stopAutoSaver();
      controllers.clearGlobalMessage();
    }

    render() {
      const { accomodation, local, match } = this.props;
      const { autoSaving, lastSavedOn, isPublishDialogOpen } = this.state;

      // Calculate save status
      let saveStatus = "";

      if (autoSaving === true) {
        saveStatus = "Auto saving...";
      } else {
        if (lastSavedOn) {
          saveStatus = lastSavedOn;
        }
      }

      return (
        <React.Fragment>
          <HideTawkTo />
          <HotKeys keyMap={this.keyMap} handlers={this.handlers}>
            <Helmet>
              <title>
                {this.props.t("Create Accommodation - Tripbonder.com")}
              </title>
            </Helmet>
            <div className="container-fluid trip-setup">
              <div className="row position-relative">
                <div className="col-12 pr-md-5">
                  <p
                    className="lead"
                    style={{ color: "#42939D", fontSize: 15, fontWeight: 500 }}
                  >
                    {this.props.t("ACCOMMODATION SETUP")}
                  </p>
                  <h3>
                    <b>{this.props.t("Create your accommodation services")}</b>
                  </h3>
                  <p>
                    {this.props.t(
                      "This will appear as your main product and will appear in search results and in trip detail top banner"
                    )}
                  </p>
                </div>
                <div
                  className="balloon d-none d-md-block"
                  style={{
                    backgroundImage: `url('${require("../../assets/images/balloon.png")}')`
                  }}
                ></div>
              </div>
              <StatusAlert
                statusKey={accomodation._status}
                listingStartDate={
                  local.editorListingSnapshot &&
                  local.editorListingSnapshot.listingStartDate
                }
                listingEndDate={
                  local.editorListingSnapshot &&
                  local.editorListingSnapshot.listingEndDate
                }
              />

              {local.editorListingSnapshot &&
              local.editorListingSnapshot.class === "private" ? (
                <PrivatePackageAlert id={local.editorListingSnapshot._id} />
              ) : null}
              <div
                className="accordion indicator-plus-before round-indicator"
                id="accordionH"
                aria-multiselectable="true"
              >
                <div className="card m-b-0">
                  <div
                    className="card-header text-center mb-2"
                    role="tab"
                    id="headingOneH"
                    data-target="#collapseOneH"
                    data-toggle="collapse"
                    data-parent="#accordionH"
                    aria-expanded="false"
                    aria-controls="collapseOneH"
                  >
                    <span className="card-title">
                      {this.props.t("Basic Information")}
                    </span>
                  </div>
                  <div
                    className="collapse show"
                    id="collapseOneH"
                    role="tabpanel"
                    aria-labelledby="headingOneH"
                  >
                    <div className="card-body p-0 p-sm-2">
                      <div className="form-row">
                        <div className="form-group col-12 col-lg-6">
                          <label
                            className="header pt-2"
                            htmlFor="name-of-accomodation"
                          >
                            {this.props.t("NAME OF ACCOMMODATION")}
                            <button
                              id="accomodation-name-popover"
                              className="hint-btn"
                              type="button"
                            >
                              {"?"}
                            </button>
                            <Popover
                              trigger="legacy"
                              placement="right"
                              isOpen={this.getIsHintOpen("accomodation-name")}
                              target="accomodation-name-popover"
                              toggle={() =>
                                this.toggleIsHintOpen("accomodation-name")
                              }
                            >
                              <PopoverHeader>
                                {this.props.t("Name of accommodation")}
                              </PopoverHeader>
                              <PopoverHeader>
                                {this.props.t("Points to consider")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>
                                    {this.props.t(
                                      "Catchy and attractive title"
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t(
                                      "Must include the name of location"
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t(
                                      "Try to keep the title short and precise"
                                    )}
                                  </li>
                                </ol>
                              </PopoverBody>
                              <PopoverHeader>
                                {this.props.t("Examples")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>{this.props.t("Hotel Leela Kovalam")}</li>
                                  <li>{this.props.t("Hotel Ritz Paris")}</li>
                                </ol>
                              </PopoverBody>
                            </Popover>
                          </label>
                          <input
                            readOnly={accomodation._status !== "draft"}
                            value={accomodation.title}
                            onChange={e =>
                              controller.accomodation.updateAccomodation(
                                "title",
                                e.target.value
                              )
                            }
                            type="text"
                            className="form-control"
                            id="name-of-accomodation"
                            placeholder={this.props.t("Name")}
                          />

                          <label
                            className="header pt-2"
                            htmlFor="location-of-the-accomodation"
                          >
                            {this.props.t(
                              "LOCATION OF THE ACCOMMODATION - COUNTRY,CITY"
                            )}
                          </label>
                          <LocationSearchInput
                            readOnly={accomodation._status !== "draft"}
                            id="trip-location"
                            geoLocation={accomodation.geoLocation}
                            value={accomodation.location}
                            onChange={location =>
                              controller.accomodation.updateAccomodationLocation(
                                location
                              )
                            }
                          />

                          <label
                            className="header pt-2"
                            htmlFor="description-of-accomodation"
                          >
                            {this.props.t("DESCRIPTION OF ACCOMMODATION")}
                            <button
                              id="accomodation-description-popover"
                              className="hint-btn"
                              type="button"
                            >
                              {"?"}
                            </button>
                            <Popover
                              trigger="legacy"
                              placement="right"
                              isOpen={this.getIsHintOpen(
                                "accomodation-description"
                              )}
                              target="accomodation-description-popover"
                              toggle={() =>
                                this.toggleIsHintOpen(
                                  "accomodation-description"
                                )
                              }
                            >
                              <PopoverHeader>
                                {this.props.t("Points to consider")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>
                                    {this.props.t(
                                      "Should provide a brief description about the accommodation(maximum 6 to 8 sentences)"
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t(
                                      "Should include facilities provided by the accommodation"
                                    )}
                                  </li>
                                </ol>
                              </PopoverBody>
                              <PopoverHeader>
                                {this.props.t("Example")}
                              </PopoverHeader>
                              <PopoverBody>
                                <p>
                                  {this.props.t(
                                    "At The Ritz-Carlton Dubai, guests can experience varieties of themed restaurants ranging from flavors of Asia, Arabia and the Mediterranean including the new beach restaurant Palm Grill overlooking the Arabian Gulf. The Ritz-Carlton Spa offers a variety of signature treatments and therapies, complemented by a state-of-the-art gym, a swimming pool and a hammam area. The Ritz Kids program offers daily supervised activities for children, including handicrafts and origami workshops, story time, and even cooking activities."
                                  )}
                                </p>
                              </PopoverBody>
                            </Popover>
                          </label>
                          <textarea
                            readOnly={accomodation._status !== "draft"}
                            value={accomodation.description}
                            onChange={e =>
                              controller.accomodation.updateAccomodation(
                                "description",
                                e.target.value
                              )
                            }
                            className="form-control"
                            id="description-of-accomodation"
                            rows="5"
                            placeholder={this.props.t("Description")}
                          ></textarea>
                          <label
                            className="header pt-2"
                            htmlFor="other-information"
                          >
                            {this.props.t("OTHER INFORMATION")}
                            <button
                              id="accomodation-other-information-popover"
                              className="hint-btn"
                              type="button"
                            >
                              {"?"}
                            </button>
                            <Popover
                              trigger="legacy"
                              placement="right"
                              isOpen={this.getIsHintOpen(
                                "accomodation-other-information"
                              )}
                              target="accomodation-other-information-popover"
                              toggle={() =>
                                this.toggleIsHintOpen(
                                  "accomodation-other-information"
                                )
                              }
                            >
                              <PopoverHeader>
                                {this.props.t("Points to consider")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>
                                    {this.props.t(
                                      "You can include things like additional services and tips provided by the accommodation"
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t("Try to write in points")}
                                  </li>
                                </ol>
                              </PopoverBody>
                              <PopoverHeader>
                                {this.props.t("Examples")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>
                                    {this.props.t(
                                      "Infants aged 0-2 are free of charge"
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t("Additional bed provided")}
                                  </li>
                                </ol>
                              </PopoverBody>
                            </Popover>
                          </label>
                          <textarea
                            readOnly={accomodation._status !== "draft"}
                            value={accomodation.otherInformation}
                            onChange={e =>
                              controller.accomodation.updateAccomodation(
                                "otherInformation",
                                e.target.value
                              )
                            }
                            className="form-control"
                            id="other-information"
                            rows="5"
                            placeholder={this.props.t(
                              "Other booking related information"
                            )}
                          ></textarea>

                          <div className="form-row mt-3">
                            <div className="col-12 form-group">
                              <label className="header d-block mb-3">
                                {this.props.t("TAGS")}
                                <button
                                  id="accomodation-tags"
                                  className="hint-btn"
                                  type="button"
                                >
                                  {"?"}
                                </button>
                                <Popover
                                  trigger="legacy"
                                  placement="right"
                                  isOpen={this.getIsHintOpen(
                                    "accomodation-tags"
                                  )}
                                  target="accomodation-tags"
                                  toggle={() =>
                                    this.toggleIsHintOpen("accomodation-tags")
                                  }
                                >
                                  <PopoverHeader>
                                    {this.props.t("Points to consider")}
                                  </PopoverHeader>
                                  <PopoverBody>
                                    <ol>
                                      <li>
                                        {this.props.t(
                                          "Tag should not be too long"
                                        )}
                                      </li>
                                      <li>
                                        {this.props.t("Do not begin with #")}
                                      </li>
                                    </ol>
                                  </PopoverBody>
                                  <PopoverHeader>
                                    {this.props.t("Examples")}
                                  </PopoverHeader>
                                  <PopoverBody>
                                    <ol>
                                      <li>{this.props.t("nature")}</li>
                                      <li>{this.props.t("trekking")}</li>
                                      <li>{this.props.t("cruise")}</li>
                                    </ol>
                                  </PopoverBody>
                                </Popover>
                              </label>
                              <ChipInput
                                disabled={accomodation._status !== "draft"}
                                alwaysShowPlaceholder={true}
                                value={accomodation.tags}
                                onAdd={chip =>
                                  controller.accomodation.updateAccomodation(
                                    "tags",
                                    [
                                      ...accomodation.tags,
                                      chip.toLowerCase().replace("#", "")
                                    ]
                                  )
                                }
                                onDelete={(chip, index) =>
                                  controller.accomodation.updateAccomodation(
                                    "tags",
                                    accomodation.tags.filter(
                                      (it, _index) => _index !== index
                                    )
                                  )
                                }
                                placeholder={this.props.t("Add tags...")}
                                newChipKeys={["Tab", "Enter"]}
                                newChipKeyCodes={[32]}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div
                         className="form-group col-12 col-lg-6"
                         style={{ height: 330 }}
                         >
                         <MapContainer />
                         </div> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-header collapsed text-center mb-2"
                    role="tab"
                    id="headingTwoH"
                    data-target="#collapseTwoH"
                    data-toggle="collapse"
                    data-parent="#accordionH"
                    aria-expanded="false"
                    aria-controls="collapseTwoH"
                  >
                    <span className="card-title">
                      {this.props.t("Cover Art & Highlights")}
                    </span>
                  </div>
                  <div
                    className="collapse"
                    id="collapseTwoH"
                    role="tabpanel"
                    aria-labelledby="headingTwoH"
                  >
                    <div className="card-body p-0">
                      <div className="row cover-art-wrapper p-sm-2 p-0">
                        <div className="col-12 col-xl-4 title-wrapper">
                          <span className="card-title head mb-3">
                            {this.props.t("Cover Art")}
                            <button
                              id="accomodation-cover-art-popover"
                              className="hint-btn"
                              type="button"
                            >
                              {"?"}
                            </button>
                            <Popover
                              trigger="legacy"
                              placement="right"
                              isOpen={this.getIsHintOpen(
                                "accomodation-cover-art"
                              )}
                              target="accomodation-cover-art-popover"
                              toggle={() =>
                                this.toggleIsHintOpen("accomodation-cover-art")
                              }
                            >
                              <PopoverHeader>
                                {this.props.t("Points to consider")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>
                                    {this.props.t(
                                      "Should Upload images less than 2mb"
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t(
                                      "Should not upload blur images"
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t(
                                      "Cover art should give an idea about the accommodation"
                                    )}
                                  </li>
                                </ol>
                              </PopoverBody>
                              <PopoverHeader>
                                {this.props.t("Example")}
                              </PopoverHeader>
                              <PopoverBody>
                                <div className="text-center">
                                  <img
                                    height={150}
                                    src={require("../../assets/images/ritz.jpg")}
                                    alt="history"
                                  />
                                </div>
                              </PopoverBody>
                            </Popover>
                          </span>
                          <p>
                            {this.props.t(
                              "A beautiful and high resolution cover art provides the initial temptation for a traveller. So why not make it tempting enough?"
                            )}
                          </p>
                        </div>
                        <div className="col-12 col-xl-4">
                          <ImagePlaceholder
                            disabled={accomodation._status !== "draft"}
                            message={this.props.t(
                              "Cover image cannot be larger than 2MB"
                            )}
                            height="260px"
                            value={controller.resolveAssetAddr(
                              accomodation.coverImages[0]
                            )}
                            onClick={() => this.addCoverImage(0)}
                            onRemove={() =>
                              controller.accomodation.replaceAccomodationBanner(
                                0,
                                ""
                              )
                            }
                          />
                        </div>
                        <div className="col-12 col-xl-4 pt-3 pt-xl-0">
                          <div className="row">
                            <div className="col-6">
                              <div className="pb-4">
                                <ImagePlaceholder
                                  disabled={accomodation._status !== "draft"}
                                  message={this.props.t(
                                    "Cover image cannot be larger than 2MB"
                                  )}
                                  height="120px"
                                  value={controller.resolveAssetAddr(
                                    accomodation.coverImages[1]
                                  )}
                                  onClick={() => this.addCoverImage(1)}
                                  onRemove={() =>
                                    controller.accomodation.replaceAccomodationBanner(
                                      1,
                                      ""
                                    )
                                  }
                                />
                              </div>
                              <div>
                                <ImagePlaceholder
                                  disabled={accomodation._status !== "draft"}
                                  message={this.props.t(
                                    "Cover image cannot be larger than 2MB"
                                  )}
                                  height="120px"
                                  value={controller.resolveAssetAddr(
                                    accomodation.coverImages[2]
                                  )}
                                  onClick={() => this.addCoverImage(2)}
                                  onRemove={() =>
                                    controller.accomodation.replaceAccomodationBanner(
                                      2,
                                      ""
                                    )
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="pb-4">
                                <ImagePlaceholder
                                  disabled={accomodation._status !== "draft"}
                                  message={this.props.t(
                                    "Cover image cannot be larger than 2MB"
                                  )}
                                  height="120px"
                                  value={controller.resolveAssetAddr(
                                    accomodation.coverImages[3]
                                  )}
                                  onClick={() => this.addCoverImage(3)}
                                  onRemove={() =>
                                    controller.accomodation.replaceAccomodationBanner(
                                      3,
                                      ""
                                    )
                                  }
                                />
                              </div>
                              <div>
                                <ImagePlaceholder
                                  disabled={accomodation._status !== "draft"}
                                  message={this.props.t(
                                    "Cover image cannot be larger than 2MB"
                                  )}
                                  height="120px"
                                  value={controller.resolveAssetAddr(
                                    accomodation.coverImages[4]
                                  )}
                                  onClick={() => this.addCoverImage(4)}
                                  onRemove={() =>
                                    controller.accomodation.replaceAccomodationBanner(
                                      4,
                                      ""
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row pt-2 p-sm-2 p-0">
                        <div className="col-12 title-wrapper">
                          <span className="mb-3">
                            {this.props.t("Highlights")}
                          </span>
                          <p>
                            {this.props.t(
                              "Add some highlights to showcase what your trip offers"
                            )}
                          </p>
                        </div>
                        <div className="col-12">
                          {accomodation.highlights.map((highlight, index) => (
                            <Highlight
                              accomodation={accomodation}
                              key={index}
                              index={index}
                              item={highlight}
                              onDelete={index =>
                                this.setDeleteContext("highlight", index)
                              }
                            />
                          ))}
                        </div>
                      </div>
                      {accomodation._status === "draft" ? (
                        <div className="row my-2">
                          <div className="col-12 text-center create-package-add-button pb-2">
                            <button
                              className="circle btn btn-link btn-sm"
                              onClick={() =>
                                controller.accomodation.addHighlight()
                              }
                            >
                              <i className="fas fa-plus"></i>
                            </button>
                            <small>
                              {this.props.t("Add another highlight")}
                            </small>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className="card-header collapsed text-center mb-2"
                    role="tab"
                    id="headingThreeH"
                    data-target="#collapseThreeH"
                    data-toggle="collapse"
                    data-parent="#accordionH"
                    aria-expanded="false"
                    aria-controls="collapseThreeH"
                  >
                    <span className="card-title">
                      {this.props.t("Features")}
                    </span>
                  </div>
                  <div
                    className="collapse"
                    id="collapseThreeH"
                    role="tabpanel"
                    aria-labelledby="headingThreeH"
                  >
                    <div className="card-body p-0">
                      <div className="form-row p-sm-2 p-0">
                        <div className="form-group col-12">
                          <label className="header">
                            {this.props.t("INTERNET ACCESS")}
                          </label>
                          {local.internetaccessMaster.map(internetAccess => (
                            <div
                              key={internetAccess.value}
                              className="form-check"
                            >
                              <input
                                disabled={accomodation._status !== "draft"}
                                type="checkbox"
                                className="form-check-input"
                                id={`check-box-${internetAccess.value}`}
                                checked={
                                  accomodation.internetAccess &&
                                  accomodation.internetAccess.indexOf(
                                    internetAccess.value
                                  ) > -1
                                }
                                onChange={e =>
                                  controller.accomodation.toggleFeature(
                                    "internetAccess",
                                    internetAccess.value
                                  )
                                }
                              />

                              <label
                                className="form-check-label pr-"
                                htmlFor={`check-box-${internetAccess.value}`}
                              >
                                {this.props.t(internetAccess.label)}
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="form-group col-12 col-lg-4">
                          <label className="header pt-3">
                            {this.props.t("AMINITIES")}
                          </label>
                          {local.accomodationAmenitiesMaster.map(amenity => (
                            <div key={amenity.value} className="form-check">
                              <input
                                disabled={accomodation._status !== "draft"}
                                type="checkbox"
                                className="form-check-input"
                                checked={
                                  accomodation.amenities &&
                                  accomodation.amenities.indexOf(
                                    amenity.value
                                  ) > -1
                                }
                                onChange={e =>
                                  controller.accomodation.toggleFeature(
                                    "amenities",
                                    amenity.value
                                  )
                                }
                                id={`check-box-${amenity.value}`}
                              />

                              <label
                                className="form-check-label"
                                htmlFor={`check-box-${amenity.value}`}
                              >
                                {this.props.t(amenity.label)}
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="form-group col-12 col-lg-4">
                          <label className="header pt-3">
                            {this.props.t("ACCESS")}
                          </label>
                          {local.accomodationAccessMaster.map(access => (
                            <div key={access.value} className="form-check">
                              <input
                                disabled={accomodation._status !== "draft"}
                                type="checkbox"
                                className="form-check-input"
                                id={`check-box-${access.value}`}
                                checked={
                                  accomodation.access &&
                                  accomodation.access.indexOf(access.value) > -1
                                }
                                onChange={e =>
                                  controller.accomodation.toggleFeature(
                                    "access",
                                    access.value
                                  )
                                }
                              />

                              <label
                                className="form-check-label"
                                htmlFor={`check-box-${access.value}`}
                              >
                                {this.props.t(access.label)}
                              </label>
                            </div>
                          ))}
                        </div>
                        <div className="form-group col-12 col-lg-4"></div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-header collapsed text-center mb-2"
                    role="tab"
                    id="headingFourH"
                    data-target="#collapseFourH"
                    data-toggle="collapse"
                    data-parent="#accordionH"
                    aria-expanded="false"
                    aria-controls="collapseFourH"
                  >
                    <span className="card-title">
                      {this.props.t("Accommodation Details")}
                    </span>
                  </div>
                  <div
                    className="collapse"
                    id="collapseFourH"
                    role="tabpanel"
                    aria-labelledby="headingFourH"
                  >
                    <div className="card-body p-0">
                      <div className="form-row p-sm-2 p-0">
                        <div className="form-group col-12">
                          {accomodation.rooms.map((highlightDetail, index) => (
                            <Room
                              accomodation={accomodation}
                              key={index}
                              index={index}
                              item={highlightDetail}
                              local={this.props.local}
                              capacityMaster={local.capacityMaster}
                              spaceMaster={local.spaceMaster}
                              onDelete={index =>
                                this.setDeleteContext("highlightdetail", index)
                              }
                            />
                          ))}

                          {accomodation._status === "draft" ? (
                            <div className="row pt-4 pt-lg-5">
                              <div className="col-12 text-center create-package-add-button pb-2">
                                <button
                                  className="circle btn btn-link btn-sm"
                                  onClick={() =>
                                    controller.accomodation.addDetailsHighlight()
                                  }
                                >
                                  <i className="fas fa-plus"></i>
                                </button>
                                <small className="mt-3">
                                  {this.props.t("Add another highlight")}
                                </small>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="deletePrompt"
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="title">
                      {this.props.t("Remove")}{" "}
                      <span className="text-capitalize">
                        {this.state.keyToDelete}
                      </span>
                    </h5>
                    <button
                      onClick={e => this.setDeleteContext("", -1)}
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">{this.props.t("\xD7")}</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {this.props.t("Are you sure want to delete this?")}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={e => this.setDeleteContext("", -1)}
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      {this.props.t("Cancel")}
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      onClick={this.proceedDelete}
                      className="btn btn-danger"
                    >
                      {this.props.t("Confirm")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <DraftControl
              isModified={local.editorLastSavedOn < local.editorLastModifiedOn}
              statusMessage={saveStatus}
              onSaveDraft={() => this.saveAccomodation()}
              onPublish={() => this.onPublish()}
              onPreview={() => this.setState({ isPreviewOpen: true })}
              statusKey={accomodation._status}
              private={
                local.editorListingSnapshot &&
                local.editorListingSnapshot.class === "private"
              }
            />
          </HotKeys>
          <Modal
            isOpen={isPublishDialogOpen}
            toggle={this.onTogglePublish}
            size="xl"
            centered
            className="profile-prompt"
            keyboard={false}
            backdrop="static"
          >
            <PublishModal
              toggle={this.onTogglePublish}
              listingId={match.params.id}
              packageType="accomodation"
              package={accomodation}
            />
          </Modal>
          <Modal
            style={{ maxWidth: "100%" }}
            isOpen={this.state.isPreviewOpen}
            toggle={() =>
              this.setState({ isPreviewOpen: !this.state.isPreviewOpen })
            }
            scrollable={true}
            size={"lg"}
            className="ordered-preview"
          >
            <button
              className="closePreview"
              onClick={() => this.setState({ isPreviewOpen: false })}
            >
              <i className="fas fa-times fa-2x"></i>
            </button>
            <ModalBody id="sample-id" className="p-0">
              <TripViewer
                scrollableId="#sample-id"
                spyOffset={-1100}
                context={this.props.context}
                mode="preview"
              />
            </ModalBody>
          </Modal>
        </React.Fragment>
      );
    }
  }
);

const mapStateToProps = state => ({
  accomodation: state.accomodation,
  context: state.marketing.currentPackage,
  local: state.local
});

export default connect(mapStateToProps)(AccomodationSetup);
