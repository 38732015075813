import { useTranslation, withTranslation } from "react-i18next";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-reveal";
import { Helmet } from "react-helmet";
import queryString from "query-string";
import moment from "moment";
import { connect } from "react-redux";
import { Elements, CardElement, injectStripe } from "react-stripe-elements";
import { GetRawFormattedPrice, stripAddress } from "../../resources/Helper";
import Controller from "../../store/controllers/index";
import { NETWORK_ERR_MESSAGE } from "../../resources/String";
import { OrderServices } from "../../services/Order.services";
import TripViewer from "./../../components/TripViewer";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input
} from "reactstrap";
import controllers from "../../store/controllers/index";
import { AdditionalInfoMaster } from "shared-tripbonder-lib/lib/masters/package.master";

const PaymentDetailsView = injectStripe(
  withTranslation()(
    class PaymentDetailsView extends Component {
      constructor(props) {
        super(props);
        this.state = {
          isPayingNow: false,
          isLoading: false
        };

        this.pay = this.pay.bind(this);
      }

      pay() {
        const onOrderUpdate = this.props.onOrderUpdate;
        const togglePaymentDetailsModal = this.props.togglePaymentDetailsModal;
        const self = this;
        const paymentInfo = this.props.paymentInfo;
        if (self.state.isPayingNow === true) {
          self.setState(
            {
              isLoading: true
            },

            () => {
              this.props.stripe
                .createToken()
                .then(response => {
                  const tokenId = response.token.id;
                  OrderServices.PayRemaining(paymentInfo._id, {
                    tokenId
                  })
                    .then(response => {
                      onOrderUpdate && onOrderUpdate(response.order);
                      setTimeout(() => {
                        togglePaymentDetailsModal &&
                          togglePaymentDetailsModal(null);
                        Controller.setGlobalSuccess(
                          "Payment Successfull",
                          true
                        );

                        setTimeout(() => {
                          Controller.clearGlobalMessage();
                        }, 1700);
                      }, 700);
                    })
                    .catch(err => {
                      console.error(err);
                      Controller.setGlobalError(err.message, true);
                    });
                })
                .catch(err => {
                  console.log(err);
                  self.setState({
                    isLoading: false
                  });
                });
            }
          );
        } else {
          self.setState(
            {
              isLoading: true,
              isPayingNow: true
            },

            () =>
              setTimeout(() => {
                self.setState({
                  isLoading: false
                });
              }, 700)
          );
        }
      }

      render() {
        const paymentInfo = this.props.paymentInfo;
        const isPayingNow = this.state.isPayingNow;
        const isLoading = this.state.isLoading;
        const togglePaymentDetailsModal = this.props.togglePaymentDetailsModal;
        const remainingAmount = paymentInfo.effectiveRemainingAmount || 0;

        let buttonContent = null;

        if (isLoading === true) {
          buttonContent = (
            <>
              <i className="fas fa-spin fa-circle-notch"></i> Processing...
            </>
          );
        } else {
          if (isPayingNow === true) {
            buttonContent = `Pay ${GetRawFormattedPrice(
              paymentInfo.effectiveRemainingAmount,
              paymentInfo.currencyId
            )}`;
          } else {
            buttonContent = `Pay Balance Now`;
          }
        }

        return (
          <>
            <ModalHeader
              className="payment-details-modal"
              toggle={() =>
                togglePaymentDetailsModal && togglePaymentDetailsModal(null)
              }
              style={{
                backgroundImage: `url('${Controller.resolveAssetAddr(
                  paymentInfo.listingSnapshot.package.defaultCoverArtId
                )}')`,
                backgroundSize: "cover",
                backgroundPosition: "center"
              }}
            >
              <div className="order-info">
                <div className="ribbon">
                  <span className="ribbon4">
                    <div>
                      <div className="truncate">
                        {paymentInfo.listingSnapshot.package.title}
                      </div>
                      <small className="d-flex truncate">
                        <i className="fas fa-map-marker-alt pt-2 pr-1"></i>{" "}
                        {stripAddress(
                          paymentInfo.listingSnapshot.package.location
                        )}
                      </small>
                    </div>
                  </span>
                </div>
              </div>
            </ModalHeader>
            <ModalBody>
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th scope="row">{this.props.t("Net Amount")}</th>
                    <td>
                      {GetRawFormattedPrice(
                        paymentInfo.effectiveNetAmount,
                        paymentInfo.currencyId
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">{this.props.t("Amount Paid")}</th>
                    <td>
                      {GetRawFormattedPrice(
                        paymentInfo.amountPaid,
                        paymentInfo.currencyId
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">
                      <h5>{this.props.t("Balance Amount")}</h5>
                    </th>
                    <td>
                      <p className="h5">
                        {GetRawFormattedPrice(
                          paymentInfo.effectiveRemainingAmount,
                          paymentInfo.currencyId
                        )}
                      </p>
                    </td>
                  </tr>
                  {isPayingNow ? (
                    <Zoom duration={350} delay={700}>
                      <tr>
                        <td colSpan={2}>
                          <div>
                            <ul className="nav nav-tabs">
                              <li className="nav-item">
                                <button className="nav-link active font-13">
                                  <i className="fas fa-credit-card"></i>
                                  {this.props.t("Credit Card")}
                                </button>
                              </li>
                            </ul>
                            <div className="px-3 pt-4">
                              <CardElement />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </Zoom>
                  ) : null}
                </tbody>
              </table>
            </ModalBody>
            {remainingAmount > 0 ? (
              <ModalFooter>
                <Button
                  disabled={isLoading}
                  className="btn btn-primary-gradient btn-block"
                  color="primary"
                  onClick={() => this.pay()}
                >
                  {buttonContent}
                </Button>
              </ModalFooter>
            ) : null}
          </>
        );
      }
    }
  )
);

const TranslateInfoKey = key => {
  return `__${key}`;
};

const OtherInfoModal = withTranslation()(
  class OtherInfoModal extends Component {
    constructor(props) {
      super(props);
      this.state = Object.assign({
        isLoading: false
      });

      if (props.orderInfo) {
        if (props.orderInfo.listingSnapshot) {
          if (props.orderInfo.listingSnapshot.package) {
            if (props.orderInfo.listingSnapshot.package.addtinfo) {
              const getDefaultValue = key => {
                if (props.orderInfo.addtInfoCollected) {
                  if (props.orderInfo.addtInfoCollected[key]) {
                    return props.orderInfo.addtInfoCollected[key];
                  }
                }

                return "";
              };

              this.state = Object.assign(
                this.state,
                props.orderInfo.listingSnapshot.package.addtinfo.reduce(
                  function(accummulator, field) {
                    return Object.assign(accummulator, {
                      [TranslateInfoKey(field)]: getDefaultValue(field)
                    });
                  },
                  {}
                )
              );
            }
          }
        }
      }

      this.updateInfo = this.updateInfo.bind(this);
      this.translateInfoKey = this.translateInfoKey.bind(this);
    }

    translateInfoKey(key) {
      return TranslateInfoKey(key);
    }

    updateInfo(e) {
      e && e.preventDefault();
      const onOrderUpdate = this.props.onOrderUpdate;
      const toggleUpdateInformation = this.props.toggleUpdateInformation;
      const orderInfo = this.props.orderInfo;

      const extracts = Object.keys(this.state).filter(f => f.startsWith("__"));
      for (var i = 0; i < extracts.length; i++) {
        if (this.state[extracts[i]] === "") {
          controllers.setGlobalError("All fields are required", true);
          return;
        }
      }

      this.setState(
        {
          isLoading: true
        },

        () => {
          setTimeout(() => {
            const infoExtract = Object.keys(this.state).reduce(
              (accummulator, __field) => {
                if (__field.startsWith("__")) {
                  return Object.assign(accummulator, {
                    [__field.replace("__", "")]: this.state[__field]
                  });
                } else {
                  return accummulator;
                }
              },
              {}
            );

            OrderServices.UpdateAdditionalInformation(
              orderInfo._id,
              infoExtract
            )
              .then(order => {
                onOrderUpdate && onOrderUpdate(order);
                controllers.setGlobalSuccess("Information Updated", true);
                setTimeout(() => {
                  toggleUpdateInformation && toggleUpdateInformation(null);
                  controllers.clearGlobalMessage();
                }, 1400);
              })
              .catch(err => {
                console.error(err);
                controllers.setGlobalError(
                  err.message || NETWORK_ERR_MESSAGE,
                  true
                );
              });
          }, 800);
        }
      );
    }

    render() {
      const orderInfo = this.props.orderInfo;
      const isLoading = this.state.isLoading;
      const toggleUpdateInformation = this.props.toggleUpdateInformation;

      let buttonContent = null;

      if (isLoading === true) {
        buttonContent = (
          <>
            <i className="fas fa-spin fa-circle-notch"></i>{" "}
            {this.props.t("Processing...")}
          </>
        );
      } else {
        buttonContent = this.props.t("Update Information");
      }

      return (
        <form onSubmit={e => this.updateInfo(e)}>
          <ModalHeader
            className="payment-details-modal"
            toggle={() =>
              toggleUpdateInformation && toggleUpdateInformation(null)
            }
            style={{
              backgroundImage: `url('${Controller.resolveAssetAddr(
                orderInfo.listingSnapshot.package.defaultCoverArtId
              )}')`,
              backgroundSize: "cover",
              backgroundPosition: "center"
            }}
          >
            <div className="order-info">
              <div className="ribbon">
                <span className="ribbon4">
                  <div>
                    <div className="truncate">
                      {orderInfo.listingSnapshot.package.title}
                    </div>
                    <small className="d-flex truncate">
                      <i className="fas fa-map-marker-alt pt-2 pr-1"></i>{" "}
                      {stripAddress(orderInfo.listingSnapshot.package.location)}
                    </small>
                  </div>
                </span>
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <table className="table table-borderless">
              <tbody>
                {Array.isArray(orderInfo.listingSnapshot.package.addtinfo)
                  ? orderInfo.listingSnapshot.package.addtinfo.map(
                      (item, index) => {
                        const fieldInfo = AdditionalInfoMaster.find(
                          f => f.value === item
                        );

                        return (
                          <tr key={item}>
                            {fieldInfo ? (
                              <td>
                                <div className="form-group">
                                  <label htmlFor={`${item}-${index}`}>
                                    {this.props.t(fieldInfo.label)}
                                    <sup>{this.props.t("*")}</sup>
                                  </label>
                                  <input
                                    disabled={isLoading}
                                    autoFocus={index === 0}
                                    type="text"
                                    value={
                                      this.state[this.translateInfoKey(item)]
                                    }
                                    onChange={e =>
                                      this.setState({
                                        [this.translateInfoKey(item)]: e.target
                                          .value
                                      })
                                    }
                                    className="form-control"
                                    id={`${item}-${index}`}
                                    placeholder={`Enter ${fieldInfo.label.toLowerCase()} here`}
                                  />
                                </div>
                              </td>
                            ) : null}
                          </tr>
                        );
                      }
                    )
                  : null}
              </tbody>
            </table>
          </ModalBody>
          <ModalFooter>
            <Button
              disabled={isLoading}
              className="btn btn-primary-gradient btn-block"
              color="primary"
              type="submit"
            >
              {buttonContent}
            </Button>
          </ModalFooter>
        </form>
      );
    }
  }
);

function Order(props) {
  const { t } = useTranslation();
  let badgeStyle = "";
  const { data } = props;
  let buttonStyle = false;
  let cancelledView = "";
  let noOfDays = 1;
  const beginningDateMoment = moment(data.beginingDate);
  const endDateMoment = moment(data.endDate);
  if (data.beginingDate && data.endDate) {
    noOfDays = endDateMoment.diff(beginningDateMoment, "days");
    noOfDays = noOfDays + 1;
  }
  switch (data.status) {
    case "cancellation_requested":
    case "cancelled": {
      cancelledView = "cancelled-view";
      buttonStyle = true;
      break;
    }
    case "rejected": {
      cancelledView = "cancelled-view";
      buttonStyle = true;
      break;
    }
    default: {
      cancelledView = "post-items";
      buttonStyle = false;
      break;
    }
  }

  function renderOrder() {
    if (data.type === "itinerary") {
      return (
        <>
          <span className="detail-tiles">
            <i className="fas fa-user-friends pr-2 icons"></i>
            {`${data.adultPersonCount} Adult${
              data.adultPersonCount > 1 ? "s" : ""
            }`}{" "}
            {data.childCount + (data.childCountWithBed || 0) !== 0 ? (
              <>
                {`${data.childCount + (data.childCountWithBed || 0)} ${
                  data.childCount + (data.childCountWithBed || 0) > 1
                    ? "Children"
                    : "Child"
                }`}
              </>
            ) : null}
          </span>
          <span className="pl-2 detail-tiles">
            <i className="fas fa-plane-departure pr-2 icons"></i>
            {beginningDateMoment.format("ll")}
            {noOfDays > 1 ? (
              <>
                {t("-")}
                {endDateMoment.format("ll")}
              </>
            ) : null}
          </span>
          <span className="pl-2  detail-tiles">
            <i className="fas fa-stopwatch pr-2 icons"></i>
            {`${noOfDays} Day${noOfDays > 1 ? "s" : ""}`}
          </span>

          <span className="pl-2 detail-tiles">
            <i className="fas fa-coins icons pr-1"></i>
            {GetRawFormattedPrice(data.netAmount, data.currencyId)}
          </span>
          <br />
          <span>
            <button
              className="payment-details-link btn-link"
              style={{ background: "transparent" }}
              disabled={buttonStyle}
              onClick={e => {
                e.stopPropagation();
                props.onPaymentDetails(data);
              }}
            >
              {t("See payment details")}
            </button>
          </span>
        </>
      );
    } else if (data.type === "accomodation") {
      return (
        <>
          <span className="detail-tiles">
            <i className="fas fa-couch pr-2 icons"></i>
            {
              data.listingSnapshot.package.rooms[data.selectedRoomIndex]
                .roomType
            }
          </span>
          <span className="pl-2 detail-tiles">
            <i className="fas fa-bed pr-2 icons"></i>
            {
              (
                props.local.capacityMaster.find(
                  f => f.value === data.selectedCapacityOptions[0]
                ) || { value: null }
              ).label
            }
          </span>
          <span className="pl-2 detail-tiles">
            <i className="fas fa-plane-departure pr-2 icons"></i>
            {beginningDateMoment.format("ll")}
            {noOfDays > 1 ? (
              <>
                {t("-")}
                {endDateMoment.format("ll")}
              </>
            ) : null}
          </span>
          <span className="pl-2  detail-tiles">
            <i className="fas fa-stopwatch pr-2 icons"></i>
            {`${noOfDays} Day${noOfDays > 1 ? "s" : ""}`}
          </span>
          <span className="pl-2 detail-tiles">
            <i className="fas fa-coins icons pr-1"></i>
            {GetRawFormattedPrice(data.netAmount, data.currencyId)}
          </span>
          <br />
          <span>
            <button
              className="payment-details-link btn-link"
              style={{ background: "transparent" }}
              disabled={buttonStyle}
              onClick={e => {
                e.stopPropagation();
                props.onPaymentDetails(data);
              }}
            >
              {t("See payment details")}
            </button>
          </span>
        </>
      );
    } else if (data.type === "ticket") {
      return (
        <>
          <span className="detail-tiles">
            <i className="fas fa-user-friends pr-2 icons"></i>
            {data.adultPersonCount}{" "}
            {`${data.adultPersonCount === 1 ? " Person" : " People"}`}
          </span>
          <span className="pl-2 detail-tiles">
            <i className="fas fa-plane-departure pr-2 icons"></i>
            {beginningDateMoment.format("ll")}
          </span>
          <span className="pl-2 detail-tiles">
            <i className="fas fa-coins icons pr-1"></i>
            {GetRawFormattedPrice(data.netAmount, data.currencyId)}
          </span>
          <br />
          <span>
            <button
              className="payment-details-link btn-link"
              disabled={buttonStyle}
              style={{ background: "transparent" }}
              onClick={e => {
                e.stopPropagation();
                props.onPaymentDetails(data);
              }}
            >
              {t("See payment details")}
            </button>
          </span>
        </>
      );
    } else if (data.type === "transport") {
      return (
        <>
          {data.tariff === "normal" ? (
            <>
              <span className="pl-2 detail-tiles">
                <i className="fas fa-plane-departure pr-2 icons"></i>
                {beginningDateMoment.format("ll")}
                {noOfDays > 1 ? (
                  <>
                    {t("-")}
                    {endDateMoment.format("ll")}
                  </>
                ) : null}
              </span>

              <span className="pl-2 detail-tiles">
                <i className="fas fa-coins icons pr-1"></i>
                {GetRawFormattedPrice(data.netAmount, data.currencyId)}
              </span>
            </>
          ) : null}
          {data.tariff === "per-way" ? (
            <>
              <span className="pl-2 detail-tiles">
                <i className="fas fa-plane-departure pr-2 icons"></i>
                {moment(data.beginingDate).format("ll")}
              </span>

              <span className="pl-2 detail-tiles">
                <i className="fas fa-coins icons pr-1"></i>
                {GetRawFormattedPrice(data.netAmount, data.currencyId)}
              </span>
            </>
          ) : null}
          <span className="pl-2  detail-tiles">
            <i className="fas fa-stopwatch pr-2 icons"></i>
            {`${noOfDays} Day${noOfDays > 1 ? "s" : ""}`}
          </span>
          <br />
          <span>
            <button
              className="payment-details-link btn-link"
              style={{ background: "transparent" }}
              disabled={buttonStyle}
              onClick={e => {
                e.stopPropagation();
                props.onPaymentDetails(data);
              }}
            >
              {t("See payment details")}
            </button>
          </span>
        </>
      );
    } else {
      return <span>{t("INVALID REQUEST")}</span>;
    }
  }
  function renderOrderSm() {
    if (data.type === "itinerary") {
      return (
        <>
          <div className="detail-tiles p-2">
            <i className="fas fa-user-friends pr-2 icons"></i>
            {`${data.adultPersonCount} Adult${
              data.adultPersonCount > 1 ? "s" : ""
            }`}{" "}
            {data.childCount + (data.childCountWithBed || 0) !== 0 ? (
              <>
                {`${data.childCount + (data.childCountWithBed || 0)} ${
                  data.childCount + (data.childCountWithBed || 0) > 1
                    ? "Children"
                    : "Child"
                }`}
              </>
            ) : null}
          </div>
          <div className="p-2 detail-tiles">
            <i className="fas fa-plane-departure pr-2 icons"></i>
            {beginningDateMoment.format("ll")}
            {noOfDays > 1 ? (
              <>
                {t("-")}
                {endDateMoment.format("ll")}
              </>
            ) : null}
          </div>
          <div className="p-2 detail-tiles">
            <i className="fas fa-stopwatch pr-2 icons"></i>
            {`${noOfDays} Day${noOfDays > 1 ? "s" : ""}`}
          </div>

          <div className="p-2 detail-tiles">
            <i className="fas fa-coins icons pr-1"></i>
            {GetRawFormattedPrice(data.netAmount, data.currencyId)}
          </div>

          <div className="text-center">
            <button
              className="payment-details-link btn-link"
              style={{ background: "transparent" }}
              disabled={buttonStyle}
              onClick={e => {
                e.stopPropagation();
                props.onPaymentDetails(data);
              }}
            >
              {t("See payment details")}
            </button>
          </div>
        </>
      );
    } else if (data.type === "accomodation") {
      return (
        <>
          <div className="p-2 detail-tiles">
            <i className="fas fa-couch pr-2 icons"></i>
            {
              data.listingSnapshot.package.rooms[data.selectedRoomIndex]
                .roomType
            }
          </div>
          <div className="p-2 detail-tiles">
            <i className="fas fa-bed pr-2 icons"></i>
            {
              (
                props.local.capacityMaster.find(
                  f => f.value === data.selectedCapacityOptions[0]
                ) || { value: null }
              ).label
            }
          </div>
          <div className="p-2 detail-tiles">
            <i className="fas fa-plane-departure pr-2 icons"></i>
            {beginningDateMoment.format("ll")}
            {noOfDays > 1 ? (
              <>
                {t("-")}
                {endDateMoment.format("ll")}
              </>
            ) : null}
          </div>
          <div className="p-2  detail-tiles">
            <i className="fas fa-stopwatch pr-2 icons"></i>
            {`${noOfDays} Day${noOfDays > 1 ? "s" : ""}`}
          </div>
          <div className="p-2 detail-tiles">
            <i className="fas fa-coins icons pr-1"></i>
            {GetRawFormattedPrice(data.netAmount, data.currencyId)}
          </div>

          <div className="text-center">
            <button
              className="payment-details-link btn-link"
              style={{ background: "transparent" }}
              disabled={buttonStyle}
              onClick={e => {
                e.stopPropagation();
                props.onPaymentDetails(data);
              }}
            >
              {t("See payment details")}
            </button>
          </div>
        </>
      );
    } else if (data.type === "ticket") {
      return (
        <>
          <div className="p-2 detail-tiles">
            <i className="fas fa-user-friends pr-2 icons"></i>
            {data.adultPersonCount}{" "}
            {`${data.adultPersonCount === 1 ? " Person" : " People"}`}
          </div>
          <div className="p-2 detail-tiles">
            <i className="fas fa-plane-departure pr-2 icons"></i>
            {beginningDateMoment.format("ll")}
          </div>
          <div className="p-2 detail-tiles">
            <i className="fas fa-coins icons pr-1"></i>
            {GetRawFormattedPrice(data.netAmount, data.currencyId)}
          </div>

          <div className="text-center">
            <button
              className="payment-details-link btn-link"
              style={{ background: "transparent" }}
              disabled={buttonStyle}
              onClick={e => {
                e.stopPropagation();
                props.onPaymentDetails(data);
              }}
            >
              {t("See payment details")}
            </button>
          </div>
        </>
      );
    } else if (data.type === "transport") {
      return (
        <>
          {data.tariff === "normal" ? (
            <>
              <div className="p-2 detail-tiles">
                <i className="fas fa-plane-departure pr-2 icons"></i>
                {beginningDateMoment.format("ll")}
                {noOfDays > 1 ? (
                  <>
                    {t("-")}
                    {endDateMoment.format("ll")}
                  </>
                ) : null}
              </div>

              <div className="p-2 detail-tiles">
                <i className="fas fa-coins icons pr-1"></i>
                {GetRawFormattedPrice(data.netAmount, data.currencyId)}
              </div>
            </>
          ) : null}
          {data.tariff === "per-way" ? (
            <>
              <div className="p-2 detail-tiles">
                <i className="fas fa-plane-departure pr-2 icons"></i>
                {beginningDateMoment.format("ll")}
              </div>

              <div className="p-2 detail-tiles">
                <i className="fas fa-coins icons pr-1"></i>
                {GetRawFormattedPrice(data.netAmount, data.currencyId)}
              </div>
            </>
          ) : null}
          <div className="p-2  detail-tiles">
            <i className="fas fa-stopwatch pr-2 icons"></i>
            {`${noOfDays} Day${noOfDays > 1 ? "s" : ""}`}
          </div>
          <div className="text-center">
            <button
              className="payment-details-link btn-link"
              disabled={buttonStyle}
              style={{ background: "transparent" }}
              onClick={e => {
                e.stopPropagation();
                props.onPaymentDetails(data);
              }}
            >
              {t("See payment details")}
            </button>
          </div>
        </>
      );
    } else {
      return <div>{t("INVALID REQUEST")}</div>;
    }
  }

  switch (data.status) {
    case "confirmed": {
      badgeStyle = "badge-success";
      break;
    }
    case "placed": {
      badgeStyle = "badge-warning";
      break;
    }
    case "cancelled": {
      badgeStyle = "badge-danger";
      break;
    }
    case "rejected": {
      badgeStyle = "badge-danger";
      break;
    }
    case "completed": {
      badgeStyle = "badge-primary";
      break;
    }
    default: {
      badgeStyle = "badge-warning";
      break;
    }
  }

  return (
    <>
      <div
        className={`row post-item ${cancelledView} mb-4 px-3`}
        onClick={() => {
          if (data.status !== "cancelled" && data.status !== "rejected") {
            Controller.marketing.setCurrentPackage(data.listingSnapshot);
            props.toggleTripDetails();
          } else {
            return;
          }
        }}
      >
        <div
          className="col-12 col-md-3 trip-img p-0"
          style={{
            backgroundImage: `url('${Controller.resolveAssetAddr(
              data.listingSnapshot.package.defaultCoverArtId
            )}')`
          }}
        ></div>
        <div className="col-12 col-md-8 trip-details">
          <div className="d-flex">
            <div className="flex-shrink-0"></div>
            <div className="flex-fill badge-wrapper d-md-none text-center pt-2">
              <span
                className={`badge ${badgeStyle} ml-2 ml-md-2 px-3 text-capitalize`}
              >
                {t(data.status)}
              </span>
            </div>
            <div className="dropdown-button-wrapper flex-shrink-0 d-md-none d-inline">
              <button
                disabled={buttonStyle}
                className="ellipses"
                id="orderDropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={e => e.stopPropagation()}
              >
                <i className="fas fa-ellipsis-v"></i>
              </button>
              <div
                className="dropdown-menu py-0"
                aria-labelledby="orderDropdownMenuButton"
              >
                <button
                  className="dropdown-item"
                  onClick={e => {
                    e.stopPropagation();
                    props.onPaymentDetails(data);
                  }}
                >
                  {t("Payment Details")}
                </button>
                {data.requireAdditionalInformation === true ? (
                  <button
                    className="dropdown-item"
                    onClick={e => {
                      e.stopPropagation();
                      props.onUpdateInformation(data);
                    }}
                  >
                    {t("Update Information")}
                  </button>
                ) : null}
                {/* <button
                   className="dropdown-item"
                   onClick={e => e.stopPropagation()}
                  >
                   {t("Contact Traveller")}
                  </button> */}
                {data.status !== "completed" ? (
                  <button
                    className="dropdown-item cancel-order"
                    onClick={e => {
                      e.stopPropagation();
                      props.onCancelOrder(data);
                    }}
                  >
                    {t("Cancel Booking")}
                  </button>
                ) : null}
              </div>
            </div>
          </div>
          <div className="px-2 pb-2 pb-md-0 trip-details-wrapper">
            <div className="location text-center text-md-left pt-2">
              <i className="fas fa-map-marker-alt location pt-2 pr-1"></i>
              {stripAddress(data.listingSnapshot.package.location)}
            </div>
            <div className="text-center text-md-left">
              <h5 className="pt-1">
                <b>{data.listingSnapshot.package.title}</b>
              </h5>
              <span className="author-label">
                {t("By")}
                {data.tripbonder.name}
              </span>
            </div>
            <div className="post-desc my-2">
              {data.listingSnapshot.package.description}
            </div>
            <div className="mt-2 d-none d-md-inline">{renderOrder()}</div>
            <div className="mt-2 d-md-none text-left">{renderOrderSm()}</div>
          </div>
        </div>
        <div className="col-12 col-md trip-buttons pr-0 text-right">
          <div className="dropdown-button-wrapper d-none d-md-block">
            <button
              disabled={buttonStyle}
              className="ellipses"
              id="orderDropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={e => e.stopPropagation()}
            >
              <i className="fas fa-ellipsis-v"></i>
            </button>
            <div
              className="dropdown-menu py-0"
              aria-labelledby="orderDropdownMenuButton"
            >
              <button
                className="dropdown-item"
                onClick={e => {
                  e.stopPropagation();
                  props.onPaymentDetails(data);
                }}
              >
                {t("Payment Details")}
              </button>
              {data.requireAdditionalInformation === true ? (
                <button
                  className="dropdown-item"
                  onClick={e => {
                    e.stopPropagation();
                    props.onUpdateInformation(data);
                  }}
                >
                  {t("Update Information")}
                </button>
              ) : null}
              {/* <button
                 className="dropdown-item"
                 onClick={e => e.stopPropagation()}
                >
                 {t("Contact Traveller")}
                </button> */}
              {data.status !== "completed" ? (
                <button
                  className="dropdown-item cancel-order"
                  onClick={e => {
                    e.stopPropagation();
                    props.onCancelOrder(data);
                  }}
                >
                  {t("Cancel Booking")}
                </button>
              ) : null}
            </div>
          </div>
          <div className="badge-wrapper d-none d-md-block">
            <span
              className={`badge ${badgeStyle} ml-2 ml-md-2 px-3 text-capitalize`}
            >
              {t(data.status)}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
const OrdersPage = withTranslation()(
  class OrdersPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        allInfo: [],
        cancelOrderModal: false,
        orderToCancel: [],
        cancellingReason: "",
        otherReason: "",
        paymentDetailsModal: false,
        paymentInfo: null,
        tripDetails: false,
        informationModal: false,
        selectedOrder: null
      };

      this.fetchMe = this.fetchMe.bind(this);
      this.toggleCancelOrderModal = this.toggleCancelOrderModal.bind(this);
      this.togglePaymentDetailsModal = this.togglePaymentDetailsModal.bind(
        this
      );

      this.toggleUpdateInformation = this.toggleUpdateInformation.bind(this);

      this.radioCancelReason = this.radioCancelReason.bind(this);
      this.handleOrderUpdate = this.handleOrderUpdate.bind(this);
      this.toggleTripDetails = this.toggleTripDetails.bind(this);
    }
    toggleTripDetails() {
      this.setState({
        tripDetails: !this.state.tripDetails
      });
    }
    radioCancelReason(value) {
      this.setState({
        cancellingReason: value
      });
    }
    togglePaymentDetailsModal(paymentContext) {
      this.setState({
        paymentDetailsModal: !this.state.paymentDetailsModal,
        paymentInfo: paymentContext || null
      });
    }
    toggleUpdateInformation(data) {
      this.setState({
        informationModal: !this.state.informationModal,
        selectedOrder: data || null
      });
    }
    toggleCancelOrderModal(orderContext) {
      this.setState({
        cancelOrderModal: !this.state.cancelOrderModal,
        orderToCancel: orderContext,
        cancellingReason: "",
        otherReason: ""
      });
    }
    fetchMe() {
      Controller.setGlobalLoader("Getting your bookings", false);
      setTimeout(() => {
        OrderServices.GetMe()
          .then(orders => {
            Controller.clearGlobalMessage();
            this.setState(
              {
                allInfo: orders.map(a => Object.assign({}, a))
              },

              () => {
                const searchArg = queryString.parse(window.location.search);
                const autoPayId = (searchArg && searchArg.pay) || null;
                const autoUpdateId = (searchArg && searchArg.update) || null;
                if (autoPayId) {
                  const order = this.state.allInfo.find(
                    o => o._id === autoPayId
                  );

                  if (order) {
                    this.togglePaymentDetailsModal(order);
                  }
                }

                if (autoUpdateId) {
                  const order = this.state.allInfo.find(
                    o =>
                      o._id === autoUpdateId &&
                      o.requireAdditionalInformation === true
                  );

                  if (order) {
                    this.toggleUpdateInformation(order);
                  }
                }
              }
            );
          })
          .catch(err => {
            Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
          });
      }, 800);
    }
    cancelSelectedOrder(_id, reason) {
      this.toggleCancelOrderModal();
      Controller.setGlobalLoader("Cancelling booking...", false);
      setTimeout(() => {
        OrderServices.CancelOrder(_id, reason)
          .then(response => {
            Controller.setGlobalSuccess(
              "Your request have been processed",
              true
            );

            this.setState({
              allInfo: this.state.allInfo.map(item => {
                if (item._id === _id) {
                  item.status = response.status;
                }
                return item;
              })
            });

            setTimeout(() => Controller.clearGlobalMessage(), 1800);
          })
          .catch(err => {
            Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
          });
      }, 800);
    }
    componentDidMount() {
      this.fetchMe();
      controllers.subscribeToLanguageChange(
        this.fetchMe,
        "my-orders-page-traveller"
      );
    }

    handleOrderUpdate(updatedOrder) {
      if (updatedOrder) {
        this.setState({
          allInfo: this.state.allInfo.map(order => {
            if (order._id === updatedOrder._id) {
              return updatedOrder;
            }
            return order;
          })
        });
      }
    }

    render() {
      const {
        match: {
          params: { status }
        }
      } = this.props;
      let currentTab;
      let dataToShow = [];
      switch (status) {
        case "cancelled": {
          currentTab = "cancelled";
          dataToShow = this.state.allInfo.filter(
            f =>
              f.status === "cancelled" ||
              f.status === "cancellation_requested" ||
              f.status === "rejected"
          );

          break;
        }
        default: {
          currentTab = "active";
          dataToShow = this.state.allInfo;
        }
      }

      console.log(this.state.allInfo);

      return (
        <Fade duration={300}>
          <Helmet>
            <title>{this.props.t("Tripbonder - My Account Page")}</title>
          </Helmet>
          <section className="my-orders">
            <div className="container">
              <div className="row title mt-2">
                <div className="col-12">
                  <div className="ordered-info-text">
                    {this.props.t("Here's what you have booked,")}{" "}
                    {this.props.local.currentUser.name}
                    {this.props.t("!")}
                  </div>
                  <h3 style={{ fontWeight: "bold" }}>
                    {this.props.t("Bookings")}
                  </h3>
                </div>
                <div className="pl-3">
                  <div className="baloon"></div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-4 mt-2 px-3">
                  <div className="text-center">
                    <div
                      className="btn-group btn-group-sm"
                      role="group"
                      aria-label="Basic example"
                    >
                      <Link
                        to="/me/orders"
                        className={`${
                          currentTab === "active"
                            ? "active-button"
                            : "inactive-button"
                        } btn btn-secondary px-3`}
                      >
                        {this.props.t("All")}
                      </Link>
                      <Link
                        to="/me/orders/cancelled"
                        className={`${
                          currentTab === "cancelled"
                            ? "active-button"
                            : "inactive-button"
                        } btn btn-secondary px-3`}
                      >
                        {this.props.t("Cancelled")}
                      </Link>
                    </div>
                  </div>
                  {dataToShow.length > 0 ? (
                    <>
                      <div className="text-center pt-4 active-orders-label-wrapper">
                        <label className="mb-0">
                          {this.props.t("Showing")} {dataToShow.length}{" "}
                          {this.props.t("Bookings")}
                        </label>
                      </div>
                    </>
                  ) : (
                    <div className="jumbotron mt-3">
                      <h1 className="display-4">
                        {this.props.t("You have no bookings")}
                      </h1>
                      <p className="lead"></p>
                      <hr className="my-4" />
                      <p>
                        {this.props.t(
                          "Please checkout the various trip options available from our tripbonders"
                        )}
                      </p>
                      <p className="lead">
                        <Link to="/" className="btn btn-primary-gradient">
                          {this.props.t("Explore")}
                        </Link>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {dataToShow.map((order, index) => {
                return (
                  <Order
                    key={index}
                    data={order}
                    selectedCurrency={this.props.selectedCurrency}
                    local={this.props.local}
                    badgeText="active"
                    onCancelOrder={this.toggleCancelOrderModal}
                    onPaymentDetails={this.togglePaymentDetailsModal}
                    onUpdateInformation={this.toggleUpdateInformation}
                    toggleTripDetails={this.toggleTripDetails}
                  />
                );
              })}
            </div>
          </section>
          <Modal
            isOpen={this.state.cancelOrderModal}
            centered
            toggle={this.toggleCancelOrderModal}
            size="lg"
          >
            <ModalHeader toggle={this.toggleCancelOrderModal}>
              {this.props.t("CANCEL BOOKING")}
            </ModalHeader>
            <ModalBody className="pb-5">
              <h5 className="pl-3" style={{ fontSize: 18 }}>
                {this.props.t(
                  "Please tell us why you are cancelling this booking?"
                )}
              </h5>
              <div className="pl-3">
                {this.props.local.cancellingReasonMaster.map(
                  (reason, index) => (
                    <div key={index} className="custom-control custom-radio">
                      <input
                        checked={this.state.cancellingReason === reason}
                        type="radio"
                        id={`customRadio${index}`}
                        value={reason}
                        name="customRadio"
                        className="custom-control-input"
                        onChange={e => this.radioCancelReason(e.target.value)}
                      />

                      <label
                        className="custom-control-label"
                        htmlFor={`customRadio${index}`}
                      >
                        {reason}
                      </label>
                    </div>
                  )
                )}

                <div className="custom-control custom-radio">
                  <input
                    type="radio"
                    id="customRadio4"
                    value="other"
                    name="customRadio"
                    className="custom-control-input"
                    onChange={e => this.radioCancelReason(e.target.value)}
                  />

                  <label
                    className="custom-control-label"
                    htmlFor="customRadio4"
                  >
                    {this.props.t("Any other reason(s)")}
                  </label>
                </div>
                {this.state.cancellingReason === "other" ? (
                  <Input
                    className="mt-2"
                    type="textarea"
                    name="text"
                    id="exampleText"
                    placeholder={this.props.t("(optional)")}
                    value={this.state.otherReason}
                    onChange={e =>
                      this.setState({
                        otherReason: e.target.value
                      })
                    }
                  />
                ) : null}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                onClick={() =>
                  this.cancelSelectedOrder(
                    this.state.orderToCancel._id,
                    this.state.cancellingReason === "other"
                      ? this.state.otherReason
                      : this.state.cancellingReason
                  )
                }
              >
                {this.props.t("Cancel Booking")}
              </Button>{" "}
              <Button color="secondary" onClick={this.toggleCancelOrderModal}>
                {this.props.t("Close")}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal
            isOpen={this.state.paymentDetailsModal}
            toggle={() => this.togglePaymentDetailsModal(null)}
            centered
            className="payment-details-modal"
          >
            {this.state.paymentInfo ? (
              <Elements>
                <PaymentDetailsView
                  paymentInfo={this.state.paymentInfo}
                  togglePaymentDetailsModal={() =>
                    this.togglePaymentDetailsModal(null)
                  }
                  onOrderUpdate={this.handleOrderUpdate}
                />
              </Elements>
            ) : null}
          </Modal>
          <Modal
            isOpen={this.state.informationModal}
            toggle={() => this.toggleUpdateInformation(null)}
            centered
            className="payment-details-modal"
          >
            {this.state.selectedOrder ? (
              <OtherInfoModal
                orderInfo={this.state.selectedOrder}
                toggleUpdateInformation={() =>
                  this.toggleUpdateInformation(null)
                }
                onOrderUpdate={this.handleOrderUpdate}
              />
            ) : null}
          </Modal>
          <Modal
            style={{ maxWidth: "100%" }}
            isOpen={
              this.props.context !== null && this.state.tripDetails === true
            }
            toggle={() => {
              Controller.marketing.setCurrentPackage(null);
              this.toggleTripDetails();
            }}
            scrollable={true}
            size={"lg"}
            className="ordered-preview"
          >
            <button
              className="closePreview"
              onClick={() => {
                Controller.marketing.setCurrentPackage(null);
                this.toggleTripDetails();
              }}
            >
              <i className="fas fa-times fa-2x"></i>
            </button>
            <ModalBody id="sample-id" className="p-0">
              <TripViewer
                scrollableId="#sample-id"
                spyOffset={-1100}
                context={this.props.context}
                mode="preview"
              />
            </ModalBody>
          </Modal>
        </Fade>
      );
    }
  }
);

const mapStateToProps = state => ({
  local: state.local,
  selectedCurrency: state.local.selectedCurrency,
  context: state.marketing.currentPackage
});

export default connect(mapStateToProps)(OrdersPage);
