import Axios from "axios";
import queryString from "query-string";
export const AuthServices = {
  PWAInit: () => {
    return new Promise((resolve, reject) => {
      Axios.get("/auth/init/pwa")
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  Authenticate: (username, password) => {
    return new Promise((resolve, reject) => {
      Axios.post("/auth/login", queryString.stringify({ username, password }), {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      })
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  LogoutUser: () => {
    return new Promise((resolve, reject) => {
      Axios.get("/auth/logout")
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  RegisterUser: (fullname, email, password) => {
    return new Promise((resolve, reject) => {
      Axios.post("/auth/register", {
        name: fullname,
        emailAddress: email,
        password: password
      })
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  SetCurrency: key => {
    return new Promise((resolve, reject) => {
      Axios.post(
        `/public/api/listings/settings/currency?key=${key.toUpperCase()}`
      )
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  SetLanguage: key => {
    return new Promise((resolve, reject) => {
      Axios.post(`/public/api/listings/settings/language?key=${key}`)
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  RecoveryInitiate: email => {
    return new Promise((resolve, reject) => {
      Axios.post("/auth/recovery/password/initiate", {
        emailAddress: email
      })
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  },
  RecoveryExecute: (token, newPassword) => {
    return new Promise((resolve, reject) => {
      Axios.post("/auth/recovery/password/execute", {
        requestId: token,
        newPassword: newPassword
      })
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  }
};
