import React from "react";

const iconMaster = [
  {
    key: "wifiinroom",
    image: (
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 489.3 489.3"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <path
              d="M79.55,229.675c-10.2,10.2-10.2,26.8,0,37.1c10.2,10.2,26.8,10.2,37.1,0c70.6-70.6,185.5-70.6,256.1,0
			c5.1,5.1,11.8,7.7,18.5,7.7s13.4-2.6,18.5-7.7c10.2-10.2,10.2-26.8,0-37.1C318.75,138.575,170.55,138.575,79.55,229.675z"
            />

            <path
              d="M150.35,300.475c-10.2,10.2-10.2,26.8,0,37.1c10.2,10.2,26.8,10.2,37.1,0c31.5-31.6,82.9-31.6,114.4,0
			c5.1,5.1,11.8,7.7,18.5,7.7s13.4-2.6,18.5-7.7c10.2-10.2,10.2-26.8,0-37C286.95,248.475,202.35,248.475,150.35,300.475z"
            />

            <circle cx="244.65" cy="394.675" r="34.9" />
            <path
              d="M481.65,157.675c-130.7-130.6-343.3-130.6-474,0c-10.2,10.2-10.2,26.8,0,37.1c10.2,10.2,26.8,10.2,37.1,0
			c110.2-110.3,289.6-110.3,399.9,0c5.1,5.1,11.8,7.7,18.5,7.7s13.4-2.6,18.5-7.7C491.85,184.575,491.85,167.975,481.65,157.675z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    )
  },

  {
    key: "wifipublic",
    image: (
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 34.988 34.988"
        xmlSpace="preserve"
      >
        <g>
          <path
            d="M8.945,2.921L8.189,2.167c2.887-2.888,7.586-2.889,10.473-0.001l-0.756,0.757C15.435,0.452,11.415,0.452,8.945,2.921z
		 M17.355,3.474c-2.168-2.166-5.693-2.166-7.859,0.001l0.757,0.755C12.001,2.48,14.85,2.48,16.6,4.229L17.355,3.474z M11.604,5.58
		c1.004-1.006,2.641-1.006,3.646,0l0.756-0.757c-1.422-1.423-3.736-1.423-5.16,0.001L11.604,5.58z M20.643,15.517l-0.24,0.24
		c-0.535,0.535-0.535,1.402,0.002,1.937l0.105,0.105c0.535,0.536,1.399,0.536,1.936,0.001l1.81-1.808l0.237-0.239
		c0.535-0.535,0.535-1.403,0-1.938l-0.107-0.106c-0.532-0.535-1.399-0.535-1.937,0L20.643,15.517z M24.627,16.366l-1.809,1.809
		c-0.532,0.533-0.532,1.401,0,1.936l0.104,0.106c0.535,0.534,1.401,0.534,1.937,0l0.24-0.241l1.567-1.565l0.237-0.24
		c0.537-0.535,0.535-1.4,0.002-1.938L26.8,16.128c-0.534-0.534-1.399-0.537-1.937-0.001L24.627,16.366z M30.598,25.158l-8.08,8.081
		c-0.674,0.674-1.777,0.674-2.449,0.001l-1.391-1.392l-1.472,1.47c0,0-2.191,2.176-5.16,1.561c-0.023-0.005-0.047-0.008-0.069-0.013
		c-0.129-0.028-0.261-0.066-0.392-0.105c-0.107-0.032-0.219-0.07-0.328-0.11c-0.107-0.04-0.215-0.076-0.324-0.125
		c-0.219-0.095-0.438-0.203-0.661-0.333c-0.065-0.039-0.134-0.087-0.201-0.129c-0.187-0.115-0.375-0.246-0.564-0.39
		c-0.073-0.056-0.147-0.112-0.223-0.173c-0.252-0.203-0.505-0.422-0.76-0.678c-2.861-2.861-2.736-5.41-2.201-6.697
		c0.002-0.004,0.438-1.61,0.967-3.524c0.149-0.544,0.308-1.11,0.463-1.677L4.39,17.561c-0.674-0.673-0.674-1.775,0-2.45l8.08-8.079
		c0.674-0.674,1.777-0.674,2.449,0l4.688,4.686l0.426-0.427c0.535-0.535,1.402-0.533,1.938,0l0.105,0.107
		c0.535,0.534,0.535,1.4,0,1.936l-0.239,0.241l-1.565,1.566l-0.242,0.24c-0.532,0.533-1.396,0.536-1.935,0l-0.104-0.104
		c-0.534-0.536-0.534-1.403,0-1.938l0.238-0.24v-0.001l0.528-0.528l-3.97-3.969l-8.828,8.825l2.168,2.166
		C8.496,18.272,8.826,17.121,9,16.573c0.549-1.739,1.935-1.675,2.594-0.617c0.513,0.815,0.115,2.672-0.151,4.774
		c-0.053,0.415-0.192,1.097-0.354,1.821l8.793,8.792l8.823-8.825l-0.655-0.655l-0.77,0.77c-0.534,0.535-1.4,0.533-1.936,0
		l-0.107-0.107c-0.534-0.533-0.534-1.398,0-1.936l0.24-0.24l1.567-1.566l0.237-0.24c0.535-0.534,1.4-0.535,1.938-0.001l0.104,0.105
		c0.537,0.535,0.535,1.401,0,1.938l-0.237,0.238l0.002,0.002L28.9,21.012l1.697,1.697C31.271,23.382,31.271,24.483,30.598,25.158z
		 M8.021,13.544c0.08,0.082,0.215,0.08,0.295-0.001l2.586-2.586c0.082-0.081,0.082-0.214,0-0.295
		c-0.082-0.082-0.215-0.082-0.295-0.001l-2.586,2.586C7.939,13.329,7.939,13.461,8.021,13.544z M25.945,27.362
		c-0.338-0.339-0.887-0.339-1.225,0s-0.336,0.887,0,1.225c0.34,0.339,0.887,0.339,1.225,0.001
		C26.282,28.249,26.282,27.702,25.945,27.362z"
          />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    )
  },

  {
    key: "atm",
    image: (
      <svg viewBox="0 -59 366 366" xmlns="http://www.w3.org/2000/svg">
        <path d="m100.742188 177.453125c0-8.234375-2.105469-14.90625-4.707032-14.90625-2.597656 0-4.707031 6.671875-4.707031 14.90625s2.109375 14.90625 4.707031 14.90625c2.601563 0 4.707032-6.671875 4.707032-14.90625zm0 0" />
        <path d="m333 0h-300c-18.199219 0-33 15.257812-33 33.453125v5.546875h366v-5.546875c0-18.195313-14.800781-33.453125-33-33.453125zm0 0" />
        <path d="m117.378906 203.5c10.613282.007812 20.171875-6.425781 24.160156-16.257812 3.988282-9.835938 1.613282-21.109376-6.007812-28.496094-7.621094-7.386719-18.960938-9.414063-28.667969-5.121094 10.503907 14.144531 10.503907 33.503906 0 47.648438 3.3125 1.46875 6.894531 2.226562 10.515625 2.226562zm0 0" />
        <path d="m0 214.546875c0 18.195313 14.800781 33.453125 33 33.453125h300c18.199219 0 33-15.257812 33-33.453125v-106.546875h-366zm74.691406-77.148437c7.558594-.003907 14.960938 2.140624 21.34375 6.1875 16.773438-10.589844 38.773438-7.308594 51.722656 7.71875 12.949219 15.023437 12.949219 37.265624 0 52.292968-12.949218 15.023438-34.949218 18.308594-51.722656 7.714844-14.398437 9.078125-32.96875 8.058594-46.289062-2.542969-13.316406-10.601562-18.476563-28.46875-12.859375-44.539062 5.617187-16.070313 20.78125-26.832031 37.804687-26.832031zm0 0" />
        <path d="m0 53h366v41h-366zm0 0" />
        <path d="m74.691406 203.5c3.625 0 7.207032-.757812 10.519532-2.226562-10.507813-14.144532-10.507813-33.503907 0-47.648438-9.707032-4.285156-21.039063-2.253906-28.65625 5.128906-7.613282 7.386719-9.988282 18.652344-6.003907 28.484375s13.535157 16.261719 24.140625 16.261719zm0 0" />
      </svg>
    )
  },

  {
    key: "concierge",
    image: (
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <path
              d="M371.869,270.469c-25.082,37.278-67.659,61.853-115.87,61.853c-48.211,0-90.788-24.574-115.87-61.853
			c-60.924,3.153-109.521,53.71-109.521,115.405V512h450.784V385.874C481.392,324.178,432.794,273.621,371.869,270.469z
			 M294.899,452.107H217.1v-29.922h77.798V452.107z M294.899,392.214H217.1v-29.922h77.798V392.214z"
            />
          </g>
        </g>
        <g>
          <g>
            <path d="M146.361,97.344v31.397c18.175-4.036,33.924-15.32,43.588-31.397H146.361z" />
          </g>
        </g>
        <g>
          <g>
            <path
              d="M312.368,165.294c-28.633,0-55.791-7.446-78.538-21.532c-9.584-5.935-18.085-12.957-25.3-20.769
			c-15.288,19.407-37.408,32.416-62.167,36.18v33.588C146.361,253.216,195.545,302.4,255.999,302.4s109.638-49.184,109.638-109.638
			v-36.749C349.021,162.117,330.971,165.294,312.368,165.294z"
            />
          </g>
        </g>
        <g>
          <g>
            <path d="M225.868,97.345c17.763,23.109,50.347,38.028,86.5,38.028c19.075,0,37.314-4.075,53.27-11.829V97.345H225.868z" />
          </g>
        </g>
        <g>
          <g>
            <rect x="146.36" width="219.272" height="67.425" />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    )
  },

  {
    key: "smoking-area",
    image: (
      <svg
        id="Capa_1"
        enableBackground="new 0 0 524.235 524.235"
        viewBox="0 0 524.235 524.235"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m524.235 187.229c0-46.121-17.286-65.783-40.018-88.935.7 43.944-58.276 78.638-58.276 117.49 0 49.513 31.609 63.567 58.276 79.098 1.612-43.482 40.018-61.534 40.018-107.653z" />
        <path d="m399.246 196.562c1.074-28.981 26.669-41.014 26.669-71.755s-11.521-43.848-26.669-59.278c.468 29.289-38.834 52.415-38.834 78.31 0 33.003 21.064 42.371 38.834 52.723z" />
        <path d="m0 327.647h425.941v131.059h-425.941z" />
        <path d="m458.706 327.647h65.529v131.059h-65.529z" />
      </svg>
    )
  },

  {
    key: "daily-housekeeping",
    image: (
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 517.008 517.008"
        xmlSpace="preserve"
      >
        <g>
          <path
            d="M459.439,32.653c-39.713-22.921-94.458-23.887-142.844-2.515c-44.772,19.785-77.046,63.38-96.123,96.142
		c-8.587-2.094-17.777,1.989-21.764,10.299c-3.815,7.946-1.587,17.165,4.762,22.673c-1.74,3.873-2.802,6.474-3.203,7.487
		l-92.661,203.528c-9.343-4.304-20.474-0.469-25.083,8.816c-4.695,9.457-0.841,20.933,8.616,25.637l0.631,0.316L70.925,450.85h-51.8
		C8.568,450.85,0,459.418,0,469.975S8.568,489.1,19.125,489.1h95.625c10.557,0,19.125-8.568,19.125-19.125
		s-8.568-19.125-19.125-19.125h-1.798l13.263-29.137c1.75,0.507,3.519,0.851,5.288,0.851c7.028,0,13.799-3.892,17.146-10.614
		c4.38-8.816,1.262-19.307-6.828-24.509l96.018-210.9c2.591,1.195,5.297,1.789,7.984,1.789c7.124,0,13.961-3.997,17.251-10.854
		c4.379-9.113,0.822-19.919-7.842-24.834c16.361-27.569,42.554-62.367,76.835-77.514c36.854-16.285,79.35-16.036,108.257,0.65
		c21.258,12.269,33.469,31.786,36.318,58.025c5.336,49.142,0.812,82.094-13.406,97.958c-1.311,1.463-2.754,2.716-4.227,3.901v42.811
		c11.781-3.978,23.093-10.47,32.694-21.17c22.108-24.624,29.614-66.374,22.969-127.622
		C510.561,81.728,490.938,50.822,459.439,32.653z"
          />

          <path
            d="M404.972,355.225c12.479,0,24.279,2.84,34.903,7.793V272.93v-38.804v-22.338c0-10.566,1.004-9.562-9.562-9.562h-37.456
		c-10.566,0-117.141,8.769-145.293,147.931L229.5,465.193c0,10.566,8.559,23.906,19.125,23.906h90.758
		c-10.921-14.066-17.49-31.661-17.49-50.796C321.893,392.489,359.168,355.225,404.972,355.225z"
          />

          <path
            d="M449.438,392.441c-2.936-2.84-6.13-5.402-9.562-7.649c-10.05-6.579-22.032-10.442-34.903-10.442
		c-35.267,0-63.954,28.688-63.954,63.954c0,20.712,9.945,39.11,25.265,50.796c5.336,4.073,11.312,7.315,17.767,9.562
		c6.569,2.285,13.589,3.586,20.923,3.586c7.335,0,14.354-1.301,20.923-3.586c6.771-2.353,13.034-5.805,18.561-10.155
		c5.202-4.093,9.783-8.941,13.503-14.43c6.923-10.223,10.978-22.539,10.978-35.783c0-12.526-3.672-24.184-9.916-34.052
		C456.274,399.938,453.081,395.971,449.438,392.441z M404.972,406.222c17.71,0,32.073,14.362,32.073,32.072
		s-14.363,32.073-32.073,32.073s-32.072-14.363-32.072-32.073S387.253,406.222,404.972,406.222z"
          />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    )
  },

  {
    key: "dry-cleaning",
    image: (
      <svg viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg">
        <path d="m176 24v24h-8v-8a8 8 0 0 0 -8-8h-128a8 8 0 0 0 -8 8v8h-8v-24h-16v32a8 8 0 0 0 8 8h16v64h144v-64h16a8 8 0 0 0 8-8v-32z" />
        <path d="m24 192h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-16h16v16h16v-48h-144z" />
      </svg>
    )
  },

  {
    key: "luggage-storage",
    image: (
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 511.626 511.627"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <path
              d="M18.842,128.48C6.28,141.043,0,156.08,0,173.588v237.537c0,17.515,6.28,32.552,18.842,45.111
			c12.563,12.565,27.6,18.842,45.111,18.842h18.271V109.634H63.953C46.438,109.634,31.402,115.917,18.842,128.48z"
            />

            <path
              d="M365.446,63.955c0-7.614-2.663-14.084-7.994-19.414c-5.325-5.33-11.8-7.993-19.411-7.993H173.589
			c-7.612,0-14.083,2.663-19.414,7.993c-5.33,5.327-7.994,11.799-7.994,19.414v45.679h-36.545v365.444h292.359V109.634h-36.549
			V63.955z M328.904,109.634H182.725V73.091h146.179V109.634z"
            />

            <path
              d="M492.785,128.48c-12.563-12.562-27.601-18.846-45.111-18.846h-18.274v365.444h18.274c17.511,0,32.548-6.276,45.111-18.842
			c12.559-12.56,18.842-27.597,18.842-45.111V173.588C511.626,156.08,505.343,141.043,492.785,128.48z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    )
  },

  {
    key: "safety-deposit-boxes",
    image: (
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 297 297"
        xmlSpace="preserve"
      >
        <g>
          <path
            d="M273.833,0h-250C11.15,0,0.5,10.317,0.5,23v235c0,12.683,10.65,23,23.333,23H32.5v16h34v-16h164v16h34v-16h9.333
		c12.683,0,22.667-10.317,22.667-23V23C296.5,10.317,286.516,0,273.833,0z M263.5,149v76c0,12.683-9.984,23-22.667,23h-184
		C44.15,248,33.5,237.683,33.5,225v-10h-17v-33h17v-82h-17V67h17V56c0-12.683,10.65-23,23.333-23h184
		c12.683,0,22.667,10.317,22.667,23v76h17v17H263.5z"
          />

          <path d="M165.5,125v-16.334c0-9.189-7.182-16.666-16.418-16.666h-0.5c-9.235,0-17.082,7.477-17.082,16.666V125H165.5z" />
          <path
            d="M247.5,132V56c0-3.859-2.808-7-6.667-7h-184c-3.859,0-7.333,3.141-7.333,7v169c0,3.859,3.474,7,7.333,7h184
		c3.859,0,6.667-3.141,6.667-7v-76h-16v-17H247.5z M189.5,193c0,8.284-6.383,15-14.667,15h-51c-8.284,0-15.333-6.716-15.333-15v-53
		c0-5.34,3-10.015,7-12.673v-18.661C115.5,90.654,130.524,76,148.582,76h0.5c18.059,0,32.418,14.654,32.418,32.666v18.072
		c5,2.516,8,7.507,8,13.262V193z"
          />

          <path
            d="M156.5,168.387c4-2.691,6.833-7.271,6.833-12.471c0-8.283-6.634-15-14.917-15c-8.283,0-15.125,6.717-15.125,15
		c0,5.479,3.208,10.257,7.208,12.875V191h16V168.387z"
          />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    )
  },

  {
    key: "currency-exchange",
    image: (
      <svg viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg">
        <path d="m96 16a80.2 80.2 0 0 1 64 32h-8v16h24a8 8 0 0 0 7.59-5.47l8-24-15.18-5.06-3.175 9.53a95.994 95.994 0 0 0 -173.235 57h16a80.091 80.091 0 0 1 80-80z" />
        <path d="m176 96a80 80 0 0 1 -144 48h8v-16h-24a8 8 0 0 0 -7.59 5.47l-8 24 15.18 5.06 3.175-9.53a95.994 95.994 0 0 0 173.235-57z" />
        <path d="m40 96a56 56 0 1 0 56-56 56.063 56.063 0 0 0 -56 56zm80-32v16h-28a4 4 0 0 0 0 8h8a20 20 0 0 1 4 39.6v8.4h-16v-8h-16v-16h28a4 4 0 0 0 0-8h-8a20 20 0 0 1 -4-39.6v-8.4h16v8z" />
      </svg>
    )
  },

  {
    key: "laundry-service",
    image: (
      <svg viewBox="0 0 192 192" xmlns="http://www.w3.org/2000/svg">
        <path d="m16 184a8 8 0 0 0 8 8h144a8 8 0 0 0 8-8v-16h-160z" />
        <path d="m168 0h-144a8 8 0 0 0 -8 8v152h160v-152a8 8 0 0 0 -8-8zm-88 16a8 8 0 1 1 -8 8 8 8 0 0 1 8-8zm-40 8a8 8 0 1 1 8 8 8 8 0 0 1 -8-8zm56 120a48 48 0 1 1 48-48 48.054 48.054 0 0 1 -48 48zm56-112h-32v-16h32z" />
        <path d="m121.6 76.821a32 32 0 1 0 0 38.358 23.96 23.96 0 0 1 0-38.358z" />
      </svg>
    )
  },

  {
    key: "wheelchair",
    image: (
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 491.975 491.975"
        xmlSpace="preserve"
      >
        <g id="XMLID_28_">
          <path
            id="XMLID_31_"
            d="M225.315,96.963c26.748,0,48.479-21.706,48.479-48.481C273.794,21.699,252.063,0,225.315,0
		c-26.779,0-48.492,21.699-48.492,48.482C176.823,75.257,198.536,96.963,225.315,96.963z"
          />

          <path
            id="XMLID_30_"
            d="M300.233,371.688c-12.883,44.732-54.121,77.583-102.946,77.583c-59.126,0-107.209-48.085-107.209-107.193
		c0-43.754,26.396-81.413,64.066-98.054V198.58c-61.69,18.581-106.764,75.847-106.764,143.498
		c0,82.649,67.247,149.897,149.906,149.897c60.238,0,112.159-35.801,135.966-87.169l-16.926-33.255
		C311.575,371.59,306.071,371.64,300.233,371.688z"
          />

          <path
            id="XMLID_29_"
            d="M441.48,429.237l-64.939-127.672c-4.847-9.553-14.645-15.566-25.363-15.566h-83.173v-18.966h71.582
		c7.148,0,13.156-3.736,17.037-9.118c2.522-3.506,4.316-7.579,4.316-12.236c0-11.789-9.549-21.351-21.353-21.351h-43.125h-28.457
		V160c0-17.692-13.118-42.704-42.689-42.704c-23.584,0-42.703,19.122-42.703,42.704v139.372c0,24.058,19.503,43.561,43.562,43.561
		h78.265h29.284l57.024,112.117c5.011,9.875,15.011,15.573,25.389,15.573c4.35,0,8.761-0.994,12.883-3.104
		C443.054,460.401,448.628,443.251,441.48,429.237z"
          />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    )
  },

  {
    key: "pets",
    image: (
      <svg viewBox="0 -32 512.00011 512" xmlns="http://www.w3.org/2000/svg">
        <path d="m342.382812 239.351562c-23.039062-35.941406-62.277343-57.402343-104.964843-57.402343s-81.925781 21.460937-104.960938 57.402343l-55.515625 86.605469c-9.210937 14.367188-13.460937 30.96875-12.292968 47.996094 1.167968 17.03125 7.648437 32.890625 18.738281 45.875 11.097656 12.972656 25.761719 21.839844 42.40625 25.644531 16.644531 3.804688 33.707031 2.179688 49.339843-4.691406l1.046876-.464844c39.335937-16.945312 84.285156-16.789062 123.519531.464844 10.121093 4.449219 20.84375 6.699219 31.664062 6.699219 5.882813 0 11.800781-.667969 17.664063-2.003907 16.644531-3.800781 31.308594-12.667968 42.410156-25.644531 11.09375-12.972656 17.578125-28.835937 18.75-45.871093 1.171875-17.035157-3.078125-33.632813-12.289062-48.007813zm0 0" />
        <path d="m91.894531 239.238281c16.515625-6.34375 29.0625-19.652343 35.328125-37.476562 5.964844-16.960938 5.476563-36.109375-1.378906-53.921875-6.859375-17.800782-19.335938-32.332032-35.132812-40.921875-16.59375-9.019531-34.824219-10.488281-51.3125-4.132813-33.171876 12.753906-48.394532 53.746094-33.929688 91.398438 11.554688 29.96875 38.503906 48.886718 65.75 48.886718 6.957031 0 13.933594-1.234374 20.675781-3.832031zm0 0" />
        <path d="m199.613281 171.386719c41.46875 0 75.207031-38.4375 75.207031-85.683594 0-47.257813-33.738281-85.703125-75.207031-85.703125-41.464843 0-75.199219 38.445312-75.199219 85.703125 0 47.246094 33.734376 85.683594 75.199219 85.683594zm0 0" />
        <path d="m329.496094 192.4375h.003906c6.378906 2.117188 12.886719 3.128906 19.367188 3.128906 30.242187 0 59.714843-22.011718 70.960937-55.839844 6.476563-19.472656 6.050781-40.0625-1.199219-57.972656-7.585937-18.75-21.644531-32.359375-39.589844-38.324218-17.949218-5.964844-37.359374-3.476563-54.660156 7-16.527344 10.007812-29.191406 26.246093-35.660156 45.71875-13.652344 41.078124 4.640625 84.273437 40.777344 96.289062zm0 0" />
        <path d="m487.875 182.4375-.011719-.011719c-28.597656-21.125-71.367187-11.96875-95.347656 20.421875-23.957031 32.40625-20.210937 75.972656 8.34375 97.113282 10.414063 7.714843 22.71875 11.402343 35.3125 11.402343 21.949219 0 44.785156-11.203125 60.050781-31.804687 23.953125-32.40625 20.210938-75.972656-8.347656-97.121094zm0 0" />
      </svg>
    )
  },

  {
    key: "front-desk",
    image: (
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 45.564 45.564"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <path
              d="M43.583,36.837H1.98c-1.094,0-1.98,0.887-1.98,1.98s0.887,1.981,1.98,1.981h41.604c1.094,0,1.98-0.888,1.98-1.981
			C45.562,37.723,44.677,36.837,43.583,36.837z"
            />

            <path
              d="M3.45,34.602h8.899c0.175,0,0.342-0.092,0.455-0.223c0.113-0.134,0.178-0.323,0.15-0.495
			c-0.334-2.115-1.94-3.801-4.03-4.211V28.93c0-0.547-0.461-0.989-1.009-0.989s-1.009,0.442-1.009,0.989v0.742
			c-2.089,0.409-3.713,2.096-4.047,4.212c-0.027,0.172,0.023,0.361,0.137,0.495C3.11,34.511,3.276,34.602,3.45,34.602z"
            />

            <path
              d="M17.03,34.602h24.549c0.231,0,0.429-0.182,0.465-0.411c0.292-1.836,1.399-9.535-0.063-11.765
			c-0.987-1.503-3.522-2.535-6.273-3.193c-0.021,0.016-6.416,14.375-6.416,14.375S23.065,19.21,23.05,19.197
			c-2.763,0.657-5.429,1.721-6.418,3.229c-1.464,2.229-0.356,9.929-0.065,11.765C16.603,34.42,16.799,34.602,17.03,34.602z"
            />

            <path
              d="M29.305,18.495c3.786,0,6.865-3.08,6.865-6.865c0-3.785-3.079-6.865-6.865-6.865c-3.785,0-6.864,3.08-6.864,6.865
			C22.441,15.415,25.52,18.495,29.305,18.495z M24.602,10.948c0.109-0.013,0.231-0.029,0.365-0.051
			c1.473-0.234,4.258-0.984,5.329-3.363c0,0,1.013,2.248,3.354,2.174c0.195,0.526,0.406,1.238,0.406,1.922
			c0,2.62-2.132,4.753-4.752,4.753c-2.621,0-4.754-2.133-4.754-4.753C24.552,11.398,24.568,11.171,24.602,10.948z"
            />

            <path
              d="M28.541,19.4c-0.202,0-0.379,0.198-0.379,0.43v8.253c0,0.09,0.031,0.176,0.076,0.246l0.77,1.201
			c0.067,0.106,0.178,0.17,0.293,0.172s0.225-0.059,0.295-0.162l0.807-1.207c0.049-0.074,0.065-0.164,0.065-0.256V19.83
			c0-0.231-0.144-0.43-0.347-0.43H28.541z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    )
  },

  {
    key: "facilities",
    image: (
      <svg viewBox="-110 0 512 512" xmlns="http://www.w3.org/2000/svg">
        <path d="m0 328.539062 30 60v123.460938h30v-123.460938l30-60v-198.539062h-90zm30-168.539062h30v30h-30zm0 60h30v101.460938l-15 30-15-30zm0 0" />
        <path d="m120 422h37.5v90h-37.5zm0 0" />
        <path d="m240 130h-120v262h37.5v-75.5h30v195.5h37.5v-329.5h30v134.5c0 10.339844 8.410156 18.75 18.75 18.75s18.75-8.410156 18.75-18.75v-134.5c0-28.949219-23.550781-52.5-52.5-52.5zm0 0" />
        <path d="m172.5 100c27.570312 0 50-22.429688 50-50s-22.429688-50-50-50-50 22.429688-50 50 22.429688 50 50 50zm0 0" />
      </svg>
    )
  },

  {
    key: "checkin",
    image: (
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 60 60"
        xmlSpace="preserve"
      >
        <g>
          <path
            d="M42.117,43.007c-0.55-0.067-1.046,0.327-1.11,0.876s0.328,1.046,0.876,1.11C52.399,46.231,58,49.567,58,51.5
		c0,2.714-10.652,6.5-28,6.5S2,54.214,2,51.5c0-1.933,5.601-5.269,16.117-6.507c0.548-0.064,0.94-0.562,0.876-1.11
		c-0.065-0.549-0.561-0.945-1.11-0.876C7.354,44.247,0,47.739,0,51.5C0,55.724,10.305,60,30,60s30-4.276,30-8.5
		C60,47.739,52.646,44.247,42.117,43.007z"
          />

          <path
            d="M29.823,54.757L45.164,32.6c5.754-7.671,4.922-20.28-1.781-26.982C39.761,1.995,34.945,0,29.823,0
		s-9.938,1.995-13.56,5.617c-6.703,6.702-7.535,19.311-1.804,26.952L29.823,54.757z M30,12c3.86,0,7,3.141,7,7s-3.14,7-7,7
		s-7-3.141-7-7S26.14,12,30,12z"
          />
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    )
  },

  {
    key: "elevator",
    image: (
      <svg
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 471.418 471.418"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <polygon points="60.343,0 60.343,471.418 94.343,471.418 94.343,34 423.777,34 423.777,471.418 457.777,471.418 457.777,0 		" />
            <g>
              <rect x="104.194" y="43.906" width="149.255" height="400.604" />
              <rect x="264.672" y="43.906" width="149.254" height="400.604" />
            </g>
            <path
              d="M13.641,281.419h36.811v-91.362H13.641V281.419z M32.044,201.635l11.34,19.123h-22.68L32.044,201.635z M43.384,250.718
			l-11.34,19.123l-11.34-19.123H43.384z"
            />
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    )
  },

  {
    key: "security",
    image: (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 300 300"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <g>
              <polygon
                points="121.631,143.635 107.992,155.633 140.718,192.109 140.782,192.045 140.826,192.109 199.514,132.467 
				189.039,118.698 141.34,157.952 			"
              />

              <path
                d="M149.997,0C67.158,0,0.003,67.161,0.003,149.997S67.158,300,149.997,300s150-67.163,150-150.003S232.837,0,149.997,0z
				 M150,232.886c-77.185,0-82.683-130.592-82.683-130.592l82.681-35.187H150l82.681,35.187
				C232.681,102.294,227.183,232.886,150,232.886z"
              />
            </g>
          </g>
        </g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    )
  },

  {
    key: "ac",
    image: (
      <svg
        id="air-conditioner"
        xmlns="http://www.w3.org/2000/svg"
        width="43.301"
        height="43.301"
        viewBox="0 0 43.301 43.301"
      >
        <g id="Group_1598" data-name="Group 1598">
          <g id="Group_1597" data-name="Group 1597">
            <path
              id="Path_3781"
              data-name="Path 3781"
              d="M41.61,0H1.691A1.691,1.691,0,0,0,0,1.691V21.651a1.691,1.691,0,0,0,1.691,1.691H41.61A1.691,1.691,0,0,0,43.3,21.651V1.691A1.691,1.691,0,0,0,41.61,0ZM9.81,19.959H3.383V17.422H9.81Zm20.3,0H13.193V17.422H30.108Zm9.81,0H33.491V17.422h6.428Zm0-5.92H3.383V3.383H39.918Z"
              fill="#8f8f8f"
            />

            <path
              id="Path_3782"
              data-name="Path 3782"
              d="M173.667,314.314a3.118,3.118,0,0,1,1.074-2.338,1.691,1.691,0,0,0-2.562-2.209,6.4,6.4,0,0,0,0,9.095,3.081,3.081,0,0,1,0,4.676,1.691,1.691,0,0,0,2.562,2.209,6.4,6.4,0,0,0,0-9.095A3.118,3.118,0,0,1,173.667,314.314Z"
              transform="translate(-155.883 -283.031)"
              fill="#8f8f8f"
            />

            <path
              id="Path_3783"
              data-name="Path 3783"
              d="M269.989,314.314a3.118,3.118,0,0,1,1.074-2.338,1.691,1.691,0,0,0-2.562-2.209,6.4,6.4,0,0,0,0,9.095,3.081,3.081,0,0,1,0,4.676,1.691,1.691,0,1,0,2.562,2.209,6.4,6.4,0,0,0,0-9.094A3.118,3.118,0,0,1,269.989,314.314Z"
              transform="translate(-244.058 -283.031)"
              fill="#8f8f8f"
            />
          </g>
        </g>
      </svg>
    )
  },

  {
    key: "addt-info",
    image: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.258 44.381">
        <path
          id="Path_3792"
          data-name="Path 3792"
          d="M34.645,29.973H5.547a2.947,2.947,0,0,1,0-5.893H30.885l6.1-8.264c1.5-2.048,2.85-4.112,2.85-6.83A9,9,0,0,0,30.9,0h-.054a9,9,0,0,0-8.986,8.987c0,2.718,1.349,4.781,2.851,6.83l4.152,5.663H5.547a5.547,5.547,0,0,0,0,11.094h29.1a3.013,3.013,0,1,1,0,6.025H8.771a4.481,4.481,0,1,0,0,2.6H34.645a5.613,5.613,0,1,0,0-11.226ZM30.853,13.611a4.625,4.625,0,1,1,4.624-4.625A4.629,4.629,0,0,1,30.853,13.611Zm0,0"
          transform="translate(0 0)"
          fill="#8f8f8f"
        />
      </svg>
    )
  },

  {
    key: "parking-fee",
    image: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.172 40.581">
        <g id="parking-ticket" transform="translate(-53.05)">
          <g
            id="Group_1600"
            data-name="Group 1600"
            transform="translate(67.039 12.511)"
          >
            <g id="Group_1599" data-name="Group 1599" transform="translate(0)">
              <path
                id="Path_3789"
                data-name="Path 3789"
                d="M232.318,157.843h-2.78v4.318h2.78a2.159,2.159,0,0,0,0-4.318Z"
                transform="translate(-229.538 -157.843)"
                fill="#8f8f8f"
              />
            </g>
          </g>
          <g
            id="Group_1602"
            data-name="Group 1602"
            transform="translate(53.05)"
          >
            <g id="Group_1601" data-name="Group 1601" transform="translate(0)">
              <path
                id="Path_3790"
                data-name="Path 3790"
                d="M79.024,5.133,74.126.974l-4.96,4.159L64.212.978l-4.96,4.159L53.05,0V40.581H85.222V0Zm-14.365,5h5.16a4.539,4.539,0,0,1,0,9.077h-2.78v6.129h-2.38V10.131Zm14,25.462H59.617v-2.38H78.654Zm0-5H59.617v-2.38H78.654Z"
                transform="translate(-53.05)"
                fill="#8f8f8f"
              />
            </g>
          </g>
        </g>
      </svg>
    )
  },

  {
    key: "toll",
    image: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.742 38.438">
        <g id="barrier" transform="translate(0.258 -46)">
          <path
            id="Path_3784"
            data-name="Path 3784"
            d="M276.612,171.564,279.7,166h-5.174l-3.091,5.564Z"
            transform="translate(-246.265 -108.871)"
            fill="#8f8f8f"
          />

          <path
            id="Path_3785"
            data-name="Path 3785"
            d="M366.612,171.564,369.7,166h-5.174l-3.091,5.564Z"
            transform="translate(-327.918 -108.871)"
            fill="#8f8f8f"
          />

          <path
            id="Path_3786"
            data-name="Path 3786"
            d="M212.526,171.564,215.617,166H210v5.564Z"
            transform="translate(-190.525 -108.871)"
            fill="#8f8f8f"
          />

          <path
            id="Path_3787"
            data-name="Path 3787"
            d="M451.438,171.564h5.617V166h-2.526Z"
            transform="translate(-409.571 -108.871)"
            fill="#8f8f8f"
          />

          <path
            id="Path_3788"
            data-name="Path 3788"
            d="M32.782,50.173v1.391H30V76.6H43.911V51.564H41.129V50.173a4.173,4.173,0,1,0-8.347,0Zm4.173,12.52A1.391,1.391,0,1,1,38.347,61.3,1.391,1.391,0,0,1,36.956,62.693Z"
            transform="translate(-27.218)"
            fill="#8f8f8f"
          />

          <rect
            id="Rectangle_396"
            data-name="Rectangle 396"
            width="19"
            height="5"
            transform="translate(-0.258 79.438)"
            fill="#8f8f8f"
          />
        </g>
      </svg>
    )
  },

  {
    key: "insurance",
    image: (
      <svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="first-aid"
        className="svg-inline--fa fa-first-aid fa-w-18"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
      >
        <path
          fill="currentColor"
          d="M0 80v352c0 26.5 21.5 48 48 48h48V32H48C21.5 32 0 53.5 0 80zm128 400h320V32H128v448zm64-248c0-4.4 3.6-8 8-8h56v-56c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v56h56c4.4 0 8 3.6 8 8v48c0 4.4-3.6 8-8 8h-56v56c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8v-56h-56c-4.4 0-8-3.6-8-8v-48zM528 32h-48v448h48c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48z"
        ></path>
      </svg>
    )
  }
];

export const AmenitiesIcon = function(props) {
  const icon = iconMaster.find(i => i.key === props.id || null);
  return icon ? icon.image : null;
};
