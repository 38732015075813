import Axios from "axios";
export const ItineraryServices = {
  CreateItinerary: (title, noOfDays) => {
    return new Promise((resolve, reject) => {
      Axios.post(
        `/api/listings/itinerary?name=${encodeURI(title)}&days=${encodeURI(
          noOfDays
        )}`.trim()
      )
        .then(response => {
          resolve((response.data && response.data) || null);
        })
        .catch(err => {
          reject((err.response && err.response && err.response.data) || err);
        });
    });
  }
};
