import { withTranslation } from "react-i18next";
import React from "react";
import Helmet from "react-helmet";
import { Fade } from "react-reveal";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { OrderServices } from "./../../services/Order.services";
import Controller from "../../store/controllers/index";
import { NETWORK_ERR_MESSAGE } from "../../resources/String";
import moment from "moment";
import { GetRawFormattedPrice, stripAddress } from "../../resources/Helper";
import matchSorter from "match-sorter";
import TripViewer from "./../../components/TripViewer";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import getConfig from "../../config";
import { AdditionalInfoMaster } from "shared-tripbonder-lib/lib/masters/package.master";
const MyOrders = withTranslation()(
  class MyOrders extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        allInfo: [],
        updateStatusPopUp: false,
        approveCancellationPopUp: false,
        statusType: "",
        statusUpdateId: "",
        message: "",
        orderDetailsModal: false,
        orderDetails: null,
        tripDetails: false
      };

      this.fetchOrder = this.fetchOrder.bind(this);
      this.updateStatus = this.updateStatus.bind(this);
      this.updateStatusPopUpToggle = this.updateStatusPopUpToggle.bind(this);
      this.approveCancellationPopupToggle = this.approveCancellationPopupToggle.bind(
        this
      );

      this.renderOrder = this.renderOrder.bind(this);
      this.renderPayment = this.renderPayment.bind(this);
      this.toggleOrderDetailsModal = this.toggleOrderDetailsModal.bind(this);
      this.renderAction = this.renderAction.bind(this);
      this.toggleTripDetails = this.toggleTripDetails.bind(this);
    }
    toggleTripDetails() {
      this.setState({
        tripDetails: !this.state.tripDetails
      });
    }
    toggleOrderDetailsModal(orderContext) {
      this.setState({
        orderDetailsModal: !this.state.orderDetailsModal,
        orderDetails: orderContext
      });
    }
    approveCancellationPopupToggle(type, _id) {
      this.setState({
        approveCancellationPopUp: !this.state.approveCancellationPopUp,
        statusType: type || null,
        statusUpdateId: _id,
        message: ""
      });
    }
    updateStatusPopUpToggle(type, _id) {
      this.setState({
        updateStatusPopUp: !this.state.updateStatusPopUp,
        statusType: type || null,
        statusUpdateId: _id,
        message: ""
      });
    }
    fetchOrder() {
      Controller.setGlobalLoader("Fetching information ...", false);
      setTimeout(() => {
        OrderServices.GetOrders()
          .then(orders => {
            Controller.clearGlobalMessage();
            this.setState({
              allInfo: orders
            });
          })
          .catch(err => {
            Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
          });
      }, 800);
    }
    updateStatus(
      _id,
      action,
      message = "",
      popUpToggleKey = "updateStatusPopUpToggle"
    ) {
      this[popUpToggleKey]();
      Controller.setGlobalLoader("Updating...", false);
      setTimeout(() => {
        OrderServices.UpdateStatus(_id, action, message)
          .then(response => {
            Controller.setGlobalSuccess(`Order ${action}`, true);
            this.setState({
              allInfo: this.state.allInfo.map(item => {
                if (item._id === _id) {
                  return response;
                }
                return item;
              })
            });

            setTimeout(() => Controller.clearGlobalMessage(), 1200);
          })
          .catch(err => {
            Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
          });
      }, 800);
    }
    componentDidMount() {
      this.fetchOrder();
    }
    renderImage(row) {
      const data = row.original;
      return (
        <>
          <div
            style={{
              height: 110,
              width: "100%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundImage: `url('${Controller.resolveAssetAddr(
                row.value
              )}')`
            }}
          ></div>
          <div>
            <b>{data.otherInfo.listingSnapshot.package.title}</b>
            <div>
              <small>{data.type}</small>
            </div>
          </div>
        </>
      );
    }
    renderOrderDetails(data) {
      let noOfDays = 1;
      const beginningDateMoment = moment(data.beginingDate);
      const endDateMoment = moment(data.endDate);
      noOfDays = endDateMoment.diff(beginningDateMoment, "day");
      noOfDays = noOfDays + 1;
      if (data.type === "itinerary") {
        return (
          <>
            <tr>
              <th scope="row">{this.props.t("No of Days:")}</th>
              <td> {data.listingSnapshot.package.dayCount}</td>
            </tr>
            <tr>
              <th scope="row">{this.props.t("No of People:")}</th>
              <td>
                {this.props.t("Adult(s):")} {data.adultPersonCount}{" "}
                {this.props.t("Children:")}{" "}
                {data.childCount + (data.childCountWithBed || 0)}
              </td>
            </tr>
            <tr>
              <th scope="row">{this.props.t("Date:")}</th>
              <td>
                {beginningDateMoment.format("ll")}
                {noOfDays > 1 ? (
                  <>
                    {this.props.t("-")}
                    {endDateMoment.format("ll")}
                  </>
                ) : null}
              </td>
            </tr>
            <tr>
              <th scope="row">{this.props.t("Payment Status:")}</th>
              <td>
                {data.paymentStatus === "paid_partial"
                  ? "Partially Paid"
                  : data.paymentStatus}
              </td>
            </tr>
            <tr>
              <th scope="row">{this.props.t("Payment Term:")}</th>
              <td>{data.paymentTerm}</td>
            </tr>
          </>
        );
      } else if (data.type === "accomodation") {
        return (
          <>
            <tr>
              <th scope="row">{this.props.t("No of Days:")}</th>
              <td>{`${noOfDays} Day${noOfDays > 1 ? "s" : ""}`}</td>
            </tr>
            <tr>
              <th scope="row">{this.props.t("Room:")}</th>
              <td>
                {
                  data.listingSnapshot.package.rooms[data.selectedRoomIndex]
                    .roomType
                }
              </td>
            </tr>
            <tr>
              <th scope="row">{this.props.t("Capacity:")}</th>
              <td>
                {
                  (
                    this.props.local.capacityMaster.find(
                      f => f.value === data.selectedCapacityOptions[0]
                    ) || { value: null }
                  ).label
                }
              </td>
            </tr>
            <tr>
              <th scope="row">{this.props.t("Date:")}</th>
              <td>
                {beginningDateMoment.format("ll")}
                {noOfDays > 1 ? (
                  <>
                    {this.props.t("-")}
                    {endDateMoment.format("ll")}
                  </>
                ) : null}
              </td>
            </tr>
            <tr>
              <th scope="row">{this.props.t("Payment Status:")}</th>
              <td>
                {data.paymentStatus === "paid_partial"
                  ? "Partially Paid"
                  : data.paymentStatus}
              </td>
            </tr>
            <tr>
              <th scope="row">{this.props.t("Payment Term:")}</th>
              <td>{data.paymentTerm}</td>
            </tr>
          </>
        );
      } else if (data.type === "transport") {
        return (
          <>
            <tr>
              <th scope="row">{this.props.t("No of Days:")}</th>
              <td>{`${noOfDays} Day${noOfDays > 1 ? "s" : ""}`}</td>
            </tr>
            <tr>
              <th scope="row">{this.props.t("Tariff:")}</th>
              <td>
                {data.tariff === "per-way" ? "Per Way" : null}
                {data.tariff === "normal" ? "Normal" : null}
              </td>
            </tr>
            <tr>
              <th scope="row">{this.props.t("Date:")}</th>
              <td>
                {beginningDateMoment.format("ll")}
                {noOfDays > 1 ? (
                  <>
                    {this.props.t("-")}
                    {endDateMoment.format("ll")}
                  </>
                ) : null}
              </td>
            </tr>
            <tr>
              <th scope="row">{this.props.t("Payment Status:")}</th>
              <td>
                {data.paymentStatus === "paid_partial"
                  ? "Partially Paid"
                  : data.paymentStatus}
              </td>
            </tr>
            <tr>
              <th scope="row">{this.props.t("Payment Term:")}</th>
              <td>{data.paymentTerm}</td>
            </tr>
          </>
        );
      } else if (data.type === "ticket") {
        return (
          <>
            <tr>
              <th scope="row">{this.props.t("No of People:")}</th>
              <td>{data.adultPersonCount}</td>
            </tr>
            <tr>
              <th scope="row">{this.props.t("Date:")}</th>
              <td>{beginningDateMoment.format("ll")}</td>
            </tr>
            <tr>
              <th scope="row">{this.props.t("Payment Status:")}</th>
              <td>
                {data.paymentStatus === "paid_partial"
                  ? "Partially Paid"
                  : data.paymentStatus}
              </td>
            </tr>
            <tr>
              <th scope="row">{this.props.t("Payment Term:")}</th>
              <td>{data.paymentTerm}</td>
            </tr>
          </>
        );
      }
    }

    renderAction(row) {
      const data = row.original;
      if (data.status === "confirmed" && !data.otherInfo.isUpcoming) {
        return (
          <>
            <Button
              onClick={() => {
                Controller.marketing.setCurrentPackage(
                  data.otherInfo.listingSnapshot
                );

                this.toggleTripDetails();
              }}
              className="btn btn-info btn-sm"
            >
              {this.props.t("Open package")}
            </Button>
            <Button
              onClick={() =>
                this.updateStatusPopUpToggle("completed", data._id)
              }
              className="btn btn-success btn-sm ml-2"
            >
              {this.props.t("Mark as Completed")}
            </Button>
          </>
        );
      }
      return (
        <Button
          onClick={() => {
            Controller.marketing.setCurrentPackage(
              data.otherInfo.listingSnapshot
            );

            this.toggleTripDetails();
          }}
          className="btn btn-info btn-sm"
        >
          {this.props.t("Open package")}
        </Button>
      );
    }

    renderOrder(row) {
      const data = row.original;
      let noOfDays = 1;
      const beginningDateMoment = moment(data.otherInfo.beginingDate);
      const endDateMoment = moment(data.otherInfo.endDate);
      let remainingDays = beginningDateMoment.fromNow(true);
      noOfDays = endDateMoment.diff(beginningDateMoment, "day");
      noOfDays = noOfDays + 1;
      if (data.type === "itinerary") {
        return (
          <>
            <div className="truncate">
              {this.props.t("Order ID :")}
              {data._id}
            </div>
            <div>
              {this.props.t("No of Days:")}
              {data.otherInfo.listingSnapshot.package.dayCount}
            </div>
            <div>
              <span>
                {this.props.t("No. of Adult:")}{" "}
                {data.otherInfo.adultPersonCount}
              </span>
              <br />
              <span>
                {this.props.t("Children:")} {data.otherInfo.childCount}
              </span>
              {(data.otherInfo.childCountWithBed || 0) > 0 ? (
                <>
                  <br />
                  <span>
                    {this.props.t("Children (with bed):")}{" "}
                    {data.otherInfo.childCountWithBed || 0}
                  </span>
                </>
              ) : null}
            </div>
            <div>
              {this.props.t("Date :")}
              {moment(data.otherInfo.beginingDate).format("ll")}
              {noOfDays > 1 ? (
                <>
                  {this.props.t("-")}
                  {endDateMoment.format("ll")}
                </>
              ) : null}
            </div>
            <div>
              <b>{this.props.t("Additional Information")}</b>
              <br />
              {data.addtInfoCollected
                ? Object.keys(data.addtInfoCollected).length > 0
                  ? Object.keys(data.addtInfoCollected).map((f, i) => {
                      const fieldInfo = AdditionalInfoMaster.find(
                        _f => _f.value === f
                      );

                      return (
                        <span key={i}>
                          {this.props.t((fieldInfo && fieldInfo.label) || f)}
                          {": "}
                          {data.addtInfoCollected[f]}
                          <br />
                        </span>
                      );
                    })
                  : null
                : null}
            </div>
            {data.status === "confirmed" && data.otherInfo.isUpcoming ? (
              <div>
                <b>
                  {remainingDays} {this.props.t("to go")}
                </b>
              </div>
            ) : null}
          </>
        );
      } else if (data.type === "ticket") {
        return (
          <>
            <div className="truncate">
              {this.props.t("Order ID :")}
              {data._id}
            </div>
            <div>
              {this.props.t("No. of persons:")}
              {data.otherInfo.adultPersonCount}
            </div>
            <div>
              {this.props.t("Date :")}
              {moment(data.otherInfo.beginingDate).format("ll")}
            </div>
            {data.status === "confirmed" && data.otherInfo.isUpcoming ? (
              <div>
                <b>
                  {remainingDays}
                  {this.props.t("to go")}
                </b>
              </div>
            ) : null}
          </>
        );
      } else if (data.type === "accomodation") {
        return (
          <>
            <div className="truncate">
              {this.props.t("Order ID :")}
              {data._id}
            </div>
            <div>
              {this.props.t("No. of Days:")}
              {`${noOfDays} Day${noOfDays > 1 ? "s" : ""}`}
            </div>
            <div>
              {this.props.t("Room :")}{" "}
              {
                data.otherInfo.listingSnapshot.package.rooms[
                  data.otherInfo.selectedRoomIndex
                ].roomType
              }
            </div>
            <div>
              {
                (
                  this.props.local.capacityMaster.find(
                    f => f.value === data.otherInfo.selectedCapacityOptions[0]
                  ) || { value: null }
                ).label
              }
            </div>
            <div>
              {this.props.t("Date :")}
              {moment(data.otherInfo.beginingDate).format("ll")}
              {noOfDays > 1 ? (
                <>
                  {this.props.t("-")}
                  {endDateMoment.format("ll")}
                </>
              ) : null}
            </div>
            {data.status === "confirmed" && data.otherInfo.isUpcoming ? (
              <div>
                <b>
                  {remainingDays}
                  {this.props.t("to go")}
                </b>
              </div>
            ) : null}
          </>
        );
      } else if (data.type === "transport") {
        return (
          <>
            <div className="truncate">
              {this.props.t("Order ID :")}
              {data._id}
            </div>
            <div>
              {this.props.t("No. of Days:")}
              {`${noOfDays} Day${noOfDays > 1 ? "s" : ""}`}
            </div>
            <div>
              {this.props.t("Date :")}
              {moment(data.otherInfo.beginingDate).format("ll")}
              {noOfDays > 1 ? (
                <>
                  {this.props.t("-")}
                  {endDateMoment.format("ll")}
                </>
              ) : null}
            </div>
            <div>
              {this.props.t("Tariff:")}{" "}
              {data.otherInfo.tariff === "per-way"
                ? "per way"
                : data.otherInfo.tariff}
            </div>
            {data.status === "confirmed" && data.otherInfo.isUpcoming ? (
              <div>
                <b>
                  {remainingDays}
                  {this.props.t("to go")}
                </b>
              </div>
            ) : null}
          </>
        );
      }
    }
    renderPayment(row) {
      const data = row.original;
      return (
        <>
          <div className="truncate">
            {this.props.t("Payment Status :")}{" "}
            {data.otherInfo.paymentStatus === "paid_partial"
              ? "Partially Paid"
              : data.otherInfo.paymentStatus}
          </div>
          <div>
            {this.props.t("Payment Term:")}
            {data.otherInfo.paymentTerm}
          </div>
          <div>
            {this.props.t("status:")}
            {data.status}
          </div>
          <div>
            {this.props.t("Net Amount:")}{" "}
            {GetRawFormattedPrice(
              data.otherInfo.effectiveNetAmount,
              data.otherInfo.currencyId
            )}
          </div>
          <div>
            {this.props.t("Amount Paid:")}{" "}
            {GetRawFormattedPrice(
              data.otherInfo.effectiveInitialAmountPayable,
              data.otherInfo.currencyId
            )}
          </div>
          <div>
            {this.props.t("Balance Amount:")}{" "}
            {GetRawFormattedPrice(
              data.otherInfo.effectiveRemainingAmount,
              data.otherInfo.currencyId
            )}
          </div>
        </>
      );
    }

    renderCustomerInfo(row) {
      const data = row.original;
      return (
        <>
          <div>{data.customerName}</div>
          <div>{this.props.t("BILLING DETAILS :")}</div>
          <div>
            {data.otherInfo.billingName ? (
              <div>{data.otherInfo.billingName}</div>
            ) : null}
            <div>
              {data.otherInfo.addressLine1}
              {this.props.t(",")}
            </div>
            {data.otherInfo.addressLine2 ? (
              <div>
                {data.otherInfo.addressLine2}
                {this.props.t(",")}
              </div>
            ) : null}
            <span>{data.otherInfo.city}</span>
            {this.props.t(",")} <span>{data.otherInfo.state}</span>
            {this.props.t(",")} <span>{data.otherInfo.postalCode}</span>
            {this.props.t(",")}{" "}
            <span>
              {data.otherInfo.country}
              {this.props.t(".")}
            </span>
            <div>
              <span>{data.otherInfo.phoneNumber}</span>{" "}
              {data.otherInfo.altPhoneNumber ? (
                <span>
                  {this.props.t("Alt:")}
                  {data.otherInfo.altPhoneNumber}
                </span>
              ) : null}
            </div>
          </div>
        </>
      );
    }
    render() {
      const {
        match: {
          params: { status }
        }
      } = this.props;
      const activeOrders = this.state.allInfo
        .filter(f => f.isUpcoming === false && f.status === "confirmed")
        .map(order => {
          return {
            _id: order._id,
            coverImage: order.listingSnapshot.package.defaultCoverArtId,
            type: order.type,
            customerName: order.customer.name,
            status: order.status,
            otherInfo: order,
            addtInfoCollected: order.addtInfoCollected
          };
        });

      const upcomingOrders = this.state.allInfo
        .filter(f => f.status === "confirmed" && f.isUpcoming === true)
        .map(order => {
          return {
            _id: order._id,
            coverImage: order.listingSnapshot.package.defaultCoverArtId,
            type: order.type,
            customerName: order.customer.name,
            status: order.status,
            otherInfo: order,
            addtInfoCollected: order.addtInfoCollected
          };
        });

      const completedOrders = this.state.allInfo
        .filter(f => f.status === "completed")
        .map(order => {
          return {
            _id: order._id,
            coverImage: order.listingSnapshot.package.defaultCoverArtId,
            type: order.type,
            customerName: order.customer.name,
            status: order.status,
            otherInfo: order,
            addtInfoCollected: order.addtInfoCollected
          };
        });

      const cancelledOrders = this.state.allInfo
        .filter(f => f.status === "cancelled")
        .map(order => {
          return {
            _id: order._id,
            coverImage: order.listingSnapshot.package.defaultCoverArtId,
            type: order.type,
            customerName: order.customer.name,
            status: order.status,
            otherInfo: order,
            addtInfoCollected: order.addtInfoCollected
          };
        });

      const rejectedOrders = this.state.allInfo
        .filter(f => f.status === "rejected")
        .map(order => {
          return {
            _id: order._id,
            coverImage: order.listingSnapshot.package.defaultCoverArtId,
            type: order.type,
            customerName: order.customer.name,
            status: order.status,
            otherInfo: order,
            addtInfoCollected: order.addtInfoCollected
          };
        });

      const disputedOrders = this.state.allInfo
        .filter(f => f.status === "disputed")
        .map(order => {
          return {
            _id: order._id,
            coverImage: order.listingSnapshot.package.defaultCoverArtId,
            type: order.type,
            customerName: order.customer.name,
            status: order.status,
            otherInfo: order,
            addtInfoCollected: order.addtInfoCollected
          };
        });

      let currentTab;
      let dataToShow = [];
      switch (status) {
        case "confirmed": {
          currentTab = "confirmed";
          dataToShow = upcomingOrders;
          break;
        }
        case "completed": {
          currentTab = "completed";
          dataToShow = completedOrders;
          break;
        }
        case "cancelled": {
          currentTab = "cancelled";
          dataToShow = cancelledOrders;
          break;
        }
        case "rejected": {
          currentTab = "rejected";
          dataToShow = rejectedOrders;
          break;
        }
        case "disputed": {
          currentTab = "disputed";
          dataToShow = disputedOrders;
          break;
        }
        default: {
          currentTab = "active";
          dataToShow = activeOrders;
          break;
        }
      }

      const placedOrders = this.state.allInfo.filter(
        f => f.status === "placed"
      );

      const cancellationRequestedOrders = this.state.allInfo.filter(
        f => f.status === "cancellation_requested"
      );

      return (
        <>
          <Fade duration={300}>
            <Helmet>
              <title>{this.props.t("My Bookings - Tripbonder.com")}</title>
            </Helmet>
            <div className="container-fluid my-orders-page-wrapper">
              <div className="row px-0 px-lg-3 pt-2 pt-lg-3">
                <div className="col-12 col-lg-6 title-wrapper text-center text-lg-left">
                  <label className="mb-0">
                    {this.props.t("Here are your bookings,")}{" "}
                    {this.props.local.currentUser.name}
                    {this.props.t("!")}
                  </label>
                  <h3>{this.props.t("Bookings")}</h3>
                </div>
                <div className="col-12 col-lg-6 text-center text-lg-right img-wrapper">
                  <img
                    src={require("./../../assets/images/baloon.jpg")}
                    alt=""
                  />
                </div>
                {cancellationRequestedOrders.length > 0 ? (
                  <div className="col-12 py-3 px-2 px-lg-3">
                    <div
                      className="order-invites-background-wrapper px-3 pb-3"
                      style={{ backgroundColor: "#dc6767" }}
                    >
                      <div className="text-center pt-3 pb-2">
                        <h6 style={{ color: "white" }}>
                          {this.props.t("Cancellation Requests")}

                          <span className="badge badge-warning ml-2  pb-1">
                            {cancellationRequestedOrders.length}
                          </span>
                        </h6>
                      </div>
                      <div className="order-invites-content-wrapper">
                        {cancellationRequestedOrders.map((details, index) => {
                          return (
                            <div
                              key={index}
                              className="d-flex pb-1 pb-sm-2 pt-1 px-0 px-sm-3 o-border"
                            >
                              <div className="dropdown-button-wrapper dropleft d-sm-none">
                                <button
                                  id="orderDropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fas fa-ellipsis-v"></i>
                                </button>
                                <div
                                  className="dropdown-menu py-0"
                                  aria-labelledby="orderDropdownMenuButton"
                                >
                                  <button
                                    className="btn btn-link dropdown-item"
                                    onClick={() => {
                                      Controller.marketing.setCurrentPackage(
                                        details.listingSnapshot
                                      );

                                      this.toggleTripDetails();
                                    }}
                                  >
                                    {this.props.t("Trip Details")}
                                  </button>
                                  <button
                                    className="btn btn-link dropdown-item"
                                    onClick={() =>
                                      this.toggleOrderDetailsModal(details)
                                    }
                                  >
                                    {this.props.t("Order Details")}
                                  </button>
                                  <button className="btn btn-link dropdown-item">
                                    {this.props.t("Contact Traveller")}
                                  </button>
                                </div>
                              </div>
                              <div className="flex-shrink-0 d-flex  justify-content-md-left align-items-md-center package-title-wrapper px-3 px-sm-0 py-2 py-sm-0">
                                <div>
                                  <span className="d-lg-none">
                                    {" "}
                                    {moment(details.createdAt).format("ll")}
                                  </span>
                                  <h6 className="mb-0">
                                    {details.customer.name}
                                  </h6>
                                  <small>
                                    {details.listingSnapshot.package.title}
                                  </small>
                                </div>
                              </div>
                              <div className="flex-fill d-flex justify-content-center align-items-center">
                                <button
                                  onClick={() => {
                                    Controller.marketing.setCurrentPackage(
                                      details.listingSnapshot
                                    );

                                    this.toggleTripDetails();
                                  }}
                                  type="button"
                                  className="btn btn-link trip-orders-button-wrapper d-none d-sm-block"
                                >
                                  {this.props.t("Trip Details")}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link trip-orders-button-wrapper d-none d-sm-block"
                                  onClick={() =>
                                    this.toggleOrderDetailsModal(details)
                                  }
                                >
                                  {this.props.t("Order Details")}
                                </button>
                                {/* <button
                                    type="button"
                                    className="btn btn-link contact-button d-none d-sm-block"
                                  >
                                    {this.props.t("Contact Traveller")}
                                  </button> */}
                              </div>
                              <div className="flex-shrink-0 d-flex justify-content-center align-items-center py-2 py-sm-0">
                                <span className="px-2 px-xl-5 d-none d-lg-block">
                                  {moment(details.createdAt).format("ll")}
                                </span>
                                <button
                                  className="check-button mx-2"
                                  onClick={() =>
                                    this.approveCancellationPopupToggle(
                                      "cancelled",
                                      details._id
                                    )
                                  }
                                >
                                  <i className="fas fa-check"></i>{" "}
                                  <span className="d-sm-none">
                                    {this.props.t("Approve Cancellation")}
                                  </span>
                                </button>
                                <button
                                  className="close-button mx-2"
                                  onClick={() =>
                                    this.approveCancellationPopupToggle(
                                      "disputed",
                                      details._id
                                    )
                                  }
                                >
                                  <i className="fas fa-times"></i>{" "}
                                  <span className="d-sm-none">
                                    {this.props.t("Raise Dispute")}
                                  </span>
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : null}
                {placedOrders.length > 0 ? (
                  <div className="col-12 py-3 px-2 px-lg-3">
                    <div className="order-invites-background-wrapper px-3 pb-3">
                      <div className="text-center pt-3 pb-2">
                        <h6>
                          {this.props.t("Order Invites")}

                          <span className="badge badge-warning ml-2  pb-1">
                            {placedOrders.length}
                          </span>
                        </h6>
                      </div>
                      <div className="order-invites-content-wrapper">
                        {placedOrders.map((details, index) => {
                          return (
                            <div
                              key={index}
                              className="d-flex pb-1 pb-sm-2 pt-1 px-0 px-sm-3 o-border"
                            >
                              <div className="dropdown-button-wrapper dropleft d-sm-none">
                                <button
                                  id="orderDropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fas fa-ellipsis-v"></i>
                                </button>
                                <div
                                  className="dropdown-menu py-0"
                                  aria-labelledby="orderDropdownMenuButton"
                                >
                                  <button
                                    className="btn btn-link dropdown-item"
                                    onClick={() => {
                                      Controller.marketing.setCurrentPackage(
                                        details.listingSnapshot
                                      );

                                      this.toggleTripDetails();
                                    }}
                                  >
                                    {this.props.t("Trip Details")}
                                  </button>
                                  <button
                                    className="btn btn-link dropdown-item"
                                    onClick={() =>
                                      this.toggleOrderDetailsModal(details)
                                    }
                                  >
                                    {this.props.t("Order Details")}
                                  </button>
                                  <button className="btn btn-link dropdown-item">
                                    {this.props.t("Contact Traveller")}
                                  </button>
                                </div>
                              </div>
                              <div className="flex-shrink-0 d-flex  justify-content-md-left align-items-md-center package-title-wrapper px-3 px-sm-0 py-2 py-sm-0">
                                <div>
                                  <span className="d-lg-none">
                                    {" "}
                                    {moment(details.createdAt).format("ll")}
                                  </span>
                                  <h6 className="mb-0">
                                    {details.customer.name}
                                  </h6>
                                  <small>
                                    {details.listingSnapshot.package.title}
                                  </small>
                                </div>
                              </div>
                              <div className="flex-fill d-flex justify-content-center align-items-center">
                                <button
                                  onClick={() => {
                                    Controller.marketing.setCurrentPackage(
                                      details.listingSnapshot
                                    );

                                    this.toggleTripDetails();
                                  }}
                                  type="button"
                                  className="btn btn-link trip-orders-button-wrapper d-none d-sm-block"
                                >
                                  {this.props.t("Trip Details")}
                                </button>
                                <button
                                  type="button"
                                  className="btn btn-link trip-orders-button-wrapper d-none d-sm-block"
                                  onClick={() =>
                                    this.toggleOrderDetailsModal(details)
                                  }
                                >
                                  {this.props.t("Order Details")}
                                </button>
                                {/* <button
                                  type="button"
                                  className="btn btn-link contact-button d-none d-sm-block"
                                 >
                                  {this.props.t("Contact Traveller")}
                                 </button> */}
                              </div>
                              <div className="flex-shrink-0 d-flex justify-content-center align-items-center py-2 py-sm-0">
                                <span className="px-2 px-xl-5 d-none d-lg-block">
                                  {moment(details.createdAt).format("ll")}
                                </span>
                                <button
                                  className="check-button mx-2"
                                  onClick={() =>
                                    this.updateStatusPopUpToggle(
                                      "confirmed",
                                      details._id
                                    )
                                  }
                                >
                                  <i className="fas fa-check"></i>{" "}
                                  <span className="d-sm-none">
                                    {this.props.t("Accept Order")}
                                  </span>
                                </button>
                                <button
                                  className="close-button mx-2"
                                  onClick={() =>
                                    this.updateStatusPopUpToggle(
                                      "rejected",
                                      details._id
                                    )
                                  }
                                >
                                  <i className="fas fa-times"></i>{" "}
                                  <span className="d-sm-none">
                                    {this.props.t("Reject order")}
                                  </span>
                                </button>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>

              <div className="row activity pl-0 pl-xl-2">
                {placedOrders.length > 0 ? (
                  <div className="col-12 text-center">
                    <h6>{this.props.t("Order History")}</h6>
                  </div>
                ) : null}
                <div className="col-12 text-center pt-2">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <Link
                      to="/dashboard/orders/active"
                      className={`${
                        currentTab === "active"
                          ? "active-button"
                          : "inactive-button"
                      } btn btn-secondary px-4`}
                    >
                      {this.props.t("Active")}

                      {activeOrders.length > 0
                        ? ` (${activeOrders.length})`
                        : ""}
                    </Link>
                    <Link
                      to="/dashboard/orders/confirmed"
                      className={`${
                        currentTab === "confirmed"
                          ? "active-button"
                          : "inactive-button"
                      } btn btn-secondary px-4`}
                    >
                      {this.props.t("Upcoming")}

                      {upcomingOrders.length > 0
                        ? ` (${upcomingOrders.length})`
                        : ""}
                    </Link>
                    <Link
                      to="/dashboard/orders/completed"
                      className={`${
                        currentTab === "completed"
                          ? "active-button"
                          : "inactive-button"
                      } btn btn-secondary px-4 d-none d-md-block`}
                    >
                      {this.props.t("Completed")}

                      {completedOrders.length > 0
                        ? ` (${completedOrders.length})`
                        : ""}
                    </Link>
                    <Link
                      to="/dashboard/orders/cancelled"
                      className={`${
                        currentTab === "cancelled"
                          ? "active-button"
                          : "inactive-button"
                      } btn btn-secondary px-4 d-none d-md-block`}
                    >
                      {this.props.t("Cancelled")}

                      {cancelledOrders.length > 0
                        ? ` (${cancelledOrders.length})`
                        : ""}
                    </Link>
                    <Link
                      to="/dashboard/orders/rejected"
                      className={`${
                        currentTab === "rejected"
                          ? "active-button"
                          : "inactive-button"
                      } btn btn-secondary px-4 d-none d-md-block`}
                    >
                      {this.props.t("Rejected")}

                      {rejectedOrders.length > 0
                        ? ` (${rejectedOrders.length})`
                        : ""}
                    </Link>
                    <Link
                      to="/dashboard/orders/disputed"
                      className={`${
                        currentTab === "disputed"
                          ? "active-button"
                          : "inactive-button"
                      } btn btn-secondary px-4 d-none d-md-block`}
                    >
                      {this.props.t("Disputed")}

                      {disputedOrders.length > 0
                        ? ` (${disputedOrders.length})`
                        : ""}
                    </Link>
                    <button
                      id="btnGroupDrop1"
                      type="button"
                      className={`${
                        currentTab === "completed" ||
                        currentTab === "rejected" ||
                        currentTab === "cancelled"
                          ? "active-button"
                          : "inactive-button"
                      } btn btn-secondary dropdown-toggle d-md-none`}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {this.props.t("More")}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="btnGroupDrop1"
                    >
                      <Link
                        className={`${
                          currentTab === "completed" ? "active" : ""
                        } dropdown-item`}
                        to="/dashboard/orders/completed"
                      >
                        {this.props.t("Completed")}

                        {completedOrders.length > 0
                          ? ` (${completedOrders.length})`
                          : ""}
                      </Link>
                      <Link
                        className={`${
                          currentTab === "cancelled" ? "active" : ""
                        } dropdown-item`}
                        to="/dashboard/orders/cancelled"
                      >
                        {this.props.t("Cancelled")}

                        {cancelledOrders.length > 0
                          ? ` (${cancelledOrders.length})`
                          : ""}
                      </Link>
                      <Link
                        className={`${
                          currentTab === "rejected" ? "active" : ""
                        } dropdown-item`}
                        to="/dashboard/orders/rejected"
                      >
                        {this.props.t("Rejected")}

                        {rejectedOrders.length > 0
                          ? ` (${rejectedOrders.length})`
                          : ""}
                      </Link>
                      <Link
                        className={`${
                          currentTab === "disputed" ? "active" : ""
                        } dropdown-item`}
                        to="/dashboard/orders/disputed"
                      >
                        {this.props.t("Disputed")}

                        {disputedOrders.length > 0
                          ? ` (${disputedOrders.length})`
                          : ""}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-6 pl-4 pl-md-3">
                  <div className="text-left refresh pt-3 ">
                    <a
                      className="export-to-csv"
                      href={`${
                        getConfig().SERVER_BASE_ADDRS
                      }api/orders?csv=true`}
                      target="blank"
                    >
                      <i className="fas fa-cloud-download-alt pr-1"></i>
                      {this.props.t("Export to CSV")}
                    </a>
                  </div>
                </div>
                <div className="col-6">
                  <div className="refresh text-right pt-3">
                    <button onClick={() => this.fetchOrder()}>
                      <i className="fas fa-redo-alt mr-2"></i>
                      {this.props.t("Refresh")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="row d-none d-lg-block">
                <div className="col-12 my-4 px-0">
                  <ReactTable
                    showPaginationTop={true}
                    showPaginationBottom={true}
                    minRows={3}
                    data={dataToShow}
                    filterable
                    style={{ textTransform: "capitalize", fontSize: 12 }}
                    columns={[
                      {
                        Header: "",
                        accessor: "coverImage",
                        Cell: this.renderImage,
                        filterMethod: (filter, rows) => {
                          return matchSorter(rows, filter.value, {
                            keys: [
                              "otherInfo.listingSnapshot.package.title",
                              "otherInfo.listingSnapshot.package.location"
                            ]
                          });
                        },
                        filterAll: true
                      },

                      {
                        Header: this.props.t("CUSTOMER INFO."),
                        accessor: "customerInfo",
                        Cell: this.renderCustomerInfo.bind(this),
                        filterMethod: (filter, rows) => {
                          return matchSorter(rows, filter.value, {
                            keys: [
                              "otherInfo.customer.name",
                              "otherInfo.phoneNumber",
                              "otherInfo.altPhoneNumber",
                              "otherInfo.addressLine1",
                              "otherInfo.country",
                              "otherInfo.state",
                              "otherInfo.city",
                              "otherInfo.postalCode",
                              "otherInfo.billingName"
                            ]
                          });
                        },
                        filterAll: true
                      },

                      {
                        Header: this.props.t("ORDER INFO."),
                        accessor: "otherInfo",
                        Cell: this.renderOrder,
                        filterMethod: (filter, rows) => {
                          return matchSorter(rows, filter.value, {
                            keys: [
                              {
                                threshold:
                                  matchSorter.rankings.WORD_STARTS_WITH,
                                key: "otherInfo.id"
                              }
                            ]
                          });
                        },
                        filterAll: true
                      },

                      {
                        Header: this.props.t("PAYMENT INFO."),
                        accessor: "paymentInfo",
                        Cell: this.renderPayment,
                        sortable: false,
                        filterable: false
                      },

                      {
                        Header: this.props.t("ACTIONS"),
                        accessor: "actions",
                        className: "lh-110",
                        sortable: false,
                        filterable: false,
                        Cell: this.renderAction
                      }
                    ]}
                  />
                </div>
              </div>
              <div className="row d-lg-none">
                <div className="col-12 px-0">
                  <div className="pt-3 mt-2 my-orders-accordion-wrapper">
                    <div className="accordion" id="myOrdersAccordion">
                      {dataToShow.map((order, index) => {
                        return (
                          <div key={index} className="card">
                            <button
                              className={`card-header ${
                                index > 0 ? "collapsed" : ""
                              } d-flex`}
                              type="button"
                              data-toggle="collapse"
                              data-target={`#collapse${index}`}
                              id="myOrdersHeadingOne"
                              aria-expanded="false"
                              aria-controls={`collapse${index}`}
                            >
                              <div className="flex-shrink-0 pt-3">
                                <i className="fas fa-info-circle info"></i>
                              </div>
                              <div className="mb-0 flex-fill truncate accordion-header pl-3">
                                <h6 className="mb-0 my-1 truncate">
                                  {
                                    order.otherInfo.listingSnapshot.package
                                      .title
                                  }
                                </h6>
                                <span>
                                  {moment(order.otherInfo.createdAt).format(
                                    "ll"
                                  )}
                                </span>
                              </div>
                              <div className="flex-shrink-0">
                                <i className="fas fa-chevron-up mt-3"></i>
                              </div>
                            </button>
                            <div
                              id={`collapse${index}`}
                              className={`collapse ${
                                index === 0 ? "show" : null
                              } mb-2`}
                              data-parent="#myOrdersAccordion"
                            >
                              <div className="accordion-content-wrapper py-4">
                                <div>
                                  <span>{this.props.t("AMOUNT")}</span>
                                </div>
                                <label className="pt-2 px-4 text-center">
                                  {GetRawFormattedPrice(
                                    order.otherInfo.netAmount,
                                    order.otherInfo.currencyId
                                  )}
                                </label>
                                <span className="pt-4">
                                  {this.props.t("TRAVELER")}
                                </span>
                                <label className="pt-2 px-4 text-center">
                                  {order.customerName}
                                </label>
                                <span className="pt-4">
                                  {this.props.t("PAYMENT STATUS")}
                                </span>
                                <span
                                  className="badge badge-pill badge-warning px-4 mt-3"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {order.otherInfo.paymentStatus}
                                </span>
                                <span className="pt-4">
                                  {this.props.t("TRIP STATUS")}
                                </span>
                                <span
                                  className="badge badge-pill badge-info px-4 mt-3"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {order.status}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <Modal
                isOpen={this.state.approveCancellationPopUp}
                toggle={this.approveCancellationPopupToggle}
                centered
              >
                {this.state.statusType === "cancelled" ? (
                  <>
                    <ModalHeader toggle={this.approveCancellationPopupToggle}>
                      {this.props.t("APPROVE ORDER CANCELLATION")}
                    </ModalHeader>
                    <ModalBody>
                      <h5>
                        {this.props.t(
                          "You are about to approve this cancellation request. Once done, the refund will be initiated to the customer. This action cannot be undone, are you sure you want to continue?"
                        )}
                      </h5>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="primary"
                        onClick={() =>
                          this.updateStatus(
                            this.state.statusUpdateId,
                            "cancelled",
                            this.state.message,
                            "approveCancellationPopupToggle"
                          )
                        }
                      >
                        {this.props.t("Approve")}
                      </Button>{" "}
                      <Button
                        color="secondary"
                        onClick={this.approveCancellationPopupToggle}
                      >
                        {this.props.t("Cancel")}
                      </Button>
                    </ModalFooter>
                  </>
                ) : null}
                {this.state.statusType === "disputed" ? (
                  <>
                    <ModalHeader toggle={this.approveCancellationPopupToggle}>
                      {this.props.t("RAISE DISPUTE OVER ORDER CANCELLATION")}
                    </ModalHeader>
                    <ModalBody>
                      <h5>
                        {this.props.t(
                          "If you don't want to approve this order cancellation request, then you may raise a dispute here. So our support team will get in touch with you to resolve the same."
                        )}
                      </h5>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="danger"
                        onClick={() =>
                          this.updateStatus(
                            this.state.statusUpdateId,
                            "disputed",
                            "Booking cancellation dispute",
                            "approveCancellationPopupToggle"
                          )
                        }
                      >
                        {this.props.t("Raise Dispute")}
                      </Button>{" "}
                      <Button
                        color="secondary"
                        onClick={this.approveCancellationPopupToggle}
                      >
                        {this.props.t("Cancel")}
                      </Button>
                    </ModalFooter>
                  </>
                ) : null}
              </Modal>
              <Modal
                isOpen={this.state.updateStatusPopUp}
                toggle={this.updateStatusPopUpToggle}
                centered
              >
                {this.state.statusType === "rejected" ? (
                  <>
                    <ModalHeader toggle={this.updateStatusPopUpToggle}>
                      {this.props.t("REJECT ORDER")}
                    </ModalHeader>
                    <ModalBody>
                      <h5>
                        {this.props.t(
                          "Are you sure you want to reject this order ?"
                        )}
                      </h5>
                      <FormGroup>
                        <Label for="exampleText">
                          {this.props.t("Reason for rejection")}
                        </Label>
                        <Input
                          type="textarea"
                          name="text"
                          id="exampleText"
                          value={this.state.message}
                          onChange={e =>
                            this.setState({ message: e.target.value })
                          }
                        />
                      </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="danger"
                        disabled={!this.state.message}
                        onClick={() =>
                          this.updateStatus(
                            this.state.statusUpdateId,
                            "rejected",
                            this.state.message
                          )
                        }
                      >
                        {this.props.t("Reject")}
                      </Button>{" "}
                      <Button
                        color="secondary"
                        onClick={this.updateStatusPopUpToggle}
                      >
                        {this.props.t("Cancel")}
                      </Button>
                    </ModalFooter>
                  </>
                ) : null}
                {this.state.statusType === "confirmed" ? (
                  <>
                    <ModalHeader toggle={this.updateStatusPopUpToggle}>
                      {this.props.t("CONFIRMING ORDER")}
                    </ModalHeader>
                    <ModalBody>
                      <h6>
                        {this.props.t("You are about to confirm this order")}
                      </h6>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="success"
                        onClick={() =>
                          this.updateStatus(
                            this.state.statusUpdateId,
                            "confirmed"
                          )
                        }
                      >
                        {this.props.t("Confirm")}
                      </Button>{" "}
                      <Button
                        color="secondary"
                        onClick={this.updateStatusPopUpToggle}
                      >
                        {this.props.t("Cancel")}
                      </Button>
                    </ModalFooter>
                  </>
                ) : null}
                {this.state.statusType === "completed" ? (
                  <>
                    <ModalHeader toggle={this.updateStatusPopUpToggle}>
                      {this.props.t("CONFIRMATION")}
                    </ModalHeader>
                    <ModalBody>
                      <h6>
                        {this.props.t(
                          "Mark this order completed as per traveller's satisfation"
                        )}
                      </h6>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="success"
                        onClick={() =>
                          this.updateStatus(
                            this.state.statusUpdateId,
                            "completed"
                          )
                        }
                      >
                        {this.props.t("Mark as Completed")}
                      </Button>{" "}
                      <Button
                        color="secondary"
                        onClick={this.updateStatusPopUpToggle}
                      >
                        {this.props.t("Cancel")}
                      </Button>
                    </ModalFooter>
                  </>
                ) : null}
              </Modal>
            </div>
          </Fade>
          <Modal
            style={{ maxWidth: "100%" }}
            isOpen={
              this.props.context !== null && this.state.tripDetails === true
            }
            toggle={() => {
              Controller.marketing.setCurrentPackage(null);
              this.toggleTripDetails();
            }}
            scrollable={true}
            size={"lg"}
            className="ordered-preview"
          >
            <button
              className="closePreview"
              onClick={() => {
                Controller.marketing.setCurrentPackage(null);
                this.toggleTripDetails();
              }}
            >
              <i className="fas fa-times fa-2x"></i>
            </button>
            <ModalBody id="sample-id" className="p-0">
              <TripViewer
                scrollableId="#sample-id"
                spyOffset={-1100}
                context={this.props.context}
                mode="preview"
              />
            </ModalBody>
          </Modal>

          <Modal
            isOpen={this.state.orderDetailsModal}
            toggle={() => this.toggleOrderDetailsModal(null)}
            centered
            className="payment-details-modal"
          >
            {this.state.orderDetails ? (
              <>
                <ModalHeader
                  className="payment-details-modal"
                  toggle={() => this.toggleOrderDetailsModal(null)}
                  style={{
                    backgroundImage: `url('${Controller.resolveAssetAddr(
                      this.state.orderDetails.listingSnapshot.package
                        .defaultCoverArtId
                    )}')`,
                    backgroundSize: "cover",
                    backgroundPosition: "center"
                  }}
                >
                  <div className="order-info">
                    <div className="ribbon">
                      <span className="ribbon3">
                        <div>
                          <div className="truncate pt-2">
                            {
                              this.state.orderDetails.listingSnapshot.package
                                .title
                            }
                          </div>
                          <small className="d-flex truncate">
                            <i className="fas fa-map-marker-alt pt-2 pr-1"></i>
                            {stripAddress(
                              this.state.orderDetails.listingSnapshot.package
                                .location
                            )}
                          </small>
                        </div>
                      </span>
                    </div>
                  </div>
                </ModalHeader>
                <ModalBody className="order-details">
                  <table className="table table-borderless">
                    <tbody>
                      {this.renderOrderDetails(this.state.orderDetails)}
                      <tr>
                        <th scope="row">{this.props.t("Net Amount")}</th>
                        <td>
                          {GetRawFormattedPrice(
                            this.state.orderDetails.effectiveNetAmount,
                            this.state.orderDetails.currencyId
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">{this.props.t("Amount Paid")}</th>
                        <td>
                          {GetRawFormattedPrice(
                            this.state.orderDetails
                              .effectiveInitialAmountPayable,
                            this.state.orderDetails.currencyId
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">{this.props.t("Balance Amount")}</th>
                        <td>
                          {GetRawFormattedPrice(
                            this.state.orderDetails.effectiveRemainingAmount,
                            this.state.orderDetails.currencyId
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </ModalBody>
              </>
            ) : null}
          </Modal>
        </>
      );
    }
  }
);

const mapStateToProps = state => ({
  context: state.marketing.currentPackage,
  local: state.local,
  selectedCurrency: state.local.selectedCurrency
});

export default connect(mapStateToProps)(MyOrders);
