import { useTranslation, withTranslation } from "react-i18next";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet";
import TripViewer from "../../components/TripViewer";
import { PackageServices } from "../../services/Package.services";
import controllers from "../../store/controllers";
import {
  isReadMoreRequired,
  stripAddress,
  sliceText
} from "../../resources/Helper";

function CompareDays(props) {
  const { t } = useTranslation();
  const package1 = props.package1;
  const package2 = props.package2;
  const dayRange = Math.max(
    package1.package.days.length,
    package2.package.days.length
  );

  const [selectedDay, updateSelectedDay] = useState(0);
  return (
    <div className="container-fluid">
      <div className="row text-center">
        <div className="col-12">
          <small>{t("Select a day to view insights")}</small>
        </div>
        <div className="col-12 mx-auto">
          {new Array(dayRange).fill(null).map((_, i) => (
            <button
              key={i}
              className={`custom-inline ${selectedDay === i ? "selected" : ""}`}
              onClick={() => updateSelectedDay(i)}
            >
              {t("Day")}

              {i + 1}
            </button>
          ))}
        </div>
        <div className="col-12 text-center py-3">
          <h5>
            <b>
              {t("Day")}
              {selectedDay + 1}
            </b>
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col-6 py-3" style={{ backgroundColor: "#8080800a" }}>
          {package1.package.days[selectedDay] ? (
            <section className="container px-md-5 full-details">
              <div className="row mt-2 custom-card">
                {package1.package.days[selectedDay].timeline.map(
                  (events, index) => (
                    <React.Fragment key={index}>
                      <div className="col-12 col-lg-6">
                        <div className="card mx-2">
                          <div
                            className="card-header"
                            style={{ backgroundColor: "#8080800a" }}
                          >
                            <small className="text-muted">
                              <b>
                                {t("-")}
                                {events.title}
                              </b>
                            </small>
                          </div>
                          <img
                            src={controllers.resolveAssetAddr(events.imageId)}
                            className="card-img-top"
                            style={{
                              objectFit: "cover",
                              height: 300,
                              width: "100%",
                              cursor: "pointer"
                            }}
                            alt="..."
                            onClick={() =>
                              controllers.marketing.setLightBoxImage(
                                events.imageId
                              )
                            }
                          />

                          <div
                            className="card-body"
                            style={{ backgroundColor: "#8080800a" }}
                          >
                            <div className="location">
                              <span className="place pr-1">
                                <i
                                  className="fas fa-map-marker-alt"
                                  aria-hidden="true"
                                ></i>{" "}
                                {stripAddress(events.location)}
                              </span>
                              <span className="time pl-1">
                                <i
                                  className="far fa-clock"
                                  aria-hidden="true"
                                ></i>{" "}
                                {events.hourFormatted}
                              </span>
                            </div>
                            {events.description ? (
                              <>
                                <p className="card-text mb-1">
                                  {sliceText(events.description, 400)}
                                </p>
                                <div className="pb-1 text-left">
                                  {isReadMoreRequired(
                                    events.description,
                                    400
                                  ) ? (
                                    <button
                                      className="btn btn-link btn-sm"
                                      style={{ color: "#01959F" }}
                                      onClick={() => {
                                        controllers.marketing.setReadMoreContext(
                                          events.description,
                                          events.title,
                                          events.imageId
                                        );
                                      }}
                                    >
                                      <i className="fas fa-plus-circle"></i>{" "}
                                      {t("Read More")}
                                    </button>
                                  ) : null}
                                </div>
                              </>
                            ) : null}
                            {events.approxDuration ? (
                              <>
                                <h6 className="card-title">
                                  {t("APPROX. HOURS")}
                                </h6>
                                <p className="card-text">
                                  {events.approxDuration}
                                </p>
                              </>
                            ) : null}
                            {events.tips ? (
                              <>
                                <h6 className="card-title">{t("Tips")}</h6>
                                <p className="card-text">{events.tips}</p>
                              </>
                            ) : null}
                          </div>
                        </div>
                        <hr />
                      </div>
                    </React.Fragment>
                  )
                )}
              </div>
            </section>
          ) : null}
        </div>
        <div className="col-6 py-3" style={{ backgroundColor: "#80808026" }}>
          {package2.package.days[selectedDay] ? (
            <section className="container px-md-5 full-details">
              <div className="row mt-2 custom-card">
                {package2.package.days[selectedDay].timeline.map(
                  (events, index) => (
                    <React.Fragment key={index}>
                      <div className="col-12 col-lg-6">
                        <div className="card mx-2">
                          <div
                            className="card-header"
                            style={{ backgroundColor: "#80808026" }}
                          >
                            <small className="text-muted">
                              <b>
                                {t("-")}
                                {events.title}
                              </b>
                            </small>
                          </div>
                          <img
                            src={controllers.resolveAssetAddr(events.imageId)}
                            className="card-img-top"
                            style={{
                              objectFit: "cover",
                              height: 300,
                              width: "100%",
                              cursor: "pointer"
                            }}
                            alt="..."
                            onClick={() =>
                              controllers.marketing.setLightBoxImage(
                                events.imageId
                              )
                            }
                          />

                          <div
                            className="card-body"
                            style={{ backgroundColor: "#80808026" }}
                          >
                            <div className="location">
                              <span className="place pr-1">
                                <i
                                  className="fas fa-map-marker-alt"
                                  aria-hidden="true"
                                ></i>{" "}
                                {stripAddress(events.location)}
                              </span>
                              <span className="time pl-1">
                                <i
                                  className="far fa-clock"
                                  aria-hidden="true"
                                ></i>{" "}
                                {events.hourFormatted}
                              </span>
                            </div>
                            {events.description ? (
                              <>
                                <p className="card-text mb-1">
                                  {sliceText(events.description, 400)}
                                </p>
                                <div className="pb-1 text-left">
                                  {isReadMoreRequired(
                                    events.description,
                                    400
                                  ) ? (
                                    <button
                                      className="btn btn-link btn-sm"
                                      style={{ color: "#01959F" }}
                                      onClick={() => {
                                        controllers.marketing.setReadMoreContext(
                                          events.description,
                                          events.title,
                                          events.imageId
                                        );
                                      }}
                                    >
                                      <i className="fas fa-plus-circle"></i>{" "}
                                      {t("Read More")}
                                    </button>
                                  ) : null}
                                </div>
                              </>
                            ) : null}
                            {events.approxDuration ? (
                              <>
                                <h6 className="card-title">
                                  {t("APPROX. HOURS")}
                                </h6>
                                <p className="card-text">
                                  {events.approxDuration}
                                </p>
                              </>
                            ) : null}
                            {events.tips ? (
                              <>
                                <h6 className="card-title">{t("Tips")}</h6>
                                <p className="card-text">{events.tips}</p>
                              </>
                            ) : null}
                          </div>
                        </div>
                        <hr />
                      </div>
                    </React.Fragment>
                  )
                )}
              </div>
            </section>
          ) : (
            <div className="col-12 text-center pt-5 mt-5">
              <div>
                <i className="fas fa-cloud-sun fa-2x text-muted"></i>
              </div>
              <h5 className="text-muted">
                {t("No Activities for this trip on the selected day")}
              </h5>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
const ComparePackages = withTranslation()(
  class ComparePackages extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        listings: []
      };

      this.loadListings = this.loadListings.bind(this);
    }

    loadListings = listingIds => {
      PackageServices.GetComparePackages(listingIds)
        .then(response => {
          this.setState({
            listings: response
          });
        })
        .catch(err => {
          console.error(err);
          controllers.setGlobalError("Request failed, try again", true);
        });
    };

    componentDidMount() {
      const {
        match: {
          params: { package1, package2 }
        }
      } = this.props;
      this.loadListings([package1, package2]);
    }

    render() {
      return (
        <Fade duration={300}>
          <Helmet>
            <title>{this.props.t("Compare Packages - Tripbonder.com")}</title>
          </Helmet>
          <div className="container-fluid compare-package-wrapper">
            <div className="row d-none d-xl-block">
              <div className="col-12 compare-package-header-wrapper">
                <div className="container">
                  <div className="row">
                    <div className="col-12 compare-package-header-wrapper">
                      <div className="d-flex flex-direction-row">
                        <h6 className="mb-0 py-2 pr-3 d-flex align-items-center">
                          {this.props.t("Comparing:")}
                        </h6>
                        {this.state.listings.length >= 2 ? (
                          <>
                            <div className="px-3 trip-comparison py-2">
                              <img
                                height={28}
                                width={45}
                                style={{ borderRadius: 5 }}
                                src={controllers.resolveAssetAddr(
                                  this.state.listings[0][0].package
                                    .defaultCoverArtId
                                )}
                                alt=""
                              />

                              <h6 className="mb-0 px-2 truncate">
                                {this.state.listings[0][0].package.title}
                              </h6>
                            </div>
                            <div className="px-3 trip-comparison selected py-2">
                              <img
                                height={28}
                                width={45}
                                style={{ borderRadius: 5 }}
                                src={controllers.resolveAssetAddr(
                                  this.state.listings[1][0].package
                                    .defaultCoverArtId
                                )}
                                alt=""
                              />

                              <h6 className="mb-0 px-2 truncate">
                                {this.state.listings[1][0].package.title}
                              </h6>
                            </div>
                          </>
                        ) : null}
                      </div>
                      <div>
                        <Link to="/me/wishlist" className="btn btn-info px-3">
                          {this.props.t("Exit Comparison")}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex container-fluid">
                <div className="col-6">
                  <TripViewer
                    context={
                      this.state.listings[0] ? this.state.listings[0][0] : null
                    }
                    isCompareView={true}
                  />
                </div>
                <div className="col-6">
                  <TripViewer
                    context={
                      this.state.listings[1] ? this.state.listings[1][0] : null
                    }
                    isCompareView={true}
                  />
                </div>
              </div>
              {this.state.listings[0] &&
              this.state.listings[0][0].type === "itinerary" ? (
                <CompareDays
                  package1={
                    this.state.listings[0] ? this.state.listings[0][0] : null
                  }
                  package2={
                    this.state.listings[1] ? this.state.listings[1][0] : null
                  }
                />
              ) : null}
            </div>
            <div className="row d-xl-none">
              <div
                className="col-12 text-center d-flex justify-content-center align-items-center"
                style={{ height: "80vh" }}
              >
                <h6>
                  {this.props.t(
                    "Compare package is only available in Desktop view"
                  )}
                </h6>
              </div>
            </div>
          </div>
        </Fade>
      );
    }
  }
);

export default ComparePackages;
