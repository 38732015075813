import { withTranslation } from "react-i18next";
import React, { createRef } from "react";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { ProfileServices } from "../../services/Profile.services";
import controllers from "../../store/controllers";
import { NETWORK_ERR_MESSAGE } from "../../resources/String";
import moment from "moment";
import { DatetimePickerTrigger } from "rc-datetime-picker";

function FieldPro(props) {
  const isDisabled = props.disabled || false;
  let editor = null;
  if (props.mode === true) {
    editor = props.children;
  } else {
    editor = <p className="pl-2 py-1">{props.title}</p>;
  }

  const handleSubmit = e => {
    e.preventDefault();
  };

  return (
    <form onSubmit={handleSubmit}>
      <hr />
      <div className="row">
        <div className=" col-8 text-left d-flex align-items-end">
          <label>{props.label}</label>
        </div>
        {props.editable && props.editable === true ? (
          <div className=" col-4 text-right">
            {props.mode === true && isDisabled === false ? (
              <button
                onClick={props.onSave}
                type="submit"
                className="px-2 btn btn-link"
              >
                <i className="far fa-save fa-lg"></i>
              </button>
            ) : null}
            {isDisabled === false ? (
              <button
                type="button"
                onClick={props.onToggle}
                className="px-2 btn btn-link"
              >
                {props.mode === true ? (
                  <i className="fas fa-times"></i>
                ) : (
                  <i className="far fa-edit"></i>
                )}
              </button>
            ) : null}
          </div>
        ) : null}
        <div className="col-12 col-md-10">{editor}</div>
      </div>
    </form>
  );
}
const Profile = withTranslation()(
  class Profile extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        emailChanging: false
      };

      this.onToggle = this.onToggle.bind(this);

      this.getState = this.getState.bind(this);
      this.updateState = this.updateState.bind(this);

      this.getMode = this.getMode.bind(this);
      this.toggleMode = this.toggleMode.bind(this);

      this.isFieldBusy = this.isFieldBusy.bind(this);
      this.setFieldBusy = this.setFieldBusy.bind(this);
      this.updateProfile = this.updateProfile.bind(this);

      this.profileInputRef = createRef();
      this.putDisplayPicture = this.putDisplayPicture.bind(this);
    }

    onToggle(key) {
      this.setState({
        [key]: !this.state[key]
      });
    }

    getState(key, defaultValue = null) {
      return this.state[key] === undefined || this.state[key] === null
        ? defaultValue
        : this.state[key];
    }

    updateState(key, value) {
      this.setState({
        [key]: value
      });
    }

    getMode(key) {
      return this.state[`${key}Mode`] || false;
    }

    toggleMode(key) {
      this.setState({
        [`${key}Mode`]: !(this.state[`${key}Mode`] || false)
      });
    }

    isFieldBusy(key) {
      return this.state[`${key}Busy`] || false;
    }

    setFieldBusy(key, value) {
      this.setState({
        [`${key}Busy`]: value
      });
    }

    updateProfile(key, body) {
      this.setState(
        {
          [`${key}Busy`]: true
        },

        () => {
          ProfileServices.UpdateProfile(key, body)
            .then(payload => {
              controllers.setCurrentUser(payload.user);
              this.setState({
                [key]: null,
                [`${key}Mode`]: false,
                [`${key}Busy`]: false
              });
            })
            .catch(err => {
              console.error(err);
              this.setState(
                {
                  [key]: null,
                  [`${key}Mode`]: false,
                  [`${key}Busy`]: false
                },

                () => {
                  controllers.setGlobalError(
                    err.message || NETWORK_ERR_MESSAGE,
                    true
                  );
                }
              );
            });
        }
      );
    }
    handleChange = moment => {
      this.setState({
        dob: moment
      });
    };

    putDisplayPicture = () => {
      const files = this.profileInputRef.current.files;
      if (files && files.length > 0) {
        controllers.setGlobalLoader("Uploading...", false);
        setTimeout(() => {
          ProfileServices.UploadDisplayPicture(files[0])
            .then(payload => {
              controllers.setCurrentUser(payload.user);
              controllers.setGlobalSuccess("Profile picture updated", true);
              setTimeout(() => {
                controllers.clearGlobalMessage();
              }, 1200);
            })
            .catch(err => {
              controllers.setGlobalError(
                err.message || NETWORK_ERR_MESSAGE,
                true
              );
            });
          this.profileInputRef.current.value = "";
        }, 1000);
      }
    };

    getDisplayPicture = imageId => {
      if (imageId) {
        return controllers.resolveAssetAddr(imageId);
      } else {
        return require("../../assets/images/default-avatar.png");
      }
    };

    render() {
      const { local } = this.props;
      const t = this.props.t;
      return (
        <Fade duration={300}>
          <Helmet>
            <title>
              {this.props.t(
                "Tripbonder Profile - manage your personal settings"
              )}
            </title>
          </Helmet>
          <div className="container-fluid profile">
            <div className="row pro-bg">
              <div className="col-12 py-5">
                <div className="text-center">
                  <div className="pro-dp mx-auto">
                    <div className="proborder">
                      <div className="inner">
                        <img
                          src={this.getDisplayPicture(
                            local.currentUser.profileImageId
                          )}
                          className="avatar"
                          alt="Cinque Terre"
                        />
                      </div>
                    </div>
                    <label className="edit">
                      <div className="text-bg">
                        <i className="fas fa-camera fa-lg"></i>
                        <div className="pt-2">
                          {this.props.t("Change Picture")}
                        </div>
                        <input
                          type="file"
                          accept="image/*"
                          ref={this.profileInputRef}
                          onChange={this.putDisplayPicture}
                        />
                      </div>
                    </label>
                  </div>
                  <p className="h4 pt-2">{local.currentUser.name}</p>
                  <p className="text-muted">{local.currentUser.emailAddress}</p>
                  <p className="pt-2">
                    {/* Verified{" "}
                     <i
                     className="fas fa-check-circle"
                     style={{ color: "#00c300" }}
                     ></i> */}
                  </p>
                </div>
              </div>
            </div>
            <div className="container pb-3">
              <div className="row">
                <div className="col-12">
                  <FieldPro
                    disabled={this.isFieldBusy("name")}
                    label={t("FULL NAME")}
                    title={local.currentUser.name}
                    editable
                    mode={this.getMode("name")}
                    onToggle={() => this.toggleMode("name")}
                    onSave={() => {
                      if (this.state.name) {
                        this.updateProfile("name", {
                          name: this.getState("name")
                        });
                      } else {
                        controllers.setGlobalError("Name is required!", true);
                      }
                    }}
                  >
                    <input
                      disabled={this.isFieldBusy("name")}
                      autoFocus={true}
                      type="text"
                      className="form-control"
                      value={this.getState("name", local.currentUser.name)}
                      placeholder={this.props.t("Enter your Full Name")}
                      onChange={e => this.updateState("name", e.target.value)}
                    />
                  </FieldPro>
                </div>
                <div className="col-12">
                  <FieldPro
                    disabled={this.isFieldBusy("email")}
                    label={t("EMAIL ADDRESS ")}
                    title={local.currentUser.emailAddress}
                    editable
                    mode={this.getMode("email")}
                    onToggle={() => this.toggleMode("email")}
                    onSave={() => {
                      if (this.state.email) {
                        this.updateProfile("email", {
                          emailAddress: this.getState("email")
                        });
                      } else {
                        controllers.setGlobalError(
                          "Please provide a valid Email address",
                          true
                        );
                      }
                    }}
                  >
                    <input
                      disabled={this.isFieldBusy("email")}
                      autoFocus={true}
                      type="text"
                      className="form-control"
                      value={this.getState("email")}
                      placeholder={this.props.t("Enter new Email Address")}
                      onChange={e => this.updateState("email", e.target.value)}
                    />
                  </FieldPro>
                </div>
                <div className="col-12">
                  <FieldPro
                    disabled={this.isFieldBusy("password")}
                    label={t("PASSWORD ")}
                    title={
                      local.currentUser.passwordLastChangedOn
                        ? `Last updated ${moment(
                            parseInt(local.currentUser.passwordLastChangedOn)
                          ).fromNow()} `
                        : "Unavailable"
                    }
                    editable
                    mode={this.getMode("password")}
                    onToggle={() => {
                      this.setState(
                        {
                          currentPassword: "",
                          newPassword: "",
                          reNewPassword: ""
                        },

                        () => {
                          this.toggleMode("password");
                        }
                      );
                    }}
                    onSave={() => {
                      if (
                        !this.state.currentPassword ||
                        !this.state.newPassword ||
                        !this.state.reNewPassword
                      ) {
                        controllers.setGlobalError(
                          "Please fill all the fields",
                          true
                        );
                      } else if (this.state.newPassword.length < 5) {
                        controllers.setGlobalError(
                          "Password must be at least 5 characters long",
                          true
                        );
                      } else if (
                        this.state.newPassword !== this.state.reNewPassword
                      ) {
                        controllers.setGlobalError(
                          "Entered password does not match",
                          true
                        );
                      } else {
                        this.updateProfile("password", {
                          password: this.getState("currentPassword"),
                          newPassword: this.getState("newPassword")
                        });
                      }
                    }}
                  >
                    <div className="form-group">
                      <input
                        disabled={this.isFieldBusy("currentPassword")}
                        autoFocus={true}
                        type="password"
                        className="form-control"
                        value={this.getState("currentPassword")}
                        placeholder={this.props.t("Enter Current Password")}
                        onChange={e =>
                          this.updateState("currentPassword", e.target.value)
                        }
                      />
                    </div>
                    <div className="form-group">
                      <input
                        disabled={this.isFieldBusy("newPassword")}
                        type="password"
                        className="form-control"
                        value={this.getState("newPassword")}
                        placeholder={this.props.t(
                          "Enter new Password (must have at least 5 characters)"
                        )}
                        onChange={e =>
                          this.updateState("newPassword", e.target.value)
                        }
                      />
                    </div>
                    <div className="form-group">
                      <input
                        disabled={this.isFieldBusy("reNewPassword")}
                        type="password"
                        className="form-control"
                        value={this.getState("reNewPassword")}
                        placeholder={this.props.t("Re-enter new Password")}
                        onChange={e =>
                          this.updateState("reNewPassword", e.target.value)
                        }
                      />
                    </div>
                  </FieldPro>
                </div>
                <div className="col-12">
                  <FieldPro
                    disabled={this.isFieldBusy("dob")}
                    label={t("DATE OF BIRTH")}
                    title={
                      local.currentUser.dateOfBirth
                        ? moment(
                            parseInt(local.currentUser.dateOfBirth)
                          ).format("ll")
                        : "Unavailable"
                    }
                    mode={this.getMode("dob")}
                    onToggle={() => this.toggleMode("dob")}
                    onSave={() => {
                      this.updateProfile("dob", {
                        dateOfBirth: this.getState(
                          "dob",
                          moment(
                            parseInt(local.currentUser.dateOfBirth) || undefined
                          )
                        ).valueOf()
                      });
                    }}
                    editable
                  >
                    <DatetimePickerTrigger
                      showTimePicker={false}
                      closeOnSelectDay={true}
                      moment={this.getState(
                        "dob",
                        moment(
                          parseInt(local.currentUser.dateOfBirth) || undefined
                        )
                      )}
                      onChange={moment => this.updateState("dob", moment)}
                    >
                      <input
                        type="text"
                        autoFocus={true}
                        className="form-control"
                        value={this.getState(
                          "dob",
                          moment(
                            parseInt(local.currentUser.dateOfBirth) || undefined
                          )
                        ).format("ll")}
                        readOnly
                        style={{ backgroundColor: "#fff" }}
                      />
                    </DatetimePickerTrigger>
                  </FieldPro>
                </div>
                <div className="col-12">
                  <FieldPro
                    label={t("GENDER")}
                    title={
                      local.currentUser.gender !== "N/A"
                        ? local.currentUser.gender
                        : "Unspecified"
                    }
                    mode={this.getMode("gender")}
                    onToggle={() => this.toggleMode("gender")}
                    onSave={() => {
                      this.updateProfile("gender", {
                        gender: this.getState(
                          "gender",
                          local.currentUser.gender
                        )
                      });
                    }}
                    editable
                  >
                    <select
                      disabled={this.isFieldBusy("gender")}
                      autoFocus={true}
                      className="form-control"
                      value={this.getState("gender", local.currentUser.gender)}
                      onChange={e => this.setState({ gender: e.target.value })}
                    >
                      <option value="N/A">
                        {this.props.t("Please choose your gender")}
                      </option>
                      <option value="Male">{this.props.t("Male")}</option>
                      <option value="Female">{this.props.t("Female")}</option>
                      <option value="Transgender">
                        {this.props.t("Transgender")}
                      </option>
                      <option value="Others">{this.props.t("Others")}</option>
                    </select>
                  </FieldPro>
                </div>
                <div className="col-12 col-md-6">
                  <FieldPro
                    disabled={this.isFieldBusy("currency")}
                    label={t("PREFFERED CURRENCY")}
                    title={local.currentUser.preferredCurrencyId}
                    editable
                    mode={this.getMode("currency")}
                    onToggle={() => this.toggleMode("currency")}
                    onSave={() => {
                      this.updateProfile("currency", {
                        preferredCurrencyId: this.getState("currency")
                      });
                    }}
                    type="select"
                  >
                    <select
                      disabled={this.isFieldBusy("currency")}
                      autoFocus={true}
                      className="form-control"
                      value={this.getState(
                        "currency",
                        local.currentUser.preferredCurrencyId
                      )}
                      onChange={e =>
                        this.updateState("currency", e.target.value)
                      }
                    >
                      <option value={null}>
                        {this.props.t("Please choose a currency")}
                      </option>
                      {local.supportedCurrencies.map((currency, index) => (
                        <option value={currency.key} key={index}>
                          {currency.key} {currency.title}
                        </option>
                      ))}
                    </select>
                  </FieldPro>
                </div>
                <div
                  className="col-12 col-md-6"
                  style={{ textTransform: "uppercase" }}
                >
                  <FieldPro
                    label={t("PREFFERED LANGUAGE")}
                    title={local.currentUser.preferredLanguage}
                    editable
                  />
                </div>
                <div className="col-12">
                  <FieldPro
                    label={t("ADDRESS")}
                    disabled={this.isFieldBusy("address")}
                    title={local.currentUser.fullAddress}
                    editable
                    mode={this.getMode("address")}
                    onToggle={() => this.toggleMode("address")}
                    onSave={() => {
                      this.updateProfile("address", {
                        addressLine1: this.getState(
                          "addressLine1",
                          local.currentUser.addressLine1
                        ),

                        addressLine2: this.getState(
                          "addressLine2",
                          local.currentUser.addressLine2
                        ),

                        city: this.getState("city", local.currentUser.city),
                        state: this.getState("state", local.currentUser.state),
                        postalCode: this.getState(
                          "postalCode",
                          local.currentUser.postalCode
                        ),

                        country: this.getState(
                          "country",
                          local.currentUser.country
                        )
                      });
                    }}
                  >
                    <div className="profile-field">
                      <div className="form-row pt-2">
                        <div className="form-group col-12">
                          <label>{this.props.t("Address Line 1")}</label>
                          <input
                            disabled={this.isFieldBusy("addressLine1")}
                            type="text"
                            className="form-control"
                            value={this.getState(
                              "addressLine1",
                              local.currentUser.addressLine1
                            )}
                            autoFocus={true}
                            placeholder={this.props.t("(Required)")}
                            onChange={e =>
                              this.updateState("addressLine1", e.target.value)
                            }
                          />
                        </div>
                        <div className="form-group col-12">
                          <label>{this.props.t("Address Line 2")}</label>
                          <input
                            disabled={this.isFieldBusy("addressLine2")}
                            type="text"
                            className="form-control"
                            value={this.getState(
                              "addressLine2",
                              local.currentUser.addressLine2
                            )}
                            placeholder={this.props.t("(Optional)")}
                            onChange={e =>
                              this.updateState("addressLine2", e.target.value)
                            }
                          />
                        </div>
                        <div className="form-group col-6">
                          <label>{this.props.t("Town / City")}</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled={this.isFieldBusy("city")}
                            value={this.getState(
                              "city",
                              local.currentUser.city
                            )}
                            onChange={e =>
                              this.updateState("city", e.target.value)
                            }
                          />
                        </div>
                        <div className="form-group col-6">
                          <label>{this.props.t("State")}</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled={this.isFieldBusy("state")}
                            value={this.getState(
                              "state",
                              local.currentUser.state
                            )}
                            onChange={e =>
                              this.updateState("state", e.target.value)
                            }
                          />
                        </div>
                        <div className="form-group col-6">
                          <label>{this.props.t("Postal Code")}</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={this.props.t("ZIP / PIN")}
                            disabled={this.isFieldBusy("postalCode")}
                            value={this.getState(
                              "postalCode",
                              local.currentUser.postalCode
                            )}
                            onChange={e =>
                              this.updateState("postalCode", e.target.value)
                            }
                          />
                        </div>
                        <div className="form-group col-6">
                          <label>{this.props.t("Country")}</label>
                          <input
                            type="text"
                            className="form-control"
                            disabled={this.isFieldBusy("country")}
                            value={this.getState(
                              "country",
                              local.currentUser.country
                            )}
                            onChange={e =>
                              this.updateState("country", e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </FieldPro>
                </div>
                <div className="col-12">
                  <FieldPro
                    disabled={this.isFieldBusy("phone")}
                    label={t("CONTACT NUMBER")}
                    title={
                      local.currentUser.phoneNumber
                        ? local.currentUser.phoneNumber
                        : "Not Provided"
                    }
                    editable
                    mode={this.getMode("phone")}
                    onToggle={() => this.toggleMode("phone")}
                    onSave={() => {
                      this.updateProfile("phone", {
                        phoneNumber: this.getState(
                          "phone",
                          local.currentUser.phoneNumber
                        )
                      });
                    }}
                  >
                    <input
                      disabled={this.isFieldBusy("phone")}
                      autoFocus={true}
                      type="tel"
                      className="form-control"
                      value={this.getState(
                        "phone",
                        local.currentUser.phoneNumber
                      )}
                      placeholder={this.props.t(
                        "Please Provide your Phone Number"
                      )}
                      onChange={e => this.updateState("phone", e.target.value)}
                    />
                  </FieldPro>
                </div>
                <div className="col-12">
                  <FieldPro
                    disabled={this.isFieldBusy("bank-account")}
                    label={t("BANK DETAILS")}
                    title={
                      local.currentUser.bankAccountNumber
                        ? [
                            local.currentUser.bankName,
                            "Acc No: " + local.currentUser.bankAccountNumber,
                            "Bank Code: " + local.currentUser.bankBranchCode,
                            "Branch: " + local.currentUser.bankBranchLocation
                          ].join(", ")
                        : "Not Provided"
                    }
                    editable
                    mode={this.getMode("bank-account")}
                    onToggle={() => this.toggleMode("bank-account")}
                    onSave={() => {
                      this.updateProfile("bank-account", {
                        bankName: this.getState(
                          "bankName",
                          local.currentUser.bankName
                        ),

                        bankAccountNumber: this.getState(
                          "accountNumber",
                          local.currentUser.bankAccountNumber
                        ),

                        bankBranchCode: this.getState(
                          "bankCode",
                          local.currentUser.bankBranchCode
                        ),

                        bankBranchLocation: this.getState(
                          "bankBranch",
                          local.currentUser.bankBranchLocation
                        )
                      });
                    }}
                  >
                    <div className="profile-field">
                      <div className="form-row pt-2">
                        <div className="form-group col-12">
                          <label>{this.props.t("BANK NAME")}</label>
                          <input
                            disabled={this.isFieldBusy("bank-account")}
                            type="text"
                            autoFocus={true}
                            className="form-control"
                            value={this.getState(
                              "bankName",
                              local.currentUser.bankName
                            )}
                            placeholder={this.props.t("(Required)")}
                            onChange={e =>
                              this.updateState("bankName", e.target.value)
                            }
                          />
                        </div>
                        <div className="form-group col-12">
                          <label>{this.props.t("ACCOUNT NUMBER")}</label>
                          <input
                            disabled={this.isFieldBusy("bank-account")}
                            type="text"
                            className="form-control"
                            value={this.getState(
                              "accountNumber",
                              local.currentUser.bankAccountNumber
                            )}
                            placeholder={this.props.t("(Required)")}
                            onChange={e =>
                              this.updateState("accountNumber", e.target.value)
                            }
                          />
                        </div>
                        <div className="form-group col-12">
                          <label>{this.props.t("BANK CODE")}</label>
                          <input
                            disabled={this.isFieldBusy("bank-account")}
                            type="text"
                            className="form-control"
                            value={this.getState(
                              "bankCode",
                              local.currentUser.bankBranchCode
                            )}
                            placeholder={this.props.t("(Required)")}
                            onChange={e =>
                              this.updateState("bankCode", e.target.value)
                            }
                          />
                        </div>
                        <div className="form-group col-12">
                          <label>{this.props.t("BRANCH")}</label>
                          <input
                            disabled={this.isFieldBusy("bank-account")}
                            type="text"
                            className="form-control"
                            value={this.getState(
                              "bankBranch",
                              local.currentUser.bankBranchLocation
                            )}
                            placeholder={this.props.t("(Required)")}
                            onChange={e =>
                              this.updateState("bankBranch", e.target.value)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </FieldPro>
                </div>
                <div className="col-12">
                  <FieldPro
                    disabled={this.isFieldBusy("paypal-email")}
                    label={t("PAYPAL DETAILS")}
                    title={
                      local.currentUser.paypalEmailAddress
                        ? local.currentUser.paypalEmailAddress
                        : "Not Provided"
                    }
                    editable
                    mode={this.getMode("paypal-email")}
                    onToggle={() => this.toggleMode("paypal-email")}
                    onSave={() => {
                      this.updateProfile("paypal-email", {
                        paypalEmailAddress: this.getState(
                          "paypal-email",
                          local.currentUser.paypalEmailAddress
                        )
                      });
                    }}
                  >
                    <input
                      disabled={this.isFieldBusy("paypal-email")}
                      autoFocus={true}
                      type="text"
                      className="form-control"
                      value={this.getState(
                        "paypal-email",
                        local.currentUser.paypalEmailAddress
                      )}
                      placeholder={this.props.t(
                        "Please Provide your Paypal ID"
                      )}
                      onChange={e =>
                        this.updateState("paypal-email", e.target.value)
                      }
                    />
                  </FieldPro>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      );
    }
  }
);

export default connect(state => ({
  local: state.local
}))(Profile);
