import React from "react";
import { connect } from "react-redux";
import Controller from "./../../store/controllers";
import { PackageServices } from "./../../services/Package.services";
import TripViewer from "../../components/TripViewer";
import ReactGA from "react-ga";

class TripDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.viewCounter = null;
    this.refresh = this.refresh.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
  }

  refresh() {
    Controller.marketing.setCurrentPackage(null);
    const isPrivate =
      window.location.pathname.indexOf("/custom/packages/view/") > -1;
    PackageServices.GetPackage(this.props.match.params.packageId, isPrivate)
      .then(_package => {
        ReactGA.pageview(
          window.location.pathname + window.location.search,
          [],
          `${_package.type} - ${_package.package.title}`
        );

        this.viewCounter = setTimeout(() => {
          PackageServices.ViewCounter(this.props.match.params.packageId);
        }, 5000);
        setTimeout(() => {
          window.scrollTo(0, 0);
          Controller.marketing.setCurrentPackage(_package);
        }, 300);
      })
      .catch(err => {
        console.error(err);
        window.scrollTo(0, 0);
        Controller.setGlobalError("404 Package Not Found", false);
      });
  }

  handleCurrencyChange() {
    this.refresh();
  }

  componentDidMount() {
    Controller.subscribeToCurrencyChange(
      this.handleCurrencyChange,
      "package-details-page"
    );

    Controller.subscribeToLanguageChange(
      this.handleCurrencyChange,
      "package-details-page"
    );

    this.refresh();
  }

  componentWillUnmount() {
    clearTimeout(this.viewCounter);
    Controller.unsubscribeFromCurrencyChange(
      this.handleCurrencyChange,
      "package-details-page"
    );
  }

  render() {
    let currentUserId =
      (this.props.local.currentUser && this.props.local.currentUser._id) ||
      null;
    let tripbonderUserId = this.props.context && this.props.context.user._id;
    return (
      <TripViewer
        context={this.props.context}
        canInteract={currentUserId !== tripbonderUserId}
        headerSize={73}
      />
    );
  }
}

export default connect(state => ({
  context: state.marketing.currentPackage,
  selectedRoomIndex: state.marketing.selectedRoomIndex,
  local: state.local
}))(TripDetailsPage);
