import { useTranslation, withTranslation } from "react-i18next";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import ImagePlaceholder from "../../components/Draft/ImagePlaceholder";
import moment from "moment";
import controller from "../../store/controllers";
import { ListingServices } from "../../services/Listing.services";
// import GridImagePlaceholder from "../../components/Draft/GridImagePlaceholder";
// import MapContainer from "../../components/Draft/Gmaps";
import { DraftControl } from "../../components/Draft/DraftControl";
import LocationSearchInput from "../../components/Draft/LocationSearchInput";
import GalleryController from "./../../components/MediaGallery/gallery.controller";
import { Helmet } from "react-helmet";
import { HotKeys } from "react-hotkeys";
import { StatusAlert } from "../../components/Draft/StatusAlert";
import PublishModal from "../../components/Modals/PublishModal";
import TripViewer from "./../../components/TripViewer";
import PrivatePackageAlert from "../../components/Draft/PrivatePackageAlert";
import HideTawkTo from "./../../components/HideTawkTo";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import ChipInput from "material-ui-chip-input";

function Highlight(props) {
  const { t } = useTranslation();
  const { ticket } = props;
  const [hintContext, setHintContext] = useState({});
  function setHighlightImage() {
    GalleryController.insertMedia("", "", {}, mediaObj => {
      controller.ticket.updateHighlight(props.index, "imageId", mediaObj._id);
    });
  }
  const _getHintStateId = key => {
    return `_${key}_HINT`;
  };

  const getIsHintOpen = key => {
    if (hintContext[_getHintStateId(key)]) {
      return hintContext[_getHintStateId(key)];
    } else {
      return false;
    }
  };

  const toggleIsHintOpen = key => {
    setHintContext(
      Object.assign({}, hintContext, {
        [_getHintStateId(key)]: !getIsHintOpen(key)
      })
    );
  };
  return (
    <React.Fragment>
      <div className="highlights row mt-3">
        <div className="col-12">
          <div className="card-title">
            {t("#")}
            {props.index + 1} {t("Highlight")}
            {ticket._status === "draft" ? (
              <button
                className="close"
                type="button"
                data-toggle="modal"
                data-target="#deletePrompt"
                onClick={e => props.onDelete && props.onDelete(props.index)}
              >
                <i className="fas fa-times"></i>
              </button>
            ) : null}
          </div>
          <div className="row highlight-content-wrapper mx-0">
            <div className="col-12 col-lg-2 px-0">
              <ImagePlaceholder
                disabled={ticket._status !== "draft"}
                message={t(
                  "Click to add image. Image cannot be larger than 2MB"
                )}
                height="150px"
                border="none"
                value={controller.resolveAssetAddr(props.item.imageId)}
                onClick={() => setHighlightImage()}
                onRemove={() =>
                  controller.ticket.updateHighlight(
                    props.index,
                    "imageId",
                    null
                  )
                }
              />
            </div>
            <div className="col-12 pt-4 col-lg-4 location-wrapper">
              <div className="form-group px-3 px-lg-0">
                <label htmlFor="location" className="header">
                  {t("LOCATION")}
                </label>
                <LocationSearchInput
                  geoLocation={props.item.geoLocation}
                  readOnly={ticket._status !== "draft"}
                  id={`location-${props.index}`}
                  value={props.item.location}
                  onChange={location =>
                    controller.ticket.updateHighlightLocation(
                      props.index,
                      location
                    )
                  }
                />
              </div>
            </div>
            <div className="col-12 pt-0 pt-md-4 col-lg-4">
              <div className="form-group px-3 px-lg-0">
                <label className="header">
                  {t("WHY IS IT A MUST DO OR SEE?")}
                  <button
                    id="event-highlight-description"
                    className="hint-btn"
                    type="button"
                  >
                    {"?"}
                  </button>
                  <Popover
                    trigger="legacy"
                    placement="right"
                    isOpen={getIsHintOpen("event-highlight-description")}
                    target="event-highlight-description"
                    toggle={() =>
                      toggleIsHintOpen("event-highlight-description")
                    }
                  >
                    <PopoverHeader>{t("Points to consider")}</PopoverHeader>
                    <PopoverBody>
                      <ol>
                        <li>{t("Enter highlights of this event")}</li>
                        <li>{t("Try to keep the title short and precise")}</li>
                      </ol>
                    </PopoverBody>
                    <PopoverHeader>{t("Examples")}</PopoverHeader>
                    <PopoverBody>
                      <ol>
                        <li>
                          {t(
                            "Perched on a hill top that gently slopes into the Arabian Sea is The Leela Raviz Kovalam. Ensconced in a grove of coconut palms that sway lightly to the rhythm of the sea breeze rustling through them, the resort is known for its well-appointed accommodations, traditional and world cuisine and impeccable services. It is our goal to provide all guests with memorable magical stays that deliver the essence of India."
                          )}
                        </li>
                      </ol>
                    </PopoverBody>
                  </Popover>
                </label>
                <textarea
                  readOnly={ticket._status !== "draft"}
                  value={props.item.description}
                  onChange={e =>
                    controller.ticket.updateHighlight(
                      props.index,
                      "description",
                      e.target.value
                    )
                  }
                  className="form-control"
                  id="tripDesc"
                  rows="1"
                  placeholder={t("Description")}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
const TicketSetup = withTranslation()(
  class TicketSetup extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        autoSaving: false,
        saving: false,
        keyToDelete: "",
        indexToDelete: -1,
        isPublishDialogOpen: false,
        isPreviewOpen: false
      };

      this.setDeleteContext = this.setDeleteContext.bind(this);
      this.proceedDelete = this.proceedDelete.bind(this);

      this.loadTicket = this.loadTicket.bind(this);
      this.saveTicket = this.saveTicket.bind(this);
      this.onPublish = this.onPublish.bind(this);
      this.onTogglePublish = this.onTogglePublish.bind(this);
      this.runAutoSaver = this.runAutoSaver.bind(this);
      this.stopAutoSaver = this.stopAutoSaver.bind(this);
      this.handleSaveHotKey = this.handleSaveHotKey.bind(this);
    }
    // Hint Functions BEGIN

    _getHintStateId(key) {
      return `_${key}_HINT`;
    }

    getIsHintOpen(key) {
      if (this.state[this._getHintStateId(key)]) {
        return this.state[this._getHintStateId(key)];
      } else {
        return false;
      }
    }

    toggleIsHintOpen(key) {
      this.setState({
        [this._getHintStateId(key)]: !this.getIsHintOpen(key)
      });
    }

    // Hint Functions END
    setDeleteContext(keyToDelete, indexToDelete) {
      this.setState({ keyToDelete, indexToDelete });
    }

    proceedDelete() {
      const { keyToDelete, indexToDelete } = this.state;
      switch (keyToDelete) {
        case "highlight": {
          controller.ticket.deleteHighlight(indexToDelete);
          this.setDeleteContext("", -1);
          break;
        }
        default: {
          break;
        }
      }
    }
    addCoverImage(index) {
      GalleryController.insertMedia(
        "Upload Ticket Cover",
        "Any attractive picture must get you the thing you wanted",
        {},
        mediaObj => {
          controller.ticket.replaceTicketBanner(index, mediaObj._id);
        }
      );
    }

    loadTicket(props) {
      const {
        match: {
          params: { id }
        }
      } = props || this.props;
      controller.setGlobalLoader("Preparing Editor...", false);
      ListingServices.GetListingById("ticket", id)
        .then(ticket => {
          controller.resetEditorSaveState();
          setTimeout(() => {
            controller.setEditorListing(ticket);
            controller.marketing.setCurrentPackage(ticket);
            controller.ticket.setTicketEditorContext(
              ticket.package,
              ticket.status
            );

            controller.clearGlobalMessage();
            this.titleInputRef && this.titleInputRef.focus();
            this.runAutoSaver();
          }, 1000);
        })
        .catch(err => {
          controller.setGlobalError("Package not found", false);
        });
    }
    runAutoSaver() {
      const self = this;
      const lastSavePrefix = "last saved ";
      self.setState(
        {
          autoSaving: false,
          lastSavedOn: null
        },

        () => {
          self.autoSaver =
            (self.autoSaver && clearInterval(self.autoSaver)) || null;
          let isModified = false;
          self.autoSaver = setInterval(() => {
            if (self.state.saving === false) {
              isModified =
                self.props.local.editorLastModifiedOn >
                self.props.local.editorLastSavedOn;
              if (isModified === true) {
                let timeDifference =
                  new Date().getTime() - self.props.local.editorLastModifiedOn;
                if (timeDifference > 2500) {
                  self.setState(
                    {
                      saving: true,
                      autoSaving: true
                    },

                    () =>
                      self
                        .saveTicket(true)
                        .then(() =>
                          self.setState({
                            saving: false,
                            autoSaving: false,
                            lastSavedOn: `${lastSavePrefix}${moment(
                              self.props.local.editorLastSavedOn
                            ).fromNow()}`
                          })
                        )
                        .catch(() =>
                          self.setState({ saving: false, autoSaving: false })
                        )
                  );
                }
              }
            }

            if (self.props.local.editorLastSavedOn > 0) {
              self.setState({
                lastSavedOn: `${lastSavePrefix}${moment(
                  self.props.local.editorLastSavedOn
                ).fromNow()}`
              });
            }
          }, 1800);
        }
      );
    }

    stopAutoSaver() {
      this.autoSaver && clearInterval(this.autoSaver);
    }

    onPublish() {
      this.setState({
        isPublishDialogOpen: true
      });
    }

    onTogglePublish() {
      this.setState({
        isPublishDialogOpen: false
      });
    }

    handleSaveHotKey(e) {
      e.preventDefault();
      this.saveTicket(false);
    }

    handlers = {
      SAVE: this.handleSaveHotKey.bind(this)
    };

    keyMap = {
      SAVE: "command+s"
    };

    saveTicket(silent = false) {
      return new Promise((resolve, reject) => {
        const {
          ticket,
          match: {
            params: { id }
          }
        } = this.props;
        this.setState(
          {
            saving: true
          },

          () => {
            !silent && controller.setGlobalLoader("Saving...", false);
            ListingServices.Save("ticket", id, ticket)
              .then(response => {
                this.setState(
                  {
                    saving: silent
                  },

                  () => {
                    controller.marketing.setCurrentPackage(
                      Object.assign(this.props.local.editorListingSnapshot, {
                        package: response
                      })
                    );

                    controller.setEditorSaved();
                    !silent &&
                      setTimeout(() => {
                        !silent && controller.setGlobalSuccess("Saved", true);
                        setTimeout(() => {
                          !silent && controller.clearGlobalMessage();
                          resolve(true);
                        }, 1700);
                      }, 700);
                    silent && setTimeout(() => resolve(true), 300);
                  }
                );
              })
              .catch(err => {
                err = (err.data && err.data.message) || err.message;
                !silent && controller.setGlobalError(err, true);
                reject(err);
              });
          }
        );
      });
    }

    componentDidMount() {
      this.loadTicket();
    }

    componentWillReceiveProps(newProps) {
      const newListingId = newProps.match.params.id;
      const oldListingId = this.props.match.params.id;
      if (newListingId !== oldListingId) {
        // Detected Change
        this.loadTicket(newProps);
      }
    }

    componentWillUnmount() {
      this.stopAutoSaver();
      controller.clearGlobalMessage();
    }

    render() {
      const { ticket, local, match } = this.props;
      const { autoSaving, lastSavedOn, isPublishDialogOpen } = this.state;

      // Calculate save status
      let saveStatus = "";

      if (autoSaving === true) {
        saveStatus = "Auto saving...";
      } else {
        if (lastSavedOn) {
          saveStatus = lastSavedOn;
        }
      }

      return (
        <React.Fragment>
          <HideTawkTo />
          <HotKeys keyMap={this.keyMap} handlers={this.handlers}>
            <Helmet>
              <title>{this.props.t("Create Ticket - Tripbonder.com")}</title>
            </Helmet>
            <div className="container-fluid trip-setup">
              <div className="row position-relative">
                <div className="col-12 pr-md-5">
                  <p
                    className="lead"
                    style={{ color: "#42939D", fontSize: 15, fontWeight: 500 }}
                  >
                    {this.props.t("TICKET / EVENT SETUP")}
                  </p>
                  <h3>
                    <b>
                      {this.props.t(
                        "Create your tickets / entrance fee services"
                      )}
                    </b>
                  </h3>
                  <p>
                    {this.props.t(
                      "This will appear as your main product and will appear in search results and in trip detail top banner"
                    )}
                  </p>
                </div>
                <div
                  className="balloon d-none d-md-block"
                  style={{
                    backgroundImage: `url('${require("../../assets/images/balloon.png")}')`
                  }}
                ></div>
              </div>
              <StatusAlert
                statusKey={ticket._status}
                listingStartDate={
                  local.editorListingSnapshot &&
                  local.editorListingSnapshot.listingStartDate
                }
                listingEndDate={
                  local.editorListingSnapshot &&
                  local.editorListingSnapshot.listingEndDate
                }
              />

              <div>
                {local.editorListingSnapshot &&
                local.editorListingSnapshot.class === "private" ? (
                  <PrivatePackageAlert id={local.editorListingSnapshot._id} />
                ) : null}
              </div>
              <div
                className="accordion indicator-plus-before round-indicator"
                id="accordionH"
                aria-multiselectable="true"
              >
                <div className="card m-b-0">
                  <div
                    className="card-header text-center mb-2"
                    role="tab"
                    id="headingOneH"
                    data-target="#collapseOneH"
                    data-toggle="collapse"
                    data-parent="#accordionH"
                    aria-expanded="false"
                    aria-controls="collapseOneH"
                  >
                    <span className="card-title">
                      {this.props.t("Basic Information")}
                    </span>
                  </div>
                  <div
                    className="collapse show"
                    id="collapseOneH"
                    role="tabpanel"
                    aria-labelledby="headingOneH"
                  >
                    <div className="card-body p-0 p-sm-2">
                      <div className="form-row">
                        <div className="form-group col-12 col-lg-6">
                          <label className="header pt-2">
                            {this.props.t("TITLE OF THE TICKETS/ENTRANCE FEES")}
                            <button
                              id="ticket-title-popover"
                              className="hint-btn"
                              type="button"
                            >
                              {"?"}
                            </button>
                            <Popover
                              trigger="legacy"
                              placement="right"
                              isOpen={this.getIsHintOpen("ticket-title")}
                              target="ticket-title-popover"
                              toggle={() =>
                                this.toggleIsHintOpen("ticket-title")
                              }
                            >
                              <PopoverHeader>
                                {this.props.t("Points to consider")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>
                                    {this.props.t(
                                      "Catchy and attractive title."
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t(
                                      "Must include the name of event"
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t(
                                      "Try to keep the title short and precise"
                                    )}
                                  </li>
                                </ol>
                              </PopoverBody>
                              <PopoverHeader>
                                {this.props.t("Examples")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>
                                    {this.props.t(
                                      "Kolukkumalai Jeep Safari Tickets"
                                    )}
                                  </li>
                                  <li>{this.props.t("Sun Burn Dj tickets")}</li>
                                </ol>
                              </PopoverBody>
                            </Popover>
                          </label>
                          <input
                            readOnly={ticket._status !== "draft"}
                            value={ticket.title}
                            onChange={e =>
                              controller.ticket.updateTicket(
                                "title",
                                e.target.value
                              )
                            }
                            type="text"
                            className="form-control"
                            id="title"
                            placeholder={this.props.t("Enter Trip Title")}
                          />

                          <label className="pt-2 header">
                            {this.props.t("LOCATION (REGION/COUNTRY/CITY)")}
                          </label>
                          <LocationSearchInput
                            readOnly={ticket._status !== "draft"}
                            id="trip-location"
                            value={ticket.location}
                            geoLocation={ticket.geoLocation}
                            onChange={location =>
                              controller.ticket.updateTicketLocation(location)
                            }
                          />

                          <label className="pt-2 header">
                            {this.props.t("DESCRIPTION")}
                            <button
                              id="ticket-description-popover"
                              className="hint-btn"
                              type="button"
                            >
                              {"?"}
                            </button>
                            <Popover
                              trigger="legacy"
                              placement="right"
                              isOpen={this.getIsHintOpen("ticket-description")}
                              target="ticket-description-popover"
                              toggle={() =>
                                this.toggleIsHintOpen("ticket-description")
                              }
                            >
                              <PopoverHeader>
                                {this.props.t("Points to consider")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>
                                    {this.props.t(
                                      "Should provide a brief description about the event(maximum 6 to 8 sentences)"
                                    )}
                                  </li>
                                </ol>
                              </PopoverBody>
                              <PopoverHeader>
                                {this.props.t("Example")}
                              </PopoverHeader>
                              <PopoverBody>
                                <p>
                                  {this.props.t(
                                    "With over 400 sea species and 30,000 fish on display, SEA LIFE Bangkok Ocean World Aquarium (formerly known as Siam Ocean World) in Bangkok is one of the few in the world where families can spend a few hours without feeling bored. Upon arriving at SEA Life Bangkok Ocean World, have no worries when redeeming your ticket"
                                  )}
                                </p>
                              </PopoverBody>
                            </Popover>
                          </label>
                          <textarea
                            readOnly={ticket._status !== "draft"}
                            value={ticket.description}
                            onChange={e =>
                              controller.ticket.updateTicket(
                                "description",
                                e.target.value
                              )
                            }
                            className="form-control"
                            id="tripDesc"
                            rows="5"
                            placeholder={this.props.t(
                              "A brief about this package"
                            )}
                          ></textarea>

                          <label className="pt-2 header">
                            {this.props.t("OTHER INFORMATION")}
                            <button
                              id="ticket-other-information-popover"
                              className="hint-btn"
                              type="button"
                            >
                              {"?"}
                            </button>
                            <Popover
                              trigger="legacy"
                              placement="right"
                              isOpen={this.getIsHintOpen(
                                "ticket-other-information"
                              )}
                              target="ticket-other-information-popover"
                              toggle={() =>
                                this.toggleIsHintOpen(
                                  "ticket-other-information"
                                )
                              }
                            >
                              <PopoverHeader>
                                {this.props.t("Points to consider")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>
                                    {this.props.t(
                                      "You can include things like additional services and tips for the passengers"
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t("Try to write in points")}
                                  </li>
                                </ol>
                              </PopoverBody>
                              <PopoverHeader>
                                {this.props.t("Examples")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>
                                    {this.props.t(
                                      "Children aged 0-3 can enter the park for free"
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t(
                                      "In case the activity gets canceled due to inclement weather, you will be given the option to reschedule the tour or opt for a full refund"
                                    )}
                                  </li>
                                </ol>
                              </PopoverBody>
                            </Popover>
                          </label>
                          <textarea
                            readOnly={ticket._status !== "draft"}
                            value={ticket.otherInformation}
                            onChange={e =>
                              controller.ticket.updateTicket(
                                "otherInformation",
                                e.target.value
                              )
                            }
                            className="form-control"
                            id="otherInfo"
                            rows="5"
                            placeholder={this.props.t(
                              "Other booking related information"
                            )}
                          ></textarea>

                          <div className="form-row mt-3">
                            <div className="col-12 form-group">
                              <label className="header d-block mb-3">
                                {this.props.t("TAGS")}
                                <button
                                  id="ticket-tags"
                                  className="hint-btn"
                                  type="button"
                                >
                                  {"?"}
                                </button>
                                <Popover
                                  trigger="legacy"
                                  placement="right"
                                  isOpen={this.getIsHintOpen("ticket-tags")}
                                  target="ticket-tags"
                                  toggle={() =>
                                    this.toggleIsHintOpen("ticket-tags")
                                  }
                                >
                                  <PopoverHeader>
                                    {this.props.t("Points to consider")}
                                  </PopoverHeader>
                                  <PopoverBody>
                                    <ol>
                                      <li>
                                        {this.props.t(
                                          "Tag should not be too long"
                                        )}
                                      </li>
                                      <li>
                                        {this.props.t("Do not begin with #")}
                                      </li>
                                    </ol>
                                  </PopoverBody>
                                  <PopoverHeader>
                                    {this.props.t("Examples")}
                                  </PopoverHeader>
                                  <PopoverBody>
                                    <ol>
                                      <li>{this.props.t("nature")}</li>
                                      <li>{this.props.t("trekking")}</li>
                                      <li>{this.props.t("cruise")}</li>
                                    </ol>
                                  </PopoverBody>
                                </Popover>
                              </label>
                              <ChipInput
                                alwaysShowPlaceholder={true}
                                disabled={ticket._status !== "draft"}
                                value={ticket.tags}
                                onAdd={chip =>
                                  controller.ticket.updateTicket("tags", [
                                    ...ticket.tags,
                                    chip.toLowerCase().replace("#", "")
                                  ])
                                }
                                onDelete={(chip, index) =>
                                  controller.ticket.updateTicket(
                                    "tags",
                                    ticket.tags.filter(
                                      (it, _index) => _index !== index
                                    )
                                  )
                                }
                                placeholder={this.props.t("Add tags...")}
                                newChipKeys={["Tab", "Enter"]}
                                newChipKeyCodes={[32]}
                              />
                            </div>
                          </div>

                          <label className="pt-2 header">
                            {this.props.t("Price")}
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span
                                className="input-group-text mb-0 py-0"
                                id="basic-addon1"
                              >
                                {this.props.t("USD")}
                              </span>
                            </div>
                            <input
                              readOnly={ticket._status !== "draft"}
                              value={ticket.price}
                              onChange={e =>
                                controller.ticket.updateTicket(
                                  "price",
                                  e.target.value
                                )
                              }
                              type="number"
                              step="0.01"
                              className="form-control"
                            />
                          </div>
                        </div>
                        {/* <div
                         className="form-group col-12 col-lg-6"
                         style={{ height: 330 }}
                         >
                         <MapContainer />
                         </div> */}
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-header collapsed text-center mb-2"
                    role="tab"
                    id="headingTwoH"
                    data-target="#collapseTwoH"
                    data-toggle="collapse"
                    data-parent="#accordionH"
                    aria-expanded="false"
                    aria-controls="collapseTwoH"
                  >
                    <span className="card-title">
                      {this.props.t("Cover Art & Highlights")}
                    </span>
                  </div>
                  <div
                    className="collapse"
                    id="collapseTwoH"
                    role="tabpanel"
                    aria-labelledby="headingTwoH"
                  >
                    <div className="card-body p-0">
                      <div className="row cover-art-wrapper p-sm-2 p-0">
                        <div className="col-12 col-xl-4 title-wrapper">
                          <span className="card-title head mb-3">
                            {this.props.t("Cover Art")}
                            <button
                              id="ticket-cover-art-popover"
                              className="hint-btn"
                              type="button"
                            >
                              {"?"}
                            </button>
                            <Popover
                              trigger="legacy"
                              placement="right"
                              isOpen={this.getIsHintOpen("ticket-cover-art")}
                              target="ticket-cover-art-popover"
                              toggle={() =>
                                this.toggleIsHintOpen("ticket-cover-art")
                              }
                            >
                              <PopoverHeader>
                                {this.props.t("Points to consider")}
                              </PopoverHeader>
                              <PopoverBody>
                                <ol>
                                  <li>
                                    {this.props.t(
                                      "Should Upload images less than 2mb"
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t(
                                      "Should not upload blur images"
                                    )}
                                  </li>
                                  <li>
                                    {this.props.t(
                                      "Cover art should give an idea about the package destinations"
                                    )}
                                  </li>
                                </ol>
                              </PopoverBody>
                              <PopoverHeader>
                                {this.props.t("Example")}
                              </PopoverHeader>
                              <PopoverBody>
                                <div className="text-center">
                                  <img
                                    height={150}
                                    src={require("../../assets/images/zoo-ticket.jpg")}
                                    alt="history"
                                  />
                                </div>
                              </PopoverBody>
                            </Popover>
                          </span>
                          <p>
                            {this.props.t(
                              "A beautiful and high resolution cover art provides the initial temptation for a traveller. So why not make it tempting enough?"
                            )}
                          </p>
                        </div>
                        <div className="col-12 col-xl-4">
                          <ImagePlaceholder
                            disabled={ticket._status !== "draft"}
                            message={this.props.t(
                              "Cover image cannot be larger than 2MB"
                            )}
                            height="260px"
                            value={controller.resolveAssetAddr(
                              ticket.coverImages[0]
                            )}
                            onClick={() => this.addCoverImage(0)}
                            onRemove={() =>
                              controller.ticket.replaceTicketBanner(0, "")
                            }
                          />
                        </div>
                        <div className="col-12 col-xl-4 pt-3 pt-xl-0">
                          <div className="row">
                            <div className="col-6">
                              <div className="pb-4">
                                <ImagePlaceholder
                                  disabled={ticket._status !== "draft"}
                                  message={this.props.t(
                                    "Cover image cannot be larger than 2MB"
                                  )}
                                  height="120px"
                                  value={controller.resolveAssetAddr(
                                    ticket.coverImages[1]
                                  )}
                                  onClick={() => this.addCoverImage(1)}
                                  onRemove={() =>
                                    controller.ticket.replaceTicketBanner(1, "")
                                  }
                                />
                              </div>
                              <div>
                                <ImagePlaceholder
                                  disabled={ticket._status !== "draft"}
                                  message={this.props.t(
                                    "Cover image cannot be larger than 2MB"
                                  )}
                                  height="120px"
                                  value={controller.resolveAssetAddr(
                                    ticket.coverImages[2]
                                  )}
                                  onClick={() => this.addCoverImage(2)}
                                  onRemove={() =>
                                    controller.ticket.replaceTicketBanner(2, "")
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="pb-4">
                                <ImagePlaceholder
                                  disabled={ticket._status !== "draft"}
                                  message={this.props.t(
                                    "Cover image cannot be larger than 2MB"
                                  )}
                                  height="120px"
                                  value={controller.resolveAssetAddr(
                                    ticket.coverImages[3]
                                  )}
                                  onClick={() => this.addCoverImage(3)}
                                  onRemove={() =>
                                    controller.ticket.replaceTicketBanner(3, "")
                                  }
                                />
                              </div>
                              <div>
                                <ImagePlaceholder
                                  disabled={ticket._status !== "draft"}
                                  message={this.props.t(
                                    "Cover image cannot be larger than 2MB"
                                  )}
                                  height="120px"
                                  value={controller.resolveAssetAddr(
                                    ticket.coverImages[4]
                                  )}
                                  onClick={() => this.addCoverImage(4)}
                                  onRemove={() =>
                                    controller.ticket.replaceTicketBanner(4, "")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row pt-2 p-sm-2 p-0">
                        <div className="col-12 title-wrapper">
                          <span className="mb-3">
                            {this.props.t("Highlights")}
                          </span>
                          <p>
                            {this.props.t(
                              "Add some highlights to showcase what your trip offers"
                            )}
                          </p>
                        </div>
                        <div className="col-12">
                          {ticket.highlights.map((highlight, index) => (
                            <Highlight
                              ticket={ticket}
                              key={index}
                              index={index}
                              item={highlight}
                              onDelete={index =>
                                this.setDeleteContext("highlight", index)
                              }
                            />
                          ))}
                        </div>
                      </div>
                      {ticket._status === "draft" ? (
                        <div className="row my-2">
                          <div className="col-12 text-center create-package-add-button pb-2">
                            <button
                              onClick={() => controller.ticket.addHighlight()}
                              className="circle btn btn-link btn-sm"
                            >
                              <i className="fas fa-plus"></i>
                            </button>
                            <small>
                              {this.props.t("Add another highlight")}
                            </small>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal fade"
              id="deletePrompt"
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="title">
                      {this.props.t("Remove")}{" "}
                      <span className="text-capitalize">
                        {this.state.keyToDelete}
                      </span>
                    </h5>
                    <button
                      onClick={e => this.setDeleteContext("", -1)}
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">{this.props.t("\xD7")}</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {this.props.t("Are you sure want to delete this?")}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={e => this.setDeleteContext("", -1)}
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      {this.props.t("Cancel")}
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      onClick={this.proceedDelete}
                      className="btn btn-danger"
                    >
                      {this.props.t("Confirm")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <DraftControl
              isModified={local.editorLastSavedOn < local.editorLastModifiedOn}
              statusMessage={saveStatus}
              onSaveDraft={() => this.saveTicket()}
              onPublish={() => this.onPublish()}
              onPreview={() => this.setState({ isPreviewOpen: true })}
              statusKey={ticket._status}
              private={
                local.editorListingSnapshot &&
                local.editorListingSnapshot.class === "private"
              }
            />
          </HotKeys>
          <Modal
            isOpen={isPublishDialogOpen}
            toggle={this.onTogglePublish}
            size="xl"
            centered
            className="profile-prompt"
            keyboard={false}
            backdrop="static"
          >
            <PublishModal
              toggle={this.onTogglePublish}
              listingId={match.params.id}
              packageType="ticket"
              package={ticket}
            />
          </Modal>
          <Modal
            style={{ maxWidth: "100%" }}
            isOpen={this.state.isPreviewOpen}
            toggle={() =>
              this.setState({ isPreviewOpen: !this.state.isPreviewOpen })
            }
            scrollable={true}
            size={"lg"}
            className="ordered-preview"
          >
            <button
              className="closePreview"
              onClick={() => this.setState({ isPreviewOpen: false })}
            >
              <i className="fas fa-times fa-2x"></i>
            </button>
            <ModalBody id="sample-id" className="p-0">
              <TripViewer
                scrollableId="#sample-id"
                spyOffset={-1100}
                context={this.props.context}
                mode="preview"
              />
            </ModalBody>
          </Modal>
        </React.Fragment>
      );
    }
  }
);

const mapStateToProps = state => ({
  context: state.marketing.currentPackage,
  ticket: state.ticket,
  local: state.local
});

export default connect(mapStateToProps)(TicketSetup);
