import { withTranslation } from "react-i18next";
import React from "react";
import { connect } from "react-redux";
import { Zoom, Fade } from "react-reveal";
import Cropper from "react-cropper";
import fileSize from "filesize";
import "cropperjs/dist/cropper.css";
import jQuery from "jquery";
import Axios from "axios";
import Controller from "./gallery.controller";
import controllers from "../../store/controllers";

function ImgElement(props) {
  const isMarkedForDeletion = props.isMarkedForDeletion || false;
  return (
    <>
      <div
        disabled={isMarkedForDeletion}
        onClick={e => !isMarkedForDeletion && props.onClick && props.onClick(e)}
        className={`img-element ${
          props.isSelected && props.isSelected === true ? "selected" : ""
        }
        ${isMarkedForDeletion === true ? " deleting" : ""}`}
        style={{ backgroundImage: `url(${props.imageUrl && props.imageUrl})` }}
      ></div>
    </>
  );
}
const Dropzone = withTranslation()(
  class Dropzone extends React.Component {
    constructor(props) {
      super(props);

      this.dragCounter = 0;
      this.handleDrag = this.handleDrag.bind(this);
      this.handleDragIn = this.handleDragIn.bind(this);
      this.handleDragOut = this.handleDragOut.bind(this);
      this.handleDrop = this.handleDrop.bind(this);
      this.handleFileInputChange = this.handleFileInputChange.bind(this);
      this.handleFile = this.handleFile.bind(this);

      this.dropRef = React.createRef();
      this.fileInputRef = React.createRef();
      this.currentFile = null;
      this.state = {
        dragging: false
      };
    }

    handleFile(files) {
      const { onMedia } = this.props;
      if (files && files.length > 0) {
        onMedia && onMedia(files[0]);
      }
    }

    handleFileInputChange(e) {
      this.handleFile(this.fileInputRef.current.files);
      this.fileInputRef.current.value = "";
    }

    handleDrag = e => {
      e.preventDefault();
      e.stopPropagation();
    };

    handleDragIn = e => {
      e.preventDefault();
      e.stopPropagation();
      this.dragCounter++;
      if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
        this.setState({ dragging: true });
      }
    };

    handleDragOut = e => {
      e.preventDefault();
      e.stopPropagation();
      this.dragCounter--;
      if (this.dragCounter > 0) return;
      this.setState({ dragging: false });
    };

    handleDrop = e => {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ dragging: false });
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        this.handleFile(e.dataTransfer.files);
        e.dataTransfer.clearData();
        this.dragCounter = 0;
      }
    };

    componentDidMount() {
      this.dragCounter = 0;
      let div = this.dropRef.current;
      div.addEventListener("dragenter", this.handleDragIn);
      div.addEventListener("dragleave", this.handleDragOut);
      div.addEventListener("dragover", this.handleDrag);
      div.addEventListener("drop", this.handleDrop);
    }

    componentWillUnmount() {
      let div = this.dropRef.current;
      div.removeEventListener("dragenter", this.handleDragIn);
      div.removeEventListener("dragleave", this.handleDragOut);
      div.removeEventListener("dragover", this.handleDrag);
      div.removeEventListener("drop", this.handleDrop);
    }

    render() {
      const { dragging } = this.state;
      return (
        <div
          ref={this.dropRef}
          className={`dropzone flex-fill d-flex flex-column align-items-center justify-content-center ${
            dragging === true ? "is-dragging" : ""
          }`}
        >
          <i className="fas fa-photo-video"></i>
          <span className="pt-3">
            {dragging === true ? (
              <React.Fragment>
                {this.props.t("Yah drop right here!")}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={this.handleFileInputChange}
                    ref={this.fileInputRef}
                  />

                  {this.props.t("Browse")}
                </label>{" "}
                {this.props.t("or Drop your media here")}
              </React.Fragment>
            )}
          </span>
        </div>
      );
    }
  }
);

const Gallery = withTranslation()(
  class Gallery extends React.Component {
    constructor(props) {
      super(props);

      this.renderCropper = this.renderCropper.bind(this);

      this._crop = this._crop.bind(this);
      this.showCropper = this.showCropper.bind(this);
      this.quitCropper = this.quitCropper.bind(this);
      this.beginUpload = this.beginUpload.bind(this);
      this.handleSecondaryFileInputChange = this.handleSecondaryFileInputChange.bind(
        this
      );

      this.cropperRef = React.createRef();
      this.fileInputRef = React.createRef();

      this.currentFile = null;
      this.currentFileBlob = null;
      this.state = {
        mode: "drop-ready",
        cropperMode: "ready",
        disableGalleryInput: false,
        uploadProgress: 0,
        uploadMessage: ""
      };
    }

    _crop() {
      // image in dataUrl
      // console.log(this.cropperRef.current.cropper.getCroppedCanvas().toDataURL());
    }

    showCropper(file) {
      const self = this;
      const { onToggleCrop, context } = self.props;
      const uploadLimits = Object.assign(
        {
          maxFileSizeInBytes: 5000000
        },

        context.uploadConfig
      );

      var reader = new FileReader();

      reader.addEventListener(
        "load",
        function() {
          // reader.result
          self.currentFile = reader.result;
          self.currentFileBlob = file;
          self.setState({
            mode: "crop"
          });
        },
        false
      );

      if (file.size <= uploadLimits.maxFileSizeInBytes) {
        if (file.type.startsWith("image/") === true) {
          onToggleCrop && onToggleCrop(true);
          reader.readAsDataURL(file);
        } else {
          controllers.setGlobalError(
            `The uploaded media is not supported. Allowed types are png, jpg, gif, bmp, tif`,
            true
          );
        }
      } else {
        controllers.setGlobalError(
          `The uploaded media is too large. Max. supported file size is ${fileSize(
            uploadLimits.maxFileSizeInBytes,
            { round: false }
          )}.`,
          true
        );
      }
    }

    quitCropper() {
      const { onToggleCrop } = this.props;
      this.setState(
        {
          mode: "drop-ready"
        },

        () => {
          this.currentFile = null;
          this.currentFileBlob = null;
          onToggleCrop && onToggleCrop(false);
        }
      );
    }

    handleSecondaryFileInputChange(e) {
      const files = this.fileInputRef.current.files;
      if (files && files.length > 0) {
        this.showCropper(files[0]);
      }
      this.fileInputRef.current.value = "";
    }

    beginUpload() {
      const self = this;
      let progress = 0;
      let progressMessage = "Uploading...";
      this.setState(
        {
          cropperMode: "busy",
          disableGalleryInput: true,
          uploadProgress: progress,
          uploadMessage: progressMessage
        },

        () => {
          const formData = new FormData();
          const cropData = JSON.stringify(
            this.cropperRef.current.cropper.getData()
          );

          formData.append("media", this.currentFileBlob);
          formData.append("cropData", cropData);
          Axios.post("/api/media", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            },

            onUploadProgress: progressEvent => {
              progress = parseInt(
                (progressEvent.loaded / progressEvent.total) * 100
              );

              progressMessage =
                progress > 95 ? "Processing..." : "Uploading...";

              self.setState({
                uploadProgress: progress,
                uploadMessage: progressMessage
              });
            }
          })
            .then(response => {
              self.setState(
                {
                  cropperMode: "ready",
                  disableGalleryInput: false,
                  uploadProgress: 0,
                  uploadMessage: ""
                },

                () => {
                  self.quitCropper();
                  Controller.addToRecentFiles(response.data);
                  Controller.sendImageResult(response.data);
                  Controller.closeGallery();
                }
              );
            })
            .catch(err => {
              err = (err.response && err.response) || err;
              self.setState(
                {
                  cropperMode: "ready",
                  disableGalleryInput: false,
                  uploadProgress: 0,
                  uploadMessage: ""
                },

                () => {
                  const message = (err.data && err.data.message) || err.message;
                  controllers.setGlobalError(message, true);
                }
              );
            });
        }
      );
    }

    renderCropper() {
      const {
        context: {
          uploadConfig: { aspectRatio }
        }
      } = this.props;
      const {
        cropperMode,
        disableGalleryInput,
        uploadProgress,
        uploadMessage
      } = this.state;
      return (
        <Zoom duration={300}>
          <div className="w-100 crop-tool">
            <div className="cropper-wrapper">
              {cropperMode === "busy" ? (
                <Fade duration={300}>
                  <div className="progress-box d-flex justify-content-center align-items-center">
                    <div className="message text-center">
                      <h3>{uploadMessage}</h3>
                      <div className="progress">
                        <div
                          className="progress-bar progress-bar-striped progress-bar-animated"
                          role="progressbar"
                          aria-valuenow={uploadProgress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                          style={{ width: `${uploadProgress}%` }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </Fade>
              ) : null}
              <Cropper
                dragMode="move"
                rotatable={true}
                ref={this.cropperRef}
                src={this.currentFile}
                responsive={true}
                style={{ height: "60vh", width: "100%" }}
                // Cropper.js options
                aspectRatio={aspectRatio}
                guides={false}
                crop={this._crop.bind(this)}
              />
            </div>
            <div className="container-fluid">
              <div className="editor-control row pb-0 pb-sm-3 pb-md-0">
                <div className="left-control py-3 col-3 d-none d-lg-flex align-items-center justify-content-start truncate">
                  <i className="editor-logo fas fa-crop-alt"></i>
                  <label className="truncate">
                    {this.currentFileBlob.name}
                  </label>
                </div>
                <div className="center-control col-12 col-md-6 col-lg-5 d-flex align-items-center justify-content-center justify-content-md-start justify-content-lg-center">
                  <button
                    className="py-3"
                    disabled={disableGalleryInput}
                    onClick={() => this.cropperRef.current.rotate(-45)}
                    ref={r => jQuery(r).tooltip()}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Rotate 45&#176; anti-clockwise"
                  >
                    <i className="fas fa-undo"></i>
                  </button>
                  <button
                    className="py-3"
                    disabled={disableGalleryInput}
                    onClick={() => this.cropperRef.current.rotate(45)}
                    ref={r => jQuery(r).tooltip()}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Rotate 45&#176; clockwise"
                  >
                    <i className="fas fa-redo"></i>
                  </button>
                  <div className="seperator"></div>
                  <button
                    className="py-3"
                    disabled={disableGalleryInput}
                    onClick={() => this.cropperRef.current.reset()}
                    ref={r => jQuery(r).tooltip()}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Reset Editor"
                  >
                    <i className="fas fa-sync"></i>
                  </button>
                </div>
                <div className="py-0 py-md-3 col-12 col-sm-6 col-md-3 col-lg-2 d-flex align-items-center justify-content-end">
                  <button
                    disabled={disableGalleryInput}
                    onClick={this.quitCropper}
                    className="btn btn-danger btn-block btn-sm mb-3 mb-sm-0 px-4"
                  >
                    <i className="fas fa-chevron-left"></i>
                    {this.props.t("Cancel")}
                  </button>
                </div>
                <div className="py-0 py-md-3 mb-3 mb-sm-0 col-12 col-sm-6 col-md-3 col-lg-2 d-flex align-items-center justify-content-end">
                  <button
                    disabled={disableGalleryInput}
                    onClick={this.beginUpload}
                    className="btn btn-primary btn-block btn-sm px-4"
                  >
                    {this.props.t("Upload")}

                    <i className="fas fa-cloud-upload-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Zoom>
      );
    }

    renderRecentUploads() {
      const { context } = this.props;
      const selectedRecentMediaId = context.selectedMediaId;
      const isMarkedForDeletion =
        context.markedForDeletion.indexOf(selectedRecentMediaId) > -1;

      return (
        <div className="flex-fill recent-files-wrapper">
          <div className="col-12 recent-upload-head">
            <h5>{this.props.t("Recent Uploads")}</h5>
            {selectedRecentMediaId !== null ? (
              <div className="dropdown mr-2">
                <button
                  disabled={isMarkedForDeletion}
                  className="btn btn-danger btn-sm dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-trash"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <div className="alert alert-danger" role="alert">
                    {this.props.t(
                      "Deleting this picture could affect other places where it is being used. Are you sure you want to continue?"
                    )}

                    <button
                      onClick={() =>
                        Controller.markForDeletion(selectedRecentMediaId)
                      }
                      className="btn btn-danger btn-sm"
                    >
                      {this.props.t("DELETE FOREVER")}
                    </button>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="galbody">
            <div className="col-12" style={{ overflow: "hidden" }}>
              {context.recentFiles.map(media => (
                <ImgElement
                  key={media._id}
                  isMarkedForDeletion={
                    context.markedForDeletion.indexOf(media._id) > -1
                  }
                  isSelected={selectedRecentMediaId === media._id}
                  onClick={() =>
                    Controller.selectRecentMedia(
                      selectedRecentMediaId === media._id ? null : media._id
                    )
                  }
                  imageUrl={controllers.resolveAssetAddr(media._id)}
                />
              ))}
            </div>
          </div>
        </div>
      );
    }

    chooseFromRecentImage(mediaId) {
      const { context } = this.props;
      const media = context.recentFiles.find(f => f._id === mediaId);
      Controller.sendImageResult(media);
      Controller.closeGallery();
    }

    renderUploadControls() {
      const {
        context: { selectedMediaId }
      } = this.props;
      return (
        <div className="upload-control flex-shrink-0 row px-4 py-3">
          <div className="col-12 col-sm-6 offset-md-6 col-md-3 offset-lg-8 col-lg-2 d-flex align-items-center mb-3 mb-sm-0">
            <label className="btn btn-block btn-secondary m-0">
              <input
                type="file"
                accept="image/*"
                onChange={this.handleSecondaryFileInputChange}
                ref={this.fileInputRef}
              />

              <i className="fas fa-upload pr-1"></i>
              {this.props.t("Upload")}
            </label>
          </div>
          <div className="col-12 col-sm-6 col-md-3 col-lg-2 d-flex align-items-center">
            <button
              onClick={() => this.chooseFromRecentImage(selectedMediaId)}
              disabled={selectedMediaId === null}
              className="btn btn-block btn-primary"
            >
              {this.props.t("Choose Image")}

              <i className="fas fa-arrow-right pl-1"></i>
            </button>
          </div>
        </div>
      );
    }

    render() {
      const { context } = this.props;
      const { mode } = this.state;

      return (
        <div className="gallery d-flex flex-direction-column">
          <div className="flex-shrink-0 uploader d-flex flex-column">
            {mode === "crop" ? (
              this.renderCropper()
            ) : (
              <React.Fragment>
                <div className="head pb-2 mb-2">
                  {<h3 className="pr-4">{context.title || "Uploader"}</h3>}
                  {
                    <p className="m-0">
                      {context.desc || "Drag and drop your photo here"}
                    </p>
                  }

                  <button
                    onClick={this.props.onClose}
                    title="Close Uploader"
                    className="close-btn"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
                {context.isLoading === false ? (
                  <Fade key="dropzone" duration={300}>
                    <div className="flex-fill d-none d-md-flex flex-column">
                      <Dropzone onMedia={this.showCropper} />
                    </div>
                  </Fade>
                ) : (
                  <div className="d-flex loader flex-fill justify-content-center align-items-center">
                    <i className="fas fa-spin fa-circle-notch"></i>
                    <span className="pl-2">
                      {this.props.t("Fetching recent files...")}
                    </span>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
          {mode === "drop-ready" &&
          context.isLoading === false &&
          context.recentFiles.length > 0 ? (
            <Zoom duration={300}>
              {this.renderRecentUploads()}
              {this.renderUploadControls()}
            </Zoom>
          ) : null}
        </div>
      );
    }
  }
);

export default connect(state => ({
  context: state._gallery_
}))(Gallery);
