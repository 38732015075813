import { withTranslation } from "react-i18next";
import React from "react";
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-reveal";
import ReactTable from "react-table";
import { Helmet } from "react-helmet";
import { DateRangePicker } from "react-dates";
import { connect } from "react-redux";
import { Modal } from "reactstrap";
import { ListingServices } from "./../../services/Listing.services";
import { CouponServices } from "./../../services/Coupon.services";
import Controller from "../../store/controllers/index";
import { NETWORK_ERR_MESSAGE } from "../../resources/String";
import moment from "moment";
import getConfig from "../../config";
const Coupons = withTranslation()(
  class Coupons extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        data: [],
        acceptedListings: [],
        couponCode: "",
        listing: "",
        start: null,
        end: null,
        amount: "",
        isCouponModalOpen: false,
        isDeleteModalOpen: false,
        idToDelete: -1,
        idToUpdate: -1,
        editorMode: "add"
      };

      this.fetchListing = this.fetchListing.bind(this);
      this.toggleCouponModal = this.toggleCouponModal.bind(this);
      this.fetchCoupon = this.fetchCoupon.bind(this);
      this.renderImage = this.renderImage.bind(this);
      this.addCoupon = this.addCoupon.bind(this);
      this.editCoupon = this.editCoupon.bind(this);
      this.renderAction = this.renderAction.bind(this);
      this.createCoupon = this.createCoupon.bind(this);
      this.toggleDeleteModal = this.toggleDeleteModal.bind(this);
      this.deleteCoupon = this.deleteCoupon.bind(this);
      this.UpdateCoupon = this.UpdateCoupon.bind(this);
      this.saveChanges = this.saveChanges.bind(this);
      this.generateRandomCoupon = this.generateRandomCoupon.bind(this);
    }
    toggleCouponModal() {
      this.setState({
        isCouponModalOpen: !this.state.isCouponModalOpen,
        couponCode: "",
        listing: "",
        start: null,
        end: null,
        amount: ""
      });
    }

    toggleDeleteModal(idToDelete = -1) {
      this.setState({
        isDeleteModalOpen: !this.state.isDeleteModalOpen,
        idToDelete: idToDelete
      });
    }

    fetchListing() {
      if (this.state.acceptedListings.length < 1) {
        Controller.setGlobalLoader("Preparing coupon...", false);
        ListingServices.GetListings("accepted")
          .then(response => {
            Controller.clearGlobalMessage();
            this.setState({
              acceptedListings: response
            });
          })
          .catch(err => {
            Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
          });
      }
    }

    convertCouponToTableFriendlyFormat(coupon) {
      return {
        coverImage: coupon.listing.package.defaultCoverArtId,
        listing: coupon.listing.package.title,
        couponCode: coupon.couponCode,
        startDate: moment(coupon.startDate).format("ll"),
        endDate: moment(coupon.endDate).format("ll"),
        amountInUSD: coupon.amountInUSD,
        usageCount: coupon.usageCount,
        _id: coupon.listing._id,
        couponId: coupon._id,
        hasExpired: coupon.hasExpired
      };
    }

    fetchCoupon() {
      Controller.setGlobalLoader("Fetching coupon...", false);
      setTimeout(() => {
        CouponServices.GetCoupons()
          .then(coupons => {
            Controller.clearGlobalMessage();
            this.setState({
              data: coupons.map(coupon =>
                this.convertCouponToTableFriendlyFormat(coupon)
              )
            });
          })
          .catch(err => {
            Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
          });
      }, 800);
    }

    saveChanges() {
      if (this.state.editorMode === "update") {
        this.UpdateCoupon();
      } else {
        this.addCoupon();
      }
    }

    UpdateCoupon() {
      if (!this.state.start || !this.state.end) {
        Controller.setGlobalError("Please select start and end date", true);
        return;
      }

      const payload = {
        startDate: this.state.start.valueOf(),
        endDate: this.state.end.valueOf(),
        amountInUSD: this.state.amount
      };

      Controller.setGlobalLoader("Updating...", false);
      CouponServices.UpdateCoupon(this.state.idToUpdate, payload)
        .then(coupon => {
          Controller.clearGlobalMessage();
          this.setState({
            listing: "",
            start: null,
            end: null,
            amount: "",
            couponCode: "",
            isCouponModalOpen: false,
            data: this.state.data.map(_coupon => {
              if (_coupon.couponId === this.state.idToUpdate) {
                return this.convertCouponToTableFriendlyFormat(coupon);
              }

              return _coupon;
            })
          });
        })
        .catch(err => {
          Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
        });
    }

    addCoupon() {
      if (!this.state.start || !this.state.end) {
        Controller.setGlobalError("Please select start and end date", true);
        return;
      }

      const payload = {
        listing: this.state.listing,
        startDate: this.state.start.valueOf(),
        endDate: this.state.end.valueOf(),
        amountInUSD: this.state.amount,
        couponCode: this.state.couponCode
      };

      Controller.setGlobalLoader("Generating coupon...", false);
      setTimeout(() => {
        CouponServices.CreateCoupon(payload)
          .then(generatedCoupon => {
            Controller.clearGlobalMessage();
            this.setState({
              listing: "",
              start: null,
              end: null,
              amount: "",
              isCouponModalOpen: false,
              data: [
                this.convertCouponToTableFriendlyFormat(generatedCoupon),
                ...this.state.data
              ]
            });
          })
          .catch(err => {
            Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
          });
      }, 800);
    }

    componentDidMount() {
      this.fetchCoupon();
    }
    renderImage(row) {
      return (
        <div
          style={{
            height: 110,
            width: "100%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundImage: `url('${Controller.resolveAssetAddr(row.value)}')`
          }}
        ></div>
      );
    }

    createCoupon() {
      this.setState({
        isCouponModalOpen: true,
        editorMode: "add"
      });

      this.fetchListing();
      this.generateRandomCoupon();
    }

    deleteCoupon() {
      Controller.setGlobalLoader("Deleting...", false);
      CouponServices.deleteCoupon(this.state.idToDelete)
        .then(coupons => {
          Controller.clearGlobalMessage();
          this.setState({
            isDeleteModalOpen: false,
            idToDelete: -1,
            data: this.state.data.filter(coupon => {
              if (coupon.couponId === this.state.idToDelete) {
                return false;
              }
              return true;
            })
          });
        })
        .catch(err => {
          Controller.setGlobalError(err.message || NETWORK_ERR_MESSAGE, true);
        });
    }

    editCoupon(data) {
      this.setState({
        editorMode: "update",
        idToUpdate: data.couponId,
        isCouponModalOpen: true,
        listing: data._id,
        start: moment(data.startDate),
        end: moment(data.endDate),
        couponCode: data.couponCode,
        amount: data.amountInUSD
      });

      this.fetchListing();
    }

    generateRandomCoupon() {
      return new Promise((resolve, reject) => {
        this.setState(
          {
            couponCode: ""
          },

          () =>
            setTimeout(() => {
              const listingId = this.state.listing;
              let hasSuitableCodeFound = false;
              let generatedCode = "";

              const CouponFilter = _coupon => {
                return (
                  _coupon.listing === listingId &&
                  _coupon.couponCode === generatedCode
                );
              };

              while (hasSuitableCodeFound === false) {
                var text = "";
                var number = "";
                var possibleText = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
                for (var i = 0; i < 2; i++) {
                  text += possibleText.charAt(
                    Math.floor(Math.random() * possibleText.length)
                  );
                }

                var possibleNumber = "0123456789";
                for (var j = 0; j < 1; j++) {
                  number += possibleNumber.charAt(
                    Math.floor(Math.random() * possibleNumber.length)
                  );
                }

                generatedCode = `TBOFF-${text.toUpperCase()}${number}`;

                const existingCoupons = this.state.data.filter(CouponFilter);

                if (existingCoupons.length < 1) {
                  hasSuitableCodeFound = true;
                }
              }

              this.setState({
                couponCode: generatedCode
              });
            }, 700)
        );
      });
    }

    renderAction(row) {
      return (
        <div>
          <button
            className="coupon-delete-edit-refresh-button-wrapper"
            onClick={e => this.editCoupon(row.original)}
          >
            <i className="far fa-edit pr-1"></i>
            {this.props.t("Edit")}
          </button>
          <button
            className="coupon-delete-edit-refresh-button-wrapper"
            onClick={() => this.toggleDeleteModal(row.original.couponId)}
          >
            <i className="fas fa-trash-alt pr-1"></i>
            {this.props.t("Delete")}
          </button>
        </div>
      );
    }

    render() {
      const { local } = this.props;
      const {
        match: {
          params: { status }
        }
      } = this.props;

      let currentTab;
      let dataToDisplay = [];
      switch (status) {
        case "expired": {
          currentTab = "expired";
          dataToDisplay = this.state.data.filter(
            coupon => coupon.hasExpired === true
          );

          break;
        }
        default: {
          dataToDisplay = this.state.data.filter(
            coupon => coupon.hasExpired === false
          );

          currentTab = "active";
        }
      }

      return (
        <React.Fragment>
          <Fade duration={300}>
            <Helmet>
              <title>{this.props.t("Coupons - Tripbonder.com")}</title>
            </Helmet>
            <div className="container-fluid listings-wrapper coupon-wrapper">
              <div className="row">
                <div className="col-12 text-center pt-3 listing-header-wrapper">
                  <label>
                    {this.props.t("Manage your Coupons here,")}{" "}
                    {local.currentUser.name}
                    {this.props.t("!")}
                  </label>
                  <h3>{this.props.t("My Coupons")}</h3>
                </div>
              </div>
              <div className="row activity">
                <div className="col-12 pt-2 text-center">
                  <div
                    className="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <Link
                      to="/dashboard/coupons/active"
                      className={`${
                        currentTab === "active"
                          ? "active-button"
                          : "inactive-button"
                      } btn btn-secondary px-4`}
                    >
                      {this.props.t("Active")}
                    </Link>
                    <Link
                      to="/dashboard/coupons/expired"
                      className={`${
                        currentTab === "expired"
                          ? "active-button"
                          : "inactive-button"
                      } btn btn-secondary px-4`}
                    >
                      {this.props.t("Expired")}
                    </Link>
                  </div>
                </div>
                <div className="col-12 col-xl-6 text-center text-xl-left pt-3 mt-0 mt-xl-2">
                  <div className=" refresh ">
                    <a
                      className="export-to-csv"
                      href={`${
                        getConfig().SERVER_BASE_ADDRS
                      }api/coupons?csv=true`}
                      target="blank"
                    >
                      <i className="fas fa-cloud-download-alt pr-1"></i>
                      {this.props.t("Export to CSV")}
                    </a>
                  </div>
                </div>
                <div className="col-12 col-xl-6 text-center text-xl-left">
                  <div className="grid-table-toggle-button-wrapper text-center text-xl-right pt-2">
                    <div className="d-xl-inline">
                      <button
                        onClick={() => this.fetchCoupon()}
                        className="coupon-delete-edit-refresh-button-wrapper mr-lg-2"
                      >
                        <i className="fas fa-redo-alt pr-2"></i>
                        {this.props.t("Refresh")}
                      </button>
                    </div>
                    <button
                      onClick={e => this.createCoupon()}
                      className="btn btn-warning-gradient mt-2 mt-xl-0 btn-sm"
                      data-toggle="modal"
                      data-target="#couponGenerator"
                    >
                      <i className="fas fa-plus pr-1"></i>
                      {this.props.t("Generate Coupon")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 my-4 px-0">
                  <ReactTable
                    showPaginationTop={true}
                    defaultPageSize={5}
                    showPaginationBottom={true}
                    data={dataToDisplay}
                    columns={[
                      {
                        Header: "",
                        accessor: "coverImage",
                        Cell: this.renderImage
                      },

                      {
                        Header: this.props.t("Listing"),
                        accessor: "listing",
                        className: "lh-110",
                        filterable: true
                      },

                      {
                        Header: this.props.t("Coupon Code"),
                        accessor: "couponCode",
                        className: "lh-110",
                        filterable: true
                      },

                      {
                        Header: this.props.t("Start Date"),
                        accessor: "startDate",
                        className: "lh-110 text-capitalize",
                        filterable: true
                      },

                      {
                        Header: this.props.t("End Date"),
                        accessor: "endDate",
                        className: "lh-110",
                        filterable: true
                      },

                      {
                        Header: this.props.t("Amount In USD"),
                        accessor: "amountInUSD",
                        className: "lh-110",
                        filterable: true
                      },

                      {
                        Header: this.props.t("Usage Count"),
                        accessor: "usageCount",
                        className: "lh-110",
                        filterable: true
                      },

                      {
                        Header: this.props.t("Actions"),
                        accessor: "actions",
                        className: "lh-110",
                        sortable: false,
                        filterable: false,
                        Cell: this.renderAction
                      }
                    ]}
                  />
                </div>
              </div>
            </div>
          </Fade>
          <Modal
            isOpen={this.state.isCouponModalOpen}
            centered={true}
            toggle={e => this.toggleCouponModal()}
            modalClassName="coupon-modal-wrapper"
            tabIndex="-1"
          >
            <div className="row px-2 px-md-0">
              <div className="col-2 d-md-none"></div>
              <div className="col-8 d-md-none text-center mini-coupen-img-wrapper">
                <img src={require("../../assets/images/coupon.png")} alt="" />
                <h6 className="d-md-none pt-2">
                  {this.props.t("Create new coupon")}
                </h6>
              </div>
              <div className="col-2 d-md-none mini-close-button-wrapper text-right pt-2">
                <button onClick={e => this.toggleCouponModal(e)}>
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <div className="col-4 coupen-img-wrapper d-none d-md-block">
                <img src={require("../../assets/images/coupon.png")} alt="" />
              </div>
              <div className="col-12 col-md-8 my-3 coupon-content-wrapper">
                <div className="d-flex">
                  <div className="flex-shrink-0 pl-3 pl-md-0 d-none d-md-block">
                    <h6>{this.props.t("Create new coupon")}</h6>
                  </div>
                  <div className="flex-fill"></div>
                  <div className="flex-shrink-0 close-button-wrapper pr-3 d-none d-md-block">
                    <button onClick={e => this.toggleCouponModal(e)}>
                      <i className="fas fa-times pr-1"></i>
                    </button>
                  </div>
                </div>
                <div className="coupon-ticket-wrapper  mr-0 mr-md-4 px-3">
                  <div className="form-row pt-3">
                    <div className="col-12 col-md-6">
                      {this.state.couponCode !== "" ? (
                        <Zoom duration={350}>
                          <h4 style={{ margin: 0 }}>{this.state.couponCode}</h4>
                        </Zoom>
                      ) : (
                        <div>
                          <i className="fas fa-spin fa-circle-notch"></i>{" "}
                          <small>{this.props.t("Fetching coupon...")}</small>
                        </div>
                      )}
                    </div>
                    <div className="col-12 col-md-6 text-left text-md-right">
                      <select
                        value={this.state.listing}
                        onChange={e =>
                          this.setState({ listing: e.target.value }, () =>
                            this.generateRandomCoupon()
                          )
                        }
                        className="custom-select-sm pl-2 pr-5 btn-block"
                        disabled={this.state.editorMode === "update"}
                      >
                        <option value="">
                          {this.props.t("Select Listing")}
                        </option>
                        {this.state.acceptedListings.map((listing, index) => (
                          <option key={index} value={listing._id}>
                            {listing.package.title}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <div className="text-center text-md-left pt-2 pt-md-0">
                      <label className="pt-2 mb-0">
                        {this.props.t("VALIDITY")}
                      </label>
                      <hr className="my-0"></hr>
                    </div>
                  </div>
                  <div className="form-row date-wrapper input-wrapper pt-2 px-2">
                    <DateRangePicker
                      keepOpenOnDateSelect
                      hideKeyboardShortcutsPanel
                      small={window.matchMedia("(max-width: 400px)").matches}
                      block={window.matchMedia("(max-width: 400px)").matches}
                      numberOfMonths={
                        window.matchMedia("(max-width: 400px)").matches === true
                          ? 1
                          : 2
                      }
                      startDate={this.state.start}
                      displayFormat="ll"
                      startDateId="your_unique_start_date_id"
                      endDate={this.state.end}
                      endDateId="your_unique_end_date_id"
                      onDatesChange={({ startDate, endDate }) =>
                        this.setState({
                          start: startDate,
                          end: endDate
                        })
                      }
                      focusedInput={this.state.focusedDateRangeInput}
                      onFocusChange={focusedInput =>
                        this.setState({
                          focusedDateRangeInput: focusedInput
                        })
                      }
                    />
                  </div>
                  <div className="text-center text-md-left  pt-2 pt-md-0">
                    <label className="pt-2 mb-0">
                      {this.props.t("DISCOUNT AMOUNT")}
                    </label>
                    <hr className="my-0"></hr>
                  </div>
                  <div className="form-row date-wrapper px-2 pt-2">
                    <div className="col-2 mb-3 pr-3">
                      <select
                        className="custom-select-sm pl-2 mr-2"
                        disabled={true}
                      >
                        <option value>{this.props.t("USD")}</option>
                      </select>
                    </div>
                    <div className="col-10 mb-3 pl-5 pl-md-3 discount-amount-input">
                      <input
                        onChange={e =>
                          this.setState({
                            amount: e.target.value
                          })
                        }
                        value={this.state.amount}
                        type="type"
                        className="form-control form-control-sm"
                        id="to"
                        placeholder={this.props.t("Enter discount amount")}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex pt-2">
                  <div className="flex-shrink-0">
                    {this.state.editorMode === "add" ? (
                      <button
                        disabled={this.state.couponCode === ""}
                        onClick={() => this.generateRandomCoupon()}
                        className="regenerate-button"
                      >
                        <i className="fas fa-sync-alt pr-2"></i>
                        {this.props.t("Regenerate Coupon")}
                      </button>
                    ) : null}
                  </div>
                  <div className="flex-fill"></div>
                  <div className="flex-shrink-0 px-4 d-none d-md-block">
                    <button
                      disabled={this.state.couponCode === ""}
                      onClick={e => this.saveChanges()}
                      type="button"
                      className="btn btn-success-gradient px-5 create"
                    >
                      {this.props.t("Save Coupon")}
                    </button>
                  </div>
                </div>
                <div className="d-md-none pt-2">
                  <button
                    disabled={this.state.couponCode === ""}
                    onClick={e => this.saveChanges()}
                    type="button"
                    className="btn btn-success-gradient px-5 create btn-block"
                  >
                    {this.props.t("Save Coupon")}
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="modal-footer">
                 <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                 <button type="button" className="btn btn-primary">Save changes</button>
               </div> */}
          </Modal>
          <Modal
            isOpen={this.state.isDeleteModalOpen}
            toggle={e => this.toggleDeleteModal()}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{this.props.t("Delete Coupon")}</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => this.toggleDeleteModal()}
                  aria-label="Close"
                >
                  <span aria-hidden="true">{this.props.t("\xD7")}</span>
                </button>
              </div>
              <div className="modal-body">
                {this.props.t("Are you sure you want to delete this?")}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => this.toggleDeleteModal()}
                >
                  {this.props.t("Close")}
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => this.deleteCoupon()}
                >
                  {this.props.t("Confirm")}
                </button>
              </div>
            </div>
          </Modal>
        </React.Fragment>
      );
    }
  }
);

export default connect(state => ({
  local: state.local
}))(Coupons);
