import { useTranslation, withTranslation } from "react-i18next";
import React, { useState } from "react";
import { HotKeys } from "react-hotkeys";
import { connect } from "react-redux";
import moment from "moment";
import { Fade } from "react-reveal";
import ImagePlaceholder from "../../components/Draft/ImagePlaceholder";
import GridImagePlaceholder from "../../components/Draft/GridImagePlaceholder";
import controller from "../../store/controllers";
import GalleryController from "./../../components/MediaGallery/gallery.controller";
import BottomSlider from "./../../components/Modals/BottomSlider";
import DayEditor from "./../../components/Draft/DayEditor";
import controllers from "../../store/controllers";
import { ListingServices } from "../../services/Listing.services";
import { DraftControl } from "../../components/Draft/DraftControl";
import LocationSearchInput from "../../components/Draft/LocationSearchInput";
import { Helmet } from "react-helmet";
import { Modal, ModalBody } from "reactstrap";
import PublishModal from "../../components/Modals/PublishModal";
import { StatusAlert } from "../../components/Draft/StatusAlert";
import TripViewer from "./../../components/TripViewer";
import PrivatePackageAlert from "../../components/Draft/PrivatePackageAlert";
import HideTawkTo from "./../../components/HideTawkTo";
import { Popover, PopoverHeader, PopoverBody } from "reactstrap";
import ChipInput from "material-ui-chip-input";

function Highlight(props) {
  const { t } = useTranslation();
  const { itinerary } = props;
  const [hintContext, setHintContext] = useState({});
  function setHighlightImage() {
    GalleryController.insertMedia("", "", {}, mediaObj => {
      controller.itinerary.updateHighlight(
        props.index,
        "imageId",
        mediaObj._id
      );
    });
  }

  const _getHintStateId = key => {
    return `_${key}_HINT`;
  };

  const getIsHintOpen = key => {
    if (hintContext[_getHintStateId(key)]) {
      return hintContext[_getHintStateId(key)];
    } else {
      return false;
    }
  };

  const toggleIsHintOpen = key => {
    setHintContext(
      Object.assign({}, hintContext, {
        [_getHintStateId(key)]: !getIsHintOpen(key)
      })
    );
  };
  return (
    <React.Fragment>
      <div className="highlights row mt-3">
        <div className="col-12">
          <div className="card-title">
            {"#"}
            {props.index + 1} {t("Highlight")}
            {itinerary._status === "draft" ? (
              <button
                className="close"
                type="button"
                data-toggle="modal"
                data-target="#deletePrompt"
                onClick={e => props.onDelete && props.onDelete(props.index)}
              >
                <i className="fas fa-times"></i>
              </button>
            ) : null}
          </div>
          <div className="row mx-0 highlight-content-wrapper">
            <div className="col-12 col-lg-2 px-0">
              <ImagePlaceholder
                disabled={itinerary._status !== "draft"}
                message={t(
                  "Click to add image. Image cannot be larger than 2MB"
                )}
                height="150px"
                border="none"
                value={controller.resolveAssetAddr(props.item.imageId)}
                onClick={() => setHighlightImage()}
                onRemove={() =>
                  controller.itinerary.updateHighlight(
                    props.index,
                    "imageId",
                    null
                  )
                }
              />
            </div>
            <div className="col-12 pt-4 col-lg-4 location-wrapper">
              <div className="form-group">
                <label htmlFor="location" className="header">
                  {t("LOCATION")}
                </label>
                <LocationSearchInput
                  readOnly={itinerary._status !== "draft"}
                  id="location"
                  geoLocation={props.item.geoLocation}
                  value={props.item.location}
                  onChange={location =>
                    controller.itinerary.updateHighlightLocation(
                      props.index,
                      location
                    )
                  }
                />
              </div>
            </div>
            <div className="col-12 pt-0 pt-md-4 col-lg-4">
              <div className="form-group">
                <label className="header">
                  {t("WHY IS IT A MUST DO OR SEE?")}
                  <button
                    id="trip-highlight-description"
                    className="hint-btn"
                    type="button"
                  >
                    {"?"}
                  </button>
                  <Popover
                    trigger="legacy"
                    placement="right"
                    isOpen={getIsHintOpen("trip-highlight-description")}
                    target="trip-highlight-description"
                    toggle={() =>
                      toggleIsHintOpen("trip-highlight-description")
                    }
                  >
                    <PopoverHeader>{t("Points to consider")}</PopoverHeader>
                    <PopoverBody>
                      <ol>
                        <li>{t("Enter highlights of this package")}</li>
                        <li>{t("Try to keep the title short and precise")}</li>
                      </ol>
                    </PopoverBody>
                    <PopoverHeader>{t("Examples")}</PopoverHeader>
                    <PopoverBody>
                      <ol>
                        <li>
                          {t(
                            " Because of its rugged terrain, and abundant rainfall, the rivers in Coorg have challenging grades, ranging from I to V"
                          )}
                        </li>
                        <li>
                          {t(
                            "Dubai Skydive, a leader in extreme skydiving, has broken yet another Guinness World Record in less than a month for BASE jumping off the very tip of The Burj Khalifa"
                          )}
                        </li>
                      </ol>
                    </PopoverBody>
                  </Popover>
                </label>
                <textarea
                  readOnly={itinerary._status !== "draft"}
                  value={props.item.description}
                  onChange={e =>
                    controller.itinerary.updateHighlight(
                      props.index,
                      "description",
                      e.target.value
                    )
                  }
                  className="form-control"
                  id="tripDesc"
                  rows="1"
                  placeholder={t("Description")}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export const ACTIVITIES = "activities";
export const FOOD = "food";
export const STAY = "stay";
const ItinerarySetup = withTranslation()(
  class ItinerarySetup extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        keyToDelete: "",
        indexToDelete: -1,
        isDayEditorOpen: false,
        dayEditorIndex: -1,
        currentDayEditorMode: ACTIVITIES,
        autoSaving: false,
        saving: false,
        isPublishDialogOpen: false,
        isPreviewOpen: false
      };

      this.switchDayEditorTab = this.switchDayEditorTab.bind(this);
      this.openDayEditor = this.openDayEditor.bind(this);
      this.closeDayEditor = this.closeDayEditor.bind(this);
      this.setDeleteContext = this.setDeleteContext.bind(this);
      this.proceedDelete = this.proceedDelete.bind(this);
      this.setCoverPicture = this.setCoverPicture.bind(this);
      this.loadItinerary = this.loadItinerary.bind(this);
      this.saveItinerary = this.saveItinerary.bind(this);
      this.onPublish = this.onPublish.bind(this);
      this.onTogglePublish = this.onTogglePublish.bind(this);
      this.runAutoSaver = this.runAutoSaver.bind(this);
      this.stopAutoSaver = this.stopAutoSaver.bind(this);
      this.handleSaveHotKey = this.handleSaveHotKey.bind(this);
    }

    // Hint Functions BEGIN

    _getHintStateId(key) {
      return `_${key}_HINT`;
    }

    getIsHintOpen(key) {
      if (this.state[this._getHintStateId(key)]) {
        return this.state[this._getHintStateId(key)];
      } else {
        return false;
      }
    }

    toggleIsHintOpen(key) {
      this.setState({
        [this._getHintStateId(key)]: !this.getIsHintOpen(key)
      });
    }

    // Hint Functions END

    switchDayEditorTab(tab) {
      this.setState({
        currentDayEditorMode: tab
      });
    }

    openDayEditor(dayIndex) {
      this.setState({
        isDayEditorOpen: true,
        dayEditorIndex: dayIndex,
        currentDayEditorMode: ACTIVITIES
      });
    }

    closeDayEditor() {
      this.setState({
        isDayEditorOpen: false,
        dayEditorIndex: -1,
        currentDayEditorMode: ACTIVITIES
      });
    }

    setDeleteContext(keyToDelete, indexToDelete) {
      this.setState({ keyToDelete, indexToDelete });
    }

    proceedDelete() {
      const { keyToDelete, indexToDelete } = this.state;
      switch (keyToDelete) {
        case "highlight": {
          controller.itinerary.deleteHighlight(indexToDelete);
          this.setDeleteContext("", -1);
          break;
        }
        case "day": {
          controller.itinerary.deleteDay(indexToDelete);
          break;
        }
        default: {
          break;
        }
      }
    }

    addCoverImage(index) {
      GalleryController.insertMedia(
        "Choose Itinerary Cover",
        "",
        {},
        mediaObj => {
          controller.itinerary.replaceItineraryBanner(index, mediaObj._id);
        }
      );
    }

    setCoverPicture() {
      GalleryController.insertMedia(
        "Upload Itinerary Cover",
        "Any attractive picture must get you the thing you wanted",
        {},
        mediaObj => {
          controller.itinerary.updateItinerary(
            "defaultCoverArtId",
            mediaObj._id
          );
        }
      );
    }

    loadItinerary(props) {
      const {
        match: {
          params: { id }
        }
      } = props || this.props;
      controllers.setGlobalLoader("Preparing Editor...", false);
      ListingServices.GetListingById("itinerary", id)
        .then(itinerary => {
          controllers.resetEditorSaveState();
          setTimeout(() => {
            controllers.setEditorListing(itinerary);
            controllers.marketing.setCurrentPackage(itinerary);
            controllers.itinerary.setItineraryEditorContext(
              itinerary.package,
              itinerary.status
            );

            controllers.clearGlobalMessage();
            this.titleInputRef && this.titleInputRef.focus();
            this.runAutoSaver();
          }, 1000);
        })
        .catch(err => {
          controllers.setGlobalError("Package not found", false);
        });
    }

    runAutoSaver() {
      const self = this;
      const lastSavePrefix = "last saved ";
      self.setState(
        {
          autoSaving: false,
          lastSavedOn: null
        },

        () => {
          self.autoSaver =
            (self.autoSaver && clearInterval(self.autoSaver)) || null;
          let isModified = false;
          self.autoSaver = setInterval(() => {
            if (self.state.saving === false) {
              isModified =
                self.props.local.editorLastModifiedOn >
                self.props.local.editorLastSavedOn;
              if (isModified === true) {
                let timeDifference =
                  new Date().getTime() - self.props.local.editorLastModifiedOn;
                if (timeDifference > 2500) {
                  self.setState(
                    {
                      saving: true,
                      autoSaving: true
                    },

                    () =>
                      self
                        .saveItinerary(true)
                        .then(() =>
                          self.setState({
                            saving: false,
                            autoSaving: false,
                            lastSavedOn: `${lastSavePrefix}${moment(
                              self.props.local.editorLastSavedOn
                            ).fromNow()}`
                          })
                        )
                        .catch(() =>
                          self.setState({ saving: false, autoSaving: false })
                        )
                  );
                }
              }
            }

            if (self.props.local.editorLastSavedOn > 0) {
              self.setState({
                lastSavedOn: `${lastSavePrefix}${moment(
                  self.props.local.editorLastSavedOn
                ).fromNow()}`
              });
            }
          }, 1800);
        }
      );
    }

    stopAutoSaver() {
      this.autoSaver && clearInterval(this.autoSaver);
    }

    saveItinerary(silent = false) {
      return new Promise((resolve, reject) => {
        const {
          itinerary,
          match: {
            params: { id }
          }
        } = this.props;
        this.setState(
          {
            saving: true
          },

          () => {
            !silent && controllers.setGlobalLoader("Saving...", false);
            ListingServices.Save("itinerary", id, itinerary)
              .then(response => {
                this.setState(
                  {
                    saving: silent
                  },

                  () => {
                    controllers.marketing.setCurrentPackage(
                      Object.assign(this.props.local.editorListingSnapshot, {
                        package: response
                      })
                    );

                    controllers.setEditorSaved();
                    !silent &&
                      setTimeout(() => {
                        !silent && controllers.setGlobalSuccess("Saved", true);
                        setTimeout(() => {
                          !silent && controllers.clearGlobalMessage();
                          resolve(true);
                        }, 1700);
                      }, 700);
                    silent && setTimeout(() => resolve(true), 300);
                  }
                );
              })
              .catch(err => {
                err = (err.data && err.data.message) || err.message;
                !silent && controllers.setGlobalError(err, true);
                reject(err);
              });
          }
        );
      });
    }

    onPublish() {
      this.setState({
        isPublishDialogOpen: true
      });
    }

    onTogglePublish() {
      this.setState({
        isPublishDialogOpen: false
      });
    }

    componentDidMount() {
      this.loadItinerary();
    }

    componentWillReceiveProps(newProps) {
      const newListingId = newProps.match.params.id;
      const oldListingId = this.props.match.params.id;
      if (newListingId !== oldListingId) {
        // Detected Change
        this.loadItinerary(newProps);
      }
    }

    componentWillUnmount() {
      this.stopAutoSaver();
      controllers.clearGlobalMessage();
    }

    handleSaveHotKey(e) {
      e.preventDefault();
      this.saveItinerary(false);
    }

    handlers = {
      SAVE: this.handleSaveHotKey.bind(this)
    };

    keyMap = {
      SAVE: "command+s"
    };

    render() {
      const { itinerary, local, match } = this.props;
      const {
        isDayEditorOpen,
        currentDayEditorMode,
        dayEditorIndex,
        autoSaving,
        lastSavedOn,
        isPublishDialogOpen
      } = this.state;

      // Calculate save status
      let saveStatus = "";

      if (autoSaving === true) {
        saveStatus = "Auto saving...";
      } else {
        if (lastSavedOn) {
          saveStatus = lastSavedOn;
        }
      }

      return (
        <React.Fragment>
          <HideTawkTo />
          <HotKeys keyMap={this.keyMap} handlers={this.handlers}>
            <Fade duration={300}>
              <Helmet>
                <title>{this.props.t("Itinerary - Tripbonder.com")}</title>
              </Helmet>
              <div className="container-fluid trip-setup">
                <div className="row position-relative">
                  <div className="col-12 pr-md-5">
                    <p
                      className="lead"
                      style={{
                        color: "#42939D",
                        fontSize: 15,
                        fontWeight: 500
                      }}
                    >
                      {this.props.t("Itenirary: Step 1 of 3")}
                    </p>
                    <h3>
                      {this.props.t("Create your overall trip information")}
                    </h3>
                    <p>
                      {this.props.t(
                        "This will appear as your main product on featured trips, search result and trip detail banner"
                      )}
                    </p>
                  </div>
                  <div
                    className="balloon d-none d-md-block"
                    style={{
                      backgroundImage: `url('${require("../../assets/images/balloon.png")}')`
                    }}
                  ></div>
                </div>
                <StatusAlert
                  statusKey={itinerary._status}
                  listingStartDate={
                    local.editorListingSnapshot &&
                    local.editorListingSnapshot.listingStartDate
                  }
                  listingEndDate={
                    local.editorListingSnapshot &&
                    local.editorListingSnapshot.listingEndDate
                  }
                />

                {local.editorListingSnapshot &&
                local.editorListingSnapshot.class === "private" ? (
                  <PrivatePackageAlert id={local.editorListingSnapshot._id} />
                ) : null}
                <div
                  className="accordion indicator-plus-before round-indicator"
                  id="accordionH"
                  aria-multiselectable="true"
                >
                  <div className="card m-b-0">
                    <div
                      className="card-header text-center mb-2"
                      role="tab"
                      id="headingOneH"
                      data-target="#collapseOneH"
                      data-toggle="collapse"
                      data-parent="#accordionH"
                      aria-expanded="false"
                      aria-controls="collapseOneH"
                    >
                      <span className="card-title">
                        {this.props.t("Basic Information")}
                      </span>
                    </div>
                    <div
                      className="collapse show"
                      id="collapseOneH"
                      role="tabpanel"
                      aria-labelledby="headingOneH"
                    >
                      <div className="card-body p-0 p-sm-2">
                        <div className="form-row">
                          <div className="form-group col-12 col-lg-6">
                            <label
                              htmlFor="trip-title"
                              className="header d-inline-flex align-items-center mb-2"
                            >
                              {this.props.t("TRIP TITLE")}
                              <button
                                id="title-popover"
                                className="hint-btn"
                                type="button"
                              >
                                {"?"}
                              </button>
                              <Popover
                                trigger="legacy"
                                placement="right"
                                isOpen={this.getIsHintOpen("title")}
                                target="title-popover"
                                toggle={() => this.toggleIsHintOpen("title")}
                              >
                                <PopoverHeader>
                                  {this.props.t("Points to consider")}
                                </PopoverHeader>
                                <PopoverBody>
                                  <ol>
                                    <li>
                                      {this.props.t(
                                        "Catchy and attractive title"
                                      )}
                                    </li>
                                    <li>
                                      {this.props.t(
                                        "Must include the name of location"
                                      )}
                                    </li>
                                    <li>
                                      {this.props.t(
                                        "Try to keep the title short and precise"
                                      )}
                                    </li>
                                  </ol>
                                </PopoverBody>
                                <PopoverHeader>
                                  {this.props.t("Examples")}
                                </PopoverHeader>
                                <PopoverBody>
                                  <ol>
                                    <li>{this.props.t("Trip to Bali")}</li>
                                    <li>
                                      {this.props.t(
                                        "Mesmerising Thailand trip"
                                      )}
                                    </li>
                                  </ol>
                                </PopoverBody>
                              </Popover>
                            </label>
                            <input
                              readOnly={itinerary._status !== "draft"}
                              value={itinerary.title}
                              ref={ref => (this.titleInputRef = ref)}
                              onChange={e =>
                                controller.itinerary.updateItinerary(
                                  "title",
                                  e.target.value
                                )
                              }
                              type="text"
                              className="form-control"
                              id="trip-title"
                              placeholder={this.props.t(
                                "e.g. Trip to Hong Kong"
                              )}
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-12 col-lg-4">
                            <label htmlFor="trip-theme" className="header">
                              {this.props.t("TRIP THEME")}
                            </label>
                            <select
                              disabled={itinerary._status !== "draft"}
                              value={itinerary.theme}
                              onChange={e =>
                                controller.itinerary.updateItinerary(
                                  "theme",
                                  e.target.value
                                )
                              }
                              id="trip-theme"
                              className="form-control"
                            >
                              <option>{this.props.t("Select a theme")}</option>
                              {local.filterSearchCategoryMaster.map(
                                (item, index) => (
                                  <option key={index} value={item.value}>
                                    {item.label}
                                  </option>
                                )
                              )}
                            </select>
                          </div>
                          <div className="form-group col-12 col-lg-4">
                            <label htmlFor="trip-location" className="header">
                              {this.props.t(
                                "TRIP LOCATION (REGION/COUNTRY/CITY)"
                              )}
                            </label>
                            <LocationSearchInput
                              readOnly={itinerary._status !== "draft"}
                              id="trip-location"
                              value={itinerary.location}
                              geoLocation={itinerary.geoLocation}
                              onChange={location =>
                                controller.itinerary.updateItineraryLocation(
                                  location
                                )
                              }
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-12 col-lg-8">
                            <label htmlFor="tripDesc" className="header">
                              {this.props.t("TRIP DESCRIPTION")}
                              <button
                                id="description-popover"
                                className="hint-btn"
                                type="button"
                              >
                                {"?"}
                              </button>
                              <Popover
                                trigger="legacy"
                                placement="right"
                                isOpen={this.getIsHintOpen("description")}
                                target="description-popover"
                                toggle={() =>
                                  this.toggleIsHintOpen("description")
                                }
                              >
                                <PopoverHeader>
                                  {this.props.t("Points to consider")}
                                </PopoverHeader>
                                <PopoverBody>
                                  <ol>
                                    <li>
                                      {this.props.t(
                                        "Should provide a brief description about the package(maximum 6 to 8 sentences)"
                                      )}
                                    </li>
                                    <li>
                                      {this.props.t(
                                        "Should not display activity details of the package here"
                                      )}
                                    </li>
                                  </ol>
                                </PopoverBody>
                                <PopoverHeader>
                                  {this.props.t("Examples")}
                                </PopoverHeader>
                                <PopoverBody>
                                  <p>
                                    {this.props.t(
                                      "Munnar is a hill station situated above 1600 meter above sea-level in Western Ghats. The name derived because of the confluence of three rivers, Madhurappuzha, Nallathanni and Kundali. Munnar is situated in Kannan Devam village in Devikulam Taluk, Idukki District."
                                    )}
                                  </p>
                                </PopoverBody>
                              </Popover>
                            </label>
                            <textarea
                              readOnly={itinerary._status !== "draft"}
                              value={itinerary.description}
                              onChange={e =>
                                controller.itinerary.updateItinerary(
                                  "description",
                                  e.target.value
                                )
                              }
                              className="form-control"
                              id="tripDesc"
                              rows="5"
                              placeholder={this.props.t("Trip description")}
                            ></textarea>
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-12 col-lg-8 form-group">
                            <label
                              htmlFor="max-no-of-people"
                              className="header"
                            >
                              {this.props.t("MAXIMUM NO. OF PEOPLE")}
                            </label>
                            <input
                              readOnly={itinerary._status !== "draft"}
                              value={itinerary.maxTravellers}
                              onChange={e =>
                                controller.itinerary.updateItinerary(
                                  "maxTravellers",
                                  e.target.value
                                )
                              }
                              type="number"
                              className="form-control"
                              id="max-no-of-people"
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-12 col-lg-8">
                            <label className="header">
                              {this.props.t("ADDITIONAL INFORMATION REQUIRED")}
                            </label>
                            <div className="form-check">
                              {local.addtinfosMaster
                                .filter(
                                  i =>
                                    [
                                      "flightnumber",
                                      "time-of-flight",
                                      "pickup-time",
                                      "contact-person-name",
                                      "contactnumber",
                                      "contact-email",
                                      "passport-number",
                                      "passport-issue-country"
                                    ].indexOf(i.value) > -1
                                )
                                .map((addtinfo, index) => (
                                  <React.Fragment key={index}>
                                    <label
                                      className="form-check-label pr-5"
                                      htmlFor={`check-box-${addtinfo.value}`}
                                    >
                                      <input
                                        disabled={itinerary._status !== "draft"}
                                        key={addtinfo.value}
                                        type="checkbox"
                                        className="form-check-input"
                                        id={`check-box-${addtinfo.value}`}
                                        checked={
                                          itinerary.addtinfo &&
                                          itinerary.addtinfo.indexOf(
                                            addtinfo.value
                                          ) > -1
                                        }
                                        onChange={e =>
                                          controller.itinerary.toggleInfo(
                                            "addtinfo",
                                            addtinfo.value
                                          )
                                        }
                                      />

                                      {this.props.t(addtinfo.label)}
                                    </label>
                                  </React.Fragment>
                                ))}
                            </div>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-12 col-lg-8">
                            <label htmlFor="otherInfo" className="header">
                              {this.props.t("OTHER INFORMATION")}
                              <button
                                id="other-information-popover"
                                className="hint-btn"
                                type="button"
                              >
                                {"?"}
                              </button>
                              <Popover
                                trigger="legacy"
                                placement="right"
                                isOpen={this.getIsHintOpen("other-information")}
                                target="other-information-popover"
                                toggle={() =>
                                  this.toggleIsHintOpen("other-information")
                                }
                              >
                                <PopoverHeader>
                                  {this.props.t("Points to consider")}
                                </PopoverHeader>
                                <PopoverBody>
                                  <ol>
                                    <li>
                                      {this.props.t(
                                        "You can include things like additional services and tips for the passengers"
                                      )}
                                    </li>
                                    <li>
                                      {this.props.t("Try to write in points")}
                                    </li>
                                  </ol>
                                </PopoverBody>
                                <PopoverHeader>
                                  {this.props.t("Examples")}
                                </PopoverHeader>
                                <PopoverBody>
                                  <ol>
                                    <li>
                                      {this.props.t(
                                        "Children aged 0-3 can enter the park for free"
                                      )}
                                    </li>
                                    <li>
                                      {this.props.t(
                                        "In case the activity gets canceled due to inclement weather, you will be given the option to reschedule the tour or opt for a full refund"
                                      )}
                                    </li>
                                    <li>
                                      {this.props.t(
                                        "This trip is not wheelchair and stroller accessible"
                                      )}
                                    </li>
                                  </ol>
                                </PopoverBody>
                              </Popover>
                            </label>
                            <textarea
                              readOnly={itinerary._status !== "draft"}
                              value={itinerary.otherInformation}
                              onChange={e =>
                                controller.itinerary.updateItinerary(
                                  "otherInformation",
                                  e.target.value
                                )
                              }
                              className="form-control"
                              id="otherInfo"
                              rows="5"
                              placeholder={this.props.t("(Required)")}
                            ></textarea>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="col-12 col-lg-8 form-group">
                            <label className="header d-block mb-3">
                              {this.props.t("TAGS")}
                              <button
                                id="tags"
                                className="hint-btn"
                                type="button"
                              >
                                {"?"}
                              </button>
                              <Popover
                                trigger="legacy"
                                placement="right"
                                isOpen={this.getIsHintOpen("tags")}
                                target="tags"
                                toggle={() => this.toggleIsHintOpen("tags")}
                              >
                                <PopoverHeader>
                                  {this.props.t("Points to consider")}
                                </PopoverHeader>
                                <PopoverBody>
                                  <ol>
                                    <li>
                                      {this.props.t(
                                        "Tag should not be too long"
                                      )}
                                    </li>
                                    <li>
                                      {this.props.t("Do not begin with #")}
                                    </li>
                                  </ol>
                                </PopoverBody>
                                <PopoverHeader>
                                  {this.props.t("Examples")}
                                </PopoverHeader>
                                <PopoverBody>
                                  <ol>
                                    <li>{this.props.t("nature")}</li>
                                    <li>{this.props.t("trekking")}</li>
                                    <li>{this.props.t("cruise")}</li>
                                  </ol>
                                </PopoverBody>
                              </Popover>
                            </label>
                            <ChipInput
                              fullWidthInput={true}
                              fullWidth={true}
                              alwaysShowPlaceholder={true}
                              disabled={itinerary._status !== "draft"}
                              value={itinerary.tags}
                              onAdd={chip =>
                                controller.itinerary.updateItinerary("tags", [
                                  ...itinerary.tags,
                                  chip.toLowerCase().replace("#", "")
                                ])
                              }
                              onDelete={(chip, index) =>
                                controller.itinerary.updateItinerary(
                                  "tags",
                                  itinerary.tags.filter(
                                    (it, _index) => _index !== index
                                  )
                                )
                              }
                              placeholder={this.props.t("Add tags...")}
                              newChipKeys={["Tab", "Enter"]}
                              newChipKeyCodes={[32]}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <label className="header d-block mb-3">
                              {this.props.t("PRICING (MULTI-TIER)")}
                            </label>
                          </div>
                        </div>

                        <div className="form-row tier-pricing">
                          <div className="col-12 col-lg-8 table-responsive">
                            <table className="table table-sm">
                              <tbody>
                                <tr>
                                  <th
                                    style={{ backgroundColor: "#e9ecef" }}
                                    rowSpan={3}
                                    className="align-middle adult-th"
                                  >
                                    <label className="m-0 font-13">
                                      {this.props.t("Per Adult")}
                                    </label>
                                  </th>
                                  <td className="align-middle">
                                    <label className="m-0 font-13">
                                      {this.props.t("1 Adult")}
                                      <sup>{this.props.t("*")}</sup>
                                    </label>
                                  </td>
                                  <td colSpan={2}>
                                    <div className="input-group flex-nowrap">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text mb-0 py-0 font-13"
                                          id="addon-wrapping"
                                        >
                                          {this.props.t("USD / Person")}
                                        </span>
                                      </div>
                                      <input
                                        readOnly={itinerary._status !== "draft"}
                                        value={itinerary.perAdultPrice}
                                        onChange={e =>
                                          controller.itinerary.updateItinerary(
                                            "perAdultPrice",
                                            e.target.value
                                          )
                                        }
                                        type="number"
                                        step="0.01"
                                        className="form-control"
                                        placeholder={this.props.t(
                                          "Price per person"
                                        )}
                                        aria-describedby="addon-wrapping"
                                      />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle">
                                    <label
                                      className="m-0 font-13"
                                      style={{
                                        opacity:
                                          parseFloat(
                                            itinerary.perAdultUpto5Price
                                          ) < 1 ||
                                          isNaN(
                                            parseFloat(
                                              itinerary.perAdultUpto5Price
                                            )
                                          )
                                            ? 0.4
                                            : 1
                                      }}
                                    >
                                      {this.props.t("2 to 5 Adults")}{" "}
                                      {parseFloat(
                                        itinerary.perAdultUpto5Price
                                      ) < 1 ||
                                      isNaN(
                                        parseFloat(itinerary.perAdultUpto5Price)
                                      )
                                        ? this.props.t("(Disabled)")
                                        : null}
                                    </label>
                                  </td>
                                  <td colSpan={2}>
                                    <div className="input-group flex-nowrap">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text mb-0 py-0 font-13"
                                          id="addon-wrapping"
                                        >
                                          {this.props.t("USD / Person")}
                                        </span>
                                      </div>
                                      <input
                                        readOnly={itinerary._status !== "draft"}
                                        value={itinerary.perAdultUpto5Price}
                                        onChange={e =>
                                          controller.itinerary.updateItinerary(
                                            "perAdultUpto5Price",
                                            e.target.value
                                          )
                                        }
                                        type="number"
                                        step="0.01"
                                        className="form-control"
                                        placeholder={this.props.t(
                                          "Price per person"
                                        )}
                                        aria-describedby="addon-wrapping"
                                      />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="align-middle">
                                    <label
                                      className="m-0 font-13"
                                      style={{
                                        opacity:
                                          parseFloat(
                                            itinerary.perAdultPriceMax
                                          ) < 1 ||
                                          isNaN(
                                            parseFloat(
                                              itinerary.perAdultPriceMax
                                            )
                                          )
                                            ? 0.4
                                            : 1
                                      }}
                                    >
                                      {this.props.t("More than 5 Adults")}{" "}
                                      {parseFloat(itinerary.perAdultPriceMax) <
                                        1 ||
                                      isNaN(
                                        parseFloat(itinerary.perAdultPriceMax)
                                      )
                                        ? this.props.t("(Disabled)")
                                        : null}
                                    </label>
                                  </td>
                                  <td colSpan={2}>
                                    <div className="input-group flex-nowrap">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text mb-0 py-0 font-13"
                                          id="addon-wrapping"
                                        >
                                          {this.props.t("USD / Person")}
                                        </span>
                                      </div>
                                      <input
                                        readOnly={itinerary._status !== "draft"}
                                        value={itinerary.perAdultPriceMax}
                                        onChange={e =>
                                          controller.itinerary.updateItinerary(
                                            "perAdultPriceMax",
                                            e.target.value
                                          )
                                        }
                                        type="number"
                                        step="0.01"
                                        className="form-control"
                                        placeholder={this.props.t(
                                          "Price per person"
                                        )}
                                        aria-describedby="addon-wrapping"
                                      />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td height="20" colSpan={3}></td>
                                </tr>
                                <tr>
                                  <th
                                    style={{ backgroundColor: "#e9ecef" }}
                                    className="align-middle child-th"
                                    rowSpan={2}
                                  >
                                    <label className="m-0 font-13">
                                      {this.props.t("Per Child")}
                                    </label>
                                  </th>
                                  <td className="align-middle m-0 font-13">
                                    {this.props.t("Without Bed")}
                                  </td>
                                  <td>
                                    <div className="input-group flex-nowrap">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text mb-0 py-0 font-13"
                                          id="addon-wrapping"
                                        >
                                          {this.props.t("USD / Child")}
                                        </span>
                                      </div>
                                      <input
                                        readOnly={itinerary._status !== "draft"}
                                        value={itinerary.perChildPrice}
                                        onChange={e =>
                                          controller.itinerary.updateItinerary(
                                            "perChildPrice",
                                            e.target.value
                                          )
                                        }
                                        type="number"
                                        step="0.01"
                                        className="form-control"
                                        placeholder={this.props.t(
                                          "Price per child"
                                        )}
                                        aria-describedby="addon-wrapping"
                                      />
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    className="align-middle m-0 font-13"
                                    style={{
                                      opacity:
                                        parseFloat(
                                          itinerary.perChildPriceWithBed
                                        ) < 1 ||
                                        isNaN(
                                          parseFloat(
                                            itinerary.perChildPriceWithBed
                                          )
                                        )
                                          ? 0.4
                                          : 1
                                    }}
                                  >
                                    {this.props.t("With Bed")}{" "}
                                    {parseFloat(
                                      itinerary.perChildPriceWithBed
                                    ) < 1 ||
                                    isNaN(
                                      parseFloat(itinerary.perChildPriceWithBed)
                                    )
                                      ? this.props.t("(Disabled)")
                                      : null}
                                  </td>
                                  <td>
                                    <div className="input-group flex-nowrap">
                                      <div className="input-group-prepend">
                                        <span
                                          className="input-group-text mb-0 py-0 font-13"
                                          id="addon-wrapping"
                                        >
                                          {this.props.t("USD / Child")}
                                        </span>
                                      </div>
                                      <input
                                        readOnly={itinerary._status !== "draft"}
                                        value={itinerary.perChildPriceWithBed}
                                        onChange={e =>
                                          controller.itinerary.updateItinerary(
                                            "perChildPriceWithBed",
                                            e.target.value
                                          )
                                        }
                                        type="number"
                                        step="0.01"
                                        className="form-control"
                                        placeholder={this.props.t(
                                          "Price per child"
                                        )}
                                        aria-describedby="addon-wrapping"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="form-row">
                          <div className="form-group col-12">
                            <label className="header">
                              {this.props.t("Minimum Travellers")}
                            </label>
                            <div className="form-check">
                              <label
                                className="form-check-label pr-5"
                                htmlFor={`check-box-min-2-pax`}
                              >
                                <input
                                  disabled={itinerary._status !== "draft"}
                                  type="checkbox"
                                  className="form-check-input"
                                  id={`check-box-min-2-pax`}
                                  checked={itinerary.min2Pax || false}
                                  onChange={e =>
                                    controller.itinerary.updateItinerary(
                                      "min2Pax",
                                      e.target.checked
                                    )
                                  }
                                />

                                {this.props.t("Start with 2 Pax")}
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* <div className="form-row">
                          <div className="col-12 col-lg-4 form-group">
                            <label className="header">
                              {this.props.t("PRICE (ADULTS)")}
                            </label>
                            <div className="input-group flex-nowrap">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text mb-0 py-0"
                                  id="addon-wrapping"
                                >
                                  {this.props.t("USD")}
                                </span>
                              </div>
                              <input
                                readOnly={itinerary._status !== "draft"}
                                value={itinerary.perAdultPrice}
                                onChange={e =>
                                  controller.itinerary.updateItinerary(
                                    "perAdultPrice",
                                    e.target.value
                                  )
                                }
                                type="text"
                                className="form-control"
                                placeholder={this.props.t("Price per person")}
                                aria-describedby="addon-wrapping"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-lg-4 form-group">
                            <label className=" header">
                              {this.props.t("PRICE (CHILDREN)")}
                            </label>
                            <div className="input-group flex-nowrap">
                              <div className="input-group-prepend">
                                <span
                                  className="input-group-text mb-0 py-0"
                                  id="addon-wrapping"
                                >
                                  {this.props.t("USD")}
                                </span>
                              </div>
                              <input
                                readOnly={itinerary._status !== "draft"}
                                value={itinerary.perChildPrice}
                                onChange={e =>
                                  controller.itinerary.updateItinerary(
                                    "perChildPrice",
                                    e.target.value
                                  )
                                }
                                type="text"
                                className="form-control"
                                placeholder={this.props.t("Price per person")}
                                aria-describedby="addon-wrapping"
                              />
                            </div>
                          </div>
                         </div> */}
                      </div>
                    </div>
                    <div
                      className="card-header collapsed text-center mb-2"
                      role="tab"
                      id="headingTwoH"
                      data-target="#collapseTwoH"
                      data-toggle="collapse"
                      data-parent="#accordionH"
                      aria-expanded="false"
                      aria-controls="collapseTwoH"
                    >
                      <span className="card-title">
                        {this.props.t("Cover Art & Highlights")}
                      </span>
                    </div>
                    <div
                      className="collapse"
                      id="collapseTwoH"
                      role="tabpanel"
                      aria-labelledby="headingTwoH"
                    >
                      <div className="card-body p-0">
                        <div className="row cover-art-wrapper p-sm-2 p-0">
                          <div className="col-12 col-xl-4 title-wrapper">
                            <span className="card-title head mb-3">
                              {this.props.t("Cover Art")}
                              <button
                                id="cover-art-popover"
                                className="hint-btn"
                                type="button"
                              >
                                {"?"}
                              </button>
                              <Popover
                                trigger="legacy"
                                placement="right"
                                isOpen={this.getIsHintOpen("cover-art")}
                                target="cover-art-popover"
                                toggle={() =>
                                  this.toggleIsHintOpen("cover-art")
                                }
                              >
                                <PopoverHeader>
                                  {this.props.t("Points to consider")}
                                </PopoverHeader>
                                <PopoverBody>
                                  <ol>
                                    <li>
                                      {this.props.t(
                                        "Should Upload images less than 2mb"
                                      )}
                                    </li>
                                    <li>
                                      {this.props.t(
                                        "Should not upload blur images"
                                      )}
                                    </li>
                                    <li>
                                      {this.props.t(
                                        "Cover art should give an idea about the package destinations"
                                      )}
                                    </li>
                                  </ol>
                                </PopoverBody>
                                <PopoverHeader>
                                  {this.props.t("Example")}
                                </PopoverHeader>
                                <PopoverBody>
                                  <div className="text-center">
                                    <img
                                      height={150}
                                      src={require("../../assets/images/bali-example.jpg")}
                                      alt="history"
                                    />
                                  </div>
                                </PopoverBody>
                              </Popover>
                            </span>
                            <p>
                              {this.props.t(
                                "A beautiful and high resolution cover art provides the initial temptation for a traveller. So why not make it tempting enough?"
                              )}
                            </p>
                          </div>
                          <div className="col-12 col-xl-4">
                            <ImagePlaceholder
                              disabled={itinerary._status !== "draft"}
                              message={this.props.t(
                                "Cover image cannot be larger than 2MB"
                              )}
                              value={controller.resolveAssetAddr(
                                itinerary.coverImages[0]
                              )}
                              height="260px"
                              onClick={() => this.addCoverImage(0)}
                              onRemove={() =>
                                controller.itinerary.replaceItineraryBanner(
                                  0,
                                  ""
                                )
                              }
                            />
                          </div>
                          <div className="col-12 col-xl-4 pt-3 pt-xl-0">
                            <div className="row">
                              <div className="col-6">
                                <div className="pb-4">
                                  <ImagePlaceholder
                                    disabled={itinerary._status !== "draft"}
                                    value={controller.resolveAssetAddr(
                                      itinerary.coverImages[1]
                                    )}
                                    message={this.props.t(
                                      "Cover image cannot be larger than 2MB"
                                    )}
                                    onClick={() => this.addCoverImage(1)}
                                    height="120px"
                                    onRemove={() =>
                                      controller.itinerary.replaceItineraryBanner(
                                        1,
                                        ""
                                      )
                                    }
                                  />
                                </div>
                                <div>
                                  <ImagePlaceholder
                                    disabled={itinerary._status !== "draft"}
                                    value={controller.resolveAssetAddr(
                                      itinerary.coverImages[2]
                                    )}
                                    message={this.props.t(
                                      "Cover image cannot be larger than 2MB"
                                    )}
                                    onClick={() => this.addCoverImage(2)}
                                    height="120px"
                                    onRemove={() =>
                                      controller.itinerary.replaceItineraryBanner(
                                        2,
                                        ""
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="pb-4">
                                  <ImagePlaceholder
                                    disabled={itinerary._status !== "draft"}
                                    value={controller.resolveAssetAddr(
                                      itinerary.coverImages[3]
                                    )}
                                    message={this.props.t(
                                      "Cover image cannot be larger than 2MB"
                                    )}
                                    onClick={() => this.addCoverImage(3)}
                                    height="120px"
                                    onRemove={() =>
                                      controller.itinerary.replaceItineraryBanner(
                                        3,
                                        ""
                                      )
                                    }
                                  />
                                </div>
                                <div>
                                  <ImagePlaceholder
                                    disabled={itinerary._status !== "draft"}
                                    value={controller.resolveAssetAddr(
                                      itinerary.coverImages[4]
                                    )}
                                    message={this.props.t(
                                      "Cover image cannot be larger than 2MB"
                                    )}
                                    onClick={() => this.addCoverImage(4)}
                                    height="120px"
                                    onRemove={() =>
                                      controller.itinerary.replaceItineraryBanner(
                                        4,
                                        ""
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row pt-2 p-sm-2 p-0">
                          <div className="col-12 title-wrapper">
                            <span className="mb-3">
                              {this.props.t("Highlights")}
                            </span>
                            <p>
                              {this.props.t(
                                "Add some highlights to showcase what your trip offers"
                              )}
                            </p>
                          </div>
                          <div className="col-12">
                            {itinerary.highlights.map((highlight, index) => (
                              <Highlight
                                itinerary={itinerary}
                                key={index}
                                index={index}
                                item={highlight}
                                onDelete={index =>
                                  this.setDeleteContext("highlight", index)
                                }
                              />
                            ))}
                          </div>
                        </div>
                        {itinerary._status === "draft" ? (
                          <div className="row my-2">
                            <div className="col-12 text-center create-package-add-button pb-2">
                              <button
                                onClick={() =>
                                  controller.itinerary.addHighlight()
                                }
                                className="circle btn btn-link btn-sm"
                              >
                                <i className="fas fa-plus"></i>
                              </button>
                              <small>{this.props.t("Add Highlight")}</small>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div
                      className="card-header collapsed text-center mb-2"
                      role="tab"
                      id="headingThreeH"
                      data-target="#collapseThreeH"
                      data-toggle="collapse"
                      data-parent="#accordionH"
                      aria-expanded="false"
                      aria-controls="collapseThreeH"
                    >
                      <span className="card-title">
                        {this.props.t("Days & Activities")}
                      </span>
                    </div>
                    <div
                      className="collapse"
                      id="collapseThreeH"
                      role="tabpanel"
                      aria-labelledby="headingThreeH"
                    >
                      <div className="card-body">
                        {itinerary.days.length < 1 ? (
                          <div className="row">
                            <div className="col-12 mt-2">
                              <div className="jumbotron">
                                <h4 className="display-4">
                                  {this.props.t("Days & Activities")}
                                </h4>
                                <p clas="lead">
                                  {this.props.t(
                                    "You haven't added any Days to this trip yet."
                                  )}
                                </p>
                                <hr className="my-1" />
                                <p>
                                  {this.props.t(
                                    "Use the Add Day button below to add days to the itinerary package."
                                  )}
                                </p>
                                <div className="lead">
                                  <button
                                    onClick={e => {
                                      controller.itinerary.addDay();
                                      this.openDayEditor(0);
                                    }}
                                    className="btn btn-primary btn-lg"
                                  >
                                    <i className="fas fa-plus-circle"></i>
                                    {this.props.t("Add Day")}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <React.Fragment>
                            <span className="card-title">
                              {this.props.t(
                                "Click on a day to edit activities and meal plans for that day"
                              )}
                            </span>
                            <div className="row">
                              <div className="col-12 text-center text-md-left">
                                {itinerary.days.map((day, index) => (
                                  <GridImagePlaceholder
                                    itinerary={itinerary}
                                    message={day.title}
                                    key={index}
                                    index={index}
                                    item={day}
                                    onDelete={index =>
                                      this.setDeleteContext("day", index)
                                    }
                                    onClick={index => this.openDayEditor(index)}
                                  />
                                ))}

                                {itinerary._status === "draft" ? (
                                  <div className="col-12 text-center create-package-add-button">
                                    <button
                                      onClick={e =>
                                        controller.itinerary.addDay()
                                      }
                                      className="circle btn btn-link btn-sm"
                                    >
                                      <i className="fas fa-plus"></i>
                                    </button>
                                    <small>{this.props.t("Add Day")}</small>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Fade>

            <div
              className="modal fade"
              id="deletePrompt"
              tabIndex="-1"
              role="dialog"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="title">
                      {this.props.t("Remove")}{" "}
                      <span className="text-capitalize">
                        {this.state.keyToDelete}
                      </span>
                    </h5>
                    <button
                      onClick={e => this.setDeleteContext("", -1)}
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">{this.props.t("\xD7")}</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {this.props.t("Are you sure want to delete this?")}
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      onClick={e => this.setDeleteContext("", -1)}
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      {this.props.t("Cancel")}
                    </button>
                    <button
                      type="button"
                      data-dismiss="modal"
                      onClick={this.proceedDelete}
                      className="btn btn-danger"
                    >
                      {this.props.t("Confirm")}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <BottomSlider
              onToggleView={() => this.closeDayEditor()}
              isOpen={isDayEditorOpen}
            >
              <DayEditor
                onClose={() => this.closeDayEditor()}
                currentTab={currentDayEditorMode}
                onTabChange={tab => this.switchDayEditorTab(tab)}
                onNextDay={() => {
                  if (this.state.dayEditorIndex + 1 < itinerary.days.length) {
                    this.setState({
                      dayEditorIndex: this.state.dayEditorIndex + 1
                    });
                  } else {
                    controller.setGlobalError(
                      "That's the last day of the trip",
                      true
                    );
                  }
                }}
                itinerary={itinerary}
                selectedDayIndex={dayEditorIndex}
                local={local}
              />
            </BottomSlider>
            <DraftControl
              isModified={local.editorLastSavedOn < local.editorLastModifiedOn}
              statusMessage={saveStatus}
              onSaveDraft={() => this.saveItinerary()}
              onPublish={() => this.onPublish()}
              onPreview={() => this.setState({ isPreviewOpen: true })}
              statusKey={itinerary._status}
              private={
                local.editorListingSnapshot &&
                local.editorListingSnapshot.class === "private"
              }
            />
          </HotKeys>
          <Modal
            isOpen={isPublishDialogOpen}
            toggle={this.onTogglePublish}
            size="xl"
            centered
            className="profile-prompt"
            keyboard={false}
            backdrop="static"
          >
            <PublishModal
              toggle={this.onTogglePublish}
              listingId={match.params.id}
              packageType="itinerary"
              package={itinerary}
            />
          </Modal>
          <Modal
            style={{ maxWidth: "100%" }}
            isOpen={this.state.isPreviewOpen}
            toggle={() =>
              this.setState({ isPreviewOpen: !this.state.isPreviewOpen })
            }
            scrollable={true}
            size={"lg"}
            className="ordered-preview"
          >
            <button
              className="closePreview"
              onClick={() => this.setState({ isPreviewOpen: false })}
            >
              <i className="fas fa-times fa-2x"></i>
            </button>
            <ModalBody id="sample-id" className="p-0">
              <TripViewer
                scrollableId="#sample-id"
                spyOffset={-1100}
                context={this.props.context}
                mode="preview"
              />
            </ModalBody>
          </Modal>
        </React.Fragment>
      );
    }
  }
);

const mapStateToProps = state => ({
  context: state.marketing.currentPackage,
  itinerary: state.itinerary,
  local: state.local
});

export default connect(mapStateToProps)(ItinerarySetup);
