let env;

//env = "development";
// env = "remote_dev";
// env = "staging";
env = "production";

export default () => {
  let config = {
    // Google Analytics ID
    GMAPS_KEY: "AIzaSyDRi-dfnVByN4grbE9-mDWmT2EawBJu-9w",
    GA_ID: "UA-154563365-1",
    STRIPE_API_KEY: "pk_test_PEgoxehryTNDA8UQQZFg7Hho",
    SERVER_BASE_ADDRS: "http://localhost:7860/",
    // ASSET_BASE_ADDRS:
    //   "https://tripbonder-dev-assets.s3.ap-south-1.amazonaws.com/",
    ASSET_BASE_ADDRS: "https://tripbonder.s3-ap-southeast-1.amazonaws.com/",
    activationKey: "tripbonder-dev"
  };

  switch (env) {
    case "remote_dev": {
      return Object.assign({}, config, {
        SERVER_BASE_ADDRS: "https://tripbonder-demo.skyslit.net/",
        ASSET_BASE_ADDRS:
          "https://tripbonder-dev-assets.s3.ap-south-1.amazonaws.com/",
        activationKey: "tripbonder-dev"
      });
    }
    case "staging": {
      return Object.assign({}, config, {
        GMAPS_KEY: "AIzaSyBAUA5GSdbrz_t6ScP-EYkO2cz8U8afLz8",
        SERVER_BASE_ADDRS: "http://tripbonder.io/",
        ASSET_BASE_ADDRS: "https://tripbonder.s3-ap-southeast-1.amazonaws.com/",
        activationKey: "tripbonder-stage"
      });
    }
    case "production": {
      return Object.assign({}, config, {
        GMAPS_KEY: "AIzaSyBAUA5GSdbrz_t6ScP-EYkO2cz8U8afLz8",
        GA_ID: "UA-129045759-1",
        STRIPE_API_KEY: "pk_live_HvXpvjdBFWw777FqWltv0rr8",
        SERVER_BASE_ADDRS: "/",
        ASSET_BASE_ADDRS: "https://tripbonder.s3-ap-southeast-1.amazonaws.com/",
        activationKey: "tripbonder-prod"
      });
    }
    case "development":
    default: {
      return config;
    }
  }
};
