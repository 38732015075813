import {
  ITINERARY_UPDATE,
  ITINERARY_ADD_HIGHLIGHT,
  ITINERARY_DELETE_HIGHLIGHT,
  ITINERARY_UPDATE_HIGHLIGHT,
  ITINERARY_ADD_DAY,
  ITINERARY_UPDATE_DAY,
  ITINERARY_DELETE_DAY,
  ITINERARY_ADD_ACTIVITY,
  ITINERARY_UPDATE_ACTIVITY,
  ITINERARY_REMOVE_ACTIVITY,
  ITINERARY_ADD_FOOD_ACTIVITY,
  ITINERARY_UPDATE_FOOD_ACTIVITY,
  ITINERARY_REMOVE_FOOD_ACTIVITY,
  ITINERARY_ADD_STAY_IMAGE,
  ITINERARY_UPDATE_STAY_IMAGE,
  ITINERARY_REMOVE_STAY_IMAGE,
  ITINERARY_SET,
  ITINERARY_UPDATE_LOC,
  ITINERARY_SPLICE_BANNER,
  ITINERARY_HIGHLIGHT_LOC,
  ITINERARY_TOGGLE_FEATURES,
  ITINERARY_UPDATE_ACTIVITY_LOC,
  ITINERARY_UPDATE_FOOD_ACTIVITY_LOC,
  ITINERARY_UPDATE_DAY_STAY_LOC,
  ITINERARY_TOGGLE_INFO
} from "../types";

const initialState = {
  _status: null,
  _id: null,
  title: "",
  description: "",
  location: "",
  geoLocation: null,
  city: null,
  country: null,
  theme: "",
  highlights: [],
  defaultCoverArtId: null,
  coverImages: ["", "", "", "", ""],
  days: [],
  maxTravellers: 2,
  perAdultPrice: 0,
  perChildPrice: 0,
  otherInformation: ""
};

const GetDefaultHour = category => {
  switch (category) {
    case "morning":
      return 4;
    case "afternoon":
      return 12;
    case "evening":
      return 16;
    case "breakfast":
      return 8;
    case "lunch":
      return 13;
    case "dinner":
      return 19;
    default:
      return 1;
  }
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ITINERARY_SET: {
      const { value, status } = action.payload;
      return Object.assign({}, initialState, value || {}, { _status: status });
    }
    case ITINERARY_UPDATE: {
      const { key, value } = action.payload;
      return Object.assign({}, state, { [key]: value });
    }
    case ITINERARY_UPDATE_LOC: {
      const { value } = action.payload;
      return Object.assign({}, state, {
        location: value.address,
        geoLocation: value.geoLocation,
        city: value.city,
        country: value.country
      });
    }
    case ITINERARY_HIGHLIGHT_LOC: {
      const { value, index } = action.payload;
      return Object.assign({}, state, {
        highlights: state.highlights.map((item, mapIndex) => {
          if (index === mapIndex) {
            return Object.assign({}, item, {
              location: value.address,
              geoLocation: value.geoLocation
            });
          }
          return item;
        })
      });
    }
    case ITINERARY_SPLICE_BANNER: {
      const { index, value } = action.payload;
      return Object.assign({}, state, {
        coverImages: state.coverImages.map((image, mapIndex) => {
          if (mapIndex === index) {
            return value;
          }
          return image;
        })
      });
    }
    case ITINERARY_DELETE_HIGHLIGHT: {
      const { indexToDelete } = action.payload;
      return Object.assign({}, state, {
        highlights: state.highlights.filter(
          (highlight, index) => index !== indexToDelete
        )
      });
    }
    case ITINERARY_UPDATE_HIGHLIGHT: {
      const { indexToUpdate, key, value } = action.payload;
      return Object.assign({}, state, {
        highlights: state.highlights.map((highlight, index) => {
          if (index === indexToUpdate) {
            return Object.assign({}, highlight, {
              [key]: value
            });
          } else {
            return highlight;
          }
        })
      });
    }
    case ITINERARY_ADD_HIGHLIGHT: {
      return Object.assign({}, state, {
        highlights: [
          ...state.highlights,
          {
            imageId: null,
            location: "",
            geoLocation: null,
            description: ""
          }
        ]
      });
    }
    case ITINERARY_ADD_DAY: {
      return Object.assign({}, state, {
        days: [
          ...state.days,
          {
            title: `Day ${state.days.length + 1}`,
            morningActivities: [],
            afternoonActivities: [],
            eveningActivities: [],
            breakfastActivities: [],
            lunchActivities: [],
            dinnerActivities: [],
            stayLocation: "",
            stayGeoLocation: null,
            stayDiscription: "",
            stayImages: [],
            internetFeatures: [],
            amenities: [],
            access: []
          }
        ]
      });
    }
    case ITINERARY_UPDATE_DAY: {
      const { indexToUpdate, key, value } = action.payload;
      return Object.assign({}, state, {
        days: state.days.map((day, index) => {
          if (index === indexToUpdate) {
            return Object.assign({}, day, {
              [key]: value
            });
          } else {
            return day;
          }
        })
      });
    }
    case ITINERARY_UPDATE_DAY_STAY_LOC: {
      const { indexToUpdate, value } = action.payload;
      return Object.assign({}, state, {
        days: state.days.map((day, index) => {
          if (index === indexToUpdate) {
            return Object.assign({}, day, {
              stayLocation: value.address,
              stayGeoLocation: value.geoLocation
            });
          } else {
            return day;
          }
        })
      });
    }
    case ITINERARY_TOGGLE_FEATURES: {
      const { indexToUpdate, key, value } = action.payload;
      return Object.assign({}, state, {
        days: state.days.map((day, index) => {
          if (index === indexToUpdate) {
            if (day[key].indexOf(value) > -1) {
              return Object.assign({}, day, {
                [key]: day[key].filter(v => v !== value)
              });
            } else {
              return Object.assign({}, day, {
                [key]: [...day[key], value]
              });
            }
          } else {
            return day;
          }
        })
      });
    }
    case ITINERARY_DELETE_DAY: {
      const { indexToDelete } = action.payload;
      return Object.assign({}, state, {
        days: state.days.filter((day, index) => index !== indexToDelete)
      });
    }

    case ITINERARY_ADD_ACTIVITY: {
      const { dayIndex, category } = action.payload;
      return Object.assign({}, state, {
        days: state.days.map((day, index) => {
          if (index === dayIndex) {
            switch (category) {
              case "morning": {
                return Object.assign({}, day, {
                  morningActivities: [
                    ...day.morningActivities,
                    {
                      imageId: null,
                      title: "",
                      location: "",
                      geoLocation: null,
                      approxDuration: 1,
                      hours: GetDefaultHour(category),
                      description: "",
                      tips: "",
                      category
                    }
                  ]
                });
              }
              case "afternoon": {
                return Object.assign({}, day, {
                  afternoonActivities: [
                    ...day.afternoonActivities,
                    {
                      imageId: null,
                      title: "",
                      location: "",
                      geoLocation: null,
                      time: "",
                      hours: GetDefaultHour(category),
                      description: "",
                      tips: "",
                      category
                    }
                  ]
                });
              }
              case "evening": {
                return Object.assign({}, day, {
                  eveningActivities: [
                    ...day.eveningActivities,
                    {
                      imageId: null,
                      title: "",
                      location: "",
                      geoLocation: null,
                      time: "",
                      hours: GetDefaultHour(category),
                      description: "",
                      tips: "",
                      category
                    }
                  ]
                });
              }
              default: {
                break;
              }
            }
          }

          return day;
        })
      });
    }

    case ITINERARY_UPDATE_ACTIVITY: {
      const { dayIndex, indexToUpdate, category, key, value } = action.payload;
      return Object.assign({}, state, {
        days: state.days.map((day, index) => {
          if (index === dayIndex) {
            return Object.assign({}, day, {
              [`${category}Activities`]: day[`${category}Activities`].map(
                (activity, index) => {
                  if (index === indexToUpdate) {
                    return Object.assign({}, activity, {
                      [key]: value
                    });
                  }
                  return activity;
                }
              )
            });
          }
          return day;
        })
      });
    }

    case ITINERARY_UPDATE_ACTIVITY_LOC: {
      const { dayIndex, indexToUpdate, category, value } = action.payload;
      return Object.assign({}, state, {
        days: state.days.map((day, index) => {
          if (index === dayIndex) {
            return Object.assign({}, day, {
              [`${category}Activities`]: day[`${category}Activities`].map(
                (activity, index) => {
                  if (index === indexToUpdate) {
                    return Object.assign({}, activity, {
                      location: value.address,
                      geoLocation: value.geoLocation
                    });
                  }
                  return activity;
                }
              )
            });
          }
          return day;
        })
      });
    }

    case ITINERARY_REMOVE_ACTIVITY: {
      const { dayIndex, indexToDelete, category } = action.payload;
      return Object.assign({}, state, {
        days: state.days.map((day, index) => {
          if (index === dayIndex) {
            return Object.assign({}, day, {
              [`${category}Activities`]: day[`${category}Activities`].filter(
                (item, index) => index !== indexToDelete
              )
            });
          }
          return day;
        })
      });
    }

    case ITINERARY_ADD_FOOD_ACTIVITY: {
      const { dayIndex, category } = action.payload;
      return Object.assign({}, state, {
        days: state.days.map((day, index) => {
          if (index === dayIndex) {
            switch (category) {
              case "breakfast": {
                return Object.assign({}, day, {
                  breakfastActivities: [
                    ...day.breakfastActivities,
                    {
                      imageId: null,
                      title: "",
                      location: "",
                      geoLocation: null,
                      time: "",
                      hours: GetDefaultHour(category),
                      description: "",
                      tips: "",
                      category
                    }
                  ]
                });
              }
              case "lunch": {
                return Object.assign({}, day, {
                  lunchActivities: [
                    ...day.lunchActivities,
                    {
                      imageId: null,
                      title: "",
                      location: "",
                      geoLocation: null,
                      time: "",
                      hours: GetDefaultHour(category),
                      description: "",
                      tips: "",
                      category
                    }
                  ]
                });
              }
              case "dinner": {
                return Object.assign({}, day, {
                  dinnerActivities: [
                    ...day.dinnerActivities,
                    {
                      imageId: null,
                      title: "",
                      location: "",
                      geoLocation: null,
                      time: "",
                      hours: GetDefaultHour(category),
                      description: "",
                      tips: "",
                      category
                    }
                  ]
                });
              }
              default: {
                break;
              }
            }
          }

          return day;
        })
      });
    }

    case ITINERARY_UPDATE_FOOD_ACTIVITY: {
      const { dayIndex, indexToUpdate, category, key, value } = action.payload;
      return Object.assign({}, state, {
        days: state.days.map((day, index) => {
          if (index === dayIndex) {
            return Object.assign({}, day, {
              [`${category}Activities`]: day[`${category}Activities`].map(
                (foodActivity, index) => {
                  if (index === indexToUpdate) {
                    return Object.assign({}, foodActivity, {
                      [key]: value
                    });
                  }
                  return foodActivity;
                }
              )
            });
          }
          return day;
        })
      });
    }

    case ITINERARY_UPDATE_FOOD_ACTIVITY_LOC: {
      const { dayIndex, indexToUpdate, category, value } = action.payload;
      return Object.assign({}, state, {
        days: state.days.map((day, index) => {
          if (index === dayIndex) {
            return Object.assign({}, day, {
              [`${category}Activities`]: day[`${category}Activities`].map(
                (foodActivity, index) => {
                  if (index === indexToUpdate) {
                    return Object.assign({}, foodActivity, {
                      location: value.address,
                      geoLocation: value.geoLocation
                    });
                  }
                  return foodActivity;
                }
              )
            });
          }
          return day;
        })
      });
    }

    case ITINERARY_REMOVE_FOOD_ACTIVITY: {
      const { dayIndex, indexToDelete, category } = action.payload;
      return Object.assign({}, state, {
        days: state.days.map((day, index) => {
          if (index === dayIndex) {
            return Object.assign({}, day, {
              [`${category}Activities`]: day[`${category}Activities`].filter(
                (item, index) => index !== indexToDelete
              )
            });
          }
          return day;
        })
      });
    }

    case ITINERARY_ADD_STAY_IMAGE: {
      const { dayIndex } = action.payload;
      return Object.assign({}, state, {
        days: state.days.map((day, index) => {
          if (index === dayIndex) {
            return Object.assign({}, day, {
              stayImages: [
                ...day.stayImages,
                {
                  imageId: "",
                  description: ""
                }
              ]
            });
          }

          return day;
        })
      });
    }
    case ITINERARY_UPDATE_STAY_IMAGE: {
      const { dayIndex, indexToUpdate, key, value } = action.payload;
      return Object.assign({}, state, {
        days: state.days.map((day, index) => {
          if (index === dayIndex) {
            return Object.assign({}, day, {
              stayImages: day.stayImages.map((stayImage, index) => {
                if (index === indexToUpdate) {
                  return Object.assign({}, stayImage, {
                    [key]: value
                  });
                }
                return stayImage;
              })
            });
          }
          return day;
        })
      });
    }

    case ITINERARY_REMOVE_STAY_IMAGE: {
      const { dayIndex, indexToDelete } = action.payload;
      return Object.assign({}, state, {
        days: state.days.map((day, index) => {
          if (index === dayIndex) {
            return Object.assign({}, day, {
              stayImages: day.stayImages.filter(
                (item, index) => index !== indexToDelete
              )
            });
          }
          return day;
        })
      });
    }

    case ITINERARY_TOGGLE_INFO: {
      const { key, value } = action.payload;
      return Object.assign({}, state, {
        [key]:
          state[key].indexOf(value) > -1
            ? state[key].filter(a => a !== value)
            : [...state[key], value]
      });
    }

    default: {
      return state;
    }
  }
}
