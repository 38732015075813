import { withTranslation } from "react-i18next";
import React from "react";
import { Fade } from "react-reveal";
import { Helmet } from "react-helmet";
const ComposeDashboard = withTranslation()(
  class ComposeDashboard extends React.Component {
    render() {
      return (
        <Fade duration={300}>
          <Helmet>
            <title>
              {this.props.t(
                "Welcome to the Tripbonder - Asia's leading trip and travel planning portal"
              )}
            </title>
          </Helmet>
          <div className="container-fluid privacy-policy">
            <div className="row">
              <div className="col-12 p-4">
                <ul className="inline-wrapper p-0">
                  <li className="terms-style">
                    <a href="/">{this.props.t("Terms & Conditions")}</a>
                  </li>
                  {/* <li className="policy-style ml-3">
                   <a href="/">Privacy Policy</a>
                   </li> */}
                </ul>
                <hr />
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 p-0">
                  <div className="policy-header text-center">
                    <h2>{this.props.t("Privacy Policy")}</h2>
                    <span>
                      {this.props.t("Last updated: October 22, 2019")}
                    </span>
                    <hr />
                  </div>
                  <div className="policy-content">
                    <h1>{this.props.t("Heading 1")}</h1>
                    <h2>{this.props.t("Heading 2")}</h2>
                    <h3>{this.props.t("Heading 3")}</h3>
                    <h4>{this.props.t("Heading 4")}</h4>
                    <h5>{this.props.t("Heading 5")}</h5>
                    <h6 className="mb-4">{this.props.t("Heading 6")}</h6>

                    <h5>{this.props.t("Paragrah")}</h5>
                    <p>
                      {this.props.t(
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"
                      )}
                    </p>
                    <p>
                      {this.props.t(
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"
                      )}
                    </p>

                    <h5>{this.props.t("Unordered List")}</h5>
                    <ul>
                      <li>
                        {this.props.t(
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                        )}{" "}
                      </li>
                      <li>
                        {this.props.t(
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                        )}
                      </li>
                      <li>
                        {this.props.t(
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                        )}{" "}
                      </li>
                    </ul>

                    <h5>{this.props.t("Ordered List")}</h5>
                    <ol>
                      <li>
                        {this.props.t(
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"
                        )}
                      </li>
                      <li>
                        {this.props.t(
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"
                        )}
                      </li>
                      <li>
                        {this.props.t(
                          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum"
                        )}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      );
    }
  }
);

export default ComposeDashboard;
