import React, { useEffect, useState } from "react";

export default function(props) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.GYG.constructor();
    var uxTimer = setTimeout(() => {
      setLoading(false);
    }, 900);

    return function() {
      clearTimeout(uxTimer);
    };
  }, []);

  return (
    <>
      {loading === true ? (
        <div className="col-12 pt-3 pb-2 d-flex justify-content-center align-items-center">
          <i className="fas fa-spin fa-2x fa-spinner"></i>
        </div>
      ) : null}
      <div
        data-gyg-partner-id="1IPV6XW"
        data-gyg-number-of-items="12"
        data-gyg-currency="USD"
        data-gyg-locale-code="en-US"
        data-gyg-id="code-example"
        data-gyg-q="LOCAL GUIDES & TICKET"
        data-gyg-widget="activites"
        data-gyg-href="https://widget.getyourguide.com/1IPV6XW/activities.frame"
      ></div>
    </>
  );
}
